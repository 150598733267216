import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../../client/DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { DialogService } from '../../../core/services/dialog.service';
import { Operations, CreateActivityTrackerInput } from '../../../model/activity-tracker';
import { ActivityTrackerService } from '../../../core/services/activity-tracker.service';
import { DatePipe } from '@angular/common';

declare var $: any;
@Component({
  selector: 'app-manage-tab-sequence',
  templateUrl: './manage-tab-sequence.component.html',
  styleUrls: ['./manage-tab-sequence.component.scss'],
})

export class ManageTabSequenceComponent implements OnInit {
  gridShow: boolean;
  @Input() templateData: any;
  SubMenuCardModel: SubMenuCardModel;
  json: any = [];
  selected = 'Accession';
  destDeployment: string = '';
  templateDatafordefault: any;
  enableHorizontal: boolean = false;
  uploadClicked: boolean=false;
  mandatoryFields = [{}];
  excelDataArray: any[];
  enableVertical: boolean = false;
  allFields: any = [{}];
  noData: boolean;
  showInvalidColumns: boolean;
  postUpload: boolean;
  bulkUpload: boolean=false;
  enableActive: boolean;
  checkStatus: boolean;
  noDataFound: boolean;
  gridwidth: number;
  gridData: wjcCore.CollectionView<any>;
  selectedData: any[];
  workBook: any;
  sheetsToSelect: any[];
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  invalidColumns: any;
  showDelete: boolean;
  selector: Selector;
  sheetHeader: any;
  uploadBtn: boolean;
  createBtn: boolean;
  editBtn: boolean;
  exportBtn: boolean;
  copyBtn: boolean;
  removeBtn: boolean;
  Caseinfodata: any = [];
  Patientinfodata: any = [];
  headertext: string;
  Supplementalinfodata: any = [];
  primaryInsuranceData: any = [];
  secondaryInsuranceData: any = [];
  guarantorInsuranceData: any = [];
  horizontalTabSequence: any = [];
  verticalTabSequence: any = [];
  caseheadertext: string;
  patientheadertext: string;
  supplementalHeadertext: string;
  PinsuranceHeadertext: string;
  SinsuranceHeadertext: string;
  TprimaryInsuranceData: any = [];
  TinsuranceHeadertext: string;
  GinsuranceHeadertext: string;
  ButtonHeadertext: string;
  Buttoninfodata: any = [];
  Boxdata: any;
  caseinfoboxnumber: any = [];
  patientinfoboxnumber: any = [];
  supplementinfoboxnumber: any = [];
  pinsuranceinfoboxnumber: any = [];
  sinsuranceinfoboxnumber: any = [];
  tinsuranceinfoboxnumber: any = [];
  guaratorinfoboxnumber: any = [];
  buttoninfoboxnumber: any = [];
  backupData: any = [];
  statusClass = 'not-active';
  notes: any = 'The tab order sequence is shown beside the field label(Default tab order sequence)';
  createActivityInput = new CreateActivityTrackerInput();
  createActivityOperations = new Operations();



  //////////////////////////////// default value for the the value coming from DB;
  ///////////////////////////Case Information Default//////////////////////////////
  caseProfile: any;
  caseType: any;
  otherCaseType: any;
  externalLaboratory: any;
  collectionDate: any;
  collectionTime: any;
  recievedDate: any;
  recievedTime: any;
  laboratory: any;
  hospitalFacility: any;
  orderingPhysician: any;
  labCase: any;
  sendInlaboratory: any;
  services: any;
  procedure: any;
  otherExtractionProcedure: any;
  Services1: any;
  kitRequisition: any;
  referringPhysician: any;
  referringPhysicianFax: any;
  copyReport: any;
  tracking: any;
  soBillTo: any;
  noOfJars: any;
  siteSchema: any;
  reference: any;
  encounter: any;
  icd1: any;
  icd2: any;
  icd3: any;
  icd4: any;
  icd5: any;
  icd6: any;
  icd7: any;
  icd8: any;
  priority: any;
  lmpDate: any;
  billTo: any;
  forResearchUse: any;

  ///////////////////////////////Patient Default//////////////////////////////
  lastName: any;
  gender: any;
  emr: any;
  firstName: any;
  dob: any;
  pmsPws: any;
  middleName: any;
  ssn: any;
  homePhone: any;
  workPhone: any;
  address1: any;
  address2: any;
  city: any;
  state: any;
  zip: any;
  email: any;
  patSuffix: any;

  /////////////////////////////Supplemental Information Default/////////////////////////
  clinicalInfo: any;
  specialInstructions: any;
  sendOutNote: any;
  clinicalStage: any;
  psavalue: any;

  /////////////////////////////Primary Insurance Default/////////////////////////
  pRelationship: any;
  pLastName: any;
  pDob: any;
  pGroupPlan: any;
  pFirstName: any;
  pGender: any;
  pGroup: any;
  pPlanType: any;
  pMiddleInitial: any;
  pPolicy: any;
  pPatientPolicy: any;
  pPayor: any;
  pAddress1: any;
  pCity: any;
  pZip: any;
  pInsuranceCode: any;
  pAddress2: any;
  pState: any;
  pSuffix: any;
  lookUp: any;


  /////////////////////////////Secondary Insurance Default/////////////////////////
  sRelationship: any;
  sLastName: any;
  sDob: any;
  sGroupPlan: any;
  sFirstName: any;
  sGender: any;
  sGroup: any;
  sPlanType: any;
  sMiddleInitial: any;
  sPolicy: any;
  sPatientPolicy: any;
  sPayor: any;
  sAddress1: any;
  sCity: any;
  sZip: any;
  sInsuranceCode: any;
  sAddress2: any;
  sState: any;
  sInsuranceTypeCode: any;
  sSuffix: any;


  /////////////////////////////Tertiary Insurance Default/////////////////////////
  tRelationship: any;
  tLastName: any;
  tDob: any;
  tGroupPlan: any;
  tFirstName: any;
  tGender: any;
  tGroup: any;
  tPlanType: any;
  tMiddleInitial: any;
  tPolicy: any;
  tPatientPolicy: any;
  tPayor: any;
  tAddress1: any;
  tCity: any;
  tZip: any;
  tInsuranceCode: any;
  tAddress2: any;
  tState: any;
  tInsuranceTypeCode: any;
  tSuffix: any;


  /////////////////////////////Guarantor Insurance Default/////////////////////////
  gRelationship: any;
  gLastName: any;
  gFirstName: any;
  gMiddleInitial: any;
  gHomePhone: any;
  gWorkPhone: any;
  gAddress1: any;
  gCity: any;
  gZip: any;
  gAddress2: any;
  gState: any;
  gSuffix: any;

  /////////////////////////////Button Default/////////////////////////
  bSave: any;
  bSaveClose: any;
  bSaveProceed: any;
  bCancelRequistion: any;
  bOrder: any;
  bAccession: any;
  bCancelOrder: any;
  bReturn: any;
  bSendOutCase: any;
  bReject: any;
  verticalTabDefultData: any;
  requisitionPage: boolean;
  cprequisitionPage: boolean;
  accessionPage: boolean=true;
  page: any;
  sendoutPage: boolean;
  public enableTabFocus: boolean=false;
  task: any = { color: 'primary' };
  tempTabFlagObj: any;
  deployment: any;
  isDataChanged: boolean=false;
  previousSelectedPage: string;

  newAuditObj:any;
  oldAuditDataauditobj:any=[];
  finalAuditObj: any=[];
  rbacObj={ "uploadBtn": false, "editBtn": false};
  constructor(private ngxService: NgxUiLoaderService,
    private datashare: DataShareService,
    private _fb: UntypedFormBuilder,
    public tabService: VitalAdminTabService,
    public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar,
    private dialog: MatDialog,
    public commonService: CommonService,
    private opendialog: DialogService,
    public datePipe: DatePipe,
    public activityService: ActivityTrackerService
  ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
  }

options:any;

selectedpage: string; // default value
activitytrackerObjForTabFlag={
  "Enable tab focus":{oldValue :'',newValue:''}
}
  async ngOnInit() {
    this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
    await this.validateOrganizationType()
    await this.GetButtonAccess(this.VitalHttpServices.SubmenuAction);
    await this.CreateConfig()
    this.activityService.setActivitySession({'entityId': '','entityType': this.templateData.menuURL, 'context':[{'key':'parentMenu','value':this.templateData.menuURL}]})
  }

  //associationcheck
  async validateOrganizationType() {
    let orgid=this.templateData.secondarykeys.OrganizationId
    await this.VitalHttpServices.validateTabIndexOrgType(orgid, this.destDeployment).subscribe(async result => {
      if (!result.errors) {
        if(result[0].status){
          if(result[0].result == "Laboratories"){
            this.options = [
              { label: 'Accession', value: 'Accession' },
              { label: 'Send Out', value: 'SendOut' }
            ];

          this.selectedpage = 'Accession';
          //await this.getInputData(this.selectedpage);
          }else if(result[0].result == "Both"){
            this.options = [
              { label: 'Accession', value: 'Accession' },
              { label: 'Requisition', value: 'Requisition' },
              { label: 'CP Requisition', value: 'CP Requisition' },
              { label: 'Send Out', value: 'SendOut' }
            ];

          this.selectedpage = 'Accession';
          //await this.getInputData(this.selectedpage);
          }
          else{
            this.options = [
              { label: 'Requisition', value: 'Requisition' },
              { label: 'CP Requisition', value: 'CP Requisition' },
            ];

          this.selectedpage = 'Requisition';
        }
      }
      await this.resetTabIndex(this.selectedpage,false)
        this.previousSelectedPage=this.selectedpage;
      }

    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.log(error);
    });
  }

  // Audit
  auditDetails(oldObj, newObjArray, info) {
    let msgActivityTracker = ''
    msgActivityTracker = (info == 'updated') ? 'Updated' : (info == 'saved') ? 'Added' : (info == 'copied') ? 'Copy from other entity' : 'Bulk Upload';

    let oldObject = {};
    if (Object.keys(oldObj).length > 0) {
      /*Update record*/
      this.ngxService.start();
      this.commonService.createActivityTracker(msgActivityTracker, oldObj.attributeid, "Tab Order Index", 'Audit', newObjArray[0] == undefined ? newObjArray : newObjArray[0], oldObj);
      this.ngxService.stop();
    }
    else {
      /*New records*/
      this.ngxService.start();
      let orgid = sessionStorage.getItem('org_id');
      if (!newObjArray || !newObjArray.length) {
        this.commonService.createActivityTracker(msgActivityTracker, orgid, 'Tab Order Index', 'Audit', newObjArray, {});
      }
      else {
        for (let i = 0; i < newObjArray.length; i++) {
          this.commonService.createActivityTracker(msgActivityTracker, orgid, 'Tab Order Index', 'Audit', newObjArray[i], {});
        }
      }
    }
    this.ngxService.stop();
  }
  //RBAC audit
  GetButtonAccess(CasemenuAction) {
    let seletedMenuPermissions = CasemenuAction.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "false" ? false : true;
          this.rbacObj.uploadBtn=this.uploadBtn
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "false" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "false" ? false : true;
          this.rbacObj.editBtn=this.editBtn
          break;
      }
    }
  }
  tabSequenceForm = this._fb.group({
    sectionname: '',
    controllabelname: ['', Validators.required],
    subsectionname: ['', Validators.required],
    configuredtabindex: ''
  });

  tabSequenceCaseInfoForm = this._fb.group({
    CICase_Profile: '',
    CICase_type: '',
    CICollection_date: '',
    CICollection_time: '',
    CIRecieved_date: '',
    CIRecieved_time: '',
    CILaboratory: '',
    CIHospital_Facility: '',
    CIOrdering_physician: '',
    CILab_case: '',
    CISend_in_laboratory: '',
    CIProcedure: '',
    CIServices: '',
    CIKit_requisition: '',
    CIReferring_physician: '',
    CIReferring_physician_Fax: '',
    CICopy_reports_to_referring_priority_physician: '',
    CITracking_: '',
    CINo_of_jars: '',
    CIEncounter: '',
    CIICD_indicative1: '',
    CIICD_indicative2: '',
    CIICD_indicative3: '',
    CIICD_indicative4: '',
    CIICD_indicative5: '',
    CIICD_indicative6: '',
    CIICD_indicative7: '',
    CIICD_indicative8: '',
    CIPriority: '',
    CIBill_to: ''
  });




  getInputData(selectedpage) {
    let query = this.SubMenuCardModel.GetQuery('getmanagetabsequencelevel');
    let queryVariable = {
      organizationid: sessionStorage.getItem('org_id').toString(),
      pagetype: selectedpage
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult).subscribe(
      data => {
        if (!data.errors) {
          this.Boxdata = data.data.submenuData;
          for (var j = 0; j < this.Boxdata.length; j++) {
            if (!this.Boxdata[j].sectionname || this.Boxdata[j].sectionname.toString().toLowerCase() == 'caseinformation' || this.Boxdata[j].sectionname.toString().toLowerCase() == 'case information') {
              this.caseinfoboxnumber.push(this.Boxdata[j]);

            }
            if (!this.Boxdata[j].sectionname || this.Boxdata[j].sectionname.toString().toLowerCase() == 'patientinformation' || this.Boxdata[j].sectionname.toString().toLowerCase() == 'patient information') {
              this.patientinfoboxnumber.push(this.Boxdata[j]);
            }
            if (!this.Boxdata[j].sectionname || this.Boxdata[j].sectionname.toString().toLowerCase() == 'supplementalinformation' || this.Boxdata[j].sectionname.toString().toLowerCase() == 'supplemental information') {
              this.supplementinfoboxnumber.push(this.Boxdata[j]);
            }
            if (!this.Boxdata[j].sectionname || this.Boxdata[j].sectionname.toString().toLowerCase() == 'insurance') {
              if (!this.Boxdata[j].sectionname || this.Boxdata[j].subsectionname.toString().toLowerCase() == 'primary insurance') {
                this.pinsuranceinfoboxnumber.push(this.Boxdata[j]);
              }
              if (!this.Boxdata[j].sectionname || this.Boxdata[j].subsectionname.toString().toLowerCase() == 'secondary insurance') {
                this.sinsuranceinfoboxnumber.push(this.Boxdata[j]);
              }
              if (!this.Boxdata[j].sectionname || this.Boxdata[j].subsectionname.toString().toLowerCase() == 'tertiary insurance') {
                this.tinsuranceinfoboxnumber.push(this.Boxdata[j]);
              }
              if (!this.Boxdata[j].sectionname || this.Boxdata[j].subsectionname.toString().toLowerCase() == 'guarantor') {
                this.guaratorinfoboxnumber.push(this.Boxdata[j]);
              }
            }
            if (!this.Boxdata[j].sectionname || this.Boxdata[j].sectionname.toString().toLowerCase() == 'button') {
              this.buttoninfoboxnumber.push(this.Boxdata[j]);
            }
          }
          this.getData();
        }
        this.ngxService.stop();
      },
      error => {
        console.error(error);
        this.ngxService.stop();
      }
    );
  }

  getData() {
    let query = '';
    let queryVariable = {};
    query = this.SubMenuCardModel.GetQuery('getmanagetabsequencelevel');
    queryVariable = {
      organizationid: "-1",
      pagetype: this.selectedpage
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult).subscribe(
      data => {
        if (!data.errors) {
          this.templateDatafordefault = data.data.submenuData;
          this.verticalTabDefultData = data.data.submenuData;
          this.horizontalTabSequence = [];
          this.verticalTabSequence = [];
          this.setDefalutValue(this.templateDatafordefault);
          this.getPatchedData();
        }
        this.ngxService.stop();
      },
      error => {
        console.error(error);
        this.ngxService.stop();
      }
    );
  }

  getPatchedData() {
    for (var i = 0; i < this.templateDatafordefault.length; i++) {
      if (!this.templateDatafordefault[i].sectionname || this.templateDatafordefault[i].sectionname.toString().toLowerCase() == 'caseinformation' || this.templateDatafordefault[i].sectionname.toString().toLowerCase() == 'case information') {
        var casedata;
        this.caseheadertext = 'Case Information';
        casedata = this.caseinfoboxnumber.filter(x => x.controlid == this.templateDatafordefault[i].controlid);

        if (casedata.length == 0) {
          this.templateDatafordefault[i].configuredtabindex = 0;
          this.Caseinfodata.push(this.templateDatafordefault[i]);
        }
        else {
          this.templateDatafordefault[i].configuredtabindex = casedata[0].configuredtabindex;
          this.templateDatafordefault[i].id = casedata[0].id;
          this.Caseinfodata.push(this.templateDatafordefault[i]);
        }
        //sort the number in ascending order
        //  this.Caseinfodata = this.Caseinfodata.sort((a, b) => a.configuredtabindex - b.configuredtabindex);
      }
      if (!this.templateDatafordefault[i].sectionname || this.templateDatafordefault[i].sectionname.toString().toLowerCase() == 'patient information') {
        var Patientdata;
        this.patientheadertext = 'Patient Information';
        Patientdata = this.patientinfoboxnumber.filter(x => x.controlid == this.templateDatafordefault[i].controlid);
        if (Patientdata.length == 0) {
          this.templateDatafordefault[i].configuredtabindex = 0;
          this.Patientinfodata.push(this.templateDatafordefault[i]);
        }
        else {
          this.templateDatafordefault[i].configuredtabindex = Patientdata[0].configuredtabindex;
          this.templateDatafordefault[i].id = Patientdata[0].id;
          this.Patientinfodata.push(this.templateDatafordefault[i]);
        }
        //sort the number in ascending order
        //  this.Patientinfodata = this.Patientinfodata.sort((a, b) => a.configuredtabindex - b.configuredtabindex);
      }
      if (!this.templateDatafordefault[i].sectionname || this.templateDatafordefault[i].sectionname.toString().toLowerCase() == 'supplemental information') {
        var supplementaldata;
        this.supplementalHeadertext = 'Supplemental Information';
        supplementaldata = this.supplementinfoboxnumber.filter(x => x.controlid == this.templateDatafordefault[i].controlid);
        if (supplementaldata.length == 0) {
          this.templateDatafordefault[i].configuredtabindex = 0;
          this.Supplementalinfodata.push(this.templateDatafordefault[i]);
        }
        else {
          this.templateDatafordefault[i].configuredtabindex = supplementaldata[0].configuredtabindex;
          this.templateDatafordefault[i].id = supplementaldata[0].id;
          this.Supplementalinfodata.push(this.templateDatafordefault[i]);
        }
        //sort the number in ascending order
        // this.Supplementalinfodata = this.Supplementalinfodata.sort((a, b) => a.configuredtabindex - b.configuredtabindex);
      }
      if (!this.templateDatafordefault[i].sectionname || this.templateDatafordefault[i].sectionname.toString().toLowerCase() == 'insurance') {
        this.headertext = 'Insurance';
        if (!this.templateDatafordefault[i].sectionname || this.templateDatafordefault[i].subsectionname.toString().toLowerCase() == 'primary insurance') {
          var Pinsurancedata;
          this.PinsuranceHeadertext = 'Primary Insurance';
          Pinsurancedata = this.pinsuranceinfoboxnumber.filter(x => x.controlid == this.templateDatafordefault[i].controlid);
          if (Pinsurancedata.length == 0) {
            this.templateDatafordefault[i].configuredtabindex = 0;
            this.primaryInsuranceData.push(this.templateDatafordefault[i]);
          }
          else {
            this.templateDatafordefault[i].configuredtabindex = Pinsurancedata[0].configuredtabindex;
            this.templateDatafordefault[i].id = Pinsurancedata[0].id;
            this.primaryInsuranceData.push(this.templateDatafordefault[i]);
          }
          //sort the number in ascending order
          //  this.primaryInsuranceData = this.primaryInsuranceData.sort((a, b) => a.configuredtabindex - b.configuredtabindex);
        }
        if (!this.templateDatafordefault[i].sectionname || this.templateDatafordefault[i].subsectionname.toString().toLowerCase() == 'secondary insurance') {
          var Sinsurancedata;
          this.SinsuranceHeadertext = 'Secondary Insurance';
          Sinsurancedata = this.sinsuranceinfoboxnumber.filter(x => x.controlid == this.templateDatafordefault[i].controlid);
          if (Sinsurancedata.length == 0) {
            this.templateDatafordefault[i].configuredtabindex = 0;
            this.secondaryInsuranceData.push(this.templateDatafordefault[i]);
          }
          else {
            this.templateDatafordefault[i].configuredtabindex = Sinsurancedata[0].configuredtabindex;
            this.templateDatafordefault[i].id = Sinsurancedata[0].id;
            this.secondaryInsuranceData.push(this.templateDatafordefault[i]);
          }
          //sort the number in ascending order
          // this.secondaryInsuranceData = this.secondaryInsuranceData.sort((a, b) => a.configuredtabindex - b.configuredtabindex);
        }
        if (!this.templateDatafordefault[i].sectionname || this.templateDatafordefault[i].subsectionname.toString().toLowerCase() == 'tertiary insurance') {
          var Tinsurancedata;
          this.TinsuranceHeadertext = 'Tertiary Insurance';
          Tinsurancedata = this.tinsuranceinfoboxnumber.filter(x => x.controlid == this.templateDatafordefault[i].controlid);
          if (Tinsurancedata.length == 0) {
            this.templateDatafordefault[i].configuredtabindex = 0;
            this.TprimaryInsuranceData.push(this.templateDatafordefault[i]);
          }
          else {
            this.templateDatafordefault[i].configuredtabindex = Tinsurancedata[0].configuredtabindex;
            this.templateDatafordefault[i].id = Tinsurancedata[0].id;
            this.TprimaryInsuranceData.push(this.templateDatafordefault[i]);
          }
          //sort the number in ascending order
          //  this.TprimaryInsuranceData = this.TprimaryInsuranceData.sort((a, b) => a.configuredtabindex - b.configuredtabindex);
        }
        if (!this.templateDatafordefault[i].sectionname || this.templateDatafordefault[i].subsectionname.toString().toLowerCase() == 'guarantor') {
          var GuratorIdata;
          this.GinsuranceHeadertext = 'Guarantor';
          GuratorIdata = this.guaratorinfoboxnumber.filter(x => x.controlid == this.templateDatafordefault[i].controlid);
          if (GuratorIdata.length == 0) {
            this.templateDatafordefault[i].configuredtabindex = 0;
            this.guarantorInsuranceData.push(this.templateDatafordefault[i]);
          }
          else {
            this.templateDatafordefault[i].configuredtabindex = GuratorIdata[0].configuredtabindex;
            this.templateDatafordefault[i].id = GuratorIdata[0].id;
            this.guarantorInsuranceData.push(this.templateDatafordefault[i]);
          }
          //sort the number in ascending order
          // this.guarantorInsuranceData = this.guarantorInsuranceData.sort((a, b) => a.configuredtabindex - b.configuredtabindex);
        }
      }
      if (!this.templateDatafordefault[i].sectionname || this.templateDatafordefault[i].sectionname.toString().toLowerCase() == 'button') {
        var Buttondata;
        this.ButtonHeadertext = 'Buttons';
        Buttondata = this.buttoninfoboxnumber.filter(x => x.controlid == this.templateDatafordefault[i].controlid);
        if (Buttondata.length == 0) {
          this.templateDatafordefault[i].configuredtabindex = 0;
          this.Buttoninfodata.push(this.templateDatafordefault[i]);
        }
        else {
          this.templateDatafordefault[i].configuredtabindex = Buttondata[0].configuredtabindex;
          this.templateDatafordefault[i].id = Buttondata[0].id;
          this.Buttoninfodata.push(this.templateDatafordefault[i]);
        }
        //sort the number in ascending order
        //this.Buttoninfodata = this.Buttoninfodata.sort((a, b) => a.configuredtabindex - b.configuredtabindex);
      }
    }
    this.patchDbToDeafult();
  }
  //set the horizontal value and the vertical value
  setDefalutValue(value) {
    let orgid = sessionStorage.getItem('org_id')
    for (var j = 0; j < value.length; j++) {
      if (!value[j].sectionname || value[j].sectionname.toString().toLowerCase() == 'caseinformation' || value[j].sectionname.toString().toLowerCase() == 'case information') {
        if (value[j].controlid.toString() == 'ddlCaseProfile') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 1;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'ddlCaseType') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 2;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'ddlprocedure') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 3;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtNoJars') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 4;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtOtherCaseType') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 5;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtOtherExtractionProcedure') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 6;
          this.horizontalTabSequence.push(value[j]);
        }
        else if (value[j].controlid.toString() == 'ddlSiteScheme') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 7;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'ddlExternalLab') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 8;
          this.horizontalTabSequence.push(value[j]);
        }
        else if (value[j].controlid.toString() == 'ddlServices') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 9;
          this.horizontalTabSequence.push(value[j]);
        }
        else if (value[j].controlid.toString() == 'txtRefNumber') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 10;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtCollectionDate') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 11;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtCollectionTime' || value[j].controlid.toString() == 'ddlCollectionTime') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 12;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtRequisition') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 13;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtEncounter') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 14;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtRecievedDate') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 15;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtReceivedTime' || value[j].controlid.toString() == 'ddlReceivedTime') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 16;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtReferringPhysician') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 17;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtICD1') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 18;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtICD2') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 19;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'ddlLaboratory') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 20;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtRefPhysicianFaxNo') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 21;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtICD3') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 22;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtICD4') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 23;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtOH') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 24;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'chkReports') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 25;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtICD5') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 26;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtICD6') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 27;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'ddlPhysician') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 28;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtClientTrackingNo') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 29;
          this.horizontalTabSequence.push(value[j]);
        }


        else if (value[j].controlid.toString() == 'txtICD7') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 30;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtICD8') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 31;
          this.horizontalTabSequence.push(value[j]);
        }


        else if (value[j].controlid.toString() == 'txtLabCaseNo') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 32;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'ddlSendOutBillTo') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 33;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'ddlBillTo') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 34;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'ddlSendInLab') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 35;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtLmpDate') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 36;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'ddlCasePriority') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 37;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'ddlSendOutServices') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 38;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'chkResearch') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 39;
          this.horizontalTabSequence.push(value[j]);
        }


      }

      else if (!value[j].sectionname || value[j].sectionname.toString().toLowerCase() == 'patient information') {
        if (value[j].controlid.toString() == 'txtLastName') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 40;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtFirstName') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 41;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtMiddleName') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 42;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtAddress1') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 43
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'ddlGender') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 44
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtDateOfBirth') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 45
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtSSNNo') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 46
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtAddress2') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 47
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtMedRecNo') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 48
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtOrderNo') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 49
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtPatientHomePhone') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 50
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtCity') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 51;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtPatientEmail') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 52
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'btnSearch') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 53
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtPatientWorkPhone') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 54
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'drpState') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 55
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtSuffix') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 56;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtZip') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 57
          this.horizontalTabSequence.push(value[j]);
        }
      }

      else if (!value[j].sectionname || value[j].sectionname.toString().toLowerCase() == 'supplemental information') {
        if (value[j].controlid.toString() == 'txtClinicalInfo') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 58;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtSpecial') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 59;
          this.horizontalTabSequence.push(value[j]);
        }
        else if (value[j].controlid.toString() == 'txtSendOutNotes') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 60;
          this.horizontalTabSequence.push(value[j]);
        }
        else if (value[j].controlid.toString() == 'ddlClinicalStages') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 61;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'txtPSARange') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 62;
          this.horizontalTabSequence.push(value[j]);
        }

      }

      if (!value[j].sectionname || value[j].sectionname.toString().toLowerCase() == 'insurance') {

        if (!value[j].subsectionname || value[j].subsectionname.toString().toLowerCase() == 'primary insurance') {
          if (value[j].controlid.toString() == 'SubscribeInfo_txtPrimaryRelationship') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 63;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtRespSuffix1') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 64;
            this.horizontalTabSequence.push(value[j]);
          }

          // else if (value[j].controlid.toString() == 'txtRefPhysicianFaxNo') {
          //   value[j].organizationid = orgid;
          //   value[j].configuredtabindex = 65;
          //   this.horizontalTabSequence.push(value[j]);
          // }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPrimaryPlanType') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 65;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPayor1') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 66;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtIC1') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 67;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtLastName1') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 68;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtRespFirstName1') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 69;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtRespMiddleInitials1') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 70;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtAddress11') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 71;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtAddress12') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 72;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtDob1') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 73;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtGender1') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 74;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPolicyNo1') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 75;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtCity1') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 76;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtState1') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 77;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtGroupName1') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 78;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtGroupNo1') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 79;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPatientPolicyno1') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 80;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtZip1') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 81;
            this.horizontalTabSequence.push(value[j]);
          }

        }

        else if (!value[j].subsectionname || value[j].subsectionname.toString().toLowerCase() == 'secondary insurance') {
          if (value[j].controlid.toString() == 'SubscribeInfo_txtSecondaryRelationship') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 82;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtRespSuffix2') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 83;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtSecondaryPlanType') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 84;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPayor2') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 85;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtIC2') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 86;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtLastName2') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 87;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtRespFirstName2') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 88;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtRespMiddleInitials2') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 89;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtAddress21') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 90;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtAddress22') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 91;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtDob2') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 92;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtGender2') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 93;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPolicyNo2') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 94;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtCity2') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 95;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtState2') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 96;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtGroupName2') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 97;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtGroupNo2') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 98;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPatientPolicyno2') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 99;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtZip2') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 100;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_drpInsuranceTypeCode2') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 101;
            this.horizontalTabSequence.push(value[j]);
          }

        }

        else if (!value[j].subsectionname || value[j].subsectionname.toString().toLowerCase() == 'tertiary insurance') {
          if (value[j].controlid.toString() == 'SubscribeInfo_txtTertiayRelationship') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 102;
            this.horizontalTabSequence.push(value[j]);
          }
          else if (value[j].controlid.toString() == 'SubscribeInfo_txtRespSuffix3') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 103;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtTertiayPlanType') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 104;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPayor3') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 105;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtIC3') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 106;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtLastName3') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 107;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtRespFirstName3') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 108;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtRespMiddleInitials3') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 109;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtAddress31') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 110;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtAddress32') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 111;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtDob3') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 112;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtGender3') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 113;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPolicyNo3') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 114;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtCity3') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 115;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtState3') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 116;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtGroupName3') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 117;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtGroupNo3') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 118;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPatientPolicyno3') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 119;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtZip3') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 120;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_drpInsuranceTypeCode3') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 121;
            this.horizontalTabSequence.push(value[j]);
          }

        }

        else if (!value[j].subsectionname || value[j].subsectionname.toString().toLowerCase() == 'guarantor') {
          if (value[j].controlid.toString() == 'SubscribeInfo_ddlPatientRelationship1') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 122;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeLastName') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 123;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeFirstName') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 124;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeMiddleName') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 125;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeHomePhone') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 126;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeWorkPhone') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 127;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeAddress1') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 128;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeAddress2') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 129;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeCity') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 130;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_drpPatientRelativeState') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 131;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeZip') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 132;
            this.horizontalTabSequence.push(value[j]);
          }

          else if (value[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeSuffix') {
            value[j].organizationid = orgid;
            value[j].configuredtabindex = 133;
            this.horizontalTabSequence.push(value[j]);
          }

        }
      }

      else if (!value[j].sectionname || value[j].sectionname.toString().toLowerCase() == 'button') {
        if (value[j].controlid.toString() == 'lnkSaveOnly') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 134;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'btnsaveonly') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 135;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'btnProceed') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 136;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'btnCancelReq') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 137;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'btnOrder') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 138;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'btnAccession') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 139;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'btnCancelOrder') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 140;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'btnCancel') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 141;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'btnSentOut') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 142;
          this.horizontalTabSequence.push(value[j]);
        }

        else if (value[j].controlid.toString() == 'btnReject') {
          value[j].organizationid = orgid;
          value[j].configuredtabindex = 143;
          this.horizontalTabSequence.push(value[j]);
        }
      }

    }

  }

  //set the vertical value
  setDefalutValueVertical(data) {
    let orgid = sessionStorage.getItem('org_id')
    for (let j = 0; j < data.length; j++) {
      if (!data[j].sectionname || data[j].sectionname.toString().toLowerCase() == 'caseinformation' || data[j].sectionname.toString().toLowerCase() == 'case information') {
        if (data[j].controlid.toString() == 'ddlCaseProfile') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 1;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'ddlCaseType') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 2;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtOtherCaseType') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 3;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'ddlExternalLab') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 4
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtCollectionDate') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 5
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtCollectionTime' || data[j].controlid.toString() == 'ddlCollectionTime') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 6
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtRecievedDate') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 7
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtReceivedTime' || data[j].controlid.toString() == 'ddlReceivedTime') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 8
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'ddlLaboratory') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 9
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtOH') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 10
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'ddlPhysician') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 11
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtLabCaseNo') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 12
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'ddlSendInLab') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 13
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'ddlSendOutServices') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 14
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'ddlprocedure') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 15
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtOtherExtractionProcedure') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 16
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'ddlServices') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 17
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtRequisition') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 18
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtReferringPhysician') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 19
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtRefPhysicianFaxNo') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 20
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'chkReports') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 21
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtClientTrackingNo') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 22
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'ddlSendOutBillTo') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 23
          this.verticalTabSequence.push(data[j]);
        }
        else if (data[j].controlid.toString() == 'txtLmpDate') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 24
          this.verticalTabSequence.push(data[j]);
        }
        else if (data[j].controlid.toString() == 'chkResearch') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 25
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtNoJars') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 26
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'ddlSiteScheme') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 27
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtRefNumber') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 28
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtEncounter') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 29
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtICD1') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 30
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtICD2') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 31
          this.verticalTabSequence.push(data[j]);
        }
        else if (data[j].controlid.toString() == 'txtICD3') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 32
          this.verticalTabSequence.push(data[j]);
        }
        else if (data[j].controlid.toString() == 'txtICD4') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 33
          this.verticalTabSequence.push(data[j]);
        }
        else if (data[j].controlid.toString() == 'txtICD5') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 34
          this.verticalTabSequence.push(data[j]);
        }
        else if (data[j].controlid.toString() == 'txtICD6') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 35
          this.verticalTabSequence.push(data[j]);
        }
        else if (data[j].controlid.toString() == 'txtICD7') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 36
          this.verticalTabSequence.push(data[j]);
        }
        else if (data[j].controlid.toString() == 'txtICD8') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 37
          this.verticalTabSequence.push(data[j]);
        }
        else if (data[j].controlid.toString() == 'ddlBillTo') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 38
          this.verticalTabSequence.push(data[j]);
        }
        else if (data[j].controlid.toString() == 'ddlCasePriority') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 39
          this.verticalTabSequence.push(data[j]);
        }
      }

      else if (!data[j].sectionname || data[j].sectionname.toString().toLowerCase() == 'patient information' || data[j].sectionname.toString().toLowerCase() == 'patientinformation') {
        if (data[j].controlid.toString() == 'txtLastName') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 40;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'ddlGender') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 41;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtMedRecNo') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 42;
          this.verticalTabSequence.push(data[j]);
        }
        else if (data[j].controlid.toString() == 'txtPatientEmail') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 43;
          this.verticalTabSequence.push(data[j]);
        }
        else if (data[j].controlid.toString() == 'txtSuffix') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 44;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtFirstName') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 45;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtDateOfBirth') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 46;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtOrderNo') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 47;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'btnSearch') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 48;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtMiddleName') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 49;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtSSNNo') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 50;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtPatientHomePhone') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 51;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtPatientWorkPhone') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 52;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtAddress1') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 53;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtAddress2') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 54;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtCity') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 55;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'drpState') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 56;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtZip') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 57;
          this.verticalTabSequence.push(data[j]);
        }
      }

      else if (!data[j].sectionname || data[j].sectionname.toString().toLowerCase() == 'supplemental information' || data[j].sectionname.toString().toLowerCase() == 'supplementalinformation') {
        if (data[j].controlid.toString() == 'txtClinicalInfo') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 58;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtSpecial') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 60;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtSendOutNotes') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 61;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'ddlClinicalStages') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 62;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'txtPSARange') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 59;
          this.verticalTabSequence.push(data[j]);
        }

      }

      else if (!data[j].sectionname || data[j].sectionname.toString().toLowerCase() == 'insurance') {

        if (!data[j].subsectionname || data[j].subsectionname.toString().toLowerCase() == 'primary insurance') {
          if (data[j].controlid.toString() == 'SubscribeInfo_txtPrimaryRelationship') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 63;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtLastName1') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 64;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtDob1') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 65;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtGroupName1') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 66;
            this.verticalTabSequence.push(data[j]);
          }
          else if (data[j].controlid.toString() == 'SubscribeInfo_txtRespSuffix1') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 67;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtRespFirstName1') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 68;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtGender1') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 69;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtGroupNo1') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 70;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPrimaryPlanType') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 71;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtRespMiddleInitials1') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 72;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPolicyNo1') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 73;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPatientPolicyno1') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 74;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPayor1') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 75;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtAddress11') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 76;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtCity1') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 77;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtZip1') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 78;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtIC1') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 79;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtAddress12') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 80;
            this.verticalTabSequence.push(data[j]);
          }
          else if (data[j].controlid.toString() == 'SubscribeInfo_txtState1') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 81;
            this.verticalTabSequence.push(data[j]);
          }

        }

        else if (!data[j].subsectionname || data[j].subsectionname.toString().toLowerCase() == 'secondary insurance') {
          if (data[j].controlid.toString() == 'SubscribeInfo_txtSecondaryRelationship') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 82;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtLastName2') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 83;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtDob2') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 84;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtGroupName2') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 85;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtRespSuffix2') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 86;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtRespFirstName2') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 87;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtGender2') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 88;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtGroupNo2') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 89;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtSecondaryPlanType') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 90;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtRespMiddleInitials2') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 91;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPolicyNo2') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 92;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPatientPolicyno2') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 93;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPayor2') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 94;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtAddress21') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 95;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtCity2') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 96;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtZip2') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 97;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtIC2') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 98;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtAddress22') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 99;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtState2') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 100;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_drpInsuranceTypeCode2') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 101;
            this.verticalTabSequence.push(data[j]);
          }

        }

        else if (!data[j].subsectionname || data[j].subsectionname.toString().toLowerCase() == 'tertiary insurance') {
          if (data[j].controlid.toString() == 'SubscribeInfo_txtTertiayRelationship') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 102;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtLastName3') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 103;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtDob3') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 104;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtGroupName3') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 105;
            this.verticalTabSequence.push(data[j]);
          }
          else if (data[j].controlid.toString() == 'SubscribeInfo_txtRespSuffix3') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 106;
            this.verticalTabSequence.push(data[j]);
          }


          else if (data[j].controlid.toString() == 'SubscribeInfo_txtRespFirstName3') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 107;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtGender3') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 108;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtGroupNo3') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 109;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtTertiayPlanType') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 110;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtRespMiddleInitials3') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 111;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPolicyNo3') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 112;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPatientPolicyno3') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 113;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPayor3') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 114;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtAddress31') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 115;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtCity3') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 116;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtZip3') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 117;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtIC3') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 118;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtAddress32') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 119;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtState3') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 120;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_drpInsuranceTypeCode3') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 121;
            this.verticalTabSequence.push(data[j]);
          }

        }

        else if (!data[j].subsectionname || data[j].subsectionname.toString().toLowerCase() == 'guarantor') {
          if (data[j].controlid.toString() == 'SubscribeInfo_ddlPatientRelationship1') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 122;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeHomePhone') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 123;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeCity') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 124;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeLastName') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 125;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeWorkPhone') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 126;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_drpPatientRelativeState') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 127;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeFirstName') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 128;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeAddress1') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 129;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeZip') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 130;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeMiddleName') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 131;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeAddress2') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 132;
            this.verticalTabSequence.push(data[j]);
          }

          else if (data[j].controlid.toString() == 'SubscribeInfo_txtPatientRelativeSuffix') {
            data[j].organizationid = orgid;
            data[j].configuredtabindex = 133;
            this.verticalTabSequence.push(data[j]);
          }


        }
      }

      else if (!data[j].sectionname || data[j].sectionname.toString().toLowerCase() == 'button') {
        if (data[j].controlid.toString() == 'lnkSaveOnly') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 134;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'btnOrder') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 135;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'btnSentOut') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 136;
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'btnsaveonly') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 137
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'btnAccession') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 138
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'btnReject') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 139
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'btnProceed') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 140
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'btnCancelOrder') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 141
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'btnCancelReq') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 142
          this.verticalTabSequence.push(data[j]);
        }

        else if (data[j].controlid.toString() == 'btnCancel') {
          data[j].organizationid = orgid;
          data[j].configuredtabindex = 143
          this.verticalTabSequence.push(data[j]);
        }
      }
    }

  }

  async updateTabIndexOrder() {
    if (this.enableHorizontal && !this.enableVertical) {
      for (var i = 0; i < this.horizontalTabSequence.length; i++) {
        var horizontal = {
          "organizationid": this.horizontalTabSequence[i].organizationid,
          "pagetype": this.horizontalTabSequence[i].pagetype,
          "controllabelname": this.horizontalTabSequence[i].controllabelname,
          "sectionname": this.horizontalTabSequence[i].sectionname,
          "subsectionname": this.horizontalTabSequence[i].sectionname.toString().toLowerCase() == 'insurance' ? this.horizontalTabSequence[i].subsectionname : "NULL",
          "configuredtabindex": this.horizontalTabSequence[i].configuredtabindex,
          "controlid": this.horizontalTabSequence[i].controlid,
          "id": this.horizontalTabSequence[i].id,
          "type": 'Add'
        }
        this.backupData.push(horizontal);
      };
    }
    else if (!this.enableHorizontal && this.enableVertical) {
      for (var i = 0; i < this.verticalTabSequence.length; i++) {
        var vertical = {
          "organizationid": this.verticalTabSequence[i].organizationid,
          "pagetype": this.verticalTabSequence[i].pagetype,
          "controllabelname": this.verticalTabSequence[i].controllabelname,
          "sectionname": this.verticalTabSequence[i].sectionname,
          "subsectionname": this.verticalTabSequence[i].sectionname.toString().toLowerCase() == 'insurance' ? this.verticalTabSequence[i].subsectionname : "NULL",
          "configuredtabindex": this.verticalTabSequence[i].configuredtabindex,
          "controlid": this.verticalTabSequence[i].controlid,
          "id": this.verticalTabSequence[i].id,
          "type": 'Add'
        }
        this.backupData.push(vertical);
      };

    }
    else if (!this.enableHorizontal && !this.enableVertical) {
      let orgid = sessionStorage.getItem('org_id')
      for (var i = 0; i < this.json.length; i++) {
        var Default = {
          "organizationid": orgid,
          "pagetype": this.json[i].pagetype,
          "controllabelname": this.json[i].controllabelname,
          "sectionname": this.json[i].sectionname,
          "subsectionname": this.json[i].sectionname.toString().toLowerCase() == 'insurance' ? this.json[i].subsectionname : "NULL",
          "configuredtabindex": this.json[i].configuredtabindex,
          "controlid": this.json[i].controlid,
          "id": this.json[i].id,
          "type": 'Add'
        }
        this.backupData.push(Default);
      };
    }
    this.ngxService.start();
    if (this.backupData.length > 0) {
      await this.VitalHttpServices.updateTabIndex(this.backupData, this.destDeployment).subscribe(
        result => {
          if (!result.errors) {
            this.ngxService.stop();
            this.backupData = [];
            this.json = [];
            this.horizontalTabSequence = [];
            this.verticalTabSequence = [];
            this.auditDetails([], this.backupData, 'updated');
            if (result[0].NoteMessage.toString().match(/Duplicate/i)) {
              this._snackbar.open(result[0].NoteMessage, 'Close');
              return;
            }
            if (result[0].status.toString().match(/failure/i)) {
              this._snackbar.open(result[0].NoteMessage, 'Close');
              return;
            }
            this._snackbar.open(result[0].NoteMessage, 'Close');
            this.isDataChanged=false;
            this.enableHorizontal=false;
            this.enableVertical=false;
            this.resetTabIndex(false,null);
          }
        },
        error => {
          this.backupData = [];
          this.json = [];
          this.horizontalTabSequence = [];
          this.verticalTabSequence = [];
          this._snackbar.open('Error updating tab sequence', 'Close');
          this.ngxService.stop();
          console.error(error);
        }
      );
    }
    else {
      this.ngxService.stop();
    }

  }


  save(value: any, event: any, type: any) {
    this.isDataChanged=true;
    if (type == 'Horizontal') {
      this.horizontalTabSequence.forEach(v => {
        if (v.controlid.toString() == value.toString()) {
          let configuredtabindex = event.target.value == "0" ? null : event.target.value;
          configuredtabindex = configuredtabindex == '' ? 0 : configuredtabindex
          v.configuredtabindex = configuredtabindex
        }
      });
    }
    else if (type == 'Vertical') {
      this.verticalTabSequence.forEach(v => {
        if (v.controlid.toString() == value.toString()) {
          let configuredtabindex = event.target.value == "0" ? null : event.target.value;
          configuredtabindex = configuredtabindex == '' ? null : configuredtabindex
          v.configuredtabindex = configuredtabindex
        }
      });

    }
    else {
      var onevalue;
      onevalue = this.horizontalTabSequence.filter(v => v.controlid.toString() == value.toString());
      let configuredtabindex = event.target.value == "0" ? null : event.target.value;
      configuredtabindex = configuredtabindex == '' ? null : configuredtabindex
      onevalue[0].configuredtabindex = configuredtabindex;
      this.json.push(onevalue[0]);
    }

  }

  //allows only number
  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  arrayfilter() {
    this.caseProfile = ''
    this.caseType = ''
    this.otherCaseType = ''
    this.externalLaboratory = ''
    this.collectionDate = ''
    this.collectionTime = ''
    this.recievedDate = ''
    this.recievedTime = ''
    this.laboratory = ''
    this.hospitalFacility = ''
    this.orderingPhysician = ''
    this.labCase = ''
    this.sendInlaboratory = ''
    this.services = ''
    this.procedure = ''
    this.otherExtractionProcedure = ''
    this.Services1 = ''
    this.kitRequisition = ''
    this.referringPhysician = ''
    this.referringPhysicianFax = ''
    this.copyReport = ''
    this.tracking = ''
    this.soBillTo = ''
    this.noOfJars = ''
    this.siteSchema = ''
    this.reference = ''
    this.encounter = ''
    this.icd1 = ''
    this.icd2 = ''
    this.icd3 = ''
    this.icd4 = ''
    this.icd5 = ''
    this.icd6 = ''
    this.icd7 = ''
    this.icd8 = ''
    this.priority = ''
    this.lmpDate = ''
    this.billTo = ''
    this.forResearchUse = ""

    this.lastName = '';
    this.gender = '';
    this.emr = '';
    this.firstName = '';
    this.dob = '';
    this.pmsPws = '';
    this.middleName = '';
    this.ssn = '';
    this.homePhone = '';
    this.workPhone = '';
    this.address1 = '';
    this.address2 = '';
    this.city = '';
    this.state = '';
    this.zip = '';
    this.email = '';
    this.patSuffix = '';

    /////////////////////////////Supplementalthis.Informationthis.Default/////////////////////////
    this.clinicalInfo = '';
    this.specialInstructions = '';
    this.sendOutNote = '';
    this.clinicalStage = '';
    this.psavalue = '';

    /////////////////////////////Primarythis.Insurancethis.Default/////////////////////////
    this.pRelationship = '';
    this.pLastName = '';
    this.pDob = '';
    this.pGroupPlan = '';
    this.pFirstName = '';
    this.pGender = '';
    this.pGroup = '';
    this.pPlanType = '';
    this.pMiddleInitial = '';
    this.pPolicy = '';
    this.pPatientPolicy = '';
    this.pPayor = '';
    this.pAddress1 = '';
    this.pCity = '';
    this.pZip = '';
    this.pInsuranceCode = '';
    this.pAddress2 = '';
    this.pState = '';
    this.pSuffix = '';
    this.lookUp = '';


    /////////////////////////////Secondarythis.Insurancethis.Default/////////////////////////
    this.sRelationship = '';
    this.sLastName = '';
    this.sDob = '';
    this.sGroupPlan = '';
    this.sFirstName = '';
    this.sGender = '';
    this.sGroup = '';
    this.sPlanType = '';
    this.sMiddleInitial = '';
    this.sPolicy = '';
    this.sPatientPolicy = '';
    this.sPayor = '';
    this.sAddress1 = '';
    this.sCity = '';
    this.sZip = '';
    this.sInsuranceCode = '';
    this.sAddress2 = '';
    this.sState = '';
    this.sInsuranceTypeCode = '';
    this.sSuffix = '';


    /////////////////////////////Tertiarythis.Insurancethis.Default/////////////////////////
    this.tRelationship = '';
    this.tLastName = '';
    this.tDob = '';
    this.tGroupPlan = '';
    this.tFirstName = '';
    this.tGender = '';
    this.tGroup = '';
    this.tPlanType = '';
    this.tMiddleInitial = '';
    this.tPolicy = '';
    this.tPatientPolicy = '';
    this.tPayor = '';
    this.tAddress1 = '';
    this.tCity = '';
    this.tZip = '';
    this.tInsuranceCode = '';
    this.tAddress2 = '';
    this.tState = '';
    this.tInsuranceTypeCode = '';
    this.tSuffix = '';


    /////////////////////////////Guarantorthis.Insurancethis.Default/////////////////////////
    this.gRelationship = '';
    this.gLastName = '';
    this.gFirstName = '';
    this.gMiddleInitial = '';
    this.gHomePhone = '';
    this.gWorkPhone = '';
    this.gAddress1 = '';
    this.gCity = '';
    this.gZip = '';
    this.gAddress2 = '';
    this.gState = '';
    this.gSuffix = '';

    /////////////////////////////Buttonthis.Default/////////////////////////
    this.bSave = '';
    this.bSaveClose = '';
    this.bSaveProceed = '';
    this.bCancelRequistion = '';
    this.bOrder = '';
    this.bAccession = '';
    this.bCancelOrder = '';
    this.bReturn = '';
    this.bSendOutCase = '';
    this.bReject = '';

    this.headertext = "";
    this.caseheadertext = "";
    this.patientheadertext = "";
    this.supplementalHeadertext = "";
    this.PinsuranceHeadertext = "";
    this.SinsuranceHeadertext = "";
    this.TinsuranceHeadertext = "";
    this.GinsuranceHeadertext = "";
    this.ButtonHeadertext = "";
    this.Caseinfodata = [];
    this.Patientinfodata = [];
    this.Supplementalinfodata = [];
    this.primaryInsuranceData = [];
    this.secondaryInsuranceData = [];
    this.guarantorInsuranceData = [];
    this.TprimaryInsuranceData = [];
    this.Buttoninfodata = [];
    this.Boxdata;
    this.horizontalTabSequence=[]
    this.verticalTabSequence=[]
    this.caseinfoboxnumber = [];
    this.patientinfoboxnumber = [];
    this.supplementinfoboxnumber = [];
    this.pinsuranceinfoboxnumber = [];
    this.sinsuranceinfoboxnumber = [];
    this.tinsuranceinfoboxnumber = [];
    this.guaratorinfoboxnumber = [];
    this.buttoninfoboxnumber = [];
    this.backupData = [];
  }

  uploadFlags(event:any){
    this.uploadClicked=event.uploadbtn;
    this.isDataChanged=event.isDataChanged;
    let tempnotes='The tab order sequence is shown beside the field label(Default tab order sequence)';
    this.notes=event.notes?event.notes:tempnotes;
  }

  async resetTabIndex(page?, changePage?) {
    if(this.previousSelectedPage == page){
        return
    }
    if (!this.isDataChanged || changePage) {
      this.notes = 'The tab order sequence is shown beside the field label(Default tab order sequence)';
      this.enableHorizontal = false;
      this.enableVertical = false;
      this.previousSelectedPage = this.selectedpage;
      await this.arrayfilter();
      this.page = page;
      await this.getInputData(this.selectedpage);
      await this.getTabCondition();
      await this.CreateConfig();
      this.selectedpage = this.page?this.page:this.selectedpage;
    } else {
        const popUpHeader = "Confirmation";
        const popUpMessage = `There are changes made on this page.\nIf you exit this page you will lose your changes!\nDo you want to continue?`;
        const continueBtn = "Yes";
        const dialogRef = this.opendialog.openDialog(popUpHeader, popUpMessage, '', continueBtn, "No");
        dialogRef.afterClosed().subscribe(async res => {
          if (res) {
            this.page = page;
            await this.resetTabIndex(page, true);
            this.isDataChanged = false;
          } else {
            this.selectedpage = this.previousSelectedPage;
          }
        });

    }
  }


  getTabCondition(changePage?){
      if(this.page=='Requisition'){
        this.accessionPage=false;
        return true
      }
      else if(this.page == 'CP Requisition'){
        this.accessionPage=false;
        return true
      }else if(this.page == 'SendOut'){
        this.accessionPage=false;
        return true
      }else if(this.page == 'Accession'){
        this.accessionPage=false;
        return true
      }
  }

  toggle(data) {
    //this.getInputData();
    // this.arrayfilter();
    if (data == 'horiz') {
      this.notes = 'Tab order sequence is set horizontally'
      this.enableHorizontal = true
      this.enableVertical = false;
      this.verticalTabSequence = [];
      this.horizontalTabSequence = [];
      this.setDefalutValue(this.templateDatafordefault);
      this.isDataChanged=true

    }
    else {
      this.notes = 'Tab order sequence is set vertically'
      this.enableHorizontal = false
      this.enableVertical = true
      this.horizontalTabSequence = [];
      this.setDefalutValueVertical(this.verticalTabDefultData);
      this.isDataChanged=true
    }

  }

  // back button
  backSelect() {
    this.tabSequenceForm.patchValue({
      sectionname: '',
      controllabelname: ['', Validators.required],
      subsectionname: ['', Validators.required],
      configuredtabindex: ''
    });
    this.uploadClicked = false;
    this.noData = false;
    this.excelDataArray = [];
    this.showInvalidColumns = false;
    this.postUpload = false;
    this.bulkUpload = false
    this.gridShow = false;
    this.isDataChanged=false;
    this.resetTabIndex()
    this.resetFormErrors();
  }

  //form reset method
  resetFormErrors() {
    Object.keys(this.tabSequenceForm.controls).forEach(key => {
      this.tabSequenceForm.controls[key].setErrors(null)
    });
  }

  //Upload screen
  async loadUploadScreen() {
    this.uploadClicked = false;
    this.isDataChanged=false;
    if (!this.uploadBtn) {
      this.noDataFound = false;
      this.uploadClicked = true;
      this.gridShow = false;
      this.bulkUpload = true;
      this.gridwidth = 0
      let queryVariable = { tablename: 'OrganizationTabIndex', identity: "false", nullable: "false" };
      let query = this.SubMenuCardModel.GetQuery("getTableColumns");
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      await this.VitalHttpServices.GetData(queryResult).subscribe(data => {
        if (!data.errors) {
          if (data) {
            let allFields = {}
            for (let i = 0; i < data.data.allFields.length; i++) {
              allFields[data.data.allFields[i]['Column']] = ''
            }
            this.allFields[0] = allFields
            delete this.allFields[0]["ID"];
            delete this.allFields[0]["OrganizationID"];
            delete this.allFields[0]["OrganizationTabIndexGUID"];
            delete this.allFields[0]["SourceGUID"];
          }
        }
      }, error => {
        console.error(error);
      });
    }
  }
  //Upload templates
  downloadTemplate(type) {
    let filename = ""
    let excel = [{}];
    if (type == 'allFieldsCheck') {
      filename = 'Tab Focus_' + 'All Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      excel = this.allFields
      var ws = XLSX.utils.json_to_sheet(excel);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationTabOrderIndex');
      ws = XLSX.utils.json_to_sheet(this.sampleDataAllFields)
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      XLSX.writeFile(wb, filename);
    }
  }

  //sample data for excel
  sampleDataAllFields = [{
    PageType: 'Accession',
    ControlLabelName: 'First name',
    SectionName: 'Insurance',
    SubSectionName: 'primary Insurance',
    ConfiguredTabIndex: '1',
    ControlId: 'txtControlId'
  }]

  // Grid
  initGrid(grid, hitCount?) {
    if (hitCount) {
      if (!this.postUpload) {
        this.selector = new Selector(grid, {
          itemChecked: () => {
            this.selectedData = grid.rows.filter(r => r.isSelected);
          }
        });
      }
    }
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }

  // grid remove button
  gridRemove() {
    this.checkStatus = false;
    this.postUpload = false;
    this.showInvalidColumns = false;
    this.noData = false;
    this.noDataFound = false;
    this.enableActive = true;
    this.gridwidth = 0
    this.tabSequenceForm.patchValue({
      frmOrganization: ''
    })
    this.gridData = new CollectionView([]);
    // this.approveDisable();
  }

  //message validation color
  formateItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value.toString().includes('Mandatory')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value.toString().includes('Valid') || value.toString().includes('Successfully') || value.toString().includes('success') || value.toString().includes('approval')) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if (value.toString().includes('exist') || (value.toString().includes('Ignored'))) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }

  ExportExcel(gridData) {
    let excel = [];
    let flex = gridData;
    const view: any = flex.collectionView;
    let oldPgSize = view.pageSize
    view.pageSize = 0;
    flex.beginUpdate();
    let rows = flex.rows;
    flex.rows.find((e: any): any => {
      let primary = {}
      delete e._data['Action'];
      delete e._data['casetype'];
      delete e._data['type'];
      delete e._data['tablename'];
      delete e._data['organizationid'];
      delete e._data['copyflag'];
      delete e._data['slno'];
      for (let [key, value] of Object.entries(e._data)) {
        primary[key] = value;
      }
      excel.push(primary);
    });
    let filename = 'Tab Focus_' + sessionStorage.getItem('org_id').toString() + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + 'Tab Order Index');
    XLSX.writeFile(wb, filename);
    view.pageSize = oldPgSize;
    flex.endUpdate();
  }

  //approve button
  approveTabIndex(copyData?) {
    this.postUpload = false;
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (this.excelDataArray[i]["notes"] == "") {
          this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
          return;
        }
        else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && (this.excelDataArray[i]["notes"].toString().match(/already exists/i) || this.excelDataArray[i]["notes"].toString().match(/missing/i))) {
          errorcount++
        }
      }
      if ((this.showInvalidColumns)) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value === "null" || value === "" || value === null) {
              this.excelDataArray[i][key] = null;
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }
          }
        }
      }

      if (this.excelDataArray.some(va => !va.controlid || !va.controllabelname || !va.pagetype || !va.sectionname)) {
        this._snackbar.open('Some of the mandatory data is missing!', 'Close');
        return
      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      // copyData ? dataArray.some(va => va.notes.toString().toLowerCase().match(/already exists/i)) ? this._snackbar.open("Some selected " + this.templateDatafordefault.menuURL.toString().toLowerCase() + "s already exist", "Close") : null : null
      let sendjson = dataArray
      let context = copyData ? null : ''
      this.addUploadTabIndex(dataArray, sendjson, this.bulkUpload, context)
    }
  }
  //method for bulk upload
  addUploadTabIndex(dataArray: object[], sendjson, bulkUpload: boolean, context?) {
    this.ngxService.start();
    this.VitalHttpServices.updateTabIndex(sendjson, this.destDeployment).subscribe(result => {
      this.noDataFound = false;
      this.ngxService.stop();
      if (!result.errors) {
        this.auditDetails([], sendjson, 'saved');
        //this.commonService.auditDetails('Tab Focus','Tab Focus',null,sendjson, 'Upload', this.templateData, this.allFields);
        this.noData = false;
        dataArray.filter((e: any) => {
          result.filter(r => {
            if (r.slno.toString() === e.slno.toString()) {
              e.status = r.status
              e.notes = r.NoteMessage;
            }
          });
        });
        dataArray.forEach((va: any) => {
          if (va.notes.toString().toLowerCase().match(/already exists/i)) {
            va.status = 'Ignored'
          }
        })
        if (result.length > 0) {
          this.postUpload = true;
          let uploadresult = this.convertObjKeysToLower(dataArray);
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          this.AddGridData(uploadresult, 1);
        }
        else {
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open('Tab Order Index creation failed Please try again.', 'Close');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong.Please try again', 'Close');
      console.error(error)
    })
  }
  //delete row in the grid in upload
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    this.selectedData = this.excelDataArray
    this._snackbar.open('Deleted successfully', 'Close');
    if (grid.rows.length == 0) {
      this.gridRemove();
    }
  }
  //flag for copy as inactive
  checkValue(event) {
    if (event.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }

  //Upload file
  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect?.filter(va => va?.toString()?.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'organizationtabindex')
            validSheet.length > 0 ? this.convertToJson(validSheet[0]) : this.openModal();

          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }
  // validation in grid
  validateExcel(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    for (let k = 0; k < dataArray.length; k++) {
      if (!dataArray[k].controlid && !dataArray[k].controllabelname && !dataArray[k].sectionname && !dataArray[k].pagetype) {
        dataArray[k].notes = 'Mandatory field(s) is missing'
      }
    }
    this.VitalHttpServices.validateTabIndex(dataArray, this.destDeployment).subscribe(result => {
      if (!result.errors) {
        this.noData = false
        if (result.length > 0) {
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          dataArray.filter(e => {
            result.filter(r => {
              if (r.slno === e.slno) {
                e.notes = r.NoteMessage;
              }
            });
          });
          this.AddGridData(dataArray, 1);
          this.ngxService.stop();
        }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
      }
      this.noData = true;
    }, error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.log(error);
    });
  }
  //Append validation notes in the grid
  convertToJson(sheetname) {
    var worksheet;
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    if (tempExcelArr.length == 0) {
      this._snackbar.open("The uploaded excel does not contain any data", "Close");
      tempExcelArr = [];
      return;
    }
    let primary = {}
    let tempArray = []
    for (let i = 0; i < tempExcelArr.length; i++) {
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().match(/empty/i)) {
          if (key.toString().match(/sequence/i)) {
            if (value && value.toString() == '0') {
              value = 0;
            }
            else {
              value = value;
            }
          }
          else {
            if (value == null) {
              value = null;
            }
            else if (value === '') {
              value = ''
            }
            else if (value.toString().match(/null/i)) {
              value = 'null'
            }
          }
          primary[key] = value;
        }
      }
      tempArray.push(primary)
      primary = {}
    }
    tempExcelArr = tempArray;
    this.sheetHeader = [];
    let temp = [];
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    temp[0].reverse()
    temp[0].push("Notes")
    this.sheetHeader = temp[0];
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = []
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    let i = 1;
    this.excelDataArray.find(d => {
      Object.assign(d, { slno: i, notes: '', tablename: 'OrganizationTabIndex', organizationid: sessionStorage.getItem('org_id').toString(), type: 'Upload' });
      i++;
    });
    this.validateExcel(this.excelDataArray);
  }
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }
  // Close Modal

  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if (result) {
      this.convertToJson(result)
    }
  }
  //add grid in upload and copy screen
  AddGridData(data, hitCount) {
    this.ngxService.start()
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (!this.excelDataArray[i]["status"] || this.excelDataArray[i]["status"] == '') {
        this.excelDataArray[i]["status"] = 'Ignored'
      }
    }
    this.noDataFound = false
    let gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().toLowerCase().match(/empty/i) && hitCount == 1) {
              if (key == 'sequence') {
                if (value == 0) {
                  if (primary['notes'] != 'Mandatory field is missing!' && primary['notes'] != 'Null values exist!') {
                    primary['notes'] = 'Zero is not allowed for Sequence';
                  }
                }
                else {
                  let pattern = /[0-9]+/
                  if (primary['notes'] != 'Mandatory field is missing!' && primary['notes'] != 'Null values exist!') {
                    primary['notes'] = value && !pattern.test(value.toString()) ? 'Sequence only takes numbers' : primary['notes'];
                  }
                }
              }
            }
            primary[key] = value;
          }
          gridArray.push(primary);
        }
        this.excelDataArray = gridArray;
        this.showDelete = true
        if (this.postUpload) {
          this.showDelete = false
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        this.selectedData = gridArray;
        this.gridData = new CollectionView(gridArray);
        this.gridwidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridwidth > 1300) {
          this.gridwidth = 1300;
        }
      }
      this.ngxService.stop()
    }
  }

  //disbaled approved button
  disableApprovebtn() {
    let existsCount = 0;
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (!this.excelDataArray[i]['notes'] && (this.excelDataArray[i]['notes'].toString().match(/Mandatory field is missing/i)) || !this.excelDataArray[i]['notes'].toString().match(/valid/i)) {
        existsCount++;
      }
    }
    if (existsCount == this.excelDataArray.length) {
      return true
    }
    return false;
  }

  setActiveClass() {
    this.statusClass = 'active';
  }

  // default patching
  patchDbToDeafult() {
    //CaseInformation Defualt
    for (var j = 0; j < this.Caseinfodata.length; j++) {
      if (this.Caseinfodata[j].controlid == 'ddlCaseProfile') {
        this.caseProfile = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'ddlCaseType') {
        this.caseType = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtOtherCaseType') {
        this.otherCaseType = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'ddlExternalLab') {
        this.externalLaboratory = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtCollectionDate') {
        this.collectionDate = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtCollectionTime' || this.Caseinfodata[j].controlid == 'ddlCollectionTime') {
        this.collectionTime = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtRecievedDate') {
        this.recievedDate = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtReceivedTime' || this.Caseinfodata[j].controlid == 'ddlReceivedTime') {
        this.recievedTime = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'ddlLaboratory') {
        this.laboratory = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtOH') {
        this.hospitalFacility = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'ddlPhysician') {
        this.orderingPhysician = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtLabCaseNo') {
        this.labCase = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'ddlSendInLab') {
        this.sendInlaboratory = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'ddlSendOutServices') {
        this.services = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'ddlprocedure') {
        this.procedure = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtOtherExtractionProcedure') {
        this.otherExtractionProcedure = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'ddlServices') {
        this.Services1 = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtRequisition') {
        this.kitRequisition = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtReferringPhysician') {
        this.referringPhysician = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtRefPhysicianFaxNo') {
        this.referringPhysicianFax = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'chkReports') {
        this.copyReport = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtClientTrackingNo') {
        this.tracking = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'ddlSendOutBillTo') {
        this.soBillTo = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtNoJars') {
        this.noOfJars = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'ddlSiteScheme') {
        this.siteSchema = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtRefNumber') {
        this.reference = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtEncounter') {
        this.encounter = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtICD1') {
        this.icd1 = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtICD2') {
        this.icd2 = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtICD3') {
        this.icd3 = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtICD4') {
        this.icd4 = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtICD5') {
        this.icd5 = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtICD6') {
        this.icd6 = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtICD7') {
        this.icd7 = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtICD8') {
        this.icd8 = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'ddlCasePriority') {
        this.priority = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'ddlBillTo') {
        this.billTo = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'txtLmpDate') {
        this.lmpDate = this.Caseinfodata[j].configuredtabindex;
      }
      else if (this.Caseinfodata[j].controlid == 'chkResearch') {
        this.forResearchUse = this.Caseinfodata[j].configuredtabindex;
      }
    }

    //Patient Information Default
    for (var k = 0; k < this.Patientinfodata.length; k++) {
      if (this.Patientinfodata[k].controlid == 'txtLastName') {
        this.lastName = this.Patientinfodata[k].configuredtabindex;
      }
      else if (this.Patientinfodata[k].controlid == 'ddlGender') {
        this.gender = this.Patientinfodata[k].configuredtabindex;
      }
      else if (this.Patientinfodata[k].controlid == 'txtMedRecNo') {
        this.emr = this.Patientinfodata[k].configuredtabindex;
      }
      else if (this.Patientinfodata[k].controlid == 'txtFirstName') {
        this.firstName = this.Patientinfodata[k].configuredtabindex;
      }
      else if (this.Patientinfodata[k].controlid == 'txtDateOfBirth') {
        this.dob = this.Patientinfodata[k].configuredtabindex;
      }
      else if (this.Patientinfodata[k].controlid == 'txtOrderNo') {
        this.pmsPws = this.Patientinfodata[k].configuredtabindex;
      }
      else if (this.Patientinfodata[k].controlid == 'txtMiddleName') {
        this.middleName = this.Patientinfodata[k].configuredtabindex;
      }
      else if (this.Patientinfodata[k].controlid == 'txtSSNNo') {
        this.ssn = this.Patientinfodata[k].configuredtabindex;
      }
      else if (this.Patientinfodata[k].controlid == 'txtPatientHomePhone') {
        this.homePhone = this.Patientinfodata[k].configuredtabindex;
      }
      else if (this.Patientinfodata[k].controlid == 'txtPatientWorkPhone') {
        this.workPhone = this.Patientinfodata[k].configuredtabindex;
      }
      else if (this.Patientinfodata[k].controlid == 'txtAddress1') {
        this.address1 = this.Patientinfodata[k].configuredtabindex;
      }
      else if (this.Patientinfodata[k].controlid == 'txtAddress2') {
        this.address2 = this.Patientinfodata[k].configuredtabindex;
      }
      else if (this.Patientinfodata[k].controlid == 'txtCity') {
        this.city = this.Patientinfodata[k].configuredtabindex;
      }
      else if (this.Patientinfodata[k].controlid == 'drpState') {
        this.state = this.Patientinfodata[k].configuredtabindex;
      }
      else if (this.Patientinfodata[k].controlid == 'txtZip') {
        this.zip = this.Patientinfodata[k].configuredtabindex;
      }
      else if (this.Patientinfodata[k].controlid == 'txtPatientEmail') {
        this.email = this.Patientinfodata[k].configuredtabindex;
      }
      else if (this.Patientinfodata[k].controlid == 'txtSuffix') {
        this.patSuffix = this.Patientinfodata[k].configuredtabindex;
      }
      else if (this.Patientinfodata[k].controlid == 'btnSearch') {
        this.lookUp = this.Patientinfodata[k].configuredtabindex;
      }
    }

    //Supplemental Information Default
    for (var l = 0; l < this.Supplementalinfodata.length; l++) {
      if (this.Supplementalinfodata[l].controlid == 'txtClinicalInfo') {
        this.clinicalInfo = this.Supplementalinfodata[l].configuredtabindex;
      }
      else if (this.Supplementalinfodata[l].controlid == 'ddlClinicalStages') {
        this.clinicalStage = this.Supplementalinfodata[l].configuredtabindex;
      }
      else if (this.Supplementalinfodata[l].controlid == 'txtPSARange') {
        this.psavalue = this.Supplementalinfodata[l].configuredtabindex;
      }
      else if (this.Supplementalinfodata[l].controlid == 'txtSendOutNotes') {
        this.sendOutNote = this.Supplementalinfodata[l].configuredtabindex;
      }
      else if (this.Supplementalinfodata[l].controlid == 'txtSpecial') {
        this.specialInstructions = this.Supplementalinfodata[l].configuredtabindex;
      }
    }

    //Primary Insurance Default
    for (var m = 0; m < this.primaryInsuranceData.length; m++) {
      if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtPrimaryRelationship') {
        this.pRelationship = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtLastName1') {
        this.pLastName = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtDob1') {
        this.pDob = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtGroupName1') {
        this.pGroupPlan = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtRespFirstName1') {
        this.pFirstName = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtGender1') {
        this.pGender = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtGroupNo1') {
        this.pGroup = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtPrimaryPlanType') {
        this.pPlanType = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtRespMiddleInitials1') {
        this.pMiddleInitial = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtPolicyNo1') {
        this.pPolicy = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtPatientPolicyno1') {
        this.pPatientPolicy = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtPayor1') {
        this.pPayor = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtAddress11') {
        this.pAddress1 = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtCity1') {
        this.pCity = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtZip1') {
        this.pZip = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtIC1') {
        this.pInsuranceCode = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtAddress12') {
        this.pAddress2 = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtState1') {
        this.pState = this.primaryInsuranceData[m].configuredtabindex;
      }
      else if (this.primaryInsuranceData[m].controlid == 'SubscribeInfo_txtRespSuffix1') {
        this.pSuffix = this.primaryInsuranceData[m].configuredtabindex;
      }
    }

    //Secondary Insurance Default
    for (var n = 0; n < this.secondaryInsuranceData.length; n++) {
      if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtSecondaryRelationship') {
        this.sRelationship = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtLastName2') {
        this.sLastName = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtDob2') {
        this.sDob = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtGroupName2') {
        this.sGroupPlan = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtRespFirstName2') {
        this.sFirstName = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtGender2') {
        this.sGender = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtGroupNo2') {
        this.sGroup = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtSecondaryPlanType') {
        this.sPlanType = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtRespMiddleInitials2') {
        this.sMiddleInitial = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtPolicyNo2') {
        this.sPolicy = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtPatientPolicyno2') {
        this.sPatientPolicy = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtPayor2') {
        this.sPayor = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtAddress21') {
        this.sAddress1 = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtCity2') {
        this.sCity = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtZip2') {
        this.sZip = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtIC2') {
        this.sInsuranceCode = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtAddress22') {
        this.sAddress2 = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtState2') {
        this.sState = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_drpInsuranceTypeCode2') {
        this.sInsuranceTypeCode = this.secondaryInsuranceData[n].configuredtabindex;
      }
      else if (this.secondaryInsuranceData[n].controlid == 'SubscribeInfo_txtRespSuffix2') {
        this.sSuffix = this.secondaryInsuranceData[n].configuredtabindex;
      }
    }

    //Tertiary Insurance Default
    for (var o = 0; o < this.TprimaryInsuranceData.length; o++) {
      if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtTertiayRelationship') {
        this.tRelationship = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtLastName3') {
        this.tLastName = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtDob3') {
        this.tDob = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtGroupName3') {
        this.tGroupPlan = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtRespFirstName3') {
        this.tFirstName = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtGender3') {
        this.tGender = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtGroupNo3') {
        this.tGroup = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtTertiayPlanType') {
        this.tPlanType = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtRespMiddleInitials3') {
        this.tMiddleInitial = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtPolicyNo3') {
        this.tPolicy = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtPatientPolicyno3') {
        this.tPatientPolicy = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtPayor3') {
        this.tPayor = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtAddress31') {
        this.tAddress1 = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtCity3') {
        this.tCity = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtZip3') {
        this.tZip = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtIC3') {
        this.tInsuranceCode = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtAddress32') {
        this.tAddress2 = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtState3') {
        this.tState = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_drpInsuranceTypeCode3') {
        this.tInsuranceTypeCode = this.TprimaryInsuranceData[o].configuredtabindex;
      }
      else if (this.TprimaryInsuranceData[o].controlid == 'SubscribeInfo_txtRespSuffix3') {
        this.tSuffix = this.TprimaryInsuranceData[o].configuredtabindex;
      }
    }

    //Guarantor Insurance Default
    for (var p = 0; p < this.guarantorInsuranceData.length; p++) {
      if (this.guarantorInsuranceData[p].controlid == 'SubscribeInfo_ddlPatientRelationship1') {
        this.gRelationship = this.guarantorInsuranceData[p].configuredtabindex;
      }
      else if (this.guarantorInsuranceData[p].controlid == 'SubscribeInfo_txtPatientRelativeHomePhone') {
        this.gHomePhone = this.guarantorInsuranceData[p].configuredtabindex;
      }
      else if (this.guarantorInsuranceData[p].controlid == 'SubscribeInfo_txtPatientRelativeCity') {
        this.gCity = this.guarantorInsuranceData[p].configuredtabindex;
      }
      else if (this.guarantorInsuranceData[p].controlid == 'SubscribeInfo_txtPatientRelativeLastName') {
        this.gLastName = this.guarantorInsuranceData[p].configuredtabindex;
      }
      else if (this.guarantorInsuranceData[p].controlid == 'SubscribeInfo_txtPatientRelativeWorkPhone') {
        this.gWorkPhone = this.guarantorInsuranceData[p].configuredtabindex;
      }
      else if (this.guarantorInsuranceData[p].controlid == 'SubscribeInfo_drpPatientRelativeState') {
        this.gState = this.guarantorInsuranceData[p].configuredtabindex;
      }
      else if (this.guarantorInsuranceData[p].controlid == 'SubscribeInfo_txtPatientRelativeFirstName') {
        this.gFirstName = this.guarantorInsuranceData[p].configuredtabindex;
      }
      else if (this.guarantorInsuranceData[p].controlid == 'SubscribeInfo_txtPatientRelativeAddress1') {
        this.gAddress1 = this.guarantorInsuranceData[p].configuredtabindex;
      }
      else if (this.guarantorInsuranceData[p].controlid == 'SubscribeInfo_txtPatientRelativeZip') {
        this.gZip = this.guarantorInsuranceData[p].configuredtabindex;
      }
      else if (this.guarantorInsuranceData[p].controlid == 'SubscribeInfo_txtPatientRelativeMiddleName') {
        this.gMiddleInitial = this.guarantorInsuranceData[p].configuredtabindex;
      }
      else if (this.guarantorInsuranceData[p].controlid == 'SubscribeInfo_txtPatientRelativeAddress2') {
        this.gAddress2 = this.guarantorInsuranceData[p].configuredtabindex;
      }
      else if (this.guarantorInsuranceData[p].controlid == 'SubscribeInfo_txtPatientRelativeSuffix') {
        this.gSuffix = this.guarantorInsuranceData[p].configuredtabindex;
      }
    }

    //Button Default
    for (var q = 0; q < this.Buttoninfodata.length; q++) {
      if (this.Buttoninfodata[q].controlid == 'lnkSaveOnly') {
        this.bSave = this.Buttoninfodata[q].configuredtabindex;
      }
      else if (this.Buttoninfodata[q].controlid == 'btnOrder') {
        this.bOrder = this.Buttoninfodata[q].configuredtabindex;
      }
      else if (this.Buttoninfodata[q].controlid == 'btnSentOut') {
        this.bSendOutCase = this.Buttoninfodata[q].configuredtabindex;
      }
      else if (this.Buttoninfodata[q].controlid == 'btnsaveonly') {
        this.bSaveClose = this.Buttoninfodata[q].configuredtabindex;
      }
      else if (this.Buttoninfodata[q].controlid == 'btnAccession') {
        this.bAccession = this.Buttoninfodata[q].configuredtabindex;
      }
      else if (this.Buttoninfodata[q].controlid == 'btnReject') {
        this.bReject = this.Buttoninfodata[q].configuredtabindex;
      }
      else if (this.Buttoninfodata[q].controlid == 'btnProceed') {
        this.bSaveProceed = this.Buttoninfodata[q].configuredtabindex;
      }
      else if (this.Buttoninfodata[q].controlid == 'btnCancelOrder') {
        this.bCancelOrder = this.Buttoninfodata[q].configuredtabindex;
      }
      else if (this.Buttoninfodata[q].controlid == 'btnCancelReq') {
        this.bCancelRequistion = this.Buttoninfodata[q].configuredtabindex;
      }
      else if (this.Buttoninfodata[q].controlid == 'btnCancel') {
        this.bReturn = this.Buttoninfodata[q].configuredtabindex;
      }
    }
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   this.CreateConfig()
  // }


  CreateConfig(){
    let obj: any;
      obj = {
        entityid: sessionStorage.getItem('org_id'),
        entytitype: 2,
        ConfigName: "EnableTabindexForSection",
        tableName: "VaEntityExtAttributes",
        scope: 3
      }
    this.ngxService.start();
    this.VitalHttpServices.getVAEntityAttribute(obj, sessionStorage.getItem('deploymentKey')).toPromise().then((res) => {
      this.ngxService.stop();
      if(res && res.Success){
        let data = JSON.parse(res.Message);
        this.tempTabFlagObj={
          "ExtAttrValue1": data[0].All_Locations_Value_1.toLowerCase()=='true' ?true:false,
          "ExtAttrValue2": "Not set",
          "configid": data[0].id,     //"40782",
          "entityid": 3,
          "entytitype": 2,
          "description": data[0].Description,        //"Enable Tab index For Section",
          "AttributeName": data[0].refconfigname,    //"EnableTabindexForSection",
          "id": data[0].OrgVeId?data[0].OrgVeId:null,
          "tableName": "VaEntityExtAttributes"
        }
        if(data[0].All_Locations_Value_1.toLowerCase()=='true'){
         this.enableTabFocus = true;
         this.activitytrackerObjForTabFlag["Enable tab focus"].oldValue='Yes'
        }else{
          this.enableTabFocus = false;
          this.activitytrackerObjForTabFlag["Enable tab focus"].oldValue='No'
        }
      }
    },  error => {
      this.ngxService.stop();
      this._snackbar.open("An error occurred while processing your request", "Failed");
    });
  }

  updatetabFlag(event) {
    this.tempTabFlagObj.ExtAttrValue1 = event.target.checked;
    //this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
    if(this.tempTabFlagObj.id == null){
      let obj={
          "ExtAttrValue1":  event.target.checked,
          "ExtAttrValue2": "Not set",
          "configid": this.tempTabFlagObj.id,     //"40782",
          "entityid": sessionStorage.getItem('org_id'),
          "entytitype": 2,
          "description":"Enable Tab index For Section",        //"Enable Tab index For Section",
          "AttributeName": "EnableTabindexForSection",    //"EnableTabindexForSection",
          "id": -1,
          "tableName": "VaEntityExtAttributes"
      }
      this.VitalHttpServices.createVAEntityAttribute(obj, this.destDeployment).subscribe((res) => {
        if (res && res[2] == 'True') {
          this.enableTabFocus=event.target.checked;
          this._snackbar.open(res[1], 'Close');
          let transactionid=this.commonService.generateGuid();
          this.activitytrackerObjForTabFlag["Enable tab focus"].newValue=event.target.checked?'Yes':'No';;
          this.createActivityObject('','Configuration',"Tab Focus",'Create',this.activitytrackerObjForTabFlag,transactionid)
          this.CreateConfig()
        }
        this.ngxService.stop();

      }, error => {
        // Change position in view level if update fail
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", "Failed");
      });
    }else{
      this.VitalHttpServices.UpdateVAEntityAttribute(this.tempTabFlagObj, this.destDeployment).subscribe((res) => {
        if (res && res.Success == true) {
          this.enableTabFocus=event.target.checked;
          this._snackbar.open(res.Message, 'Close');
          let transactionid=this.commonService.generateGuid();
          this.activitytrackerObjForTabFlag["Enable tab focus"].newValue=event.target.checked?'Yes':'No';
          this.createActivityObject('','Configuration',"Tab Focus",'Edit',this.activitytrackerObjForTabFlag,transactionid)
          this.CreateConfig()
        }
        else if (res.Success != true) {
          this._snackbar.open(res.Message, 'Close');
        }
        this.ngxService.stop();
      }, error => {
        // Change position in view level if update fail
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", "Failed");
      });
    }

  }


  createActivityObject(entityid, changedValue, entityType, actionType, obj, transactionId, uploadedFile?: any) {
    try {
      let temptype=actionType=='Edit'?"Modified":"Created"
      let internalUserRegex = /^\w+([\.-]?\w+)@starmarkit|@vitalaxis(\.\w{2,3})+$/;
      let date = new Date();
      let entityTypeContext: any
      let deployid = sessionStorage.getItem('DeploymentKey')
      this.activityService.getActivitySession.subscribe(activityTab => entityTypeContext = activityTab);

      this.createActivityInput.productId = this.VitalHttpServices.GetDxurlBasedOnDeployment(deployid).replace(/(^\w+:|^)\/\//, '');
      this.createActivityInput.product = 'VitalDx';
      this.createActivityInput.customerId = sessionStorage.getItem('org_id');
      this.createActivityInput.customerName = sessionStorage.getItem('Org_Name');
      this.createActivityInput.entityType = entityType;
      this.createActivityInput.actionType = 'audit';
      this.createActivityInput.userName = localStorage.getItem('user_name');
      this.createActivityInput.userId = localStorage.getItem('UserEmail');
      this.createActivityInput.entityId = entityid;
      this.createActivityInput.locationId = sessionStorage.getItem('Locationid');
      this.createActivityInput.locationName = sessionStorage.getItem('locationName');
      this.createActivityInput.isInternal = internalUserRegex.test(localStorage.getItem('UserEmail').toLowerCase());
      this.createActivityInput.transactionId = transactionId;
      this.createActivityInput.ticketId = 'Tic' + this.datePipe.transform(date, 'dd-MM-yyyyHH:mm a');
      this.createActivityInput.ticketInfo = actionType + " " + entityType;
      this.createActivityOperations.extendedAttributes = obj;
      this.createActivityOperations.attachments = (uploadedFile == null ? null : [uploadedFile]);
      this.createActivityInput.operation = this.createActivityOperations;
      this.createActivityInput.context = [{'key':'parentMenu','value':"Tab Focus"}];
      this.createActivityOperations.info = temptype+" Tab Focus "+changedValue;
      if (Object.keys(this.createActivityOperations.extendedAttributes).length != 0 || uploadedFile != null) {
        this.VitalHttpServices.CreateActivity(this.createActivityInput)
          .subscribe((res) => {
           });
      }
    } catch (error) {
      console.error("Error occured while creating activity");
    }
  }

}
