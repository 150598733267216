<div class="modal-dialog">
    <div class="modal-content" style="margin-top: 60px !important;width: 44vw; right: 9%;" >
        <!-- <div class="modal-header admin-model-header mt-2 listingModalHeader homepage-header">
        <h3 class="modal-title w-100 admin-model-header-txt">
            <strong class="header-View"> ACS Search Optimization</strong>

        </h3>

        <span type="button" data-dismiss="modal" (click)="SetConfigClose()" class="mr-2">
            <img src="../../../../assets/icons/icons8-cancel-48.svg" alt="Close"
                class="material-icons md-24 cursor cancel-icon-black" title="Close">
        </span>

        </div> -->
              <!-- Modal Header -->
      <div class="modal-header admin-model-header">
        <h3 class="modal-title w-100 admin-model-header-txt">Manage Customer Type</h3>
        <span type="button" data-dismiss="modal" (click)="SetConfigClose()" class="mr-2">
          <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
            title="Close">
        </span>
      </div>

        <div class="row col-sm-12 p-0 ml-0 mb-4 mt-4">
            <!-- Deployment Field -->
            <mat-form-field class="col-sm-4 example-additional-selection" appearance="outline">
                <mat-label>Deployment<span class="error-msg">*</span></mat-label>
                <mat-select disableOptionCentering type="text" matInput [(ngModel)]="selectedDeployment">
                    <mat-option class="mat-opt-align" *ngFor="let i of DeploymentList" value="{{i}}"
                        (onSelectionChange)="selectDeployment($event,i)">
                        <span>{{ i }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Group Field -->
            <mat-form-field class="col-sm-6 example-additional-selection" appearance="outline">
                <mat-label>Group <span class="error-msg">*</span></mat-label>
                <div class="d-flex">
                <input type="text" matInput [(ngModel)]="organizationInput"
                    (click)="filterOrganizations(organizationInput)" (keyup)="filterOrganizations(organizationInput)"
                    [matAutocomplete]="orgauto" placeholder="Search here ..." style="width: 95%;"
                    [disabled]="disableOrg">
                <mat-autocomplete #orgauto="matAutocomplete">
                    <mat-option *ngFor="let show of searchResult" [value]="show.organizationname"
                        (onSelectionChange)="selectedOrganization($event, show)">
                        <span>{{ show.organizationname }} ({{ show.organizationid }})</span>
                    </mat-option>
                </mat-autocomplete>
                        <mat-icon class="icons" matSuffix mat-icon-button (click)="filterOrganizations(organizationInput)">keyboard_arrow_down</mat-icon>
                        <mat-icon class="icons" *ngIf="organizationInput" matSuffix mat-icon-button
                        aria-label="Clear" title="Reset Search" (click)="clearOrganization()">close</mat-icon>
                </div>
            </mat-form-field>
        </div>
        <div *ngIf="showFlags" class="toggle">
            <div class="badgeAlignment">
                <span class="pb-2 mr-2"> IsCustomer: </span>
                <label class="ct-tag-toggle mt-1 mb-0" [ngClass]="{'disabled': isSetUpPending || isTestOrganization}">
                    <input type="checkbox" [(ngModel)]="isCustomer" name="isCustomer"
                        (change)="onCheckChanges(isCustomer ,'isCustomer')"
                        [value]="isCustomer == (false || 0 || null|| '') ? 0 : 1" [disabled]="isSetUpPending || isTestOrganization">
                    <span class="slider" [ngClass]="{'disabled': isSetUpPending || isTestOrganization}"></span>
                </label>
                <span class="pb-2 mr-2 ml-5"> IsOrgSetupPending: </span>
                <label class="ct-tag-toggle mt-1 mb-0" [ngClass]="{'disabled': isCustomer || isTestOrganization}">
                    <input type="checkbox" [(ngModel)]="isSetUpPending" name="isSetUpPending"
                        (change)="onCheckChanges(isSetUpPending,'isSetUpPending')"
                        [value]="isSetUpPending == (false || 0 || null|| '') ? 0 : 1" [disabled]="isCustomer || isTestOrganization">
                    <span class="slider" [ngClass]="{'disabled': isCustomer || isTestOrganization}"></span>
                </label>
                <span class="pb-2 mr-2 ml-5"> IsTestOrganization: </span>
                <label class="ct-tag-toggle mt-1 mb-0" [ngClass]="{'disabled': isSetUpPending || isCustomer}">
                    <input type="checkbox" [(ngModel)]="isTestOrganization" name="isTestOrganization"
                        (change)="onCheckChanges(isTestOrganization,'isTestOrganization')"
                        [value]="isTestOrganization == (false || 0 || null|| '') ? 0 : 1" [disabled]="isCustomer || isSetUpPending">
                    <span class="slider" [ngClass]="{'disabled': isSetUpPending || isCustomer}"></span>
                </label>
            </div>
            <div class="row mt-5 ml-1 mr-1">
                <div class="col-sm-8 mb-2 button-wrap">
                    <button mat-raised-button type="button" class="admin-btn-success mr-4" (click)="SetConfigClose()">
                        Cancel
                    </button>
                    <button type="submit" mat-raised-button class="admin-btn-success" (click)="SaveUpdatedFlags()">
                        Save
                    </button>
                </div>
            </div>
        </div>

    </div>
</div>