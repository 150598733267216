<span class="tab-seq2">
<div *ngIf="!uploadClicked">
    <!-- vertical,horizatol and options -->
    <div class="col-sm-3 p-0">
        <span class="col-md-9 d-flex p-0">
            <span class="mr-2 opt-hover" [ngClass]="{ 'disabled': !tabindexFlag }">
                <div *ngIf="enableHorizontal " class="active">
                    <i class="fa fa-align-justify" aria-hidden="true"></i>
                    <span class="cursor " matTooltip="The tab order sequence will be set Horizontally" (click)="toggle('horiz')"
                        [readonly]="!tabindexFlag"> Horizontal</span>
                </div>
                <div *ngIf="!enableHorizontal ">
                    <i class="fa fa-align-justify" aria-hidden="true"></i>
                    <span class="cursor " matTooltip="The tab order sequence will be set Horizontally" (click)="toggle('horiz')"
                        [readonly]="!tabindexFlag"> Horizontal</span>
                </div>
            </span>

            <span class="opt-hover" [ngClass]="{ 'disabled': !tabindexFlag }">
                <div *ngIf="!enableVertical">
                    <i class="fa fa-align-justify verticalvalue" aria-hidden="true"></i>
                    <span class="cursor" matTooltip="The tab order sequence will be set Vertically" (click)="toggle('vert')">
                        Vertical</span>
                </div>
                <div *ngIf="enableVertical" class="active">
                    <i class="fa fa-align-justify verticalvalue" aria-hidden="true"></i>
                    <span class="cursor" matTooltip="The tab order sequence will be set Vertically" (click)="toggle('vert')">
                        Vertical</span>
                </div>
            </span>

        </span>
    </div>

    <!-- case information -->
    <div class="col-sm-12 d-flex mt-3 p-0" *ngIf="caseInformationFields?.length >0">
        <div class="col-sm-2 color">
            <strong>Case Information</strong>
        </div>
        <span class="col-sm-9 pl-0 mt-1">
            <hr class="border-line">
        </span>
        <div class="col-sm-1 button-wrap">
            <span *ngIf="HCmoreHide">
                <em class="fa fa-chevron-down mt-1 more-arrow" (click)="HCmoreHide=false"></em>
            </span>
            <span *ngIf="!HCmoreHide" (click)="HCmoreHide">
                <em class="fa fa-chevron-up mt-1 more-arrow" (click)="HCmoreHide=true"></em>
            </span>
        </div>
    </div>
    <div class="col-sm-12  row d-flex rowData" *ngIf="!HCmoreHide">
        <div *ngFor="let fields of caseInformationFields" [ngClass]="fields.classname ? fields.classname  : 'col-sm-4'">
            <form *ngIf="fields.controllabelname" class="row d-flex box-align label-hor">
                <div class="row col-sm-12  p-1">
                    <span [ngClass]="fields.classname =='col-sm-2' ? 'col-sm-10 p-1 pl-3' : 'col-sm-10 p-1 pl-3'">
                        {{fields.controllabelname}}
                    </span>
                    <span [ngClass]="fields.classname =='col-sm-2' ? 'col-sm-2 fr px-1' : 'col-sm-2 fr px-1'">
                        <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3" (change)="save(fields,$event,displaytype)"
                            value="{{fields.indexValue}}" [disabled]="isButtonEnable(tabindexFlag,rbacObject.editBtn)">
                    </span>
                </div>
            </form>
        </div>
    </div>

    <!-- patient information -->
    <div class="col-sm-12 d-flex mt-3 p-0" *ngIf="patientInformation?.length >0">
        <div class="col-sm-3 color">
            <strong>Patient Information</strong>
        </div>
        <span class="col-sm-8 pl-0 mt-1">
            <hr class="border-line">
        </span>
        <div class="col-sm-1 button-wrap">
            <span *ngIf="patientInfo">
                <em class="fa fa-chevron-down mt-1 more-arrow" (click)="patientInfo=false"></em>
            </span>
            <span *ngIf="!patientInfo" (click)="HCmoreHide">
                <em class="fa fa-chevron-up mt-1 more-arrow" (click)="patientInfo=true"></em>
            </span>
        </div>
    </div>
    <div class="col-sm-12  row d-flex rowData" *ngIf="!patientInfo">
        <div *ngFor="let fields of patientInformation" [ngClass]="fields.classname ? fields.classname  : 'col-sm-3'">
            <form *ngIf="fields.controllabelname" class="row d-flex box-align label-hor">
                <div class="row col-sm-12  p-1">
                    <span [ngClass]="fields.classname =='col-sm-2' ? 'col-sm-10 p-1 pl-3' : 'col-sm-10 p-1 pl-3'">
                        {{fields.controllabelname}}
                    </span>
                    <span [ngClass]="fields.classname =='col-sm-2' ? 'col-sm-2 fr px-1' : 'col-sm-2 fr px-1'">
                        <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3" (change)="save(fields,$event,displaytype)"
                            value="{{fields.indexValue}}" [disabled]="isButtonEnable(tabindexFlag,rbacObject.editBtn)">
                    </span>
                </div>
            </form>
        </div>
    </div>

    <!-- suppliment information -->
    <div class="col-sm-12 d-flex mt-3 p-0" *ngIf="suplimentalInformation?.length >0">
        <div class="col-sm-3 color">
            <strong>Supplemental Information</strong>
        </div>
        <span class="col-sm-8 pl-0 mt-1">
            <hr class="border-line">
        </span>
        <div class="col-sm-1 button-wrap">
            <span *ngIf="supplimentalInfo">
                <em class="fa fa-chevron-down mt-1 more-arrow" (click)="supplimentalInfo=false"></em>
            </span>
            <span *ngIf="!supplimentalInfo" (click)="HCmoreHide">
                <em class="fa fa-chevron-up mt-1 more-arrow" (click)="supplimentalInfo=true"></em>
            </span>
        </div>
    </div>
    <div class="col-sm-12  row d-flex rowData" *ngIf="!supplimentalInfo">
        <div *ngFor="let fields of suplimentalInformation" [ngClass]="fields.classname ? fields.classname  : 'col-sm-4'">
            <form *ngIf="fields.controllabelname" class="row d-flex box-align label-hor">
                <div class="row col-sm-12  p-1">
                    <span [ngClass]="fields.classname =='col-sm-2' ? 'col-sm-10 p-1 pl-3' : 'col-sm-10 p-1 pl-3'">
                        {{fields.controllabelname}}
                    </span>
                    <span [ngClass]="fields.classname =='col-sm-2' ? 'col-sm-2 fr px-1' : 'col-sm-2 fr px-1'">
                        <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3" (change)="save(fields,$event,displaytype)"
                            value="{{fields.indexValue}}" [disabled]="isButtonEnable(tabindexFlag,rbacObject.editBtn)">
                    </span>
                </div>
            </form>
        </div>
    </div>

    <!-- insurance information -->
    <div class="col-sm-12 d-flex mt-3 p-0">
        <div class="col-sm-2 color">
            <strong>Insurance</strong>
        </div>
        <span class="col-sm-9 pl-0 mt-1">
            <hr class="border-line">
        </span>
        <div class="col-sm-1 button-wrap">
            <span *ngIf="insuranceHide">
                <em class="fa fa-chevron-down mt-1 more-arrow" (click)="insuranceHide=false"></em>
            </span>
            <span *ngIf="!insuranceHide">
                <em class="fa fa-chevron-up mt-1 more-arrow" (click)="insuranceHide=true"></em>
            </span>
        </div>
    </div>
    <div *ngIf="!insuranceHide">
        <div class="row col-sm-12 mt-3 ml-2 more-data mb-2" *ngIf="patientInformation?.length >0">
            <div class="col-sm-2 p-0">
                <strong>Primary Insurance</strong>
            </div>
        </div>
        <div class="col-sm-12  row d-flex rowData">
            <div *ngFor="let fields of primaryInsuranceFields" [ngClass]="fields.classname ? fields.classname  : 'col-sm-2'">
                <form *ngIf="fields.controllabelname" class="row d-flex box-align label-hor">
                    <div class="row col-sm-12 p-1">
                        <span [ngClass]="fields.classname =='col-sm-2' ? 'col-sm-10 p-1 pl-3' : 'col-sm-10 p-1 pl-3'">
                            {{fields.controllabelname}}
                        </span>
                        <span [ngClass]="fields.classname =='col-sm-2' ? 'col-sm-2 fr px-1' : 'col-sm-2 fr px-1'">
                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3" (change)="save(fields,$event,displaytype)"
                                value="{{fields.indexValue}}" [disabled]="isButtonEnable(tabindexFlag,rbacObject.editBtn)">
                        </span>
                    </div>
                </form>
            </div>
        </div>
        <div class="row col-sm-12 mt-3 ml-2 more-data mb-2">
            <div class="col-sm-2 p-0">
                <strong>Secondary Insurance</strong>
            </div>
        </div>
        <div class="col-sm-12  row d-flex rowData">
            <div *ngFor="let fields of secondaryInsuranceFields" [ngClass]="fields.classname ? fields.classname  : 'col-sm-2'">
                <form *ngIf="fields.controllabelname" class="row d-flex box-align label-hor">
                    <div class="row col-sm-12 p-1">
                        <span [ngClass]="fields.classname =='col-sm-2' ? 'col-sm-10 p-1 pl-3' : 'col-sm-10 p-1 pl-3'">
                            {{fields.controllabelname}}
                        </span>
                        <span [ngClass]="fields.classname =='col-sm-2' ? 'col-sm-2 fr px-1' : 'col-sm-2 fr px-1'">
                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3" (change)="save(fields,$event,displaytype)"
                                value="{{fields.indexValue}}" [disabled]="isButtonEnable(tabindexFlag,rbacObject.editBtn)">
                        </span>
                    </div>
                </form>
            </div>
        </div>
        <div class="row col-sm-12 mt-3 ml-2 more-data mb-2">
            <div class="col-sm-2 p-0">
                <strong>Tertiary Insurance</strong>
            </div>
        </div>
        <div class="col-sm-12  row d-flex rowData">
            <div *ngFor="let fields of tertiaryInsuranceFields" [ngClass]="fields.classname ? fields.classname  : 'col-sm-2'">
                <form *ngIf="fields.controllabelname" class="row d-flex box-align label-hor">
                    <div class="row col-sm-12 p-1 ">
                        <span [ngClass]="fields.classname =='col-sm-2' ? 'col-sm-10 p-1 pl-3' : 'col-sm-10 p-1 pl-3'">
                            {{fields.controllabelname}}
                        </span>
                        <span [ngClass]="fields.classname =='col-sm-2' ? 'col-sm-2 fr px-1' : 'col-sm-2 fr px-1'">
                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3" (change)="save(fields,$event,displaytype)"
                                value="{{fields.indexValue}}" [disabled]="isButtonEnable(tabindexFlag,rbacObject.editBtn)">
                        </span>
                    </div>
                </form>
            </div>
        </div>
        <div class="row col-sm-12 mt-3 ml-2 more-data mb-2">
            <div class="col-sm-2 p-0">
                <strong>Guarantor</strong>
            </div>
        </div>
        <div class="col-sm-12  row d-flex rowData">
            <div *ngFor="let fields of guarantorFields" [ngClass]="fields.classname ? fields.classname  : 'col-sm-3'">
                <form *ngIf="fields.controllabelname" class="row d-flex box-align label-hor">
                    <div class="row col-sm-12  p-1">
                        <span [ngClass]="fields.classname =='col-sm-2' ? 'col-sm-10 p-1 pl-3' : 'col-sm-10 p-1 pl-3'">
                            {{fields.controllabelname}}
                        </span>
                        <span [ngClass]="fields.classname =='col-sm-2' ? 'col-sm-2 fr px-1' : 'col-sm-2 fr px-1'">
                            <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3" (change)="save(fields,$event,displaytype)"
                                value="{{fields.indexValue}}" [disabled]="isButtonEnable(tabindexFlag,rbacObject.editBtn)">
                        </span>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- Button information -->
    <div class="col-sm-12 d-flex mt-3 p-0" *ngIf="buttonData?.length >0">
        <div class="col-sm-3 color">
            <strong>Buttons</strong>
        </div>
        <span class="col-sm-8 pl-0 mt-1">
            <hr class="border-line">
        </span>
        <div class="col-sm-1 button-wrap">
            <span *ngIf="buttonInfo">
                <em class="fa fa-chevron-down mt-1 more-arrow" (click)="buttonInfo=false"></em>
            </span>
            <span *ngIf="!buttonInfo" (click)="HCmoreHide">
                <em class="fa fa-chevron-up mt-1 more-arrow" (click)="buttonInfo=true"></em>
            </span>
        </div>
    </div>
    <div class="col-sm-12  row d-flex rowData" *ngIf="!buttonInfo">
        <div *ngFor="let fields of buttonData" [ngClass]="fields.classname ? fields.classname  : 'col-sm-3'">
            <form *ngIf="fields.controllabelname" class="row d-flex box-align label-hor">
                <div class="row col-sm-12  p-1">
                    <span [ngClass]="fields.classname =='col-sm-2' ? 'col-sm-10 p-1 pl-3' : 'col-sm-10 p-1 pl-3'">
                        {{fields.controllabelname}}
                    </span>
                    <span [ngClass]="fields.classname =='col-sm-2' ? 'col-sm-2 fr px-1' : 'col-sm-2 fr px-1'">
                        <input class="input-wrap" (keypress)="AllowOnlyNumber($event)" maxlength="3" (change)="save(fields,$event,displaytype)"
                            value="{{fields.indexValue}}" [disabled]="isButtonEnable(tabindexFlag,rbacObject.editBtn)">
                    </span>
                </div>
            </form>
        </div>
    </div>


    <div class="col-sm-12 button-wrap mt-2">
        <!-- <button mat-raised-button class="admin-btn-success mr-4" [disabled]="!tabindexFlag">
            Export
        </button> -->
      
        <button mat-raised-button class="admin-btn-success mr-4" [disabled]="isButtonEnable(tabindexFlag,rbacObject.uploadBtn)"
            (click)="loadUploadScreen()">
            Upload   
        </button>

        <button mat-raised-button class="admin-btn-success mr-4" (click)="resetTabIndex()" [disabled]="isButtonEnable(tabindexFlag,rbacObject.editBtn)">
            Reset
        </button>
               
        <button mat-raised-button class="admin-btn-success" [disabled]="isButtonEnable(tabindexFlag,rbacObject.editBtn)" (click)="updateTabIndexOrder()">
            Save
        </button>
    </div>

</div>


<div *ngIf="uploadClicked" class="mt-3 my-manage-form">
    <div class="modal-header admin-model-header mb-2 mt-3">
        <h3 class="modal-title w-100 admin-model-header-txt main-title">
            <strong>Upload Tab Focus</strong>
        </h3>
    </div>
    <div class="button-field-wrap m-1 p-0">
        <mat-form-field class="col-sm-3 example-additional-selection p-0" appearance="outline">
            <mat-label class="d-flex">
                Templates
            </mat-label>
            <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
            <mat-select disableOptionCentering class="ml-2 temp-mat-align">
                <mat-option class="temp-font-size" (click)="downloadTemplate('allFieldsCheck')">
                    <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="button-field-wrap">
        <span class="instr-size mb-2">
            <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
            You can download the sample template file to upload.</span>
    </div>

    <div class="col-sm-12 p-0" *ngIf="gridwidth == 0">
        <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
            <input type="file" id="fileDropRef" #fileDropRef click="value = null" value="" (change)="onFileChange($event)"
                hidden />
            <span class="align-center img-wrap">
                <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
            </span>
            <span>Drag & Drop excel files here</span>
            <span class="align-center">Or</span>
            <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
        </div>
        <button mat-raised-button class="admin-btn-success mt-4" (click)="backSelect()">
            Back
        </button>
    </div>

    <div *ngIf="gridwidth > 0">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="gridData" (initialized)="initGrid(grid)" #grid
            [headersVisibility]="'Column'" (formatItem)="formateItem(grid, $event)">
            <!-- Status -->
            <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
                [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <span [ngStyle]="{color: cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.status}}
                    </span>
                </ng-template>
            </wj-flex-grid-column>

            <!-- Action -->
            <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126" [isReadOnly]="true"
                [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                    <button class="edit-delete-btn" (click)="deleteRow(grid, row)">
                        <em id="deleteButton" [title]="'Delete'" class="p-0 fa fa-trash"></em>
                    </button>
                </ng-template>
            </wj-flex-grid-column>
            <div *ngFor="let columnname of sheetHeader">
                <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
                    [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                </wj-flex-grid-column>
            </div>
            <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>

        <div class="row pl-3">
            <div class="col-sm-2 p-0 mt-4">
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth > 0" (click)="gridRemove()">Back</button>
                <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth == 0" (click)="backSelect()">Back</button>
            </div>
            <div class="col-sm-10 button-wrap p-0 mt-4">
                <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4" (click)="backSelect()">Cancel</button>
                <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4" [disabled]="disableApprovebtn()"
                    (click)="approveTabIndex()">Approve</button>
                <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4" (click)="backSelect()">Finish</button>
                <button mat-raised-button *ngIf="postUpload" class="admin-btn-success" (click)="ExportExcel(grid)">Download
                    Result</button>
            </div>
        </div>
    </div>
    <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
        <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
    </div>
</div>
</span>
