<span class="casetype-config">
    <div class="col-sm-12">
        <div class="button-field-wrap mt-2">
            <div  class="col-4 text-right" *ngIf="source=='NewLocation'">
            <!-- <mat-form-field class="col-6" appearance="outline">
                <mat-label>Deployment</mat-label>
                <mat-select disableOptionCentering type="text" matInput #searchbar [(value)]="selectDeployment" [disabled]="true">
                    <mat-option class="temp-font-size" *ngFor="let i of CopyAcrossDeploymentKey" [value]="i" (onSelectionChange)="onChangeDeployment($event, i)">
                        <span>{{ i }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field> -->
        </div>
            <mat-form-field appearance="outline" class="frmMatSelect w-25 pl-2">
                <mat-label>
                    Configuration Source
                </mat-label>
                <input type="text" #txtInput matInput [(ngModel)]="selectedAccount" style="width: 91%;margin-top: 8px;" (input)="filteredlabList()"
                    [matAutocomplete]="sourceAccount" #accountTrigger="matAutocompleteTrigger">
                <!-- <em class="fa fa-chevron-down chevron-align" style="margin-left: 5px;margin-top: 10px;"></em> -->
                <mat-autocomplete #sourceAccount="matAutocomplete">
                  <mat-option *ngIf="!enabledCaseTypeDep" (onSelectionChange)="setCaseType($event)" value='Default Configuration' class="mat-opt-align">
                    <span>Default Configuration</span>
                  </mat-option>
                  <mat-option *ngFor="let item of filteredlabList()" class="mat-opt-align" (onSelectionChange)="setCaseType($event,item)"
                  value='{{item.displayname}} ({{item.accountid}})' value="{{item.displayname}} ({{item.accountid}})"
                  title="{{item.displayname}} ({{item.accountid}})">
                  <span>{{item.displayname}} ({{item.accountid}})</span>
                </mat-option>
              </mat-autocomplete>
              <button matSuffix mat-icon-button>
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
              <button *ngIf="selectedAccount" matSuffix mat-icon-button aria-label="Clear" title="Reset Search" (click)="clearAccounts($event,accountTrigger,sourceAccount)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="casetype-wrap" #scrollMe>
            <div class="row p-0 m-0" *ngIf="mode == 'edit' && configuredCaseTypes.length > 0">
                <div class="col-4 p-0 m-0">
                    <span class="error-msg pr-1"></span><strong>Case types configured :</strong>
                    <span style="padding-inline: 10vw">
                    </span>
                </div>
                <span class="popup-wrappers matCheckboxColor row mt-2 d-flex">
                    <div class="row p-0 m-0 case-type-width">
                        <div class="col-12 p-2 m-1">
                            <section class="casetype-section setupcard-wrap">
                                <div class="m-0 option-wrap">
                                    <div *ngFor="let case of configuredCaseTypes" class="col-12 pr-2 mr-2">
                                        <mat-checkbox [color]="task.color" (change)="changeCaseType($event,case)"
                                            [(checked)]="case.checked">
                                            {{case.casetype}}
                                        </mat-checkbox>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </span>
            </div>
    
            <div class="row col-sm-11 p-0 m-0 align-center" *ngIf="showCasetype">
                <div class="col-4 p-0 m-0">
                    <span class="error-msg pr-1" *ngIf="source != 'NewEntity' && (mode && mode != 'edit')">*</span><strong>Case types grouped by
                        Speciality :</strong>
                    <span style="padding-inline: 10vw">
                    </span>
                </div>
    
                <div class="col-4 p-0 m-0" *ngIf="showCasetype">
                    <div class="p-0 m-0 mt-2 case-wrap">
                        <mat-form-field appearance="outline" class="frmMatSelect w-100">
                            <mat-label>
                                Case Type Search
                            </mat-label>
                            <input type="text" #searchInput style="width: 93%;margin-top: 10px;" matInput #trigger="matAutocompleteTrigger"
                                [matAutocomplete]="auto" [ngModelOptions]="{standalone: true}" (input)="filterCasetypes(searchCaseType)"
                                [(ngModel)]="searchCaseType">
                            <!-- <em class="fa fa-chevron-down chevron-align down-icon"></em> -->
                            <button matSuffix mat-icon-button>
                                <mat-icon>keyboard_arrow_down</mat-icon>
                              </button>
                            <button *ngIf="searchCaseType" matSuffix mat-icon-button aria-label="Clear" title="Reset Search"
                                (click)="clearCaseTypeResults($event,trigger,auto)">
                                <mat-icon>close</mat-icon>
                            </button>
                            <mat-autocomplete (optionSelected)="filterCasetypes($event.option.viewValue)" #auto="matAutocomplete">
                                <mat-option class="mat-opt-align" *ngFor="let casetype of getCaseTypeList(searchCaseType)">
                                    <span>{{casetype}}</span>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <!-- <span [innerHtml]="errorAlertSpan" *ngIf="showNoCaseTypeSelected()" class="error-msg text-left"></span>
                    <span [innerHtml]="errorAlertSpan" *ngIf="checkOrderCodeConfig()" class="error-msg text-left"></span> -->
                    </div>
                </div>
    
                <div class="col-4 p-0 m-0 flex-right checkboxAll" *ngIf="showCasetype">
                    <mat-checkbox [color]="task.color" [color]="task.color" [checked]="checkAllSelected()" [disabled]="caseTypesSaved"
                        class="float-right ml-2 mt-2" (change)="checkAllCasetypes($event)">
                        <span><strong>All Case types</strong></span>
                    </mat-checkbox>
                </div>
            </div>
            <div class="row p-0 m-0" *ngIf="!showCasetype">
                <div class="col-4 p-0 m-0">
                    <span class="error-msg pr-1" *ngIf="source != 'NewEntity' && (mode && mode != 'edit')">*</span><strong>Case types grouped by Speciality :</strong>
                    <span style="padding-inline: 10vw">
                    </span>
                </div>
                <div class="col-12 nodata-wrap mt-4 mb-4">
                    <span class="nodata-design">
                        No Data Found</span>
                </div>
            </div>
            <span *ngIf="showCasetype" class="popup-wrapper matCheckboxColor row mt-2 d-flex">
                <div class="row p-0 m-0 case-type-width" *ngFor="let item of categoryList">
                    <div class="col-12 p-2 m-1">
                        <section class="casetype-section setupcard-wrap">
                            <span class="case-details">
                                <mat-checkbox [color]="task.color" [indeterminate]="checkPartialSelection(item)" [disabled]="caseTypesSaved"
                                    class="casetype-margin check-box" [checked]="checkAllSelection(item)" (change)="checkCategory($event,item.Category)">
                                    <strong><span class="label-wrap">{{ item.Category }}</span></strong>
                                </mat-checkbox>
                            </span>
                            <div class="m-0 option-wrap">
                                <div *ngFor="let case of item.caseTypes" class="col-12 pr-2 mr-2">
                                    <mat-checkbox [color]="task.color" [(disabled)]="case.disabled || caseTypesSaved"
                                        [(checked)]="case.checked" (change)="checkCasetypes($event, item.Category, case.casetype)">
                                        <span class="casetype-font">
                                            {{case.casetype}}
                                        </span>
                                    </mat-checkbox>
                                    <div *ngIf="case.Iscompendium && contextValue != 'Ordering Facility'" class="link-disable col-12 error-size pl-5 link-wrap">
                                        <a (click)="getOrderCodes(case.casetype)" class="validate-button" target="_blank"
                                            *ngIf="case.checked  && !case.disabled && !caseTypesSaved"><u>Manage
                                                Panels</u></a>
                                        <a aria-disabled="true" *ngIf="!case.checked || case.disabled || caseTypesSaved"><u>Manage
                                                Panels</u></a>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </span>
        </div>
    </div>
    <div class="mt-4">
        <button mat-raised-button class="admin-btn-success mr-4" (click)="previousNxtBtn('prev')">Previous</button>
        <button mat-raised-button class="admin-btn-success mr-4" (click)="previousNxtBtn('nxt')">Next</button>
        <button mat-raised-button class="admin-btn-success float-right" [disabled]="caseTypesSaved" (click)="saveData('nxt')">Save</button>
        <button mat-raised-button class="admin-btn-success float-right mr-4" (click)="previousNxtBtn('can')">Cancel</button>
    </div>
    
    <!-- Alert -->
    <div class="model-dialog row p-0 m-0 mt-2" *ngIf="alertPopUp" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="col-12 p-0 m-0">
            <!-- The Modal -->
            <div class="col-sm-12 align-center modal" style="background: #393d406b;">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="admin-model-header">
                        <h4 class="modal-title p-1">Case Types</h4>
                    </div>
                    <!-- Modal body -->
                    <div class="modal-body" style="text-align: center;font-size:16px">
                        <span class="title-card">Save Changes ?
                        </span><br><span class="title-card">
                            If not saved, any modifications made will be lost.
                        </span><br>
                    </div>
                    <!-- Modal footer -->
                    <div class="modal-footer mt-4" style="display: block;margin: auto;">
                        <button type="button" mat-raised-button class="admin-btn-success mr-4" (click)="saveCaseTypeAndOrProceed('save')">Save
                            and Proceed</button>
                        <button type="button" mat-raised-button class="admin-btn-success mr-4" (click)="saveCaseTypeAndOrProceed('dontsave')">Proceed
                            without Saving</button>
                        <button type="button" mat-raised-button class="admin-btn-success" (click)="saveCaseTypeAndOrProceed('cancel')">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    
    <!-- Previous next popup -->
    <!-- <div class="modal overlay" id="setupNewFacilityModal" data-backdrop="static" data-keyboard="false" style="display: flex;"> -->
    <div class="model-dialog row p-0 m-0 mt-2" *ngIf="caseTypePopup" aria-hidden="true" data-backdrop="static"
        data-keyboard="false">
        <div class="col-12 p-0 m-0">
            <!-- The Modal -->
            <div class="col-sm-12 align-center modal" style="background: #393d406b;">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="admin-model-header">
                        <h4 class="modal-title p-1">Case Types</h4>
                    </div>
                    <!-- Modal body -->
                    <div class="modal-body" style="text-align: center;font-size:16px">
                        <span class="title-card">Save Changes ?
                        </span><br><span class="title-card">
                            If not saved, any modifications made will be lost.
                        </span><br>
                    </div>
                    <!-- Modal footer -->
                    <div class="modal-footer mb-4" style="display: flex;">
                        <button mat-raised-button class="admin-btn-success mr-4" (click)="saveNotSave('save')" type="button">Save
                            and
                            Proceed</button>
                        <button mat-raised-button class="admin-btn-success mr-4" (click)="saveNotSave('notsave')" type="button">Proceed
                            without Saving</button>
                        <button mat-raised-button class="admin-btn-success" (click)="closeModal()" type="button">Stay
                            in
                            this
                            Page</button>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
    
    <div class="modal model-dialog row p-0 m-0 mt-4" id="manageOrderCodes" aria-hidden="true" data-backdrop="static"
        data-keyboard="false">
        <div class="modal-content">
            <div class="admin-model-header align-center">
                <h4 class="col-sm-11 mt-3 align-center modal-title p-1">Manage Panels</h4>
    
                <span type="button" data-dismiss="modal" (click)="orderCodePopupClose()" class="col-sm-1 button-wrap">
                    <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor"
                        title="Close">
                </span>
            </div>
            <div class="row modal-body pr-4 pl-4">
                <div class="col-sm-12 p-0 m-0 button-wrap align-center border-color">
                    <div class="col-8 p-0 m-0">
                        <span><strong>{{this.caseType}}</strong></span>
                    </div>
                    <div class="col-4 p-0 m-0 flex-right checkboxAll">
                        <mat-checkbox [color]="task.color" [(checked)]="checkAllPanel" (change)="checkAllPanels($event.checked)"
                            class="float-right ml-2 mt-2">
                            <span><strong>All Panels</strong></span>
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="col-4">
                        <mat-form-field appearance="outline"  class="col-sm-12">
                            <mat-label>Search</mat-label>
                            <input matInput [(ngModel)]="searchString">
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="modal-body row body-height">
                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
                    <!-- This is the tree node template for leaf nodes -->
                    <mat-tree-node *matTreeNodeDef="let node" [style.display]="
                    filterLeafNode(node) ? 'none' : 'block'
                  "
                        matTreeNodeToggle>
                        <li class="mat-tree-node">
                            <!-- use a disabled button to provide padding for tree leaf -->
                            <button mat-icon-button disabled></button>
                            <mat-checkbox [color]="task.color" class="checklist-leaf-node" (change)="todoItemSelectionToggle($event.checked,node)"
                                [checked]="node.selected">
                                {{node.name}}</mat-checkbox>
                        </li>
                    </mat-tree-node>
                    <!-- This is the tree node template for expandable nodes -->
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" [style.display]="filterParentNode(node) ? 'none' : 'block'">
                        <li>
                            <div class="mat-tree-node">
                                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                    </mat-icon>
                                </button>
                                <mat-checkbox [color]="task.color" [checked]="node.selected" [indeterminate]="node.indeterminate && !node.selected"
                                    (change)="todoItemSelectionToggle($event.checked,node)">{{node.name}}</mat-checkbox>
                            </div>
                            <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>
            </div>
            <div class="row modal-body pr-4 pl-4" *ngIf="resultSetList.length > 0">
                <div class="col-sm-12 p-0 m-0 button-wrap align-center border-color">
                    <div class="col-12 p-0 m-0">
                        <span><strong>Result Set(HTML Configurations)</strong></span>
                    </div>
                </div>
                <div class="row col-12 m-2 p-2">
                    <mat-radio-group class="col-sm-4" [color]="task.color" *ngFor="let item of resultSetList"><strong>
                            <mat-radio-button [checked]="item.checked" class=" pr-2" (change)="checkResultSet($event)"
                                value="{{item.ID}}">{{item.ResultObjectName}}</mat-radio-button>
                        </strong></mat-radio-group>
                </div>
            </div>
            <div class="row modal-body pr-4 pl-4" *ngIf="instrumentsList.length > 0">
                <div class="col-sm-12 p-0 m-0 button-wrap align-center border-color">
                    <div class="col-6 p-0 m-0">
                        <span><strong>Instrument Mapping</strong></span>
                    </div>
                    <div class="col-6 p-0 m-0 flex-right checkboxAll">
                        <mat-checkbox [color]="task.color" [(checked)]="checkAllInstruments" class="float-right ml-2 mt-2"
                            (change)="selectAllInstruments($event)">
                            <span><strong>All Instruments</strong></span>
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="row modal-body pr-4 pl-4" *ngIf="instrumentsList.length > 0">
                <div class="row col-sm-12 p-0 m-0 d-flex">
                    <div class="p-0 m-0 checkboxAll" *ngFor="let item of instrumentsList">
                        <mat-checkbox [color]="task.color" class="col-sm-4" [checked]="item.checked" class=" pr-2" (change)="checkInstrumentValue($event,item.InstrumentName)"
                            value="{{item.InstrumentName}}">
                            <strong>{{item.InstrumentName}}
                            </strong></mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="modal-footer align-center">
                <div class="col-sm-12 p-0 m-0">
                  <div class="btns-wraps pr-0 mt-2 mb-2">
                    <button mat-raised-button class="admin-btn-success mr-2 pt-1" type="reset" data-dismiss="modal" (click)="orderCodeSave()">
                        Save
                    </button>
                    <button mat-raised-button class="admin-btn-success pt-1" type="reset" data-dismiss="modal" (click)="orderCodePopupClose()">
                        Close
                    </button>
                  </div>
                </div>
            </div>
        </div>
    </div>
    </span>
    