<div class="modal-dialog NewCustomerEnableCasetype">
  <form [formGroup]="frm_UserEnteredData" #commentNgForm="ngForm" autocomplete="off">
    <div class="modal-header admin-model-header header-freeze">
      <h3 *ngIf="!resultPage" class="mainTitle modal-title w-100 admin-model-header-txt">
        Setup New Customer
      </h3>
      <h3 *ngIf="resultPage" class="mainTitle modal-title w-100 admin-model-header-txt">
        Customer Summary
      </h3>
      <span type="button" data-dismiss="modal" (click)="setupNewCustomerClose(compendiumGroup)" class="mr-2 flot-rgt">
        <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor" title="Close">
      </span>
    </div>
    <div class="modal-content header-wrapss">
      <!-- && selectedData -->
      <div class="modal-body pb-0 pt-0 mt-3" *ngIf="!FormDataEntry">
        <div class="container-fluid p-0 m-0">
          <div class="row mb-1 mx-0">
            <div class="col-lg-12 p-0 m-md-1 m-lg-0">
              <div class="row m-0 p-0">
                <div class="col-md-6 float-left pl-0 dep-wrap">
                  <mat-form-field appearance="outline" class="frmMatSelect w-100">
                    <mat-label value="none">
                      <strong>Deployment</strong>
                      <!-- <span class="error-msg">*</span> -->
                    </mat-label>
                    <!-- <mat-label value="none" *ngIf="selecteddefaultdeploymentkey && selecteddefaultdeploymentkey !== 'ALL'">
                      <strong>D<span class="error-msg">*</span></strong>
                    </mat-label> -->
                    <mat-select disableOptionCentering formControlName="frmDeploymentKey" [disabled]="disableDeployment"
                      (selectionChange)="onChangeDep(compendiumGroup,$event.value)" style="padding: 0.17rem 0px !important; border: none !important;">
                      <mat-option *ngFor="let i of DeploymentKeys" value="{{i.toString().toUpperCase()}}">{{i}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!selectedData" class="casetype-wrap">
        <mat-tab-group [selectedIndex]="selectedTab.value" #TabGroup class="stretched-user-tabs">
          <mat-tab label="Lab Details" disabled="hideLabDetailsTab" class="col-sm-12 ml-4 tab-label-wrap">
            <!-- *ngIf="UserEnteredDataDiv" -->
            <div class="modal-body FormDataEntry pt-2">
              <div *ngIf="!resultPage">
                <!-- 1st row -->
                <div class="row col-sm-12 p-0 m-0 d-flex user-wrap">
                  <div class="col-sm-2 pl-0" id="input-container">
                    <!-- [(ngModel)]="frm_NPI" -->
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>NPI
                        <span class="error-msg">*</span>
                      </mat-label>
                      <input matInput placeholder="Enter Valid NPI" formControlName="frm_npi" maxlength="10" type="text"
                        autocomplete="off" (keyup.enter)="ValidateNPIandgetData(frm_UserEnteredData.value.frm_npi)"
                        (input)="captureNPI(frm_UserEnteredData.value.frm_npi)" />

                    </mat-form-field>
                    <div *ngIf="NPIWarning" class="col-sm-8 p-0 mt-1 error-msg">NPI is not valid!</div>
                    <div *ngIf="validationWarning" class="col-sm-12 p-0 mt-1 error-msg mb-2 error-size">
                      Please validate the NPI!</div>
                  </div>

                    <div class="col-sm-4 word-wrap">
                      <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Name
                        </mat-label>
                        <input matInput placeholder="Enter Name" id="name" formControlName="frm_name" (input)="generateNameAndMnemonic(frm_UserEnteredData.value.frm_name)"
                          maxlength="174" />
                      </mat-form-field>
                      <div class="ml-2 mt-2 w-100 p-0" *ngIf="submitted && formcontrol.frm_name.invalid">
                        <span class="error-msg requiredError error-size">
                          Name is required.</span>
                      </div>
                    </div>

                      <!-- Search NPI / Registry Button  -->
                     <div class="row ml-2 mr-2 align-content-center" >
                      <button type = 'button' mat-raised-button class="admin-btn-success" (click)="openSearchNPIPopup()">
                        Search NPI
                      </button>
                     </div>

                  <div class="col-sm-2 user-wrap">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Display Name
                        <!-- <span class="error-msg">*</span> -->
                      </mat-label>
                      <input matInput placeholder="Enter Display Name" id="displayname" formControlName="frm_displayname"
                        maxlength="200" />
                    </mat-form-field>
                    <div class="ml-2 mt-2 w-100 p-0" *ngIf="submitted && formcontrol.frm_displayname.invalid">
                      <span class="error-msg requiredError text-center error-size">
                        Display Name is required.</span>
                    </div>
                  </div>

                  <div class="col-sm-2 pr-0 user-wrap">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Short Name
                        <!-- <span class="error-msg">*</span> -->
                      </mat-label>
                      <input matInput placeholder="Enter Short Name" type="text" formControlName="frm_shortname"
                        maxlength="10" />
                    </mat-form-field>
                    <div class="ml-2 mt-2 w-100 p-0" *ngIf="submitted && formcontrol.frm_shortname.invalid">
                      <span class="error-msg requiredError text-center error-size">
                        Short Name is required.</span>
                    </div>
                  </div>
                </div>

                <div class="row mt-4 data-wrap">
                  <div class="col-sm-12 row pr-0">
                    <div class="col-sm-4">
                      <div class="col-sm-12 addr-wrap">
                        <span class="addr-details">Address Details</span>
                        <div class="row p-0 m-0">
                          <div class="col-sm-6 p-0 m-0 word-wrap pr-1">
                            <mat-form-field appearance="outline" class=" w-100">
                              <mat-label>Address 1</mat-label>
                              <input matInput placeholder="Enter Address 1" formControlName="frm_Address1" type="text"
                                maxlength="100" />
                            </mat-form-field>
                          </div>
                          <div class="col-sm-6 p-0 m-0 word-wrap pl-1">
                            <mat-form-field appearance="outline" class=" w-100">
                              <mat-label>Address 2</mat-label>
                              <input matInput placeholder="Enter Address 2" formControlName="frm_Address2" type="text"
                                maxlength="100" />
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="row p-0 m-0 mt-2">
                          <div class="col-sm-6 p-0 m-0 word-wrap pr-1">
                            <mat-form-field appearance="outline" class=" w-100">
                              <mat-label>City</mat-label>
                              <input matInput placeholder="Enter City" formControlName="frm_city" type="text" maxlength="25" />
                            </mat-form-field>
                          </div>
                          <div class="col-sm-6 p-0 m-0 word-wrap pl-1">
                            <mat-form-field appearance="outline" class="frmMatSelect frm-auto w-100 col-sm-12 p-0 valuedField">
                              <mat-label>State</mat-label>
                              <i class="fa fa-chevron-down chevron-align align-icon"></i>
                              <input type="text" #searchInput  matInput #trigger="matAutocompleteTrigger"
                                [matAutocomplete]="auto"  formControlName="frm_state"
                                (input)="filterState(frm_UserEnteredData.value.frm_state,'frm_state')">
                              <button *ngIf="frm_UserEnteredData.value.frm_state" matSuffix mat-icon-button aria-label="Clear" title="Reset Search"
                                (click)="refreshStateSearch('frm_state')">
                                <mat-icon>close</mat-icon>
                              </button>
                              <mat-autocomplete (optionSelected)="filterState($event.option.viewValue,'frm_state')" #auto="matAutocomplete">
                                <mat-option class="mat-opt-align" title="{{state.item}}"
                                  *ngFor="let state of getStatesList(frm_UserEnteredData.value.frm_state)"
                                  value = "{{state.item}}">
                                  <span class="font-size"> {{state.item}}</span>
                                </mat-option>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="row p-0 m-0  mt-2">
                          <div class="col-sm-6 p-0 m-0 word-wrap pr-1">
                            <mat-form-field appearance="outline" class=" w-100">
                              <mat-label>Country</mat-label>
                              <input matInput placeholder="Enter Country" formControlName="frm_country" type="text"
                                maxlength="25" />
                            </mat-form-field>
                          </div>
                          <div class="col-sm-6 p-0 m-0 pl-1">
                            <mat-form-field appearance="outline" class=" w-100">
                              <mat-label>Zip</mat-label>
                              <input matInput placeholder="Enter Zip" formControlName="frm_zip" type="text" maxlength="10"
                                (keypress)="AllowOnlyNumberandSpclChar($event)" />
                            </mat-form-field>
                            <div *ngIf="submitted && !zipValid" class="requiredError">
                              <span class="error-msg">Zip is not valid!</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3 pl-0 pr-0">
                      <div class="col-sm-12 addr-wrap">
                        <span class="addr-details">Contact Details</span>
                        <div class="row p-0 m-0">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Phone</mat-label>
                            <input matInput placeholder="Enter Phone" (keypress)="AllowOnlyNumberandSpclChar($event)"
                              formControlName="frm_phone" maxlength="20" type="text" />
                          </mat-form-field>
                        </div>
                        <div class="row p-0 m-0 mt-2">
                          <div class="col-sm-12 p-0 m-0 word-wrap pr-1">
                            <mat-form-field appearance="outline" class="w-100">
                              <mat-label>Fax</mat-label>
                              <input matInput placeholder="Enter Fax" (keypress)="AllowOnlyNumberandSpclChar($event)"
                                formControlName="frm_fax" type="text" maxlength="50" />
                            </mat-form-field>
                            <div *ngIf="submitted && !faxValid">
                              <span class="error-msg">Fax is not valid!</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-2 p-0 user-wrap">
                      <div class=" row p-0 m-0 mb-2">
                        <div class="align-center col-sm-12">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>EIN</mat-label>
                            <input matInput placeholder="Enter EIN" formControlName="frm_ein" type="text" maxlength="10" />
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row p-0 m-0">
                        <div class="align-center col-sm-12">
                          <mat-form-field appearance="outline" class="w-100">
                            <mat-label>CLIA</mat-label>
                            <input matInput placeholder="Enter CLIA" formControlName="frm_clia" maxlength="10" type="text" />
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-3 upload-wrap">
                      <span class="sign-upload">Logo</span>
                      <div class="col-sm-16 addrs-line">
                        <div *ngIf="!imagePath">
                          <div class="custom-dropzone image-height row m-0" appDnd (fileDropped)="onFileDropped($event)">
                            <input type="file" id="fileDropRef" #fileDropRef (change)="onFileChange($event)" click="value = null" value=""
                            accept="image/*" [multiple]="false" hidden />
                            <span class="mb-2 error-size">Drag & Drop</span>
                            <span class="mb-2 align-center"><strong>or</strong></span>
                            <label class="btn-sm btn rounded browse ml-2" for="fileDropRef">
                              <span style="padding-left:10px;padding-right:10px;">Browse</span>
                          </label>
                         </div>
                        </div>
                        <div class="image-height" *ngIf="imagePath" style="width: 100%;">
                          <img  class="img-height-set" [src]="frm_logo" alt=""
                            srcset="">
                        </div>
                        <div *ngIf="frm_logo" title="Remove" (click)="onRemove()" class="close-img">
                          <img src="\assets\images\delete.jpg" class="img-style">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-4 add-form-align additional-wrap">
                  <div class="col-sm-12">
                    <mat-accordion>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header class="accordianheader">
                          <mat-panel-title class="mainTitle">
                            Additional Details
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="col-sm-12 d-flex p-0 m-0 mt-2">
                          <div class="col-sm-8 d-flex pl-2">
                            <div class="mt-2 col-4 p-0 m-0 user-wrap">
                              <div class="row p-0 m-0 pr-1 mb-2">
                                <div class="col-12 p-0 m-0">
                                  <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Mnemonic
                                      <!-- <span class="error-msg pr-1">*</span> -->
                                    </mat-label>
                                    <input matInput placeholder="Enter Mnemonic" formControlName="frm_mnemonic"
                                      maxlength="18" type="text" />
                                  </mat-form-field>
                                </div>
                                <div *ngIf="submitted && formcontrol.frm_mnemonic.invalid">
                                  <span class="error-msg error-size">
                                    Mnemonic is required.</span>
                                  <br />
                                </div>
                              </div>
                              <div class="row p-0 m-0 pr-1 mb-2">
                                <div class="col-12 p-0 m-0">
                                  <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Batching Mnemonic</mat-label>
                                    <input matInput placeholder="Enter Batching Mnemonic" formControlName="frm_batchingmnemonic"
                                      maxlength="200" type="text" />
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row p-0 m-0 pr-1 mb-2">
                                <div class="col-12 p-0 m-0">
                                  <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Taxonomy Code</mat-label>
                                    <input matInput placeholder="Enter Taxonomy Code" formControlName="frm_taxonomycode"
                                      maxlength="10" type="text" />
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row p-0 m-0 pr-1 mb-2">
                                <div class="col-12 p-0 m-0">
                                  <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Support ID</mat-label>
                                    <input matInput placeholder="Enter Support ID" formControlName="frm_supportid"
                                      maxlength="10" type="text" />
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row p-0 m-0 pr-1 mb-2">
                                <div class="col-12 p-0 m-0">
                                  <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>CLPOL</mat-label>
                                    <input matInput placeholder="Enter CLPOL" formControlName="frm_clpol" maxlength="50"
                                      type="text" />
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                            <div class="mt-2 col-4 pr-1 pl-1 m-0 drop-wrapp ">
                              <div class="row p-0 m-0 pl-2 mb-2">
                                <div class="col-12 p-0 m-0">
                                  <mat-form-field appearance="outline" class="frmMatSelect w-100">
                                    <mat-label>Name Display Format
                                    </mat-label>
                                    <mat-select disableOptionCentering class="form-control" formControlName="frm_usernameDisplayFormat"
                                      style="padding: 0.17rem 0px !important; border: none !important;">
                                      <mat-option *ngFor="let usernameformat of UsernameWebFormat" value="{{usernameformat.item}}">
                                        {{usernameformat.item}}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row p-0 m-0 pl-2 mb-2">
                                <div class="col-12 p-0 m-0">
                                  <mat-form-field appearance="outline" class="frmMatSelect w-100">
                                    <mat-label>Reports Display Format
                                    </mat-label>
                                    <mat-select disableOptionCentering class="form-control" formControlName="frm_usernameReportDisplayFormat"
                                      style="padding: 0.17rem 0px !important; border: none !important;">
                                      <mat-option *ngFor="let usernamereportformat of UsernameWebFormat" value="{{usernamereportformat.item}}">
                                        {{usernamereportformat.item}}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row p-0 m-0 pl-2 mb-2">
                                <div class="col-12 p-0 m-0">
                                  <mat-form-field appearance="outline" class="frmMatSelect w-100">
                                    <mat-label>Collection Date Format</mat-label>
                                    <mat-select disableOptionCentering class="form-control" formControlName="frm_collectiondateformat"
                                      style="padding: 0.17rem 0px !important; border: none !important;">
                                      <mat-option *ngFor="let i of dateFormat" value="{{i}}">{{i}}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row p-0 m-0 pl-2 mb-2">
                                <div class="col-12 p-0 m-0">
                                  <mat-form-field appearance="outline" class="frmMatSelect w-100">
                                    <mat-label>Package Date Format</mat-label>
                                    <mat-select disableOptionCentering class="form-control" formControlName="frm_packagedateformat"
                                      style="padding: 0.17rem 0px !important; border: none !important;">
                                      <mat-option *ngFor="let i of dateFormat" value="{{i}}">{{i}}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row p-0 m-0 pl-2 mb-2">
                                <div class="col-12 p-0 m-0">
                                  <mat-form-field appearance="outline" class="frmMatSelect w-100">
                                    <mat-label>ETA Date Format</mat-label>
                                    <mat-select disableOptionCentering class="form-control" formControlName="frm_etadateformat"
                                      style="padding: 0.17rem 0px !important; border: none !important;">
                                      <mat-option *ngFor="let i of dateFormat" value="{{i}}">{{i}}</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                            <div class="mt-2 col-4 pr-1 m-0 user-wrap">
                              <div class="row p-0 m-0 opt-wrap">
                                <div class="col-12 p-0 m-0">
                                  <mat-form-field appearance="outline" class="frmMatSelect w-100">
                                    <mat-label>Claim Options</mat-label>
                                    <mat-select disableOptionCentering class="form-control" formControlName="frm_claimoptions"
                                      style="padding: 0.17rem 0px !important; border: none !important;">
                                      <mat-option value="Track Claim">Track Claim
                                      </mat-option>
                                      <mat-option value="Submit For Billing">Submit
                                        For Billing</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row p-0 m-0 pr-1 mt-2">
                                <div class="col-12 p-0 m-0">
                                  <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Claim Mnemonics</mat-label>
                                    <input matInput placeholder="Enter Claim Mnemonics" formControlName="frm_claimmnemonics"
                                      maxlength="2" type="text" />
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row p-0 m-0 pr-1 mt-2">
                                <div class="col-12 p-0 m-0">
                                  <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Report Data</mat-label>
                                    <input matInput placeholder="Enter Report Data" formControlName="frm_reportdata"
                                      maxlength="100" type="text" />
                                  </mat-form-field>
                                </div>
                              </div>
                             <div class="row p-0 m-0 mt-2">
                                <div class="col-12 p-0 m-0">
                                  <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>
                                      State License Number
                                    </mat-label>
                                    <input matInput placeholder="Enter State License Number" formControlName="frm_statelicensenumber"
                                      maxlength="50" type="text" />
                                  </mat-form-field>
                                </div>
                                <div *ngIf="submitted && formcontrol.frm_mnemonic.invalid">
                                  <span class="error-msg"></span>
                                  <br />
                                </div>
                              </div>
                              <div class="row p-0 m-0 mt-4" style="visibility: hidden;">
                                <div class="col-12 p-0 m-0">
                                  <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>
                                      State License Number
                                    </mat-label>
                                    <input matInput placeholder="Enter State License Number" formControlName="frm_statelicensenumber"
                                      maxlength="50" type="text" />
                                  </mat-form-field>
                                </div>
                                <div *ngIf="submitted && formcontrol.frm_mnemonic.invalid">
                                  <span class="error-msg"></span>
                                  <br />
                                </div>
                              </div>
                              <div class="row p-0 m-0 matCheckboxColor">
                                <div class="col-sm-12 p-0 m-0">
                                  <label>Enable Fax</label>
                                  <mat-checkbox [color]="task.color" class="example-margin" formControlName="frm_enabledfax">
                                  </mat-checkbox>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-4 mb-2 pl-4">
                            <div class="col-sm-10 addr-wrap pr-0">
                              <span class="addr-details">Payment Details</span>
                              <div class="col-sm-12 d-flex p-0 m-0">
                                <div class="col-sm-6 p-0 m-0 word-wrap pr-1">
                                  <mat-form-field appearance="outline" class=" w-100">
                                    <mat-label>Address 1</mat-label>
                                    <input matInput placeholder="Enter Address 1" formControlName="frm_payaddress1"
                                      maxlength="100" type="text" class="form-control radius-change form-font" />
                                  </mat-form-field>
                                </div>
                                <div class="col-sm-5 p-0 m-0 word-wrap pr-1">
                                  <mat-form-field appearance="outline" class=" w-100">
                                    <mat-label>Address 2</mat-label>
                                    <input matInput placeholder="Enter Address 2" formControlName="frm_payaddress2"
                                      maxlength="100" type="text" class="form-control radius-change form-font" />
                                  </mat-form-field>
                                </div>
                              </div>

                              <div class="col-sm-12 d-flex p-0 m-0 mt-2">
                                <div class="col-sm-6 p-0 m-0 word-wrap pr-1">
                                  <mat-form-field appearance="outline" class=" w-100">
                                    <mat-label>City</mat-label>
                                    <input matInput placeholder="Enter City" formControlName="frm_paycity" maxlength="100"
                                      type="text" class="form-control radius-change form-font" />
                                  </mat-form-field>
                                </div>
                                <div class="col-sm-5 p-0 m-0 word-wrap pr-1">

                                  <!-- <mat-form-field appearance="outline" class="frmMatSelect w-100">
                                    <mat-label>State</mat-label>
                                    <mat-select formControlName="frm_paystate">
                                      <mat-option *ngFor="let state of StateList" value="{{state.itemValue}}">
                                        {{state.item}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field> -->
                                  <mat-form-field appearance="outline" class="frmMatSelect frm-auto w-100 col-sm-12 p-0 valuedField">
                                    <mat-label>State</mat-label>
                                    <i class="fa fa-chevron-down chevron-align align-icon"></i>
                                    <input type="text" #searchInput  matInput #trigger="matAutocompleteTrigger"
                                      [matAutocomplete]="auto1"  formControlName="frm_paystate"
                                      (input)="filterState(frm_UserEnteredData.value.frm_paystate, 'frm_paystate')">
                                    <button *ngIf="frm_UserEnteredData.value.frm_paystate" matSuffix mat-icon-button aria-label="Clear" title="Reset Search"
                                      (click)="refreshStateSearch('frm_paystate')">
                                      <mat-icon>close</mat-icon>
                                    </button>
                                    <mat-autocomplete (optionSelected)="filterState($event.option.viewValue, 'frm_paystate')" #auto1="matAutocomplete">
                                      <mat-option class="mat-opt-align" title="{{state.item}}"
                                        *ngFor="let state of getStatesList(frm_UserEnteredData.value.frm_paystate)"
                                        value = "{{state.item}}">
                                        <span class="font-size"> {{state.item}}</span>
                                      </mat-option>
                                    </mat-autocomplete>
                                  </mat-form-field>
                                </div>
                              </div>

                              <div class="col-sm-12 d-flex p-0 m-0 mt-2">
                                <div class="col-sm-6 p-0 m-0 word-wrap pr-1">
                                  <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Country</mat-label>
                                    <input matInput placeholder="Enter Country" formControlName="frm_country" type="text"
                                      maxlength="25" />
                                  </mat-form-field>
                                </div>
                                <div class="col-sm-5 p-0 m-0 word-wrap pr-1">
                                  <mat-form-field appearance="outline" class=" w-100">
                                    <mat-label>Zip</mat-label>
                                    <input matInput placeholder="Enter Zip" formControlName="frm_payzip" maxlength="10"
                                      type="text" class="form-control radius-change form-font" />
                                  </mat-form-field>
                                  <div *ngIf="submitted && !payZipValid" style="display:table" class="requiredError">
                                    <span class="error-msg">
                                      Zip is not valid!
                                    </span>
                                    <br />
                                  </div>
                                </div>
                              </div>
                            </div>
                              <div class="p-0 matCheckboxColor">
                                <label class="sendout-label">Enabled Send Out</label>
                                    <mat-checkbox [color]="task.color" formControlName="frm_enabledsendout">
                                    </mat-checkbox>
                              </div>
                              <div class="p-0 matCheckboxColor">
                                <label>Shared Surgical Center</label>
                                <mat-checkbox [color]="task.color" formControlName="frm_sharedsurgicalcenter">
                                </mat-checkbox>
                              </div>
                          </div>
                        </div>
                      </mat-expansion-panel>

                      <mat-expansion-panel class="mt-2">
                        <mat-expansion-panel-header class="accordianheader">
                          <mat-panel-title class="mainTitle">
                            VitalAxis Representatives
                          </mat-panel-title>
                        </mat-expansion-panel-header>

                          <app-vital-axis-assign-representatives
                                          *ngIf="representativeUsersList && representativeRolesList?.length"
                                          [openedLocation] = "'new customer'"
                                          [deploymentKey] = "deploymentKey"
                                          [saveClicked] = "saveClicked"
                                          [originalUsersList] = "representativeUsersList"
                                          [finalAssignedUsers] = "selectedRepresentativesList"
                                          (emitSelectedUsersArray)="selectedUsersFromFrameWork($event)"
                                          [originalRolesList] = "representativeRolesList">
                          </app-vital-axis-assign-representatives>

                      </mat-expansion-panel>
                    </mat-accordion>
                    <div class="row p-0 m-0">
                      <div class="col-sm-12 align-center pl-0 pr-0 mt-4">
                        <div class="col-sm-4 p-0">
                          <button mat-raised-button (click)="setupNewCustomerClose(compendiumGroup)" type="reset" class="admin-btn-success mr-4">
                            Previous
                          </button>
                          <button mat-raised-button class="admin-btn-success mr-4" type="submit" (click)="openAlertModal(compendiumGroup,'right')">
                            Next
                          </button>
                        </div>
                        <div class="col-sm-8 p-0 save-btn-wrap ml-2">
                          <button mat-raised-button class="admin-btn-success mr-4" type="reset" (click)="setupNewCustomerClose(compendiumGroup)">
                            Close
                          </button>
                          <button mat-raised-button class="admin-btn-success" (click)="saveLabDetails(compendiumGroup,frm_UserEnteredData.value,'stayInTab')"
                            type="submit">
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Case Type" disabled="hideCaseTypeTab" class="row mt-3 add-form-align case-type-wrap">
            <div class="col-sm-12">
              <div class="col-sm-12 d-flex justify-content-end">
                <!-- <mat-form-field class="col-sm-2 example-additional-selection mt-2 ext-height" appearance="outline">
                  <mat-label>Deployment</mat-label>
                  <mat-select disableOptionCentering type="text" matInput #searchbar [(value)]="frm_UserEnteredData.value.frmDeploymentKey" [disabled]="true">
                      <mat-option class="temp-font-size" *ngFor="let i of CopyAcrossDeploymentKey" [value]="i" (onSelectionChange)="onChangeDeployment($event, i)">
                          <span>{{ i }}</span>
                      </mat-option>
                  </mat-select>
              </mat-form-field> -->
              
                <mat-form-field appearance="outline" class="frmMatSelect pl-2 float-right width-set pt-2">
                  <mat-label>
                      Configuration Source
                  </mat-label>
                  <input type="text" #txtInput matInput [(ngModel)]="selectedAccount" [ngModelOptions]="{standalone: true}" class="margin-width" (input)="filteredlabList()"
                      [matAutocomplete]="sourceAccount" #accountTrigger="matAutocompleteTrigger" >
                  <!-- <em class="fa fa-chevron-down chevron-align" style="margin-left: 5px;margin-top: 10px;"></em> -->
                  <mat-autocomplete #sourceAccount="matAutocomplete">
                    <mat-option *ngIf="!enableDeploymentlist" (onSelectionChange)="setCaseType($event)" value="Default Configuration" class="mat-opt-align">
                      <span>Default Configuration</span>
                    </mat-option>
                    <mat-option *ngFor="let item of filteredlabList()" class="mat-opt-align" (onSelectionChange)="setCaseType($event,item)"
                    value='{{item.LabName}} ({{item.LabAccountId}})' value="{{item.LabName}} ({{item.LabAccountId}})"
                    title="{{item.LabName}} ({{item.LabAccountId}})">
                    <span>{{item.LabName}} ({{item.LabAccountId}})</span>
                  </mat-option>
                </mat-autocomplete>
                <button matSuffix mat-icon-button>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>
                <button *ngIf="selectedAccount" matSuffix mat-icon-button aria-label="Clear" title="Reset Search" (click)="clearAccounts($event,accountTrigger,sourceAccount)">
                      <mat-icon>close</mat-icon>
                  </button>
              </mat-form-field>
              </div>
              <div class="row p-0 m-0 align-center" *ngIf="showCasetype">
                <div class="col-4 p-0 m-0">
                  <span class="error-msg pr-1">*</span><strong>Case types grouped by Speciality :</strong>
                  <span style="padding-inline: 10vw">
                  </span>
                </div>

                <div class="col-4 p-0 m-0">
                  <div class="p-0 m-0 mt-2 case-wrap">
                    <mat-form-field appearance="outline" class="frmMatSelect w-100">
                      <mat-label>
                        Case Type Search
                      </mat-label>
                      <em class="fa fa-chevron-down chevron-align"></em>
                      <input type="text" #searchInput style="width: 95%" matInput #trigger="matAutocompleteTrigger"
                        [matAutocomplete]="auto" [ngModelOptions]="{standalone: true}" [(ngModel)]="searchCaseType"
                        (input)="filterCasetypes(searchCaseType)">
                      <button *ngIf="searchCaseType" matSuffix mat-icon-button aria-label="Clear" title="Reset Search"
                        (click)="clearResults($event,trigger)">
                        <mat-icon>close</mat-icon>
                      </button>
                      <mat-autocomplete (closed)="arrowIconSubject.next('arrow_drop_down')" (opened)="arrowIconSubject.next('arrow_drop_up')"
                        (optionSelected)="filterCasetypes($event.option.viewValue)" #auto="matAutocomplete">
                        <mat-option class="mat-opt-align" *ngFor="let casetype of getCaseTypeList(searchCaseType)">
                          <span>{{casetype}}</span>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                    <span [innerHtml]="errorAlertSpan" *ngIf="showNoCaseTypeSelected()" class="error-msg text-left"></span>
                    <span [innerHtml]="errorAlertSpan" *ngIf="checkOrderCodeConfig()" class="error-msg text-left"></span>
                  </div>
                </div>

                <div class="col-4 p-0 m-0 flex-right checkboxAll">
                  <mat-checkbox [color]="task.color" [(checked)]="checkAllCaseType" class="float-right ml-2 mt-2"
                    (change)="checkAllCasetypes($event)">
                    <span><strong>All Case types</strong></span>
                  </mat-checkbox>
                </div>
              </div>
              <div class="row p-0 m-0" *ngIf="!showCasetype">
                <div class="col-12 nodata-wrap mt-4 mb-4">
                  <span class="nodata-design">
                    No Data Found</span>
                </div>
              </div>
              <div *ngIf="showCasetype" class="popup-wrapper matCheckboxColor row mt-2 d-flex">
                <div class="row p-0 m-0 case-type-width" *ngFor="let item of categoryList">
                  <div class="col-12 p-2 m-1">
                    <section class="casetype-section setupcard-wrap">
                      <span class="case-details">
                        <mat-checkbox [color]="task.color" [indeterminate]="checkPartialSelection(item)" class="check-box"
                          [checked]="checkAllSelection(item)" (change)="checkCategory($event,item.Category)">
                          <strong><span class="label-wrap">{{item.Category}}</span></strong>
                        </mat-checkbox>
                      </span>
                      <div class="m-0 option-wrap">
                        <div *ngFor="let case of item.caseTypes" class="col-12 pr-2 mr-2">
                          <mat-checkbox [color]="task.color" [(checked)]="case.checked" (change)="checkCasetypes(item.Category, case.casetype)">
                            {{case.casetype}}
                          </mat-checkbox>
                          <div *ngIf="case.Iscompendium" class="link-disable col-12 error-size pl-5 link-wrap">
                            <a (click)="manageOrderCodesOpen(case.casetype)" class="validate-button" target="_blank"
                              *ngIf="case.checked"><u>Manage
                                Panels</u></a>
                            <a aria-disabled="true" *ngIf="!case.checked"><u>Manage
                                Panels</u></a>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <div class="row p-0 m-0">
              <div class="col-sm-12 align-center pr-0 pl-4 mt-4 mb-4">
                <div class="col-sm-4 p-0">
                  <button mat-raised-button (click)="openAlertModal(compendiumGroup,'left')" class="admin-btn-success mr-4">
                    Previous
                  </button>
                </div>
                <div class="col-sm-8 p-0 save-btn-wrap mr-3">
                  <button mat-raised-button class="admin-btn-success mr-4" (click)="setupNewCustomerClose(compendiumGroup)">
                    Close
                  </button>
                  <button mat-raised-button class="admin-btn-success" type="submit" (click)="quicksetupnewlab(compendiumGroup,frm_UserEnteredData.value)">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="Summary" disabled="hideSummaryTab">
            <div id="setupCwc" class="row p-0 m-0 setupSummary summary-popup-wrapper" *ngIf="resultPage && summaryData && summaryData != {}">
              <div class="col-sm-12">
                <!-- lab details preview-->
                <mat-accordion>
                  <mat-expansion-panel [expanded]="true" class="mt-2">
                    <mat-expansion-panel-header class="accordianheader">
                      <mat-panel-title class="mainTitle">
                        Laboratory Details
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row p-0 m-0">
                      <div class="col-12 p-0 m-0 mt-1">
                        <div class="row p-0 m-0 mt-2" style="border-bottom: 1px solid #fcd2a2;">
                          <div class="col-9 p-0 m-0" style="color: #095682;word-break: break-all;">
                            <strong>
                              <span style="word-break: break-all;">{{summaryData.OrgLabAccData.OrganizationName}}</span>&nbsp;<span
                                style="word-break: break-all;">({{summaryData.OrgLabAccData['OrganizationID']}})</span></strong>
                          </div>
                          <!-- Will Impliment with diffrent library -->

                          <!-- <div class="col-3 p-0 m-0 " style="cursor: pointer;" (click)="GetLabDetails('LabData')">
                                                            overall download -->
                          <!-- <em class="fa fa-download float-right"></em>
                                                        </div> -->

                        </div>
                        <div class="row p-0 m-0 d-flex justify-content-between">
                          <div class="col-8 p-0 m-0">
                            <div class="row p-0 m-0 mt-2">
                              <div class="col-12 p-0 m-0">
                                <strong>Display
                                  Name:</strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{summaryData.OrgLabAccData['OrganizationName']}}</span>&nbsp;&nbsp;&nbsp;<strong>|&nbsp;&nbsp;&nbsp;Short
                                  Name:&nbsp;&nbsp;
                                </strong><span style="word-break: break-all;">{{summaryData.OrgLabAccData['Orgnaizationmnemonic']}}</span>
                              </div>
                            </div>
                            <div class="row p-0 m-0 mt-1">
                              <div class="col-12 p-0 m-0">
                                <strong>EIN :</strong>&nbsp;&nbsp;{{summaryData.OrgLabAccData.Organizationein
                                &&
                                summaryData.OrgLabAccData.Organizationein != '' ?
                                summaryData.OrgLabAccData.Organizationein
                                : 'Not Specified'}}
                              </div>
                            </div>
                          </div>
                          <div class="p-0 m-0 mt-2 position-relative test1">

                              <div>

                                <div class="align-center">
                                  <img class="float-right fitImage" style="border: 1px solid #e5e5e5;" *ngIf="summaryData.OrgLabAccData.Logo && summaryData.OrgLabAccData.Logo != ''"
                                  [src]="'data:image/jpg;base64,' + summaryData.OrgLabAccData.Logo" />

                                  <div *ngIf="summaryData.OrgLabAccData.Logo && summaryData.OrgLabAccData.Logo != ''" class="lineSeparator" ></div>

                                  <div class="iconsContainer" *ngIf="summaryData.OrgLabAccData.Logo && summaryData.OrgLabAccData.Logo != ''">
                                    <img src="/assets/icons/user_icons/download.svg" alt="" height="20" (click)="downloadLogo()" class="cursor">
                                  </div>
                                </div>


                                <div class="fitImage float-right text-center logo-set"
                                  *ngIf="!summaryData.OrgLabAccData.Logo || summaryData.OrgLabAccData.Logo == ''">
                                  <div style="display: table;width: 100%; height: 100%;">
                                    <div style="display: table-cell;vertical-align: middle;">No
                                      Logo</div>
                                  </div>
                                </div>

                                <!-- <div class="col-sm-9 action_btns align-center"  *ngIf="summaryData.OrgLabAccData.Logo && summaryData.OrgLabAccData.Logo != ''">
                                  <div class="col-sm-3 actions_icon download-wrp" (click)="downloadLogo()">
                                    <img src="/assets/icons/user_icons/download.svg" alt="" height="20" class="drop-wrap">
                                  </div>
                                </div> -->

                              </div>

                          </div>
                        </div>
                        <div class="row p-0 m-0 mt-3" style="border-bottom: 1px solid #fcd2a2;">
                          <div class="col-9 p-0 m-0" style="color: #095682;">
                            <strong>Lab location Details</strong>
                          </div>

                          <!-- Will Impliment with diffrent library -->
                          <!-- <div class="col-3 p-0 m-0 newcustomerpdfdownload" style="cursor: pointer;"
                                                            (click)="GetLabDetails('LabData')">
                                                             lab details download -->
                          <!--<em class="fa fa-download float-right"></em>
                                                        </div> -->
                        </div>
                        <div class="row p-0 m-0 mt-2">
                          <div class="col-8 p-0 m-0">
                            <strong>Name:</strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{summaryData.OrgLabAccData.AccountName}}</span>
                          </div>
                          <div class="col-4 p-0 m-0 text-right">
                            <strong>Location
                              Id:</strong>&nbsp;&nbsp;{{summaryData.OrgLabAccData.AccountID}}
                          </div>
                        </div>
                        <div class="row p-0 m-0 mt-1">
                          <div class="col-8 p-0 m-0">
                            <strong>Display
                              Name:</strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{summaryData.OrgLabAccData.DisplayName}}</span>&nbsp;&nbsp;&nbsp;<strong>|&nbsp;&nbsp;&nbsp;Short
                              Name:
                            </strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{summaryData.OrgLabAccData.Mnemonic}}</span>
                          </div>
                          <div class="col-4 p-0 m-0 text-right">
                            <strong>NPI :</strong>&nbsp;&nbsp;{{summaryData.OrgLabAccData.NPI.toUpperCase()}}
                          </div>
                        </div>
                        <div class="row p-0 m-0 mt-1">
                          <div class="col-8 p-0 m-0">
                            <div class="row p-0 m-0">
                              <div class="col-2 p-0 m-0">
                                <strong>Address:</strong>&nbsp;&nbsp;
                              </div>
                              <div class="col-8 p-0 m-0" *ngIf="(summaryData.OrgLabAccData.Address1 && summaryData.OrgLabAccData.Address1 != '')
                                    || (summaryData.OrgLabAccData.Address2 && summaryData.OrgLabAccData.Address2 != '')
                                    || (summaryData.OrgLabAccData.City && summaryData.OrgLabAccData.City != '' )
                                    || (summaryData.OrgLabAccData.State && summaryData.OrgLabAccData.State != '')
                                    || (summaryData.OrgLabAccData.Zip && summaryData.OrgLabAccData.Zip != '')
                                    || (summaryData.OrgLabAccData.Fax && summaryData.OrgLabAccData.Fax != '')
                                    || (summaryData.OrgLabAccData.Phone && summaryData.OrgLabAccData.Phone != '')">
                                <div class="row p-0 m-0">
                                  <div class="col-12 p-0 m-0">
                                    <span *ngIf="summaryData.OrgLabAccData.Address1 && summaryData.OrgLabAccData.Address1 != ''">
                                      <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Address1}},</span>&nbsp;</span>
                                    <span *ngIf="summaryData.OrgLabAccData.Address2 && summaryData.OrgLabAccData.Address2 != ''">
                                      <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Address2}},</span>
                                    </span>
                                  </div>
                                  <div class="col-12 p-0 m-0">
                                    <span *ngIf="summaryData.OrgLabAccData.City && summaryData.OrgLabAccData.City != '' ">
                                      <span style="word-break: break-all;">{{summaryData.OrgLabAccData.City}},</span>&nbsp;</span>
                                    <span *ngIf="summaryData.OrgLabAccData.State && summaryData.OrgLabAccData.State != ''">
                                      <span style="word-break: break-all;">{{summaryData.OrgLabAccData.State}},</span>
                                    </span>
                                  </div>
                                  <div class="col-12 p-0 m-0">
                                    <span *ngIf="summaryData.OrgLabAccData.Zip && summaryData.OrgLabAccData.Zip != ''">
                                      <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Zip}},</span>&nbsp;</span>
                                    <span *ngIf="summaryData.OrgLabAccData.Fax && summaryData.OrgLabAccData.Fax != ''">
                                      <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Fax}},</span>
                                    </span>
                                  </div>
                                  <div class="col-12 p-0 m-0">
                                    <span *ngIf="summaryData.OrgLabAccData.Phone && summaryData.OrgLabAccData.Phone != ''">
                                      <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Phone}}</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-8 p-0 m-0" *ngIf="(!summaryData.OrgLabAccData.Address1 || summaryData.OrgLabAccData.Address1 == '')
                                    && (!summaryData.OrgLabAccData.Address2 || summaryData.OrgLabAccData.Address2 == '')
                                    && (!summaryData.OrgLabAccData.City || summaryData.OrgLabAccData.City == '')
                                    && (!summaryData.OrgLabAccData.State || summaryData.OrgLabAccData.State == '')
                                    && (!summaryData.OrgLabAccData.Zip || summaryData.OrgLabAccData.Zip == '')
                                    && (!summaryData.OrgLabAccData.Fax || summaryData.OrgLabAccData.Fax == '')
                                    && (!summaryData.OrgLabAccData.Phone || summaryData.OrgLabAccData.Phone == '')">
                                <div class="row p-0 m-0">
                                  <div class="col-12 p-0 m-0">
                                    Not Specified
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 p-0 m-0">
                            <div class="row p-0 m-0">
                              <div class="col-12 p-0 m-0 text-right">
                                <strong>GUID :</strong>&nbsp;&nbsp;{{ (summaryData.OrgLabAccData.AccountGUID && summaryData.OrgLabAccData.AccountGUID) ? summaryData.OrgLabAccData.AccountGUID : 'Not Specified'}}
                              </div>
                            </div>
                            <div class="row p-0 m-0 mt-1">
                              <div class="col-12 p-0 m-0 text-right">
                                <strong>CLIA :</strong>&nbsp;&nbsp;{{summaryData.OrgLabAccData.Clia
                                &&
                                summaryData.OrgLabAccData.Clia
                                != '' ? summaryData.OrgLabAccData.Clia : 'Not Specified'}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row p-0 m-0 mt-3" style="color: #095682;border-bottom: 1px solid #fcd2a2;">
                          <div class="col-12 p-0 m-0">
                            <strong>Lab User Details</strong>
                          </div>
                        </div>
                        <div class="row p-0 m-0 mt-2">
                          <div class="col-12 p-0 m-0">
                            <table>
                              <tr>
                                <th><strong>Name</strong></th>
                                <th><strong>Role</strong></th>
                                <th><strong>Login Name</strong></th>
                                <th><strong>Email ID</strong></th>
                              </tr>
                              <tr *ngFor="let user of summaryData.UsersData">
                                <ng-container *ngIf="user.UserAccountID == summaryData.OrgLabAccData.AccountID">
                                  <td><span style="word-break: break-all;">{{user.Users
                                      && user.Users != '' ? user.Users
                                      :
                                      'Not Specified'}}</span></td>
                                  <td><span style="word-break: break-all;">{{user.RoleName
                                      && user.RoleName != '' ?
                                      user.RoleName : 'Not
                                      Specified'}}</span></td>
                                  <td><span style="word-break: break-all;">{{user.LoginName}}</span>
                                  </td>
                                  <td>
                                    <span style="word-break: break-all;">{{user.Email
                                      && user.Email != '' ? user.Email :
                                      'Not
                                      Specified'}}</span>
                                  </td>
                                  <td style="border:none">&nbsp;</td>
                                </ng-container>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div class="row p-0 m-0 mt-3" style="border-bottom: 1px solid #fcd2a2;" *ngIf="!disableCaseType">
                          <div class="col-12 p-0 m-0" style="color: #095682;">
                            <strong>Case Type Details</strong>
                          </div>
                        </div>
                        <div class="row p-0 m-0 mt-2" *ngIf="!disableCaseType">
                          <div class="col-12 p-0 m-0">
                            <table>
                              <tr>
                                <th><strong>Case Type</strong></th>
                                <th><strong>Display Name</strong></th>
                                <th><strong>Case Type Category</strong></th>
                              </tr>
                              <tr *ngFor="let casetype of summaryData.CasetypeData">
                                <ng-container *ngIf="casetype.CaseTypeAccountID == summaryData.OrgLabAccData.AccountID">
                                  <td><span style="word-break: break-all;">{{casetype.CaseType}}</span>
                                  </td>
                                  <td><span style="word-break: break-all;">
                                      {{casetype.CaseTypeDisplayname &&
                                      casetype.CaseTypeDisplayname ?
                                      casetype.CaseTypeDisplayname : 'Not
                                      Specified'}}</span>
                                  </td>
                                  <td><span style="word-break: break-all;">
                                      {{casetype.Category && casetype.Category ?
                                      casetype.Category : 'Not Specified'}}</span>
                                  </td>

                                  <!-- Will Impliment with diffrent library -->
                                  <!-- <td style="border:none">
                                                                            individual casetype detailsdownload -->
                                  <!-- <em class="fa fa-download newcustomerpdfdownload"
                                                                                style="cursor: pointer;" (click)="GetCasetypeReport( summaryData.OrgLabAccData.OrganizationID,'',casetype.CaseType)"></em>
                                                                        </td> -->
                                </ng-container>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion>
                  <mat-expansion-panel class="mt-2">
                    <mat-expansion-panel-header class="accordianheader">
                      <mat-panel-title class="mainTitle">
                        Ordering Facility Details
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row p-0 m-0">
                      <div class="col-12 p-0 m-0 mt-2 mb-5" *ngFor="let facility of summaryData.FacAccData">
                        <div class="row p-0 m-0" style="border-bottom: 1px solid #fcd2a2;">
                          <div class="col-9 p-0 m-0" style="color: #095682;">
                            <strong><span style="word-break: break-all;">{{facility.FacOrgDetails.FacilityOrgName}}</span>&nbsp;<span
                                style="word-break: break-all;">({{facility.OrganizationID}})</span></strong>
                          </div>
                          <!-- Will Impliment with diffrent library -->
                          <!-- <div class="col-3 p-0 m-0" style="cursor: pointer;" (click)="GetLabDetails('LabData')"> -->
                          <!-- individual facility details download -->
                          <!-- <em class="fa fa-download float-right"></em>
                                                        </div> -->
                        </div>
                        <div class="row p-0 m-0">
                          <div class="col-8 p-0 m-0">
                            <div class="row p-0 m-0 mt-2">
                              <div class="col-12 p-0 m-0">
                                <strong>Display
                                  Name:</strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{facility.FacOrgDetails.FacilityOrgName}}</span>&nbsp;&nbsp;&nbsp;<strong>|&nbsp;&nbsp;&nbsp;Short
                                  Name:</strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{facility.FacOrgDetails.Facilitymnemonic}}</span>
                              </div>
                            </div>
                            <div class="row p-0 m-0">
                              <div class="col-12 p-0 m-0 mt-1">
                                <strong>EIN :</strong>&nbsp;&nbsp;{{facility.FacOrgDetails.FacilityEIN
                                &&
                                facility.FacOrgDetails.FacilityEIN != '' ?
                                facility.FacOrgDetails.FacilityEIN :
                                'Not Specified'}}
                              </div>
                            </div>
                          </div>
                          <div class="col-4 p-0 m-0 mt-2">
                            <img class="float-right fitImage" style="border: 1px solid #e5e5e5;" *ngIf="facility.FacOrgDetails.FacilityLogo && facility.FacOrgDetails.FacilityLogo != ''"
                              [src]="'data:image/jpg;base64,'+ facility.FacOrgDetails.FacilityLogo" />
                            <div class="fitImage float-right text-center" style="font-size: 12-px;border: 1px solid #e5e5e5;"
                              *ngIf="!facility.FacOrgDetails.FacilityLogo || facility.FacOrgDetails.FacilityLogo == ''">
                              <div style="display: table;width: 100%; height: 100%;">
                                <div style="display: table-cell;vertical-align: middle;">No
                                  Logo</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row p-0 m-0 mt-3" style="border-bottom: 1px solid #fcd2a2;">
                          <div class="col-9 p-0 m-0" style="color:#095682"><strong>OF Location
                              Details</strong></div>
                        </div>
                        <div class="row p-0 m-0 mt-2">
                          <div class="col-8 p-0 m-0">
                            <strong>Name:</strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{facility.FacilityAccountName}}</span>
                          </div>
                          <div class="col-4 p-0 m-0 text-right">
                            <strong>Location
                              Id:</strong>&nbsp;&nbsp;{{facility.FacilityAccountID}}
                          </div>
                        </div>
                        <div class="row p-0 m-0">
                          <div class="col-8 p-0 m-0 mt-2">
                            <strong>Display
                              Name:</strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{facility.FacilityDisplayName}}</span>&nbsp;&nbsp;&nbsp;<strong>|&nbsp;&nbsp;&nbsp;Short
                              Name:
                            </strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{facility.FacilityMnemonic}}</span>
                          </div>
                          <div class="col-4 p-0 m-0 text-right">
                            <div class="row p-0 m-0">
                              <div class="col-12 p-0 m-0">
                                <strong>NPI :</strong>&nbsp;&nbsp;{{facility.FacilityNPI.toUpperCase()}}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row p-0 m-0 mt-2">
                          <div class="col-8 p-0 m-0">
                            <div class="row p-0 m-0">
                              <div class="col-2 p-0 m-0">
                                <strong>Address:</strong>&nbsp;&nbsp;
                              </div>
                              <div class="col-8 p-0 m-0" *ngIf="(facility.FacilityAddress1 && facility.FacilityAddress1 != '')
                                    || (facility.FacilityAddress2 && facility.FacilityAddress2 == '')
                                    || (facility.FacilityCity && facility.FacilityCity != '')
                                    || (facility.FacilityState && facility.FacilityState != '')
                                    || (facility.FacilityZip && facility.FacilityZip != '')
                                    || (facility.FacilityFax && facility.FacilityFax != '')
                                    || (facility.FacilityPhone && facility.FacilityPhone != '') ">
                                <div class="row p-0 m-0">
                                  <div class="col-12 p-0 m-0">
                                    <span *ngIf="facility.FacilityAddress1 && facility.FacilityAddress1 != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityAddress1}},</span>&nbsp;</span>
                                    <span *ngIf="facility.FacilityAddress2 && facility.FacilityAddress2 != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityAddress2}},</span>
                                    </span>
                                  </div>
                                  <div class="col-12 p-0 m-0">
                                    <span *ngIf="facility.FacilityCity && facility.FacilityCity != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityCity}},</span>&nbsp;</span>
                                    <span *ngIf="facility.FacilityState && facility.FacilityState != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityState}},</span>
                                    </span>
                                  </div>
                                  <div class="col-12 p-0 m-0">
                                    <span *ngIf="facility.FacilityZip && facility.FacilityZip != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityZip}},</span>&nbsp;</span>
                                    <span *ngIf="facility.FacilityFax && facility.FacilityFax != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityFax}},</span>
                                    </span>
                                  </div>
                                  <div class="col-12 p-0 m-0">
                                    <span *ngIf="facility.FacilityPhone && facility.FacilityPhone != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityPhone}}</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-8 p-0 m-0" *ngIf="(!facility.FacilityAddress1 || facility.FacilityAddress1 == '')
                                    && (!facility.FacilityAddress2 || facility.FacilityAddress2 == '')
                                    && (!facility.FacilityCity || facility.FacilityCity == '')
                                    && (!facility.FacilityState || facility.FacilityState == '')
                                    && (!facility.FacilityZip || facility.FacilityZip == '')
                                    && (!facility.FacilityFax || facility.FacilityFax == '')
                                    && (!facility.FacilityPhone || facility.FacilityPhone == '') ">
                                <div class="row p-0 m-0">
                                  <div class="col-12 p-0 m-0">
                                    Not Specified
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 p-0 m-0 text-right">
                            <strong>GUID :</strong>&nbsp;&nbsp;{{facility.FacilityAccountGUID}}
                          </div>
                        </div>
                        <div class="row p-0 m-0 mt-3" style="border-bottom: 1px solid #fcd2a2;">
                          <div class="col-12 p-0 m-0" style="color:#095682">
                            <strong>OF User Details</strong>
                          </div>
                        </div>
                        <div class="row p-0 m-0 mt-2">
                          <div class="col-12 p-0 m-0">
                            <table>
                              <tr>
                                <th><strong>Name</strong></th>
                                <th><strong>Role</strong></th>
                                <th><strong>Email ID</strong></th>
                              </tr>
                              <tr *ngFor="let user of summaryData.UsersData">
                                <ng-container *ngIf="(user.UserAccountID == facility.FacilityAccountID) && (user.RoleName && user.RoleName != '' && user.RoleName.toLowerCase() == 'physician')">
                                  <td><span style="word-break: break-all;">{{user.Users}}</span>
                                  </td>
                                  <td><span style="word-break: break-all;">{{user.RoleName}}</span>
                                  </td>
                                  <td>
                                    <span style="word-break: break-all;">{{user.Email
                                      && user.Email ? user.Email : 'Not
                                      Specified'}}</span>
                                  </td>
                                  <td style="border:none">&nbsp;</td>
                                </ng-container>
                              </tr>
                            </table>
                          </div>
                        </div>
                        <div class="row p-0 m-0 mt-3" style="border-bottom: 1px solid #fcd2a2;">
                          <div class="col-12 p-0 m-0" style="color: #095682;">
                            <strong>Case Type Details</strong>
                          </div>
                        </div>
                        <div class="row p-0 m-0 mt-2">
                          <div class="col-12 p-0 m-0">
                            <table>
                              <tr>
                                <th><strong>Case Type</strong></th>
                                <th><strong>Display Name</strong></th>
                                <th><strong>Case Type Category</strong></th>
                              </tr>
                              <tr *ngFor="let casetype of summaryData.CasetypeData">
                                <ng-container *ngIf="casetype.CaseTypeAccountID == facility.FacilityAccountID">
                                  <td><span style="word-break: break-all;">{{casetype.CaseType}}</span>
                                  </td>
                                  <td>
                                    <span style="word-break: break-all;">{{casetype.CaseTypeDisplayname
                                      &&
                                      casetype.CaseTypeDisplayname ?
                                      casetype.CaseTypeDisplayname : 'Not
                                      Specified'}}</span>
                                  </td>
                                  <td>
                                    <span style="word-break: break-all;">{{casetype.Category
                                      && casetype.Category ?
                                      casetype.Category : 'Not Specified'}}</span>
                                  </td>
                                  <!-- impliment with diffrent library -->
                                  <!-- <td style="border:none">
                                                                            individual casetype details download -->
                                  <!-- <em class="fa fa-download newcustomerpdfdownload"
                                                                                style="cursor: pointer;" (click)="GetAllDataReport1()"></em>
                                                                        </td> -->
                                </ng-container>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                      <br />
                      <hr />
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>

                <mat-accordion *ngIf="summaryData?.assinedUsers">
                  <mat-expansion-panel class="mt-2">
                    <mat-expansion-panel-header class="accordianheader">
                      <mat-panel-title class="mainTitle">
                        VitalAxis Representatives
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="col-sm-12 p-0 vitalaxis-card-wrapper {{  summaryData?.assinedUsers?.length >= 3 ? 'alignCenter' : 'alignNormal' }}"
                      *ngIf="summaryData?.assinedUsers?.length">
                        <div class="p-0" style="width: 33% !important;" *ngFor="let cardInfo of summaryData?.assinedUsers; let i = index;" [ngStyle]="{'display': cardInfo?.IsAssociationActive ? 'block' : 'none'}">
                            <app-representative-card
                                                [cardInfo]="cardInfo"
                                                [openedLocation]="'view'">
                            </app-representative-card>
                        </div>
                    </div>

                    <div class="col-sm-12 p-0 vitalaxis-card-wrapper errorContainer" *ngIf="summaryData?.assinedUsers === 'error'">
                      Representative Assignment Failed, you can edit the Group to assign.
                    </div>
                  </mat-expansion-panel>

                </mat-accordion>

              </div>
            </div>
            <div *ngIf="downloadReportClicked">
              <div class="container-fluid p-5 m-0" style="color: #063970;font-size: 18px;" *ngIf="downloadReportLab" id='labReportDetails1'
                #labReportDetails1>
                <div class="row p-0 m-0">
                  <div class="col-12 p-0 m-0">
                    <div class="row p-0 m-0">
                      <div class="col-12 p-0 m-0" style="font-size:26px">
                        <strong><u>Laboratory Details</u></strong>
                      </div>
                    </div>
                    <div class="row p-0 m-0">
                      <div class="col-8 p-0 m-0">
                        <div class="row p-0 m-0">
                          <div class="col-12 p-0 m-0 mt-2">
                            <strong>Laboratory
                              Name&nbsp;:&nbsp;&nbsp;</strong><span style="word-break: break-all;">{{summaryData.OrgLabAccData.OrganizationName}}</span>&nbsp;<span
                              style="word-break: break-all;">({{summaryData.OrgLabAccData.OrganizationID}})</span>
                          </div>
                          <div class="col-12 p-0 m-0 mt-2">
                            <strong>EIN
                              #&nbsp;:</strong>&nbsp;&nbsp;{{summaryData.OrgLabAccData.Organizationein
                            &&
                            summaryData.OrgLabAccData.Organizationein != '' ?
                            summaryData.OrgLabAccData.Organizationein
                            : 'Not Specified'}}
                          </div>
                        </div>
                      </div>
                      <div class="col-4 p-0 m-0 mt-2">
                        <img class="float-right fitImage" style="border: 1px solid #e5e5e5;" *ngIf="summaryData.OrgLabAccData.Logo && summaryData.OrgLabAccData.Logo != ''"
                          [src]="'data:image/jpg;base64,' + summaryData.OrgLabAccData.Logo" />
                        <div class="fitImage float-right text-center" style="font-size: 12-px;border: 1px solid #e5e5e5;"
                          *ngIf="!summaryData.OrgLabAccData.Logo || summaryData.OrgLabAccData.Logo == ''">
                          <div style="display: table;width: 100%; height: 100%;">
                            <div style="display: table-cell;vertical-align: middle;">No Logo
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-3">
                      <div class="col-12 p-0 m-0" style="font-size: 22px;">
                        <strong><u>Lab Location</u></strong>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-2">
                      <div class="col-12 p-0 m-0">
                        <strong><span style="word-break: break-all;">{{summaryData.OrgLabAccData.AccountName}}</span>&nbsp;<span
                            style="word-break: break-all;">({{summaryData.OrgLabAccData.AccountID}})</span></strong>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-2">
                      <div class="col-8 m-0 p-0">
                        <div class="row p-0 m-0">
                          <div class="col-12 p-0 m-0" *ngIf="(summaryData.OrgLabAccData.Address1 && summaryData.OrgLabAccData.Address1 != '')
                                || (summaryData.OrgLabAccData.Address2 && summaryData.OrgLabAccData.Address2 != '')
                                || (summaryData.OrgLabAccData.City && summaryData.OrgLabAccData.City != '' )
                                || (summaryData.OrgLabAccData.State && summaryData.OrgLabAccData.State != '')
                                || (summaryData.OrgLabAccData.Zip && summaryData.OrgLabAccData.Zip != '')
                                || (summaryData.OrgLabAccData.Fax && summaryData.OrgLabAccData.Fax != '')
                                || (summaryData.OrgLabAccData.Phone && summaryData.OrgLabAccData.Phone != '')">
                            <div class="row p-0 m-0">
                              <div class="col-12 p-0 m-0">
                                <span *ngIf="summaryData.OrgLabAccData.Address1 && summaryData.OrgLabAccData.Address1 != ''">
                                  <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Address1}},</span>&nbsp;</span>
                                <span *ngIf="summaryData.OrgLabAccData.Address2 && summaryData.OrgLabAccData.Address2 != ''">
                                  <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Address2}},</span>
                                </span>
                              </div>
                              <div class="col-12 p-0 m-0">
                                <span *ngIf="summaryData.OrgLabAccData.City && summaryData.OrgLabAccData.City != '' ">
                                  <span style="word-break: break-all;">{{summaryData.OrgLabAccData.City}},</span>&nbsp;</span>
                                <span *ngIf="summaryData.OrgLabAccData.State && summaryData.OrgLabAccData.State != ''">
                                  <span style="word-break: break-all;">{{summaryData.OrgLabAccData.State}},</span>
                                </span>
                              </div>
                              <div class="col-12 p-0 m-0">
                                <span *ngIf="summaryData.OrgLabAccData.Zip && summaryData.OrgLabAccData.Zip != ''">
                                  <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Zip}},</span>&nbsp;</span>
                                <span *ngIf="summaryData.OrgLabAccData.Fax && summaryData.OrgLabAccData.Fax != ''">
                                  <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Fax}},</span>
                                </span>
                              </div>
                              <div class="col-12 p-0 m-0">
                                <span *ngIf="summaryData.OrgLabAccData.Phone && summaryData.OrgLabAccData.Phone != ''">
                                  <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Phone}}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 p-0 m-0" *ngIf="(!summaryData.OrgLabAccData.Address1 || summaryData.OrgLabAccData.Address1 == '')
                                    && (!summaryData.OrgLabAccData.Address2 || summaryData.OrgLabAccData.Address2 == '')
                                    && (!summaryData.OrgLabAccData.City || summaryData.OrgLabAccData.City == '')
                                    && (!summaryData.OrgLabAccData.State || summaryData.OrgLabAccData.State == '')
                                    && (!summaryData.OrgLabAccData.Zip || summaryData.OrgLabAccData.Zip == '')
                                    && (!summaryData.OrgLabAccData.Fax || summaryData.OrgLabAccData.Fax == '')
                                    && (!summaryData.OrgLabAccData.Phone || summaryData.OrgLabAccData.Phone == '')">
                            <div class="row p-0 m-0">
                              <div class="col-12 p-0 m-0">
                                Address Not Specified
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 p-0 m-0">
                        <div class="row p-0 m-0">
                          <div class="col-12 p-0 m-0">
                            <span class="float-right">
                              <strong>NPI
                                #&nbsp;:</strong>&nbsp;&nbsp;{{summaryData.OrgLabAccData.NPI.toUpperCase()}}
                            </span>
                          </div>
                          <div class="col-12 p-0 m-0">
                            <span class="float-right">
                              <strong>CLIA
                                #&nbsp;:</strong>&nbsp;&nbsp;{{summaryData.OrgLabAccData.Clia
                              &&
                              summaryData.OrgLabAccData.Clia
                              != '' ? summaryData.OrgLabAccData.Clia : 'Not Specified'}}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-5">
                      <div class="col-12 m-0 p-0" style="font-size: 22px;">
                        <strong><u>Lab Administrator</u></strong>
                      </div>
                      <div class="col-12 p-0 m-0 mt-3">
                        <table>
                          <tr>
                            <th><strong>Name</strong></th>
                            <th><strong>Role</strong></th>
                            <th><strong>Email ID</strong></th>
                          </tr>
                          <tr *ngFor="let user of summaryData.UsersData">
                            <ng-container *ngIf="(user.UserAccountID == summaryData.OrgLabAccData.AccountID) && (user.RoleName && user.RoleName != '' && user.RoleName.toLowerCase() == 'lab administrator')">
                              <td><span style="word-break: break-all;">{{user.Users &&
                                  user.Users !=
                                  '' ? user.Users : 'Not
                                  Specified'}}</span></td>
                              <td><span style="word-break: break-all;">{{user.RoleName &&
                                  user.RoleName != '' ? user.RoleName
                                  : 'Not
                                  Specified'}}</span></td>
                              <td>
                                <span style="word-break: break-all;">{{user.Email &&
                                  user.Email !=
                                  '' ? user.Email : 'Not
                                  Specified'}}</span>
                              </td>
                              <td style="border:none">&nbsp;</td>
                            </ng-container>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-5">
                      <div class="col-12 m-0 p-0" style="font-size: 22px;">
                        <strong><u>Case Types</u></strong>
                      </div>
                      <div class="col-12 m-0 p-0 mt-3">
                        <table>
                          <tr>
                            <th><strong>Case Type Name</strong></th>
                            <th><strong>Category</strong></th>
                          </tr>
                          <tr *ngFor="let casetypeItem of selectedCasetypesList">
                            <td><span style="word-break: break-all;">{{casetypeItem.CaseTypeName}}</span>
                            </td>
                            <td>
                              <span style="word-break: break-all;">{{casetypeItem.Category}}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-fluid p-5 m-0" style="color: #063970;font-size: 18px;" *ngIf="downloadReportLab" id='labReportDetails2'
                #labReportDetails2>
                <div class="row p-0 m-0 mt-5">
                  <div class="col-12 p-0 m-0">
                    <div class="row p-0 m-0">
                      <div class="col-12 p-0 m-0 mt-3" style="font-size:26px">
                        <strong><u>Laboratory Location Details</u></strong>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-4">
                      <div class="col-12 p-0 m-0" style="font-size: 22px;">
                        <strong><u>Lab Location</u></strong>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-3">
                      <div class="col-12 p-0 m-0">
                        <strong><span style="word-break: break-all;">{{summaryData.OrgLabAccData.AccountName}}</span>&nbsp;<span
                            style="word-break: break-all;">({{summaryData.OrgLabAccData.AccountID}})</span></strong>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-2">
                      <div class="col-8 m-0 p-0">
                        <div class="row p-0 m-0">
                          <div class="col-12 p-0 m-0" *ngIf="(summaryData.OrgLabAccData.Address1 && summaryData.OrgLabAccData.Address1 != '')
                                || (summaryData.OrgLabAccData.Address2 && summaryData.OrgLabAccData.Address2 != '')
                                || (summaryData.OrgLabAccData.City && summaryData.OrgLabAccData.City != '' )
                                || (summaryData.OrgLabAccData.State && summaryData.OrgLabAccData.State != '')
                                || (summaryData.OrgLabAccData.Zip && summaryData.OrgLabAccData.Zip != '')
                                || (summaryData.OrgLabAccData.Fax && summaryData.OrgLabAccData.Fax != '')
                                || (summaryData.OrgLabAccData.Phone && summaryData.OrgLabAccData.Phone != '')">
                            <div class="row p-0 m-0">
                              <div class="col-12 p-0 m-0">
                                <span *ngIf="summaryData.OrgLabAccData.Address1 && summaryData.OrgLabAccData.Address1 != ''">
                                  <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Address1}},</span>&nbsp;</span>
                                <span *ngIf="summaryData.OrgLabAccData.Address2 && summaryData.OrgLabAccData.Address2 != ''">
                                  <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Address2}},</span>
                                </span>
                              </div>
                              <div class="col-12 p-0 m-0">
                                <span *ngIf="summaryData.OrgLabAccData.City && summaryData.OrgLabAccData.City != '' ">
                                  <span style="word-break: break-all;">{{summaryData.OrgLabAccData.City}},</span>&nbsp;</span>
                                <span *ngIf="summaryData.OrgLabAccData.State && summaryData.OrgLabAccData.State != ''">
                                  <span style="word-break: break-all;">{{summaryData.OrgLabAccData.State}},</span>
                                </span>
                              </div>
                              <div class="col-12 p-0 m-0">
                                <span *ngIf="summaryData.OrgLabAccData.Zip && summaryData.OrgLabAccData.Zip != ''">
                                  <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Zip}},</span>&nbsp;</span>
                                <span *ngIf="summaryData.OrgLabAccData.Fax && summaryData.OrgLabAccData.Fax != ''">
                                  <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Fax}},</span>
                                </span>
                              </div>
                              <div class="col-12 p-0 m-0">
                                <span *ngIf="summaryData.OrgLabAccData.Phone && summaryData.OrgLabAccData.Phone != ''">
                                  <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Phone}}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 p-0 m-0" *ngIf="(!summaryData.OrgLabAccData.Address1 || summaryData.OrgLabAccData.Address1 == '')
                              && (!summaryData.OrgLabAccData.Address2 || summaryData.OrgLabAccData.Address2 == '')
                              && (!summaryData.OrgLabAccData.City || summaryData.OrgLabAccData.City == '')
                              && (!summaryData.OrgLabAccData.State || summaryData.OrgLabAccData.State == '')
                              && (!summaryData.OrgLabAccData.Zip || summaryData.OrgLabAccData.Zip == '')
                              && (!summaryData.OrgLabAccData.Fax || summaryData.OrgLabAccData.Fax == '')
                              && (!summaryData.OrgLabAccData.Phone || summaryData.OrgLabAccData.Phone == '')">
                            <div class="row p-0 m-0">
                              <div class="col-12 p-0 m-0">
                                Address Not Specified
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 p-0 m-0">
                        <div class="row p-0 m-0">
                          <div class="col-12 p-0 m-0">
                            <span class="float-right">
                              <strong>NPI
                                #&nbsp;:</strong>&nbsp;&nbsp;{{summaryData.OrgLabAccData.NPI.toUpperCase()}}
                            </span>
                          </div>
                          <div class="col-12 p-0 m-0">
                            <span class="float-right">
                              <strong>CLIA
                                #&nbsp;:</strong>&nbsp;&nbsp;{{summaryData.OrgLabAccData.Clia
                              &&
                              summaryData.OrgLabAccData.Clia != '' ?
                              summaryData.OrgLabAccData.Clia :
                              'Not Specified'}}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-5">
                      <div class="col-12 m-0 p-0" style="font-size: 22px;">
                        <strong><u>Lab Users</u></strong>
                      </div>
                      <div class="col-12 p-0 m-0 mt-3">
                        <table>
                          <tr>
                            <th><strong>Name</strong></th>
                            <th><strong>Role</strong></th>
                            <th><strong>Email ID</strong></th>
                          </tr>
                          <tr *ngFor="let user of summaryData.UsersData">
                            <ng-container *ngIf="(user.UserAccountID == summaryData.OrgLabAccData.AccountID)">
                              <td><span style="word-break: break-all;">{{user.Users &&
                                  user.Users !=
                                  '' ? user.Users : 'Not
                                  Specified'}}</span></td>
                              <td><span style="word-break: break-all;">{{user.RoleName &&
                                  user.RoleName != '' ? user.RoleName
                                  : 'Not
                                  Specified'}}</span></td>
                              <td>
                                <span style="word-break: break-all;"></span>{{user.Email &&
                                user.Email != '' ? user.Email :
                                'Not Specified'}}
                              </td>
                            </ng-container>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-5">
                      <div class="col-12 m-0 p-0" style="font-size: 22px;">
                        <strong><u>Case Types</u></strong>
                      </div>
                      <div class="col-12 m-0 p-0 mt-3">
                        <table>
                          <tr>
                            <th><strong>Case Type</strong></th>
                            <th><strong>Display Name</strong></th>
                            <th><strong>Short Code</strong></th>
                            <th><strong>Case Type Category</strong></th>
                          </tr>
                          <tr *ngFor="let casetype of summaryData.CasetypeData">
                            <ng-container *ngIf="casetype.CaseTypeAccountID == summaryData.OrgLabAccData.AccountID">
                              <td><span style="word-break: break-all;">{{casetype.CaseType}}</span>
                              </td>
                              <td>
                                <span style="word-break: break-all;">{{casetype.CaseTypeDisplayname
                                  &&
                                  casetype.CaseTypeDisplayname ?
                                  casetype.CaseTypeDisplayname : 'Not Specified'}}</span>
                              </td>
                              <td>
                                Not Specified
                              </td>
                              <td>
                                <span style="word-break: break-all;">{{casetype.Category &&
                                  casetype.Category ?
                                  casetype.Category : 'Not Specified'}}</span>
                              </td>
                            </ng-container>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-fluid p-5 m-0" style="color: #063970;font-size: 18px;" *ngIf="downloadReportFacility"
                id='facilityReportDetails1' #facilityReportDetails1>
                <div class="row p-0 m-0">
                  <div class="col-12 p-0 m-0">
                    <div class="row p-0 m-0">
                      <div class="col-12 p-0 m-0" style="font-size:26px">
                        <strong><u>Client Facility Details</u></strong>
                      </div>
                    </div>
                    <div class="row p-0 m-0">
                      <div class="col-8 p-0 m-0">
                        <div class="row p-0 m-0">
                          <div class="col-12 p-0 m-0 mt-2">
                            <strong>For Laboratory&nbsp;:&nbsp;&nbsp;</strong>
                            <span style="word-break: break-all;">{{summaryData.OrgLabAccData.OrganizationName}}</span>
                          </div>
                          <div class="col-12 p-0 m-0 mt-2">
                            <strong><span style="word-break: break-all;">{{selectedPdfFacilityDetails.FacOrgDetails.FacilityOrgName}}</span>&nbsp;<span
                                style="word-break: break-all;">({{selectedPdfFacilityDetails.OrganizationID}})</span></strong>
                          </div>
                          <div class="col-12 p-0 m-0 mt-2">
                            <strong>EIN #&nbsp;:</strong>&nbsp;&nbsp;
                            <span style="word-break: break-all;">{{selectedPdfFacilityDetails.FacOrgDetails.FacilityEIN
                              &&
                              selectedPdfFacilityDetails.FacOrgDetails.FacilityEIN != '' ?
                              selectedPdfFacilityDetails.FacOrgDetails.FacilityEIN
                              : 'Not Specified'}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 p-0 m-0 mt-2">
                        <img class="float-right fitImage" style="border: 1px solid #e5e5e5;" *ngIf="selectedPdfFacilityDetails.FacOrgDetails.FacilityLogo && selectedPdfFacilityDetails.FacOrgDetails.FacilityLogo != ''"
                          [src]="'data:image/jpg;base64,' + selectedPdfFacilityDetails.FacOrgDetails.FacilityLogo" />
                        <div class="fitImage float-right text-center" style="font-size: 12-px;border: 1px solid #e5e5e5;"
                          *ngIf="!selectedPdfFacilityDetails.FacOrgDetails.FacilityLogo || selectedPdfFacilityDetails.FacOrgDetails.FacilityLogo == ''">
                          <div style="display: table;width: 100%; height: 100%;">
                            <div style="display: table-cell;vertical-align: middle;">No Logo
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-3">
                      <div class="col-12 p-0 m-0" style="font-size: 22px;">
                        <strong><u>Facility Locations</u></strong>
                      </div>
                    </div>
                    <div class="row p-0 m-0">
                      <div class="col-12 p-0 m-0" *ngFor="let facility of summaryData.FacAccData">
                        <div class="row p-0 m-0 mt-2">
                          <div class="col-12 p-0 m-0">
                            <strong><span style="word-break: break-all;">{{facility.FacilityAccountName}}</span>&nbsp;<span
                                style="word-break: break-all;">({{facility.FacilityAccountID}})</span></strong>
                          </div>
                        </div>
                        <div class="row p-0 m-0 mt-2 mb-5">
                          <div class="col-8 m-0 p-0">
                            <div class="row p-0 m-0">
                              <div class="col-12 p-0 m-0" *ngIf="(facility.FacilityAddress1 && facility.FacilityAddress1 != '')
                                        || (facility.FacilityAddress2 && facility.FacilityAddress2 != '')
                                        || (facility.FacilityCity && facility.FacilityCity != '' )
                                        || (facility.FacilityState && facility.FacilityState != '')
                                        || (facility.FacilityZip && facility.FacilityZip != '')
                                        || (facility.FacilityFax && facility.FacilityFax != '')
                                        || (facility.FacilityPhone && facility.FacilityPhone != '')">
                                <div class="row p-0 m-0">
                                  <div class="col-12 p-0 m-0">
                                    <span *ngIf="facility.FacilityAddress1 && facility.FacilityAddress1 != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityAddress1}},</span>&nbsp;</span>
                                    <span *ngIf="facility.FacilityAddress2 && facility.FacilityAddress2 != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityAddress2}},</span>
                                    </span>
                                  </div>
                                  <div class="col-12 p-0 m-0">
                                    <span *ngIf="facility.FacilityCity && facility.FacilityCity != '' ">
                                      <span style="word-break: break-all;">{{facility.FacilityCity}},</span>&nbsp;</span>
                                    <span *ngIf="facility.FacilityState && facility.FacilityState != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityState}},</span>
                                    </span>
                                  </div>
                                  <div class="col-12 p-0 m-0">
                                    <span *ngIf="facility.FacilityZip && facility.FacilityZip != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityZip}},</span>&nbsp;</span>
                                    <span *ngIf="facility.FacilityFax && facility.FacilityFax != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityFax}},</span>
                                    </span>
                                  </div>
                                  <div class="col-12 p-0 m-0">
                                    <span *ngIf="facility.FacilityPhone && facility.FacilityPhone != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityPhone}}</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 p-0 m-0" *ngIf="(!facility.FacilityAddress1 || facility.FacilityAddress1 == '')
                                        && (!facility.FacilityAddress2 || facility.FacilityAddress2 == '')
                                        && (!facility.FacilityCity || facility.FacilityCity == '')
                                        && (!facility.FacilityState || facility.FacilityState == '')
                                        && (!facility.FacilityZip || facility.FacilityZip == '')
                                        && (!facility.FacilityFax || facility.FacilityFax == '')
                                        && (!facility.FacilityPhone || facility.FacilityPhone == '')">
                                <div class="row p-0 m-0">
                                  <div class="col-12 p-0 m-0">
                                    Address Not Specified
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 p-0 m-0">
                            <div class="row p-0 m-0">
                              <div class="col-12 p-0 m-0">
                                <span class="float-right">
                                  <strong>NPI
                                    #&nbsp;:</strong>&nbsp;&nbsp;{{facility.FacilityNPI.toUpperCase()}}
                                </span>
                              </div>
                              <div class="col-12 p-0 m-0">
                                <span class="float-right">
                                  <strong>CLIA
                                    #&nbsp;:</strong>&nbsp;&nbsp;{{facility.FacilityClia
                                  &&
                                  facility.FacilityClia
                                  != '' ? facility.FacilityClia : 'Not Specified'}}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-5">
                      <div class="col-12 m-0 p-0" style="font-size: 22px;">
                        <strong><u>Case Types</u></strong>
                      </div>
                      <div class="col-12 m-0 p-0 mt-3">
                        <table>
                          <tr>
                            <th><strong>Case Type Name</strong></th>
                            <th><strong>Display Name</strong></th>
                            <th><strong>Short Code</strong></th>
                            <th><strong>Category</strong></th>
                          </tr>
                          <tr *ngFor="let casetypeItem of selectedCasetypesList">
                            <td><span style="word-break: break-all;">{{casetypeItem.CaseTypeName}}</span>
                            </td>
                            <td>
                              <span style="word-break: break-all;">{{casetypeItem.CaseTypeDisplayname
                                &&
                                casetypeItem.CaseTypeDisplayname ?
                                casetypeItem.CaseTypeDisplayname : 'Not Specified'}}</span>
                            </td>
                            <td>
                              Not Specified
                            </td>
                            <td>
                              <span style="word-break: break-all;">{{casetypeItem.Category}}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-fluid p-5 m-0" style="color: #063970;font-size: 18px;" *ngIf="downloadReportFacility"
                id='facilityReportDetails2' #facilityReportDetails2>
                <div class="row p-0 m-0 mt-5">
                  <div class="col-12 p-0 m-0">
                    <div class="row p-0 m-0">
                      <div class="col-12 p-0 m-0 mt-3" style="font-size:26px">
                        <strong><u>Facility Location Details</u></strong>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-3">
                      <div class="col-12 p-0 m-0">
                        <strong><span style="word-break: break-all;">{{selectedPdfFacilityDetails.FacilityAccountName}}</span>&nbsp;<span
                            style="word-break: break-all;">({{selectedPdfFacilityDetails.FacilityAccountID}})</span></strong>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-2">
                      <div class="col-8 m-0 p-0">
                        <div class="row p-0 m-0">
                          <div class="col-12 p-0 m-0" *ngIf="(selectedPdfFacilityDetails.FacilityAddress1 && selectedPdfFacilityDetails.FacilityAddress1 != '')
                                    || (selectedPdfFacilityDetails.FacilityAddress2 && selectedPdfFacilityDetails.FacilityAddress2 != '')
                                    || (selectedPdfFacilityDetails.FacilityCity && selectedPdfFacilityDetails.FacilityCity != '' )
                                    || (selectedPdfFacilityDetails.FacilityState && selectedPdfFacilityDetails.FacilityState != '')
                                    || (selectedPdfFacilityDetails.FacilityZip && selectedPdfFacilityDetails.FacilityZip != '')
                                    || (selectedPdfFacilityDetails.FacilityFax && selectedPdfFacilityDetails.FacilityFax != '')
                                    || (selectedPdfFacilityDetails.FacilityPhone && selectedPdfFacilityDetails.FacilityPhone != '')">
                            <div class="row p-0 m-0">
                              <div class="col-12 p-0 m-0">
                                <span *ngIf="selectedPdfFacilityDetails.FacilityAddress1 && selectedPdfFacilityDetails.FacilityAddress1 != ''">
                                  <span style="word-break: break-all;">{{selectedPdfFacilityDetails.FacilityAddress1}},</span>&nbsp;</span>
                                <span *ngIf="selectedPdfFacilityDetails.FacilityAddress2 && selectedPdfFacilityDetails.FacilityAddress2 != ''">
                                  <span style="word-break: break-all;">{{selectedPdfFacilityDetails.FacilityAddress2}},</span>
                                </span>
                              </div>
                              <div class="col-12 p-0 m-0">
                                <span *ngIf="selectedPdfFacilityDetails.FacilityCity && selectedPdfFacilityDetails.FacilityCity != '' ">
                                  <span style="word-break: break-all;">{{selectedPdfFacilityDetails.FacilityCity}},</span>&nbsp;</span>
                                <span *ngIf="selectedPdfFacilityDetails.FacilityState && selectedPdfFacilityDetails.FacilityState != ''">
                                  <span style="word-break: break-all;">{{selectedPdfFacilityDetails.FacilityState}},</span>
                                </span>
                              </div>
                              <div class="col-12 p-0 m-0">
                                <span *ngIf="selectedPdfFacilityDetails.FacilityZip && selectedPdfFacilityDetails.FacilityZip != ''">
                                  <span style="word-break: break-all;">{{selectedPdfFacilityDetails.FacilityZip}},</span>&nbsp;</span>
                                <span *ngIf="selectedPdfFacilityDetails.FacilityFax && selectedPdfFacilityDetails.FacilityFax != ''">
                                  <span style="word-break: break-all;">{{selectedPdfFacilityDetails.FacilityFax}},</span>
                                </span>
                              </div>
                              <div class="col-12 p-0 m-0">
                                <span *ngIf="selectedPdfFacilityDetails.FacilityPhone && selectedPdfFacilityDetails.FacilityPhone != ''">
                                  <span style="word-break: break-all;">{{selectedPdfFacilityDetails.FacilityPhone}}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 p-0 m-0" *ngIf="(!selectedPdfFacilityDetails.FacilityAddress1 || selectedPdfFacilityDetails.FacilityAddress1 == '')
                                    && (!selectedPdfFacilityDetails.FacilityAddress2 || selectedPdfFacilityDetails.FacilityAddress2 == '')
                                    && (!selectedPdfFacilityDetails.FacilityCity || selectedPdfFacilityDetails.FacilityCity == '')
                                    && (!selectedPdfFacilityDetails.FacilityState || selectedPdfFacilityDetails.FacilityState == '')
                                    && (!selectedPdfFacilityDetails.FacilityZip || selectedPdfFacilityDetails.FacilityZip == '')
                                    && (!selectedPdfFacilityDetails.FacilityFax || selectedPdfFacilityDetails.FacilityFax == '')
                                    && (!selectedPdfFacilityDetails.FacilityPhone || selectedPdfFacilityDetails.FacilityPhone == '')">
                            <div class="row p-0 m-0">
                              <div class="col-12 p-0 m-0">
                                Address Not Specified
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 p-0 m-0">
                        <div class="row p-0 m-0">
                          <div class="col-12 p-0 m-0">
                            <span class="float-right">
                              <strong>NPI
                                #&nbsp;:</strong>&nbsp;&nbsp;{{selectedPdfFacilityDetails.FacilityNPI.toUpperCase()}}
                            </span>
                          </div>
                          <div class="col-12 p-0 m-0">
                            <span class="float-right">
                              <strong>CLIA
                                #&nbsp;:</strong>&nbsp;&nbsp;{{selectedPdfFacilityDetails.FacilityClia
                              &&
                              selectedPdfFacilityDetails.FacilityClia != '' ?
                              selectedPdfFacilityDetails.FacilityClia : 'Not
                              Specified'}}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-5">
                      <div class="col-12 m-0 p-0" style="font-size: 22px;">
                        <strong><u>Facility Users</u></strong>
                      </div>
                      <div class="col-12 p-0 m-0 mt-3">
                        <table>
                          <tr>
                            <th><strong>Name</strong></th>
                            <th><strong>Role</strong></th>
                            <th><strong>Email ID</strong></th>
                          </tr>
                          <tr *ngFor="let user of summaryData.UsersData">
                            <ng-container *ngIf="(user.UserAccountID == selectedPdfFacilityDetails.FacilityAccountID) && (user.RoleName && user.RoleName != '' && user.RoleName.toLowerCase() == 'physician')">
                              <td><span style="word-break: break-all;">{{user.Users &&
                                  user.Users !=
                                  '' ? user.Users : 'Not
                                  Specified'}}</span></td>
                              <td><span style="word-break: break-all;">{{user.RoleName &&
                                  user.RoleName != '' ? user.RoleName
                                  : 'Not
                                  Specified'}}</span></td>
                              <td>
                                <span style="word-break: break-all;">{{user.Email &&
                                  user.Email !=
                                  '' ? user.Email : 'Not
                                  Specified'}}</span>
                              </td>
                            </ng-container>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-5">
                      <div class="col-12 m-0 p-0" style="font-size: 22px;">
                        <strong><u>Case Types</u></strong>
                      </div>
                      <div class="col-12 m-0 p-0 mt-3">
                        <table>
                          <tr>
                            <th><strong>Case Type</strong></th>
                            <th><strong>Display Name</strong></th>
                            <th><strong>Short Code</strong></th>
                            <th><strong>Case Type Category</strong></th>
                          </tr>
                          <tr *ngFor="let casetype of summaryData.CasetypeData">
                            <ng-container *ngIf="casetype.CaseTypeAccountID == selectedPdfFacilityDetails.FacilityAccountID">
                              <td><span style="word-break: break-all;">{{casetype.CaseType}}</span>
                              </td>
                              <td>
                                <span style="word-break: break-all;">{{casetype.CaseTypeDisplayname
                                  &&
                                  casetype.CaseTypeDisplayname ?
                                  casetype.CaseTypeDisplayname : 'Not Specified'}}</span>
                              </td>
                              <td>
                                Not Specified
                              </td>
                              <td>
                                <span style="word-break: break-all;">{{casetype.Category &&
                                  casetype.Category ?
                                  casetype.Category : 'Not Specified'}}</span>
                              </td>
                            </ng-container>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-fluid p-5 m-0" style="color: #063970;font-size: 18px;" *ngIf="downloadReportAllFacility"
                id='facilityReportDetails3' #facilityReportDetails3>
                <div class="row p-0 m-0">
                  <div class="col-12 p-0 m-0">
                    <div class="row p-0 m-0">
                      <div class="col-12 p-0 m-0" style="font-size:26px">
                        <strong><u>Client Facility Details</u></strong>
                      </div>
                    </div>
                    <div class="row p-0 m-0">
                      <div class="col-8 p-0 m-0">
                        <div class="row p-0 m-0">
                          <div class="col-12 p-0 m-0 mt-2">
                            <strong>For Laboratory:&nbsp;:&nbsp;&nbsp;</strong><span style="word-break: break-all;">{{summaryData.OrgLabAccData.OrganizationName}}</span>
                          </div>
                          <div class="col-12 p-0 m-0 mt-2">
                            <strong><span style="word-break: break-all;">{{selectedPdfFacilityDetails.FacOrgDetails.FacilityOrgName}}</span>&nbsp;<span
                                style="word-break: break-all;">({{selectedPdfFacilityDetails.OrganizationID}})</span></strong>
                          </div>
                          <div class="col-12 p-0 m-0 mt-2">
                            <strong>EIN #&nbsp;:</strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{selectedPdfFacilityDetails.FacOrgDetails.FacilityEIN
                              &&
                              selectedPdfFacilityDetails.FacOrgDetails.FacilityEIN != '' ?
                              selectedPdfFacilityDetails.FacOrgDetails.FacilityEIN
                              : 'Not Specified'}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 p-0 m-0 mt-2">
                        <img class="float-right fitImage" style="border: 1px solid #e5e5e5;" *ngIf="selectedPdfFacilityDetails.FacOrgDetails.FacilityLogo && selectedPdfFacilityDetails.FacOrgDetails.FacilityLogo != ''"
                          [src]="'data:image/jpg;base64,' + selectedPdfFacilityDetails.FacOrgDetails.FacilityLogo" />
                        <div class="fitImage float-right text-center" style="font-size: 12-px;border: 1px solid #e5e5e5;"
                          *ngIf="!selectedPdfFacilityDetails.FacOrgDetails.FacilityLogo || selectedPdfFacilityDetails.FacOrgDetails.FacilityLogo == ''">
                          <div style="display: table;width: 100%; height: 100%;">
                            <div style="display: table-cell;vertical-align: middle;">No Logo
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-3">
                      <div class="col-12 p-0 m-0" style="font-size: 22px;">
                        <strong><u>Facility Locations</u></strong>
                      </div>
                    </div>
                    <div class="row p-0 m-0">
                      <div class="col-12 p-0 m-0" *ngFor="let facility of summaryData.FacAccData">
                        <div class="row p-0 m-0 mt-2">
                          <div class="col-12 p-0 m-0">
                            <strong><span style="word-break: break-all;">{{facility.FacilityAccountName}}</span>&nbsp;<span
                                style="word-break: break-all;">({{facility.FacilityAccountID}})</span></strong>
                          </div>
                        </div>
                        <div class="row p-0 m-0 mt-2 mb-5">
                          <div class="col-8 m-0 p-0">
                            <div class="row p-0 m-0">
                              <div class="col-12 p-0 m-0" *ngIf="(facility.FacilityAddress1 && facility.FacilityAddress1 != '')
                                      || (facility.FacilityAddress2 && facility.FacilityAddress2 != '')
                                      || (facility.FacilityCity && facility.FacilityCity != '' )
                                      || (facility.FacilityState && facility.FacilityState != '')
                                      || (facility.FacilityZip && facility.FacilityZip != '')
                                      || (facility.FacilityFax && facility.FacilityFax != '')
                                      || (facility.FacilityPhone && facility.FacilityPhone != '')">
                                <div class="row p-0 m-0">
                                  <div class="col-12 p-0 m-0">
                                    <span *ngIf="facility.FacilityAddress1 && facility.FacilityAddress1 != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityAddress1}},</span>&nbsp;</span>
                                    <span *ngIf="facility.FacilityAddress2 && facility.FacilityAddress2 != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityAddress2}},</span>
                                    </span>
                                  </div>
                                  <div class="col-12 p-0 m-0">
                                    <span *ngIf="facility.FacilityCity && facility.FacilityCity != '' ">
                                      <span style="word-break: break-all;">{{facility.FacilityCity}},</span>&nbsp;</span>
                                    <span *ngIf="facility.FacilityState && facility.FacilityState != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityState}},</span>
                                    </span>
                                  </div>
                                  <div class="col-12 p-0 m-0">
                                    <span *ngIf="facility.FacilityZip && facility.FacilityZip != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityZip}},</span>&nbsp;</span>
                                    <span *ngIf="facility.FacilityFax && facility.FacilityFax != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityFax}},</span>
                                    </span>
                                  </div>
                                  <div class="col-12 p-0 m-0">
                                    <span *ngIf="facility.FacilityPhone && facility.FacilityPhone != ''">
                                      <span style="word-break: break-all;">{{facility.FacilityPhone}}</span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 p-0 m-0" *ngIf="(!facility.FacilityAddress1 || facility.FacilityAddress1 == '')
                                      && (!facility.FacilityAddress2 || facility.FacilityAddress2 == '')
                                      && (!facility.FacilityCity || facility.FacilityCity == '')
                                      && (!facility.FacilityState || facility.FacilityState == '')
                                      && (!facility.FacilityZip || facility.FacilityZip == '')
                                      && (!facility.FacilityFax || facility.FacilityFax == '')
                                      && (!facility.FacilityPhone || facility.FacilityPhone == '')">
                                <div class="row p-0 m-0">
                                  <div class="col-12 p-0 m-0">
                                    Address Not Specified
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-4 p-0 m-0">
                            <div class="row p-0 m-0">
                              <div class="col-12 p-0 m-0">
                                <span class="float-right">
                                  <strong>NPI
                                    #&nbsp;:</strong>&nbsp;&nbsp;{{facility.FacilityNPI.toUpperCase()}}
                                </span>
                              </div>
                              <div class="col-12 p-0 m-0">
                                <span class="float-right">
                                  <strong>CLIA
                                    #&nbsp;:</strong>&nbsp;&nbsp;{{facility.FacilityClia
                                  &&
                                  facility.FacilityClia
                                  != '' ? facility.FacilityClia : 'Not Specified'}}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-5">
                      <div class="col-12 m-0 p-0" style="font-size: 22px;">
                        <strong><u>Case Types</u></strong>
                      </div>
                      <div class="col-12 m-0 p-0 mt-3">
                        <table>
                          <tr>
                            <th><strong>Case Type Name</strong></th>
                            <th><strong>Display Name</strong></th>
                            <th><strong>Short Code</strong></th>
                            <th><strong>Category</strong></th>
                          </tr>
                          <tr *ngFor="let casetypeItem of selectedCasetypesList">
                            <td><span style="word-break: break-all;">{{casetypeItem.CaseTypeName}}</span>
                            </td>
                            <td>
                              <span style="word-break: break-all;">{{casetypeItem.CaseTypeDisplayname
                                &&
                                casetypeItem.CaseTypeDisplayname ?
                                casetypeItem.CaseTypeDisplayname : 'Not Specified'}}</span>
                            </td>
                            <td>
                              Not Specified
                            </td>
                            <td>
                              <span style="word-break: break-all;">{{casetypeItem.Category}}</span>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container-fluid p-5 m-0" style="color: #063970;font-size: 18px;" *ngIf="downloadReportAllFacility"
                id='facilityReportDetails4' #facilityReportDetails4>
                <div class="row p-0 m-0 mt-5">
                  <div class="col-12 p-0 m-0">
                    <div class="row p-0 m-0">
                      <div class="col-12 p-0 m-0 mt-3" style="font-size:26px">
                        <strong><u>Facility Location Details</u></strong>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-3">
                      <div class="col-12 p-0 m-0">
                        <strong><span style="word-break: break-all;">{{selectedPdfFacilityDetails1.FacilityAccountName}}</span>&nbsp;<span
                            style="word-break: break-all;">({{selectedPdfFacilityDetails1.FacilityAccountID}})</span></strong>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-2">
                      <div class="col-8 m-0 p-0">
                        <div class="row p-0 m-0">
                          <div class="col-12 p-0 m-0" *ngIf="(selectedPdfFacilityDetails1.FacilityAddress1 && selectedPdfFacilityDetails1.FacilityAddress1 != '')
                                  || (selectedPdfFacilityDetails1.FacilityAddress2 && selectedPdfFacilityDetails1.FacilityAddress2 != '')
                                  || (selectedPdfFacilityDetails1.FacilityCity && selectedPdfFacilityDetails1.FacilityCity != '' )
                                  || (selectedPdfFacilityDetails1.FacilityState && selectedPdfFacilityDetails1.FacilityState != '')
                                  || (selectedPdfFacilityDetails1.FacilityZip && selectedPdfFacilityDetails1.FacilityZip != '')
                                  || (selectedPdfFacilityDetails1.FacilityFax && selectedPdfFacilityDetails1.FacilityFax != '')
                                  || (selectedPdfFacilityDetails1.FacilityPhone && selectedPdfFacilityDetails1.FacilityPhone != '')">
                            <div class="row p-0 m-0">
                              <div class="col-12 p-0 m-0">
                                <span *ngIf="selectedPdfFacilityDetails1.FacilityAddress1 && selectedPdfFacilityDetails1.FacilityAddress1 != ''">
                                  <span style="word-break: break-all;">{{selectedPdfFacilityDetails1.FacilityAddress1}},</span>&nbsp;</span>
                                <span *ngIf="selectedPdfFacilityDetails1.FacilityAddress2 && selectedPdfFacilityDetails1.FacilityAddress2 != ''">
                                  <span style="word-break: break-all;">{{selectedPdfFacilityDetails1.FacilityAddress2}},</span>
                                </span>
                              </div>
                              <div class="col-12 p-0 m-0">
                                <span *ngIf="selectedPdfFacilityDetails1.FacilityCity && selectedPdfFacilityDetails1.FacilityCity != '' ">
                                  <span style="word-break: break-all;">{{selectedPdfFacilityDetails1.FacilityCity}},</span>&nbsp;</span>
                                <span *ngIf="selectedPdfFacilityDetails1.FacilityState && selectedPdfFacilityDetails1.FacilityState != ''">
                                  <span style="word-break: break-all;">{{selectedPdfFacilityDetails1.FacilityState}},</span>
                                </span>
                              </div>
                              <div class="col-12 p-0 m-0">
                                <span *ngIf="selectedPdfFacilityDetails1.FacilityZip && selectedPdfFacilityDetails1.FacilityZip != ''">
                                  <span style="word-break: break-all;">{{selectedPdfFacilityDetails1.FacilityZip}},</span>&nbsp;</span>
                                <span *ngIf="selectedPdfFacilityDetails1.FacilityFax && selectedPdfFacilityDetails1.FacilityFax != ''">
                                  <span style="word-break: break-all;">{{selectedPdfFacilityDetails1.FacilityFax}},</span>
                                </span>
                              </div>
                              <div class="col-12 p-0 m-0">
                                <span *ngIf="selectedPdfFacilityDetails1.FacilityPhone && selectedPdfFacilityDetails1.FacilityPhone != ''">
                                  <span style="word-break: break-all;">{{selectedPdfFacilityDetails1.FacilityPhone}}</span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 p-0 m-0" *ngIf="(!selectedPdfFacilityDetails1.FacilityAddress1 || selectedPdfFacilityDetails1.FacilityAddress1 == '')
                                  && (!selectedPdfFacilityDetails1.FacilityAddress2 || selectedPdfFacilityDetails1.FacilityAddress2 == '')
                                  && (!selectedPdfFacilityDetails1.FacilityCity || selectedPdfFacilityDetails1.FacilityCity == '')
                                  && (!selectedPdfFacilityDetails1.FacilityState || selectedPdfFacilityDetails1.FacilityState == '')
                                  && (!selectedPdfFacilityDetails1.FacilityZip || selectedPdfFacilityDetails1.FacilityZip == '')
                                  && (!selectedPdfFacilityDetails1.FacilityFax || selectedPdfFacilityDetails1.FacilityFax == '')
                                  && (!selectedPdfFacilityDetails1.FacilityPhone || selectedPdfFacilityDetails1.FacilityPhone == '')">
                            <div class="row p-0 m-0">
                              <div class="col-12 p-0 m-0">
                                Address Not Specified
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 p-0 m-0">
                        <div class="row p-0 m-0">
                          <div class="col-12 p-0 m-0">
                            <span class="float-right">
                              <strong>NPI
                                #&nbsp;:</strong>&nbsp;&nbsp;{{selectedPdfFacilityDetails1.FacilityNPI.toUpperCase()}}
                            </span>
                          </div>
                          <div class="col-12 p-0 m-0">
                            <span class="float-right">
                              <strong>CLIA
                                #&nbsp;:</strong>&nbsp;&nbsp;{{selectedPdfFacilityDetails1.FacilityClia
                              &&
                              selectedPdfFacilityDetails1.FacilityClia != '' ?
                              selectedPdfFacilityDetails1.FacilityClia : 'Not
                              Specified'}}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-5">
                      <div class="col-12 m-0 p-0" style="font-size: 22px;">
                        <strong><u>Facility Users</u></strong>
                      </div>
                      <div class="col-12 p-0 m-0 mt-3">
                        <table>
                          <tr>
                            <th><strong>Name</strong></th>
                            <th><strong>Role</strong></th>
                            <th><strong>Email ID</strong></th>
                          </tr>
                          <tr *ngFor="let user of summaryData.UsersData">
                            <ng-container *ngIf="(user.UserAccountID == selectedPdfFacilityDetails1.FacilityAccountID) && (user.RoleName && user.RoleName != '' && user.RoleName.toLowerCase() == 'physician')">
                              <td><span style="word-break: break-all;">{{user.Users &&
                                  user.Users !=
                                  '' ? user.Users : 'Not
                                  Specified'}}</span></td>
                              <td><span style="word-break: break-all;">{{user.RoleName &&
                                  user.RoleName != '' ? user.RoleName
                                  : 'Not
                                  Specified'}}</span></td>
                              <td>
                                <span style="word-break: break-all;">{{user.Email &&
                                  user.Email !=
                                  '' ? user.Email : 'Not
                                  Specified'}}</span>
                              </td>
                            </ng-container>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div class="row p-0 m-0 mt-5">
                      <div class="col-12 m-0 p-0" style="font-size: 22px;">
                        <strong><u>Case Types</u></strong>
                      </div>
                      <div class="col-12 m-0 p-0 mt-3">
                        <table>
                          <tr>
                            <th><strong>Case Type</strong></th>
                            <th><strong>Display Name</strong></th>
                            <th><strong>Short Code</strong></th>
                            <th><strong>Case Type Category</strong></th>
                          </tr>
                          <tr *ngFor="let casetype of summaryData.CasetypeData">
                            <ng-container *ngIf="casetype.CaseTypeAccountID == selectedPdfFacilityDetails1.FacilityAccountID">
                              <td><span style="word-break: break-all;">{{casetype.CaseType}}</span>
                              </td>
                              <td>
                                <span style="word-break: break-all;">{{casetype.CaseTypeDisplayname
                                  &&
                                  casetype.CaseTypeDisplayname ?
                                  casetype.CaseTypeDisplayname : 'Not Specified'}}</span>
                              </td>
                              <td>
                                Not Specified
                              </td>
                              <td>
                                <span style="word-break: break-all;">{{casetype.Category &&
                                  casetype.Category ?
                                  casetype.Category : 'Not Specified'}}</span>
                              </td>
                            </ng-container>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id='casetypeReportDetails' #casetypeReportDetails>
                <div class="container-fluid p-5 m-0" style="color: #063970;font-size: 18px;" *ngIf="downloadReportCasetype"
                  id='casetypeReportDetails1' #casetypeReportDetails1>
                  <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0">
                      <div class="row p-0 m-0">
                        <div class="col-12 p-0 m-0" style="font-size:26px">
                          <strong><u>Case Type Details</u></strong>
                        </div>
                      </div>
                      <div class="row p-0 m-0">
                        <div class="col-8 p-0 m-0">
                          <div class="row p-0 m-0">
                            <div class="col-12 p-0 m-0 mt-2">
                              <strong>Laboratory
                                Name&nbsp;:&nbsp;&nbsp;</strong><span style="word-break: break-all;">{{summaryData.OrgLabAccData.OrganizationName}}</span>&nbsp;<span
                                style="word-break: break-all;">({{summaryData.OrgLabAccData.OrganizationID}})</span>
                            </div>
                            <div class="col-12 p-0 m-0 mt-2">
                              <strong>EIN #&nbsp;:</strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{summaryData.OrgLabAccData.Organizationein
                                &&
                                summaryData.OrgLabAccData.Organizationein != '' ?
                                summaryData.OrgLabAccData.Organizationein
                                : 'Not Specified'}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-4 p-0 m-0 mt-2">
                          <img class="float-right fitImage" style="border: 1px solid #e5e5e5;" *ngIf="summaryData.OrgLabAccData.Logo && summaryData.OrgLabAccData.Logo != ''"
                            [src]="'data:image/jpg;base64,' + summaryData.OrgLabAccData.Logo" />
                          <div class="fitImage float-right text-center" style="font-size: 12-px;border: 1px solid #e5e5e5;"
                            *ngIf="!summaryData.OrgLabAccData.Logo || summaryData.OrgLabAccData.Logo == ''">
                            <div style="display: table;width: 100%; height: 100%;">
                              <div style="display: table-cell;vertical-align: middle;">No
                                Logo</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row p-0 m-0 mt-3">
                        <div class="col-12 p-0 m-0" style="font-size: 22px;">
                          <strong><u>Lab Location</u></strong>
                        </div>
                      </div>
                      <div class="row p-0 m-0 mt-2">
                        <div class="col-12 p-0 m-0">
                          <strong><span style="word-break: break-all;">{{summaryData.OrgLabAccData.AccountName}}</span>&nbsp;<span
                              style="word-break: break-all;">({{summaryData.OrgLabAccData.AccountID}})</span></strong>
                        </div>
                      </div>
                      <div class="row p-0 m-0 mt-2">
                        <div class="col-8 m-0 p-0">
                          <div class="row p-0 m-0">
                            <div class="col-12 p-0 m-0" *ngIf="(summaryData.OrgLabAccData.Address1 && summaryData.OrgLabAccData.Address1 != '')
                                  || (summaryData.OrgLabAccData.Address2 && summaryData.OrgLabAccData.Address2 != '')
                                  || (summaryData.OrgLabAccData.City && summaryData.OrgLabAccData.City != '' )
                                  || (summaryData.OrgLabAccData.State && summaryData.OrgLabAccData.State != '')
                                  || (summaryData.OrgLabAccData.Zip && summaryData.OrgLabAccData.Zip != '')
                                  || (summaryData.OrgLabAccData.Fax && summaryData.OrgLabAccData.Fax != '')
                                  || (summaryData.OrgLabAccData.Phone && summaryData.OrgLabAccData.Phone != '')">
                              <div class="row p-0 m-0">
                                <div class="col-12 p-0 m-0">
                                  <span *ngIf="summaryData.OrgLabAccData.Address1 && summaryData.OrgLabAccData.Address1 != ''">
                                    <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Address1}},</span>&nbsp;</span>
                                  <span *ngIf="summaryData.OrgLabAccData.Address2 && summaryData.OrgLabAccData.Address2 != ''">
                                    <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Address2}},</span>
                                  </span>
                                </div>
                                <div class="col-12 p-0 m-0">
                                  <span *ngIf="summaryData.OrgLabAccData.City && summaryData.OrgLabAccData.City != '' ">
                                    <span style="word-break: break-all;">{{summaryData.OrgLabAccData.City}},</span>&nbsp;</span>
                                  <span *ngIf="summaryData.OrgLabAccData.State && summaryData.OrgLabAccData.State != ''">
                                    <span style="word-break: break-all;">{{summaryData.OrgLabAccData.State}},</span>
                                  </span>
                                </div>
                                <div class="col-12 p-0 m-0">
                                  <span *ngIf="summaryData.OrgLabAccData.Zip && summaryData.OrgLabAccData.Zip != ''">
                                    <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Zip}},</span>&nbsp;</span>
                                  <span *ngIf="summaryData.OrgLabAccData.Fax && summaryData.OrgLabAccData.Fax != ''">
                                    <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Fax}},</span>
                                  </span>
                                </div>
                                <div class="col-12 p-0 m-0">
                                  <span *ngIf="summaryData.OrgLabAccData.Phone && summaryData.OrgLabAccData.Phone != ''">
                                    <span style="word-break: break-all;">{{summaryData.OrgLabAccData.Phone}}</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 p-0 m-0" *ngIf="(!summaryData.OrgLabAccData.Address1 || summaryData.OrgLabAccData.Address1 == '')
                                  && (!summaryData.OrgLabAccData.Address2 || summaryData.OrgLabAccData.Address2 == '')
                                  && (!summaryData.OrgLabAccData.City || summaryData.OrgLabAccData.City == '')
                                  && (!summaryData.OrgLabAccData.State || summaryData.OrgLabAccData.State == '')
                                  && (!summaryData.OrgLabAccData.Zip || summaryData.OrgLabAccData.Zip == '')
                                  && (!summaryData.OrgLabAccData.Fax || summaryData.OrgLabAccData.Fax == '')
                                  && (!summaryData.OrgLabAccData.Phone || summaryData.OrgLabAccData.Phone == '')">
                              <div class="row p-0 m-0">
                                <div class="col-12 p-0 m-0">
                                  Address Not Specified
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-4 p-0 m-0">
                          <div class="row p-0 m-0">
                            <div class="col-12 p-0 m-0">
                              <span class="float-right">
                                <strong>NPI
                                  #&nbsp;:</strong>&nbsp;&nbsp;{{summaryData.OrgLabAccData.NPI.toUpperCase()}}
                              </span>
                            </div>
                            <div class="col-12 p-0 m-0">
                              <span class="float-right">
                                <strong>CLIA
                                  #&nbsp;:</strong>&nbsp;&nbsp;{{summaryData.OrgLabAccData.Clia
                                &&
                                summaryData.OrgLabAccData.Clia != '' ?
                                summaryData.OrgLabAccData.Clia : 'Not Specified'}}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row p-0 m-0 mt-5">
                        <div class="col-12 m-0 p-0" style="font-size: 22px;">
                          <strong><u>Lab Administrator</u></strong>
                        </div>
                        <div class="col-12 p-0 m-0 mt-3">
                          <table>
                            <tr>
                              <th><strong>Name</strong></th>
                              <th><strong>Role</strong></th>
                              <th><strong>Email ID</strong></th>
                            </tr>
                            <tr *ngFor="let user of summaryData.UsersData">
                              <ng-container *ngIf="(user.UserAccountID == summaryData.OrgLabAccData.AccountID) && (user.RoleName && user.RoleName != '' && user.RoleName.toLowerCase() == 'lab administrator')">
                                <td><span style="word-break: break-all;">{{user.Users &&
                                    user.Users
                                    != '' ? user.Users : 'Not
                                    Specified'}}</span></td>
                                <td><span style="word-break: break-all;">{{user.RoleName &&
                                    user.RoleName != '' ?
                                    user.RoleName : 'Not
                                    Specified'}}</span></td>
                                <td>
                                  <span style="word-break: break-all;">{{user.Email &&
                                    user.Email
                                    != '' ? user.Email : 'Not
                                    Specified'}}</span>
                                </td>
                                <td style="border:none">&nbsp;</td>
                              </ng-container>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div class="row p-0 m-0 mt-5">
                        <div class="col-12 m-0 p-0" style="font-size: 22px;">
                          <strong><u>Case Types</u></strong>
                        </div>
                        <div class="col-12 m-0 p-0 mt-3">
                          <table>
                            <tr>
                              <th><strong>Case Type Name</strong></th>
                              <th><strong>Category</strong></th>
                            </tr>
                            <tr *ngFor="let casetypeItem of selectedCasetypesList">
                              <td><span style="word-break: break-all;">{{casetypeItem.CaseTypeName}}</span>
                              </td>
                              <td>
                                <span style="word-break: break-all;">{{casetypeItem.Category}}</span>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container-fluid p-5 m-0" style="color: #063970;font-size: 18px;" *ngIf="downloadReportCasetype"
                  id='casetypeReportDetails2' #casetypeReportDetails2>
                  <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0">
                      <div class="row p-0 m-0">
                        <div class="col-12 p-0 m-0" style="font-size:26px">
                          <strong><u>Case Types:</u></strong>
                        </div>
                      </div>
                      <div class="row p-0 m-0">
                        <div class="col-8 p-0 m-0">
                          <div class="row p-0 m-0">
                            <div class="col-12 p-0 m-0 mt-2">
                              <strong>
                                Name&nbsp;:&nbsp;&nbsp;</strong><span style="word-break: break-all;">{{selectedPdfCasetypeDetails.basicDetails.Casetype}}</span>
                            </div>
                            <div class="col-12 p-0 m-0 mt-2">
                              <strong>Category&nbsp;:</strong>&nbsp;&nbsp;<span style="word-break: break-all;">{{selectedPdfCasetypeDetails.basicDetails.Category}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row p-0 m-0 mt-3" *ngIf="selectedPdfCasetypeDetails.CaseTypeTestExtractionDetails && selectedPdfCasetypeDetails.CaseTypeTestExtractionDetails.length > 0">
                        <div class="col-12 p-0 m-0" style="font-size: 22px;">
                          <strong><u>Extraction Procedures&nbsp;:</u></strong>
                        </div>
                        <div class="col-12 p-0 m-0 mt-2">
                          <table>
                            <tr>
                              <th><strong>Name</strong></th>
                              <th><strong>Sequence</strong></th>
                            </tr>
                            <tr *ngFor="let casetypeDataExtn of selectedPdfCasetypeDetails.CaseTypeTestExtractionDetails">
                              <td><span style="word-break: break-all;">{{casetypeDataExtn.ExtractionProcedure}}</span>
                              </td>
                              <td><span style="word-break: break-all;">{{casetypeDataExtn.Sequence}}</span>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div class="row p-0 m-0 mt-5" *ngIf="selectedPdfCasetypeDetails.CaseTypeSiteDetails && selectedPdfCasetypeDetails.CaseTypeSiteDetails.length > 0">
                        <div class="col-12 m-0 p-0" style="font-size: 22px;">
                          <strong><u>Sites&nbsp;:</u></strong>
                        </div>
                        <div class="col-12 p-0 m-0 mt-3">
                          <table>
                            <tr>
                              <th><strong>Site Name</strong></th>
                              <th><strong>Body Site</strong></th>
                              <th><strong>Site Order</strong></th>
                            </tr>
                            <tr *ngFor="let casetypeDataSites of selectedPdfCasetypeDetails.CaseTypeSiteDetails">
                              <td><span style="word-break: break-all;">{{casetypeDataSites.SiteName}}</span>
                              </td>
                              <td><span style="word-break: break-all;">{{casetypeDataSites.BodySite}}</span>
                              </td>
                              <td><span style="word-break: break-all;">{{casetypeDataSites.SiteOrder}}</span>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container-fluid p-5 m-0" style="color: #063970;font-size: 18px;" *ngIf="downloadReportCasetype"
                  id='casetypeReportDetails3' #casetypeReportDetails3>
                  <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0">
                      <div class="row p-0 m-0 mt-3" *ngIf="selectedPdfCasetypeDetails.CaseTypeRuleOutDetails && selectedPdfCasetypeDetails.CaseTypeRuleOutDetails.length > 0">
                        <div class="col-12 p-0 m-0" style="font-size: 22px;">
                          <strong><u>Rule Outs&nbsp;:</u></strong>
                        </div>
                        <div class="col-12 p-0 m-0 mt-2">
                          <!-- <table>
                                <tr>
                                  <th><strong>Name</strong></th>
                                  <th><strong>Sequence</strong></th>
                                </tr>
                                <tr *ngFor="let casetypeDataExtn of selectedPdfCasetypeDetails.CaseTypeRuleOutDetails">
                                  <td>{{casetypeDataExtn.ExtractionProcedure}}</td>
                                  <td>{{casetypeDataExtn.Sequence}}</td>
                                </tr>
                              </table> -->
                        </div>
                      </div>
                      <div class="row p-0 m-0 mt-5" *ngIf="selectedPdfCasetypeDetails.CaseTypeQualityAttributeDetails && selectedPdfCasetypeDetails.CaseTypeQualityAttributeDetails.length > 0">
                        <div class="col-12 m-0 p-0" style="font-size: 22px;">
                          <strong><u>Quality Attribute&nbsp;:</u></strong>
                        </div>
                        <div class="col-12 p-0 m-0 mt-3">
                          <table>
                            <tr>
                              <th><strong>Category</strong></th>
                              <th><strong>Name</strong></th>
                            </tr>
                            <tr *ngFor="let casetypeDataQA of selectedPdfCasetypeDetails.CaseTypeQualityAttributeDetails">
                              <td><span style="word-break: break-all;">{{casetypeDataQA.QualityAttributeCategory}}</span>
                              </td>
                              <td><span style="word-break: break-all;">{{casetypeDataQA.QualityAttributeName}}</span>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container-fluid p-5 m-0" style="color: #063970;font-size: 18px;" *ngIf="downloadReportCasetype"
                  id='casetypeReportDetails4' #casetypeReportDetails4>
                  <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0">
                      <div class="row p-0 m-0 mt-3" *ngIf="selectedPdfCasetypeDetails.CaseTypeAttributeDetails && selectedPdfCasetypeDetails.CaseTypeAttributeDetails.length > 0">
                        <div class="col-12 p-0 m-0" style="font-size: 22px;">
                          <strong><u>Gross Attributes&nbsp;:</u></strong>
                        </div>
                        <div class="col-12 p-0 m-0 mt-2">
                          <table>
                            <tr>
                              <th><strong>Attribute Type</strong></th>
                              <th><strong>Attribute Name</strong></th>
                              <th><strong>Sequence Order</strong></th>
                            </tr>
                            <tr *ngFor="let casetypeDataAD of selectedPdfCasetypeDetails.CaseTypeAttributeDetails">
                              <td><span style="word-break: break-all;">{{casetypeDataAD.AttributeType}}</span>
                              </td>
                              <td><span style="word-break: break-all;">{{casetypeDataAD.AttributeName}}</span>
                              </td>
                              <td><span style="word-break: break-all;">{{casetypeDataAD.SequenceOrder}}</span>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div class="row p-0 m-0 mt-5" *ngIf="selectedPdfCasetypeDetails.CaseTypeTemplateDetails && selectedPdfCasetypeDetails.CaseTypeTemplateDetails.length > 0">
                        <div class="col-12 m-0 p-0" style="font-size: 22px;">
                          <strong><u>Template Details&nbsp;:</u></strong>
                        </div>
                        <div class="col-12 p-0 m-0 mt-3">
                          <table>
                            <tr>
                              <th><strong>Template Name</strong></th>
                              <th><strong>Description</strong></th>
                              <th><strong>Is Default</strong></th>
                            </tr>
                            <tr *ngFor="let casetypeDataTD of selectedPdfCasetypeDetails.CaseTypeTemplateDetails">
                              <td><span style="word-break: break-all;">{{casetypeDataTD.TemplateName}}</span>
                              </td>
                              <td><span style="word-break: break-all;">{{casetypeDataTD.Description}}</span>
                              </td>
                              <td><span style="word-break: break-all;">{{casetypeDataTD.IsDefault}}</span>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container-fluid p-5 m-0" style="color: #063970;font-size: 18px;" *ngIf="downloadReportCasetype"
                  id='casetypeReportDetails5' #casetypeReportDetails5>
                  <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0">
                      <div class="row p-0 m-0 mt-3" *ngIf="selectedPdfCasetypeDetails.CaseTypeDiagnosisDetails && selectedPdfCasetypeDetails.CaseTypeDiagnosisDetails.length > 0">
                        <div class="col-12 p-0 m-0" style="font-size: 22px;">
                          <strong><u>Gross Attributes&nbsp;:</u></strong>
                        </div>
                        <div class="col-12 p-0 m-0 mt-2">
                          <table>
                            <tr>
                              <th><strong>Diagnosis</strong></th>
                              <th><strong>Diagnosis Code</strong></th>
                              <th><strong>Diagnostic Summary</strong></th>
                              <th><strong>Microscopic Notes</strong></th>
                              <th><strong>Icd Codes</strong></th>
                            </tr>
                            <tr *ngFor="let casetypeDataDD of selectedPdfCasetypeDetails.CaseTypeDiagnosisDetails">
                              <td><span style="word-break: break-all;">{{casetypeDataDD.Diagnosis}}</span>
                              </td>
                              <td><span style="word-break: break-all;">{{casetypeDataDD.DiagnosisCode}}</span>
                              </td>
                              <td><span style="word-break: break-all;">{{casetypeDataDD.DiagnosticSummary}}</span>
                              </td>
                              <td><span style="word-break: break-all;">{{casetypeDataDD.MicroscopicNotes}}</span>
                              </td>
                              <td><span style="word-break: break-all;">{{casetypeDataDD.Icd9Codes}}</span>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                      <div class="row p-0 m-0 mt-5" *ngIf="selectedPdfCasetypeDetails.CaseTypeICD9Details && selectedPdfCasetypeDetails.CaseTypeICD9Details.length > 0">
                        <div class="col-12 m-0 p-0" style="font-size: 22px;">
                          <strong><u>ICD Codes&nbsp;:</u></strong>
                        </div>
                        <div class="col-12 p-0 m-0 mt-3">
                          <table>
                            <tr>
                              <th><strong>ICD Code</strong></th>
                              <th><strong>ICD CodeName</strong></th>
                              <th><strong>ICD CodeDescription</strong></th>
                              <th><strong>ICD Standard</strong></th>
                            </tr>
                            <tr *ngFor="let casetypeDataICD of selectedPdfCasetypeDetails.CaseTypeICD9Details">
                              <td><span style="word-break: break-all;">{{casetypeDataICD.ICD9Code}}</span>
                              </td>
                              <td><span style="word-break: break-all;">{{casetypeDataICD.ICD9CodeName}}</span>
                              </td>
                              <td><span style="word-break: break-all;">{{casetypeDataICD.ICD9CodeDescription}}</span>
                              </td>
                              <td> <span style="word-break: break-all;">{{casetypeDataICD.ICDStandard}}</span>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row p-0 m-0">
              <div class="col-sm-12 align-center pr-0 pl-4 mt-4 mb-4">
                <div class="col-sm-4 p-0">
                  <button mat-button [matMenuTriggerFor]="menu" class="admin-btn-success">Export</button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="downloadCaseTypeDetails()">All Casetypes
                      Details</button>
                    <button mat-menu-item (click)="downloadSetupDetails('lab')">Lab Details</button>
                  </mat-menu>
                </div>
                <div class="col-sm-8 p-0 save-btn-wrap">
                  <button mat-raised-button class="admin-btn-success mr-4" type="reset" (click)="setupNewCustomerClose(compendiumGroup)">
                    Finish
                  </button>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </form>
</div>


<!-- Manage order popup -->
<div class="modal model-dialog row p-0 m-0 mt-2" id="manageOrderCode" aria-hidden="true" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-content">
    <div class="admin-model-header">
      <span type="button" (click)="orderCodePopupClose()" class="mr-2 flot-rgt">
        <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24 cursor" title="Close">
      </span>
      <h4 class="modal-title p-1">Manage Panels</h4>
    </div>
    <div class="row modal-body pr-4 pl-4">
      <div class="col-sm-12 p-0 m-0 button-wrap align-center border-color">
        <div class="col-8 p-0 m-0">
          <span><strong>{{this.caseType}}</strong></span>
        </div>
        <div class="col-4 p-0 m-0 flex-right checkboxAll">
          <mat-checkbox [color]="task.color" [(checked)]="checkAllPanel" (change)="checkAllPanels($event.checked)"
            class="float-right ml-2 mt-2">
            <span><strong>All Panels</strong></span>
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="col-4">
          <mat-form-field appearance="outline">
            <mat-label>Search</mat-label>
            <input matInput [(ngModel)]="searchString">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="modal-body row body-height">
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" [style.display]="
                filterLeafNode(node) ? 'none' : 'block'
              "
          matTreeNodeToggle>
          <li class="mat-tree-node">
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled></button>
            <mat-checkbox [color]="task.color" class="checklist-leaf-node" (change)="todoItemSelectionToggle($event.checked,node)"
              [checked]="node.selected">
              {{node.name}}</mat-checkbox>
          </li>
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" [style.display]="filterParentNode(node) ? 'none' : 'block'">
          <li>
            <div class="mat-tree-node">
              <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <mat-checkbox [color]="task.color" [checked]="node.selected" [indeterminate]="node.indeterminate && !node.selected"
                (change)="todoItemSelectionToggle($event.checked,node)">{{node.name}}</mat-checkbox>
            </div>
            <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
              <ng-container matTreeNodeOutlet></ng-container>
            </ul>
          </li>
        </mat-nested-tree-node>
      </mat-tree>
      <!-- </div>
          </section>
        </div> -->
      <!-- </div> -->
      <!-- <div class="row ml-4 pl-4">
        <ngx-treeview [items]="items"></ngx-treeview>
      </div> -->
    </div>
    <div class="row modal-body pr-4 pl-4" *ngIf="resultSetList.length > 0">
      <div class="col-sm-12 p-0 m-0 button-wrap align-center border-color">
        <div class="col-12 p-0 m-0">
          <span><strong>Result Set(HTML Configurations)</strong></span>
        </div>
      </div>
      <div class="row col-12 m-2 p-2">
        <mat-radio-group class="col-sm-4" *ngFor="let item of resultSetList"><strong>
            <mat-radio-button [checked]="item.checked" class=" pr-2" (change)="checkResultSet($event)" value="{{item.ID}}">{{item.ResultObjectName}}</mat-radio-button>
          </strong></mat-radio-group>
      </div>
    </div>
    <div class="row modal-body pr-4 pl-4" *ngIf="instrumentsList.length > 0">
      <div class="col-sm-12 p-0 m-0 button-wrap align-center border-color">
        <div class="col-6 p-0 m-0">
          <span><strong>Instrument Mapping</strong></span>
        </div>
        <div class="col-6 p-0 m-0 flex-right checkboxAll">
          <mat-checkbox [color]="task.color" [(checked)]="checkAllInstruments" class="float-right ml-2 mt-2" (change)="selectAllInstruments($event)">
            <span><strong>All Instruments</strong></span>
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="row modal-body pr-4 pl-4" *ngIf="instrumentsList.length > 0">
      <div class="row col-sm-12 p-0 m-0 d-flex">
        <div class="p-0 m-0 checkboxAll" *ngFor="let item of instrumentsList">
          <mat-checkbox [color]="task.color" class="col-sm-4" [checked]="item.checked" class=" pr-2" (change)="checkInstrumentValue($event,item.InstrumentName)"
            value="{{item.InstrumentName}}">
            <strong>{{item.InstrumentName}}
            </strong>
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="modal-footer align-center">
      <div class="col-sm-12 p-0 m-0">
        <div class="align-center pr-0 mt-2 mb-2">
          <div class="col-sm-4 p-0">
            <button mat-raised-button class="admin-btn-success mr-4" type="reset" (click)="orderCodePopupClose()">
              Close
            </button>
          </div>
          <div class="col-sm-8 p-0 save-btn-wrap">
            <button mat-raised-button class="admin-btn-success" (click)="orderCodeSave()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Alert -->
<div class="model-dialog row p-0 m-0 mt-2" *ngIf="alertPopUp" aria-hidden="true" data-backdrop="static"
data-keyboard="false">
<div class="col-12 p-0 m-0">
  <!-- The Modal -->
  <div class="col-sm-12 align-center modal" style="background: #393d406b;">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="admin-model-header">
        <h4 class="modal-title p-1">Setup New Customer</h4>
      </div>
      <!-- Modal body -->
      <div class="modal-body" style="text-align: center;font-size:16px">
        <span class="title-card">Save Changes ?
        </span><br><span class="title-card">
          If not saved, any modifications made will be lost.
        </span><br>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer mt-4" style="display: block;margin: auto;">
        <button type="button" mat-raised-button class="admin-btn-success mr-4" (click)="saveAndOrProceed('save',compendiumGroup)">Save
          and Proceed</button>
        <button type="button" mat-raised-button class="admin-btn-success mr-4" (click)="saveAndOrProceed('dontsave',compendiumGroup)">Proceed
          without Saving</button>
        <button type="button" mat-raised-button class="admin-btn-success" (click)="alertPopUp = false">Stay
          in this Page</button>
      </div>
    </div>
  </div>
</div>
</div>
