<div class="modal-content" style="width: 100vw; height: 100vh;">
    <form [formGroup]="frm_GenericBulkUpload" #commentNgForm="ngForm" autocomplete="off">
        <div class="col-sm-12 d-flex pt-2">
            <div class="col-sm-11 m-0 d-flex p-0">
                <div class="vdm-heading">
                    <span> Generic Bulk Upload </span>
                </div>
            </div>
            <div class="col-sm-1 button-wrap p-0">
                <span type="button" data-dismiss="modal" (click)="closeModal()" class="cursor">
                    <img src="../../../../assets/icons/Close_black.svg" alt="Close" class="material-icons"
                        title="Close">
                </span>
            </div>
        </div>

        <div class="p-0 mt-3" *ngIf="!FormDataEntry">
            <div class="container-fluid p-0 m-0">
                <div class="row mb-1 mx-0">
                    <div class="col-lg-12 d-flex p-0 m-md-1 m-lg-0">
                        <div class="col-sm-12 d-flex m-0">
                            <div class="col-sm-11 d-flex">
                                <div class="col-md-2 pl-0">
                                    <mat-form-field appearance="outline" class="frmMatSelect w-100">
                                        <mat-label value="none">
                                            <strong>Deployment
                                            </strong>
                                        </mat-label>
                                        <mat-select disableOptionCentering formControlName="frmDeploymentKey"
                                            (selectionChange)="onChangeDep($event.value)"
                                            style="padding: 0.17rem 0px !important; border: none !important;">
                                            <mat-option *ngFor="let i of DeploymentKeys"
                                                value="{{i.toString().toUpperCase()}}">{{i}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
    
                                <div class="col-md-3 pl-0">
                                    <mat-form-field appearance="outline" class="frmMatSelect w-100">
                                        <mat-label value="none">
                                            <strong>Select Table
                                            </strong>
                                        </mat-label>
                                        <mat-select disableOptionCentering formControlName="SheetName"
                                            (selectionChange)="onChangeTable($event.value)"
                                            style="padding: 0.17rem 0px !important; border: none !important;">
                                            <mat-option *ngFor="let submenu of submenuData"
                                                [value]="submenu">{{submenu.DisplayName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
    
                                <div class="col-sm-7 d-flex p-0" *ngIf="disableDeployment">
                                    <mat-radio-group class="radio-btn col-sm-5 p-0 mb-0 w-0 h-0">
                                        <mat-radio-button [color]="task?.color" id="radio_1" value="bulkUpdate"  (change)="onChangeRadioButton('bulkUpdate')" class="mr-5">
                                            Bulk Update
                                        </mat-radio-button>
                                        <mat-radio-button [color]="task?.color" id="radio_2" value="addNew"  (change)="onChangeRadioButton('addNew')">
                                            Add New
                                        </mat-radio-button>
                                    </mat-radio-group>
    
                                    <div class="col-sm-5  p-0 ml-1">
                                        <div class="d-flex align-center mr-2 mt-1"  [ngClass]="{'disabled-toggle': bulkUpdate}">
                                            <span class="mr-2">Allow Identity Insert: </span>
                                            <label class="ct-tag-toggle">
                                                <input  [checked]="allowInsertValue"  [disabled]="bulkUpdate"
                                                 (change)="onChangeIdentity($event.target.checked)" type="checkbox">
                                                <span class="slider"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div *ngIf="addNew || bulkUpdate"  class="create-upload-btn d-flex ml-2 justify-content-center align-items-center" style="cursor: pointer;" [ngClass]="{'btnDisabled': isBtnDisabled}"  matTooltip="Download" (click)="downloadTemplate()">
                                        <div *ngIf="!hideCreate" class="create-only">
                                            Template
                                        </div>
                        
                                        <div class="Standing-line-create"  *ngIf="addNew || bulkUpdate" >
                                        </div>
                        
                                        <div *ngIf="!hideUpload" class="upload-icon"><img
                                                src="../../../../assets/icons/Bulk ExcelDownload_White.svg"
                                                style="height: 24px;width: 24px;">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 d-flex pt-4">
            <div class="col-sm-5 m-0 d-flex p-0">
                <div class="vdm-heading">
                    <span *ngIf="addNew && gridDisplay"> Result Upload </span>
                    <span *ngIf="bulkUpdate && gridDisplay"> Result Update </span>
                </div>
            </div>
        </div>
        <div class="col-sm-12 d-flex pt-1">  
            <div class="col-sm-2"></div>
            <div class="col-sm-7">
                <div  class="col-sm-12" *ngIf="postUpload && !failureMessage">
                    <div class="col-sm-12 p-0 text-center" *ngIf="failureCount!=0 || failureCount!='0'">
                        <span class="bold-text"> Data Upload Status: </span>
                        <span  class="bold-text"> Valid = </span> {{successCount}}  |  <span class="bold-text"> InValid = </span> {{failureCount}}
                    </div> 
                    <div class="col-sm-12 p-0 text-center" *ngIf="failureCount==0 || failureCount=='0'">
                        <span class="bold-text" *ngIf="addNew">All data is uploaded successfully</span>
                        <span class="bold-text" *ngIf="bulkUpdate">All data is updated successfully</span>
                    </div> 
                    <div class="col-sm-12 invalid-message text-center" *ngIf="failureCount!=0 || failureCount!='0'">This entire transaction has been rolled back</div>
                </div>
                <div  class="col-sm-12" *ngIf="failureMessage && postUpload">
                    <div class="col-sm-12 p-0 text-center">
                        <span class="bold-text"> Data Upload Status: </span>
                        <span class="invalid-message" style="white-space: pre-wrap;display: block;">{{message}} </span>
                    </div> 
                    <div class="col-sm-12 invalid-message text-center" *ngIf="failureCount!=0 || failureCount!='0'">This entire transaction has been rolled back</div>
                </div>
            </div>
            <div class="col-sm-3"> 
                <div class="col-sm-12  button-wrap">
                    <button mat-raised-button class="ct-admin-btn-success-outline mr-3" *ngIf="gridDisplay && !postUpload"
                        (click)="backSelect()">Cancel</button>
                    <button mat-raised-button class="admin-button ct-admin-btn-success" *ngIf="gridDisplay && !postUpload"
                        (click)="GenericUpload()">Approve</button>
                        <button mat-raised-button class="admin-button ct-admin-btn-success mr-3" *ngIf="postUpload"
                        (click)="excelExport()">Download</button>
                        <button mat-raised-button class="ct-admin-btn-success-outline mr-3" *ngIf="postUpload"
                        (click)="backSelect()">Finish</button>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div class="col-sm-12 p-0" *ngIf="!gridDisplay">
                <div class="image-height-bulk-upload" (fileDropped)="onFileDropped($event)" appDnd>
                    <input type="file" id="fileDropRef" #fileDropRef (click)="fileDropRef.value = null"
                        (change)="onFileChange($event)" hidden />

                    <!-- Conditional wrapper to disable interaction -->
                    <div [ngClass]="{'disabled': !uploadClicked}">
                        <span class="align-center img-wrap">
                            <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
                        </span>
                        <span>Drag & Drop excel files here</span>
                        <span class="align-center">Or</span>
                    </div>

                    <button class="Expbtn btn-sm rounded btn mt-2 browse ml-5" style="background-color: #3874ff;"
                        [disabled]="!uploadClicked" (click)="fileDropRef.click()">
                        Browse file
                    </button>
                </div>
            </div>
            <div *ngIf="gridWidth > 0 && gridDisplay">
                <wj-flex-grid class="grid-properties p-2" [isReadOnly]="true" [itemsSource]="gridData"
                (initialized)="initGrid(grid)" #grid [headersVisibility]="'Column'"
                (formatItem)="formatItem(grid, $event)">
                <wj-flex-grid-column [header]="'Result Message'" [binding]="ResultMessage" *ngIf="postUpload && !failureMessage" [allowResizing]="true"
                [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
                <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                        <span [ngStyle]="{color: highlightNotes? ((cell.item.ResultMessage.toLowerCase()=='success' ||cell.item.ResultMessage.toLowerCase()=='valid' )?'green':cell.item.ResultMessage.toLowerCase()=='ignored' ? '#ff9800' : 'red'):''}">{{cell.item.ResultMessage}}
                    </span>
                </ng-template>
            </wj-flex-grid-column>
            <wj-flex-grid-column *ngFor="let columnname of sheetHeader"  [binding]="columnname?.toString().toLowerCase()" [header]="columnname" [visible]="!(columnname == 'ResultMessage')"
                        [allowResizing]="true" [width]="175" [allowDragging]="false" [allowSorting]="false"
                        [format]="'d'">
            </wj-flex-grid-column>
             
                <wj-flex-grid-filter #filter></wj-flex-grid-filter>
            </wj-flex-grid>
            </div>
        </div>
    </form>
</div>
