import { Clipboard } from "@angular/cdk/clipboard";
import { Component, EventEmitter, Input, OnChanges, Output, Pipe, PipeTransform, SimpleChanges } from '@angular/core';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { CommonService } from '../../core/services/commonservices';
import { VitalHttpServices } from '../../core/services/VitalHttpServices';

declare const copyToClipboard: any;

@Component({
  selector: 'vital-general-main-card',
  templateUrl: './vital-general-main-card.component.html',
  styleUrls: ['./vital-general-main-card.component.css']
})
export class VitalGenericMainCardComponent implements OnChanges {
  @Output() scrollUpMaincard = new EventEmitter()
  isExpanded = false;
  @Input()
  public menuJson: any;
  @Input()
  data: any;
  @Input()
  mainCard: any;
  @Input()
  accountid: any;
  @Input()
  public rolepermission: any;
  @Input()
  public rolemenu: any;
  @Input()
  public roleMenuPermissions: any;
  @Input()
  cardtype: any;
  @Input()
  labels: any;
  @Input()
  secondarykeys: any;
  @Input()
  GroupCode: any
  public context = sessionStorage.getItem('search_context');
  ncols = 3;
  action: { id: number, actiontype: string };
  actions: any=[{}];
  caseTypeRadio: number = 0;
  secondayKeysString: string = "";
  downloadJsonHref: SafeUrl;
  exportData: any;
  file: any;
  public userId;
  public ifUserType = false;
  public actionbutton;
  public roledatamenu;
  public actiondata;
  public roledatamenus;
  mainCardNew: any;

  @Output() changeTab : EventEmitter<any> = new EventEmitter();


  // public GetQuery1(keyword: string) {
  //   let query: string = "";
  //   let mainQueryIndex: number = this.vitalHttpServices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
  //   if (mainQueryIndex > -1)
  //     query = this.vitalHttpServices.mainQueryList[mainQueryIndex].Query;
  //   return query;
  // }

  constructor(public vitalHttpServices: VitalHttpServices, public commonService: CommonService, private datashare: DataShareService, private sanitizer: DomSanitizer, private ngxService: NgxUiLoaderService, private _snackbar: MatSnackBar, private clipboard: Clipboard) {
    let i: number = 0;
    // Import file
    // for (i = 0; i < 6; i++) {

    //   let action = { id: i, actiontype: "Action " + i.toString(), checked: false, buttontitle: "Import file" };
    //   this.actions.push(action);

    // }
  }
  ngOnInit() {
    try{
    this.labels = Object.keys(this.mainCardNew)
    this.removeAdditionalFields();
    if (this.vitalHttpServices.mainQueryList.find(mainQuery => mainQuery.Keyword == 'casetype')) {
      if (this.labels.some(va => va.toString().toLowerCase() == "id")) {
        this.labels.splice(this.labels.findIndex(e => e.toString().toLowerCase() == 'id'), 1);
      }
    }
  }
  catch(error) {
    console.error(error);
    this._snackbar.open('An error occurred while processing your request','Close');
    this.ngxService.stop('entityLoad');
  }
    // this.datashare.userdata.subscribe(data => {
    //   if (data) {
    //     this.mainCard = data;
    //   }
    // })
  }
  ngOnChanges(changes: SimpleChanges) {
    // this.datashare.getuserdata("");
    // this.mainCard.Organization
    let array = [];
    // let index = this.mainCrdNew.GroupName && this.mainCrdNew.GroupName.toString().lastIndexOf('(');
    // let OrgIndex = this.mainCrdNew.GroupName && this.mainCrdNew.GroupName.toString().slice(0,index);
    if (this.mainCard.Organization) {
      sessionStorage.setItem("Org_Name", this.mainCard.Organization);
    }
    // sessionStorage.setItem("Org_Name", this.mainCard.Lab_Organization);
    let mainCardArray = [];
    mainCardArray.push(this.mainCard);
    // for (let i = 0; i < mainCardArray.length; i++) {
    let primary = {};
    for (let [key, value] of Object.entries(mainCardArray[0])) {
      if (!value) {
        value = 'Not Specified';
      }
      if (key.toString().toLowerCase() === 'organization') {
        value = mainCardArray[0].Organization + '(' + mainCardArray[0].Org_ID + ')';
        primary["Group_Name"] = value
      }
      if (key.toString().toLowerCase() === 'org_id') {
        value = mainCardArray[0].Org_ID;
        primary["Group_ID"] = value;
      }
      else if (key.toString().toLowerCase() === 'organizationid' || key.toString().toLowerCase() === 'organizationid') {
        value = value == mainCardArray[0].Organizationid ? mainCardArray[0].Organizationid : mainCardArray[0].OrganizationID;
        if(['users', 'pathologist', 'physician'].includes(this.cardtype?.toString().toLowerCase())){
          primary["Group_ID"] = value + '(' + mainCardArray[0].OrgName + ')';
        }
        else{
          primary["Group_ID"] = value;
        }
      }
      if (key.toString().toLowerCase() === 'organization_status') {
        value = mainCardArray[0].Organization_Status;
        primary["Group_Status"] = value;
      }
      if(key?.toString()?.toLowerCase() === 'status'){
        value = mainCardArray[0]?.Status;
        primary[['users', 'pathologist', 'physician'].includes(this.cardtype?.toString().toLowerCase()) ? "User_Status" : "Location_Status" ] = value;
      }
      if (key.toString().toLowerCase() === 'organizationguid') {
        value = mainCardArray[0].OrganizationGUID;
        primary["Group_GUID"] = value;
      }
      if(['users', 'pathologist', 'physician'].includes(this.cardtype?.toString().toLowerCase())){
        if(key?.toString().toLowerCase() === 'login_name'){
          if((['email', 'autoinitiated', 'autoreinitiated'].includes(mainCardArray[0].User_Login_Type?.toString().toLowerCase())) && mainCardArray[0].Group_Login_Type?.toString().toLowerCase() == 'email'){
            value = !mainCardArray[0].Email ? 'Not Specified' : mainCardArray[0].Email;
          }
          else{
           value = !mainCardArray[0].Login_Name ? 'Not Specified' : mainCardArray[0].Login_Name
          }
        }
        if(key?.toString().toLowerCase() === 'user_login_type'){
          (value && value?.toString().toLowerCase() == 'email') ? value = 'Email' : value = 'Login Name';
        }
      }
      if(key.toLowerCase() != 'redirectdest')
      primary[key] = value;
      delete primary["Organization"]
      delete primary["Org_ID"];
      delete primary["OrganizationID"]
      delete primary["Organizationid"]
      delete primary["Organization_Status"]
      delete primary["OrganizationGUID"]
      delete primary["OrgName"]
      delete primary["Email"]
      delete primary["Status"]
    }
    array.push(primary);
    //  }
    this.mainCardNew = array[0];
    this.userId = this.accountid;
    this.actionbutton = this.rolepermission;
    this.roledatamenu = this.rolemenu;
    if (changes.secondarykeys !== undefined) {
      if (changes.secondarykeys.currentValue !== undefined) {
        let orgid = changes.secondarykeys.currentValue.OrganizationId
        if (orgid) {
          sessionStorage.setItem("org_id", orgid)
        }
      }
    }
    if (this.cardtype.toLowerCase() == 'users' || this.cardtype.toLowerCase() == 'physician' || this.cardtype.toLowerCase() == 'pathologist') {
      this.ifUserType = true;
      let orgid = this.mainCard.Organizationid //Quick fix under usercard to capture orgid
      //  let orgid = this.mainCard.GroupID //Quick fix under usercard to capture orgid
      sessionStorage.setItem("org_id", orgid)
      // setTimeout(() => {
      //   (<HTMLElement>document.getElementById('vitalMenu')).style.display = 'block';
      //  }, 500);

    } else {
      this.ifUserType = false;
      let simchanges: any = changes.prop;
      if (changes.labels && changes.labels.currentValue.length > 0) {
        this.caseTypeRadio = 0;
        this.actions[0] = {
          actiontype: "Case Type",
          buttontitle: "Import File",
          checked: true,
        }
        if (this.secondarykeys) {
          this.secondayKeysString = JSON.stringify(this.secondarykeys)
        }
      }
      this.labels = Object.keys(this.mainCardNew)
      if (this.vitalHttpServices.mainQueryList.find(mainQuery => mainQuery.Keyword == 'casetype')) {
        if (this.labels.some(va => va.toString().toLowerCase() == "id")) {
          this.labels.splice(this.labels.findIndex(e => e.toString().toLowerCase() == 'id'), 1);
        }
      }
      this.removeAdditionalFields();
      // setTimeout(() => {
      //   (<HTMLElement>document.getElementById('vitalMenu')).style.display = 'none';
      //  }, 500);
    }
  }
  ExportCaseType() {
    let query = " query($accid: String";
    let materialQuery: string = "";
    if (this.secondarykeys && this.secondarykeys.OrganizationId)
      query = query + ",$orgid:String";
    if (this.secondarykeys && this.secondarykeys.casetype)
      query = query + ",$casetype:String";
    query = query + "){"
    let indiviualQuery = "";
    let mainQuery: any;
    this.menuJson.forEach(menuJsonItem => {
      menuJsonItem.SubMenu.forEach(subMenuItem => {
        mainQuery = this.vitalHttpServices.mainQueryList.find(mainQuery => mainQuery.Keyword == subMenuItem.Keyword);
        if (mainQuery) {
          indiviualQuery = mainQuery.Query;
          indiviualQuery = indiviualQuery.split("submenuData:")[1].trim();
          query = query + indiviualQuery.slice(0, (indiviualQuery.length - 1));
        }
      });
    });
    query = query + "}";

    let queryVariable: any = {};
    queryVariable.accid = this.accountid.toString();

    if (this.secondarykeys && this.secondarykeys.OrganizationId)
      queryVariable.orgid = this.secondarykeys.OrganizationId.toString();


    if (this.secondarykeys && this.secondarykeys.casetype)
      queryVariable.casetype = this.secondarykeys.casetype.toString();


    let queryRequest: any = {
      "OperationName": null,
      "Query": query,
      "Variables": queryVariable
    };
    let exportResultData: any;
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryRequest).subscribe(result => {
      if (!result.errors) {
        this.ngxService.stop();
        if (result.data.hasOwnProperty("VitalAdminCasetypeMaterial_List")) {
          materialQuery = "query($casetype: String,$accid: String){ ";

          for (var materialkey in result.data.VitalAdminCasetypeMaterial_List) {

            materialQuery = materialQuery + result.data.VitalAdminCasetypeMaterial_List[materialkey].Querytxt;

            delete result.data.VitalAdminCasetypeMaterial_List[materialkey].Entitytype;
            delete result.data.VitalAdminCasetypeMaterial_List[materialkey].FieldType;
            delete result.data.VitalAdminCasetypeMaterial_List[materialkey].Querytxt;

          }
          materialQuery = materialQuery + " }";

          let newqueryRequest: any = {
            "OperationName": null,
            "Query": materialQuery,
            "Variables": queryVariable
          };
          exportResultData = result.data;
          this.exportData = result.data;
          this.ngxService.start();
          this.vitalHttpServices.GetData(newqueryRequest).subscribe(newresult => {
            if (!newresult.errors) {
              this.ngxService.stop();
              if (newresult.data) {
                let exportResultDataIndex: number = 0;
                for (var key in newresult.data) {
                  if (newresult.data[key][0][key.split('_').join('')])
                    exportResultData.VitalAdminCasetypeMaterial_List[exportResultDataIndex].value = newresult.data[key][0][key.split('_').join('')];
                  else
                    exportResultData.VitalAdminCasetypeMaterial_List[exportResultDataIndex].value = 0;
                  exportResultDataIndex += 1;
                }
              }
              if (exportResultData)
                this.downloadBlob(exportResultData, "CaseType-" + this.secondarykeys.casetype.toString() + ".json");
            } else {
              this.ngxService.stop();

              this._snackbar.open("An error occurred while processing your request", "Failed");
            }
          });
        }
        if (materialQuery.length < 1 && result.data)
          this.downloadBlob(result.data, "CaseType-" + this.secondarykeys.casetype.toString() + ".json");
      }
      else {
        this.ngxService.stop();

        this._snackbar.open("An error occurred while processing your request", "Failed");
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
      this._snackbar.open("An error occurred while processing your request", "Failed");
      console.error(error);
    });
  }

  DeleteUnwantedObjects(resultData: any) {
    //resultData
  }


  ScrolltoUpCard(){
  this.scrollUpMaincard.emit(true)
  }
  exportLabData(action) {
    if (action) {
      this.ngxService.start();
      this.vitalHttpServices.exportLab(action.casetype, action.OrganizationId, this.mainCard.id).subscribe(data => {
        if (data) {
          this.ngxService.stop();
          this.downloadBlob(data, `${action.casetype}.json`)
        } else {
          this.ngxService.stop();
          this._snackbar.open("An error occurred while processing your request", "Failed");
        }
      }, error => {
        console.error();
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", "Failed");
      })
    }
  }

  copytoGUID(label: string, value: string) {
    if (label == "GUID" || label == "OrganizationId" || label == "OrganizationID" || label == "OrganizationGUID") {
      // if(label == "GUID" || label == "GroupID" || label == "GroupGUID"){
      this.clipboard.copy(value);
      this._snackbar.open("", "Copied.");
    }
  }

  downloadBlob(data, filename) {
    let blobData: string = JSON.stringify(data);
    blobData = JSON.stringify(JSON.parse(blobData));
    var blob = new Blob([blobData], { type: 'application/text' });
    if ((window.navigator as any).msSaveOrOpenBlob()) {
      (window.navigator as any).msSaveOrOpenBlob(blob, filename);
    } else {
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      window.URL.revokeObjectURL(elem.href);
      document.body.removeChild(elem);
    }
  }

  /*
  RemoveCaseTypeSettings(fieldName: string, removeFields: string[], query: string, queryVariable: any, ObjectName: string) {
    for (var materialkey in this.exportData.data[fieldName]) {
      query = query + this.exportData.data[fieldName][materialkey].Querytxt;

      removeFields.forEach((columns) => {
        delete this.exportData.data[fieldName][materialkey][columns];
      });
    }
    query = query + " }";

    let newqueryRequest: any = {
      "OperationName": null,
      "Query": query,
      "Variables": queryVariable
    };
    //exportResultData = result.data;

    this.vitalHttpServices.GetData(newqueryRequest).subscribe(newresult => {
      if (!newresult.errors) {
        if (newresult.data) {
          let exportResultDataIndex: number = 0;
          for (var key in newresult.data) {
            /*if (newresult.data[key][0][key.split('_').join('')])
              this.exportData[fieldName][exportResultDataIndex].value = newresult.data[key][0][key.split('_').join('')];
            else
              this.exportData[fieldName][exportResultDataIndex].value = 0;
  /*  switch(ObjectName){
      case "VitalAdminCasetypeMaterial_List":
                                              this.NewJsonAddValue(newresult.data[key][0][key.split('_').join('')],ObjectName,);
    }


    exportResultDataIndex += 1;
  }
}
// this.downloadBlob(exportResultData, "Material.json");
}
});
}

NewJsonAddValue(fieldName: string, fieldValue: any, rowIndex: number, ObjectName: string) {
if (ObjectName == "VitalAdminCasetypeMaterial_List") {
if (fieldValue) {
this.exportData[ObjectName][fieldName][rowIndex].value = fieldValue;

}
else
this.exportData[ObjectName][fieldName][rowIndex].value = 0;
}
else if(ObjectName == "VitalAdminCasetypeSettings_List")
{
if (fieldValue) {
this.exportData[ObjectName][fieldName][rowIndex].Status = fieldValue;

}
else
this.exportData[ObjectName][fieldName][rowIndex].Status = 0;
}
} */
  Export() { }
  handleFileInput(files: any) {
    if (files && files[0] && files[0].size > 0) {
      this.file = files[0];
    }
  }
  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  changeRenderTab($event) {
    this.changeTab.emit($event)
  }

  removeAdditionalFields()
  {
    if (this.labels.some(va => va.toString().toLowerCase() == "group_code")) {
      this.labels.splice(this.labels.findIndex(e => e.toString().toLowerCase() == "group_code"), 1);
    }
    if (this.cardtype.toLowerCase() == 'accid' || this.cardtype.toLowerCase() == 'orgid' || this.cardtype.toLowerCase() == 'lab' || this.cardtype.toLowerCase() == 'location' || this.cardtype.toLowerCase() == 'facility') {
      if (this.labels.some(va => va.toString().toLowerCase() == "org_id")) {
        this.labels.splice(this.labels.findIndex(e => e.toString().toLowerCase() == "org_id"), 1);
      }
      if (this.labels.some(va => va.toString().toLowerCase() == "group_id")) {
        this.labels.splice(this.labels.findIndex(e => e.toString().toLowerCase() == "group_id"), 1);
      }
      if (this.labels.some(va => va.toString().toLowerCase() == "id")) {
        this.labels.splice(this.labels.findIndex(e => e.toString().toLowerCase() == "id"), 1);
      }
      if (this.labels.some(va => va.toString().toLowerCase() == "address")) {
        this.labels.splice(this.labels.findIndex(e => e.toString().toLowerCase() == "address"), 1);
      }
    }
  }
}


@Pipe({ name: "nrow" })
export class RowPipe implements PipeTransform {
  // input is an array of any
  // mod is the modulo, every mod items, create a row
  transform(input: any[], mod: number): any[][] {
    return input.reduce((previous, next, index) => {
      if (index % mod === 0)
        previous.push([next]);
      else
        previous[previous.length - 1].push(next);
      return previous;
    }, <any[][]>[]);
  }
}
