import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, QueryList, Renderer2, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
//import '@grapecity/wijmo.styles/wijmo.css';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Subscription } from "rxjs";
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { UserSession } from 'src/app/core/services/user.session';
import { WindowRef } from '../../core/services/NewWindowservices';
import { VitalHttpServices } from '../../core/services/VitalHttpServices';
import { SearchKey, SearchText, VitalAdditionalParam, VitalFilter } from '../../model/SearchParam';
import { ResultTabArray } from '../../model/Tab';
import { ConfirmComponent } from '../popup/confirm/confirm.component';
import { Tabset } from '../Tab/tabset.component';

declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'vs-entitysearchs',
  templateUrl: './entitysearch.component.html',
  styleUrls: ['./entitysearch.component.scss'],
})

export class EntitySearchComponent implements OnInit {
  formatGridData: wjcCore.CollectionView<any>;
  gridDisplay: boolean = false;
  gridwidth: number = 0;
  columns: ColDef[];
  gridArray: any[];
  showPaginationMainGrid: boolean = false;
  @ViewChild('grid') flex: WjFlexGrid;

  data: any[];
  public selected = 'ALL';
  srcheight: number;
  patientgrid: ColDef[];
  nativeWindow: any
  hideResetBtn: boolean;
  hideassignBtn: boolean;
  hideViewBtn: boolean;
  hideCase360Btn: boolean;
  hideOpenBtn: boolean;
  tempvalue: any;
  operation: any;
  DxUserid: any;
  commonService: any;
  vitalservice: any;
  epgAccountDetails: any;
  AssigneeAccountDetails: any;
  assigneePathologistList: any;
  assignmentForm = this._fb.group({
    assigneeAccount: '',
    assigneePathologist: ''
  });
  resetForm = this._fb.group({
    resetStatus: '',
    resetReason: '',
    retainTestDetails: false
  });
  epgData: any;
  epgAccount: boolean;
  pathLicensereCheck: any;
  result: any;
  tempaccid: any;
  tempPathUserId: any;
  resetcardValue: any;
  public associationTypeList: any[];
  public usersubscript: Subscription;
  datachangesusers: any;
  public deployemtkeybasedapidata: any;
  public olddeploymentkeybasedapidata: any;
  DrpDwnEntityValueFOrSearch: any;
  p4Depl: boolean;


  constructor(private DatabaseService: VitalHttpServices, public router: Router, public usersession: UserSession, private ngxService: NgxUiLoaderService, private cd: ChangeDetectorRef,
    private _tabset: Tabset, private ren: Renderer2, private dialog: MatDialog, public _snackBar: MatSnackBar, private winRef: WindowRef, private commonser: CommonService, private _fb: FormBuilder,
    private datashare: DataShareService) {
    this.nativeWindow = winRef.getNativeWindow();

    this.columns = [
      //************* Grid Customized Column names *********/
      new ColDef('DisplayField.status', 'Status'),
      new ColDef('DisplayField.displayname', 'Name'),
      new ColDef('DisplayField.email', 'Email ID'),
      new ColDef('DisplayField.loginname', 'Login Name'),
      new ColDef('DisplayField.logintype', 'Login Type'),
      new ColDef('DisplayField.userid', 'User ID'),
      new ColDef('DisplayField.rolename', 'Primary Role'),
      new ColDef('DisplayField.orgname', 'Group Name'),
      new ColDef('DisplayField.deploymentkey', 'Deployment'),
      new ColDef('DisplayField.uniqueid', 'Unique ID'),
      new ColDef('DisplayField.modifieddate', 'Last Modified Date'),
    ];
    this.patientgrid =[
      new ColDef('DisplayField.patientname', 'Patient Name'),
      new ColDef('DisplayField.caseid','Case ID'),
      new ColDef('DisplayField.accessionnumber', 'Accession#'),
      new ColDef('DisplayField.requisitionnumber', 'Requisition#'),
      new ColDef('DisplayField.casetype', 'Case Type'),
      new ColDef('DisplayField.orderingfacility', 'Ordering Facility'),
      new ColDef('DisplayField.orderingphysician', 'Ordering Physician'),
      new ColDef('DisplayField.services', 'Services'),
      new ColDef('DisplayField.status', 'Status'),
      new ColDef('DisplayField.pathologistuser', 'Pathologist'),
      new ColDef('DisplayField.lastsigndate', 'Signout Date'),
      // new ColDef('DisplayField.modifieddate', 'Last Modified'),

    ]
  }

  //#regin to show notes
  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      // if(e.panel.rows[0].dataItem && (e.panel.rows[0].dataItem=='notes'||e.panel.rows[0].dataItem=='status')){
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value.toString().match(/inactive/i)) {
          wjCore.toggleClass(e.cell, 'error-msg', true);
        }
        else if (value.toString().match(/active/i)) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }

      }
      //  }
    }
  }
  //#endregion

  Search;
  @Input() set SearchData({ EntityValue, queryvalue }) { };
  @Input() submitType;
  @Input()
  templateData: any;
  EntitytabList = [];
  isClearFilter: boolean = false;
  isSearching: boolean = false;
  searchfilter = new SearchKey();
  public VitalAddParam = new VitalAdditionalParam();
  public deployArr: any = [];
  searchTextDetails = new SearchText();
  VitalFilterArr: any = [];
  queryvalue: any;
  KeyWord: any;
  resultData: any;
  EntityValue: any;
  isEntitytTab: boolean = false;
  isReAssign: boolean = true;
  // isAssign: boolean = true;
  DrpDwnEntityValue: any;
  isTabClick: boolean = false;
  ResultContent: any = [];
  FacetsContent: any;
  date: any;
  FilterOnValue: any;
  Filterfield: any;
  isOnlyFilter: boolean = false;
  NoResult: boolean = false;
  ContinueSearchDetails: any = {};
  isDisabled: boolean = true;
  test = "sometext";
  oClose: boolean = false;
  isFilterVisible: boolean = true;
  AdvanceSearchPropertyValue: any;
  NewAdvSearchFilter: any;
  SuggestorEntityName: any;
  loadAdvSearch: boolean = false;
  entities: any;
  //isTick: any;
  isOnLoad: boolean = true;
  advNoResult: any[];
  AdvChipFilter: boolean = false;
  @ViewChild('SearchInput', { static: true }) SearchInput: ElementRef;
  @ViewChildren("checkboxes") checkboxes: QueryList<any>;
  EntityName: any;
  SearchText: any;
  advFilterCount: any;
  isAdvCount: boolean = false;
  isGoDisabled: boolean = false;
  triggerAdvSubmit: any = '';
  triggerAdvOnGO: boolean = true;
  oldEntityTabList: any;
  AdvData: any[] = [];
  RemoveValue: any;
  AddtionalDetailsArray: any[] = [];
  EachEntityTabArray: any[] = [];
  TabArray: any;
  isEntityTabContent: boolean = false;
  isAdditionalTabContent: boolean = false;
  ContSearchArray: any[] = [];
  TotalSearchResultCount = 0;
  SearchList: any[] = [];
  Searchtabarr = new ResultTabArray();
  SearchTabName = "Search";
  OriginalArrayOfAPICall = [];
  DURATION_IN_SECONDS = {
    epochs: ['year', 'month', 'day', 'hour', 'minute'],
    year: 31536000,
    month: 2592000,
    day: 86400,
    hour: 3600,
    minute: 60
  };
  public wijmogriduservalue: any;
  public filteredOFAccounts: Observable<any[]>;

  ngOnInit() {
    this.datashare.deploymentChange.subscribe(va => {
      if (va) {
        this.VitalFilterArr = {
          "FieldName": "Deploymentkey",
          "FieldValue": va,
          "FilterGroupName": "mat-radio-group-0",
          "FilterCondition": "or"
        }
        this.fnApplyFilter();
      }
    })
    this.srcheight = window.innerHeight - 190
    // this.addformatGridData(this.templateData.submenuData);
  }

  addformatGridData() {
    this.gridArray = [];
    let primary = {};
    //  if(this.wijmogriduservalue.TabName==="Users"){
    let data = [];
    for (let i = 0; i < this.wijmogriduservalue.ResultContent.length; i++) {
      data.push(this.wijmogriduservalue.ResultContent[i].DisplayField)
    }

    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            primary[key] = value;
          }
          this.gridArray.push(primary);
        }
        this.formatGridData = new CollectionView(this.gridArray);
        this.gridwidth = (194 * Object.keys(data[0]).length) + 37;
        this.showPaginationMainGrid = this.gridArray.length > 10 ? true : false;
        if (this.gridwidth > 1300) {
          this.gridwidth = 1300;
        }
      }
      else {
        this.gridwidth = 0;
      }
    }
    else {
      this.gridwidth = 0;
    }
    // }
  }

  //#region Tooltip for the wijmo grid
  initGrid(grid) {
    // this block to show Associated roles tooltip
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      let index = -1
       index = e._p._cols.findIndex(va => va._binding?._path && va._binding?._path.split('.')[1] == 'rolename')
      if (index != -1 && index == e.col) {
        let associatedRoles = e._p._rows[e.row]._data.DisplayField['associatedrolenames'];
        if(associatedRoles)
        {
          associatedRoles = associatedRoles.split(',')
        associatedRoles = associatedRoles.filter(va => va.toString().toLowerCase().trim() != e._p._rows[e.row]._data.DisplayField['rolename'].toString().toLowerCase().trim())
        associatedRoles = associatedRoles.join(',');
        tt.setTooltip(e.cell, `${associatedRoles}`);
        }
      }
      else if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
    // this block to show  Associated accounts tooltip
    grid.formatItem.addHandler((a, b) => {
      if (b.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      let index = -1
      index = b._p._cols.findIndex(va => va._binding?._path && va._binding?._path.split('.')[1] == 'orgname')
      if (index != -1 && index == b.col) {
        let associatedAccounts = b._p._rows[b.row]._data.DisplayField['associatedaccountname'];
        tt.setTooltip(b.cell, `${associatedAccounts}`);
      }
    });
  }
  //#endregion

  goToLink(dxurl: string) {
    window.open(dxurl)
  }
//user email verify
  UserValidation(OrganizationId) {
    let queryVariable;
    let rolepermission
    queryVariable = {
      UserEmail: localStorage.getItem('UserEmail'),
      Organizationid: OrganizationId.toString(),
    };
    let query = this.DatabaseService.GetQuery('role');
    let queryResult = this.commonser.GetCardRequest(queryVariable, query);
    this.DatabaseService.GetData(queryResult).subscribe((Resdata) => {

      if (Resdata.data.submenuData !== undefined && Resdata.data.submenuData !== null && Resdata.data.submenuData.length > 0) {
        let rolepermission = Resdata.data.submenuData[0];
        // sessionStorage.setItem('UserEmail', this.rolepermission['UserEmail']);
        sessionStorage.setItem('RoleName', rolepermission['RoleName']);
        queryVariable = {
          // RoleName: sessionStorage.getItem('RoleName'),
          RoleName: rolepermission['RoleName'],
          //CardType: this.context,
          CardType: 'Cases'
        };

      }
      else {
        let allowAllUsersRole = atob(localStorage.getItem("allowAllUsersRole"));
        sessionStorage.setItem('RoleName', allowAllUsersRole);
        queryVariable = {
          RoleName: allowAllUsersRole,
          // CardType: this.context,
          CardType: 'Cases'
        };
      }
      this.getPermissionDetails(queryVariable);
    }
    , error => {
      console.error(error);
      this._snackBar.open('An error occurred while processing your request', 'Failed');
    });
  }
// to get permission to the actions
  getPermissionDetails(queryVariable) {
    let rolepermission
    this.ngxService.start();
    let query = this.DatabaseService.GetconfigQuery();
    let queryResult = this.commonser.GetCardRequest(queryVariable, query);
    sessionStorage.setItem('deploymentKey', 'configdb');
    this.DatabaseService.GetData(queryResult).subscribe((Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors && Resdata.data.submenuData && Resdata.data.submenuData.length > 0) {
        sessionStorage.setItem('deploymentKey', this.resetcardValue.DisplayField.deploymentkey);
        let roledata = JSON.parse(Resdata.data.submenuData[0].Permission);
        //this.tab.rolemenu = roledata.MenuItems;
        rolepermission = roledata.ActionButton;
        this.getButtonAccess(rolepermission);
      }
      else {
        rolepermission = [];
        this._snackBar.open('You do not have permission', 'Failed');
      }
    }, error => {
      console.error(error);
      this._snackBar.open('An error occurred while processing your request', 'Failed');
    });
  }
//audit
  getButtonAccess(actionButtonDetails) {
    for (var i = 0; i < actionButtonDetails.length; i++) {
      switch (actionButtonDetails[i].Button) {
        case 'ResetCase':
          this.hideResetBtn = actionButtonDetails[i].IsPermitted == "true" ? false : true;
          break;
        case 'AssignCase':
          this.hideassignBtn = actionButtonDetails[i].IsPermitted == "true" ? false : true;
          break;
        case 'ViewReport':
          this.hideViewBtn = actionButtonDetails[i].IsPermitted == "true" ? false : true;
          break;
        case 'OpenCase':
          this.hideOpenBtn = actionButtonDetails[i].IsPermitted == "true" ? false : true;
          break;
        case 'Case360':
          this.hideCase360Btn = actionButtonDetails[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }
//function for actions
  Operations(operation) {

    switch (operation) {
      case ("Report"):
        this.viewPatientReport();
        break;
      case ("reset"):
        this.caseReset();
        break;
      case ("assign"):
        this.caseAssignment();
        break;
      case ("unassign"):
        this.caseAssignment();
        break;
      case ("reassign"):
        this.caseAssignment();
        break;
      case ("openToDX"):
        this.redirectToVitalDx();
        break;
      case ("case360"):
        this.launchCase360(this.operation, this.tempvalue);
        break;
      }
    }

      viewPatientReport()
      {
      if (!this.hideViewBtn) {
        if (this.tempvalue.DisplayField.status.toLowerCase() == 'finalized') {
          let data = this.tempvalue.DisplayField.caseid
          this.DatabaseService.GetEncryptedData(data).subscribe(
            res => {
              if (res.Success) {
                // let caseId = res.Message
                let deployment = this.tempvalue.DisplayField.deploymentkey.toLowerCase();
                let dxurl = this.DatabaseService.GetDxurlBasedOnDeployment(deployment) + 'VAReportGetForCase.aspx?' +
                  res.Message;
                var newWindow = window.open(dxurl, '_blank',
                  'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=1000, height=600, left=100 top=100 '
                );
                // url =this.vitalHttpServices.GetDxurlBasedOnDeployment(deployid) +

              } else {
                error => {
                  console.error(error);
                }
              }
            });
        }
        else {
          this._snackBar.open('Please select finalized case', 'Failed');
        }
      }
      else {
        this._snackBar.open("User is not authorized for this operation", 'Failed')
      }
    }
    //reset
    caseReset() {
      if (!this.hideResetBtn) {
        // let OrganizationId = this.tempvalue.DisplayField.laborgid;
        // let deployment = this.tempvalue.DisplayField.deploymentkey.toLowerCase();
        // let dxurl = this.DatabaseService.GetDxurlBasedOnDeployment(deployment);
        // var queryString = "VAResetCase.aspx?frombtn=ResetCase&caseid={0}&reset=accession&caseStatus={1}&defaultpage=ListPage/ListPage.aspx?type=cases&listpagedetails=|status||asc|Barcode|cases|||||||||false||false||||4|||true|0||||&isrequisition=false&groupBy=Status&sortBy=GrossedDateTime&sortorder=asc";
        // let caseidreplace = queryString.replace('{0}', this.tempvalue.DisplayField.caseid);
        // let finalquerystring = caseidreplace.replace('{1}', this.tempvalue.DisplayField.status)
        // var newWindow = window.open(dxurl + finalquerystring,
        //   "_blank", "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=1000, height=600, left=100 top=100 ");
        $('#myModal').modal('show');
        this.p4Depl=this.commonser.isP4deployment(this.tempvalue.DisplayField.deploymentkey.toLowerCase());
        this.resetForm.patchValue({
          resetStatus: 'Accession',
          resetReason: '',
          retainTestDetails: false

        })
      }
      else {
        this._snackBar.open('User is not authorized', 'Failed');
      }
    }

     redirectToVitalDx() {
      this.ngxService.start();
      if(!this.hideOpenBtn){
         this.DatabaseService.UpdateAdminUser(this.DxUserid[0].userid.toString()).subscribe((Info) => {
        this.ngxService.stop();
        let OrganizationId = this.tempvalue.DisplayField.laborgid;
        let deployment = this.tempvalue.DisplayField.deploymentkey.toLowerCase();
        let dxurl = this.DatabaseService.GetDxurlBasedOnDeployment(deployment);
        var queryString = "VALogin.aspx?caseid={0}&FormUserID={1}&rolename=Support&IsReadonly=true";
        let caseidreplace = queryString.replace('{0}', this.tempvalue.DisplayField.caseid);
        let finalquerystring = caseidreplace.replace('{1}', this.DxUserid[0].userid.toString());
        var newWindow = window.open(dxurl + finalquerystring,
          "_blank", "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=1000, height=600, left=100 top=100 ");
      }, error => {
        this.ngxService.stop();
        console.error(error);
        this._snackBar.open('An error occurred while processing your request', 'Failed');
      });
    }
    }

    caseAssignment() {
      if (!this.hideassignBtn) {
      if(this.operation == 'assign'){
      let tempStatus = this.tempvalue.DisplayField.status.toLowerCase();
      if (!(tempStatus.toString().toLowerCase() == 'assignment' || tempStatus.toString().toLowerCase() == 'screening review' || tempStatus.toString().toLowerCase() == 'screening review' || tempStatus.toString().toLowerCase() == 'screening - pending tests' || tempStatus.toString().toLowerCase() == 'screening Review - pending Tests' || tempStatus.toString().toLowerCase() == 'assignment - pending tests')) {
        this._snackBar.open('Select cases that are only in  Assignments or Assignments - Pending Tests status to assign to a Pathologist', 'Close')
      }
      else {
        // tech only
        $('#caseAssign').modal('show');
        if (this.tempvalue.DisplayField.services.toLowerCase() == "technical only") {
          this.fnAccountTCService();
        }
        else {
         this.getAccountNotExceptTC();
        }
      }
    }
    else if (this.operation == 'reassign') {

      let tempStatus = this.tempvalue.DisplayField.status.toLowerCase();
      if (!(tempStatus.toString().toLowerCase() == 'diagnosis - pending tests' || tempStatus.toString().toLowerCase() == 'diagnosis' || tempStatus.toString().toLowerCase() == 'pending tests')) {
        this._snackBar.open('Cannot reassign the selected cases. Select cases from Diagnosis status onwards and not in Finish Case - Pending Tests,Pending Finish Case and Finalized statuses', 'Close')
      }
      else {
        // tech only
        if (this.tempvalue.DisplayField.services.toLowerCase() == "technical only") {
          this.fnAccountTCService();
        }
        else {
          this.getAccountNotExceptTC();
        }
        $('#caseAssign').modal('show');
      }
    }
    else if (this.operation == 'unassign') {
      let tempStatus = this.tempvalue.DisplayField.status.toLowerCase();
      if (!(tempStatus.toString().toLowerCase() == 'diagnosis - pending tests' || tempStatus.toString().toLowerCase() == 'diagnosis' || tempStatus.toString().toLowerCase() == 'pending tests'
        || tempStatus.toString().toLowerCase() == 'review' || tempStatus.toString().toLowerCase() == 'Review-pending tests')) {
        this._snackBar.open("Select cases that are in  Diagnosis or Diagnosis - Pending Tests or Review or Review - Pending Tests status to unassign to a Pathologist");
      } else {
        let dialogRef = this.dialog.open(ConfirmComponent, {
          disableClose: true,
          data: {
            header: 'Un-Assign',
            message: '',
            alert: 'Do you wish to continue?',
            continue: 'yes',
            cancel: 'no',
          },
        });
        return dialogRef
          .afterClosed()
          .toPromise()
          .then((result) => {
            if (result) {
              this.ngxService.start();
              var obj = {
                caseId: this.tempvalue.DisplayField.caseid,
                userId: this.DxUserid[0].userid,
              }
              this.DatabaseService.GetCaseLevelUnAssingCase(obj).subscribe((res) => {
                if (res.Success) {
                  this._snackBar.open("Case un-assigned successfully. Please refresh to see updated status ", 'Success');
                  var auditObj = {
                    caseId: this.tempvalue.DisplayField.caseid,
                    pathAccountId: '',
                    action: "un-Assign",
                    pathAccoutid: ''
                  }
                  this.auditCaseAssign(auditObj,"un-Assign");
                } else {
                  this._snackBar.open('Can not un-assign the case ', 'Failed');
                }
                this.ngxService.stop();
              }), (error) => {
                console.error(error);
                this.ngxService.stop();
                this.AssigneeAccountDetails = [];
                this._snackBar.open('An error occurred while processing your request', 'Failed');
              };

            }
          });
      }
    }
  }
  }

  // resetPopup(){
  //   $('#myModal').modal('show');
  //   this.resetForm.patchValue({
  //     resetStatus:'Accession',
  //     resetReason:'',

  //   })
  // }

  resetCase(value) {
    this.ngxService.start();
    if(!this.p4Depl && !value.resetReason){
    this.ngxService.stop();
     this._snackBar.open("Please put the reason for reset",'Close')
    return;
    }
    let obj = {
      caseId: this.tempvalue.DisplayField.caseid,
      userId: this.DxUserid[0].userid,
      status: value.resetStatus,
      resetReason: value.resetReason,
      RetainTestDetails:value.resetStatus.toString().toLowerCase() == 'processing' ? 'true' : value.retainTestDetails,    
      IsP4:this.p4Depl
    }
    this.DatabaseService.GetCaseReset(obj).subscribe((res) => {
      if (res.Success) {
        this.resetcardValue['resetModified'] = true
        $('#myModal').modal('hide');
        this._snackBar.open("Case reset done. Please refresh to see updated status ", 'Success');
        var auditObj = {
          caseId: this.tempvalue.DisplayField.caseid,
          pathAccountId: '',
          action: "case-reset",
          pathAccoutid: ''
        }
        this.auditCaseAssign(auditObj,"case-reset");
      } else {
        this._snackBar.open('Can not reset the case ', 'Failed');
      }
      this.ngxService.stop();
    }), (error) => {
      console.error(error);
      this.ngxService.stop();
      this.AssigneeAccountDetails = [];
      this._snackBar.open('An error occurred while processing your request', 'Failed');
    };
  }

  fnAccountTCService() {
    let query = this.GetQuery('GetAssigneeAccountforTC');
    let queryVariable = {
      caseid: this.tempvalue.DisplayField.caseid.toString(),
    };
    let queryResult = this.commonser.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.DatabaseService.GetData(queryResult).subscribe((Resdata) => {
      if (!Resdata.errors) {
        this.ngxService.stop();
        this.AssigneeAccountDetails = Resdata.data.submenuData;
      }
    }), (error) => {
      console.error(error);
      this.ngxService.stop();
      this.AssigneeAccountDetails = [];
      this._snackBar.open('An error occurred while processing your request', 'Failed');
    };
  }

  getAccountNotExceptTC() {
    var obj = {
      caseId: this.tempvalue.DisplayField.caseid
    }
    this.DatabaseService.GetCaseLevelAssigneeAccounts(obj).subscribe((res) => {
      if (res.Success) {
        this.AssigneeAccountDetails = JSON.parse(res.Message)
      } else {
        this.AssigneeAccountDetails = [];
      }
    }), (error) => {
      this.ngxService.stop();

      this.AssigneeAccountDetails = [];
      this._snackBar.open('An error occurred while processing your request', 'Failed');
    };
  }

  getAssigneePathologistList(data) {
    let tempAccName = data.source.value.split('+')[1];
    this.tempaccid = data.source.value.split('+')[0];
    // this.recipientData.patchValue({
    //   // AssociatedAccountid:value.option.value
    //   associateOrg: tempAccName,
    // });
    this.assignmentForm.patchValue({
      assigneeAccount: tempAccName
    })
    let query = this.GetQuery('PathologistAccountsByAccountID');
    let queryVariable = {
      accoutnid: '',
    };
    let queryResult = this.commonser.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.DatabaseService.GetData(queryResult).subscribe((Resdata) => {
      if (!Resdata.errors) {
        this.ngxService.stop();
        this.epgData = Resdata.data.submenuData;

        for (let epg of this.epgData) {
          data.source.value == epg.AssociatedAccountID ? this.epgAccount = true : this.epgAccount = false;
        }

        let query = this.GetQuery('GetAssigneePathologies');
        let queryVariable = {
          accoutnid: this.tempaccid.toString(),
        };
        let queryResult = this.commonser.GetCardRequest(queryVariable, query);
        this.ngxService.start();
        this.DatabaseService.GetData(queryResult).subscribe((Resdata) => {
          if (!Resdata.errors) {
            this.ngxService.stop();
            this.assigneePathologistList = Resdata.data.submenuData;
          }
        }), (error) => {
          this.ngxService.stop();
          this.assigneePathologistList = [];
          this._snackBar.open('An error occurred while processing your request', 'Failed');
        };

      }
    }), (error) => {
      this.ngxService.stop();
      this.assigneePathologistList = [];
      this._snackBar.open('An error occurred while processing your request', 'Failed');
    };
  }

  filterPathologistName(value) {
    if(!value || value == '') {
      return this.assigneePathologistList
    }
    let pathologist = this.assigneePathologistList
    return pathologist.filter(pa => (pa && (pa.PathologistName && pa.PathologistName.toString().toLowerCase().includes(value.toString().toLowerCase()))))
  }

  clearOrganization() {
      this.assignmentForm.patchValue({
        assigneePathologist: ''
      })
  }
  checkLicenseOfPathologist(event,data) {
    if(event.source.selected){
    let tempPathname;
    if (data == 'epg') {
      tempPathname = 'Available to all pathologists';
      this.tempPathUserId = null;
    }
    else {
      tempPathname = event.source.value.split('+')[1];
      this.tempPathUserId = event.source.value.split('+')[0];
    }
    // this.recipientData.patchValue({
    //   // AssociatedAccountid:value.option.value
    //   associateOrg: tempAccName,
    // });
    this.assignmentForm.patchValue({
      assigneePathologist: tempPathname,
    })
    // var obj = {
    //   caseId: this.tempvalue.DisplayField.caseid,
    //   PathUserId: this.tempPathUserId.toString()
    // }
    // this.DatabaseService.GetCaseLevelLicensurePathologists(obj).subscribe((res) => {
    //   if (res.Success) {
    //     let checkPathLicense = JSON.parse(res.Message);
    //     this.pathLicensereCheck = checkPathLicense[0].UserLicensureExist
    //   } else {
    //     this._snackBar.open(res.Message, 'Failed');
    //   }
    //   this.ngxService.stop();
    // });
    // (error) => {
    //   this.ngxService.stop();
    //   this._snackBar.open('An error occurred while processing your request', 'Failed');
    // };
  }
  }

  // assign button
  assignToPathologist(value) {
    this.ngxService.start();
    if (this.tempPathUserId != null) {
      var obj = {
        caseId: this.tempvalue.DisplayField.caseid,
        PathUserId: this.tempPathUserId
      }
      this.DatabaseService.GetCaseLevelLicensurePathologists(obj).subscribe((res) => {
        this.ngxService.stop();
        if (res.Success) {
          let checkPathLicense = JSON.parse(res.Message);
          this.pathLicensereCheck = checkPathLicense[0].UserLicensureExist
          if (this.pathLicensereCheck == 1) {
            let tempStatus = this.tempvalue.DisplayField.status.toLowerCase();
            // this.tempvalue['status'] = tempStatus
            if (tempStatus == 'assignment' || tempStatus == 'screening review' || tempStatus == 'screening review' || tempStatus == 'screening - pending tests' || tempStatus == 'screening Review - pending Tests' || tempStatus == 'assignment - pending tests') {
              this.assignToPath(this.tempvalue.DisplayField.caseid, value.assigneeAccount, value.assigneePathologist)
            }
            else {
              this.reAssignToPathologist(this.tempvalue.DisplayField.caseid, value.assigneeAccount, value.assigneePathologist)
            }
          }
          else {
            let dialogRef = this.dialog.open(ConfirmComponent, {
              disableClose: true,
              // width: '250px',
              data: {
                header: 'assign',
                message: '',
                alert: 'The selected Pathologist is not licensed in the ordering facility\'\s state for this case. To continue, click "Yes" or select another Pathologist.?',
                continue: 'yes',
                cancel: 'no',
              },
            });
            return dialogRef
              .afterClosed()
              .toPromise()
              .then((result) => {
                if (result) {
                  let tempStatus = this.tempvalue.DisplayField.status.toLowerCase();
                  if (tempStatus == 'assignment' || tempStatus == 'screening review' || tempStatus == 'screening review' || tempStatus == 'screening - pending tests' || tempStatus == 'screening Review - pending Tests' || tempStatus == 'assignment - pending tests') {
                    this.assignToPath(this.tempvalue.DisplayField.caseid, value.assigneeAccount, value.assigneePathologist)
                  }
                  else {
                    this.reAssignToPathologist(this.tempvalue.DisplayField.caseid, value.assigneeAccount, value.assigneePathologist)
                  }
                }
              });
          }
        } else {
          this._snackBar.open(res.Message, 'Failed');
        }
        this.ngxService.stop();
      }), (error) => {
        console.error(error);
        this.ngxService.stop();
        this._snackBar.open('An error occurred while processing your request', 'Failed');
      };
    }
    else {
      let tempStatus = this.tempvalue.DisplayField.status.toLowerCase();
      // this.tempvalue['status'] = tempStatus
      if (tempStatus == 'assignment' || tempStatus == 'screening review' || tempStatus == 'screening review' || tempStatus == 'screening - pending tests' || tempStatus == 'screening Review - pending Tests' || tempStatus == 'assignment - pending tests') {
        this.assignToPath(this.tempvalue.DisplayField.caseid, value.assigneeAccount, value.assigneePathologist)
      }
      else {
        this.reAssignToPathologist(this.tempvalue.DisplayField.caseid, value.assigneeAccount, value.assigneePathologist)
      }
    }
  }

  assignToPath(caseid, pathaccountid, pathuserid) {
    $('#caseAssign').modal('hide');
    this.ngxService.start();
    pathuserid == 'all' ? null : pathuserid;
    var obj = {
      caseId: caseid,
      userId: this.DxUserid[0].userid,
      PathAccountId: this.tempaccid,
      PathUserId: this.tempPathUserId
    }
    this.ngxService.start();
    this.DatabaseService.GetCaseLevelAssignToPathologist(obj).subscribe((res) => {

      if (res.Success) {
        this.ngxService.stop();
        // this.ngxService.start();
        $('#caseAssign').modal('hide');
        this._snackBar.open("Case assigned successfully. Please refresh to see updated status ", 'Success');
        var auditObj = {
          caseId: caseid,
          pathAccountId: pathuserid,
          action: "Assign",
          pathAccoutid: pathaccountid
        }
        this.auditCaseAssign(auditObj,"Assign");
      } else {
        this._snackBar.open(res.Message, 'Failed');
      }

    }), (error) => {
      this._snackBar.open('An error occurred while processing your request', 'Failed');
    };
  }

  reAssignToPathologist(caseid, pathaccountid, pathuserid) {
    this.ngxService.start();
    var obj = {
      caseId: this.tempvalue.DisplayField.caseid,
      userId: this.DxUserid[0].userid,
      PathAccountId: this.tempaccid,
      PathUserId: this.tempPathUserId,
      reviewStatus: ''
    }
    let query = this.GetQuery('revisionreasondisplay');
    let queryVariable = {
      caseid: this.tempvalue.DisplayField.caseid.toString(),
    };
    let queryResult = this.commonser.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.DatabaseService.GetData(queryResult).subscribe((Resdata) => {
      if (!Resdata.errors) {
        this.ngxService.stop();
        let demopathuserid = Resdata.data.submenudata[0].pathuserid;
        if (demopathuserid != this.tempPathUserId) {
          this.DatabaseService.GetCaseLevelReAssignToPathologist(obj).subscribe((res) => {
            if (res.Success) {
              $('#caseAssign').modal('hide');
              this._snackBar.open("Case re-assigned successfully. Please refresh to see updated status", 'Success');
              var auditObj = {
                caseId: caseid,
                pathAccountId: pathuserid,
                action: "Re-Assign",
                pathAccoutid: pathaccountid
              }
              this.auditCaseAssign(auditObj,"Re-Assign");
            } else {
              this._snackBar.open(res.Message, 'Failed');
            }
            this.ngxService.stop();
          }, (error) => {
            this.ngxService.stop();
            this._snackBar.open('An error occurred while processing your request', 'Failed');
          });
        }
        else {
          this._snackBar.open("This cases cannot be Re-assigned as they are already assigned to the selected Pathologist");
          $('#caseAssign').modal('hide');
        }
      }
    }), (error) => {
      this.ngxService.stop();
      this.AssigneeAccountDetails = [];
      this._snackBar.open('An error occurred while processing your request', 'Failed');
    };
  }

  auditCaseAssign(auditOBJ,operation) {
    let value = auditOBJ.pathAccountId.split('+');
    auditOBJ.pathAccountId = value[1];
    var auditInfo = {
      CaseID: auditOBJ.caseId,
      UserID: this.DxUserid[0].userid,
      ActionPerformed: operation,
      ActionDetails: '',
      TimeTaken: 0,
      TraceID: "",
      // AuditType: null,
      // ObjectID: null,
      IsShown: 1,
      // OrderNumber: null
      // AdditionalDetails: null,
    }
    if(auditInfo.ActionPerformed.toString().toLowerCase() == "assign")
    {
      auditInfo.ActionPerformed = "Assigned case to " +  auditOBJ.pathAccountId;
    }
    else if(auditInfo.ActionPerformed.toString().toLowerCase() == "re-assign"){
      auditInfo.ActionPerformed = "Re-assigned to "+  auditOBJ.pathAccountId;
    }
    else if(auditInfo.ActionPerformed.toString().toLowerCase() == "un-assign")
    {
      auditInfo.ActionPerformed = "Un-assigned Diagnosis Pathalogist";
    }
    else if(auditInfo.ActionPerformed.toString().toLowerCase() == "case-reset")
    {
      auditInfo.ActionPerformed = "Case reset done";
    }
    this.DatabaseService.RecordAudit(auditInfo).subscribe(result => {
      this.result = result
      if (!result.error && result.message == 'Audit Successful') {
        //this.createNewWindow(url);
      }
      else {
        console.log(result.error);
      }
    }, (error) => {
      console.error(error);
    });
  }

  closingAssignModel() {
    this.assignmentForm.reset();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.SearchData.currentValue !== undefined && changes.SearchData.currentValue.EntityValue !== undefined) {
            this.queryvalue = changes.SearchData.currentValue.queryvalue;
      this.DrpDwnEntityValue = changes.SearchData.currentValue.EntityValue;
      if (this.queryvalue != "not allowed") {
        this.fnSearch(false);
      }
      else {
        if (this.DrpDwnEntityValue.toLowerCase() == 'case'
          || this.DrpDwnEntityValue.toLowerCase() == 'pat') {
          this.NoResult = true;
          this.isOnLoad = false;
          this.EntityName = this.DrpDwnEntityValue;
          this.SearchText = "Case and Patient search is not allowed for this user";
          this.EachEntityTabArray = [];
          this.ResultContent = [];
          this.EntitytabList = [];
          this._snackBar.open("Case and Patient search is not allowed for this user", "Close");
        }
        else {
          this.fnSearch(false);
        }
      }
    }
    await this.getDeployUrls();
  }



  async getDeployUrls() {
    this.ngxService.start("GetDxURL");
    await this.DatabaseService.GetAllUrls().toPromise().then((result) => {
      if (!result.errors) {
        this.ngxService.stop("GetDxURL");
        this.DatabaseService.DeploymentKeys = result.DeploymentKeys;
        this.DatabaseService.vitalDXUrls = result.vitalDXUrls;
        this.DatabaseService.reportlinks = result.reportlinks;
        this.DatabaseService.Admin20 = result.Admin20;
        let deployKeys = this.DatabaseService.DeploymentKeys;
        let vdurls = Object.entries(this.DatabaseService.vitalDXUrls);
        this.deployArr = [];
        if(!this.DatabaseService.checkDeploymentLevelAdmin){
          for (var i = 0; i < vdurls.length; i++) {
            let arr = {};
            let depKey = vdurls[i][0].toString().toLowerCase();
            let depUrl = vdurls[i][1].toString().toLowerCase();
            for (var j = 0; j < deployKeys.length; j++) {
              let strkey = deployKeys[j].toString().toLowerCase();
              if (depKey == strkey) {
                arr["DeployKeys"] = strkey;
                arr["URLs"] = depUrl;
                this.deployArr.push(arr);
                break;
              }
  
            }
          }
        }
        else{
            let arr = {};
            let depKey = vdurls[0][0].toString().toLowerCase();
            let depUrl = vdurls[0][1].toString().toLowerCase();
              let strkey = deployKeys[0].toString().toLowerCase();
              if (depKey == strkey) {
                arr["DeployKeys"] = strkey;
                arr["URLs"] = depUrl;
                this.deployArr.push(arr);
              }
        }
       
      } else {
        this.ngxService.stop("GetDxURL");
        this._snackBar.open(
          'An error occurred while processing your request',
          'Failed'
        );
      }
    })
  }

  timeSince(date) {
    let seconds, duration, suffix
    // seconds = Math.floor((new Date().valueOf() - new Date(date).valueOf()) / 1000);
    let curDate: any = new Date();
    let newDate: any = new Date(date)//.toLocaleString("en-US", {timeZone: 'Asia/Kolkata'})
    let tempCurDate = curDate.getTime() / 1000;
    let tempNewDate = newDate.getTime() / 1000;
    let sec = Math.abs(tempCurDate - (tempNewDate - 19800));
    //let sec = seconds - 19800;    //convert 5:30 hours GMT
    if (sec < 0) {
      sec = sec * -1
    }
    duration = this.getDays(sec);
    return duration;
  };

  getDays(seconds) {
    let min, hours, days, months, years, result;
    min = seconds / 60;
    hours = seconds / 3600;
    days = seconds / 86400;
    months = seconds / (86400 * 30)
    years = seconds / (86400 * 365)
    if (years == 1 || years > 1) {
      let dValue: number = years;
      let intValue: number = Math.floor(dValue);
      if (intValue == 1) {
        result = intValue + " year"
        return result;
      }
      result = intValue + " years"
    }
    else if (months == 1 || months > 1 && years < 1) {
      let dValue: number = months;
      let intValue: number = Math.floor(dValue);
      if (intValue == 1) {
        result = intValue + " month"
        return result;
      }
      result = intValue + " months"
    }
    else if (days == 1 || days > 1 && months < 1) {
      let dValue: number = days;
      let intValue: number = Math.floor(dValue);
      if (intValue == 1) {
        result = intValue + " day"
        return result;
      }
      result = intValue + " days"
    }
    else if (hours == 1 || hours > 1 && days < 1) {
      let dValue: number = hours;
      let intValue: number = Math.floor(dValue);
      if (intValue == 1) {
        result = intValue + " hour"
        return result;
      }
      result = intValue + " hours"
    }
    else if (min > 1 || min < 1 && hours < 1) {
      let dValue: number = min;
      let intValue: number = Math.floor(dValue);
      if (intValue == 1) {
        result = intValue + " minute"
        return result;
      }
      result = intValue + " minutes"
    }
    else {
      result = "Just a while";
    }
    return result;
  }

  //On click of advance search disable the go button
  // fnOnAdvSearch(){
  //   var AdvanceSearchOpen = (<HTMLElement>document.getElementById('AdvSearch')).className;
  //   if(!AdvanceSearchOpen.includes('show')){
  //     this.triggerAdvOnGO = true;
  //     //this.isGoDisabled = true;
  //   }else{
  //     this.triggerAdvOnGO = false;
  //     //this.isGoDisabled = false;
  //   }//end of if
  // }//end of fnOnAdvSearch

  adjustTabs() {
    const a = this._tabset.widthOfTab()
    const b = this._tabset.widthOfList()
    this._tabset.rightScroll()
    b + 90 > a ? this.adjustTabs() : this.ngxService.stop();
  }

  public GetQuery(keyword: string) {
    // let query: string = '';
    let mainQueryIndex: number = this.DatabaseService.mainQueryList.findIndex(
      (mainQuery) =>
        mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase()
    );
    if (mainQueryIndex > -1)
      return this.DatabaseService.mainQueryList[mainQueryIndex].Query;
  }

  openUserCard(grid, e) {
    let hti = grid.hitTest(e);
    if (hti.panel === grid.cells) {
      this.ngxService.start();
      let row = grid.rows[hti.row];
      let dataItem = row.dataItem;
      this.DisplayCardClick("", dataItem);
    }
  }
//get authentication and authorization
  CaseActions(operation, Value,grid) {
    // if(operation.toLowerCase() =='reset' || operation.toLowerCase() =='report' || operation.toLowerCase() =='case360' || operation.toLowerCase() =='opentodx') {
    // var hti = grid.hitTest(Value);
    // this.resetcardValue = hti.panel.rows[hti.row].dataItem;
    // Value= this.resetcardValue ;
    this.resetcardValue = grid._data;
    Value= this.resetcardValue ;
    this.operation = operation;
    this.tempvalue = Value;
    sessionStorage.setItem('deploymentKey', this.resetcardValue.DisplayField.deploymentkey);
    //context is set in the session, which stores the context value from Elastic search if the selected Lab is a Lab, Fscility or EPG
    sessionStorage.setItem('context', this.resetcardValue.Context);

    if (localStorage.getItem("UserEmail") && localStorage.getItem("UserEmail") != "") {
      if ( this.resetcardValue.Context.toLowerCase() == "case" ||  this.resetcardValue.Context.toLowerCase() == "patient") {
        let OrganizationId = this.resetcardValue.DisplayField.laborgid;
        // sessionStorage.setItem('deploymentKey', 'configdb')
        let deployment = this.resetcardValue.DisplayField.deploymentkey.toLowerCase();
        this.DatabaseService.deploymentKey = deployment;
        let dxurl = this.DatabaseService.GetDxurlBasedOnDeployment(deployment);
        let query = this.DatabaseService.GetDxUseridBasedOnDeployment(/*deployment*/);
        this.ngxService.start();
        this.DatabaseService.GetData(query).subscribe(data => {
          let result = data;
          this.DxUserid = result.data.submenuData ? result.data.submenuData : null;
          this.ngxService.stop();
          if (this.DxUserid && this.DxUserid.length > 0) {
            //if (this.DxUserid.userid) {
            this.UserValidation(OrganizationId);
            //condtions
            this.Operations(this.operation);
            // var queryString = "VALogin.aspx?caseid={0}&FormUserID={1}&rolename=Support&IsReadonly=true";
            // let caseidreplace = queryString.replace('{0}', Value.DisplayField.caseid);
            // let finalquerystring = caseidreplace.replace('{1}', DxUserid[0].userid.toString());
            // var newWindow = window.open(dxurl + finalquerystring,
            //   "_blank", "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=1000, height=600, left=100 top=100 ");
          } else {
            this._snackBar.open("User is not authorized for this operation", 'Failed')
          }//end of check if user id is present or not
        }, error => {
          this.ngxService.stop();
          console.error(error);
          this._snackBar.open("An error occurred while processing your request", 'Failed')
        });

      }
        }
    else {
      this.DatabaseService.logout();
    }
  }

  //Reason: remove the tab i.e remove from the EachEntityTabArray array, find index remove
  removeThisTab(TabData) {
    const removeIndex = this.EachEntityTabArray.findIndex(tab => tab.GUID == TabData.TabGUID);
    const ValueIndex = this.AddtionalDetailsArray.findIndex(Etab => Etab.GUID == TabData.TabGUID);
    if (removeIndex != -1) {
      this.EachEntityTabArray.splice(removeIndex, 1);
      this.AddtionalDetailsArray.splice(ValueIndex, 1);
    }//end of if
  }//end of removeThisTab

  fnToggleFilter(isfiltervis) {
    this.isFilterVisible = isfiltervis;
    this.oClose = !this.oClose;
  }//end of fnToggleFilter

  //when result scroll reaches the end load the next 50 result
  OnScroll(event) {
    var elmnt = event;          //document.getElementById("scrollMe");
    elmnt.path[0].scrollIntoView({ behavior: "smooth" });
    let ScrollDown = elmnt.path[0].scrollHeight - elmnt.path[0].scrollTop === elmnt.path[0].clientHeight;
    if (ScrollDown) {
      var cc = this.DrpDwnEntityValue;
      let ArrayEntityTabName;
      // return;
      if (this.DrpDwnEntityValue.toUpperCase() == "ALL") {
        var cc = this.ContinueSearchDetails;
        var ContsDetails = this.ContSearchArray.find(contarr => contarr.Entity == this.EntityValue);
        if (ContsDetails.ContData != null)
          this.ContinueSearchDetails = ContsDetails.ContData;
        ArrayEntityTabName = "EntityTab" + ContsDetails.Entity;
      } else {
        ArrayEntityTabName = "EntityTab" + this.DrpDwnEntityValue;
      }
      if (!jQuery.isEmptyObject(this.ContinueSearchDetails)) {
        let ContinueIndexName = this.ContinueSearchDetails["@odata.nextLink"];
        ContinueIndexName = ContinueIndexName.match(/\((.*?)\)/);
        let ContIndexName: string = ContinueIndexName[1];
        let ContinuationSearchOn: any = {}
        ContinuationSearchOn = {
          "IndexName": ContIndexName,
          "ContinuationSearchToken": this.ContinueSearchDetails
        } // end param for cont search
        this.ngxService.start();
        this.DatabaseService.ContinueSearch(ContinuationSearchOn).subscribe(res => {
          let contResultData: any = [];
          contResultData = res;
          for (var data in contResultData.Results) {
            var UpdateTab = this.EntitytabList.find(tab => tab.GUID == ArrayEntityTabName)
            UpdateTab.ResultContent.push(contResultData.Results[data]);
            //this.ResultContent.push(contResultData.Results[data]);
          }//end of foreach - append the result data

          if (contResultData.ContinuationSearchToken !== null) {
            this.ContinueSearchDetails = {};
            //append the continue search token to contsearcharray based on multiple entity or each entity
            if (this.DrpDwnEntityValue.toUpperCase() == "ALL") {
              var ContDtls = this.ContSearchArray.find(cont => cont.Entity == contResultData.Facets.Context[0].value);
              ContDtls.ContData = contResultData.ContinuationSearchToken;
            } else {
              this.ContinueSearchDetails = contResultData.ContinuationSearchToken;
            }//end of if
          } else {
            //set continue search token to null if all the result have been fetched based on multiple entity or each entity
            if (this.DrpDwnEntityValue.toUpperCase() == "ALL") {
              var ContDtls = this.ContSearchArray.find(cont => cont.Entity == contResultData.Facets.Context[0].value);
              ContDtls.ContData = {};
            } else {
              this.ContinueSearchDetails = {};
            }//end of inner if else
          };// end of outer if else
          this.ngxService.stop();
        });//end continue search subscribe
      }//end of inner if -> only if continue searchtoken is present
    }//end of if -> check if scroll has reached the down or not
  }//end of OnScroll


  //Reason : if search button is selected in child component i.e advsearch collapse
  searchSelected(event) {
    if (event == "hide") {
      (<HTMLElement>document.getElementById('AdvSearch')).classList.remove('show');
      //this.isGoDisabled = false;
    }//end of if
  }//end of searchselected

  onClickMainTab(searchdata) {
  }

  onClickInTab(TabData) {
    let IndexName = TabData.TabName;
    let tabContent = TabData.Tab;
    // if (!this.isAdvCount) {
    //   this.VitalFilterArr = [];
    //   this.VitalAddParam.VitalFilter = this.VitalFilterArr;
    // }
    this.isOnlyFilter = false;
    this.isTabClick = true;
    if (!tabContent.Removable) {
      this.EntityValue = IndexName;
    }
  }//end of onClickInTab


  fnFilterData(FilterOnValue, Filterfield, isChecked) {
    this.FilterOnValue = FilterOnValue;
    this.Filterfield = Filterfield.replace(/ +/g, ""); // used to remove the field same before sending to the api
    ////this.isOnlyFilter = true;
    this.isDisabled = false;
    if (!isChecked) {
      var isPresent = this.VitalFilterArr.some(x => x.FilterGroupName == 'mat-radio-group-0');
      if (isPresent) {
        let RemoveFilterIndex = this.VitalFilterArr.findIndex(val => val.FilterGroupName == 'mat-radio-group-0');
        this.VitalFilterArr.splice(RemoveFilterIndex, 1)
      }
      var vf = new VitalFilter();
      vf.FieldName = this.Filterfield;
      vf.FieldValue = this.FilterOnValue;
      vf.FilterGroupName = 'mat-radio-group-0';
      vf.FilterCondition = "or";
      this.VitalFilterArr.push(vf)
    }

    if (isChecked) {
      var isPresent = this.VitalFilterArr.some(x => x.FilterGroupName == isChecked.source.name);
      if (isPresent) {
        let RemoveFilterIndex = this.VitalFilterArr.findIndex(val => val.FilterGroupName == isChecked.source.name);
        this.VitalFilterArr.splice(RemoveFilterIndex, 1)
      }
      var vf = new VitalFilter();
      vf.FieldName = this.Filterfield;
      vf.FieldValue = this.FilterOnValue;
      vf.FilterGroupName = isChecked.source.name;
      vf.FilterCondition = "or";
      this.VitalFilterArr.push(vf)
    }
    if (this.VitalFilterArr.length == 0) {
      this.VitalFilterArr = [];
      this.isDisabled = true;
      this.fnRetainOldArrayDataToEntityTabList();
    }// if all filter are remove

    if (this.VitalFilterArr.length == 0)
      this.isDisabled = true; // disable the apply filter if there is no filter data;
    /* for checkbox
    if (isChecked) {
      var vf = new VitalFilter();
      vf.FieldName = this.Filterfield;
      vf.FieldValue = this.FilterOnValue;
      vf.FilterCondition = "or";
      this.VitalFilterArr.push(vf)
    } else {

      if (this.VitalFilterArr.length > 0) {
        let RemoveFilterIndex = this.VitalFilterArr.findIndex(val => val.FieldName == this.Filterfield && val.FieldValue == FilterOnValue);
        this.VitalFilterArr.splice(RemoveFilterIndex, 1)
      }//end of if
    } // end of if else outer if - check for checked n unchecked box and remove details
    this.VitalAddParam.VitalFilter = this.VitalFilterArr;
    if (this.VitalFilterArr.length == 0) {
      this.VitalFilterArr = [];
      this.isDisabled = true;
      this.fnRetainOldArrayDataToEntityTabList();
    }// if all filter are remove

    if (this.VitalFilterArr.length == 0)
      this.isDisabled = true; // disable the apply filter if there is no filter data;
    */ // end of for check box
  }//end of fnFilterData


  fnRetainOldArrayDataToEntityTabList() {
    let oldData = JSON.parse(this.oldEntityTabList);
    this.EntitytabList[0].ResultContent = oldData[0].ResultContent;
    this.EntitytabList[0].Count = oldData[0].Count;
    this.TotalSearchResultCount = oldData[0].Count;
    //this.fnSearch(true);
  }

  fnApplyFilter() {
    // let ActiveTab = (this.isTabClick) ? this.EntityValue : this.DrpDwnEntityValue;
    this.EntitytabList = this.EntitytabList;
    let OldArry;
    this.oClose = !this.oClose;
    let UpdateArry = [];
    if (this.EntitytabList && this.EntitytabList.length == 0) {
      this.EntitytabList = this.deployemtkeybasedapidata;
    }
    else {
      this.EntitytabList = this.deployemtkeybasedapidata;
      this.oldEntityTabList = this.olddeploymentkeybasedapidata;
    }
    // let ActiveTab = (this.isTabClick) ? this.EntityValue : this.DrpDwnEntityValue;

    if (!this.oldEntityTabList) {
      OldArry = JSON.parse(this.olddeploymentkeybasedapidata);
    }
    else {
      OldArry = JSON.parse(this.oldEntityTabList);
    }
    // let updateArry1 = OldArry[0].ResultContent.filter(x => x.DisplayField.deploymentkey == "UPM" &&
    //   x.DisplayField.deploymentkey == "VPLABS");
    /*for (let i = 0; i < this.VitalFilterArr.length; i++) {
      const type = this.VitalFilterArr[i].FieldName;
      if (i == 0) {
        UpdateArry = OldArry[0].ResultContent.filter(x =>
          x.DisplayField[type].toLowerCase() == this.VitalFilterArr[i].FieldValue.toLowerCase());
      } else {
        UpdateArry = UpdateArry.filter(x => x.DisplayField[type].toLowerCase()
        == this.VitalFilterArr[i].FieldValue.toLowerCase() );
      }
    }*/
    UpdateArry = OldArry[0].ResultContent.filter((item) => {
      for (let i = 0; i < this.VitalFilterArr.length; i++) {
        const type = this.VitalFilterArr[i].FieldName.toLowerCase();
        if (item.DisplayField[type].toLowerCase() === undefined || item.DisplayField[type].toLowerCase() != this.VitalFilterArr[i].FieldValue.toLowerCase())
          return false;
      }
      return true;
    });
    this.EntitytabList[0].ResultContent = UpdateArry;
    this.EntitytabList[0].Count = UpdateArry.length;
    this.TotalSearchResultCount = this.EntitytabList[0].Count;
    if (UpdateArry.length == 0)
      this.NoResult = false;
    else
      this.NoResult = false;
    this.FnNoResultFound(false);
    //this.DisplayTextClick('',this.EntitytabList[0].ResultContent[1])

    //this.fnSearch(true);
    // this.VitalFilterArr.forEach((element) => {
    //   this.fnRemoveNotMatchAdditionalTab(element.FieldName, element.FieldValue);
    // });
  }//end of fnApplyFilter

  ClearTheCount(countValue) {
    if (countValue == 0) {
      this.advFilterCount = countValue;
      this.isAdvCount = false;
    }//end of if
  }//end of ClearTheCount

  //Clear the checked filter and make the filter param to null, call api to get back the previous result
  fnClearFilter() {
    /* for multiple checkbox
    //set checked value to false on click of clear button
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    }); */

    //set checked value to false on click of clear button
    this.checkboxes.forEach((element) => {
      element.checked = false;
    });
    this.VitalFilterArr = [];
    this.VitalAddParam.VitalFilter = this.VitalFilterArr;
    this.isDisabled = true;
    this.isClearFilter = true;
    this.fnRetainOldArrayDataToEntityTabList();
  }//end of fnClearFilter

  //Split and send the adv filter value to the api
  fnCallSubmit(value) {
    //split the adv filter value to get count of how many filters are applied
    if (value.length != 0) {
      this.advFilterCount = value.length;
      this.isAdvCount = true;
    } else {
      this.isAdvCount = false;
    }//set the count value
    //this.AdditionalParam.Filter = value;
    this.VitalAddParam.VitalFilter = value;
    this.triggerAdvOnGO = false;

    this.EntitytabList = [];

    setTimeout(() => {
      this.fnSearch(true);
    }, 100);
  }//end of fnCallSubmit

  //get the form data from child in order to should filters value in noresult
  AdvSearchNoResultData(AdvSearchData) {
    this.advNoResult = AdvSearchData;
    this.AdvChipFilter = true;
    this.AdvData = AdvSearchData;
    if (this.AdvData.length == 0)
      this.AdvChipFilter = false;

    if (this.EachEntityTabArray.length > 0 && this.EachEntityTabArray != null) {
      for (var val in AdvSearchData) {
        this.fnRemoveNotMatchAdditionalTab(val, AdvSearchData[val]);
      }//end of for
    }//end of if
  }//end of AdvSearchNoResultData

  fnRemoveNotMatchAdditionalTab(Key, Value) {
    let TempAddtionalDetailsArray = [];
    this.AddtionalDetailsArray.forEach(ele => {
      for (let df in ele.DisplayField) {
        if (df === Key && Value !== ele.DisplayField[df]) {
          const RemoveTab = this.EachEntityTabArray.findIndex(ETab => ETab.GUID == ele.GUID);
          if (RemoveTab != -1)
            this.EachEntityTabArray.splice(RemoveTab, 1);
          break;
        } else if (df === Key && Value === ele.DisplayField[df]) {
          TempAddtionalDetailsArray.push(ele);
        }//end of if check for the removed filtered value
      }//end of for
    });//end of foreach
    this.AddtionalDetailsArray = TempAddtionalDetailsArray;
  }

  removeAdvSearch(key) {
    delete this.AdvData[key];
    this.RemoveValue = key;
    this.NewAdvSearchFilter = this.AdvData;
    this.advFilterCount--;//** uncomment when u want to clear the additional tab opened on removing the chip (so that the count get decremented) */
    if (this.EachEntityTabArray.length > 0 && this.EachEntityTabArray != null) {
      // this.AddtionalDetailsArray.forEach(ele=> {
      //   for(let df in ele.DisplayField){
      //     if(df == key && RemoveAdvValue != ele.DisplayField[df]){
      //       //var cc =  this.object.splice(index,1);
      //       const RemoveTab = this.EachEntityTabArray.findIndex(ETab => ETab.GUID == ele.GUID);
      //       if(RemoveTab != -1)
      //         this.EachEntityTabArray.splice(RemoveTab,1);
      //     }//end of if check for the removed filtered value
      //   }//end of for
      // });//end of foreach
    }//end of if -> check if any additional tab are opened
  }

  // ConvertToOrginalEntity(entity:any){
  //   let shortentity="";
  //   switch(entity){
  //     case 'Location ID':shortentity="Location";break;
  //     case 'Laboratory':shortentity="Lab";break;
  //     case 'Account ID':shortentity="Users";break;
  //     case 'Group ID':shortentity="Users";break;
  //     default:shortentity=entity;break;
  //   }
  //   return shortentity;
  // }
  goCount = 0;
  fnSearch(isFuncCall) {
    if(this.isSearching)
      return;

    let flagLength;
    let dluflag=false;
    this.isOnLoad = false;
    let previousFilter: any = [];
    //Details: Check if advcount is present and trigger the child component to read form data of child
    //Reason: when user clear the search text and enter the new value consider the previous or changed adv filter
    if (this.isAdvCount) {
      previousFilter = this.VitalFilterArr;
    }
    //***uncomment when additional tab open need to retained only based on adv search***/
    else {
      if (this.advFilterCount == 0 || this.advFilterCount == undefined) {
        this.EachEntityTabArray = [];
      }
    }
    //****uncomment till here****/

    if (this.loadAdvSearch) {
      var isAdvanceSearchOpen = (<HTMLElement>document.getElementById('AdvSearch')).className;
      if (!isAdvanceSearchOpen.includes('show')) {
        this.triggerAdvOnGO = false;
        //this.isGoDisabled = true;
      } else {
        this.triggerAdvOnGO = true;
        //this.isGoDisabled = false;
      }//end of if
      if (this.triggerAdvOnGO) {
        this.goCount++;
        this.triggerAdvSubmit = 'goclicked' + this.goCount;
        return;
      }
      if (isAdvanceSearchOpen.includes('show')) {
        (<HTMLElement>document.getElementById('AdvSearch')).classList.remove('show');
      }//end of if
    }//check advsearch is loaded or not


    //this.isFilterVisible = true;
    if (!isFuncCall) {
      this.isTabClick = false;
      this.VitalAddParam = new VitalAdditionalParam();
      this.EntitytabList = [];
      this.ResultContent = [];
      this.isOnlyFilter = false;
      this.FacetsContent = [];
    }//end of is funcall
    // this.DrpDwnEntityValueFOrSearch=this.ConvertToOrginalEntity(this.DrpDwnEntityValue);
    //this.KeyWord = (this.isTabClick) ? this.EntityValue : this.DrpDwnEntityValueFOrSearch;
    this.KeyWord = (this.isTabClick) ? this.EntityValue : this.DrpDwnEntityValue;
    let QueryValue;
    let QueryKeyWord
    if (typeof (this.queryvalue) !== 'undefined' && this.queryvalue != null) {
      // if (this.queryvalue.length > 0)
      //   var onlyChar = /^[a-zA-Z ]+$/.test(this.queryvalue.trim());
      // if (onlyChar)
      //   QueryValue = this.queryvalue.trim() + '~';
      // else
      //   QueryValue = this.queryvalue.trim();
      // let flagLength;
      if (this.queryvalue !== "") {
        const myRe = /^lab$|^loc$|^userid$|^user$|^path$|^phy$|^fac$|^aou$|^accid$|^orgid$|^case$|^pat$|^aao$/;
        this.queryvalue = this.queryvalue.trim()
        let queryLength = this.queryvalue.split(":");
        if (queryLength[queryLength.length - 1] !== "" && queryLength.length >= 3) {
          //3 part search
          if(myRe.test(queryLength[1].toLowerCase()))
          {
            flagLength = true;
          }
          else{
            flagLength = true;
             dluflag=true;
          }
        } else if (queryLength[queryLength.length - 1] !== "" && queryLength.length == 1) {
          //single search
          flagLength = true;
        }
        else {
          flagLength = false;
        }
      }
      if (this.queryvalue.length > 0)
        QueryValue = this.queryvalue.trim();
      QueryKeyWord = (QueryValue.length > 0) ? QueryValue : null;
    } else {
      QueryKeyWord = null;
    }

    //**************if Result no found**********************
    this.EntityName = this.KeyWord;
    this.SearchText = this.queryvalue;

    //***************end of if result found*****************

    // ***********Build a search object********************
    // this.searchfilter = SearchKey();
    var cc = this.searchfilter.VitalSearchText;
    this.searchTextDetails.Text = QueryKeyWord;
    this.searchfilter.VitalSearchText = this.searchTextDetails;
    if(dluflag==true)
    {
      this.searchfilter.SearchOnIndex = 'dlu';
    }
    else{
      this.searchfilter.SearchOnIndex = (this.KeyWord.toUpperCase() == 'ALL') ? '*' : this.KeyWord;
    }
    this.searchfilter.VitalAdditionalParam = this.VitalAddParam;
    this.VitalAddParam.VitalQueryType = "Full";
    this.VitalAddParam.VitalSearchMode = "All";
    //this.searchfilter.AdditionalParam = this.AdditionalParam;

    // ***********end of Build search object********************


    if (this.VitalFilterArr.length == 0 && previousFilter.length != 0 && previousFilter != undefined) {
      this.VitalAddParam.VitalFilter = previousFilter;
    }
    if (flagLength) {
      this.ngxService.start();
      this.isSearching = true;
      this.DatabaseService.SearchEntity(this.KeyWord, this.searchfilter).subscribe(res => {
        if (res != 'Exception') {
          this.resultData = res;
          if (this.resultData.Count > 0) {
            if (this.resultData.Results[0].Context?.toString().toLowerCase() == 'users' || this.resultData.Results[0].Context?.toString().toLowerCase() == 'pathologist' || this.resultData.Results[0].Context?.toString().toLowerCase() == 'physician' || this.resultData.Results[0].Context?.toString().toLowerCase() == 'userid' || this.resultData.Results[0].Context?.toString().toLowerCase() == 'patient' || this.resultData.Results[0].Context?.toString().toLowerCase() == 'case') {
              for (let i = 0; i < this.resultData.Results.length; i++) {
                var regex = new RegExp('T', "g");
                const format = 'MM/dd/yyyy hh:mm:ss a';
                const locale = 'en-US';
                if(this.resultData.Results[0].Context?.toString().toLowerCase() == 'patient' || this.resultData.Results[0].Context?.toString().toLowerCase() == 'case') {
                  if(this.resultData.Results[i].DisplayField.lastsigndate) {
                  this.resultData.Results[i].DisplayField.lastsigndate = formatDate(this.resultData.Results[i].DisplayField.lastsigndate, format, locale);
                 }
                }
                 else{
                   this.resultData.Results[i].DisplayField.modifieddate = formatDate(this.resultData.Results[i].DisplayField.modifieddate, format, locale);
                 }
                // if(this.resultData.Results[0].Context?.toString().toLowerCase() != 'patient' && this.resultData.Results[0].Context?.toString().toLowerCase() != 'case') {
                // this.resultData.Results[i].DisplayField.userslastloggedindate = formatDate(this.resultData.Results[i].DisplayField.userslastloggedindate, format, locale);
                // }
                if(this.resultData.Results[i].DisplayField.status.toString().toLowerCase() === "inactive" ){
                this.resultData.Results[i].DisplayField.status = "Inactive";
                }
                else{
                  this.resultData.Results[i].DisplayField.status= this.resultData.Results[i].DisplayField.status[0].toUpperCase()+ this.resultData.Results[i].DisplayField.status.slice(1);
                }
              }
            }
            if(['users', 'pathologist', 'physician'].includes(this.resultData.Results[0].Context?.toString().toLowerCase())){
              for (let i = 0; i < this.resultData.Results.length; i++) {
                if((['verified', 'autoinitiated', 'autoreinitiated'].includes(this.resultData.Results[i].DisplayField.authstatus?.toString().toLowerCase()))) {
                  this.resultData.Results[i].DisplayField.loginname = this.resultData.Results[i].DisplayField.email
                }
              }
            }
          }
          let multiResultCount: number = 0;
          if (this.KeyWord.toUpperCase() == "ALL") {
            if (Object.keys(res).length > 0) {
              this.EntitytabList = [];
              this.ContSearchArray = [];
              this.TotalSearchResultCount = 0;
              for (var i in this.resultData) {
                for (var j in this.resultData[i]) {
                  if (this.resultData[i][j].Count > 0) {
                    this.isEntitytTab = true;
                    let tabarr = new ResultTabArray();
                    tabarr.GUID = "EntityTab" + this.resultData[i][j].Facets.Context[j].value;
                    tabarr.Removable = false;
                    tabarr.TabName = this.resultData[i][j].Facets.Context[j].value;
                    tabarr.Count = this.resultData[i][j].Facets.Context[j].count;
                    tabarr.disabled = false;
                    tabarr.ResultContent = this.resultData[i][j].Results;
                    // this.datachangesusers = this.resultData[i][j].Results;
                    if (i == "0") {
                      tabarr.active = true;
                      this.EntityValue = this.resultData[i][j].Facets.Context[j].value;
                    } else {
                      tabarr.active = false;
                    }
                    this.TotalSearchResultCount += this.resultData[i][j].Facets.Context[j].count;
                    let ContArr = {
                      "Entity": this.resultData[i][j].Facets.Context[j].value,
                      "ContData": this.resultData[i][j].ContinuationSearchToken
                    }
                    this.ContSearchArray.push(ContArr);
                    delete this.resultData[i][j].Facets.Context;
                    tabarr.FacetsContent = this.resultData[i][j].Facets;//this.fnSplitCamelCaseForFacte(this.resultData[i][j].Facets);
                    this.EntitytabList.push(tabarr);
                  }
                  multiResultCount += this.resultData[i][j].Count;
                }//end of inner foreach
              }//end of outer foreach
            }//check if data is from multiple index
            if (multiResultCount == 0) {
              this.NoResult = true;
              this.ContSearchArray = [];
            } //end of if else - check if result is found in multiple index search
          } else if (this.KeyWord.includes(',')) {
            if (Object.keys(res).length > 0) {
              this.EntitytabList = [];
              this.ContSearchArray = [];
              this.TotalSearchResultCount = 0;
              for (var i in this.resultData) {
                for (var j in this.resultData[i]) {
                  if (this.resultData[i][j].Count > 0) {
                    this.isEntitytTab = true;
                    let tabarr = new ResultTabArray();
                    tabarr.GUID = "EntityTab" + this.resultData[i][j].Facets.Context[j].value;
                    tabarr.Removable = false;
                    tabarr.TabName = this.resultData[i][j].Facets.Context[j].value;
                    tabarr.Count = this.resultData[i][j].Facets.Context[j].count;
                    tabarr.disabled = false;
                    tabarr.ResultContent = this.resultData[i][j].Results;
                    // this.datachangesusers = this.resultData[i][j].Results;
                    if (i == "0") {
                      tabarr.active = true;
                      this.EntityValue = this.resultData[i][j].Facets.Context[j].value;
                    } else {
                      tabarr.active = false;
                    }
                    this.TotalSearchResultCount += this.resultData[i][j].Facets.Context[j].count;
                    let ContArr = {
                      "Entity": this.resultData[i][j].Facets.Context[j].value,
                      "ContData": this.resultData[i][j].ContinuationSearchToken
                    }
                    this.ContSearchArray.push(ContArr);
                    delete this.resultData[i][j].Facets.Context;
                    tabarr.FacetsContent = this.resultData[i][j].Facets;//this.fnSplitCamelCaseForFacte(this.resultData[i][j].Facets);
                    this.EntitytabList.push(tabarr);
                  }
                  multiResultCount += this.resultData[i][j].Count;
                }//end of inner foreach
              }//end of outer foreach
            }//check if data is from multiple index
            if (multiResultCount == 0) {
              this.NoResult = true;
              this.ContSearchArray = [];
            } //end of if else - check if result is found in multiple index search
          } else {
            if (this.isTabClick) {
              if (this.resultData.Count == 0) {
                this.NoResult = true;
              } else {
                this.NoResult = false;
                this.TotalSearchResultCount = 0;
                if (this.resultData.ContinuationSearchToken !== null) {
                  this.ContinueSearchDetails = {};
                  this.ContinueSearchDetails = this.resultData.ContinuationSearchToken;
                  if (this.ContSearchArray != null && this.ContSearchArray.length != 0) {
                    const UpdateContToken = this.ContSearchArray.find(cont => cont.Entity == this.resultData.Facets.Context[0].value);
                    UpdateContToken.ContData = this.resultData.ContinuationSearchToken;
                  }
                } else {
                  this.ContinueSearchDetails = {};
                  if (this.ContSearchArray != null && this.ContSearchArray.length != 0) {
                    const UpdateContToken = this.ContSearchArray.find(cont => cont.Entity == this.resultData.Facets.Context[0].value);
                    UpdateContToken.ContData = {};
                  }
                }//end of setting the continue search token
                if (this.isOnlyFilter) {
                  let TabID = "EntityTab" + this.resultData.Facets.Context[0].value;
                  var UpdateTab = this.EntitytabList.find(tab => tab.GUID == TabID)
                  UpdateTab.Count = this.resultData.Facets.Context[0].count;
                  UpdateTab.ResultContent = this.resultData.Results;
                  this.ResultContent = this.resultData.Results;
                  this.TotalSearchResultCount = this.resultData.Facets.Context[0].count;
                } else {
                  if (this.isClearFilter) {
                    let TabID = "EntityTab" + this.resultData.Facets.Context[0].value;
                    var UpdateTab = this.EntitytabList.find(tab => tab.GUID == TabID)
                    UpdateTab.Count = this.resultData.Facets.Context[0].count;
                    UpdateTab.ResultContent = this.resultData.Results;
                    this.ResultContent = this.resultData.Results;
                    this.TotalSearchResultCount = this.resultData.Facets.Context[0].count;
                  } else {
                    this.isEntitytTab = true;
                    this.EntitytabList = [];
                    let tabarr = new ResultTabArray();
                    tabarr.GUID = "EntityTab" + this.resultData.Facets.Context[0].value;
                    tabarr.Removable = false;
                    tabarr.TabName = this.resultData.Facets.Context[0].value;
                    tabarr.Count = this.resultData.Facets.Context[0].count;
                    tabarr.active = true;
                    tabarr.ResultContent = this.resultData.Results;
                    this.TotalSearchResultCount = this.resultData.Facets.Context[0].count;
                    delete this.resultData.Facets.Context;
                    tabarr.FacetsContent = this.resultData.Facets;//this.fnSplitCamelCaseForFacte(this.resultData.Facets);
                    this.EntitytabList.push(tabarr);
                  }
                  this.isClearFilter = false;
                  // delete this.resultData.Facets.Context;
                  // this.FacetsContent = this.fnSplitCamelCaseForFacte(this.resultData.Facets);
                  // this.ResultContent = this.resultData.Results;
                }//end of if - condition if isfilter is clicked
              }//end of if - check if result is found in single index search
              // this.isTabClick = false;
            } else {
              if (this.resultData.Count == 0) {
                this.NoResult = true;
                this.ResultContent = [];
                this.EntitytabList = [];
              } else {
                this.NoResult = false;
                this.TotalSearchResultCount = 0;
                if (this.resultData.ContinuationSearchToken !== null) {
                  this.ContinueSearchDetails = {};
                  this.ContinueSearchDetails = this.resultData.ContinuationSearchToken;
                } else {
                  this.ContinueSearchDetails = {};
                }//reset the continue search token for subsequent result
                if (this.isOnlyFilter) {
                  let TabID = "EntityTab" + this.resultData.Facets.Context[0].value;
                  var UpdateTab = this.EntitytabList.find(tab => tab.GUID == TabID)
                  UpdateTab.Count = this.resultData.Facets.Context[0].count;
                  UpdateTab.ResultContent = this.resultData.Results;
                  //this.ResultContent = this.resultData.Results;
                  this.TotalSearchResultCount = this.resultData.Facets.Context[0].count;
                } else {
                  this.isEntitytTab = true;
                  this.EntitytabList = [];
                  let tabarr = new ResultTabArray();
                  tabarr.GUID = "EntityTab" + this.resultData.Results[0].Context;  ////Facets.Context[0].value;
                  tabarr.Removable = false;
                  tabarr.TabName = this.resultData.Results[0].Context; ////Facets.Context[0].value;
                  tabarr.Count = this.resultData.Count;////Facets.Context[0].count;
                  tabarr.active = true;
                  tabarr.ResultContent = this.resultData.Results;
                  this.TotalSearchResultCount = this.resultData.Count;   ////Facets.Context[0].count;
                  ////delete this.resultData.Facets.Context;
                  tabarr.FacetsContent = JSON.parse(this.resultData.Facets);
                  this.EntitytabList.push(tabarr);
                }//end of else check for onlyfilter
              }//end of if else check if result is there are not
            } //end of if -> istabclick check
          }//end of if else
        } else {
          this.ngxService.stop();
        }//end of if check for exception

        this.FnNoResultFound(true);
        this.OriginalArrayOfAPICall = this.EntitytabList;
        this.deployemtkeybasedapidata = this.OriginalArrayOfAPICall

        if (this.ResultContent.length >= 0) {
          this.ngxService.stop();
        }
        this.isSearching = false;
        // this.addformatGridData();//end of if - loader end
      }, (error => {
        this.ngxService.stop();
        this.isSearching = false;
        console.error(error);

      }));//end of calling the dataservice
    } else {
      this.FnNoResultFound(true);
      this.OriginalArrayOfAPICall = this.EntitytabList;
      this.NoResult = true;
    }
  }//end of fnsearch]

  getHeaders(data){
    if(data.toLowerCase() ==='organization id'){
      return 'Group ID'
    }
    else if(data.toLowerCase() === 'account id')
      {
        return 'Location ID'
      }
    return data
  }

  FnNoResultFound(isFromApiCall) {
    if (this.NoResult) {
      this.isFilterVisible = false;
      this.oClose = true;
      //this.Searchtabarr = new ResultTabArray();
      //this.SearchList = [];
    } else {
      this.oClose = false;
      if(this.EntitytabList.length >0){
      if (this.EntitytabList[0].TabName.toLowerCase() == "users" || this.EntitytabList[0].TabName.toLowerCase() == "userid" || this.EntitytabList[0].TabName.toLowerCase() == "pathologist" || this.EntitytabList[0].TabName.toLowerCase() == "physician" || this.EntitytabList[0].TabName.toLowerCase() == "patient" || this.EntitytabList[0].TabName.toLowerCase() == "case") {
        this.isFilterVisible = false;
      } else {
        this.isFilterVisible = true;
      }
    }
      this.Searchtabarr.Count = this.TotalSearchResultCount;
      this.Searchtabarr.GUID = "SearchGUID";
      this.Searchtabarr.TabName = "Search";
      this.Searchtabarr.Removable = false;
      this.Searchtabarr.active = true;
      this.SearchList.push(this.Searchtabarr);
      if (!this.isOnlyFilter)
        if (isFromApiCall) {
          this.VitalFilterArr = [];
          this.oldEntityTabList = JSON.stringify(this.EntitytabList);
          this.olddeploymentkeybasedapidata = this.oldEntityTabList;
          this.wijmogriduservalue = this.EntitytabList[0];
        } else {
          if (this.oldEntityTabList == undefined)
            this.oldEntityTabList = JSON.stringify(this.EntitytabList);
        }
    }//end of if else -> no result found
  }

  CallVitalAdminApp(vitaladminApp: any) {
    console.log(vitaladminApp.id, "id");
  }

  adminsearchTabContent(event: any) {
    console.log(event);
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substring(1).toLowerCase();
  }

  /* adminsearchTabCompleteds(event: any)
   {

       let Value: any ={};
       if(event && event.detail && event.detail.DisplayField)
       {


       let aTab: any = {};
       aTab = event.detail;

       Value.DisplayField = aTab.DisplayField;
       Value.GUID = aTab.GUID;
       Value.DisplayText = aTab.DisplayField.Context + aTab.GUID;


      // aTab.GUID =this.newGuid();
     //  aTab.TabName =this.tabs[activeTab].accountid + "" + this.tabs[activeTab].cardType;
     //  aTab.active = true;


       let tabArr = new ResultTabArray();
       tabArr.GUID = Value.GUID;
       tabArr.Removable = true;
       tabArr.TabName = Value.DisplayText;
       tabArr.Count = null;
       tabArr.active = true;
       tabArr.disabled = false;
       tabArr.Content = Value;
      let customarrIndex  = this.EachEntityTabArray.findIndex(arr => arr.Content.DisplayField.Context == aTab.DisplayField.Context)
      if(customarrIndex == -1)
      {
      // this.EachEntityTabArray.push(tabArr);
      // this.AddtionalDetailsArray.push(Value);
       this._tabset.rightCount = 0;
        this.adjustTabs();
      }
      else{
       this.EachEntityTabArray[customarrIndex].GUID = Value.GUID;
       this.EachEntityTabArray[customarrIndex].Removable = true;;
       this.EachEntityTabArray[customarrIndex].TabName = Value.DisplayText;
       this.EachEntityTabArray[customarrIndex].Count = null;
       this.EachEntityTabArray[customarrIndex].disabled = false;
       this.EachEntityTabArray[customarrIndex].Content = Value;
      // this.EachEntityTabArray[customarrIndex].GUID = Value.GUID;
      // this.EachEntityTabArray[customarrIndex].GUID = Value.GUID;
       this._tabset.rightCount = 0;
       this.adjustTabs();
      }
       }
   }*/

  removeContentInsideBraces(inputString) {
    return inputString.replace(/\([^)]*\)/g, '');
  }

  launchCase360(operation: string, value: any) {
    if (this.hideCase360Btn) {
      this._snackBar.open("User is not authorised");
      return
    }
    let userlogged = localStorage.getItem("UserEmail");
    try {
      //.const dbName = sessionStorage.getItem('deploymentKey') ?.toUpperCase();
      const dbName = value.DisplayField.deploymentkey;
      let accession = this.removeContentInsideBraces(value.DisplayField.accessionnumber)
      const caseObj = {
        AccessionId: accession,
        caseId: value.DisplayField.caseid,
        OrgID: value.DisplayField.laborgid,
        userEmail: userlogged,
        DeploymentKey: dbName
      };
      this.DatabaseService.GetCase360Url(caseObj).subscribe(
        (data: any) => {
          // let urlstring=JSON.parse(data);
          this.goToLink(data);
          //Case 360 there should not be audit captured
          // var auditObj = {
          //   caseId: this.tempvalue.DisplayField.caseid,
          //   pathAccountId: '',
          //   action: "case360",
          //   pathAccoutid: ''
          // }
          // this.auditCaseAssign(auditObj,"case360");
        },
        (error) => {
          this.ngxService.stop();
          console.log(error);
        }
      );
    } catch (error) {
      console.error(error);
    }
  }
  getColumnWidth(binding: string): number {
    switch (binding) {
        case "DisplayField.patientname":
            return 140; // Set width for patientname
        case "DisplayField.status":
        case "DisplayField.caseid":
        case "DisplayField.services":
            return 80;
        case "DisplayField.accessionnumber":
        case "DisplayField.requisitionnumber":
            return 106;
        case "DisplayField.orderingfacility":
        case "DisplayField.orderingphysician":
        case "DisplayField.lastsigndate":
        case "DisplayField.pathologistuser":
            return 150;
        default:
            return 130;
    }
}

  //elastic search other than pat/case/userid/user/path/phy
  DisplayCardClick(operation, Value) {
    sessionStorage.setItem('deploymentKey', Value.DisplayField.deploymentkey);
    //context is set in the session, which stores the context value from Elastic search if the selected Lab is a Lab, Fscility or EPG
    sessionStorage.setItem('context', Value.DisplayField.context);
    sessionStorage.setItem('selectedContext', Value.DisplayField.context);

        const exist = this.EachEntityTabArray.length;
        this.commonser.getAccountType(Value);
      //   // this.CallVitalAdminApp({id:Value.DisplayField.Locationid,"context": "context"});
      //   //if (exist == undefined) {
        if (exist == 0) {
          let tabArr = new ResultTabArray();
          tabArr.GUID = Value.GUID;
          tabArr.Removable = true;
          tabArr.TabName = Value.DisplayText;
          tabArr.Count = null;
          tabArr.active = true;
          tabArr.disabled = false;
          tabArr.Content = Value;
          this.EachEntityTabArray.push(tabArr);
          this.cd.detectChanges();
          this.AddtionalDetailsArray.push(Value);
        }
        else if (exist > 0) {
      //     // exist.active = true;
      //     //this.removeThisTab(this.EachEntityTabArray);
      //     //this.EachEntityTabArray[0].GUID = Value.GUID;
          if (this.EachEntityTabArray.length > 0) {
            this.EachEntityTabArray[0].GUID = Value.GUID;
            this.EachEntityTabArray[0].Removable = true;
            this.EachEntityTabArray[0].TabName = Value.DisplayText;
            this.EachEntityTabArray[0].Count = null;
            this.EachEntityTabArray[0].active = true;
            this.EachEntityTabArray[0].disabled = false;
            this.EachEntityTabArray[0].Content = Value;
          }
          else if (exist > 0) {
      //       // exist.active = true;
      //       //this.removeThisTab(this.EachEntityTabArray);
      //       //this.EachEntityTabArray[0].GUID = Value.GUID;
            if (this.EachEntityTabArray.length > 0) {
              this.EachEntityTabArray[0].GUID = Value.GUID;
              this.EachEntityTabArray[0].Removable = true;
              this.EachEntityTabArray[0].TabName = Value.DisplayText;
              this.EachEntityTabArray[0].Count = null;
              this.EachEntityTabArray[0].active = true;
              this.EachEntityTabArray[0].disabled = false;
              this.EachEntityTabArray[0].Content = Value;
            }
          }
          this._tabset.rightCount = 0
          this.adjustTabs();
        }
    else {
      this.DatabaseService.logout();
    }
  }
}
class ColDef {
  constructor(public binding?: string, public header?: string) {
  }
}
