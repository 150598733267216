import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import {  MatOption } from '@angular/material/core';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import * as XLSX from 'xlsx';
import { DataShareService } from '../../../core/services/datashare.service';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import {  MatSelect } from '@angular/material/select';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import * as wjCore from '@grapecity/wijmo';
import * as wjGrid from '@grapecity/wijmo.grid';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import * as wjcGridFilter from '@grapecity/wijmo.grid.filter';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { MultisheetPopupComponent } from 'src/app/base/popup/multisheet-popup/multisheet-popup.component';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { VitalAdminTabService } from '../../tab.service';
import {  MatAutocompleteTrigger } from '@angular/material/autocomplete';

export interface Task {
  color: ThemePalette;
}

declare var $: any;
/////////

@Component({
  selector: 'app-user-defined-fields',
  templateUrl: './user-defined-fields.component.html',
  styleUrls: ['./user-defined-fields.component.scss']
})

 export class UserDefinedFieldsComponent implements OnInit {
  task: Task = {
    color: 'primary',
  };
  @ViewChild('matSelectOption') matSelectOption: MatSelect;
  @ViewChild('filter') filter: wjcGridFilter.FlexGridFilter;

  @Input() templateData: any;
  dbName: any = sessionStorage.getItem('deploymentKey').toUpperCase();
  accountid: any;
  casetype: any;
  panelOpenState = false;
  orgid: any;
  templateChangedTime: string;
  SubMenuCardModel: any;
  noDataFound: boolean = false;
  gridPage: boolean = false;
  gridheader: any[];
  showPaginationMainGrid: boolean;
  UDFGridData: wjcCore.CollectionView<any>;
  uploadClicked: boolean = false;
  gridWidth: number;
  sheetsToSelect: any[];
  excelDataArray: any = [];
  sheetHeader: any[];
  addEditScreen: boolean = false;
  showInvalidColumns: boolean;
  invalidColumns: string = "";
  gridData: wjcCore.CollectionView<any>;
  gridDisplay: boolean;
  showDelete: boolean = false;
  postUpload: boolean = false;
  postDownload: boolean;
  addEditClicked: boolean;
  editEnabled: boolean = false;
  action: string;
  currentData: any;
  selectedrowData: any;
  typeData: string[] = [];
  allFields = [{}]
  mandatoryFields = [{}]
  bulkUpdateFields = [{}];
  warningMessage: string = "";
  workBook: any;
  Status: any[] = [];
  allSelected = false;
  arrowIconSubject = new BehaviorSubject('arrow_drop_down');
  procedureLevel = false;



  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  uploadBtn: boolean;
  createBtn: boolean;
  editBtn: boolean;
  exportBtn: boolean;
  CopyBtn: boolean
  selector: Selector = null;
  selectedCopyData: any = [];
  UDFParents: any = [];
  UDFFormGroup = this._fb.group({
    frmAttributeType: false,
    procedureLevel: false,
    frmAttributeName: ["", Validators.required],
    frmDisplayName: ["", Validators.required],
    frmActive: true,
    frmDisplayValue: [""],
    frmAction: [""],
    frmDefaultValue: [""],
    frmWaterMark: [""],
    frmParentAttribute: [""],
    frmProcedureName: [""],
    frmRowNum: [""],
    frmColNum: [""],
    frmRowSpan: [""],
    frmColSpan: [""],
    frmMaxLength: [""],
    frmSectionOrder: [""],
    frmCellStyle: [""],
    frmLabelStyle: [""],
    frmControlStyle: [""],
    frmCssClass: [""],
    frmVersionNum: 0,
    frmDivName: [""],
    frmDataSourceType: [""],
    frmRefType: [""],
    frmRefCode: [""],
    frmBatchProfileID: [""],
    frmOrderCodeID: [""],
    frmSequence: [""],
    frmSpanishName: [""],
    frmEditable: false,
    frmAdditionalData: [""],
    frmMandatory: false,
    frmValidateSection: false,
    frmQuickAccession: false,
    frmHTMLControl: false,
    frmCollapsible: false,
    frmDisplayType: ["", Validators.required],
    frmStatus: ["", Validators.required]
  });
  copyFromOrgFrm = this._fb.group({
    frmDeplyment: '',
    frmOrganization: '',
    Copyisactive: false
  })
  dropdownSettings = {
    singleSelection: false,
    idField: 'item',
    textField: 'item',
    enableCheckAll: true,
    allowSearchFilter: true,
    limitSelection: -1,
    clearSearchFilter: true,
    maxHeight: 70,
    itemsShowLimit: 2,
    closeDropDownOnSelection: false,
    showSelectedItemsAtTop: false,
    defaultOpen: false
  };

  UDFDisplayTypes: any = [];
  UDFRefCodes: any = [];
  vitalservice: any;
  configuredJson: any;
  distinctCategory: any;
  CriteriaList: any;
  TempCriteriaList: any;
  userDropdownList2: any;

  editData: any;
  UDFProcedures: any = [];
  editAttributeId: any;
  selectedStatus: any = [];
  copyScreenPage: boolean;
  destDeployment: string;
  DeploymentKeys: any;
  searchInput: string;
  searchResult: any[];
  organizationList: any;
  selectedData: any = [];
  copyData: any;
  disableControls: string;
  tempUdfDisplayTypes: any;
  sampleData: any;
  sampleMinData: any;
  sampleUpdateData: any
  filename: any;
  oldObject: any;
  udfCellStyles: any;
  cellStylesDropdown: any = [];
  filterCellStyle: Observable<any[]>;
  filterActions: Observable<any[]>;
  filterLabelStyle: Observable<any[]>;
  udfActions: any;
  actiondropdown: any;
  DeleteUDF: boolean = false;
  udfLabelStyles: any;
  labelStyleDropdown: any;
  filterProcedures: Observable<any>;
  udfControlStyle: any;
  udfCssClass: any;
  controlStyleDp: any;
  cssClassDp: any[];
  filterControlStyle: Observable<any>;
  filterCssClass: Observable<any>;
  filterParent: Observable<any>;
  filterStatus: Observable<any>;
  udfDataSourcedrpdwn: any;
  addtionalDatadrpdn: any;
  noDataFoundCopy: boolean;
  dataArray: any[];
  selectedLevel: string;
  formGroup: FormGroup;
  mandateDispVal: boolean;
  frozenclms: number;
  organizationId: any;
  inputAutoComplete: any;
  tempAddtionalDatadrpdn: any;
  isBulkUpdate: boolean=false;
  exactData: string[];
  data: string[];
  deletedColumn: any;
  constructor(public _snackbar: MatSnackBar, private _fb: FormBuilder, private dialog: MatDialog,
    private datashare: DataShareService,
    private commonService: CommonService, public tabService: VitalAdminTabService, private vitalHttpServices: VitalHttpServices, public ngxService: NgxUiLoaderService) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      vitalHttpServices,
      datashare,
    );
  }
  ngOnInit(): void {
    this.orgid = this.templateData.secondarykeys.OrganizationId.toString();
    this.accountid = this.templateData.cardIdentifier;
    this.ShowUDFData(this.templateData.submenuData)
    this.gridPage = true
    //#region Get UDFDATA
    this.getStatusDropdown('');
    this.GetButtondetails();
    //#endregion
  }
  GetButtondetails() {
    this.GetButtonAccess(this.vitalHttpServices.CasemenuAction);

  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Export':
          this.exportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Create':
          this.createBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Edit':
          this.editBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'CopyToOrg':
          this.CopyBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Upload':
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case "DeleteUDF":
          this.DeleteUDF = seletedMenuPermissions[i].IsPermitted === 'true' ? true : false;
          break;
      }
    }
  }
  addingSampleData() {
    this.sampleData = [{
      AttributeName: "SampleName"
      , Status: "Order,Requisition,Accession,Grossing,Processing,Diagnosis,Diagnosis - Pending Tests,Review,Review - Pending Tests,Send Out"
      , DisplayName: "Sample Name"
      , DisplayType: "dropdown"
      , DisplayValues: "Approved-Approved,Failed-Failed,Necessity Check-NecessityCheck,Not Required-NotRequired,Pending Approval-PendingApproval"
      , DefaultValue: "NecessityCheck"
      , IsActive: "1"
      , ParentName: "SampleParentnameName"
      , Action: "onmouseover=$(this).css(&#39;background&#39;,&#39;#4255B4&#39;):onmouseout=$(this).css(&#39;background&#39;,&#39;#008ACD&#39;):onclick=toggleCheckbox(this)"
      , RowNum: "1"
      , ColNum: "1"
      , RowSpan: "1"
      , ColSpan: "1"
      , CellStyle: "width: 35%;vertical-align: top;"
      , LableStyle: "font:bold 11px Roboto, serif;    padding: 4px 4px;      font-size: 13px;      padding-bottom: 1px;"
      , ControlStyle: "width:150px; height:13px;width:150px;vertical-align: middle;border: 1px solid #CCCCCC;padding: 1px 0;"
      , IsEditable: "1"
      , MaxLength: "1"
      , WaterMark: "Sample watermark"
      , CssClass: "validateinputcontrols"
      , IsMandatory: "1"
      , VersionNum: "1"
      , IsCollapsible: "1"
      , DivName: "dvCDFPlaceHolder"
      , ValidateAtSectionLevel: "1"
      , SectionOrder: "10"
      , IsQuickAccession: "1"
      , IsHTMLControl: "1"
      , DataSourceType: "Medication"
      , AdditionalData: '{"spanDisplayName":"Browse A-Z","DataSourceType":"Medication"}'
      , RefType: "AOE"
      , RefCode: "2"
      , BatchProfileID: "1"
      , OrderCodeID: "1"
      , Sequence: "1"
      , SpanishDisplayName: "Raza del paciente"
      ,ProcedureName: "SampleProcName"
      ,ProcedureID:"1246"
    }]
    this.sampleMinData = [{
      AttributeName: "SampleName"
      , Status: "Order,Requisition,Accession,Grossing,Processing,Diagnosis,Diagnosis - Pending Tests,Review,Review - Pending Tests,Send Out"
      , DisplayName: "Sample Name"
      , DisplayType: "dropdown"
      , DisplayValues: "Approved-Approved,Failed-Failed,Necessity Check-NecessityCheck,Not Required-NotRequired,Pending Approval-PendingApproval"
      , IsActive: "1"
      , ParentName: "SampleParentnameName"
      , RowNum: "1"
      , ColNum: "1"
      , CellStyle: "width: 35%;vertical-align: top;"
      , LableStyle: "font:bold 11px Roboto, serif;    padding: 4px 4px;      font-size: 13px;      padding-bottom: 1px;"
      , ControlStyle: "width:150px; height:13px;width:150px;vertical-align: middle;border: 1px solid #CCCCCC;padding: 1px 0;"
      , ProcedureID:"1246"
      ,ProcedureName:"SampleProcName"
    }]
    this.sampleUpdateData = [{
      Attributeid: "123"
      ,AttributeName:"SampleName"
      , Status: "Order,Requisition,Accession,Grossing,Processing,Diagnosis,Diagnosis - Pending Tests,Review,Review - Pending Tests,Send Out"
      , DisplayName: "Sample Name"
      , DisplayType: "dropdown"
      , DisplayValues: "Approved-Approved,Failed-Failed,Necessity Check-NecessityCheck,Not Required-NotRequired,Pending Approval-PendingApproval"
      , DefaultValue: "NecessityCheck"
      , IsActive: "1"
      ,ParentId:"1246"
      , Action: "onmouseover=$(this).css(&#39;background&#39;,&#39;#4255B4&#39;):onmouseout=$(this).css(&#39;background&#39;,&#39;#008ACD&#39;):onclick=toggleCheckbox(this)"
      , RowNum: "1"
      , ColNum: "1"
      , RowSpan: "1"
      , ColSpan: "1"
      , CellStyle: "width: 35%;vertical-align: top;"
      , LableStyle: "font:bold 11px Roboto, serif;    padding: 4px 4px;      font-size: 13px;      padding-bottom: 1px;"
      , ControlStyle: "width:150px; height:13px;width:150px;vertical-align: middle;border: 1px solid #CCCCCC;padding: 1px 0;"
      , IsEditable: "1"
      , MaxLength: "1"
      , WaterMark: "Sample watermark"
      , CssClass: "validateinputcontrols"
      , IsMandatory: "1"
      , VersionNum: "1"
      , IsCollapsible: "1"
      , DivName: "dvCDFPlaceHolder"
      , ValidateAtSectionLevel: "1"
      , SectionOrder: "10"
      , IsQuickAccession: "1"
      , IsHTMLControl: "1"
      , DataSourceType: "Medication"
      , AdditionalData: '{"spanDisplayName":"Browse A-Z","DataSourceType":"Medication"}'
      , RefType: "AOE"
      , RefCode: "2"
      , BatchProfileID: "1"
      , OrderCodeID: "1"
      , Sequence: "1"
      , SpanishDisplayName: "Raza del paciente"
      ,ProcedureID:1246
      ,ProcedureName:"ProcedureName"
    }]
  }
  getUDFData() {
    let queryVariable = { "orgid": this.templateData.secondarykeys.OrganizationId.toString(), "casetype": this.templateData.secondarykeys.casetype.toString() };
    let query = this.SubMenuCardModel.GetQuery("getudfdetails");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).toPromise().then(data => {
      if (!data.errors) {
        if (data) {
          data.data.submenuData && data.data.submenuData.length > 0 ? this.ShowUDFData(data.data.submenuData) : this.noDataFound = true;
        }
      }
    }, error => {

      this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.ShowUDFData(this.templateData.submenuData) : this.noDataFound = true;
      console.error(error);
    });
    // this.GetButtonAccess(this.vitalHttpServices.CasemenuAction);
  }


  fetchUDFList(context ?: string) {
    let queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), orgid: this.templateData.secondarykeys.OrganizationId.toString(), filter: "accountid = null or accountid =\"" + this.templateData.cardIdentifier +"\"" };
    let query = this.dbName.toLowerCase() == 'thx' || this.dbName.toLowerCase() == 'thxvar' ? this.SubMenuCardModel.GetQuery("getudfdetailsforP4") : this.SubMenuCardModel.GetQuery("getudfdetails");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        this.templateData.submenuData = data.data.submenuData;
        context == 'list' ? this.ShowUDFData(this.templateData.submenuData) : null;
      }
    }, error => {
      console.error(error);
    });
    this.ngxService.stop();
  }


  //#region insert/update individual data
  saveUDF(check, action, Udfdata) {
    // if (this.action == 'Edit UDF') {
    //   Udfdata.frmAttributeName = this.editData.Attribute_Name;
    // }
    if (Udfdata && !Udfdata.frmAttributeName || !Udfdata.frmDisplayName || !Udfdata.frmDisplayType || !Udfdata.frmDisplayName || !Udfdata.frmStatus) {
      this._snackbar.open("Please enter the required values", "Close")

      if (!Udfdata.frmAttributeName) {
        this.UDFFormGroup.controls["frmAttributeName"].setErrors({ 'incorrect': true })
      }
      if (!Udfdata.frmDisplayName) {
        this.UDFFormGroup.controls["frmDisplayName"].setErrors({ 'incorrect': true })
      }
      if (!Udfdata.frmDisplayType) {
        this.UDFFormGroup.controls["frmDisplayType"].setErrors({ 'incorrect': true })
      }
      if (!Udfdata.frmStatus) {
        this.UDFFormGroup.controls["frmStatus"].setErrors({ 'incorrect': true })
      }
      return
    }

    if (Udfdata.frmRefType == 'domain' && !Udfdata.frmRefCode) {
      this._snackbar.open("Please enter the required values", "Close")
      return;
    }
    // if (!Udfdata.frmAttributeType && (!Udfdata.frmParentAttribute || Udfdata.frmParentAttribute == '')) {
    //   this._snackbar.open("Please enter the required values", "Close")
    //   return;
    // }
    if ((Udfdata.frmDisplayType == "checkbox" || Udfdata.frmDisplayType == "button" || Udfdata.frmDisplayType == "radiobutton" || Udfdata.frmDisplayType == "dropdown" || Udfdata.frmDisplayType == "combobox") && !Udfdata.frmDisplayValue) {
      this._snackbar.open("Displayvalues should not be Null or Empty for these Displaytype(s) 'checkbox, button, radiobutton, dropdown, combobox'", "Close")
      return;
    }
    let regex = /^[a-zA-Z0-9]*$/
    if (!regex.test(Udfdata.frmAttributeName)) {
      this._snackbar.open("Please enter valid Attribute Name", "Close")
      this.UDFFormGroup.controls["frmAttributeName"].setErrors({ 'incorrect': true })
      return;
    }
    if (Udfdata.frmDisplayValue) {
      let data = Udfdata.frmDisplayType.toString().toLowerCase();
      if (data == "checkbox" || data == "dropdown" || data == "radiobutton") {
        let tempArr = Udfdata.frmDisplayValue.toString().split(',');
        for (let i = 0; i < tempArr.length; i++) {
          tempArr[i] = tempArr[i].trim() + '-' + tempArr[i].trim()
        }
        Udfdata.frmDisplayValue = tempArr.toString();
      }
    }
    if (!Udfdata.procedureLevel) {
      Udfdata.frmProcedureName = null;
    }
    let tempParent = Udfdata.frmParentAttribute ? Udfdata.frmParentAttribute.split('-') : null
    let tempProcedure = Udfdata.frmProcedureName ? Udfdata.frmProcedureName.split('-') : null
    if (Udfdata.frmParentAttribute && this.UDFParents && !this.UDFParents.find(va => va.Id == tempParent[1])) {
      this._snackbar.open("Please select valid Header", "Close")
      return;
    }
    if (!Udfdata.frmParentAttribute && this.templateData.submenuData && this.templateData.submenuData.find(val => val.Attribute_Name == Udfdata.frmAttributeName && val.Attribute_Id != this.editAttributeId)) {
      this._snackbar.open("Parent is already exist with this name", "Close")
      return;
    }
    let ObjectArray = [{
      "attributename": Udfdata.frmAttributeName,
      "organizationid": this.templateData.secondarykeys.OrganizationId.toString(),
      "organizationtype": "",
      "speciality": "",
      "casemetatype": "",
      "casetype": this.templateData.secondarykeys.casetype.toString(),
      "status": this.selectedStatus.toString(),
      "displayname": Udfdata.frmDisplayName,
      "displaytype": Udfdata.frmDisplayType,
      "displayvalues": Udfdata.frmDisplayValue,
      "defaultvalue": Udfdata.frmDefaultValue,
      "isactive": Udfdata.frmActive==true ? 1 : 0,
      "createdby": sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid"),
      "modifiedby": sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid"),
      "parentid": tempParent ? tempParent[1] : null,
      "procedurename": tempProcedure ? tempProcedure[0] : null,
      "procedureid": tempProcedure ? tempProcedure[tempProcedure.length - 1] : null,
      "action": Udfdata.frmAction,
      "rownum": Udfdata.frmRowNum,
      "colnum": Udfdata.frmColNum,
      "rowspan": Udfdata.frmRowSpan,
      "colspan": Udfdata.frmColSpan,
      "cellstyle": Udfdata.frmCellStyle,
      "lablestyle": Udfdata.frmLabelStyle,
      "controlstyle": Udfdata.frmControlStyle,
      "iseditable": Udfdata.frmEditable,
      "maxlength": Udfdata.frmMaxLength,
      "watermark": Udfdata.frmWaterMark,
      "cssclass": Udfdata.frmCssClass,
      "ismandatory": Udfdata.frmMandatory,
      "versionnum": Udfdata.frmVersionNum,
      "iscollapsible": Udfdata.frmCollapsible,
      "divname": Udfdata.frmDivName,
      "validateatsectionlevel": Udfdata.frmValidateSection,
      "sectionorder": Udfdata.frmSectionOrder,
      "isquickaccession": Udfdata.frmQuickAccession,
      "ishtmlcontrol": Udfdata.frmHTMLControl,
      "datasourcetype": Udfdata.frmDataSourceType,
      "additionaldata": Udfdata.frmAdditionalData,
      "reftype": Udfdata.frmRefType,
      "refcode": Udfdata.frmRefCode,
      "isfishframeworkcontrol": true,
      "batchprofileid": Udfdata.frmBatchProfileID,
      "ordercodeid": Udfdata.frmOrderCodeID,
      "sequence": Udfdata.frmSequence,
      "spanishdisplayname": Udfdata.frmSpanishName,
      "tablename": "organizationstatusdatadefinition",
      "slno": 1,
      "attributeid": check == 'update' ? this.editAttributeId : null,
      "accountid": this.accountid
    }]
    if (check == 'update') {
      this.updateUDF(ObjectArray, false, '')
    }
    else {
      if (action === 'new' || !this.editEnabled) {
        //this.validateExcelData(ObjectArray);
        this.CreateUDF(ObjectArray, false, action)
      }
      else {
        //this.updateQA(ObjectArray);
        this.CreateUDF(ObjectArray, false, action)
      }
    }
  }
  //#endregion



  loadUploadScreen() {
    this.uploadClicked = true;
    this.addingSampleData();
    this.gridPage = false;
    // this.getTemplates()
    this.gridWidth = 0
    let queryVariable = { tablename: 'OrganizationStatusDataDefinition', identity: "false", nullable: "false" };
    let query = this.SubMenuCardModel.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data) {
          let UDFFormatFields = [];
          this.bulkUpdateFields[0]['AttributeId'] = ''
          for (let item of UDFFormatFields) {
            this.allFields[0][item] = ''
            this.mandatoryFields[0][item] = ''
          }
          for (let item of data.data.allFields) {
            this.allFields[0][item.Column] = '';
            this.bulkUpdateFields[0][item.Column] = '';
          }
          this.allFields[0]["ParentName"] = '';
          this.allFields[0]["ProcedureName"] = '';
          this.allFields[0]['ProcedureId'];
          // this.bulkUpdateFields[0]["ParentName"] = '';
          this.bulkUpdateFields[0]["ProcedureName"] = '';
          this.bulkUpdateFields[0]["AttributeName"] = ''
          this.mandatoryFields[0]["AttributeName"] = ''
          this.mandatoryFields[0]["Status"] = ''
          this.mandatoryFields[0]["DisplayName"] = ''
          this.mandatoryFields[0]["DisplayType"] = ''
          this.mandatoryFields[0]["DisplayValues"] = ''
          this.mandatoryFields[0]["ParentName"] = ''
          this.mandatoryFields[0]["IsActive"] = ''
          this.mandatoryFields[0]["RowNum"] = ''
          this.mandatoryFields[0]["ColNum"] = ''
          this.mandatoryFields[0]["CellStyle"] = ''
          this.mandatoryFields[0]["LableStyle"] = ''
          this.mandatoryFields[0]["ControlStyle"] = ''
          this.mandatoryFields[0]['ProcedureId']='';
          this.mandatoryFields[0]["ProcedureName"] = '';

          //this.mandatoryFields[0]["IsFishFrameworkControl"] = ''
          //Delete Keys
          delete this.allFields[0]['OrganizationId']
          delete this.allFields[0]['Casetype']
          delete this.allFields[0]['LayoutFile']
          delete this.allFields[0]['Context']
          delete this.allFields[0]['CreatedBy']
          delete this.allFields[0]['CreatedDate']
          delete this.allFields[0]['ModifiedDate']
          delete this.allFields[0]['ModifiedBy']
          delete this.allFields[0]['ControlGUID']
          delete this.allFields[0]['ParentId'];
          delete this.allFields[0]['OrganizationType'];
          delete this.allFields[0]['Speciality'];
          delete this.allFields[0]['CaseMetaType'];
          // delete this.allFields[0]['ProcedureId'];
          delete this.allFields[0]["SourceGUID"]
          delete this.allFields[0]['IsFishFrameworkControl']
          // delete keys for update

          delete this.bulkUpdateFields[0]['OrganizationId']
          delete this.bulkUpdateFields[0]['Casetype']
          delete this.bulkUpdateFields[0]['LayoutFile']
          delete this.bulkUpdateFields[0]['Context']
          delete this.bulkUpdateFields[0]['CreatedBy']
          delete this.bulkUpdateFields[0]['CreatedDate']
          delete this.bulkUpdateFields[0]['ModifiedDate']
          delete this.bulkUpdateFields[0]['ModifiedBy']
          delete this.bulkUpdateFields[0]['ControlGUID']
          delete this.bulkUpdateFields[0]["SourceGUID"]
          //delete this.bulkUpdateFields[0]['ParentId'];
          delete this.bulkUpdateFields[0]['OrganizationType'];
          delete this.bulkUpdateFields[0]['Speciality'];
          delete this.bulkUpdateFields[0]['CaseMetaType'];
          delete this.bulkUpdateFields[0]['IsFishFrameworkControl'];
          // delete this.bulkUpdateFields[0]['ProcedureId'];
        }
      }
    }, error => {
      console.error(error);
    });

  }


  //#region Refreshing the main grid
  refreshGrid(context? : string) {
    this.removeGrid();
    this.UDFFormGroup.reset();
    this.addEditClicked = false;
    this.copyScreenPage = false
    this.noDataFound = false;
    this.excelDataArray = [];
    this.showInvalidColumns = false;
    this.postUpload = false;
    this.copyFromOrgFrm.controls.frmOrganization.enable()
    context !='new'?this.fetchUDFList('list'):this.fetchUDFList()
  }
  //#endregion

  //#region Tooltip for the wijmo grid
  initGrid(grid, hitCount?) {
    if (hitCount) {
      if (!this.postUpload) {
        this.selector = new Selector(grid, {
          itemChecked: () => {
            this.selectedData = grid.rows.filter(r => r.isSelected);
          }
        });
      }
    }
    if (this.gridPage) {
      var amtFilter = this.filter.getColumnFilter('Active').conditionFilter;
      amtFilter.condition1.value = true; // value to check is 5000

      // values shown will be smaller than 5000 because we have added our own logic.
      amtFilter.condition1.operator = wjcGridFilter.Operator.EQ; // operator is Greater than
      this.filter.apply(); // apply the filter.
    }

    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }
  //#endregion

  //#region Create a wijmo grid and add values
  ShowUDFData(templateData: any[]): void {
    this.gridPage = true
    this.uploadClicked = false;
    this.addEditScreen = false;
    this.gridheader = ['Attribute_Id', 'Attribute_Name', 'Active', 'Parent_Name', 'Organization_Type', 'Speciality', 'Case_Meta_Type', 'Status', 'Display_Name', 'Display_Type', 'Display_Values', 'Default_Value', 'Procedure_Name','procedureid', 'Action', 'RowNum', 'ColNum', 'RowSpan', 'ColSpan', 'Cell_Style', 'Lable_Style', 'Control_Style', 'Editable', 'Max_Length', 'WaterMark', 'Css_Class', 'Mandatory', 'Version', 'Collapsible', 'Div_Name', 'Validate_At_Section_Level', 'Section_Order', 'Quick_Accession', 'HTML_Control', 'Data_Source_Type', 'Additional_Data', 'Ref_Type', 'Ref_Code', 'Fish_Framework_Control'
      , 'Batch_Profile_ID', 'Order_Code_ID', 'Sequence', 'Spanish_Display_Name', 'CreatedBy', 'CreatedDate', 'ModifiedBy', 'ModifiedDate']
    let gridarray = []
    let primary = {}
    this.ngxService.start();
    templateData = templateData ? templateData : [];
    for (let i = 0; i < templateData.length; i++) {
      primary = {}
      for (let [key, value] of Object.entries(templateData[i])) {
        for (let j = 0; j < this.gridheader.length; j++) {
          if (key === this.gridheader[j]) {
            if (value == null || value == '') {
              value = 'Not Specified';
            }
            if ((key == 'Parent_Name') && (value == 'Not Specified')) {
              value = "Header (Parent)"
            }

            if ((key == 'Active') && (value != true)) {
              value = false
            }
            primary[key] = value
          }
        }
      }
      gridarray.push(primary)

    }
    //gridarray.sort((a, b) => a.AttributeId < b.AttributeId ? -1 : 1).sort((a, b) => a.AttributeName < b.AttributeName ? -1 : 1)
    gridarray.sort((c,d)=>c.Parent_Name== 'Control (Not Parent)' ? -1 : 1 ).sort((a,b)=>a.Parent_Name == 'Header (Parent)' ? -1 : 1)
    this.noDataFound = gridarray.length > 0 ? false : true;
    this.UDFGridData = new CollectionView(gridarray,
      { groupDescriptions: ['Parent_Name'], pageSize: 10 })
    this.showPaginationMainGrid = gridarray.length > 10 ? true : false;
    this.ngxService.stop();
  }
  //#endregion


  //#region Method to handle file drop or selection
  onFileChange(ev) {
    this.isBulkUpdate=false;
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    this.filename = file.name;
    let filenamesplitarry = this.filename.split('.');
    if (filenamesplitarry[1].toUpperCase() != 'XLSX' && filenamesplitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          //this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() == 'udf');
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'organizationstatusdatadefinition')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0], null) : this.openModal();
          }
          else {
            this.convertToJson(this.sheetsToSelect[0], filenamesplitarry[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    if(this.filename.includes('Update')) {
      this.isBulkUpdate=true;
    }
    this.fileDropRef.nativeElement.value = "";
  }
  //#endregion

  //#region convert excel data to json data
  convertToJson(sheetname, fileName, copyfrmorg: boolean = false, copydata: object[] = []) {
    var worksheet;
    !copyfrmorg ? this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    }) : null;
    this.excelDataArray = [];
    let tempExcelArr = [];
    let deletedColumns=[];
    tempExcelArr = !copyfrmorg ? XLSX.utils.sheet_to_json(worksheet, { defval: null }) : copydata;
    if (tempExcelArr.length == 0) {
      !copyfrmorg ? this._snackbar.open("The uploaded excel does not contain any data", "Close") : null;
      tempExcelArr = [];
      return;
    }

  // if(this.isBulkUpdate==true) {
  //   if(tempExcelArr[0].AttributeId=='' || !tempExcelArr[0].AttributeId) {
  //           this._snackbar.open("Please provide the valid data");
  //           return;
  //   }
  // }
    if (this.isBulkUpdate == true) {
      if(tempExcelArr[0].AttributeId=='' || !tempExcelArr[0].AttributeId) {
        this._snackbar.open("Please provide the valid data",'Failed');
        return;
      }
      else {
        //comparing headers with user uploaded excel and bulkupdate excel
      this.exactData = Object.keys(this.bulkUpdateFields[0]);
      this.data = Object.keys(tempExcelArr[0])
      for (let i = 0; i < this.exactData.length; i++) {
        let found = false;
        for (let j = 0; j < this.data.length; j++) {
          if (this.exactData[i] === this.data[j]) {
            found = true;
            break; // No need to continue searching if found
          }
        }
        if (!found) {
          deletedColumns.push(this.exactData[i]);
          // If not found in second array, it's a deleted column
        }

      }


      for (let i = 0; i < deletedColumns.length; i++) {
        for (let j = 0; j < tempExcelArr.length; j++) {
          tempExcelArr[j][deletedColumns[i]] = ''
          //deleted columns from excel passing as empty string
        }
      }
      
    }
    if(tempExcelArr[0].IsActive=='' && tempExcelArr[0].IsActive !=false && tempExcelArr[0].IsActive !=0 ) {
      this._snackbar.open("Please provide the valid data",'Failed'); 
      return;
    }
  }
    let primary = {}
    let tempArray = []
    for (let i = 0; i < tempExcelArr.length; i++) {
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().match(/empty/i)) {
          if(this.isBulkUpdate==true) {
            if(value=='' && value != 0 && value != false && value !=true && value !=1){
              value=''
            }
          }
          if (key.toString().match(/isactive/i)) {
            value = value != null ? (value === '' ? '' : (value == '1' || value.toString().match(/true/i)) ? true : false) : true;

           // value = value != null ? (value == '1' || value.toString().match(/true/i)) ? true : false : true;
          }
          else {
            if (!value && this.isBulkUpdate==false) {
              value = ''
            }
            else if (value == 'null') {
              value = null;
            }
          }

          primary[key] = value;
        }
      }
      tempArray.push(primary)
      primary = {}
    }
    tempExcelArr = tempArray;
    this.sheetHeader = [];
    let temp = [];
    temp = !copyfrmorg ? XLSX.utils.sheet_to_json(worksheet, { header: 1 }) : Object.keys(tempExcelArr[0])
    if (!copyfrmorg) {
      // temp[0].reverse()

      temp[0].reverse();
      temp[0].push("Notes");
      this.sheetHeader = temp[0];
    }
    else {
      temp.reverse();
      temp.push("Notes")
      this.sheetHeader = temp;
    }

    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = []
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    let tempStatus = [];
    for (let i = 0; i < this.Status.length; i++) {
      tempStatus.push(this.Status[i].item)
    }

    // this.excelDataArray.sort((a, b) => a.procedurename < b.procedurename ? -1 : a.procedurename > b.procedurename ? 1 : 0)
    let i = 1;
    this.excelDataArray.find(d => {
      Object.assign(d, { slno: i, notes: '', tablename: 'organizationstatusdatadefinition', organizationid: this.templateData.secondarykeys.OrganizationId.toString(), casetype: this.templateData.cardtype.toString(), accountid: this.templateData.cardIdentifier, filename: fileName.toLowerCase(), casestatus: tempStatus.toString() });
      i++;
    });
    this.validateExcel(this.excelDataArray);
  }
  //#endregion


  //#region Sending the data for validation
  validateExcel(excelArr) {
    this.ngxService.start();
    this.dataArray = [];
    this.dataArray = this.convertObjKeysToLower(excelArr);
    this.vitalHttpServices.ValidateUDFData(this.dataArray, this.dbName).subscribe(result => {
      if (!result.errors) {
        if (result.length > 0) {
          this.ngxService.stop();
          if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
            this.showInvalidColumns = true;
            this.invalidColumns = result[0].InvalidColumns;
          }
          this.showDelete = true
          this.dataArray.filter(e => {
            result.filter(r => {
              if (r.SlNo === e.slno) {
                e.notes = r.NoteMessage;
              }
            });
          });
          this.AddGridData(this.dataArray, 1);
        }
        // else if(this.isBulkUpdate == true) {
        //   this.showDelete = true
        //   this.dataArray.filter(e => {
        //     result.filter(r => {
        //       if (r.SlNo === e.slno) {
        //         e.notes = r.NoteMessage;
        //       }
        //     });
        //   });
        //   this.AddGridData(this.dataArray, 1);
        // }
        else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
      }
    }, error => {
      this._snackbar.open("An error occurred while processing your request", 'Failed');
      console.error(error);
    });
    this.ngxService.stop();
  }
  //#endregion


  //#region Function to add data to temporary grid
  AddGridData(data, hitCount) {
    this.ngxService.stop();
    this.gridDisplay = true;
    let gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if(key== 'isactive') {
              if(value === 'false' ||value === '0' || value === 0 || value === false) {
                value=false
              }
              else if(value === 'true' || value=== '1' || value ===1 || value === true) {
                value=true
              }
            }
            primary[key] = value;

          }
          gridArray.push(primary);
        }
        if (this.showDelete) {
          // this.sheetHeader.splice(this.sheetHeader.findIndex((va) => va == 'Status'), 1)
        }
        else {
          this.sheetHeader.push('result');
        }
        if (this.postUpload) {
          this.showDelete = false
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        this.excelDataArray = gridArray;
        this.showDelete = hitCount == 1 ? true : false
         gridArray.sort((c,d)=>c.parentname== 'Control (Not Parent)' ? -1 : 1 ).sort((a,b)=>a.parentname == 'Header (Parent)' ? -1 : 1)

        if (this.copyScreenPage) {
          this.gridData = new CollectionView(gridArray, { groupDescriptions: ['parentname'] })
        }
        else {
          this.gridData = new CollectionView(gridArray)
        }
        this.gridWidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      }
    }
  }
  //#endregion

  //#region Convert data keys to lowercase
  convertObjKeysToLower(inputArr) {

    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }
  //#endregion

  //#region  Function to delete row from temporary grid
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
    this._snackbar.open('Deleted successfully', 'Close');
  }
  //#endregion

  //#region  Function to cancel the data selection
  removeGrid() {
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.postUpload = false;
    this.showDelete = false;
    this.noDataFound = false;
    this.noDataFoundCopy = false;
    this.copyFromOrgFrm.controls.frmOrganization.enable()
    this.typeData = []
    this.copyFromOrgFrm.patchValue({
      frmOrganization: ''
    })
  }
  //#endregion

  //#region Function to upload UDF Data
  uploadUDFData(copyData?: string) {
    let deletedColumns=[];

    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      let tempDataArray = this.excelDataArray;
      if (!copyData) {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i]["notes"] == "") {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
          else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && !this.excelDataArray[i]["notes"].toString().match(/already exist/i) && !this.excelDataArray[i]["notes"].toString().match(/exist/i) && !this.excelDataArray[i]["notes"].toString().match(/Valid/i)) {
            errorcount++
          }
          else if(this.excelDataArray[i]["notes"].toString().match(/Provide Procedure Id/i) || this.excelDataArray[i]["notes"].toString().match(/Procedure name does not match with the Procedure Id/i)||this.excelDataArray[i]["notes"].toString().match(/Provide Procedure Name/i)
            ||this.excelDataArray[i]["notes"].toString().match(/Attribute id does not exists/i))
            {
              errorcount++
            }
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        return
      }
      if ((this.showInvalidColumns)) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
      //     for (let [key, value] of Object.entries(this.excelDataArray[i])) {
      //       if(this.isBulkUpdate==false) {
      //       if (value == "null" || value == "" || value == null) {
      //         this.excelDataArray[i][key] = null;
      //       }
          
      //       else {
      //         this.excelDataArray[i][key] = value.toString();
      //       }
      //   }
      // }
          for (let i = 0; i < this.excelDataArray.length; i++) {
            this.excelDataArray[i]["createdby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
            if ( (this.excelDataArray[i]["isactive"].toString().toLocaleLowerCase()==='true' || this.excelDataArray[i]["isactive"].toString().toLocaleLowerCase()==='false' || this.excelDataArray[i]["isactive"] === 0 || 
            this.excelDataArray[i]["isactive"]===1 || this.excelDataArray[i]["isactive"] !== "")) {
              this.excelDataArray[i]["isactive"] = this.excelDataArray[i]["isactive"] == null 
              ? '1' 
              : (this.excelDataArray[i]["isactive"] == 0 || 
                 this.excelDataArray[i]["isactive"].toString().toLowerCase() == 'false' || 
                 this.excelDataArray[i]["isactive"] === false) 
                ? '0' 
                : '1';
          
            }
            else{
              this.excelDataArray[i]["isactive"]=''
            }
          }
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if(this.isBulkUpdate==false) {
            if ((value == "null" || value == "" || value == null) && !this.excelDataArray[i]["isactive"] == false) {
              this.excelDataArray[i][key] = null;
            }

            else {
              this.excelDataArray[i][key] = value.toString();
            }
        }
      }
        }
      }
      let selectedData: any = []
      if (copyData) {
        for (let i = 0; i < this.selectedData.length; i++) {
          if (this.selectedData[i]._data.parentid == null) {
            let cData = this.selectedData.filter(va => va.dataItem.parentid == this.selectedData[i]._data.attributeid)
            for (let m = 0; m < cData.length; m++) {
              let index = this.selectedData.findIndex((va => va.dataItem.parentid && va.dataItem.parentid == cData[i].dataItem.parentid))
              this.selectedData.splice(index, 1)
            }
            // this.sheetHeader.splice(this.sheetHeader.findIndex((va) => va == 'Status'), 1)
          }
          if (this.selectedData[i]._data.parentid) { // checking parent.
            let data = this.copyData.find(va => va.AttributeId == this.selectedData[i]._data.parentid) // parent data
            this.templateData.submenuData = !this.templateData.submenuData ? [] : this.templateData.submenuData
            let data2 = this.templateData.submenuData.filter(va => va.Attribute_Name == data.AttributeName)
            if ((this.templateData.submenuData.length == 0) || (data2.length == 0)) // parent not exists it will take parent
            {
              selectedData.push(this.excelDataArray.find(va => va.attributeid == this.selectedData[i]._data.parentid))
              selectedData = selectedData.filter((thing, index) => {
                const _thing = JSON.stringify(thing);
                return index === selectedData.findIndex(obj => {
                  return JSON.stringify(obj) === _thing;
                });
              });
            }
            else // parent exists it will add the parent id
            {
              //selectedData.push(data);// if parent is not present it will add the parent
              this.selectedData[i]._data.parentid = data.AttributeId;// Putting Parent attributeid as parentid in child
            }
          }
          else { // If adding parent child also added
            for (let j = 0; j < this.copyData.length; j++) {
              if (this.copyData[j].parentid == this.selectedData[i]._data.attributeid) {
                this.copyData[j].parentname = this.selectedData[i]._data.attributename;
                selectedData.push(this.copyData[j])
              }
            }
          }
        }
        selectedData.forEach(va=>{
          va.isGroup = 0;
        })
        for (let i = 0; i < this.selectedData.length; i++) {
          selectedData.push(this.selectedData[i]._data);
        }
        for (let i = 0; i < selectedData.length; i++) {
          if (!(selectedData[i].parentid)) { // checking the parent
            selectedData[i].isGroup = 1;
          }
        }
        selectedData.sort((a,b)=> b.isGroup - a.isGroup );
        this.selectedCopyData = this.convertObjKeysToLower(selectedData);
        // this.selectedCopyData = this.selectedCopyData.filter((item, i, arr) => arr.findIndex((t) => t.attributeid == item.attributeid) == i);
        for (let i = 0; i < this.selectedCopyData.length; i++) {
          this.selectedCopyData[i]['slno'] = i + 1;
          this.selectedCopyData[i]['parentid'] = null
          this.selectedCopyData[i]["copyflag"] = true;
          this.selectedCopyData[i]['attributeid'] = null
          this.selectedCopyData[i]['organizationid'] = this.orgid;
          if (this.copyFromOrgFrm.controls.Copyisactive.value == true) {
            this.selectedCopyData[i]["isactive"] = false;
          }
          if (!this.selectedCopyData[i]['notes']) {
            this.selectedCopyData[i]['notes'] = 'valid'
          }
        }
        this.excelDataArray = this.selectedCopyData
      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      //let sendjson = dataArray.filter(va => !va.notes?.toString().toLowerCase().match(/already exists/i))
      let sendjson = dataArray.filter(va => va.notes?.toString().toLowerCase().match(/added on approval/) || va.notes?.toString().toLowerCase().match(/valid/))
      if (!copyData) {
        this.excelDataArray = tempDataArray;
      }
      if (copyData && sendjson.length <= 0) {
        this._snackbar.open('Please select valid rows', 'Close')
        return;
      }
      if (sendjson[0].attributeid == null || sendjson[0].attributeid == '' ) {
        sendjson.length > 0 ? this.CreateUDF(sendjson, true, '') : this._snackbar.open("UDF Data already exist!", "Close");
      }
      else {
        sendjson.length > 0 ? this.updateUDF(sendjson, true, '') : this._snackbar.open("UDF Data already exist!", "Close");
      }
    }
  }
  //#endregion

  updateUDF(dataArray, bulkUpload: boolean = false, context?: string) {
    this.ngxService.start();
    this.vitalHttpServices.UpdateUDF(dataArray, this.dbName).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.length > 0) {
          this.postUpload = true;
          this.showDelete = false;
          this.postDownload = true;
          let uploadresult = this.convertObjKeysToLower(result);
          for (let i = 0; i < this.excelDataArray.length; i++) {
            for (let j = 0; j < uploadresult.length; j++) {
              if (this.excelDataArray[i]['slno'] == result[j]['SlNo']) {
                this.excelDataArray[i]['notes'] = result[j]['Notes']
                this.excelDataArray[i]['result'] = result[j]['Status']
                this.commonService.createActivityTracker('Edited', this.excelDataArray[i]['attributeid'], 'UDFS', 'Audit', this.excelDataArray[i], {});

                //  this.createActivityTracker(result[j]['id']);
              }
              if (this.excelDataArray[i]['notes'].toString().match(/already exists/i)) {
                this.excelDataArray[i]['result'] = 'Ignored'
              }
            }
          }
          if (this.excelDataArray.length == 0) {
            this.commonService.createActivityTracker('Edited', dataArray[0].attributeid, 'UDFS', 'Audit', dataArray[0], this.oldObject);
          }
          bulkUpload ? this.AddGridData(this.excelDataArray, 2) : null;
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.Status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          if (dataExistsCount == result.length) {
            if (bulkUpload) { this._snackbar.open('UDF configuration already exist!', 'Close') }
            else { this._snackbar.open('UDF configuration already exists!', 'Close'); return; }
          }
          else {
            bulkUpload ? this._snackbar.open('UDF Data  Uploaded Successfully', 'Close') : this._snackbar.open('UDF configuration updated successfully', 'Close');
          }
          if (!bulkUpload) {
            if (context != null) {
              this.typeData = [];
              Object.keys(this.UDFFormGroup.controls).forEach(key => {
                this.UDFFormGroup.controls[key].setErrors(null)
              });
              this.refreshGrid();
            }
            else {
              this.getInputData('Create', this.templateData.submenuData);
            }
          }
        }
        else {
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open('UDF addition failed! Please try again.', 'Close');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong. Please try again', 'Close');
      console.error(error)
    })
  }
  // createActivityTracker(id){
  //  console.log(this.templateData.submenuData.find(va=> va.Attribute_Id=id))
  // }
  //#region
  CreateUDF(dataArray, bulkUpload: boolean = false, context?: string) {
    this.ngxService.start();
    this.vitalHttpServices.bulkUploadUDF(dataArray, this.dbName).subscribe(result => {
      this.ngxService.stop();
      if (!result.errors) {
        if (result.length > 0) {
          this.frozenclms = 2;
          this.postUpload = true;
          this.showDelete = false;
          this.postDownload = true;
          if(this.copyScreenPage && this.postUpload){
            this.copyFromOrgFrm.controls.frmOrganization.disable()
          }
          let uploadresult = this.convertObjKeysToLower(result);
          for (let i = 0; i < this.excelDataArray.length; i++) {
            for (let j = 0; j < uploadresult.length; j++) {
              if (this.excelDataArray[i]['slno'] == result[j]['SlNo']) {
                this.excelDataArray[i]['notes'] = result[j]['Notes']
                this.excelDataArray[i]['result'] = result[j]['Status']
                this.excelDataArray[i]['parentid'] = result[j]['parentid']
                this.excelDataArray[i]['attributename'] = result[j]['name']
                this.commonService.createActivityTracker('Created', result[j]['id'], 'UDFS', 'Audit', this.excelDataArray[i], {});
              }
              if (this.excelDataArray[i]['notes'].toString().match(/already exists/i) || this.excelDataArray[i]['notes'].toString().match(/null or empty/i)) {
                this.excelDataArray[i]['result'] = 'Ignored'
              }
            }
          }
          if (this.excelDataArray.length == 0) {
            this.commonService.createActivityTracker('Created', dataArray[0].attributeid, 'UDFS', 'Audit', dataArray[0], {});
          }
          bulkUpload ? this.AddGridData(this.excelDataArray, 2) : null;
          let dataExistsCount = 0;
          this.getStatusDropdown('')
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.Status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          if (dataExistsCount == result.length) {
            if (bulkUpload) { this._snackbar.open('UDF configuration already exist!', 'Close') }
            else { this._snackbar.open('UDF configuration already exists!', 'Close'); return; }
          }
          else {
            bulkUpload ? this._snackbar.open('UDF Data  Uploaded Successfully', 'Close') : this._snackbar.open('Created successfully', 'Close');
          }
          if (!bulkUpload) {
            if (context == "") {
              this.typeData = [];
              this.UDFFormGroup.reset();
              Object.keys(this.UDFFormGroup.controls).forEach(key => {
                this.UDFFormGroup.controls[key].setErrors(null)
              });
              this.refreshGrid()
            }
            else {
              this.UDFFormGroup.reset();
              this.refreshGrid('new')
              this.getInputData('', this.templateData.submenuData);
            }
          }
        } else {
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open('UDF addition failed! Please try again.', 'Close');
        }
      }
    }, error => {
      this.ngxService.stop();
      this._snackbar.open('Something went wrong. Please try again', 'Close');
      console.error(error)
    })
  }
  //#endregion


  //#region
  //Export grid data as excel
  ExportExcel(flex) {
    let tempHeader = flex.columns[0]._hdr;
    if (!this.templateData.secondarykeys) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = 'User Defined fields_' + this.organizationId + '.xlsx';
    const view = flex.collectionView;
    let oldPgSize = view.pageSize,
      oldPgIndex = view.pageIndex;
    flex.beginUpdate();
    view.pageSize = 0;
    flex.columns.forEach(element => {
      if (element._hdr == 'Actions') {
        element._hdr = ''
      }
    });


    let excel = [];
    view.pageSize = 0;
    flex.beginUpdate();
    flex.rows.find(e => {
      delete e._data["_gd"]
      delete e._data["_name"]
      delete e._data["_level"]
      delete e._data["_isBottomLevel"]
      delete e._data["_groups"]
      delete e._data["_items"]
      delete e._data["_path"]
      if (e._data["Attribute_Id"]) {
        excel.push(e._data);
      }
    });
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'User Defiend fields');
    XLSX.writeFile(wb, filename);
    view.pageSize = oldPgSize;
    flex.endUpdate()
  }

  //#endregion

  ExportResult(data) {
    var sheet = XLSX.utils.book_new();
    let array = [];
    // for (let itr = 0; itr < data.length; itr++) {
    //   array.push(data[itr]);
    // }
    array = data;
    for (let itr = 0; itr < array.length; itr++) {
      delete array[itr].createdby;
      delete array[itr].cptcodes;
      delete array[itr].tablename;
      delete array[itr].accountid
      delete array[itr].copyflag
      delete array[itr].result;
      delete array[itr].filename;
      delete array[itr].casestatus;
      // delete array[itr].
    }
    let filename = 'User Defined fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
    if (filename.length > 31) {
      let fileName = filename.split('_')[0] + '_';
      if(fileName.length>31){
        filename = fileName.split('_')[0] +'.xlsx';
      }else{
         if(this.templateData.secondarykeys){
        filename = fileName  + this.templateData.secondarykeys.OrganizationId.toString()+'.xlsx';
      }
      else{
        filename = fileName.split('_')[0] +'.xlsx';
      }
    }
    }
    let sheetData = XLSX.utils.json_to_sheet(array);
    XLSX.utils.book_append_sheet(sheet, sheetData, 'User Defined fields');
    XLSX.writeFile(sheet, filename);
  }
  //#endregion

  downloadTemplate(allFieldsCheck) {
    let filename = ""
    // if(this.dbName.toString().toLowerCase()== 'thx'||this.dbName.toString().toLowerCase()== 'thxvar'){
    //   this.sampleData.splice(this.sampleData.indexOf('Sequence',1));
    // }
    var ws
    if (allFieldsCheck == 'all') {
      filename = 'User Defined Fields_' + 'All Fields_' + this.orgid + '.xlsx';
      ws = XLSX.utils.json_to_sheet(this.allFields)
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'UDF');
      ws = XLSX.utils.json_to_sheet(this.sampleData);
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
    }
    else if (allFieldsCheck == 'min') {
      filename = 'User Defined Fields_' + 'Minimal Fields_' + this.orgid + '.xlsx';
      ws = XLSX.utils.json_to_sheet(this.mandatoryFields)
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'UDF');
      ws = XLSX.utils.json_to_sheet(this.sampleMinData);
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
    }
    else if (allFieldsCheck == 'update') {
      filename = 'User Defined Fields_' + 'Update Fiellds_' + this.orgid + '.xlsx';
      ws = XLSX.utils.json_to_sheet(this.bulkUpdateFields)
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'UDF');
      ws = XLSX.utils.json_to_sheet(this.sampleUpdateData);
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')

      ws = XLSX.utils.json_to_sheet(this.bulkUpdateFields)
    }

    XLSX.writeFile(wb, filename);
  }


  getInputData(action, data) {
    this.gridPage = false;
    this.addEditClicked = true;
    this.allSelected = false;
    this.editAttributeId = null;
    if (action.toLowerCase() == 'edit' || action.toLowerCase() == 'copy') {
      this.action = action == "edit" ? 'Edit UDF' : "Create UDF"
      this.editEnabled = action.toLowerCase() == 'copy' ? false : true
      this.editAttributeId = data.dataItem.Attribute_Id;
      this.editData = this.templateData.submenuData.find(va => va.Attribute_Id == data.dataItem.Attribute_Id)
      let patDisplayType = this.tempUdfDisplayTypes.find(va => va.Id.toLowerCase() == data.dataItem.Display_Type.toLowerCase())
      let patProcedureName = this.UDFProcedures.find(va => va.Id == this.editData.procedureid)
      let patParent = this.UDFParents.find(va => va.Id == this.editData.Parentid)
      let patRefCode = this.UDFRefCodes.find(va => va.Id == this.editData.Ref_Code)
      // let patchParent = this.UDFParents.length == 1 ? this.UDFParents[0].Value + '-' + this.UDFParents[0].Id : null
      this.selectedStatus = this.editData.Status?.split(',');
      if (this.editData.Display_Values) {
        let data = this.editData.Display_Type.toString().toLowerCase();
        if (data == "checkbox" || data == "dropdown" || data == "radiobutton") {
          let tempArr = this.editData.Display_Values.toString().split(',');
          let tempver = ''
          for (let j = 0; j < tempArr.length; j++) {
            let tempDisplaydata = tempArr[j].toString().split('-');
            for (let i = 0; i < tempDisplaydata.length; i++) {
              tempver = tempver + tempDisplaydata[0] + ',';
              break;
              // tempArr[i] = tempArr[i].trim() + '-' + tempArr[i].trim()
            }
          }
          tempver = tempver.slice(0, -1);
          this.editData.Display_Values = tempver.toString();
        }
      }
      this.UDFFormGroup.patchValue({
        // frmAttributeType: patParent && patParent.Id ? false : true,
        frmAttributeType: patDisplayType.Value.toLowerCase() == 'heading' || patDisplayType.Value.toLowerCase() == 'heading-label' ? true : false,
        procedureLevel: patProcedureName && patProcedureName.Id ? true : false,
        frmAttributeName: this.editData.Attribute_Name,
        frmDisplayName: this.editData.Display_Name,
        frmActive: this.editData.Active,
        frmDisplayValue: this.editData.Display_Values,
        frmAction: this.editData.Action,
        frmDefaultValue: this.editData.Default_Value,
        frmWaterMark: this.editData.WaterMark,
        frmParentAttribute: patParent ? patParent.Value + '-' + patParent.Id : null,
        frmProcedureName: patProcedureName ? patProcedureName.Value + '-' + patProcedureName.Id : null,
        frmRowNum: this.editData.RowNum,
        frmColNum: this.editData.ColNum,
        frmRowSpan: this.editData.RowSpan,
        frmColSpan: this.editData.ColSpan,
        frmMaxLength: this.editData.MaxLength,
        frmSectionOrder: this.editData.Section_Order,
        frmCellStyle: this.editData.Cell_Style,
        frmLabelStyle: this.editData.Lable_Style,
        frmControlStyle: this.editData.Control_Style,
        frmCssClass: this.editData.Css_Class,
        frmVersionNum: this.editData.Version,
        frmDivName: this.editData.Div_Name,
        frmDataSourceType: this.editData.Data_Source_Type,
        frmRefType: this.editData.Ref_Type == 'domain' ? 'domain' : null,
        frmRefCode: patRefCode ? patRefCode.Id : null,
        frmBatchProfileID: this.editData.Batch_Profile_ID,
        frmOrderCodeID: this.editData.Order_Code_ID,
        frmSequence: this.editData.Sequence,
        frmSpanishName: this.editData.Spanish_Display_Name,
        frmEditable: this.editData.Editable,
        frmAdditionalData: this.editData.Additional_Data,
        frmMandatory: this.editData.Mandatory,
        frmValidateSection: this.editData.Validate_At_Section_Level,
        frmQuickAccession: this.editData.Quick_Accession,
        frmHTMLControl: this.editData.HTML_Control,
        frmCollapsible: this.editData.Collapsible,
        frmDisplayType: patDisplayType ? patDisplayType.Id : null,
        frmStatus: this.editData.Status?.split(',')
      });
      //this.selectedStatus = this.editData.Status.split(',');
      // if(action.toLowerCase() == 'edit'){
      //   this.UDFFormGroup.controls["frmAttributeName"].disable();
      // }
      this.allSelected = this.Status.length == this.editData.Status?.split(',').length ? true : false;
      this.oldObject = [{
        "attributename": this.editData.Attribute_Name,
        "organizationtype": "",
        "speciality": "",
        "casemetatype": "",
        "status": this.editData.Status,
        "displayname": this.editData.Display_Name,
        "displaytype": patDisplayType ? patDisplayType.Id : null,
        "displayvalues": this.editData.Display_Values,
        "defaultvalue": this.editData.Default_Value,
        "isactive": this.editData.Active,
        "parentname": patParent ? patParent.Value : null,
        "procedurename": patProcedureName ? patProcedureName.Value : null,
        "action": this.editData.Action,
        "rownum": this.editData.RowNum,
        "colnum": this.editData.ColNum,
        "rowspan": this.editData.RowSpan,
        "colspan": this.editData.ColSpan,
        "cellstyle": this.editData.Cell_Style,
        "lablestyle": this.editData.Lable_Style,
        "controlstyle": this.editData.Control_Style,
        "iseditable": this.editData.Editable,
        "maxlength": this.editData.MaxLength,
        "watermark": this.editData.WaterMark,
        "cssclass": this.editData.Css_Class,
        "ismandatory": this.editData.Mandatory,
        "versionnum": this.editData.Version,
        "iscollapsible": this.editData.Collapsible,
        "divname": this.editData.Div_Name,
        "validateatsectionlevel": this.editData.Validate_At_Section_Level,
        "sectionorder": this.editData.Section_Order,
        "isquickaccession": this.editData.Quick_Accession,
        "ishtmlcontrol": this.editData.HTML_Control,
        "datasourcetype": this.editData.Data_Source_Type,
        "additionaldata": this.editData.Additional_Data,
        "reftype": this.editData.RefType == 'domain' ? 'domain' : null,
        "refcode": patRefCode ? patRefCode.Id : null,
        "batchprofileid": this.editData.Batch_Profile_ID,
        "ordercodeid": this.editData.Order_Code_ID,
        "sequence": this.editData.Sequence,
        "spanishdisplayname": this.editData.Spanish_Display_Name,
        "tablename": "organizationstatusdatadefinition",
        "slno": 1,
        "attributeid": this.editAttributeId,
        "accountid": this.accountid

      }]
      action == "edit" ? this.onChangeDisable('copy') : this.onChangeDisable('copy');

    }
    // else if(action.lowercase()=='copy'){

    // }
    else {
      this.action = 'Create UDF'
      this.editEnabled = false;
      this.onDeSelectAll();
      this.UDFDisplayTypes = this.tempUdfDisplayTypes;
      this.UDFFormGroup.reset();
      this.UDFFormGroup.controls["frmAttributeName"].enable();
      //this.UDFFormGroup.reset();
      Object.keys(this.UDFFormGroup.controls).forEach(key => {
        this.UDFFormGroup.controls[key].setErrors(null)
      });
      this.UDFFormGroup.patchValue({
        frmActive: true,
        frmVersionNum: 1,
        frmAttributeType: true
      })
      this.onChangeDisable();
    }
  }

  getUDFParentAttribute() {
    let obj = {
      AccountID: sessionStorage.getItem("AccountID"),
      Casetype: this.templateData.secondarykeys.casetype,
      OrganizationId: this.templateData.secondarykeys.OrganizationId
    }
    return this.vitalHttpServices.GetUDFParents(obj, this.dbName).subscribe(data => {
      if (!data.errors) {
        if (data) {
          this.UDFParents = data.filter(va => va.Type == 'Parents');
          this.UDFProcedures = data.filter(va => va.Type == 'Procedures')
          this.filterProcedures = this.UDFFormGroup.controls.frmProcedureName.valueChanges
            .pipe(
              startWith(''),
              map((user:any) => user && typeof user === 'object' ? user.Value || user.Id : user),
              map((name: string) => name ? this.filerProcedures(name) : this.UDFProcedures.slice())
            );
          this.filterParent = this.UDFFormGroup.controls.frmParentAttribute.valueChanges
            .pipe(
              startWith(''),
              map((user:any) => user && typeof user === 'object' ? user.Value || user.Id : user),
              map((name: string) => name ? this.filteringStatus(name) : this.UDFParents.slice())
            );
          this.getUDFDisplayTypes()
        }
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  getUDFDisplayTypes() {
    return this.vitalHttpServices.GetUDFDisplayTypes(this.dbName).subscribe(data => {
      if (!data.errors) {
        if (data) {
          this.UDFDisplayTypes = data;
          this.tempUdfDisplayTypes = data;
          this.getUDFRefCodes()
        }
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  getUDFRefCodes() {
    return this.vitalHttpServices.GetUDFRefCodes(this.orgid, this.dbName).subscribe(data => {
      if (!data.errors) {
        if (data) {
          this.UDFRefCodes = data;
        }
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }


  validateForm(check: string, action: string, data: any) {
    const { frmAsequenece, frmGsequence } = data;
    check == 'Update' ? this.saveUDF('update', action, data) : this.saveUDF('', action, data)
  }


  AllowOnlyNumber(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  selectingStatus(event) {
    this.selectedStatus = event;
  }

  getStatusDropdown(item: any) {
    this.ngxService.start();
    let query = this.vitalHttpServices.GetQuery('CategoryDropDown');
    let queryVariable = { context: 'UDF' };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.vitalHttpServices.GetData(queryResult, 'configdb').subscribe((Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        let data = JSON.parse(Resdata.data.submenuData[0].Items_JSON).UDF
        this.Status = data.UDFStatus;
        this.udfCellStyles = data.UDFCellStyle;
        this.udfActions = data.Actions;
        this.udfLabelStyles = data.lablestyle;
        this.udfControlStyle = data.controlstyle;
        this.udfCssClass = data.cssclass;
        this.udfDataSourcedrpdwn = data.datasourcetype;
        this.addtionalDatadrpdn = data.additinaldata.other;
        this.tempAddtionalDatadrpdn=data.additinaldata.other
        this.filterStatus = this.UDFFormGroup.controls.frmStatus.valueChanges
          .pipe(
            startWith(''),
            map((user:any) => user && typeof user === 'object' ? user.Value || user.Id : user),
            map((name: string) => name ? this.filteringStatus(name) : this.Status.slice())
          );
        this.getUDFParentAttribute();
      }
      else {
        this.ngxService.stop();
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }


  toggleAllSelection() {
    if (this.allSelected) {
      this.matSelectOption.options.forEach((item: MatOption) => item.select());
    } else {
      this.matSelectOption.options.forEach((item: MatOption) => item.deselect());
    }
  }
  optionClick() {
    let newStatus = true;
    this.matSelectOption.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }
  // Close Modal


  // Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    if (result) {
      this.convertToJson(result, this.filename)
    }
  }
  disableApprovebtn() {
    let existsCount = 0;
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (this.excelDataArray[i]['notes'] && (this.excelDataArray[i]['notes'].toString().match(/Null or Empty/i))) {
        existsCount++;
      }
    }
    if (existsCount == this.excelDataArray.length) {
      return true
    }
    return false;
  }
  copyScreen() {
    this.gridPage = false;
    this.copyScreenPage = true;
    this.gridWidth = 0;
    this.destDeployment = sessionStorage.getItem('deploymentKey');
    this.DeploymentKeys = this.vitalHttpServices.DeploymentKeys;
    this.copyFromOrgFrm.patchValue({
      frmDeplyment: this.destDeployment,
      frmOrganization: '',
      Copyisactive: false
    })
  }
  onChangeDeployment(e, depKey) {
    if (e.source.selected) {
      this.copyFromOrgFrm.patchValue({
        frmDeplyment: depKey,
        frmOrganization: ''
      })
      this.gridWidth = 0
      this.searchInput = '';
      this.searchResult = [];
      this.postUpload = false;
      this.excelDataArray = []
      this.getListOrg();
      this.copyFromOrgFrm.controls.frmOrganization.enable()
    }
  }
  getListOrg() {
    let dbName = this.copyFromOrgFrm.value.frmDeplyment;
    let queryVariable = {};
    let query = this.vitalHttpServices.GetQuery("adduserfilterorg");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, dbName).subscribe(data => {
      if (!data.errors) {
        if (data) {
          if (data.data.Organizations_list.length > 0) {
            this.organizationList = data.data.Organizations_list
          }
        }
      }
    }, error => {
      console.error(error);
    });
    this.ngxService.stop();
  }
  fetchOrgSeries(value: string) {
    value = value?.toString().trim();
    this.searchResult = []
    if (!value.trim()) {
      let copyGroupList=this.organizationList.slice();
      return (this.searchResult = copyGroupList.splice(0, 25));
    }
    let idValidate = /^[0-9]*$/;
    if (this.copyFromOrgFrm.value.frmOrganization) {
      if (!idValidate.test(value)) {
        if (value.length > 2) {
          this.searchResult = this.organizationList.filter(function (series) {
            if (series && series.organizationname != null) {
              return series.organizationname.toString().toLowerCase().includes(value.toLowerCase());
            }
          });
          this.searchResult = this.searchResult.splice(0, 25);
        }
      }
      else {
        this.searchResult = this.organizationList.filter(function (series) {
          if (series && series.organizationid != null) {
            return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    }
  }
  getDataToCopy(orgid) {
    this.postUpload = false;
    let queryVariable = { orgid: orgid.toString(), casetype: this.templateData.secondarykeys.casetype.toString() };
    let query = this.copyFromOrgFrm.value.frmDeplyment.toLowerCase() == 'thx' || this.copyFromOrgFrm.value.frmDeplyment.toLowerCase() == 'thxvar' ? this.SubMenuCardModel.GetQuery("getCopyudfdetailsp4") : this.SubMenuCardModel.GetQuery("getCopyudfdetails");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, this.copyFromOrgFrm.value.frmDeplyment).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.copyData = data.data.submenuData;
        this.frozenclms = 1;
        if (this.copyData.length == 0) {
          this.gridWidth = 0;
          this.noDataFoundCopy = true;
          return
        }
        this.convertToJson('', '', true, this.copyData);
      }
    }, error => {

      this.ngxService.stop();
      console.error(error);
    });
  }
  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false)?.toString();
      wjCore.toggleClass(e.cell, 'error-msg', (value) && (value == 'Headers Parent not required' || value == 'Parent is already exist with this name' || value == 'Please check the Display type'  || value == 'Attribute id does not exists' || value == 'Parent Not exist' || value == 'Please check the Case Status' || value == 'Null values exist!' || value.match(/not belongs/i) || value.match(/Null or Empty/i) || value == 'failure' || value == 'Parent not found' || value == 'Procedures/CPT Codes are not exists'
       || value=='Provide Procedure Id' || value=='Provide Procedure Name' || value=='Procedure name does not match with the Procedure Id' ||  value == 'Record does not exists in OrganizationStatusDataDefinition' || value == 'Not Found'));
      wjCore.toggleClass(e.cell, 'high-value', (value) && (value == 'Valid' || value == 'Created Successfully' || value == 'Success' || value == 'Updated successfully' ));
      wjCore.toggleClass(e.cell, 'warn-value', (value) && (value == 'Ignored' || value.match(/Added on approval/i) || value == 'Parent Not exist, Added on approval' ));

      //  }
    }
  }
  //#region disabling the for headed
  onChangeDisable(copy?: string) {
    let tempStatus: string = this.UDFFormGroup.controls.frmStatus.value;
    let tempProcedureName = this.UDFFormGroup.controls.frmProcedureName.value;
    let tempprocedureLevel = this.UDFFormGroup.controls.procedureLevel.value;
    if (this.UDFFormGroup.controls.frmAttributeType.value == true) {
      this.UDFDisplayTypes = this.tempUdfDisplayTypes.filter(va => va.Value?.toString().toLowerCase().includes('heading'))
      // this.UDFFormGroup.reset();
      Object.keys(this.UDFFormGroup.controls).forEach(key => {
        this.UDFFormGroup.controls[key].setErrors(null)
      });
      //copy != 'copy' ? this.action == 'Create UDF' ? this.UDFFormGroup.reset() : null : null
      if (this.action == 'Create UDF') {
        this.UDFFormGroup.patchValue({
          frmAttributeType: true,
          frmDisplayType: "heading",
          frmControlStyle: 'width:150px; height:13px;width:150px;vertical-align: middle;border: 1px solid #CCCCCC;padding: 1px 0;',
          frmLabelStyle: 'font:bold 11px Roboto, serif;    padding: 4px 4px;      font-size: 13px;      padding-bottom: 1px;',
          frmCssClass: 'subTableHeader',
          frmActive: true,
          frmVersionNum: 1,
          frmStatus: tempStatus,
          frmProcedureName: tempProcedureName,
          procedureLevel: tempprocedureLevel
        })
      }
      this.UDFFormGroup.controls["frmDisplayValue"].disable();
      this.UDFFormGroup.controls["frmDefaultValue"].disable();
      this.UDFFormGroup.controls["frmAction"].disable()
      this.UDFFormGroup.controls["frmRowSpan"].disable()
      this.UDFFormGroup.controls["frmColSpan"].disable()
      this.UDFFormGroup.controls["frmMaxLength"].disable()
      this.UDFFormGroup.controls["frmWaterMark"].disable()
      this.UDFFormGroup.controls["frmSectionOrder"].disable()
      this.UDFFormGroup.controls["frmParentAttribute"].disable()
    }
    else if (this.UDFFormGroup.controls.frmAttributeType.value == false) {
      //copy != 'copy' ? this.action == 'Create UDF' ? this.UDFFormGroup.reset() : null : null
      let patchParent = this.UDFParents.length == 1 ? this.UDFParents[0].Value + '-' + this.UDFParents[0].Id : null
      this.UDFDisplayTypes = this.tempUdfDisplayTypes.filter(va => !va.Value?.toString().toLowerCase().includes('heading'))
      this.UDFFormGroup.controls["frmDisplayValue"].enable();
      this.UDFFormGroup.controls["frmDefaultValue"].enable();
      this.UDFFormGroup.controls["frmAction"].enable()
      this.UDFFormGroup.controls["frmRowSpan"].enable()
      this.UDFFormGroup.controls["frmColSpan"].enable()
      this.UDFFormGroup.controls["frmMaxLength"].enable()
      this.UDFFormGroup.controls["frmWaterMark"].enable()
      this.UDFFormGroup.controls["frmSectionOrder"].enable()
      this.UDFFormGroup.controls["frmParentAttribute"].enable()
      if (this.action == 'Create UDF') {
        this.UDFFormGroup.patchValue({
          frmAttributeType: false,
          frmParentAttribute: patchParent,
          frmActive: true,
          frmVersionNum: 1,
          frmProcedureName: tempProcedureName,
          procedureLevel: tempprocedureLevel
        })
        // tempStatus ? this.UDFFormGroup.patchValue({ frmStatus: tempStatus?.split(',') }) : null
      }

    }
  }
  getCaseTypeList(value) {
    // let casetypeList = [];
    if (value.value) {
      return this.cellStylesDropdown.filter(va => va.item.toString().toLowerCase().includes(value.value.toLowerCase()))
    }
  }
  selectionDisplayType(value) {
    this.ngxService.start();
    try {
      this.cellStylesDropdown = [];
      this.labelStyleDropdown = [];
      this.actiondropdown = [];
      this.controlStyleDp = [];
      this.cssClassDp = [];
      this.mandateDispVal = false;
      switch (value.toString().toLowerCase()) {
        case "checkbox":
          this.cellStylesDropdown = this.udfCellStyles.checkbox;
          this.labelStyleDropdown = this.udfLabelStyles.checkbox;
          this.actiondropdown = this.udfActions.checkbox;
          this.controlStyleDp = this.udfControlStyle.checkbox;
          this.cssClassDp = this.udfCssClass.checkbox;
          this.mandateDispVal = true;
          break;
        case "radiobutton":
          this.cellStylesDropdown = this.udfCellStyles.radiobutton;
          this.labelStyleDropdown = this.udfLabelStyles.radiobutton;
          this.controlStyleDp = this.udfControlStyle.radiobutton
          this.cssClassDp = this.udfCssClass.radiobutton;
          this.mandateDispVal = true;
          break;
        case "additionaltest":
          this.cellStylesDropdown = this.udfCellStyles.additionaltest;
          this.labelStyleDropdown = this.udfLabelStyles.additionaltest;
          this.controlStyleDp = this.udfControlStyle.additionaltest;
          this.cssClassDp = this.udfCssClass.additionaltest;
          break;
        case "dropdown":
          this.cellStylesDropdown = this.udfCellStyles.dropdown;
          this.labelStyleDropdown = this.udfLabelStyles.dropdown;
          this.controlStyleDp = this.udfControlStyle.dropdown;
          this.cssClassDp = this.udfCssClass.dropdown;
          this.mandateDispVal = true;
          break;
        case "ajaxtextbox":
          this.cellStylesDropdown = this.udfCellStyles.ajaxtextbox;
          this.labelStyleDropdown = this.udfLabelStyles.ajaxtextbox;
          this.controlStyleDp = this.udfControlStyle.ajaxtextbox;
          this.cssClassDp = this.udfCssClass.ajaxtextbox;
          break;
        case "template":
          this.cellStylesDropdown = this.udfCellStyles.template;
          this.labelStyleDropdown = this.udfLabelStyles.template;
          this.controlStyleDp = this.udfControlStyle.template;
          this.cssClassDp = this.udfCssClass.template;

          break;
        case "site":
          this.cellStylesDropdown = this.udfCellStyles.site;
          this.labelStyleDropdown = this.udfLabelStyles.site;
          this.controlStyleDp = this.udfControlStyle.site;
          this.cssClassDp = this.udfCssClass.site;

          break;
        case "button":
          this.cellStylesDropdown = this.udfCellStyles.button;
          this.actiondropdown = this.udfActions.button;
          this.labelStyleDropdown = this.udfLabelStyles.button;
          this.controlStyleDp = this.udfControlStyle.button;
          this.cssClassDp = this.udfCssClass.button;
          this.mandateDispVal = true;
          break;
        case "heading":
          this.cellStylesDropdown = this.udfCellStyles.heading;
          this.labelStyleDropdown = this.udfLabelStyles.heading;
          this.controlStyleDp = this.udfControlStyle.heading;
          this.cssClassDp = this.udfCssClass.heading;

          break;
        case "multiselect":
          this.cellStylesDropdown = this.udfCellStyles.multiselect;
          this.labelStyleDropdown = this.udfLabelStyles.multiselect;
          this.controlStyleDp = this.udfControlStyle.multiselect;
          this.cssClassDp = this.udfCssClass.multiselect;

          break;
        case "heading-label":
          this.actiondropdown = this.udfActions.headinglabel;
          this.labelStyleDropdown = this.udfLabelStyles.headinglabel;
          this.controlStyleDp = this.udfControlStyle.headinglabel;
          this.cssClassDp = this.udfCssClass.headinglabel;

          break;
        case 'label':
          this.actiondropdown = this.udfActions.label;
          this.labelStyleDropdown = this.udfLabelStyles.label;
          this.controlStyleDp = this.udfControlStyle.label;
          this.cellStylesDropdown = this.udfCellStyles.label;
          this.cssClassDp = this.udfCssClass.label;

          break;
        case 'textbox':
          this.actiondropdown = this.udfActions.textbox;
          this.labelStyleDropdown = this.udfLabelStyles.textbox;
          this.controlStyleDp = this.udfControlStyle.textbox;
          this.cellStylesDropdown = this.udfCellStyles.textbox;
          this.cssClassDp = this.udfCssClass.textbox;

          break;
        case 'textarea':
          this.controlStyleDp = this.udfControlStyle.textarea;
          this.labelStyleDropdown = this.udfLabelStyles.textarea;
          this.cellStylesDropdown = this.udfCellStyles.textarea;
          this.cssClassDp = this.udfCssClass.textarea;

          break;
        case 'multiselectext':
          this.controlStyleDp = this.udfControlStyle.multiselectext;
          this.labelStyleDropdown = this.udfLabelStyles.multiselectext;
          this.cssClassDp = this.udfCssClass.multiselectext;
          break;
        case 'additionaltestmultiselect':
          this.controlStyleDp = this.udfControlStyle.additionaltestmultiselect;
          this.labelStyleDropdown = this.udfLabelStyles.additionaltestmultiselect;
          break;
        case 'combobox':
          this.mandateDispVal = true;
          break;
      }
      this.filterCellStyle = this.UDFFormGroup.controls.frmCellStyle.valueChanges
        .pipe(
          startWith(''),
          map((user:any) => user && typeof user === 'object' ? user.item : user),
          map((name: string) => name ? this.filterName(name) : this.cellStylesDropdown.slice())
        );
      this.filterActions = this.UDFFormGroup.controls.frmAction.valueChanges
        .pipe(
          startWith(''),
          map((user:any) => user && typeof user === 'object' ? user.item : user),
          map((name: string) => name ? this.filterActionDrpdn(name) : this.actiondropdown.slice())
        );
      this.filterLabelStyle = this.UDFFormGroup.controls.frmLabelStyle.valueChanges
        .pipe(
          startWith(''),
          map((user:any) => user && typeof user === 'object' ? user.item : user),
          map((name: string) => name ? this.filterLabelStyledrpdn(name) : this.labelStyleDropdown.slice())
        );
      this.filterControlStyle = this.UDFFormGroup.controls.frmControlStyle.valueChanges
        .pipe(
          startWith(''),
          map((user:any) => user && typeof user === 'object' ? user.item : user),
          map((name: string) => name ? this.filterControlStyledrpdn(name) : this.controlStyleDp.slice())
        );
      this.filterCssClass = this.UDFFormGroup.controls.frmCssClass.valueChanges
        .pipe(
          startWith(''),
          map((user:any) => user && typeof user === 'object' ? user.item : user),
          map((name: string) => name ? this.filterCssClassdrpdn(name) : this.cssClassDp.slice())
        );
      this.UDFFormGroup.patchValue({
        frmCellStyle: null,
        frmAction: null,
        frmLabelStyle: null,
      })
      this.ngxService.stop();
    }
    catch {
      this.ngxService.stop();
    }
  }

  filterName(name: string): any[] {
    return this.cellStylesDropdown.filter(option =>
      option.item.toLowerCase().includes(name.toLowerCase()));
  }
  filterActionDrpdn(name: string): any[] {
    return this.actiondropdown.filter(option =>
      option.item.toLowerCase().includes(name.toLowerCase()));
  }
  filterLabelStyledrpdn(name: string): any[] {
    return this.labelStyleDropdown.filter(option =>
      option.item.toLowerCase().includes(name.toLowerCase()));
  }
  filterControlStyledrpdn(name: string): any[] {
    return this.controlStyleDp.filter(option =>
      option.item.toLowerCase().includes(name.toLowerCase()));
  }
  filterCssClassdrpdn(name: string): any[] {
    return this.cssClassDp.filter(option =>
      option.item.toLowerCase().includes(name.toLowerCase()));
  }
  filerProcedures(name: String): any[] {
    return this.UDFProcedures.filter(option =>
      option.Value.toLowerCase().includes(name.toLowerCase()) || option.Id.toString().includes(name.toString()));
  }
  filterParents(name: String): any[] {
    return this.UDFParents.filter(option =>
      option.Value.toLowerCase().includes(name.toLowerCase()) || option.Id.toString().includes(name.toString()));
  }
  filteringStatus(name: String): any[] {
    return this.UDFParents.filter(option =>
      option.Value.toLowerCase().includes(name.toLowerCase()) || option.Id.toString().includes(name.toString()));
  }
  //#region  deleting UDF
  deleteUDF(row) {
    if (!this.DeleteUDF) {
      this._snackbar.open("User does not have access", "Close");
      return;
    }
    let arr = [];
    let deleteArry = [];
    deleteArry.push({ "id": row.dataItem.Attribute_Id })
    for (let i = 0; i < this.templateData.submenuData.length; i++) {
      if (this.templateData.submenuData[i].Parentid == row.dataItem.Attribute_Id) {
        arr.push(this.templateData.submenuData[i].Attribute_Name + " (" + this.templateData.submenuData[i].Attribute_Id + ") ")
        deleteArry.push({ "id": this.templateData.submenuData[i].Attribute_Id })
      }
    }
    let alert = ''
    if (arr.length > 0) {
      alert = "header and controls " + arr.toString() + " both will be deleted. Do you wish to continue?"
    }
    else {
      alert = "Do you wish to continue?"
    }
    let dialogRef = this.dialog.open(ConfirmComponent, {
      disableClose: true,
      width: '360px',
      data: {
        header: 'Delete UDF',
        message: '',
        alert: alert,
        continue: 'yes',
        cancel: 'no',
      },
    });
    return dialogRef.afterClosed().toPromise().then((result) => {
      this.ngxService.start();
      //  let obj;
      if (result) {
        // if (arr.length > 0) {
        //   obj = {
        //     id: row.dataItem.Attribute_Id
        //   }
        // }

        this.vitalHttpServices.deleteUDF(deleteArry, this.dbName).subscribe((res) => {
          if (res.Success) {
            this._snackbar.open(res.Message, 'Success');
            this.gridPage = true;
            this.commonService.createActivityTracker('Deleted', row.dataItem.Attribute_Id, 'UDF', 'Audit', {}, {}, row.dataItem.Attribute_Id + ' Deleted');
            this.refreshGrid();
            // this.commonService.setBooleanToTrue(true);
            // this.AddGridData();
          } else {
            this._snackbar.open(res.Message, 'Failed');
            this.gridPage = true;
          }
        }, error => {
          console.error(error);
          this._snackbar.open("Something went wrong please try again!", 'Failed');
        });
      }
      this.ngxService.stop();
    });

  }
  //#endregion

  changeDataSourecType(data) {
    let patchAddtionalData = null;
    this.addtionalDatadrpdn=this.tempAddtionalDatadrpdn
    switch (data.value.toString().toLowerCase()) {
      case "medication":
        patchAddtionalData = '{"spanDisplayName":"Browse A-Z","DataSourceType":"Medication"}'
        break;
      case "site":
        this.addtionalDatadrpdn = [
          { 'item': '{"IsSite":"true","LoadTestBasedOnSite":"true"}' }
          , { 'item': '{"IsSite":"true","maxitemallowed":"1","DefaultSource":"Swab"}' }

        ]
      //   this.addtionalDatadrpdn = ['item':'{"IsSite":"true","LoadTestBasedOnSite":"true"}', '{"IsSite":"true","maxitemallowed":"1","DefaultSource":"Swab"}']
      //   break;
      case 'batchingperformedby':
      case 'fixatives':
      case 'color':
      case 'level':
      case 'lotnumber':
        patchAddtionalData = '{"IsSpanHidden":"false"}'
        break;
    }
    this.UDFFormGroup.patchValue({
      frmAdditionalData: patchAddtionalData
    })

  }
  onCheckChange(item: any) {
    this.selectedStatus.push(item.item)
  }

  onItemDeSelect(items: any) {
    // if (type == 'services') {
    const index: number = this.selectedStatus.indexOf(items.item);
    if (index !== -1) {
      this.selectedStatus.splice(index, 1);
    }
  }
  onSelectAll(items: any) {
    this.selectedStatus = [];
    for (let itr = 0; itr < items.length; itr++) {
      this.selectedStatus.push(items[itr].item);
    }
  }
  onDeSelectAll() {
    this.selectedStatus = [];

  }


  openOrClosePanel(evt: any, trigger: MatAutocompleteTrigger): void {
    evt.stopPropagation();
    if (trigger.panelOpen)
      trigger.closePanel();
    else
      trigger.openPanel();
  }



  clear(evt: any, type): void {
    evt.stopPropagation();

      this.UDFFormGroup.patchValue({
        frmAdditionalData: ""
      })
      this.inputAutoComplete?.nativeElement.focus();
    }
  //   public setForm() {
  //     this.formGroup = new FormGroup({
  //       name: new FormControl(this.statusArr, Validators.required),
  //     });
  //     // this.loadContent = true;
  //   }

  //   get formdata() {
  //     return this.formGroup.controls;
  //   }

  trimFieldValue(controlName: any){
    this.commonService.trimFieldValue(this.copyFromOrgFrm, controlName ,this.copyFromOrgFrm.value.frmOrganization)
  }
}


