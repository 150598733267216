<div *ngIf="templateData && templateData.labels && gridwidth > 0">
  <div class="exeute-grid-wrap customWijimoGrid">
    <wj-flex-grid class="col-sm-12 mt-3 p-0" #flex [headersVisibility]="'Column'" [isReadOnly]="true"
      [itemsSource]="gridData" (initialized)="initGrid(flex)"
      [selectionMode]="'CellRange'" 
      (loadedRows)="onGridLoaded($event)" 
      [autoSizeMode] = "'Headers'"
      (selectionChanged)="onSelectionChanged(flex)">
      <!-- [ngStyle]="{'width.px': gridwidth+1 ,'margin-left':'0px'}" -->
      <div *ngFor="let columnname of columnDefs">
        <wj-flex-grid-column *ngIf="!isDateColumn(columnname)" [binding]="columnname" [header]="columnname.split('_').join(' ')" [width]="120"
          [allowResizing]="true" [format]="'d'"></wj-flex-grid-column>
        <wj-flex-grid-column *ngIf="isDateColumn(columnname)" [binding]="columnname" [header]="columnname.split('_').join(' ')" [width]="120"
        [allowResizing]="true" [format]="'MM/dd/yyyy hh:mm:ss tt'"></wj-flex-grid-column>
      </div>
      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>
  </div>
  <div class="col-sm-12 align-center mt-1" *ngIf="showPaginationMainGrid">
    <wj-collection-view-navigator class="mr-2" headerFormat="Page {currentPage:n0} of {pageCount:n0}" [byPage]="true"
      [cv]="gridData">
    </wj-collection-view-navigator>
    <wj-menu [(value)]="gridData.pageSize" [header]="'Page Size'">
      <wj-menu-item [value]="0">No Paging</wj-menu-item>
      <wj-menu-item [value]="10">10</wj-menu-item>
      <wj-menu-item [value]="15">15</wj-menu-item>
      <wj-menu-item [value]="30">30</wj-menu-item>
      <wj-menu-item [value]="50">50</wj-menu-item>
    </wj-menu>
  </div>
  <div class="col-sm-12 align-center pb-2 mt-2">
    <button (click)="ExportExcel(flex)" mat-raised-button class="admin-btn-success" title="Export"
      [disabled]="hideExportBtn ">Export</button>
    <!-- <div class="checkbox" style="margin-left: 8px;">
        <label>
          <input [(ngModel)]="includeColumnHeader" type="checkbox"> Include Column Header
        </label>
      </div> -->
  </div>
</div>

<div *ngIf="gridwidth == 0" class="nodata-wrap mt-4">
  <div class="nodata-design"> No Data Found </div>
</div>