import { Component, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { from,of,throwError,Observable,fromEvent } from 'rxjs';
import { map, groupBy, mergeMap, reduce, toArray, tap, catchError, finalize, switchMap } from 'rxjs/operators';
import { CollectionView } from '@grapecity/wijmo';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { CommonService } from '../../../core/services/commonservices';
import { FormBuilder, Validators } from '@angular/forms';
import { InjectorService } from '../../../core/services/Injectorservices';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { DataShareService } from '../../../core/services/datashare.service';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { ActivityTrackerService } from '../../../core/services/activity-tracker.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { DialogService } from '../../../core/services/dialog.service';
 
@Component({
  selector: 'app-newcasetypetemplates',
  templateUrl: './newcasetypetemplates.component.html',
  styleUrls: ['./newcasetypetemplates.component.scss']
})
export class NewcasetypetemplatesComponent {
  dataUnavailable: boolean;
  @Input()
  templateData: any;
  gridarray: { [key: string]: any[] } = {};
  gridKeys: string[] = [];
  columnheaders: string[] = [];
  caseTemplateinformation: any;
  viewPage:boolean=false
  viePagedata: any;
  subMenuCardModel: SubMenuCardModel;
  ShowPreviewUploadTemplate: boolean;
  previewHeader: any;
  templateName: string;
  templateInfo: any[];
  NotBlankTemplate: boolean;
  previewVariable: any;
  Status: any;
  showGrid: boolean;
  queryVariable: any;
  BlankTemplate: boolean;
  isHomeHeader: boolean;
  isEditClicked:boolean=false;
  Order_ID: any;
  viewedFinalizedReport: boolean=false;
  selectedItems: any=[];
  isProperfile: boolean=false;
  fileList: File;
  fileName: string;
  uploadToServer: boolean;
  newTempfile: File;
  uploadFileContent: { id: string; name: any; source: string; }[];
  fileExtention: any;
  valueData: string;
  isCurrentTemplateAvailble: boolean;
  DropDownValues=["Case ID","Accession #"]
  dropdownValue=this.DropDownValues[0]
  showDrop: boolean;
  selectedValue = this.DropDownValues[0];
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  activityEntity: any;
  auditableColumns: any;
  menuType="Casetype Report "
  oldObject:any;
  newObject: { "Cassetype": any; "Templatetype": any; "TemplateName": any; };
  actionbuttondata: any;
  download: boolean;
  preview: boolean;

  @ViewChild('dropdownButton', { static: false }) dropdownButton: ElementRef;

  editBtn: boolean;
  dbName: string;
  isFacility: boolean = false;
  accountType: any;
  NewTemplateData: any;
  updateDataBtn: boolean;
  currentTemplateLabelName: String;
  newTemplateLabelName:String;
  deploymentstatusResult: any;

  constructor(
    private opendialog: DialogService,
    private vitalHttpServices: VitalHttpServices,
    private commonService: CommonService,
    private _fb: FormBuilder,
    private injectorServiceCaseType: InjectorService,
    public ngxservice: NgxUiLoaderService,
    public _snackbar: MatSnackBar,
    private DomSanitizer: DomSanitizer, private datashare: DataShareService,
    private activityService: ActivityTrackerService,
    private renderer: Renderer2,
    private clipboard: Clipboard
  ) {
    //super(injectorServiceCaseType, ngxservice, _snackbar);
    this.subMenuCardModel = new SubMenuCardModel(commonService, vitalHttpServices, datashare);
  }

  ngAfterViewInit(): void {
    
    this.setDropdownWidth();
  }
  setDropdownWidth() {
    if (!this.dropdownValue) {
      return;
    }
    const length = this.dropdownValue.length;
    const width = `${length * 8 + 30}px`; 
    if (this.dropdownButton?.nativeElement) {
      this.renderer.setStyle(this.dropdownButton.nativeElement, 'width', width);
    }
  }

  frm_Report = this._fb.group({
    frm_InputValue: ['']
    
  });

  frm_UploadReport = this._fb.group({
    filename: ['', Validators.required]
  });

  frm_Edit = this._fb.group({
    currentfilename:'',
    newfilename:['']
  });

  ngOnInit() {
    this.isFacility = sessionStorage.getItem("search_context")?.match(/facility/i) ? true : false;
    this.dbName=this.vitalHttpServices.deploymentKey
    if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
      this.NewTemplateData=this.templateData.submenuData
      this.menuType=this.menuType.concat(this.templateData.menuURL)
      this.templateData.menuURL=this.menuType
      this.checkOrgtype();
      //this.GridArrangement();
      this.getAuditableDetails(this.menuType);
      let actvityTrackerkeys=this.templateData.secondarykeys;
      delete actvityTrackerkeys.SubHeader;
      delete actvityTrackerkeys.GroupBy;
      this.commonService.createContext(actvityTrackerkeys, '', this.menuType);
      this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
      this.getActionbutton();
    }
    else {
      this.dataUnavailable = true;
    }
  }

  getAuditableDetails(location: any) {
    this.vitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }
  toggleDropdown() {
    this.showDrop = !this.showDrop;
  }

  OnChangeEntry(item: string) {
    this.dropdownValue = item;
    this.templateInfo=[]
    this.frm_Report.patchValue({
      frm_InputValue: ''
    })
    
  }

  CaseSearch(frmvalue, selectSearch){
    if(!frmvalue.controls.frm_InputValue.value.trim()){
      return
    }
    let value=frmvalue.controls.frm_InputValue.value
   
    let caseid = "";
    let accessionnumber = "";
    this.previewVariable = null;
    switch (selectSearch) {
      case 'Case ID':
        if (value.trim() && !isNaN(Number(value))) {
          this.previewVariable = value;
          caseid = value.toString();
          accessionnumber = null;
        } else {
          this._snackbar.open('Please enter a valid Case ID', 'Close');
          this.showGrid = false;
          return;
        }
        break;
      case 'Accession #':
        if (value) {
          if (isNaN(value) && /[a-zA-Z]/.test(value)) {
            accessionnumber = value.trim().toString();
            caseid = null;
          } else {
            this._snackbar.open('Please enter a valid Accession Number', 'Close');
            this.showGrid = false;
            return;
          }
        } else {
          this._snackbar.open('Please enter a valid Accession Number', 'Close');
          this.showGrid = false;
          return;
        }
        break;
    }
    let queryResult: any = {};
    let query = this.vitalHttpServices.GetQuery('previewtemplateforcaseid');
    let queryVariable = {
      labaccountId: !this.isFacility ? this.templateData.cardIdentifier.toString() : null,
      ofaccountId: this.isFacility ? this.templateData.cardIdentifier.toString() : null,
      casetype: this.templateData.cardtype,
      accessionnumber: accessionnumber,
      caseid: caseid,
      requisitionnumber: null
    };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxservice.start();
    this.vitalHttpServices.GetData(queryResult).subscribe((res) => {
      this.ngxservice.stop();
      this.templateInfo = res.data.submenudata.length >0 ?res.data.submenudata :[]
      if (this.templateInfo) {
        if (this.templateInfo.length > 0) {
          this.Status = this.templateInfo[0].Status;
          this.previewVariable = this.templateInfo[0].Case_ID;
          this.dataUnavailable = false;
          if(this.dropdownValue.toLowerCase() === 'case id'){
            this.frm_Report.patchValue({
              frm_InputValue:this.templateInfo[0].Case_ID
            })
          }else{
            this.frm_Report.patchValue({
              frm_InputValue:this.templateInfo[0].Accession_Number
            })
          }
          
         
        } else {
          this.showGrid = false;
          this.dataUnavailable = true;
          
          this.ngxservice.stop();
        }
      }
    });
  }
  
  
  getActionbutton() {
    if (sessionStorage.getItem('RoleName') !== null) {
      let queryVariable = {
        RoleName: sessionStorage.getItem('RoleName'),
        CardType: 'casetypetemplate'
      };
      let query = this.vitalHttpServices.GetconfigQuery();
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      sessionStorage.setItem('deploymentKey', 'configdb');
      this.vitalHttpServices.GetData(queryResult).subscribe((data) => {
        sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
        if (!data.errors) {
          let roledata = JSON.parse(data.data.submenuData[0].Permission);
          // this.actionbuttondata = roledata.ActionButton;
          this.actionbuttondata = roledata.ActionButton;
          for (var i = 0; i < this.actionbuttondata.length; i++) {
            if (this.actionbuttondata[i].Button === 'Download template') {
              if (this.actionbuttondata[i].IsPermitted == 'true') {
                this.download = true;
              } else if (this.actionbuttondata[i].IsPermitted == 'true') {
                this.download = true;
              } else {
                this.download = false;
              }
            } else if (this.actionbuttondata[i].Button === 'Preview') {
              if (this.actionbuttondata[i].IsPermitted == 'true') {
                this.preview = true;
              } else {
                this.preview = false;
              }
            }else if (this.actionbuttondata[i].Button === 'Edit') {
              if (this.actionbuttondata[i].IsPermitted == 'true') {
                this.editBtn = true;
              } else {
                this.editBtn = false;
              }
            }else if (this.actionbuttondata[i].Button === 'Update Data') {
              if (this.actionbuttondata[i].IsPermitted == 'true') {
                this.updateDataBtn = true;
              } else {
                this.updateDataBtn = false;
              }
            }
          }
        }
      }, error => {
        this._snackbar.open("An error occurred while processing your request", 'Failed');
        console.error(error);
        sessionStorage.setItem('deploymentKey', this.vitalHttpServices.deploymentKey);
      });
    }
  }

  async onSaveReportTemplate(formValue,action){

    if(!formValue.controls.newfilename.value.trim()){
      const popUpHeader = "Confirmation";
        const popUpMessage = `Report will be romoved at location casetype level.\n Do you want to proceed...?`;
        const continueBtn = "Yes";
        const dialogRef = this.opendialog.openDialog(popUpHeader, popUpMessage, '', continueBtn, "No");
        dialogRef.afterClosed().subscribe(async res => {
          if (res) {
            if(formValue.controls.currentfilename.value.trim() != formValue.controls.newfilename.value.trim()){
              await this.editTemplateName(formValue.controls.newfilename.value.trim(),action)
            } else {
                this._snackbar.open("New Name and Current Name cannot be the same", 'Close');
              }
          }
        });
    }else{
      if(formValue.controls.currentfilename.value.trim() != formValue.controls.newfilename.value.trim()){
        await this.editTemplateName(formValue.controls.newfilename.value.trim(),action)
      } else {
          this._snackbar.open("New Name and Current Name cannot be the same", 'Close');
        }
    }

  }

  async editTemplateName(value?, action?) {
    
    let tempJson = {
        CaseType: this.templateData.cardtype, 
        Accid: this.templateData.cardIdentifier,
        Template: this.templateName,
        Action: action,
        NewFileName:!value?.trim() ? '':value.toString(),
        TableName:'Accountcasetypes',
        Accounttype:this.accountType,
    };

   

    this.ngxservice.start();

    this.vitalHttpServices.getReportTemplateName(tempJson,this.dbName).pipe(
        tap(res => this.ngxservice.stop()), // Stop the loader regardless of the response
        map(res => {
            this.isEditClicked = true;
            if (!res[0].Success) {
                throw new Error('No template found');
            } else if (action === 'view') {
              this.oldObject={
                "Cassetype":this.templateData.cardtype,
                "Templatetype":this.previewHeader,
                "TemplateName":''
              }
                // Assuming res is an array with objects that have a 'path' property
                let paths = res.map(item => item.path);
                this.currentTemplateLabelName="Current Template Name";
                this.isCurrentTemplateAvailble= !paths[0].toString().trim()?false:true
                if (paths.length > 0) {
                    this.frm_Edit.patchValue({
                        currentfilename: paths[0].toString(), // Use the first path for example
                        newfilename: ''
                    });
                    this.currentTemplateLabelName=this.currentTemplateLabelName+' ('+res[0].pathlevel +')'
                    this.oldObject.TemplateName=paths[0].toString()
                    this.isEditClicked = true;
                    return;
                } else {
                  this.frm_Edit.patchValue({
                    currentfilename:'',
                    newfilename: ''
                  });
                    throw new Error('No path found');
                }
            } else if (action === 'update') {
              this.newObject={
                "Cassetype":this.templateData.cardtype,
                "Templatetype":this.previewHeader,
                "TemplateName":tempJson.NewFileName.toString()
              }
              let tempType=this.oldObject.TemplateName.toString().trim()?'Edit':'Create'
              this.commonService.auditDetails('Templatetype', 'Templatetype', [this.oldObject],[this.newObject],tempType, this.templateData, this.auditableColumns);

              this._snackbar.open("Updated Successfully", 'Close');
              this.isEditClicked = false;
            }   
        }),
        catchError(error => {
            this._snackbar.open(error.message, 'Close');
            return of(null); // Return an observable to complete the stream
        }),
        finalize(() => this.ngxservice.stop()) // Ensure the loader is stopped on completion
    ).subscribe();
}

triggerFileInput() {
  this.fileInput.nativeElement.click();
}

onInputChange(value: string) {
 if(!value.trim()){
   this.templateInfo=[]
    this.frm_Report.patchValue({
      frm_InputValue:''
    })
  }else{
    this.frm_Report.patchValue({
      frm_InputValue:value.toString()
    })
  }
  
}

returnToListpage(){
  this.isEditClicked=false
  this.viewPage=false
  this.templateInfo=[]
  this.removeFile()
}
  returnToViewPage(){
      this.isEditClicked=false
    }
    
  checkGridVisibility(form) {
    const inputValue = form.controls.frm_InputValue.value.toString().trim();
    const templateObject = this.templateInfo && this.templateInfo.length > 0 ?this.templateInfo[0]:{};

    if (!inputValue && templateObject && Object.keys(templateObject).length > 0) {
        return false;
    }
    return true;
}

async fetchDeploymentStatus(){
  let obj={
    CaseType: this.templateData.cardtype, 
      Accid: this.templateData.cardIdentifier,
      // Template: tempColoumnName,
  }
  let dbName=this.vitalHttpServices.deploymentKey
  await this.vitalHttpServices.getDeploymentStatus(obj, dbName).subscribe(async result => {
    
    if (result[0].status) {
      this.deploymentstatusResult=result[0]?.result;
      this.NewTemplateData.forEach(item => {
        let tempColoumnName=this.getTemplateColumn(item.TemplateName)
        item.TemplateLevel = this.deploymentstatusResult[tempColoumnName]? this.deploymentstatusResult[tempColoumnName]:'Deployment specific';
      });
    }
    
  }, error => {
    //this.ngxService.stop();
    this._snackbar.open("An error occurred while processing your request", 'Failed');
    console.log(error);
  });
}

getTemplateColumn(name){
  let tempNewObj={
    "Case Report":"CaseReportTemplates",
    "Blank Technical Worksheet":"BlankTechnicalWorksheet",
    "Technical Worksheet":"TechnicalWorksheetTemplates",
    "Rejection Report":"RejectionReportTemplates",
    "Schematic":"SchematicReportTemplates",
    "Supplemental Report":"SupplementalReportTemplates",
    "OF Report":"OFreporttemplate",
    "OF Schematic":"OFschematictemplate",
    "Send Out Cover Sheet":"SendoutCoverSheetTemplate",
    "Consult":"ConsultTemplate",
    "Case Requisition":"CaseRequisitionTemplate",
    "Blank Requisition":"Blankrequisitiontemplate",
    "Send Out Requisition":"SendOutReportTemplates",
    "Jar Label":"JarLabelTemplates"
  }

  return tempNewObj[name]
}


shouldShowPreviewHeader(templateName) {
  const validTemplateNames = [
    "case report",
    "of report",
    "technical worksheet",
    "case requisition",
    "jar label",
    "of report",
    "send out requisition",
  ];
  return validTemplateNames.includes(templateName.toLowerCase());
}

shouldShowTryNewTemplate(templateName) {
  const validTemplateNames = [
    "case report",
    "technical worksheet",
    "rejection report",
    "case requisition",
    "supplemental report",
    "send Out cover sheet",
    "consult",
    "of report"
  ];
  return validTemplateNames.includes(templateName.toLowerCase());
}


async checkOrgtype(){
  let orgid=this.templateData.secondarykeys.OrganizationId
  this.newTemplateLabelName="New Template Name"
  await this.vitalHttpServices.validateTabIndexOrgType(orgid, this.dbName).subscribe(async result => {
    if (!result.errors) {
      if(result[0].status){
        this.accountType=result[0].result;
        if(result[0].result !== "Laboratories" && result[0].result !== "Both"){
          let ofTemplates =result[0].result.toLowerCase() == "send-out laboratories" ?["Case Report", "Rejection Report", "Schematic", "Supplemental Report", "Case Requisition", "Blank Requisition","Send Out Requisition"] : ["Case Report", "Rejection Report", "Schematic", "Supplemental Report", "Case Requisition", "Blank Requisition"];
          this.NewTemplateData = this.NewTemplateData.filter(item => ofTemplates.includes(item.TemplateName));
        }
        this.newTemplateLabelName=this.newTemplateLabelName+"(Location Level)"
        
        this.GridArrangement()
        
      }
    }
    
  }, error => {
    //this.ngxService.stop();
    this._snackbar.open("An error occurred while processing your request", 'Failed');
    console.log(error);
  });

}





  async GridArrangement() {
    await this.fetchDeploymentStatus()
    await from(this.NewTemplateData).pipe(
      // Remove Querytxt and trim TemplateCategory
      map((item: any) => {
        delete item.Querytxt;
        item.TemplateCategory = item.TemplateCategory.trim('\r\n');
        return item;
      }),
      // Group by TemplateCategory
      groupBy((item: any) => item.TemplateCategory),
      // Merge groups into an object with key as TemplateCategory and value as an array of items
      mergeMap(group$ =>
        group$.pipe(
          toArray(),
          map(items => ({
            [group$.key]: items
          }))
        )
      ),
      // Reduce to a single object
      reduce((acc, curr) => ({ ...acc, ...curr }), {})
    ).subscribe((result: { [key: string]: any[] }) => {
      this.gridarray = result;
      this.gridKeys = Object.keys(this.gridarray);
      this.columnheaders = this.gridKeys.length > 0 ? Object.keys(this.gridarray[this.gridKeys[0]][0]) : [];
    });

  }


  openViewOrEditPage(item){
    this.viewPage=true
    this.viePagedata=item
  }

  caseTypeTemplateInformation(grid) {
    var item = grid;
    this.isHomeHeader = true;
    if (item != null) {
      if (!item.TemplateLevel) {
        return;
      } else {
        this.showPreviewUploadTemplateUI(item, item.TemplateName);
      }
    }
  }

  // triggerFileInput() {
  //   const fileInput = document.getElementById('myFileInputID') as HTMLElement;
  //   fileInput.click();
  // }
  onFileDropped(file: File) {
    this.fileName = file.name;
    this.frm_UploadReport.patchValue({
      filename: file.name
    });

  }

  removeFile() {
    this.fileName = null;
    this.frm_UploadReport.patchValue({
      filename: ''
    });
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }
  

  
  showPreviewUploadTemplateUI(obj: any, value: string) {
    if (!obj) {
      this.ShowPreviewUploadTemplate = false;
      return;
    }
  
    let status: string | null = null;
    let service: string | null = null;
    let template: string = value.toLowerCase().trim();
    this.previewHeader = value;
  
    switch (template) {
      case 'of report':
      service = 'technical only';
        this.templateName = 'ofreport';
        this.BlankTemplate = false;
        break;
      case 'case report':
        status = 'finalized';
        this.templateName = 'casereport';
        this.BlankTemplate = false;
        break;
      case 'case requisition':
        status = 'requisition';
        this.templateName = 'caserequisition';
        this.BlankTemplate = false;
        break;
      case 'send out requisition':
        status = 'send out';
        this.templateName = 'sendoutrequisition';
        this.BlankTemplate = false;
        break;
      case 'technical worksheet':
        service = 'technical only';
        this.templateName = 'technicalworksheet';
        this.BlankTemplate = false;
        break;
      case 'rejection report':
        status = 'rejected'; 
        this.templateName = 'rejectionreport';
        this.BlankTemplate = false;
        break;
      case 'blank requisition':
      case 'blank technical worksheet':
        this.templateName = 'blanktechnicalworksheet';
        this.ShowPreviewUploadTemplate = true;
        this.BlankTemplate = true;
        break;
      case 'schematic':
        service = 'global';
        this.templateName = 'schematic';
        this.BlankTemplate = false;
        break;
      case 'supplemental report':
        // status = 'suplimentalreport';
        this.templateName = 'suplimentalreport';
        this.BlankTemplate = false;
        break;

      case 'of schematic':
        //status = 'ofschematic';
        this.templateName = 'ofschematic';
        this.BlankTemplate = false;
        break;
      case 'send out cover sheet':
        status = 'send out';
        this.templateName = 'sendoutcoversheet';
        this.BlankTemplate = false;
        break;
      case 'consult':
        status = 'Send For Consult';
        this.templateName = 'consult';
        this.BlankTemplate = false;
        break;
      case 'jar label':
        status = 'jar label';
        this.templateName = 'jarlabel';
        this.BlankTemplate = false;
        break;
      default:
        status = 'finalized';
        break;
        
    }
  
    let query = this.vitalHttpServices.GetQuery('previewtemplatelatestcaseid');
    this.queryVariable = {
      labaccountid: this.templateData.cardIdentifier.toString(),
      casetype: this.templateData.cardtype,
      status: status,
      service: service
    };
  
   
  
    let queryresultstt=this.commonService.GetCardRequest(this.queryVariable, query)
    queryresultstt = new Promise<any>((resolve, reject) => {
      resolve(queryresultstt);
    });
    // Assuming GetCardRequest returns a Promise
    from(queryresultstt).pipe(
      switchMap((queryResult) => {
        // Assuming GetData returns an Observable, adjust this accordingly
        return this.vitalHttpServices.GetData(queryResult).pipe(
          tap(() => this.ngxservice.stop()),
          catchError((error) => {
            this.ngxservice.stop();
            this._snackbar.open('An error occurred while processing your request', 'Failed');
            return throwError(error);
          })
        );
      })
    ).subscribe(
      (res) => {
        if (!res.errors) {
          this.processSuccessfulResponse(res.data.submenudata);
        } else {
          this.handleErrorResponse();
        }
      },
      (error) => {
        
        this._snackbar.open('An error occurred while processing your request', 'Failed');
        console.error('HTTP Error:', error);
      }
    );
  }
  
  

  private processSuccessfulResponse(submenudata: any[]) {
    if (submenudata && submenudata.length > 0) {
      this.viewPage = true;
      this.NotBlankTemplate = false;
      this.dataUnavailable = false;
      this.Status = submenudata[0].Status;
      this.previewVariable = submenudata[0].Case_ID;
      this.templateInfo = submenudata;

      setTimeout(() => {
        this.frm_Report.patchValue({
          frm_InputValue: submenudata[0].Case_ID
         
        });
      });

    } else {
      this.viewPage = true;
      this.NotBlankTemplate = false;

      setTimeout(() => {
        this.frm_Report.patchValue({
          frm_InputValue: ''
         
        });
      });
    }
  }

  private handleErrorResponse() {
    this.templateInfo = [];
    this.ShowPreviewUploadTemplate = true;
    this.NotBlankTemplate = true;
    this.viewPage = false;

    setTimeout(() => {
      this.frm_Report.patchValue({
        frm_InputValue: ''
      });
    });
  }


  getTemplatename(template){
    switch (template.toLowerCase()) {
      case 'case report':
        
        this.templateName = 'casereport';
        
        break;
      case 'case requisition':
        
        this.templateName = 'caserequisition';
        
        break;
      case 'send out requisition':
        
        this.templateName = 'sendoutrequisition';
        
        break;
      case 'technical worksheet':
        
        this.templateName = 'technicalworksheet';
        
        break;
      case 'rejection report':
        
        this.templateName = 'rejectionreport';
        
        break;
      case 'blank requisition':
      case 'blank technical worksheet':
        this.templateName = 'blanktechnicalworksheet';
        break;
      case 'blank tc report':
        this.templateName = 'blanktcreport';
        break;
      case 'schematic':
        
        this.templateName = 'schematic';
        
        break;
      case 'suplimental report':
        this.templateName = 'suplimentalreport';
        break;
      case 'of report':
        this.templateName = 'ofreport';
        break;
      case 'of schematic':
        this.templateName = 'ofschematic';
        break;
      case 'send out cover sheet':
        this.templateName = 'sendoutcoversheet';
        break;
      case 'consult':
        this.templateName = 'consult';
        break;
      case 'jar label':
        this.templateName = 'jarlabel';
        break;
      
        
    }
  }

  async DownloadGitFile(value?,action?) {
   
    if(value && value.TemplateName.toString()){
      await this.getTemplatename(value.TemplateName.toString())
    }
    
    let downloadjson = {
      CaseType: this.templateData.cardtype, // dermatology
      Accid: this.templateData.cardIdentifier, // 18858
      Template: this.templateName?this.templateName:value.TemplateName.toString()
    };
  
    this.ngxservice.start();
  
    this.vitalHttpServices.DownloadExistTemplate(downloadjson).pipe(
      tap(res => this.ngxservice.stop()), // Stop the loader regardless of the response
      map(res => {
        if (!res.Success) {
          throw new Error('No template found');
        }
        return res.Message;
      }),
      map(message => {
        const parsedMessage = JSON.parse(message);
        if (parsedMessage.filecontent && parsedMessage.filename) {
          return parsedMessage;
        } else {
          throw new Error('No template found');
        }
      }),
      tap(parsedMessage => {
        if(action=='download'){
          this.commonService.downloadBlob(
            parsedMessage.filecontent,
            parsedMessage.filename,
            false,
            512
          );
        }else if(action=='edit'){

        }
        
      }),
      catchError(error => {
        this._snackbar.open(error.message, 'Close');
        return of(null); // Return an observable to complete the stream
      }),
      finalize(() => this.ngxservice.stop()) // Ensure the loader is stopped on completion
    ).subscribe();
  }

//=======file upload=====================

PreviewNewTemp(value, searchtext) {
  this.ngxservice.start();

  if (!this.frm_UploadReport.value.filename || !this.isProperfile) {
    this._snackbar.open('Please select the file', 'Failed');
    this.ngxservice.stop();
    return;
  }

  const query = this.vitalHttpServices.GetDxUseridBasedOnDeployment();

  this.vitalHttpServices.GetData(query).pipe(
    map(data => {
      if (!data.data.submenuData || data.data.submenuData.length === 0) {
        throw new Error('User is not authorized for this operation');
      }
      return {
        DxUserid: data.data.submenuData[0].userid,
        dxRoleName: data.data.submenuData[0].rolename
      };
    }),
    switchMap(({ DxUserid, dxRoleName }) => {
      const obj = {
        caseid: this.previewVariable,
        accountid: this.templateData.cardIdentifier,
        userid: DxUserid,
        userrole: dxRoleName
      };

      const frmData = new FormData();
      frmData.append('fileUpload', this.newTempfile);
      frmData.append('CaseDetailsJson', JSON.stringify(obj));

      return this.vitalHttpServices.uploadFile(this.uploadFileContent).pipe(
        map(res => {
          if (!res || !res.content || res.content.length === 0) {
            throw new Error('Template Not Found');
          }
          return {
            templateId: res.content[0].id,
            fileType: this.fileExtention.toLowerCase()
          };
        }),
        switchMap(({ templateId, fileType }) => {
          const obj = {
            TemplateId: templateId,
            CaseId: this.previewVariable,
            FileType: fileType
          };

          //const dbname = sessionStorage.getItem("DeploymentKey");
          return this.vitalHttpServices.previewFile(obj, this.dbName);
        })
      );
    }),
    catchError(error => {
      this._snackbar.open(error.message || 'An error occurred while processing your request', 'Failed');
      this.ngxservice.stop();
      return throwError(error);
    })
  ).subscribe(
    result => {
      const file = new Blob([result], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      this.ngxservice.stop();
    },
    error => {
      console.error(error);
      this.ngxservice.stop();
    }
  );
}

resetSearch() {
  this.frm_Report.patchValue({
    frm_InputValue: ''
  })
  this.showGrid = false;
  this.valueData = ''
}

onDrop(files: any) {
  this.onFileDropped(files[0]);
  this.fileChange(null,files[0])
}

fileChange(event: Event | null, inputFile? : File) {
  const inputElement = event?.target as HTMLInputElement || null;
  const file = inputElement?.files?.[0] || inputFile;

  if (!file) {
    return;
  }

  this.fileList = file;
  this.fileName = file.name;
  this.uploadToServer = false;

  this.fileExtention = this.getFileExtension(this.fileName);

  if (!this.isValidExtension(this.fileExtention)) {
    this.resetFileInput(inputElement);
    this._snackbar.open('Please upload an XML or FLXR file format only', 'Failed');
    this.isProperfile = false;
    return;
  }
  this.isProperfile = true;

 
  this.newTempfile = file;

  this.readFile(file).pipe(
    map((fileContent: string) => ({
      id: '1234',
      name: file.name,
      source: fileContent
    })),
    catchError(error => {
      console.error('File reading error', error);
      this._snackbar.open('Error reading file', 'Failed');
      return of(null);
    })
  ).subscribe(fileData => {
    if (fileData) {
      this.uploadFileContent = [fileData];
      this.frm_UploadReport.patchValue({
        filename: file.name
      });
    }
  });
}

private getFileExtension(filename: string): string {
  const splitArray = filename.split('.');
  return splitArray[1]?.toUpperCase() || '';
}

private isValidExtension(extension: string): boolean {
  return extension === 'XML' || extension === 'FLXR';
}

private resetFileInput(inputElement: HTMLInputElement): void {
  inputElement.value = null;
}

private readFile(file: File) {
  const fileReader = new FileReader();
  const fileReaderObservable = fromEvent(fileReader, 'load').pipe(
    map(() => {
      const result = fileReader.result as string;
      return result.split(',')[1]; // Get the base64 content
    })
  );

  fileReader.readAsDataURL(file);
  return fileReaderObservable;
}


//========fileupload ends here===========


// =========PreviewFunction==========


PreviewExistingTemp(value) {
  if (this.selectedItems.length > 0) {
    // Perform any necessary actions with this.selectedItems
    this.selectedItems;
  }

  this.ngxservice.start();
  const query = this.vitalHttpServices.GetQuery('OrdIDByCaseID');
  this.queryVariable = {
    CaseID: this.previewVariable.toString()
  };

  const queryResult = this.commonService.GetCardRequest(this.queryVariable, query);

  this.vitalHttpServices.GetData(queryResult).pipe(
    tap(res => {
      if (!res.error) {
        if (res.data.GetOrderByCaseID_list && res.data.GetOrderByCaseID_list.length > 0) {
          this.Order_ID = res.data.GetOrderByCaseID_list[0].Order_ID;
        } else {
          this.Order_ID = null;
        }
      } else {
        this.Order_ID = null;
      }
    }),
    catchError(error => {
      this._snackbar.open('An error occurred while processing your request', 'Failed');
      return of(null); // Return an observable with a null value to gracefully handle the error
    }),
    tap(() => this.ngxservice.stop()) // Ensure the loader is stopped regardless of success or error
  ).subscribe(() => this.GenerateTemplates(value));
}


GenerateTemplates(value) {
    const deployid = sessionStorage.getItem('deploymentKey');
    let dxUrl = this.vitalHttpServices.OrgaAppUrl;
    
    if (!(dxUrl && dxUrl.length > 0)) {
        dxUrl = this.vitalHttpServices.GetDxurlBasedOnDeployment(deployid);
    }
    
    if (dxUrl.substr(dxUrl.length - 1) !== '/') {
        dxUrl += '/';
    }
    
    const previewHeaderLower = this.previewHeader.trim().toLowerCase();
    
    if (this.Status.toString().toLowerCase() === 'rejected' && 
        !['rejection report', 'case requisition'].includes(previewHeaderLower)) {
        this._snackbar.open('This case was rejected', 'Close');
        return;
    }
    
    let url = '';

    switch (previewHeaderLower) {
        case 'technical worksheet':
            url = `${dxUrl}VATechnicalworkSheetReport.aspx?caseid=${this.previewVariable}`;
            this.createNewWindow(url);
            break;
        case 'send out cover sheet':
        case 'send out requisition':
            if (this.Order_ID !== null) {
                url = `${dxUrl}VASentOutReport.aspx?caseid=${this.previewVariable}_${this.Order_ID}&dummy=1#zoom=100`;
                this.createNewWindow(url);
            } else {
                this._snackbar.open('Template Not found', 'Close');
                this.ngxservice.stop();
            }
            break;
        
        case 'case requisition':
            url = `${dxUrl}VAPrintRequisition.aspx?caseid=${this.previewVariable}&dummy=1#zoom=100`;
            this.createNewWindow(url);
            break;
        case 'jar label':
            url = `${dxUrl}VAPrintCassette.aspx?Type=jar&dummy=1#zoom=100`;
            this.createNewWindow(url);
            break;
        case 'supplemental report':
            //url = `${dxUrl}VAPrintCassette.aspx?Type=jar&dummy=1#zoom=100`;
            //this.createNewWindow(url);
            break;
        case 'consult':
            this._snackbar.open('Invalid template type', 'Close');
            break;
        case 'of schematic':
        case 'schematic':
           
            this._snackbar.open('Invalid template type', 'Close');
            break;
        
        case 'of schematic':
               
                this._snackbar.open('Invalid template type', 'Close');
                break;
        
        case 'of report':
        case 'case report':
        case 'rejection report':
            this.handleReportCases(value, previewHeaderLower, dxUrl);
            break;
        
        default:
            this._snackbar.open('Invalid template type', 'Close');
            break;
    }
}

handleReportCases(value, previewHeaderLower, dxUrl) {
    this.vitalHttpServices.GetEncryptedData(value).pipe(
        tap(res => {
            if (!res.Success) {
                this._snackbar.open(res.Message, 'Failed');
                this.ngxservice.stop();
                return;
            }
        }),
        switchMap(res => {
            let url = '';
            if (this.Status.toString().toLowerCase() === 'finalized' && (previewHeaderLower === 'case report' || previewHeaderLower === 'of report')) {
                this.viewedFinalizedReport = true;
                url = `${dxUrl}VAReportGetForCase.aspx?${res.Message}`;
            }
            else if (this.Status.toString().toLowerCase() === 'rejected' && previewHeaderLower === 'rejection report') {
                url = `${dxUrl}VAReportGetForCase.aspx?${res.Message}`;
            } else if (this.Status.toString().toLowerCase() !== 'rejected' && previewHeaderLower === 'rejection report') {
                this._snackbar.open('This case was not rejected', 'Close');
                this.ngxservice.stop();
                return of(null); // Return null to terminate further execution
            }

            if (this.viewedFinalizedReport && this.Status.toString().toLowerCase() === 'finalized') {
                this.viewedFinalizedReport = false;
                const auditInfo = this.createAuditInfo(value);

                return this.vitalHttpServices.RecordAudit(auditInfo).pipe(
                    tap(result => {
                        if (!result.error && result.message === 'Audit Successful') {
                            this.createNewWindow(url);
                        } else {
                            this._snackbar.open('You are unauthorized to view the report!', 'Close');
                        }
                    }),
                    catchError(err => {
                        console.error(err);
                        this._snackbar.open('You are unauthorized to view the report!', 'Close');
                        return of(null); // Return null to handle error gracefully
                    })
                );
            } else {
                this._snackbar.open('Case ID is not in Finalized state!', 'Close');
                this.ngxservice.stop();
                return of(null); // Return null to terminate further execution
            }
        }),
        catchError(error => {
            this.ngxservice.stop();
            console.error(error);
            this._snackbar.open('An error occurred while processing your request', 'Failed');
            return of(null); // Return null to handle error gracefully
        })
    ).subscribe(() => {
        this.ngxservice.stop();
    });
}

createAuditInfo(value) {
    return {
        CaseID: value,
        UserID: sessionStorage.getItem('Userid') || -100,
        ActionPerformed: "Viewed Final Report",
        ActionDetails: ";Finalized",
        TimeTaken: 0,
        TraceID: "",
        IsShown: 0
    };
}

createNewWindow(url) {
    window.open(
        url,
        '_blank',
        'resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no, width=1000, height=600, left=100, top=100'
    );
}

copy(form) {
  this.clipboard.copy(form.controls.currentfilename.value);
  this._snackbar.open("Copied Successfully", "Close");
}


// ================Preview Ends Here==========





}
