import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-vital-axis-assign-representatives',
  templateUrl: './vital-axis-assign-representatives.component.html',
  styleUrls: ['./vital-axis-assign-representatives.component.scss']
})
export class VitalAxisAssignRepresentativesComponent implements OnInit, OnChanges {

  constructor(private snackbar: MatSnackBar) {

  }

  autoCompleteSelectedUser: any;
  autoCompleteSelectedRole: any;

  usersList: any = [];
  rolesList: any = [];
  departmentList: any = [];
  workLocationList: any = [];

  showAddNewForm = false;
  newUserForm: any;

  selectedUserWholeObject: any;
  selectedRoleWholeObject: any;
  backupUsersList: any;
  newRoleCreation: any;
  toolTipText: any;
  currentRole: any;
  isUserSelected = false;
  isRoleSelected = false;


  originalDepartmentList = ['Department 1', 'Department 2', 'Department 3', 'Department 4'];
  originalWorkLocationList = ['workLocation 1', 'workLocation 2', 'workLocation 3', 'workLocation 4'];

  @Input() originalUsersList:any = [];
  @Input() originalRolesList:any = [];
  @Input() openedLocation:any = 'new customer';
  @Input() finalAssignedUsers:any = [];
  @Input() deploymentKey:any = [];
  @Input() isShowAssign = false;
  @Input() saveClicked: any;

  @Output() emitSelectedUsersArray: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.currentRole = localStorage.getItem('Homepage_role');
    this.originalUsersList.sort((a, b) => {
      return a.RepresentativeName.localeCompare(b.RepresentativeName);
    });
    this.usersList = structuredClone(this.originalUsersList);
    this.backupUsersList = structuredClone(this.originalUsersList);
    this.rolesList = structuredClone(this.originalRolesList);
    this.departmentList = structuredClone(this.originalRolesList);
    this.rolesList = structuredClone(this.originalRolesList);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if((changes?.deploymentKey?.currentValue !== changes?.deploymentKey?.previousValue) || !this.finalAssignedUsers.length) {
      this.clearAllDetails();
    }

    if(changes?.saveClicked?.currentValue && changes?.saveClicked?.currentValue !== changes?.saveClicked?.previousValue) {
      this.resetNewUserForm();
      this.resetViewForm();
      this.isShowAssign = false;
      this.showAddNewForm = false;
    }
  }

  selectUser($event: any, item: any) {
    if(!this.isUserSelected) {
      this.isUserSelected =  true;
      this.selectedUserWholeObject = item;
      this.autoCompleteSelectedUser = item?.RepresentativeName;

      setTimeout(() => {
        this.isUserSelected = false;
      });
    }

  }

  selectRole($event: any, item: any) {
    if(!this.isRoleSelected) {
      this.isRoleSelected = true;
      this.autoCompleteSelectedRole = '';
      this.selectedRoleWholeObject = '';
      this.selectedRoleWholeObject = item;
      this.autoCompleteSelectedRole = item;

      setTimeout(() => {
        this.isRoleSelected = false;
      })
    }

  }

  filteredUsers() {
    if (!this.autoCompleteSelectedUser) {
      this.usersList = structuredClone(this.backupUsersList?.filter(user =>
        this.finalAssignedUsers?.length ? !this.finalAssignedUsers.some(selected => selected?.['Email'] === user?.['Email']) : true
      ));
    } else {
      let filteredList = this.backupUsersList.filter((option: any) => option?.['RepresentativeName']?.toLowerCase().includes(this.autoCompleteSelectedUser?.toLowerCase()));
      filteredList = structuredClone(filteredList?.filter(user =>
        this.finalAssignedUsers?.length ? !this.finalAssignedUsers.some(selected => selected?.['Email'] === user?.['Email']) : true
      ));
      this.usersList = structuredClone(filteredList);
    }
  }

  checkRoles(fieldValue: any, type = 'add') {
    if(!this.rolesList?.find((item: any) => item === fieldValue)) {
      type === 'add' ? this.newUserForm['RoleName'] = '' : this.autoCompleteSelectedRole = '', this.selectedRoleWholeObject = '';
    }
  }

  checkUsers(fieldValue: any) {
    if(!this.usersList?.find((item: any) => item?.RepresentativeName === fieldValue)) {
      this.autoCompleteSelectedUser = '';
      this.selectedUserWholeObject = '';
      setTimeout(() => {
        this.usersList = [...this.originalUsersList];
      }, 300);
    }
  }

  filteredRoles(inputValue: any) {
    if (!inputValue) {
      this.rolesList = structuredClone(this.originalRolesList);
    } else {
      let filteredList = this.originalRolesList.filter((option: any) => option.toLowerCase().includes(inputValue?.toLowerCase()));
      this.rolesList = structuredClone(filteredList);
    }
  }

  closeAssign() {
    this.resetViewForm();
    this.isShowAssign = false;
  }

  doesRoleExists(finalObject: any) {
    if(this.finalAssignedUsers?.length) {
      let assignedRoles = this.finalAssignedUsers?.filter((item: any) => item?.RoleName === 'Account Manager' && item?.IsAssociationActive);
      let isAccountManager = assignedRoles.map(item => item?.RoleName).includes(finalObject?.RoleName);
      return isAccountManager ? true : false;
    } else {
      return false;
    }
  }

  addAssignedUser() {
    if(this.selectedUserWholeObject && this.selectedRoleWholeObject) {
      let finalObject = {...this.selectedUserWholeObject, ...{ 'RoleName': this.selectedRoleWholeObject }};
      finalObject = this.getProperFinalData(finalObject);

      if(this.doesRoleExists(finalObject)) {
        // this.isShowAssign = false;
        // this.showAddNewForm = false;
        // this.resetViewForm();
        this.snackbar.open("A user already exists as Account Manager", 'Close');
        return;
      }

      this.finalAssignedUsers.push(finalObject);
      this.resetViewForm();

      const selectedUserNames = this.finalAssignedUsers.map(user => user.Email);
      const filteredUsersList = structuredClone(this.originalUsersList.filter(user => !selectedUserNames.includes(user.Email)));
      this.originalUsersList = structuredClone(filteredUsersList);
      this.usersList = structuredClone(filteredUsersList);
      this.isShowAssign = false;
      this.emitSelectedUsersArray.emit(this.finalAssignedUsers);
    } else {
      this.snackbar.open("Please select both user and role", 'Close');
    }
  }

  addNewUserForCreation() {
    let pattern = /^[a-zA-Z0-9._%+\-]+@(vitalaxis\.com|starmarkit\.com)$/i;
    if(!this.newUserForm?.RepresentativeName || !this.newUserForm?.Email || !this.newUserForm?.RoleName) {
      this.snackbar.open("Please fill all mandatory field(s)", 'Close');
    } else {
      if(this.finalAssignedUsers.find((item: any) => item.Email?.toLowerCase() === this.newUserForm?.Email?.toLowerCase())) {
        this.snackbar.open("Email already exists", 'Close');
      } else if(!pattern.test(this.newUserForm.Email)) {
        this.snackbar.open("Please enter valid email (starmarkit.com/vitalaxis.com)", 'Close');
      } else if(this.backupUsersList.find((item: any) => item.Email?.toLowerCase() === this.newUserForm?.Email?.toLowerCase())) {
        this.snackbar.open("User with this email already exists", 'Close');
        return;
      } else if(this.newUserForm?.EmployeeId && (this.backupUsersList.some((item: any) => item.EmployeeId === this.newUserForm?.EmployeeId) || this.finalAssignedUsers.some((item: any) => item.EmployeeId === this.newUserForm?.EmployeeId))) {
        this.snackbar.open("User with this employee-id already exists", 'Close');
        return;
      } else {
        this.newUserForm = this.getProperFinalData(this.newUserForm);
        this.newUserForm['AssociationId'] = 0;
        this.newUserForm['ShiftName'] = 'ALL NIGHT SHIFT';
        this.newUserForm['IsActive'] = true;
        this.newUserForm['IsAssociationActive'] = true;

        if(this.doesRoleExists(this.newUserForm)) {
          // this.isShowAssign = false;
          // this.showAddNewForm = false;
          // this.resetNewUserForm();
          this.snackbar.open("A user already exists as Account Manager", 'Close');
          return;
        }

        this.finalAssignedUsers.push(this.newUserForm);
        this.emitSelectedUsersArray.emit(this.finalAssignedUsers);
        this.isShowAssign = false;
        this.showAddNewForm = false;
        this.resetNewUserForm();
      }
    }
  }

  assignNewUser() {
    this.isShowAssign = true;
    this.usersList = [...[]];
    this.usersList =  structuredClone(this.backupUsersList.filter(user =>
      !this.finalAssignedUsers.some(selected => selected?.['Email'] === user?.['Email'])
    ));
  }

  resetViewForm() {
    this.autoCompleteSelectedRole = '';
    this.autoCompleteSelectedUser = '';
    this.selectedRoleWholeObject = '';
    this.selectedUserWholeObject = '';
    this.usersList = [...this.originalUsersList];
    this.rolesList = [...this.originalRolesList];
  }

  showNewUserForm() {
    this.resetNewUserForm();
    this.isShowAssign = false;
    this.showAddNewForm = true;
  }

  hideNewUserForm() {
    this.isShowAssign = false;
    this.showAddNewForm = false;
  }

  resetNewUserForm() {
    this.newUserForm = {
      RepresentativeName: '',
      RoleName: '',
      Email: '',
      Phone: '',
      Department: '',
      WorkLocation: '',
      EmployeeId: ''
    };
  }

  clearAllDetails() {
    this.resetNewUserForm();
    // this.isShowAssign = false;
    this.showAddNewForm = false;
    this.autoCompleteSelectedRole = '';
    this.autoCompleteSelectedUser = '';
    this.selectedRoleWholeObject = '';
    this.selectedUserWholeObject = '';
    this.usersList = [...this.originalUsersList];
    this.rolesList = [...this.originalRolesList];
    this.finalAssignedUsers = [... []];
  }

  getProperFinalData(finalObject: any) {
    let modifiedFinalObject = finalObject;
    modifiedFinalObject['OrganizationId'] = this.openedLocation === 'new customer' ? 0 : sessionStorage.getItem('org_id');
    modifiedFinalObject['OrganizationGUID'] = this.openedLocation === 'new customer' ? 0 : sessionStorage.getItem('OrganizationGUID');
    modifiedFinalObject['DeploymentKey'] = this.openedLocation === 'new customer' ? this.deploymentKey : sessionStorage.getItem('deploymentKey');
    modifiedFinalObject['IsAssociationActive'] = true;
    modifiedFinalObject['CreatedBy'] = localStorage.getItem('UserEmail');
    modifiedFinalObject['ModifiedBy'] = localStorage.getItem('UserEmail');
    modifiedFinalObject['CreatedDate'] = '';
    modifiedFinalObject['ModifiedDate'] = '';
    return modifiedFinalObject;
  }

  filterAutomCompleteOptions(autoCompleteInputboxValue: any, autoCompleteOptions: any, filterProperty: any) {
    if (!autoCompleteInputboxValue) {
      return autoCompleteOptions;
    } else {
      let filteredAutoCompleteOptions = autoCompleteOptions;
      return filteredAutoCompleteOptions.filter((option: any) => option[filterProperty].toLowerCase().includes(autoCompleteInputboxValue?.toLowerCase()));
    }
  }

  deleteAssociation($event: any, index: any) {
    this.finalAssignedUsers.splice(index, 1);
    this.usersList = [...[]];
    this.usersList = structuredClone(this.backupUsersList.filter(user =>
      this.finalAssignedUsers?.length ? !this.finalAssignedUsers.some(selected => selected?.['Email'] === user?.['Email']) : true
    ));
  }

  allowOnlyNumber(event: KeyboardEvent){
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  checkRepresentativesList(isAssociationActive : boolean){
    return this.finalAssignedUsers.some(cardInfo => cardInfo.IsAssociationActive == isAssociationActive);
  }

  trimField(field: any): void {
    this.newUserForm[field] = this.newUserForm[field] ?  this.newUserForm[field]?.trim() : this.newUserForm[field];
  }

  enableToolTip(e) {
    const text = e.scrollWidth > e.clientWidth ? e.textContent : '';
    this.toolTipText = text;
  }

  formatPhoneNumber(): void {
    let input = this.newUserForm?.Phone.replace(/\D/g, '');
    let formattedInput = '';

    if (input.length > 0) {
      formattedInput += input.substring(0, 3);
    }
    if (input.length > 3) {
      formattedInput += '.' + input.substring(3, 6);
    }
    if (input.length > 6) {
      formattedInput += '.' + input.substring(6, 10);
    }
    if (input.length > 10) {
      formattedInput += 'x' + input.substring(10, 20).substring(0, 10);;
    }

    this.newUserForm.Phone = formattedInput;
  }

  validateKeypress(event: KeyboardEvent): void {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    if (!/^[0-9x]$/.test(keyValue)) {
      event.preventDefault();
    }
  }

  preventPaste($event: ClipboardEvent) {
    $event.preventDefault();
  }

  checkPhone() {
    if(this.newUserForm?.Phone) {
      this.newUserForm.Phone = this.newUserForm?.Phone?.length < 12 ? '' : this.newUserForm?.Phone;
    }
  }
}
