import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from "rxjs";
import { Tabset } from 'src/app/base/Tab/tabset.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import { ActivatedRoute } from '@angular/router';
import { ResultTabArray } from '../../../model/Tab';
import { HttpClient } from '@angular/common/http';

declare var $: any;
declare var jQuery: any;
@Component({
  selector: 'template-Executesql',
  templateUrl: 'Executesql.component.html',
  styleUrls: ['./Executesql.component.scss']
})

export class ExecuteSqlComponent {
  public DeploymentKeys = [];
  isMaximize = false;
  // public DeploymentKeys = environment.DeploymentKeys;
  //public templateData: any = {};
  //User Ip model
  ipPattern =
    "(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)";
  // Executesqlgroup = new FormGroup({
  //   // DeploymentKey: new FormControl('', Validators.required),
  //   // querytxt: new FormControl('', Validators.required)
  // });
  //Executesqlgroup: FormGroup;
  ExecuteSqlTabArray: any[] = [];
  selectedTab: any;
  public tabSub = new BehaviorSubject<any>(this.ExecuteSqlTabArray);
  OldDeploymentKeys: any;
  defaultdeploymentkey: string;
  public static baseUrl2:any;

  @Input()
  selecteddefaultdeploymentkey: string;
  constructor(private ngxService: NgxUiLoaderService, public vaservices: VitalHttpServices, private _tabset: Tabset,
    private commonService: CommonService, public _snackBar: MatSnackBar, private fb: FormBuilder, private datashare: DataShareService, private route: ActivatedRoute,private http:HttpClient
  ) {
    //this.Executesqlgroup = this.fb.group({});
    this.DeploymentKeys = this.vaservices.DeploymentKeys
  }
  // public DeploymentKeys = this.vaservices.DeploymentKeys;

  // ngOnInit(): void {
  //   this.vaservices.GetAllUrls().subscribe((result) => {
  //     if (!result.errors) {
  //       this.DeploymentKeys = result.DeploymentKeys;
  //       if (this.vaservices.Additionaldeployment) {
  //         for (let i = 0; i < this.vaservices.Additionaldeployment.length; i++) {
  //           let obj = this.DeploymentKeys.find(x => x == this.vaservices.Additionaldeployment[i]);
  //           if (obj == undefined) {
  //             this.DeploymentKeys.push(this.vaservices.Additionaldeployment[i])
  //           }
  //         }
  //       }
  //       else {
  //         this.DeploymentKeys = this.DeploymentKeys;
  //       }
  //       this.defaultdeploymentkey = this.commonService.getDefaultDeployment();
  //       if(this.defaultdeploymentkey === 'NULL'){
  //         this.defaultdeploymentkey ="Execute Query"
  //       }else{
  //         let depKey = sessionStorage.getItem('selectedDeployment')
  //         this.defaultdeploymentkey = depKey ? depKey.toUpperCase() : this.defaultdeploymentkey.toUpperCase();
  //       }
  //       // this.route.queryParams.subscribe(data => {

  //       //   this.defaultdeploymentkey = data.deployment ? data.deployment.toString().toUpperCase() : localStorage.getItem('defaultdeployment');
  //       // });
  //       this.AddInitialTab();
  //     } else {
  //       this.ngxService.stop();
  //       this._snackBar.open('An error occurred while processing your request', 'Failed');
  //     }
  //   }, error => {
  //     console.error(error)
  //   })

  // }



  ngOnInit(): void {
    // this.http.get<any>('../../assets/config/appsettings.json').subscribe(
    //   (data) => {
    //     this.vaservices.setjsondata(data);
        this.getRoleData();
    //   }
    // );
  }

  getRoleData() {
    let queryVariable = {
      RoleName: localStorage.getItem("Homepage_role"),
      CardType: "Homepage",
    };
    let query: string = 'query ($RoleName: String, $CardType: String) {    submenuData: VitalAdminUserRoles_list(RoleName: $RoleName, CardType: $CardType) {      RoleName      CardType      Permission      Status    }  }'
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    sessionStorage.setItem('deploymentKey', "configdb");
    this.vaservices.GetData(queryResult).subscribe((data) => {
      sessionStorage.setItem('deploymentKey', this.vaservices.deploymentKey);
      if (!data.errors) {
        if (data.data.submenuData && data.data.submenuData.length > 0) {
          let homepagedata = JSON.parse(data.data.submenuData[0].Permission);
          if (homepagedata.DeploymentKeys && homepagedata.DeploymentKeys.length >= 0) {
            this.vaservices.Additionaldeployment = homepagedata.DeploymentKeys
          }
          this.vaservices.GetAllUrls().subscribe((result) => {
            if (!result.errors) {
              this.DeploymentKeys = result.DeploymentKeys;
              if (this.vaservices.Additionaldeployment) {
                for (let i = 0; i < this.vaservices.Additionaldeployment.length; i++) {
                  let obj = this.DeploymentKeys.find(x => x == this.vaservices.Additionaldeployment[i]);
                  if (obj == undefined) {
                    this.DeploymentKeys.push(this.vaservices.Additionaldeployment[i])
                  }
                }
              }
              else {
                this.DeploymentKeys = this.DeploymentKeys;
              }
              this.defaultdeploymentkey = this.commonService.getDefaultDeployment();
              this.DeploymentKeys.sort();
              if (this.defaultdeploymentkey === 'NULL') {
                this.defaultdeploymentkey = "Execute Query"
              } else {
                let depKey = sessionStorage.getItem('selectedDeployment')
                this.defaultdeploymentkey = depKey ? depKey.toUpperCase() : this.defaultdeploymentkey.toUpperCase();
              }
              this.AddInitialTab();
            } else {
              this.ngxService.stop();
              this._snackBar.open('An error occurred while processing your request', 'Failed');
            }
          }, error => {
            console.error(error)
          })
        }
      }
    }, error => {
      sessionStorage.setItem('deploymentKey', this.vaservices.deploymentKey);
      console.error(error);
    });
  }


  AddInitialTab() {
    this.ExecuteSqlTabArray = [];
    if (this.defaultdeploymentkey === 'NULL' || this.defaultdeploymentkey === 'Select' || this.defaultdeploymentkey === 'ALL') {
      this.defaultdeploymentkey = "Execute Query";
    } else {
      this.defaultdeploymentkey = this.defaultdeploymentkey ? this.defaultdeploymentkey : "Execute Query"
    }
    let resultdata = {
      Deployment: this.defaultdeploymentkey.toUpperCase(), //this.Executesqlgroup.addControl("DeploymentKey"+0,new FormControl("VPLABS")),// "DeploymentKey"+0,
      Querytxt: "", //this.Executesqlgroup.addControl("querytxt"+0,new FormControl('',Validators.required)),//  "querytxt"+0,
      sqlResult: null,
      exeSvrObjResult: null
    }
    let tabarr = new ResultTabArray();
    tabarr.GUID = "sqlTab";
    tabarr.Removable = true;
    tabarr.TabName = this.defaultdeploymentkey.toUpperCase();
    tabarr.Count = 0;
    tabarr.disabled = false;
    tabarr.active = true;
    tabarr.ResultContent = resultdata;
    this.ExecuteSqlTabArray.push(tabarr);
  }

  onClickInSqlTab(value) {
    let eventIndex: number = -1;
    if (value.index != null && value.index != undefined)
      eventIndex = value.index;
    this.sqlTabActive(eventIndex)
    this.selectedTab = this.ExecuteSqlTabArray.findIndex(tab => tab.active);
    this.defaultdeploymentkey = this.ExecuteSqlTabArray[this.selectedTab].ResultContent.Deployment.toUpperCase();
  }

  sqlTabActive(index: any) {
    if (this.ExecuteSqlTabArray[index]) {
      for (let i = 0; i < this.ExecuteSqlTabArray.length; i++) {
        this.ExecuteSqlTabArray[i].active = false;
      }
      this.ExecuteSqlTabArray[index].active = true;
      this.tabSub.next(this.ExecuteSqlTabArray);
    }
  }

  count = 0;
  AddSqlTab() {
    if (this.defaultdeploymentkey.toLowerCase() == this.ExecuteSqlTabArray[this.ExecuteSqlTabArray.length - 1].ResultContent.Deployment.toString().toLowerCase()) {
      this.count++;
      let resultdata = {
        Deployment: this.ExecuteSqlTabArray[this.ExecuteSqlTabArray.length - 1].ResultContent.Deployment ? this.ExecuteSqlTabArray[this.ExecuteSqlTabArray.length - 1].ResultContent.Deployment : "Execute Query",
        Querytxt: "",
        sqlResult: null,
        exeSvrObjResult: null
      }
      let tabarr = new ResultTabArray();
      tabarr.GUID = "sqlTab" + this.count;
      tabarr.Removable = true;
      tabarr.TabName = this.ExecuteSqlTabArray[this.ExecuteSqlTabArray.length - 1].ResultContent.Deployment;
      tabarr.Count = this.count;
      tabarr.disabled = false;
      tabarr.active = true;
      tabarr.ResultContent = resultdata;
      this.ExecuteSqlTabArray.push(tabarr);
      if (this.ExecuteSqlTabArray.length > 0) {
        this.sqlTabActive(this.ExecuteSqlTabArray.length - 1)
      }
      //this.selectedTab = tabs.findIndex(tab => tab.active);
      setTimeout(() => {
        this.selectedTab = this.ExecuteSqlTabArray.findIndex(tab => tab.active);
      }, 200);
    }
    else {
      this.count++;
      let resultdata = {
        Deployment: this.ExecuteSqlTabArray[this.ExecuteSqlTabArray.length - 1].ResultContent.Deployment.toString() ? this.ExecuteSqlTabArray[this.ExecuteSqlTabArray.length - 1].ResultContent.Deployment : this.defaultdeploymentkey,
        Querytxt: "",
        sqlResult: null,
        exeSvrObjResult: null
      }
      let tabarr = new ResultTabArray();
      tabarr.GUID = "sqlTab" + this.count;
      tabarr.Removable = true;
      tabarr.TabName = this.defaultdeploymentkey;
      tabarr.Count = this.count;
      tabarr.disabled = false;
      tabarr.active = true;
      tabarr.ResultContent = resultdata;
      this.ExecuteSqlTabArray.push(tabarr);
      if (this.ExecuteSqlTabArray.length > 0) {
        this.sqlTabActive(this.ExecuteSqlTabArray.length - 1)
      }
      //this.selectedTab = tabs.findIndex(tab => tab.active);
      setTimeout(() => {
        this.selectedTab = this.ExecuteSqlTabArray.findIndex(tab => tab.active);
      }, 200);
    }

  }

  removeThisTab(removeTab) {
    if (this.ExecuteSqlTabArray.length > 0) {
      for (let i = 0; i < this.ExecuteSqlTabArray.length - 1; i++) {
        if (i !== removeTab - 1)
          this.ExecuteSqlTabArray[removeTab - 1].active = false;
        else
          this.ExecuteSqlTabArray[removeTab - 1].active = true;
      }
      this.ExecuteSqlTabArray.splice(removeTab, 1);
    }
    setTimeout(() => {
      this.selectedTab = this.ExecuteSqlTabArray.findIndex(tab => tab.active);
    }, 200);
    this.tabSub.next(this.ExecuteSqlTabArray);
    // this.ExecuteSqlTabArray.splice(1);
    // if (this.ExecuteSqlTabArray.length > 0) {
    //   this.ExecuteSqlTabArray[this.ExecuteSqlTabArray.length - 1].active = true;
    // }
    // this.tabSub.next(this.ExecuteSqlTabArray);
  }

  CloseExecuteSql() {
    this.datashare.getreset("")
    //this.Executesqlgroup.reset();
    // if(this.ExecuteSqlTabArray.length > 1){
    //   this.ExecuteSqlTabArray.splice(1,this.ExecuteSqlTabArray.length-1);
    //   // setTimeout(() => {
    //     this.ExecuteSqlTabArray = [];
    //     this.AddInitialTab();
    //   // }, 30);
    //   //this.ExecuteSqlTabArray = this.ExecuteSqlTabArray[0];
    // }
    // //this.AddInitialTab();
    // // this.Executesqlgroup.patchValue(
    // //   {
    // //     DeploymentKey: "VPLABS"
    // //   });
  }

  SubmitExecuteSql(value) {
    //var onlyChar = /^\b(SELECT)\b[a-zA-Z0-9]*/.test(value.querytxt.trim());
    let qtxt = value.Querytxt.trim().toLowerCase()+ "\r\n";
    const validstr = qtxt.startsWith("select");
    if (validstr) {
      // For Execute SQL no need to check the user belongs to DX pr not coz it is select operation only
      // let query = this.vaservices.GetDxUseridBasedOnDeployment(/*value.Deployment.trim()*/);
      // this.ngxService.start();
      // this.vaservices.GetData(query, value.Deployment.trim()).subscribe(data => {
      //   let result = data;
      //   let DxUserid = result.data.submenuData ? result.data.submenuData : null;
      //   this.ngxService.stop();
      //  if(DxUserid && DxUserid.length > 0){
      sessionStorage.setItem('deploymentKey', value.Deployment.trim());
      let sqlobj = {
        querytxt: qtxt,
        PageNumber: 1,
        userid: localStorage.getItem("UserEmail")//this.commonService.GetDxUseridBasedOnDeployment(value.Deployment.trim())
        // no need to send DX user id here null is handled in API side so null is sending.
      }
      //clear server object result
      value.exeSvrObjResult = "";
      value.sqlResult = {};
      //console.log(value.Deployment);
      if (value.Deployment != this.defaultdeploymentkey && value.Deployment == "Execute Query") {
        value.Deployment = this.defaultdeploymentkey;
        // console.log(value.Deployment);
      }
      this.ngxService.start();
      this.vaservices.GetExecuteQueryDetails(sqlobj, value.Deployment.trim()).subscribe(data => {
        this.ngxService.stop();
        if (data) {
          if (data.Table.length > 0) {
            let labels = Object.keys(data.Table[0]);
            // const index = labels.indexOf(removelabel, 0);
            // if (index > -1) {
            //   labels.splice(index, 1);
            // }
            value.sqlResult.submenuData = data.Table;
            value.sqlResult.labels = labels;
            const updatedOnj = value.sqlResult.submenuData.map(obj => {
              const { RecordRank, ...rest } = obj;
              return { '#': RecordRank, ...rest };
            });
            value.sqlResult.submenuData = updatedOnj;
            for (let i = 0; i < value.sqlResult.labels.length; i++) {
              if (value.sqlResult.labels[i] == "RecordRank") {
                value.sqlResult.labels[i] = "#";
                break;
              }
            }
            //value.sqlResult = this.templateData;
          } else {
            this._snackBar.open("No Records Found", 'Failed')
          }
        }
      }, error => {
        this.ngxService.stop();
        console.info(error);
        this._snackBar.open("Enter a valid select statement", 'Failed')
      });
      // }

      // else{
      //   this._snackBar.open("User is not authorized for this operation", 'Failed')
      // }//end of check if userid exist or not
      // }, error => {
      //   this.ngxService.stop();
      //   this._snackBar.open("An error occurred while processing your request", 'Failed')
      // });
    } else {
      this._snackBar.open("Enter a valid select statement.Update,delete,modify are not allowed", 'Failed')
    }
  }

  SubmitExecuteSvrObj(value) {
    sessionStorage.setItem('deploymentKey', value.Deployment.trim());
    let qtxt = value.Querytxt.trim().toLowerCase()+ "\r\n";
    // const validstr = qtxt.startsWith("select");
    const validstr = qtxt.startsWith("select");
    const validstmt = qtxt.startsWith("sp_helptext");
    //clear results
    value.exeSvrObjResult = "";
    value.sqlResult = {};
    if (validstr || validstmt) {
      // let query = this.vaservices.GetDxUseridBasedOnDeployment(/*value.Deployment.trim()*/);
      // this.ngxService.start();
      // this.vaservices.GetData(query, value.Deployment.trim()).subscribe(data => {
      //   let result = data;
      //   let DxUserid = result.data.submenuData ? result.data.submenuData : null;
      //   this.ngxService.stop();
      //   if(DxUserid && DxUserid.length > 0){
      let sqlobj = {
        querytxt: qtxt,
        PageNumber: 1,
        userid: localStorage.getItem("UserEmail"),//this.commonService.GetDxUseridBasedOnDeployment(value.Deployment.trim())
      }
      this.ngxService.start();
      this.vaservices.GetExecuteSrvObjDetails(sqlobj, value.Deployment.trim()).subscribe(data => {
        // value.exeSvrObjResult = {};
        this.ngxService.stop();
        if (data) {
          if (data != undefined && data != "") {
            value.exeSvrObjResult = data;
          }
        }
        else {
          this._snackBar.open("Enter a valid select statement.Update,delete,modify are not allowed", 'Failed');
        }
      }, error => {
        this.ngxService.stop();
        console.info(error);
        this._snackBar.open("Enter a valid select statement.Update,delete,modify are not allowed", 'Failed');
      });
      // } else {
      //   this._snackBar.open("User is not authorized for this operation", 'Failed')
      // }//end of check if user id is present or not
      //   }, error => {
      //     this.ngxService.stop();
      //     this._snackBar.open("An error occurred while processing your request", 'Failed')
      //   });
      // }//end of check for valid statement
    }
    else {
      this._snackBar.open("Enter a valid select statement.Update,delete,modify are not allowed", 'Failed');
    }
  }


  selectDeployment(tab, event) {
    if (event.source.selected) {
      tab.ResultContent.Deployment = event.source.value.toString().toUpperCase();
    }
  }
}


// import { Component } from "@angular/core";
//  import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from "@angular/forms";

//  @Component({
//    selector: 'template-Executesql',
//    templateUrl: 'Executesql.component.html',
//    styleUrls: ['./Executesql.component.scss']
//  })
//  export class ExecuteSqlComponent {
//    uploadArtWorkForm: any;
//    uploadArtWorkForm1: any;
//    isFormSubmitted: boolean = false;

//    parentCategories = [
//      {"parent_id":0,"cat_name":"Jewelry","category_id":1},
//      {"parent_id":0,"cat_name":"Painting","category_id":16},
//      {"parent_id":0,"cat_name":"Photograph","category_id":22}];
//    types;
//    subTypes;

//    //below value will be displayed in Select Type dropdown
//    catTypeValue = [
//      {"parent_id":2,"cat_name":"Anklets","category_id":3,"key_name":"anklets"},
//      {"parent_id":2,
//      "cat_name":"Body",
//      "category_id":4,
//      "key_name":"body"},
//      {"parent_id":2,"cat_name":"Bracelets","category_id":5,
//      "key_name":""},
//      {"parent_id":2,
//      "cat_name":"Charms","category_id":6,
//      "key_name":""},
//      {"parent_id":2,"cat_name":"Necklaces","category_id":7,
//      "key_name":""},
//      {"parent_id":2,"cat_name":"Ornamental","category_id":8,
//      "key_name":""},
//      {"parent_id":2,
//      "cat_name":"Rings & Studs","category_id":9,
//      "key_name":""},
//      {"parent_id":2,
//      "cat_name":"Jewelry Metal","category_id":10,"key_name":""},{"parent_id":2,
//      "cat_name":"Yellow Gold","category_id":11,
//      "key_name":""},
//      {"parent_id":2,
//      "cat_name":"White Gold","category_id":12,"key_name":"whitegold"},{"parent_id":2,
//      "cat_name":"Silver","category_id":13,"key_name":"silver"}
//      ]

//    // below values will be displayed in Select Metal dd
//    catMetalValue = [
//      {"parent_id":14,"cat_name":"White Gold","category_id":24,"key_name":"whitegold"},
//      {"parent_id":14,"cat_name":"Yellow Gold","category_id":24,"key_name":"whitegold"},
//      ]

//    // below values will be displayed in Select Gems dd
//    catGemsValue = [
//      {"parent_id":15,"cat_name":"Blue Gems","category_id":25,"key_name":"bluegems"}
//      ]

//    constructor(private formBuilder: FormBuilder) {
//      this.uploadArtWorkForm = this.formBuilder.group({
//      cat_id: new FormControl('', Validators.required),
//      categoryArray: this.formBuilder.array([]),
//    });
//    }
//    ngOnInit() {}
//    onChangeType(value, id){
//  // solution to https://stackoverflow.com/questions/61315779/angular-8-not-getting-selected-dropdown-values-in-component-using-reactive-form?noredirect=1&lq=1
//    }
//    onChangeCategory(event, id) {
//      if (id) {
//        this.types = [
//          {"parent_id":1,
//          "cat_name":"Type",
//          "category_id":2,
//          "key_name":"type"},
//          {"parent_id":1,
//          "cat_name":"Metal",
//          "category_id":14,
//          "key_name":"metal"},
//          {"parent_id":1,
//          "cat_name":"Gems",
//          "category_id":15,
//          "key_name":"gems"}];
//        if(this.uploadArtWorkForm.controls['categoryArray'].length > 0){
//          this.uploadArtWorkForm.controls['categoryArray'].controls.length = 0
//        }
//        this.addInNewCategory(this.types);
//      } else {
//        this.types = null;
//        this.subTypes = null;
//      }
//    }

//    get category(): FormArray {
//      return <FormArray>this.uploadArtWorkForm.controls['categoryArray'];
//    }

//    addInNewCategory(types) {['categoryArray'];
//      types.forEach((element, i) => {
//        this.category.push(this.formBuilder.group({[element["key_name"]]: new FormControl(element["key_name"])
//        }));
//      });
//    }

//    addUploadArtWorkForm(value){
//    }
//  }
