import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import { WjFlexGrid } from '@grapecity/wijmo.angular2.grid';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { parse } from 'date-fns';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { Attachments, CreateActivityTrackerInput, Operations } from 'src/app/model/activity-tracker';
import * as XLSX from 'xlsx';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalMenuComponent } from '../../Vital Search Menu/vital-menu.component';
import { CaseTagsDialogComponent } from '../case-tags-dialog/case-tags-dialog.component';
import { CaseTagsFilterComponent } from '../case-tags-filter/case-tags-filter.component';

declare var $: any;
interface Child {
  tagvaluesequence?: string;
  organizationcasetagvalueid?: string;
  tagvalue?: string;
  tagvaluefontcolor?: string;
  color?: string;
  isdefault?: string;
}

@Component({
  selector: 'app-organization-case-tags',
  templateUrl: './organization-case-tags.component.html',
  styleUrls: ['./organization-case-tags.component.scss']
})
export class OrganizationCaseTagsComponent implements OnInit {

  chipsList = ['Casetype', 'Case Status', 'Roles']

  @Input() templateData: any;
  @Output() isEditable = false;

  @ViewChild('caseTypeFilter') caseTypeChild!: CaseTagsFilterComponent;
  @ViewChild('caseStatusFilter') caseStatusChild!: CaseTagsFilterComponent;
  @ViewChild('roleFilter') roleChild!: CaseTagsFilterComponent;

  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  @ViewChild('inputAutoComplete') inputAutoComplete: any;
  @ViewChild('inputAutoCompleteGroup') inputAutoCompleteGroup: any;

  @ViewChild('grid') flex: WjFlexGrid;
  @ViewChild('flexgrid') organizationtags: WjFlexGrid;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  allStatus: { item: string; }[];
  allCasetype: { casetype: string; }[];
  allRoles: { displayname: string; }[];
  nCasestatus: { item: string; }[];

  public SubMenuCardModel;
  public DeploymentKeys = [];

  cardData: Array<any> = [];
  GroupList: any = [];
  Orgtype: any;
  destOrgid: any = sessionStorage.getItem('org_id').toString();
  allFields: any = [{}];
  mandatoryFields = [{}];

  isAllSelected: boolean = false;

  isFlagNodata: boolean;
  isFlag: boolean = true;
  isList: boolean = true;
  cardShow: boolean = false;
  isactive: boolean = false;
  chipDataOpen: boolean = false;
  uploadClicked: boolean = false;
  xpandStatus = false;
  noData: boolean = true;
  removable = true;

  //copy
  formatGridData: wjcCore.CollectionView<any>;
  gridData: wjcCore.CollectionView<any>;
  backBtn: boolean = false;
  organizationList: any = [];
  srcDeployment: any;
  searchResult: any[];
  srcRole: string = '';
  postUpload: boolean = false;
  usertoggle: boolean = false;
  searchUserInput: String = '';
  searchRoleList: any[];
  excelDataArray: any[];
  showInvalidColumns: boolean = false;
  addEditClicked: boolean = false;
  selectedData: any = [];
  srcOrgid: string;
  checkStatus: boolean = false;
  sheetsToSelect: any;
  workBook: any;
  sheetHeader: any = [];
  copyFlag: any;
  invalidColumns: string = "";
  gridDisplay: boolean = false;
  showDelete: boolean = true;
  selectedCopyData: any = [];
  caseTagForm: FormGroup;
  selectedNCasestatusList: any = [];
  postDownload: boolean = false;
  isInputEnabled: boolean = true;

  createBtn: boolean = false;
  editBtn: boolean = false;
  exportBtn: boolean = false;
  uploadBtn: boolean = false;
  copyBtn: boolean = false;

  //upload
  copyDataClicked: boolean;
  enableInsert: boolean;
  gridShow: boolean = false;
  bulkUpload: boolean;
  gridwidth: number = 0;
  gridWidth: number = 0;
  searchInput: String = '';

  AccountsList: any = [];
  selectedAccountid: any;
  CaseNStatusList: any = [];

  RoleList: any = [];
  CasetypeList: any = [];
  CaseStatusList: any = [];

  CasetypeDataList: any;
  caseStatusDataList: any;
  nCaseStatusDataList: any;

  selectedRoleList: any = [];
  selectedCasetypeList: any = [];
  selectedCasestatusList: any = [];

  auditableColumns: any;
  activityEntity: any;

  destDeployment: string;
  matDialog: any;
  cardDatabackup = [];
  selectedVal: string = '';
  groupData: any;
  myObjectChange: any;
  listCasetype = [];
  listStatus = [];
  listRoles = [];
  updatedCasetypelist: any = [];
  updatedCaseStatuslist: any = [];
  updatedRolelist: any = [];
  removeIndex: any;
  selector: Selector;
  typeName: any;
  exixtingCaseType: any;
  exixtingCasestatus: any;
  exixtingRoles: any;
  existingnCasestatus: any;

  caseStatusDropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
    selectAllText: string;
    unSelectAllText: string,
  };

  auditObj: any
  createActivityInput = new CreateActivityTrackerInput();
  createActivityOperations = new Operations();
  createActivityAttachments = new Attachments();

  constructor(private datashare: DataShareService, public datePipe: DatePipe, private vaservice: CommonService, public VitalMenuComponent: VitalMenuComponent, private ngZone: NgZone, private _fb: FormBuilder, private ngxService: NgxUiLoaderService, private _snackbar: MatSnackBar,
    public VitalHttpServices: VitalHttpServices, public activityService: ActivityTrackerService, public commonService: CommonService, private dialog: MatDialog, private cdr: ChangeDetectorRef) {
    this.SubMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.listData();
    this.destDeployment = this.VitalHttpServices.deploymentKey;
    this.getCasestatusList();
    this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addCardData(this.templateData.submenuData) : this.noData = true;
    this.isAllSelected ? this.activeData('') : this.activeData(this.selectedVal)
    this.checkdisable()
    this.GetButtondetails();
    this.uncheckAll();
  }

  async ngOnInit(): Promise<void> {
    this.selectedVal = 'active';
    this.destDeployment = this.VitalHttpServices.deploymentKey;
    await this.getAccountType();
    await this.getLookupValues();
    this.getCasestatusList();
    this.listData();
    this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addCardData(this.templateData.submenuData) : this.noData = true;
    this.activeData(this.selectedVal)
    this.checkdisable()
    this.GetButtondetails();
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
  }

  toggleFilter() {
    // Reset data or perform any necessary actions when the filter button is clicked
    // For example, call a method to reset data (replace with your actual method)
    this.uncheckAll();
    // Toggle the filter state
    this.isFlag = !this.isFlag;
  }

  uncheckAll() {
    if (this.CasetypeDataList !== undefined) {
      this.CasetypeDataList.forEach(item => {
        item['checked'] = false;
      });
    }
    if (this.caseStatusDataList !== undefined) {
      this.caseStatusDataList.forEach(item => {
        item['checked'] = false;
      });
    }
    if (this.RoleList !== undefined) {
      this.RoleList.forEach(item => {
        item['checked'] = false;
      });
    }
    this.updatedCasetypelist = [];
    this.updatedCaseStatuslist = [];
    this.updatedRolelist = [];
  }

  async refreshComponent() {
    // this.selectedVal = 'All';
    this.destDeployment = this.VitalHttpServices.deploymentKey;
    await this.getAccountType();
    await this.getLookupValues();
    this.getCasestatusList();
    this.listData();
    this.templateData.submenuData && this.templateData.submenuData.length > 0 ? this.addCardData(this.templateData.submenuData) : this.noData = true;
    this.activeData(this.selectedVal)
  }

  //sample data
  sampleDataAllFields = [{
    Roles: 'All/RoleName'
    , GroupName: 'Any text'
    , IsGroup: '1'
    , GroupColor: ''
    , TagName: 'Any text'
    , TagValue: ''
    , SequenceOrder: '1'
    , FontColor: 'Green'
    , CaseType: 'Dermatology/ALL'
    , CaseStatus: 'Pending Sign-Out,Accession/ALL'
    , AccountId: '20029/-1/ALL'
    , IsActive: '0/1'
    , AllowBulkEdit: '0/1'
    , NotEditableCaseStatus: 'Accession/Grossing/Processing/ALL'
    , IsDefault: ''
    , TagValueFontColor: ''
    , Color: ''
    , Result: 'Creates Group'
  }, {
    Roles: 'All/RoleName'
    , GroupName: 'Any text'
    , IsGroup: '0'
    , GroupColor: ''
    , TagName: 'Transcription Status'
    , TagValue: 'Yes'
    , SequenceOrder: '1'
    , FontColor: 'Green'
    , CaseType: 'Dermatology/ALL'
    , CaseStatus: 'Pending Sign-Out,Accession/ALL'
    , AccountId: '20029/-1/ALL'
    , IsActive: '0/1'
    , AllowBulkEdit: '0/1'
    , NotEditableCaseStatus: 'Accession/Grossing/Processing/ALL'
    , IsDefault: '0/1'
    , TagValueFontColor: ''
    , Color: ''
    , Result: 'Creates Tag and Tag values under the selected group name'
  }, {
    Roles: 'All/RoleName'
    , GroupName: ''
    , IsGroup: '0'
    , GroupColor: ''
    , TagName: 'Transcription Status'
    , TagValue: 'Yes'
    , SequenceOrder: '1'
    , FontColor: 'Green'
    , CaseType: 'Dermatology/ALL'
    , CaseStatus: 'Pending Sign-Out,Accession/ALL'
    , AccountId: '20029/-1/ALL'
    , IsActive: '0/1'
    , AllowBulkEdit: '0/1'
    , NotEditableCaseStatus: 'Accession/Grossing/Processing/ALL'
    , IsDefault: '0/1'
    , TagValueFontColor: ''
    , Color: ''
    , Result: 'Creates Tag and Tag values'
  }]

  sampleDataMandatoryFields = [{
    Roles: 'All/RoleName'
    , GroupName: 'Any text'
    , IsGroup: '1'
    , TagName: 'Transcription Status'
    , CaseType: 'Dermatology/ALL'
    , CaseStatus: 'Pending Sign-Out,Accession/ALL'
    , AccountId: '20029/-1/ALL'
    , TagValue: ''
    , Result: 'Creates Group'
  },
  {
    Roles: 'All/RoleName'
    , GroupName: 'Any text'
    , IsGroup: '0'
    , TagName: 'Transcription Status'
    , CaseType: 'Dermatology/ALL'
    , CaseStatus: 'Pending Sign-Out,Accession/ALL'
    , AccountId: '20029/-1/ALL'
    , TagValue: 'Yes/No'
    , Result: 'Creates Tag and Tag values under selected Group'
  },
  {
    Roles: 'All/RoleName'
    , GroupName: ''
    , IsGroup: '0'
    , TagName: 'Transcription Status'
    , CaseType: 'Dermatology/ALL'
    , CaseStatus: 'Pending Sign-Out,Accession/ALL'
    , AccountId: '20029/-1/ALL'
    , TagValue: 'Yes/No'
    , Result: 'Creates Tag and Tag values'
  }]

  loadUploadScreen() {
    this.chipDataOpen = false;
    this.noData = false;
    this.uploadClicked = true;
    this.copyDataClicked = false;
    this.enableInsert = false;
    this.gridShow = false;
    this.bulkUpload = true;
    this.gridwidth = 0
    let queryVariable = { tablename: 'organizationtags', identity: "false", nullable: "false" };
    let query = this.SubMenuCardModel.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        this.mandatoryFields[0]["Roles"] = ''
        this.mandatoryFields[0]["GroupName"] = ''
        this.mandatoryFields[0]["IsGroup"] = ''
        this.mandatoryFields[0]["TagName"] = ''
        this.mandatoryFields[0]["CaseType"] = ''
        this.mandatoryFields[0]["CaseStatus"] = ''
        this.mandatoryFields[0]["AccountId"] = ''
        this.mandatoryFields[0]["TagValue"] = ''
        this.allFields[0]["Roles"] = ''
        this.allFields[0]["GroupName"] = ''
        this.allFields[0]["IsGroup"] = ''
        this.allFields[0]["GroupColor"] = ''
        this.allFields[0]["TagName"] = ''
        this.allFields[0]["TagValue"] = ''
        this.allFields[0]["SequenceOrder"] = ''
        this.allFields[0]["FontColor"] = ''
        this.allFields[0]["CaseType"] = ''
        this.allFields[0]["CaseStatus"] = ''
        this.allFields[0]["AccountId"] = ''
        this.allFields[0]["IsActive"] = ''
        this.allFields[0]["AllowBulkEdit"] = ''
        this.allFields[0]["NotEditableCaseStatus"] = ''
        this.allFields[0]["IsDefault"] = ''
        this.allFields[0]["TagValueFontColor"] = ''
        this.allFields[0]["Color"] = ''
      }
    }, error => {
      console.log(error);
    });
  }

  checkdisable() {
    if (this.listCasetype.length > 0 || this.listStatus.length > 0 || this.listRoles.length > 0) {
      return false
    } else {
      return true
    }
  }

  handleDataChange(key: string, updatedData: any[]) {
    switch (key) {
      case 'listCasetype':
        this.listCasetype = updatedData;
        break;
      case 'listStatus':
        this.listStatus = updatedData;
        break;
      case 'listRoles':
        this.listRoles = updatedData;
        break;
    }
    this.checkdisable()
  }


  mapChipsToFilter(mainData, removeData, typeName) {
    mainData.find(va => {
      va.checked = false
      removeData.forEach(val => {
        val[typeName] == va[typeName] ?
          va.checked = true :
          va.checked = va.checked
      })
    })
  }

  removeChip(event, key, item): void {
    switch (key) {
      case 'listCasetype':
        this.removeIndex = this.updatedCasetypelist.findIndex((casetype) => casetype.casetype.toLowerCase() === item.casetype.toLowerCase());
        this.updatedCasetypelist = this.removeItem(this.updatedCasetypelist);
        this.caseTypeChild?.selectedData({ checked: false }, item, false, 'casetype', this.listCasetype)
        this.listCasetype = cloneDeep(this.updatedCasetypelist)
        this.mapChipsToFilter(this.CasetypeDataList, this.updatedCasetypelist, 'casetype');
        break;
      case 'listStatus':
        this.removeIndex = this.updatedCaseStatuslist.findIndex((status) => status.item.toLowerCase() === item.item.toLowerCase());
        this.updatedCaseStatuslist = this.removeItem(this.updatedCaseStatuslist);
        this.caseStatusChild?.selectedData({ checked: false }, item, false, 'item', this.listStatus)
        this.listStatus = cloneDeep(this.updatedCaseStatuslist)
        this.mapChipsToFilter(this.caseStatusDataList, this.updatedCaseStatuslist, 'item');
        break;
      case 'listRoles':
        this.removeIndex = this.updatedRolelist.findIndex((role) => role.displayname.toLowerCase() === item.displayname.toLowerCase());
        this.updatedRolelist = this.removeItem(this.updatedRolelist);
        this.roleChild?.selectedData({ checked: false }, item, false, 'displayname', this.listRoles)
        this.listRoles = cloneDeep(this.updatedRolelist)
        this.mapChipsToFilter(this.RoleList, this.updatedRolelist, 'displayname');
        break;
    }
    this.applySelectedData();
  }

  removeItem(arr) {
    if (this.removeIndex >= 0) {
      arr.splice(this.removeIndex, 1);
    }
    return arr;
  }

  applyChanges(): void {
    this.isFlag = false;
    this.xpandStatus = true;
    this.updatedCasetypelist = [];
    this.updatedCaseStatuslist = [];
    this.updatedRolelist = [];
    this.updatedCasetypelist = cloneDeep(this.listCasetype);
    this.updatedCaseStatuslist = cloneDeep(this.listStatus);
    this.updatedRolelist = cloneDeep(this.listRoles);
    this.applySelectedData();
  }

  applySelectedData() {
    let cardData = this.cardDatabackup.filter((value) => {
      return (
        (this.updatedCasetypelist.length > 0 ?
          this.updatedCasetypelist.some((casetype) => {
            if (value.casetype) {
              const casetypeArray = value.casetype.includes(',') ? value.casetype.split(',').map(item => item.trim().toLowerCase()) : value.casetype.toString().trim().toLowerCase();
              if (casetype.casetype !== undefined && casetypeArray !== null) {
                return (Array.isArray(casetypeArray) ? casetypeArray.includes(casetype.casetype.trim().toLowerCase()) : casetypeArray == casetype.casetype.trim().toLowerCase()) || (value.casetype !== undefined && value.casetype.toLowerCase().includes('all'));
              }
            }
            return true;
          })
          : true) &&
        (this.updatedCaseStatuslist.length > 0 ?
          this.updatedCaseStatuslist.some((status) => {
            if (value.casestatus) {
              const casestatusArray = value.casestatus.includes(',') ? value.casestatus.split(',').map(item => item.trim().toLowerCase()) : value.casestatus.toString().trim().toLowerCase();
              if (status.item !== undefined && casestatusArray !== null) {
                return (Array.isArray(casestatusArray) ? casestatusArray.includes(status.item.trim().toLowerCase()) : casestatusArray == status.item.trim().toLowerCase()) || (value.casestatus !== undefined && value.casestatus.toLowerCase().includes('all'));
              }
            }
            return true;
          })
          : true) &&

        (this.updatedRolelist.length > 0 ?
          this.updatedRolelist.some((role) => {
            if (value.roles) {
              const rolesArray = value.roles.includes(',') ? value.roles.split(',').map(item => item.trim().toLowerCase()) : value.roles.toString().trim().toLowerCase();
              if (role.displayname !== undefined && rolesArray !== null) {
                return (Array.isArray(rolesArray) ? rolesArray.includes(role.displayname.trim().toLowerCase()) : rolesArray == role.displayname.trim().toLowerCase()) || (value.displayname !== undefined && value.displayname.toLowerCase().includes('all'));
              }
            }
            return true;
          })
          : true)
      );
    });
    cardData = this.selectedVal.match(/active/i) ? cardData.filter(va => va.isactive) : cardData
    this.listGroupData(cardData)
    if (this.cardData && this.cardData.length > 0) {
      this.isFlagNodata = false;
    } else {
      this.isFlagNodata = true;
    }
  }

  public onValChange(val: string) {
    if (val.toLowerCase() == 'active') {
      this.isAllSelected = false
    } else {
      this.isAllSelected = true
    }
    this.selectedVal = val;
  }

  clearAll() {
    this.CasetypeDataList = [...this.CasetypeDataList];
    this.caseStatusDataList = [...this.caseStatusDataList];
    this.RoleList = [...this.RoleList];
    this.updatedCasetypelist = [];
    this.updatedCaseStatuslist = [];
    this.updatedRolelist = [];
    this.listCasetype = [];
    this.listStatus = [];
    this.listRoles = [];
    this.checkdisable();
    this.selectedVal.toLowerCase() == 'active' ? this.activeData('active') : this.activeData('')
    this.chipDataOpen = false;
  }

  tagColor(value, item) {
    if (item.tagvalue) {
      let tagArray = item.tagvalue.split(',')
      let tagIndex = tagArray.findIndex(va => va == value);
      switch (true) {
        case item.tagvaluefontcolor !== null && item.color !== null:
          return {
            'background-color': item.color.split(',')[tagIndex],
            'color': item.tagvaluefontcolor.split(',')[tagIndex]
          };
        case item.groupcolor !== null && item.fontcolor !== null:
          return {
            'background-color': item.groupcolor.split(',')[tagIndex],
            'color': item.fontcolor.split(',')[tagIndex]
          }
        default:
          return {
            'background-color': '#FFF853',
            'color': '#000000'
          }
      }
    }
    return {
      'background-color': '#FFF853',
      'color': '#000000'
    }
  }

  // card data on load
  listData() {
    let groupData = this.templateData.submenuData ? this.templateData.submenuData : [];
    this.isFlagNodata = groupData.length > 0 && groupData !== null ? false : true;
    this.listGroupData(groupData);
  }

  // Assuming Child interface or type definition
  sortTagValues(childList: Child[]): Child[] {
    return childList.map(child => {
      if (child.tagvaluesequence) {
        const orgArr = child.tagvaluesequence.split(',');
        const tagvalueSequence = [...orgArr].sort((a, b) => Number(a) - Number(b));

        const color: string[] = [];
        const isdefault: string[] = [];
        const tagvaluefontcolor: string[] = [];
        const organizationcasetagvalueid: string[] = [];
        const tagvalue: string[] = [];

        tagvalueSequence.forEach(va => {
          const index = orgArr.findIndex(val => val === va);
          organizationcasetagvalueid.push(child.organizationcasetagvalueid.split(',')[index]);
          tagvalue.push(child.tagvalue.split(',')[index]);
          tagvaluefontcolor.push(child.tagvaluefontcolor ? child.tagvaluefontcolor.split(',')[index] : null);
          color.push(child.color ? child.color.split(',')[index] : null);
          isdefault.push(child.isdefault.split(',')[index]);
        });

        return {
          ...child,
          tagvaluesequence: tagvalueSequence.join(','),
          organizationcasetagvalueid: organizationcasetagvalueid.join(','),
          tagvalue: tagvalue.join(','),
          tagvaluefontcolor: tagvaluefontcolor.join(','),
          color: color.join(','),
          isdefault: isdefault.join(',')
        };
      }
      return child;
    });
  }

  listGroupData(groupData) {
    let groupDataList = groupData.filter(list => list.groupid == null);
    for (let i = 0; i < groupDataList.length; i++) {
      groupDataList[i]['childList'] = groupData.filter(list => list.groupid == groupDataList[i].organizationtagid);
      groupDataList[i]['childList'] = this.sortTagValues(groupDataList[i]['childList']);
      groupDataList[i]['childList'] = groupDataList[i]['childList'].sort((a, b) => a.sequenceorder - b.sequenceorder)
    }
    groupDataList = this.sortByDate(groupDataList)
    this.cardData = groupDataList;
  }

  // sort by created date as well as modified date
  sortByDate(groupDataList) {
    groupDataList.sort((a, b) => {
      var createdDateA = parse(a.createddate, 'MM/dd/yyyy hh:mm:ss a', new Date());
      var modifiedDateA = parse(a.modifieddate, 'MM/dd/yyyy hh:mm:ss a', new Date());
      var latestDateA: any = createdDateA > modifiedDateA ? createdDateA : modifiedDateA;

      var createdDateB = parse(b.createddate, 'MM/dd/yyyy hh:mm:ss a', new Date());
      var modifiedDateB = parse(a.modifieddate, 'MM/dd/yyyy hh:mm:ss a', new Date());
      var latestDateB: any = createdDateB > modifiedDateB ? createdDateB : modifiedDateB;

      return latestDateA - latestDateB;
    });
    // groupDataList.sort(function (a, b) {
    //   var c = parse(a.createddate, 'MM/dd/yyyy hh:mm:ss a', new Date());
    //   var d = parse(b.createddate, 'MM/dd/yyyy hh:mm:ss a', new Date());
    //   var e = parse(a.modifieddate, 'MM/dd/yyyy hh:mm:ss a', new Date());
    //   var f = parse(a.modifieddate, 'MM/dd/yyyy hh:mm:ss a', new Date());
    //   var g = c > e ? c : e
    //   var h = d > f ? d : f
    //   return g > h ? 1 : -1
    // });
    // let recentCreatedDate = groupDataList[0].createddate;
    return groupDataList;
  }

  activeData(isValue) {
    if (isValue != '') {
      this.cardData = this.cardDatabackup.filter((value) => value.groupid == null && value.isactive == true)
    } else {
      this.cardData = this.cardDatabackup.filter((value => value.groupid == null));
    }
  }

  async getOrgTaglist(context?) {
    let query = '';
    let queryVariable = {};
    query = this.SubMenuCardModel.GetQuery('getorganizationtags');
    queryVariable = {
      orgid: sessionStorage.getItem('org_id').toString()
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    let data = await this.VitalHttpServices.GetData(queryResult).toPromise();
    if (!data.errors) {
      this.templateData.submenuData = data.data.submenuData;
      if (context) {
        this.listData()
        this.cardShow = true;
        // this.addCardData(this.templateData.submenuData);
      }
    }
    this.ngxService.stop();
  }

  getCasetypeData() {
    return this.CasetypeDataList;
  }

  getCaseStatusData() {
    return this.caseStatusDataList;
  }

  public GetQuery(keyword: string) {
    let mainQueryIndex: number = this.VitalHttpServices.mainQueryList.findIndex(
      (mainQuery) =>
        mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase()
    );
    if (mainQueryIndex > -1)
      return this.VitalHttpServices.mainQueryList[mainQueryIndex].Query;
  }

  async getCasestatusList() {
    // sessionStorage.setItem('deploymentKey', 'configdb');
    let query = this.GetQuery('CategoryDropDown');
    let queryVariable = {
      keyword: 'CaseStatus',
      context: 'OrganizationTags',
    };
    let dbname = sessionStorage.getItem('deploymentKey').toUpperCase();
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let Resdata = await this.VitalHttpServices.GetData(queryResult, "configdb").toPromise();
    // sessionStorage.setItem('deploymentKey', this.VitalHttpServices.deploymentKey);
    if (!Resdata.errors) {
      if (Resdata.data) {
        if (Resdata.data.submenuData.length > 0) {
          let data = JSON.parse(Resdata.data.submenuData[0].Items_JSON);
          this.CaseStatusList = [...data.CaseStatus, { item: "ALL" }]
          this.CaseStatusList.sort((a, b) => a.item < b.item ? -1 : a.item > b.item ? 1 : 0);
          this.CaseNStatusList = cloneDeep(this.CaseStatusList);
          this.caseStatusDataList = [];
          this.nCaseStatusDataList = [];
          this.caseStatusDataList = [...this.CaseStatusList];
          this.caseStatusDataList = this.caseStatusDataList.filter(va => va.item.toLowerCase() != 'all')
          this.nCaseStatusDataList = [...this.CaseNStatusList];

          this.caseStatusDropdownSettings = {
            singleSelection: false,
            idField: 'item',
            textField: 'item',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 4,
            allowSearchFilter: true,
            enableCheckAll: true,
            limitSelection: -1,
            clearSearchFilter: true,
            maxHeight: 150,
            // itemsShowLimit: 44,
            closeDropDownOnSelection: false,
            showSelectedItemsAtTop: false,
            defaultOpen: false,
          };

        }
      }
    }
    // sessionStorage.setItem('deploymentKey', this.VitalHttpServices.deploymentKey);
  }

  async getLookupValues() {
    let queryResult: any;
    let queryVariable;
    let query = this.VitalHttpServices.GetQuery('getorganizationtagentities');
    let Qstr = "";
    Qstr = "associationtype = \"" + this.Orgtype[0]?.associationtype + "\"";
    queryVariable = { "associationtype": Qstr, orgid: this.destOrgid };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let res = await this.VitalHttpServices.GetData(queryResult, this.destDeployment).toPromise();
    if (!res.errors) {
      if (res.data) {
        this.CasetypeDataList = [];
        this.caseStatusDataList = [];
        this.nCaseStatusDataList = [];
        this.AccountsList = [];
        this.CasetypeList = [];
        this.CasetypeList = (res.data.submenuDataCasetypes.length > 0) ? res.data.submenuDataCasetypes : [];
        this.AccountsList = (res.data.submenuDataAccounts.length > 0) ? res.data.submenuDataAccounts : [];
        this.AccountsList.push({ accountid: -1, accountname: 'All' })
        this.CasetypeList = this.CasetypeList && this.CasetypeList.length > 0 ? [...new Map(this.CasetypeList.map(item => [item["casetype"], item])).values()] : [];
        this.CasetypeList.forEach(item => {
          item['checked'] = false;
        });
        this.CasetypeDataList = [...this.CasetypeList];
        this.CasetypeList = res.data.submenuDataCasetypes && res.data.submenuDataCasetypes.length > 0 ? [...new Map(res.data.submenuDataCasetypes.map(item => [item["casetype"], item])).values()] : [];
        this.CasetypeList.sort((a, b) => a.casetype < b.casetype ? -1 : a.casetype > b.casetype ? 1 : 0);
        this.AccountsList.sort((a, b) => a.accountname < b.accountname ? -1 : a.accountname > b.accountname ? 1 : 0);
      }
      else {
        this._snackbar.open('Something went wrong!', 'Close')
      }
    }
    else {
      console.log(res)
    }
  }

  getAccountName(account, event?) {
    if (event && event.source.selected) {
      this.CasetypeList = []
      let casetypeList = this.getCasetypeData();
      if ((account.accountname && account.accountname.toString().toLowerCase().trim() != 'all')
        || (account.accountid && account.accountid != -1)) {
        this.CasetypeList = casetypeList.filter(r => ((r.casetype.toString().toLowerCase().trim() == 'all') || (r.accountid && r.accountid.toString() == account.accountid.toString())))
      } else {
        this.CasetypeList = casetypeList && casetypeList.length > 0 ? [...new Map(casetypeList.map(item => [item["casetype"], item])).values()] : [];
      }
      this.CasetypeList.sort((a, b) => a.casetype < b.casetype ? -1 : a.casetype > b.casetype ? 1 : 0);
      this.selectedAccountid = account.accountid;
      this.selectedCasetypeList = [];
    }
  }

  async getAccountType() {
    let queryResult: any;
    let queryVariable;
    let query = this.VitalHttpServices.GetQuery('getassociationtypeforaccid');
    let Qstr = "";
    Qstr += "accountid = \"" + this.templateData.cardIdentifier.toString() + "\"";
    queryVariable = { "accid": Qstr };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    await this.VitalHttpServices.GetData(queryResult, this.destDeployment).toPromise().then(async res => {
      this.ngxService.stop();
      if (!res.errors) {
        if (res.data.vwGetAssociationtypeByOrgID_list.length > 0) {
          this.Orgtype = res.data.vwGetAssociationtypeByOrgID_list;
          await this.getUserRoles(this.destDeployment);
        }
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }

  async getUserRoles(dbName) {
    let queryResult: any;
    let queryVariable;
    let query = this.VitalHttpServices.GetQuery('getuserrolesforassociationtype');
    let Qstr = "";
    Qstr = "associationtype = \"" + this.Orgtype[0]?.associationtype + "\"";
    queryVariable = { "associationtype": Qstr };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    let res = await this.VitalHttpServices.GetData(queryResult, dbName).toPromise()
    this.ngxService.stop();
    if (!res.errors) {
      if (res.data) {
        if (res.data.vwGetRolesBasedOnAccountAssociation_list.length > 0) {
          this.RoleList = [...res.data.vwGetRolesBasedOnAccountAssociation_list];
        }
      }
      this.RoleList.forEach(item => {
        item['checked'] = false;
      });
      this.RoleList.sort((a, b) => a.displayname < b.displayname ? -1 : a.displayname > b.displayname ? 1 : 0);
    }
  }

  addCardData(templateData: any) {
    this.GroupList = [];
    if (templateData && templateData.length > 0) {
      this.cardData = templateData;
      this.cardDatabackup = JSON.parse(JSON.stringify(templateData))
    }
    this.cardShow = true;
  }

  setAllForData() {
    this.allStatus = [{ item: 'All' }]
    let tempstatus = cloneDeep(this.caseStatusDataList)
    tempstatus = tempstatus.filter(va => va.item.toLowerCase() != 'all')
    this.allStatus = this.allStatus.concat(tempstatus);

    this.allCasetype = [{ casetype: 'All' }]
    let tempscasetype = cloneDeep(this.CasetypeDataList)
    tempscasetype = tempscasetype.filter(va => va.casetype.toLowerCase() != 'all')
    this.allCasetype = this.allCasetype.concat(tempscasetype);


    this.allRoles = [{ displayname: 'All' }]
    let temprole = cloneDeep(this.RoleList)
    temprole = temprole.filter(va => va.displayname.toLowerCase() != 'all')
    this.allRoles = this.allRoles.concat(temprole);


    this.nCasestatus = [{ item: 'All' }]
    this.nCasestatus = this.nCasestatus.concat(cloneDeep(this.selectedNCasestatusList));
  };

  // refresh the dialog popup
  async refreshData(result) {
    await this.getOrgTaglist();
    try {
      this.isEditable = false;
      if (result && result.data.length > 0) {
        this.refreshComponent()
      }
    } catch (error) {
      console.error(error);
    }
  }

  // create casetags
  createCardPopup() {
    this.setAllForData();
    let dialogRef = this.dialog.open(CaseTagsDialogComponent, {
      disableClose: true,
      width: '1200px',
      maxWidth: '90vw',
      minWidth: '90vw',
      data: {
        header: "Case Tags", message: "Categories", alert: "", continue: "Create", isPopupFlag: "", cancel: "No",
        CasetypeDataList: JSON.parse(JSON.stringify(this.allCasetype)),
        caseStatusDataList: JSON.parse(JSON.stringify(this.allStatus)),
        RoleList: JSON.parse(JSON.stringify(this.allRoles)),
        CaseNStatusList: this.CaseNStatusList,
        templateData: this.templateData, Orgtype: this.Orgtype
      }
    })
    return dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.refreshData(result);
      }
    });
  }

  // edit casetags
  editCardPopup(value, cardData) {
    if (this.editBtn) {
      return;
    }
    this.setAllForData();
    this.exixtingCaseType = this.separateChip(value, 'casetype');
    this.exixtingCasestatus = this.separateChip(value, 'casestatus');
    this.exixtingRoles = this.separateChip(value, 'roles');
    this.existingnCasestatus = this.separateChip(value, 'ncasestatus');
    this.modifyCategory();
    let dialogRef = this.dialog.open(CaseTagsDialogComponent, {
      disableClose: true,
      width: '1200px',
      maxWidth: '90vw',
      minWidth: '90vw',
      data: {
        header: "Case Tags", message: "Categories", alert: "", continue: "Update", isPopupFlag: "", cancel: "No",
        exixtingCaseType: this.exixtingCaseType,
        exixtingCasestatus: this.exixtingCasestatus,
        exixtingRoles: this.exixtingRoles,
        CasetypeDataList: JSON.parse(JSON.stringify(this.allCasetype)),
        caseStatusDataList: JSON.parse(JSON.stringify(this.allStatus)),
        RoleList: JSON.parse(JSON.stringify(this.allRoles)),
        CaseNStatusList: this.CaseNStatusList,
        existingnCasestatus: this.existingnCasestatus,
        currentData: value,
        entireData: cardData,
        templateData: this.templateData, Orgtype: this.Orgtype
      }
    })
    return dialogRef.afterClosed().subscribe(async result => {
      if (result !== undefined) {
        this.refreshData(result);
      }
    });
  }

  modifyCategory() {
    if (this.exixtingCaseType && this.exixtingCaseType.length > 0) {
      this.typeName = 'casetype';
      this.allCasetype.forEach(item => {
        item['checked'] = false;
      });
      let caseTypeList = this.selectedCategory(this.allCasetype, this.exixtingCaseType);
      this.allCasetype = caseTypeList;
    }
    if (this.exixtingCasestatus && this.exixtingCasestatus.length > 0) {
      this.typeName = 'item';
      this.allStatus.forEach(item => {
        item['checked'] = false;
      });
      let caseStatusList = this.selectedCategory(this.allStatus, this.exixtingCasestatus);
      this.allStatus = caseStatusList;
    }
    if (this.exixtingRoles && this.exixtingRoles.length > 0) {
      this.typeName = 'displayname';
      this.allRoles.forEach(item => {
        item['checked'] = false;
      });
      let caseRoleList = this.selectedCategory(this.allRoles, this.exixtingRoles);
      this.allRoles = caseRoleList;
    }

    if (this.existingnCasestatus && this.existingnCasestatus.length > 0) {
      //this.typeName = 'item';
      this.nCasestatus.forEach(item => {
        item['checked'] = false;
      });
      let nCasestatus = this.selectedCategory(this.allRoles, this.existingnCasestatus);
      this.nCasestatus = nCasestatus;
    }
  }

  selectedCategory(dataOld, dataNew) {
    let newData = dataOld.map(element => {
      dataNew.forEach(newElement => {
        if (element[this.typeName].toLowerCase() === newElement.toLowerCase()) {
          element['checked'] = true;
        }
      });
      return element;
    });
    return newData;
  }

  separateChip(val, type) {
    if (val !== null && val !== '') {
      switch (type) {
        case 'casetype':
          return val.casetype && val.casetype.split(',').map(casetype => casetype);
          break;

        case 'casestatus':
          return val.casestatus && val.casestatus.split(',').map(casestatus => casestatus);
          break;

        case 'roles':
          return val.childList[0]?.roles && val.childList[0].roles.split(',').map(roles => roles);
          break;

        // case 'ncasestatus':
        //   return val.noteditablecasestatus && val.noteditablecasestatus.split(',');
        //   break;

        default:
          break;
      }
    } else {
      return []
    }
  }

  checkCardData(cardData) {
    let groupData = cardData.filter(va => va.groupname && va.tagname)
    return groupData && groupData.length == 0
  }

  ExportUpload(_data, type?) {
    let flex = _data ? _data : this.organizationtags;
    const view: any = flex.collectionView;
    // let oldPgSize = view.pageSize
    // view.pageSize = 0;
    flex.beginUpdate();
    let rows = cloneDeep(view._src);
    rows.find((e: any): any => {
      delete e['Action'];
      delete e['organizationid']
      delete e['slno']
      delete e['copyflag']
      delete e['tablename']
      delete e['associationtype']
      delete e['createdby']
      delete e['_path']
      delete e['_items']
      delete e['_groups']
      delete e['_level']
      delete e['_isBottomLevel']
      delete e['_name']
      delete e['_gd']
      if (this.copyDataClicked) {
        delete e['accountname']
      }
    });
    let filename = 'Case Tags_' + this.templateData.secondarykeys.OrganizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(rows);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + 'Organization Tags');
    XLSX.writeFile(wb, filename);
    flex.endUpdate();
  }

  approveDisable() {
    let existsCount = 0;
    if (!this.copyDataClicked) {
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if ((this.excelDataArray[i]['notes'] && (this.excelDataArray[i]['notes'].toString().match(/Invalid/i)))
          || (this.excelDataArray[i]['notes'] && this.excelDataArray[i]['notes'].toString().match(/mandatory/i))
          || (this.excelDataArray[i]['notes'] && this.excelDataArray[i]['notes'].toString().match(/already exists/i))) {
          existsCount++;
        }
      }
      if (existsCount > 0) {
        return true
      }
    }
    else {
      if (this.selectedData.length == 0) {
        return true
      }
    }
    return false;
  }

  // Grid column validation message color
  formateItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value == 'Mandatory field is missing!' || value == 'Null' || value.toString().includes('does not exist')
          || value.toString().toLowerCase().includes('invalid')
          || value.toString().includes('Tag & Tag values already exists')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().includes('successfully') || value == 'Success'
          || value.toString().includes('will be added')
          || value.toString().includes('on approval')) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if (value.toString().includes('already exists') || (value.toString().includes('already exist'))
          || (value.toString().includes('Ignored'))) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
    }
  }


  // export data
  ExportExcel(data, type?) {
    const excel = [];
    for (const tagGroup of data) {
      const itemsToPush = tagGroup.childList && tagGroup.childList.length > 0
        ? tagGroup.childList
        : [tagGroup];

      for (const item of itemsToPush) {
        excel.push(cloneDeep(item));
      }
    }
    let rows = cloneDeep(excel);
    rows.forEach((e: any): any => {
      delete e['organizationid']
      delete e['Action'];
      delete e['slno']
      delete e['copyflag']
      delete e['tablename']
      delete e['associationtype']
      delete e['createdby']
      delete e['_path']
      delete e['_items']
      delete e['_groups']
      delete e['_level']
      delete e['_isBottomLevel']
      delete e['_name']
      delete e['_gd']
      delete e['noteditablecasestatus']
      delete e['isdefault']
      delete e['tagvaluefontcolor']
      delete e['tagvaluesequence']
      delete e['organizationcasetagvalueid']
      delete e['type']
      delete e['groupid']
      delete e['color']
      delete e['AccountId']
      delete e['allowbulkedit']
      delete e['accountname']
    })
    let filename = 'Case Tags_' + this.templateData.secondarykeys.OrganizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(rows);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, '' + 'Organization Case Tags');
    XLSX.writeFile(wb, filename);
  }


  // upload
  downloadTemplate(type) {
    let filename = ""
    let excel = [{}];
    if (type == 'allFieldsCheck') {
      filename = 'Case Tags_' + 'All Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      excel = this.allFields
      var ws = XLSX.utils.json_to_sheet(excel);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationTags');
      ws = XLSX.utils.json_to_sheet(this.sampleDataAllFields)
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      XLSX.writeFile(wb, filename);
    }
    else if (type == 'mandatoryFieldsCheck') {
      filename = 'Case Tags_' + 'Minimal Fields_' + this.templateData.secondarykeys.OrganizationId.toString() + '.xlsx';
      excel = this.mandatoryFields;
      var ws = XLSX.utils.json_to_sheet(excel);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "" + 'OrganizationTags');
      ws = XLSX.utils.json_to_sheet(this.sampleDataMandatoryFields)
      XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData')
      XLSX.writeFile(wb, filename);
    }
  }

  approveOrgTags(copyData?) {
    this.postUpload = false;
    this.copyFlag = copyData;
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      if (!copyData) {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i]["notes"] == "") {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
          else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) || this.excelDataArray[i]["notes"].toString().includes('does not exist')) {
            errorcount++
          }
        }
      }

      if ((this.showInvalidColumns)) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          for (let [key, value] of Object.entries(this.excelDataArray[i])) {
            if (value == "null" || value == "" || value == null) {
              this.excelDataArray[i][key] = null;
            }
            else {
              this.excelDataArray[i][key] = value.toString();
            }
          }
          this.excelDataArray[i]["allowbulkedit"] = !!this.excelDataArray[i]?.allowbulkedit && ['false','0'].includes(this.excelDataArray[i]?.allowbulkedit);
        }
      }
      let selectedData = []
      for (let i = 0; i < this.selectedData.length; i++) {
        if (this.copyDataClicked) {
          selectedData.push(this.selectedData[i]._data);
        }
        else {
          selectedData.push(this.selectedData[i]);
        }
      }
      if (selectedData.some(va => !va.accountid || !va.groupname || !va.tagname || !va.casetype || !va.casestatus)) {
        this._snackbar.open('Some of the mandatory data is missing!', 'Close');
        return
      }
      if (copyData) {
        this.selectedCopyData = selectedData;
        for (let i = 0; i < this.selectedCopyData.length; i++) {
          this.selectedCopyData[i]["copyflag"] = true;
          this.bulkUpload = false;
        }
        this.excelDataArray = this.selectedCopyData
        // if (this.excelDataArray.every(va => va.notes.toString().toLowerCase().match(/already exists/i))) {
        //   this._snackbar.open("All selected records already exist!", "Close");
        //   this.bulkUpload = true;
        //   return;
        // }
      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      // copyData ? dataArray.some(va => va.notes.toString().toLowerCase().match(/already exists/i)) ? this._snackbar.open("Some selected " + this.templateData.menuURL.toString().toLowerCase() + "s already exist", "Close") : null : null
      let context = copyData ? null : ''
      this.addOrganizationTagsMethods(dataArray, this.bulkUpload, context)
    }
  }

  // Copy
  onFileChange(ev) {
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'organizationtags')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();
          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }

  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    if (result) {
      this.convertToJson(result)
    }
  }

  // cancel copy and upload
  cancelGrid() {
    this.gridwidth = null;
  }

  cancelUpload() {
    this.gridwidth = 0;
    this.showInvalidColumns = false;
  }

  selectedCopyDataOrganization(event, data) {
    if (event.source.selected && Object.keys(data).length > 0) {
      this.copyOrgTagsMethodForm.patchValue({
        frmOrgID: data.organizationid,
        frmOrganization: data.organizationname
      })
      this.srcOrgid = data.organizationid;
      this.searchInput = data.organizationname
      this.getUserRoles(this.destDeployment);
    }
  }

  removeGrid() {
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.postUpload = false;
    this.backBtn = true;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.showDelete = true;
  }

  selectedCopyDataUser(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.gridWidth = -1;
      this.usertoggle = false;
      this.srcRole = data.displayname;
      this.searchUserInput = data.displayname;
    }
  }

  initGrid(grid, hitCount?) {
    let tempData = [];
    tempData = this.excelDataArray && this.excelDataArray.length > 0 ? [...this.excelDataArray] : [];
    if (hitCount) {
      if (!this.postUpload) {
        this.selector = new Selector(grid, {
          itemChecked: () => {
            this.selectedData = grid.rows.filter(r => r.isSelected);
          }
        });
      }
    }
    if (this.copyDataClicked) {
      for (let i = 0; i < this.selectedData.length; i++) {
        if (!this.selectedData[i]._data.groupid) {
          if (tempData && tempData.length > 0) {
            for (let j = 0; j < tempData.length; j++) {
              if (tempData[j].groupid && tempData[j].groupid == this.selectedData[i]._data.organizationtagid) {
                this.excelDataArray.push(tempData[j])
              }
              else {
                this.excelDataArray.push(this.selectedData[i]._data);
              }
            }
          }
        }
      }
    }

    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      const col = e.getColumn(),
        row = e.getRow();
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }

  gridRemove() {
    this.postUpload = false;
    this.showInvalidColumns = false;
    this.noData = false;
    this.searchResult = []
    this.gridwidth = 0
    this.copyOrgTagsMethodForm.reset();
    // this.copyOrgTagsMethodForm.controls.frmOrganization.enable()
    this.copyOrgTagsMethodForm.patchValue({
      frmOrganization: '',
      frmDepKey: sessionStorage.getItem('deploymentKey').toUpperCase(),
      // frmOrgID: [''],
      // frmRole: [''],
      frmcpyasinactive: false
    })
    this.searchInput = ''
    this.gridData = new CollectionView([]);
  }

  checkValue(event) {
    if (event.target.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }

  // region Tooltip for the wijmo grid
  initTooltipGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
  }

  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    this.selectedData = this.excelDataArray
    this._snackbar.open('Deleted successfully', 'Close');
    if (grid.rows.length == 0) {
      this.gridRemove();
    }
  }

  convertToJson(sheetname, copyfrmorg: boolean = false, copyData: object[] = []) {
    var worksheet;
    !copyfrmorg ? this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    }) : null;
    this.excelDataArray = [];
    let tempExcelArr = [];
    tempExcelArr = !copyfrmorg ? XLSX.utils.sheet_to_json(worksheet, { defval: null }) : copyData;
    if (tempExcelArr.length == 0) {
      !copyfrmorg ? this._snackbar.open("The uploaded excel does not contain any data", "Close") : null;
      tempExcelArr = [];
      return;
    }
    let primary = {}
    let tempArray = []
    if (!this.copyFlag) {
      for (let i = 0; i < tempExcelArr.length; i++) {
        for (let [key, value] of Object.entries(tempExcelArr[i])) {
          if (!key.toString().match(/empty/i)) {
            if (key.toString().match(/isgroup/i)) {
              if (!value || value.toString() === '0') {
                value = 0;
              }
              else {
                value = 1;
              }
            }
            if (key == 'IsActive') {
              if (value == 1) {
                value = true;
              }
              else if (value == 0) {
                value = false;
              } else {
                value = true;
              }
              primary[key] = value;
            }
            else {
              if (value == null) {
                value = null;
              }
              else if (value == '') {
                value = ''
              }
              else if (value.toString().match(/null/i)) {
                value = 'null'
              }
              else if (copyfrmorg && key == 'OrganizationTagId') {
                value = null;
              }
            }
            primary[key] = value;
          }
        }
        tempArray.push(primary)
        primary = {}
      }
      tempExcelArr = tempArray;
    }
    this.sheetHeader = [];
    let temp = [];
    temp = !copyData ? XLSX.utils.sheet_to_json(worksheet, { header: 1 }) : Object.keys(tempExcelArr[0]);
    if (!copyData) {
      temp[0].reverse()
      temp[0].push("Notes")
      this.sheetHeader = temp[0];
    }
    else {
      temp.reverse()
      temp.push("Notes")
      this.sheetHeader = temp;
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    this.excelDataArray = []

    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    let i = 1;
    this.excelDataArray.find(d => {
      Object.assign(d, { slno: i, notes: '', tablename: 'organizationtags', organizationid: this.templateData.secondarykeys.OrganizationId.toString() });
      i++;
    });
    this.validateExcel(this.excelDataArray);
  }

  ExcelGridData(data) {
    this.ngxService.start()
    this.noData = false;
    this.copyFlag = null;
    this.gridDisplay = true;
    let gridArray = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (!data[i]["status"] || data[i]["status"] == '') {
            data[i]["status"] = 'Ignored'
          }
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().toLowerCase().match(/empty/i)) {
              if (key == 'isgroup') {
                if (!value || value.toString() == '0') {
                  value = false;
                }
                else {
                  value = true;
                }
              }
              if (key == 'isactive') {
                if (value == 1) {
                  value = true;
                }
                else if (value == 0) {
                  value = false;
                } else //if(value != 0 && (value == null || value ==''))
                {
                  value = true;
                }
                primary[key] = value;
              }
              if (key.toString().toLowerCase() == 'allowbulkedit') {
                if (!value || value.toString() == 'false' || value.toString().toLowerCase() == 'not specified') {
                  value = false;
                }
                else {
                  value = true;
                }
                primary[key] = value;
              }
              if (!this.copyDataClicked) {
                if (key && key.toString().toLowerCase().trim() == 'accountid' || key.toString().toLowerCase().trim() == 'groupname' || key.toString().toLowerCase().trim() == 'tagname' || key.toString().toLowerCase().trim() == 'casetype' || key.toString().toLowerCase().trim() == 'casestatus') {
                  if (!value || value.toString() == '') {
                    primary['notes'] = 'Mandatory field is missing!'
                  }
                  else {
                    primary[key] = value;
                  }
                }
              }
            }
            primary[key] = value;
          }
          gridArray.push(primary);
        }
        this.excelDataArray = gridArray;
        this.showDelete = true
        if (this.postUpload) {
          this.showDelete = false
          this.selector ? this.selector.column.grid.headersVisibility = HeadersVisibility.Column : null
        }
        if (!this.copyDataClicked) {
          this.selectedData = gridArray;
        }
        this.gridData = new CollectionView(gridArray, { groupDescriptions: ['groupname'] });
        this.gridwidth = (170 * this.sheetHeader.length) + 37;
        if (this.gridwidth > 1300) {
          this.gridwidth = 1300;
        }
      }
      else {
        this.gridwidth = 0;
      }
    } else {
      this.gridwidth = 0;
    }
    this.ngxService.stop();
  }

  approveCopyOrgTags(copyData?) {
    this.postUpload = false;
    this.copyFlag = copyData;
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      if (copyData) {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          if (this.excelDataArray[i]["notes"] == "") {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
            return;
          }
          else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && !this.excelDataArray[i]["notes"].toString().match(/will be added/i) && this.excelDataArray[i]["notes"].toString().match(/already exists/i)) {
            errorcount++
          }
        }
      }
      for (let i = 0; i < this.excelDataArray.length; i++) {
        for (let [key, value] of Object.entries(this.excelDataArray[i])) {
          if (value == "null" || value == "" || value == null) {
            this.excelDataArray[i][key] = null;
          }
          else {
            this.excelDataArray[i][key] = value.toString();
          }
        }
        this.excelDataArray[i]["allowbulkedit"] = !!this.excelDataArray[i]?.allowbulkedit && ['false','0'].includes(this.excelDataArray[i]?.allowbulkedit);
      }

      let selectedData = []
      for (let i = 0; i < this.selectedData.length; i++) {
        if (this.copyDataClicked) {
          selectedData.push(this.selectedData[i]._data);
        }
        else {
          selectedData.push(this.selectedData[i]);
        }
      }
      if (copyData) {
        this.selectedCopyData = selectedData;
        for (let i = 0; i < this.selectedCopyData.length; i++) {
          this.selectedCopyData[i]["copyflag"] = true;
          this.bulkUpload = false;
        }
        this.excelDataArray = this.selectedCopyData
        if (this.excelDataArray.every(va => va.notes.toString().toLowerCase().match(/already exists/i))) {
          this._snackbar.open("All selected records already exist!", "Close");
          this.bulkUpload = true;
          return;
        }
      }
      let dataArray = [];
      dataArray = this.convertObjKeysToLower(this.excelDataArray);
      copyData ? dataArray.some(va => va.notes.toString().toLowerCase().match(/already exists/i)) ? this._snackbar.open("Some selected " + this.templateData.menuURL.toString().toLowerCase() + "s already exist", "Close") : null : null
      this.addOrganizationTagsMethods(dataArray, this.bulkUpload)
    }
  }

  // audit the details for activity tracker
  auditDetails(oldObj, newObjArray, info) {
    let msgActivityTracker = '';
    msgActivityTracker = (info == 'updated') ? 'Updated' : 'Added';

    if (Object.keys(oldObj).length > 0) {
      /*Update record*/
      this.ngxService.start();
      this.commonService.createActivityTracker(msgActivityTracker, oldObj['organizationtagid'], this.templateData.menuURL.toString().replace(/\s/g, ""), 'Audit', newObjArray[0] == undefined ? newObjArray : newObjArray[0], oldObj);
      this.ngxService.stop();
    } else {
      /*New records*/
      this.ngxService.start();
      for (let i = 0; i < newObjArray.length; i++) {
        this.commonService.createActivityTracker(info, newObjArray[i].ID, this.templateData.menuURL.toString().replace(/\s/g, ""), 'Audit', newObjArray[i], {});
      }
      this.ngxService.stop();
    }
  }

  resetForm(val?) {
    this.caseTagForm.reset();
    const formArray = new FormArray([]);

    this.caseTagForm.patchValue({
      frmTagType: "",
      frmTagGroupName: "",
      frmTagName: "",
      frmGroupColor: "",
      frmFontColor: "",
      frmTagSequence: "",
      frmIsActive: true,
      frmAllowBulkEdit: "",
      frmTagValueGroup: formArray.patchValue([]),
      frmAccountName: "ALL",
      frmCasetype: [{ casetype: "ALL" }],
      frmCaseStatus: [{ item: "ALL" }],
      frmNonEditableCaseStatus: "",
      frmRoles: [{ displayname: "ALL" }]//this.RoleList
    });
    this.caseTagForm.setControl('frmTagValueGroup', this._fb.array([
      this._fb.group({
        frmTagValue: "",
        frmTagValueSequence: "",
        frmTagValueColor: "",
        frmTagValueFontColor: "",
        frmTagValueIsDefault: "",
        frmTagValueId: "",
        frmTagId: "",
        frmGroupId: "",
        frmAccountId: ""
      })]));
    this.selectedCasetypeList = [];
    this.selectedCasestatusList = [];
    this.selectedNCasestatusList = [];
    this.selectedRoleList = [];
    if (val) {
      this.caseTagForm.patchValue({
        frmTagType: val,
      })
    }
  }

  async addOrganizationTagsMethods(dataArray: object[], bulkUpload: boolean, context?) {
    this.ngxService.start();
    let userid = (sessionStorage.getItem('Userid') == null || sessionStorage.getItem('Userid') == '') ? -100 : sessionStorage.getItem('Userid');
    for (let i = 0; i < dataArray.length; i++) {
      if (!this.addEditClicked) {
        dataArray[i]['associationtype'] = this.Orgtype[0].associationtype;
        dataArray[i]['createdby'] = userid;
        dataArray[i]['accountid'] = this.copyDataClicked ? -1 : dataArray[i]['accountid'];
        if (this.copyDataClicked) {
          dataArray[i]["isactive"] = (this.checkStatus ? 0 : 1);
          dataArray[i]['roles'] = (!this.srcRole ? 'ALL' : this.srcRole);
          dataArray[i]['groupname'] = (!dataArray[i]['groupid'] && (!dataArray[i]['isgroup'] || (dataArray[i]['isgroup'] && dataArray[i]['isgroup'].toString() == '0')) ? null : dataArray[i]['groupname']);
        }
        else {
          if ('isactive' in dataArray[i]) {
            dataArray[i]['isactive'] = (dataArray[i]['isactive'] && (dataArray[i]['isactive'].toString() == '1' || dataArray[i]['isactive'].toString() == 'true')) ? 1 : 0;
          }
          dataArray[i]['roles'] = !dataArray[i]['roles'] ? 'ALL' : dataArray[i]['roles'];
        }
        delete dataArray[i]['accountname']
        delete dataArray[i]['organizationcasetagvalueid']
        delete dataArray[i]['tagvaluesequence']
      }
      else {
        dataArray[i]['createdby'] = userid;
        dataArray[i]['associationtype'] = this.Orgtype[0].associationtype;
      }
    }
    let result = await this.VitalHttpServices.InsertOrganizationTags(dataArray, this.destDeployment).toPromise()//.subscribe(result => {
    this.noData = false;
    this.ngxService.stop();
    if (!result.errors) {
      if (!this.addEditClicked) {
        this.noData = false;
        dataArray.filter((e: any) => {
          result.filter(r => {
            if (r.slno.toString() === e.slno.toString()) {
              e.status = r.status
              e.notes = r.notemessage;
            }
          });
        });
        // dataArray.forEach((va: any) => {
        //   if (va.notes.toString().toLowerCase().match(/already exists/i)) {
        //     va.status = 'Ignored'
        //   }
        // })
        if (result.length > 0) {
          this.postUpload = true;
          this.showDelete = false;
          this.showDelete = false;
          let uploadresult = this.convertObjKeysToLower(dataArray);
          let dataExistsCount = 0;
          for (let i = 0; i < result.length; i++) {
            if (Object.values(result).every(function (item: any) {
              return item.status == "Ignored" ? true : false
            })) {
              dataExistsCount++;
            }
          }
          if (dataExistsCount == result.length) {
            if (bulkUpload) { this._snackbar.open(this.templateData.menuURL.toString().trim() + '(s) already exist!', 'Close') }
            else { this._snackbar.open(this.templateData.menuURL.toString().trim() + ' already exists!', 'Close'); }
            return;
          }
          else {
            this.commonService.auditDetails('', '', [], dataArray, bulkUpload ? 'Upload' : 'Copy', this.templateData, this.auditableColumns)
            let message = bulkUpload ? 'Data uploaded successfully' : 'Copied successfully';
            this.ExcelGridData(uploadresult);
            this._snackbar.open(message, 'Close');
            this.auditDetails({}, result, 'added');
          }
        }
        else {
          bulkUpload ? this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close') : this._snackbar.open(this.templateData.menuURL.toString().trim() + ' creation failed! Please try again.', 'Close');
        }
      }
      else {
        if (result.length > 0) {
          if (context == 0) {
            this.refreshGrid('list');
            this.gridShow = true;
            this.postUpload = false;
            this.showDelete = false;
            this.showDelete = false;
            this.addEditClicked = false;
          }
          else {
            this.resetForm();
          }
          this.auditDetails({}, result, 'added');
          this._snackbar.open('Created successfully', 'Success')
        }
        else {
          this._snackbar.open('Create failed', 'Failure')
        }
      }
    } else {
      this._snackbar.open('Create failed', 'Failure')
    }
    this.ngxService.stop();
  }

  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }

  async validateExcel(excelArr) {
    this.ngxService.start();
    let dataArray = [];
    dataArray = this.convertObjKeysToLower(excelArr);
    for (let i = 0; i < dataArray.length; i++) {
      if (this.copyDataClicked) {
        dataArray[i]['accountid'] = (dataArray[i]['accountid'] && dataArray[i]['accountid'].toString() != '-1' ? this.templateData.cardIdentifier : -1);
        dataArray[i]['groupname'] = (!dataArray[i]['groupid'] && (!dataArray[i]['isgroup'] || (dataArray[i]['isgroup'] && dataArray[i]['isgroup'].toString() == '0')) ? null : dataArray[i]['groupname']);
      } else {
        if ('isactive' in dataArray[i]) {
          dataArray[i]['isactive'] = (dataArray[i]['isactive'] && (dataArray[i]['isactive'].toString() == '1' || dataArray[i]['isactive'].toString() == 'true')) ? 1 : 0;
        }
        if (dataArray[i]['accountid'] && dataArray[i]['accountid'].toString().toLowerCase() == 'all') {
          dataArray[i]['accountid'] = -1;
        }
      }

      dataArray[i]['associationtype'] = this.Orgtype[0].associationtype;
      delete dataArray[i]['accountname']
      delete dataArray[i]['organizationcasetagvalueid']
      delete dataArray[i]['tagvaluesequence']
    }
    let result = await this.VitalHttpServices.validateOrganizationTagsData(dataArray, this.destDeployment).toPromise();
    if (!result.errors) {
      this.noData = false
      if (result && result.length > 0) {
        if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
          this.showInvalidColumns = true;
          this.invalidColumns = result[0].InvalidColumns;
        }
        dataArray.filter(e => {
          result.filter(r => {
            if (r.slno === e.slno) {
              e.notes = r.notemessage;
            }
          });
        });
        this.ExcelGridData(dataArray);
        this.ngxService.stop();
      }
      else {
        this._snackbar.open("Please provide valid data", 'Failed');
      }
    }
    this.noData = true;
  }

  manageDataColumn(data) {
    var i;
    for (i = 0; i < data.length; i++) {
      delete data[i]['organizationid']
      delete data[i]['accountid']
      delete data[i]['rolename']
      delete data[i]['displayname'];
      delete data[i]['accountname'];
      delete data[i]['type'];
    }
    this.convertToJson("", true, data);
  }

  async getDataToCopy() {
    this.checkStatus = false;
    if (typeof (this.copyOrgTagsMethodForm.value.frmOrgID) == 'object') {
      let frmorgid = this.copyOrgTagsMethodForm.value.frmOrgID
      // this.copyOrgTagsMethodForm.value.frmOrgID = frmorgid[0].orgid
    }
    let queryVariable = { orgid: this.copyOrgTagsMethodForm.value.frmOrgID.toString() };
    let query = this.SubMenuCardModel.GetQuery("getorganizationtagscopy");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    let userSpecificData = [];
    this.ngxService.start();
    let data = await this.VitalHttpServices.GetData(queryResult, this.copyOrgTagsMethodForm.value.frmDepKey).toPromise()
    this.ngxService.stop();
    if (!data.errors) {
      if (!this.usertoggle) {
        if (data.data.submenuData && data.data.submenuData.length > 0) {
          this.backBtn = data.data.submenuData.length > 0 ? false : true;
          this.postUpload = false;
          if (this.srcRole && this.srcRole.toString().toLowerCase() != 'all') {
            for (let i = 0; i < data.data.submenuData.length; i++) {
              if ((data.data.submenuData[i].roles && data.data.submenuData[i].roles.toLowerCase() == this.srcRole.toLowerCase())
                || (!data.data.submenuData[i].groupid)) {
                userSpecificData.push(data.data.submenuData[i]);
              }
            }
            this.manageDataColumn(userSpecificData);
          }
          else {
            for (let i = 0; i < data.data.submenuData.length; i++) {
              data.data.submenuData[i]['roles'] = 'ALL';
            }
            data.data.submenuData = _.uniqWith(data.data.submenuData, _.isEqual);
            this.manageDataColumn(data.data.submenuData);
          }
        }
        else {
          this.backBtn = true
          this.ExcelGridData([]);
        }
      } else {
        this._snackbar.open("Please enter valid user", "Close");
        this.ngxService.stop();
        return
      }
    }
    this.ngxService.stop();
  }

  // RBAC
  GetButtondetails() {
    this.GetButtonAccess(this.VitalHttpServices.SubmenuAction);
  }

  // region get button access
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (let i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Upload":
          this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Create":
          this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Edit":
          this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "Export":
          this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
        case "CopyToOrg":
          this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ? false : true;
          break;
      }
    }
  }

  async refreshGrid(context?) {
    this.organizationList = [];
    this.noData = false;
    this.srcRole = '';
    this.gridwidth = 0;
    this.excelDataArray = [];
    this.showInvalidColumns = false;
    this.postUpload = false;
    this.copyDataClicked = false;
    this.bulkUpload = false
    this.searchResult = [];
    this.addEditClicked = false;
    this.uploadClicked = false;
    this.selectedData = [];
    this.searchInput = ''
    this.srcDeployment = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.copyOrgTagsMethodForm.reset();
    this.copyOrgTagsMethodForm.patchValue({
      frmOrganization: '',
      frmDepKey: this.srcDeployment,
      frmOrgID: "",
      frmRole: "",
      frmcpyasinactive: false
    })
    // refresh the list data after upload
    await this.getOrgTaglist(context);
    // this.activeData(this.selectedVal)
    this.refreshComponent()
  }

  fetchRoleSeries(value: string) {
    this.usertoggle = false;
    this.searchRoleList = [];
    this.srcRole = ''
    if (value === '') {
      return (this.searchRoleList = []);
    }
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 0) {
        this.RoleList.find(r => {
          if (r.displayname.toString().toLowerCase() === value.toLowerCase()) {
            this.srcRole = r.displayname
            this.usertoggle = false;
            return r
          } else {
            this.srcRole = ''
            this.usertoggle = true
          }
        })
        this.searchRoleList = this.RoleList.filter(function (series) {
          if (series && series.displayname != null) {
            return series.displayname.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchRoleList = this.searchRoleList.splice(0, 25);
      }
    }
    else {
      this.searchRoleList = this.RoleList.filter(function (series) {
        if (series && series.roleid != null) {
          return series.roleid.toString().toLowerCase().includes(value.toLowerCase());
        }
      });
      this.searchRoleList = this.searchRoleList.splice(0, 25);
    }
  }

  fetchOrgSeries(value: string) {
    this.usertoggle = false;
    this.searchResult = [];
    if (value === '') {
      return (this.searchResult = []);
    }
    let idValidate = /^[0-9]*$/;
    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.searchResult = this.organizationList.filter(function (series) {
          if (series && series.organizationname != null) {
            return series.organizationname
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    } else {
      this.searchResult = this.organizationList.filter(function (series) {
        if (series && series.organizationid != null) {
          return series.organizationid
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
    this.copyOrgTagsMethodForm.value.frmOrgID = this.organizationList.filter(function (series: any) {
      if (series && series.organizationname != null) {
        return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
      }
    });
  }
  copyOrgTagsMethodForm = this._fb.group({
    frmDepKey: [sessionStorage.getItem('deploymentKey').toUpperCase(), Validators.required],
    frmOrganization: [''],
    frmOrgID: [''],
    frmRole: [''],
    frmcpyasinactive: false
  });

  onChangeDeployment(e) {
    this.searchResult = []
    this.organizationList = [];
    this.srcRole = '';
    this.gridwidth = -1;
    this.copyOrgTagsMethodForm.patchValue({
      frmDepKey: e.value,
      frmOrganization: '',
      frmOrgID: '',
      frmcpyasinactive: false,
      frmRole: ''
    });
    this.postUpload = false;
    this.backBtn = true;
    this.searchInput = '';
    this.srcDeployment = e.value;
    this.getListOrg();
    this.usertoggle = false;
  }

  loadCopyScreen() {
    this.chipDataOpen = false;
    this.noData = false;
    this.copyDataClicked = true;
    this.uploadClicked = false;
    this.gridShow = false;
    this.gridwidth = -1;
    this.backBtn = true;
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys
    this.copyOrgTagsMethodForm.patchValue({
      frmOrganization: '',
      frmDepKey: sessionStorage.getItem('deploymentKey').toUpperCase(),
      frmOrgID: "",
      frmRole: "",
      frmcpyasinactive: false
    })
    this.searchInput = '';
    this.getListOrg();
  }

  disableGetDataBtn() {
    return (!this.searchInput) ? true : (this.srcOrgid) != '' ? false : true
  }

  getListOrg() {
    this.organizationList = [];
    let dbName;
    let query = this.GetQuery('adduserfilterorg');
    let queryString = null
    dbName = this.srcDeployment;

    let queryVariable = { "filterParam": queryString };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      if (res.data.Organizations_list && res.data.Organizations_list.length > 0) {
        this.organizationList = res.data.Organizations_list;
      }
      this.ngxService.stop();
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  backtoCardList() {
    this.uploadClicked = false;
  }


  checkChipExists(caseTypeList, statusList, roleList) {
    return caseTypeList?.length || statusList?.length || roleList?.length
  }

  // create case tag activity tracker object
  createCaseTagActivityObject(entityid, changedValue, entityType, actionType, extendedAttributesObj, uploadedFile?: any) {
    try {
      let internalUserRegex = /^\w+([\.-]?\w+)@starmarkit|@vitalaxis(\.\w{2,3})+$/;
      let date = new Date();
      let entityTypeContext: any
      let deployid = sessionStorage.getItem('DeploymentKey')
      this.activityService.getActivitySession.subscribe(activityTab => entityTypeContext = activityTab);

      this.createActivityInput.productId = this.VitalHttpServices.GetDxurlBasedOnDeployment(deployid).replace(/(^\w+:|^)\/\//, '');
      this.createActivityInput.product = 'VitalDx';
      this.createActivityInput.customerId = sessionStorage.getItem('org_id');
      this.createActivityInput.customerName = sessionStorage.getItem('Org_Name');
      this.createActivityInput.entityType = entityType;
      this.createActivityInput.actionType = 'audit';
      this.createActivityInput.userName = localStorage.getItem('user_name');
      this.createActivityInput.userId = localStorage.getItem('UserEmail');
      this.createActivityInput.locationId = sessionStorage.getItem('Locationid');
      this.createActivityInput.locationName = sessionStorage.getItem('locationName');
      this.createActivityInput.isInternal = internalUserRegex.test(localStorage.getItem('UserEmail').toLowerCase());
      this.createActivityInput.transactionId = this.vaservice.generateGuid();
      this.createActivityInput.ticketId = 'Tic' + this.datePipe.transform(date, 'dd-MM-yyyyHH:mm a');
      this.createActivityInput.ticketInfo = actionType + " " + entityType;

      this.createActivityInput.entityId = entityid;
      this.createActivityOperations.info = actionType + " " + entityType + " " + changedValue;

      this.createActivityOperations.extendedAttributes = extendedAttributesObj;
      this.createActivityOperations.attachments = null;
      this.createActivityInput.operation = this.createActivityOperations;
      this.createActivityInput.context = entityTypeContext.context;
      if (Object.keys(this.createActivityOperations.extendedAttributes).length != 0) {
        this.VitalHttpServices.CreateActivity(this.createActivityInput)
          .subscribe((res) => { });
      }
    } catch (error) {
      console.error("Error occured while creating activity");
    }
  }
}



