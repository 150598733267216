import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { HeadersVisibility } from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import * as wjcGridXlsx from '@grapecity/wijmo.grid.xlsx';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
//import 'src/assets/css/styles.css';
import * as XLSX from 'xlsx';
import { InjectorService } from '../../../core/services/Injectorservices';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalAdminTabService } from '../../tab.service';
import { UserToggleComponent } from 'src/app/base/popup/user-toggle/user-toggle.component';
import { ConfirmComponent } from 'src/app/base/popup/confirm/confirm.component';
import { TemplateHeadersService } from 'src/app/core/utility/template-headers.service';
import { TemplateViewService } from 'src/app/core/utility/template-view.service';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { LabadminSessionService } from '../../../labadmin/services/labadmin-session.service';
import { Store } from '@ngrx/store';
import { LabadminService } from '../../../labadmin/labadmin.service';
import { take } from 'rxjs';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';

declare var $: any;
@Component({
  selector: 'app-case-comments-component',
  templateUrl: './case-comments.component.html',
  styleUrls: ['./case-comments.component.scss']
})

export class CaseCommentsComponent implements OnInit, OnChanges {
  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  rowData: any;
  columnDefs: any = [];
  gridWidth: number = 0;
  gridwidth: number = 0;
  labelFirstCount: number = 0;
  actionHeader: string = '';
  @Input()
  templateData: any;
  @Input()
  templateChangedTime: string;
  cvPaging: CollectionView;
  data: any;
  gridData: CollectionView;
  @ViewChild('matSelectOption') matSelectOption: MatSelect;
  public hideGrid = true;
  public showDescription = false;
  public description;
  public createdBy;
  public createdDate;
  public expiryDate;
  public effectiveDate;
  public evaluationCriteriaCode;
  public hideExportBtn: boolean = false;
  public actionButtonDetails: any[];

  dropdownSettings: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    enableCheckAll: boolean;
    allowSearchFilter: boolean;
    limitSelection: number;
    clearSearchFilter: boolean;
    maxHeight: number;
    itemsShowLimit: number;
    closeDropDownOnSelection: boolean;
    showSelectedItemsAtTop: boolean;
    defaultOpen: boolean;
  };
  gridHeader = [];
  listedgridheader: any[] = []
  gridheaderlist: any[] = []

  submitted: boolean = false;
  UserList = [];
  userRolesList1 = [];
  createFrmCaseComment: boolean; //Flag for showing the Create case comments
  tableGrid: boolean; // flag for showing the grid
  editFrmCaseComment: boolean; // flag for Edit Case comments
  crtFrmUserCaseComment: boolean; // flag for Adding user Case comments
  caseCommentData = this._fb.group({ // Form group for case comments
    templateName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    applicationUserId: '',
    active: true,
    description: ['', [Validators.required]],
    id: '',
    userId: '',
    caseType: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    siteID: ''
  })
  viewCommentsData: any;
  formFlagCaseComments: boolean;
  subMenuCardModel: SubMenuCardModel;
  changeToTrue: boolean;
  userFlag: boolean = false;
  caseTypeList: any;
  userDropdownList: any;
  gridData2: CollectionView<any>;
  selectedUserId: any = [];
  userIdsArray: any[];
  selector: Selector;
  sitesOfSelectedCaseType: any;
  formGroup: FormGroup;
  usersListArray: any;
  caseCommentUsers: any = [];
  userDropdownList1: object[] = [];
  userDropdownList2: any;
  userOrganizationid: any;
  hideCreateBtn: boolean;
  hideUpdateBtn: boolean;
  private dragIndex: number;
  tempExportData: any[];
  orgLevelList: any;
  hideCopyToOrgBtn: boolean = false;
  hideDragnDropBtn: boolean;
  hideUploadbtn: boolean = false
  editOrgID: any;
  usersaccountid: any;
  UserListData: any;
  selectedUserData: any;
  Edituserlevel: boolean = true;
  tempGridWidth: number;
  exportButtonFlag: boolean = false;
  headerName: String
  dropDownFlag: boolean = true
  hideUserCopyToOrg: boolean = false;
  showOrgInDrop: boolean;
  userNamesList: any[];
  destDeployment: string;
  srcDeployment: any;
  orgList: any[];
  copyDataClicked: boolean = false;
  public DeploymentKeys = [];
  searchuserlist: any[];
  searchInput: string = '';
  searchUserInput: String = '';
  srcOrgid: any;
  srcUserid: string;
  srcOrgName: any;
  srcUserName: any;
  userList: any;
  userListArray: any;
  usertoggle: boolean = false;
  selectedItems: any = [];
  public searchResult: Array<any> = [];
  srcOrgType: any;
  postDownload: boolean = false;
  postUpload: boolean = false;
  backBtn: boolean = true;
  excelDataArray = [];
  inData: any;
  showInvalidColumns: boolean = false;
  invalidColumns: string = "";
  gridDisplay: boolean = false;
  gridArrays = [];
  sheetHeader: string[];
  checkStatus = false;
  CaseComments: string = "Organization";
  caseTypeArray: any[];
  repeatedNames: string;
  invalidCasetypeFlag: boolean = false;
  uploadClicked: boolean = false;
  migrateclicked: boolean = false
  AllFields = [{}];
  MandatoryFields = [{}];
  BulkUpdateFields = [{}]
  sampleDataUpdate = [{
    Id: '123',
    Description: 'Description',
    UserId: '3456',
    IsActive: '1',
  }]
  sampleDataAll = [{
    TemplateName: 'Name',
    Description: 'Description',
    UserId: '3456',
    Sequence: '50',
  }]
  sampleDataMin = [{
    templateName: 'Name',
    description: 'Description',
  }]
  workBook: any;
  sheetsToSelect: any;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  @ViewChild('txtInput') txtInput: ElementRef;
  selectedOption = 'Group Case Comments';
  showDelete: boolean = true;
  underorguserlevel: boolean = false;
  PreviousUserdata: any;
  showPaginationMainGrid: boolean;
  private dbname;
  // userselectedItems=[];
  configname = new FormControl();
  userRoles: any = [];
  noRole: boolean;
  selectedRole: any;
  isdataAdded: boolean = false;
  userfetchValue: any = [];
  enableUserSelection: boolean = false;
  headerNames: boolean = false;
  organizationId: any;
  isAllUser: boolean = false;
  bulkUpload: boolean;
  name: any;
  migrateenable: boolean = false;
  underuserid: any;
  caseTypes: any;
  hideMigratebtn: boolean = false;
  hideManageCaseCommentbtn: boolean = false;
  listHeaderbackup: any;
  ViewScreen: boolean = false;
  receivedObject: any;
  auditableColumns: any;
  saveOrEditCompleted: any;
  //frm_testForm:FormGroup
  activityEntity: any;
  selectedCasetypeForLabadmin: any;
  expectedHeaders: any = [];
  uploadedHeaders: any = [];
  UpdateColumnMisMatch: boolean = false;
  missingColumn: any = [];
  bulkUpdateFile: boolean = false;
  minFieldsFile: boolean = false;

  constructor(private injectorServiceGridContainer: InjectorService, private ngxService: NgxUiLoaderService, private _fb: FormBuilder, private datashare: DataShareService, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices, private dialog: MatDialog,
    private _snackbar: MatSnackBar, public commonService: CommonService, private templateHeaders: TemplateHeadersService, private templateViewService: TemplateViewService, private activityService: ActivityTrackerService,
    private labAdminSessionService: LabadminSessionService, private store: Store<{ breadcrum: [] }>,private labadminService: LabadminService) {
    // super(injectorServiceGridContainer);
    this.subMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
    this.dbname = sessionStorage.getItem('deploymentKey').toUpperCase();
  }

  frm_testForm = this._fb.group({
    frm_panel: ''
  });

  copycollectionMethodForm = this._fb.group({
    frmOrganization: ["", Validators.required],
    frmUser: [""],
    frmDepKey: ["", Validators.required],
    frmOrgType: ["", Validators.required],
  })

  sourceApp: string = 'Admin';
  userSessionDetails: any;
  dbName = sessionStorage.getItem('deploymentKey')?.toUpperCase() ? this.labadminService.deploymentKey : sessionStorage.getItem('deploymentKey').toUpperCase();

  async ngOnInit() {
    let checkLaunchUrl=window.location.href.split('/#/')[1]
    if(checkLaunchUrl.toString().toLocaleLowerCase()!="home"){
      let _this = this;
      await _this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async session => {
        if (Object.keys(session["userDetails"]).length > 1) {
          _this.userSessionDetails = session["userDetails"]
          _this.sourceApp = 'VitalDx'
          let menuObj = {}
          await _this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
            menuObj = result[result.length - 1]
          })
          await _this.commonService.getCommmonTemplateData(menuObj, _this.userSessionDetails);
          _this.templateData = _this.labadminService.templateData;
          _this.dbName = _this.labadminService.deploymentKey;
          sessionStorage.setItem('deploymentKey', _this.dbName);
          sessionStorage.setItem('org_id', _this.labadminService.organizationId);

          _this.getGridHeader()
          _this.loadInitialData();
          _this.GetButtonAccess(_this.labadminService.subMenuPermissions)
          _this.activityService.setActivitySession({ 'entityId': '', 'entityType': _this.templateData['menuURL'], 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Pathology Management','value':_this.templateData['menuURL']}] })
          _this.activityService.getActivitySession.subscribe(res => _this.activityEntity = res);
          _this.getAuditableDetails(_this.labadminService.templateData.menuURL)

        }
      })

     }else{

      this.DeploymentKeys = this.DeploymentKeys.map(element => element.toUpperCase());
      this.loadInitialData();
      this.getListUsers(sessionStorage.getItem('org_id'));
      this.getAuditableDetails(this.templateData.menuURL);
      //let actcontextKey=this.userFlag?'Users':'parentMenu';
      if(this.userFlag){
        this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL }] })
      }else{
        this.commonService.createContext(this.templateData.secondarykeys, '', this.templateData.menuURL);
      }
      this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
      this.GetButtondetails();
     }

  }
  async loadInitialData() {
    //this.GetButtondetails();
    this.usersaccountid = this.templateData?.cardIdentifier ?this.templateData.cardIdentifier:null;
    //this.AddGridData();

    if (!this.userFlag) {
      if (this.templateData.submenuData) {
        if (this.templateData.submenuData.length > 0) {
          this.templateData.submenuData.forEach(element => {
            if (!element['User']) {
              element['User'] = 'Not specified'
            }
          });
        }
      }
    }

    // if (this.isdataAdded) {
    //   this.templateData.submenuData = [...this.templateData.submenuData, ...mappeddata];
    // }
    this.commonService.getBooleanValue().subscribe((x) => {
      this.changeToTrue = x;
    });
    if (sessionStorage.getItem("search_context")?.toLowerCase() == "users" ||
      // sessionStorage.getItem("search_context").toLowerCase() == 'physician' ||
      sessionStorage.getItem("search_context")?.toLowerCase() == 'pathologist' ||
      sessionStorage.getItem("search_context")?.toLowerCase() == "userid") {
      this.userFlag = true;
      this.CaseComments = 'User'
      this.userRoles = this.commonService.userRoles(this.templateData.menuURL.replace(/\s/g, ''))
      if (this.userRoles && this.userRoles.length > 0) {
        //if (this.userRoles && this.userRoles.length > 0) {
        this.inData = this.userRoles[0].userid.toString();
        this.selectedRole = this.userRoles[0].RoleName.toString();
        this.templateViewService.globalMasterDataForQueries['userRole'] = this.userRoles[0].RoleName.toString();
        this.templateViewService.globalMasterDataForQueries['userId'] = this.userRoles[0].userid.toString();
        //this.caseTypeList = await

        await this.VitalHttpServices.getCasetypesBasedOnRole(this.selectedRole, this.inData).toPromise().then(
          (res) => {
            this.caseTypeList = res;
            this.caseTypes = res
            this.refreshUserlevelGrid()

          });
        // this.getOrgCasetype();
        this.PreviousUserdata = { UserId: this.inData }
        // }
        // else {
        //   this.noRole = true;
        //   return
        // }
      }
      else {
        this.noRole = true;
        this.tableGrid = false;
        return
      }
    }
    else {
      this.userFlag = false;
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'UserId',
      textField: 'User_Name',
      enableCheckAll: true,
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 150,
      itemsShowLimit: 2,
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };

    // if (this.userFlag) {
    //   var obj = {
    //     //organizationid: this.templateData.submenuData[0].OrganizationId
    //     userid: this.templateData.cardIdentifier
    //   };
    //   this.ngxService.start();
    //   this.VitalHttpServices.getCasetypesBasedOnRole(this.selectedRole, this.underuserid).subscribe(
    //     (res) => {
    //       this.ngxService.stop();
    //       this.caseTypeList = res;
    //       // if (this.caseTypeList[0].rolename.toLowerCase() == 'lab manager' || this.caseTypeList[0].rolename.toLowerCase() == 'technician' || this.caseTypeList[0].rolename.toLowerCase() == 'lab director') {
    //       // As per the Lavanya's suggestion below line will be command
    //       // this.caseTypeList = this.caseTypeList.filter(va => va.FinalizeAfterTC.toLowerCase() != "true")
    //       //}

    //       // this.AddGridData();
    //     });
    // }
    // else {
    if (!this.userFlag) {
      this.AddGridData();
    }
    //}
  }

  clearOrganization() {
    this.copycollectionMethodForm.patchValue({
      frmOrganization: '',
      frmUser: ''
    })
    this.copycollectionMethodForm.controls.frmUser.disable();
  }

  clearUser() {
    this.copycollectionMethodForm.patchValue({
      frmUser: ''
    })
  }

  async setUserRole(event, data) {
    if (event.source.selected) {
      this.inData = data.userid.toString();
      this.underuserid = data.userid.toString();
      this.commonService.selectedUserForCaseComments = data.userid.toString()
      this.selectedRole = data.RoleName.toString();
      this.templateViewService.globalMasterDataForQueries['userRole'] = data.RoleName.toString();
      this.templateViewService.globalMasterDataForQueries['userId'] = data.userid.toString();
      this.PreviousUserdata = { UserId: this.inData }
      await this.migrateEnabling(data)
      await this.refreshUserlevelGrid()

    }
  }

  async getOrgCasetype() {
    let query = this.subMenuCardModel.GetQuery('getorgcasetypes');
    let queryVariable = { "orgid": sessionStorage.getItem('org_id') };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    await this.VitalHttpServices.GetData(queryResult).toPromise().then(res => {
      if (!res.errors) {
        this.caseTypeArray = [];
        this.caseTypeArray = res.data.submenuData;
      }
    }, error => {
      console.log(error)
    })
  }


  getTempalte() {
    this.gridheaderlist = this.templateHeaders.templateList;
    for (let i = 0; i < this.gridheaderlist.length; i++) {
      if (this.gridheaderlist[i].templateName == 'Case Comments') {
        this.listedgridheader = this.gridheaderlist[i].headers;
        this.listHeaderbackup = JSON.parse(JSON.stringify(this.listedgridheader))
      }

    }
    if (sessionStorage.getItem("search_context")?.toLowerCase() == "users" ||
      sessionStorage.getItem("search_context")?.toLowerCase() == 'pathologist' ||
      sessionStorage.getItem("search_context")?.toLowerCase() == "userid") {

      this.listedgridheader = this.listHeaderbackup.filter(e => e.Name != 'User')

    } else {
      this.listedgridheader = this.listHeaderbackup.filter(e => e.Name != 'User')
      // this.listedgridheader = this.listHeaderbackup.filter(e => e.Name != 'Case Type' && e.Name != 'User')

    }
  }

  ngOnChanges() {
    this.getGridHeader()
    if (this.isdataAdded) {
      this.loadInitialData();
    }
    this.AddGridData();
  }
  getGridHeader(){
    this.getTempalte()

    this.gridHeader = [];
    this.templateData?.labels ? this.labels = JSON.parse(JSON.stringify(this.templateData?.labels)) : this.labels = [];

    if (this.labels?.length == 0) {
      this.templateData.labels = [
        'Id',
        'SiteName',
        'OrganizationId',
        'Sequence',
        'User',
        'TemplateName',
        'Description',
        'CaseType',
        'Type',
        'IsActive',
        'SiteId'];
      for (let i = 0; i < this.templateData.labels.length; i++) {
        this.gridHeader.unshift(this.templateData.labels[i]);
      }
    } else {
      for (let i = 0; i < this.labels.length; i++) {
        if (this.labels[i] === 'Description') {
          this.gridHeader.unshift('User', this.labels[i]);
        } else {
          this.gridHeader.unshift(this.labels[i]);
        }
      }
    }
    this.gridHeader = this.gridHeader.reverse()
  }

  GetButtondetails() {
    if (sessionStorage?.getItem("search_context")?.toLowerCase() == "users" || sessionStorage?.getItem("search_context")?.toLowerCase() == 'physician' || sessionStorage?.getItem("search_context")?.toLowerCase() == 'pathologist' || sessionStorage?.getItem("search_context")?.toLowerCase() == "userid") {
      this.GetButtonAccess(this.VitalHttpServices.SubmenuAction);
    }
    else {
      this.GetButtonAccess(this.VitalHttpServices.CasemenuAction);
    }
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions;
    if (this.sourceApp == 'VitalDx') {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == "Templates").SubMenu.find(va=> va.URL == this.labadminService.templateData.menuURL)["ActionButton"]
    }
    else {
      seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    }
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      if (seletedMenuPermissions[i].Button === "Export") {
        this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "Create") {
        this.hideCreateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "Edit") {
        this.hideUpdateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "CopyToOrg") {
        this.hideCopyToOrgBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "Copy") {
        this.hideCopyToOrgBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "DragnDrop") {
        this.hideDragnDropBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "Upload") {
        this.hideUploadbtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "Migrate") {
        this.hideMigratebtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
      else if (seletedMenuPermissions[i].Button === "ManageCaseComments") {
        this.hideManageCaseCommentbtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
      }
    }
  }

  AddGridData() {
    if (sessionStorage.getItem("search_context")?.toLowerCase() == "users" || sessionStorage.getItem("search_context")?.toLowerCase() == 'physician' || sessionStorage.getItem("search_context")?.toLowerCase() == 'pathologist' || sessionStorage.getItem("search_context")?.toLowerCase() == "userid") {
      this.userFlag = true;
      this.hideUserCopyToOrg = true;
    }
    else {
      this.userFlag = false;
      this.hideUserCopyToOrg = false;
    }
    this.underorguserlevel = false;
    this.ngxService.start();
    this.headerName = "Group Level Case Comments"
    this.tableGrid = true;
    let gridarray = []
    let primary = {}
    if (this.isAllUser && this.CaseComments !== "Organization") {
      this.userFlag = false;
      let mapdata = this.mapData();
      if (mapdata.length > 0) {
        this.templateData.submenuData[0] = mapdata
      } else {
        this.gridwidth = 0
        this.ngxService.stop();
        this.isAllUser = false;
        return;
      }
      this.UserList = this.templateData.submenuData;
      this.headerName = "All Users Case Comments"
      this.frm_testForm = this._fb.group({
        frm_panel: "All User",
      });
    } else {
      this.isAllUser = false;
      this.frm_testForm = this._fb.group({
        frm_panel: "Group Case Comments",
      });
    }
    //this.frm_testForm.patchValue({frm_panel:"Organization Comments"})
    // pushing submenu data to export data
    if (this.templateData.submenuData) {
      // if (this.userFlag && this.caseTypeList != undefined) {
      //   this.templateData.submenuData = this.templateData.submenuData.filter(({ Case_Type }) => this.caseTypeList.some(exclude => exclude.caseType == Case_Type));
      // }
      this.tempExportData = [...this.templateData.submenuData]
      if (this.templateData.submenuData.length > 0 && !this.isAllUser) {
        for (let i = 0; i < this.templateData.submenuData.length; i++) {
          const primary = {}; // Initialize primary as an empty object for each iteration
          for (const [key, value] of Object.entries(this.templateData.submenuData[i])) {
            for (let j = 0; j < this.gridHeader.length; j++) {
              if (key === this.gridHeader[j]) {
                primary[key] = value;
              }
              if (key === 'UserId' && value.toString().toLowerCase().trim() != 'not specified') {
                this.userFlag = true;
              }
            }
          }
          gridarray.push(primary);
        }
        // gridarray.sort((a, b) => a.Sequence > b.Sequence ? 1 : -1).sort((a, b) => a.Sitename < b.Sitename ? 1 : -1).sort((a, b) => a.Case_Type > b.Case_Type ? 1 : -1)
        let groupedData = this.userFlag ? this.groupBy(gridarray, 'SiteName', 'CaseType') : this.groupBy(gridarray, 'SiteName')
        let finalTemplate: any[] = [];
        if (groupedData !== undefined) {
          for (let [key, value] of Object.entries(groupedData)) {
            if (Array.isArray(value)) {
              // for (let i = 0; i < value.length; i++) {
              //   value[i].sequnece = i + 1;
              // }
            }
            finalTemplate = finalTemplate.concat(value);
          }
        }
        if (this.userFlag) {
          finalTemplate = finalTemplate.sort((a, b) => a.CaseType.localeCompare(b.CaseType) || a.SiteName.localeCompare(b.SiteName) || a.Sequence - b.Sequence);
        } else {
          finalTemplate = finalTemplate.sort((a, b) => a.SiteName.localeCompare(b.SiteName) || a.Sequence - b.Sequence)
        }
        gridarray = finalTemplate;

      }
      else if (this.templateData.submenuData[0]) {
        for (let i = 0; i < this.templateData.submenuData[0].length; i++) {
          const primary = {}; // Initialize primary as an empty object for each iteration
          for (const [key, value] of Object.entries(this.templateData.submenuData[0][i])) {
            for (let j = 0; j < this.gridHeader.length; j++) {
              if (key === this.gridHeader[j]) {
                primary[key] = value;
              }
              if (key === 'UserId' && value.toString().toLowerCase().trim() != 'not specified') {
                this.userFlag = true;
              }
            }
          }
          gridarray.push(primary);
        }
        // gridarray.sort((a, b) => a.Sitename > b.Sitename ? 1 : -1).sort((a, b) => a.Sequence < b.Sequence ? 1 : -1).sort((a, b) => a.Case_Type > b.Case_Type ? 1 : -1)
        let groupedData = this.userFlag ? this.groupBy(gridarray, 'SiteName', 'CaseType') : this.groupBy(gridarray, 'SiteName')
        let finalTemplate: any[] = [];
        if (groupedData !== undefined) {
          for (let [key, value] of Object.entries(groupedData)) {
            if (Array.isArray(value)) {
              // for (let i = 0; i < value.length; i++) {
              //   value[i].sequnece = i + 1;
              // }
            }
            finalTemplate = finalTemplate.concat(value);
          }
        }
        if (this.userFlag) {
          finalTemplate = finalTemplate.sort((a, b) => a.CaseType.localeCompare(b.Case_ype) || a.sitename.localeCompare(b.SiteName) || a.Sequence - b.Sequence);
        } else {
          finalTemplate = finalTemplate.sort((a, b) => a.SiteName.localeCompare(b.SiteName) || a.Sequence - b.Sequence)
        }
        gridarray = finalTemplate;
      }
      if (!this.userFlag) {
        this.Edituserlevel = true;
      }
      //this.orgLevelList = [this.templateData.submenuData,this.templateData.submenuData1,this.templateData.submenuData2]
      if (this.templateData.submenuData && this.templateData.submenuData.length > 0) {
        this.orgLevelList = this.templateData.submenuData
      }
      else {
        this.exportButtonFlag = false;
        this.orgLevelList = [];
      }

      if (this.templateData.submenuData.submenuData2 != undefined) {
        if (this.templateData.submenuData.submenuData2.length > 0) {

          this.UserList = this.templateData.submenuData.submenuData2
          //this.name=this.templateData.submenuData.submenuData2;
          this.CaseComments = "Organization";
        }
      } else {
        this.dropDownFlag = false
        this.headerName = "User Level Case Comments"
        this.UserList = []
      }
      if (this.templateData.submenuData.submenuData1 != undefined) {
        if (this.templateData.submenuData.submenuData1.length > 0) {
          this.UserListData = this.templateData.submenuData.submenuData1
        }
      } else {
        this.dropDownFlag = false
        this.headerName = "User Level Case Comments"
        this.UserListData = []
      }
      this.gridData = new CollectionView(gridarray)
      // if (gridarray.length > 10) {
      //   this.showPaginationMainGrid = true;
      // } else {
      //   this.showPaginationMainGrid = false;
      // }
      this.gridwidth = (238 * this.gridHeader.length) + 37;
      if (this.gridwidth > 1300)
        this.gridwidth = 1300;
      if (this.gridwidth != 0) {
        this.gridwidth = this.getpercentage(this.gridwidth)
        this.tempGridWidth = this.gridwidth;
      }
      if (!this.templateData.submenuData[0]) {
        this.gridwidth = 0;
      }

    } else {

      this.gridwidth = 0;
    }
    this.ngxService.stop();
  }

  //#region code for tool tip on the grid
  initGrid(flexgrid) {
    const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell)
        return;
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  //#endregion
  getpercentage(pixel: number) {
    // var pixels = 100;
    var screenWidth = window.screen.width;
    let per = (pixel / screenWidth) * 100
    if (per < 80) {
      per = per + 8
    }
    return per;
  }

  AddTab(user: any, item: string) {
    if (item === 'user') {
      let tab: any = {};
      // this accountid is actually used to  populate the data for user(userid)
      tab.accountid = user.UserId;
      tab.keyword = "Users";
      tab.cardtype = "Users";
      tab.text = user.Username;
      tab.Removable = true;
      this.tabService.addNewOrSelectTab(tab);
    }
  }


  //#region  code for showing the record after clicking grid
  // onClickingTheGrid(grid, e) {
  //   let hti = grid.hitTest(e);
  //   var col = hti.col;
  //   var row = hti.row;
  //   var item = hti.panel.rows[row].dataItem;

  //   //#region showing the details of case comments after clicking to the grid
  //   this.showCaseCommentDetailsAfterApiCall(item.Id)
  //   //#endregion
  // }
  // showCaseCommentDetailsAfterApiCall(id) {
  //   this.ngxService.start();
  //   if (id != null) {
  //     this.tableGrid = false;
  //     this.viewCommentsData = this.templateData.submenuData.find((va) => va.Id == id);
  //     this.viewTemplateName = this.viewCommentsData.Template_Name;
  //     this.viewDescription = this.viewCommentsData.Description;
  //     if (this.userFlag) {
  //       this.viewCaseType = this.viewCommentsData.Case_Type;
  //     }
  //     this.viewActive = this.viewCommentsData.Active;
  //     this.viewSequence = this.viewCommentsData.Sequence;
  //     this.viewid = this.viewCommentsData.Id;
  //     this.viewCreatedBy = this.viewCommentsData.Created_By;
  //     this.viewCreatedDate = this.viewCommentsData.Created_Date;
  //     this.viewModifiedBy = this.viewCommentsData.Modified_By;
  //     this.viewModifiedDate = this.viewCommentsData.Modified_Date;
  //     this.viewSiteId = this.viewCommentsData.Site_Id == null ? 'All' : this.viewCommentsData.Site_Id;
  //     this.viewSiteName = this.viewCommentsData.Site_Name;
  //   }
  //   this.ngxService.stop();
  // }

  //#endregion


  //#region  For Openign the Edit page After Clicking the edit buttomn inside view page
  editCaseComments(row) {
    this.ngxService.start();
    // this.actionHeader = 'Edit Case Comment';
    this.formFlagCaseComments = true;
    this.isdataAdded = true;
    this.submitted = false;
    this.editFrmCaseComment = true;
    this.tableGrid = false;
    this.editOrgID = row.Organization_Id ? row.Organization_Id : sessionStorage.getItem('org_id');
    this.createFrmCaseComment = false;
    if (this.userFlag) {
      this.actionHeader = 'Edit User Level Case Comment';
      this.onCaseTypeChange(row.CaseType)
    }
    else {
      if (row.User === 'Not specified') {
        this.actionHeader = 'Edit Group Level Case Comment';
      } else {
        this.actionHeader = 'Edit User Level Case Comment';
      }
      this.onCaseTypeChange(this.templateData.GroupData.casetype)
    }
    this.caseCommentData.patchValue({
      templateName: row.Template_Name,
      active: row.Active == 'Yes' ? true : false,
      description: row.Description,
      id: row.Id,
      caseType: row.CaseType,
      siteID: row.Site_Id ? row.Site_Id : '',
      userId: row.User !== 'Not specified' ? row.User.split(',') : ''
    })
    if (row.User) {
      this.enableUserSelection = false;
      this.caseCommentData.controls.userId.disable();
    }
    else {
      this.caseCommentData.controls.userId.enable();
      this.enableUserSelection = true
      this.fetchCaseCommentUsers(this.editOrgID, 1);
    }
    this.ngxService.stop();
  }
  //#endregion

  //#region Function for showing view page After clicking Back btn in edit page
  backFromFormPage() {
    //#region after clicking the back button from Edit page
    // if (!this.createFrmCaseComment) { // checking the flag is not create
    this.ngxService.start();
    this.formFlagCaseComments = false;
    this.tableGrid = true;
    this.caseCommentData.reset();
    //this.createFrmCaseComment = false;
    this.caseCommentUsers = [];
    this.ngxService.stop();
    //}

    //#endregion
    //#region for backing from create
    // else {
    //   this.ngxService.start();
    //   this.caseCommentData.reset();
    //   this.formFlagCaseComments = false;
    //   this.tableGrid = true;
    //   //this.createFrmCaseComment = false;
    //   this.ngxService.stop();
    // }
    //#endregion

  }
  //#endregion

  updateCaseComments(caseCommentData) {
    this.ngxService.start();
    this.submitted = true;
    let object;
    if (caseCommentData.templateName.trim() == '' || caseCommentData.description.trim() == '') {
      this._snackbar.open('Please enter required data!', 'Close');
      this.ngxService.stop()
      return;
    }

    else {
      if (this.userFlag) {
        object = {
          templateName: caseCommentData.templateName,
          description: caseCommentData.description,
          applicationUserId: sessionStorage.getItem('Userid') != '' ? sessionStorage.getItem('Userid') : -100,
          isActive: caseCommentData.active,
          id: caseCommentData.id,
          siteid: caseCommentData.siteID == 'All' ? null : caseCommentData.siteID,
          caseType: caseCommentData.caseType,
          organizationID: this.editOrgID
        }
      }
      else {
        object = {
          templateName: caseCommentData.templateName,
          description: caseCommentData.description,
          applicationUserId: sessionStorage.getItem('Userid') != '' ? sessionStorage.getItem('Userid') : -100,
          isActive: caseCommentData.active,
          id: caseCommentData.id,
          siteid: caseCommentData.siteID == 'All' ? null : caseCommentData.siteID,
          caseType: this.templateData.secondarykeys.casetype,
          organizationID: this.templateData.secondarykeys.OrganizationId
        }
      }
      this.VitalHttpServices.updateCaseComments(object).subscribe((res) => {
        this.ngxService.stop();
        if (res.Success) {
          if (res.Message.toLowerCase() == 'template with the same name already exists.cannot update!') {
            this._snackbar.open(res.Message, 'Failed');
            this.createFrmCaseComment = false;
            this.formFlagCaseComments = true;
          }
          else {
            this.createFrmCaseComment = false;
            this.formFlagCaseComments = false;
            this.commonService.setBooleanToTrue(true);
            this.tableGrid = true;
            this._snackbar.open(res.Message, 'Success');
            this.selectedOption = 'Group Case Comments';
            this.AddGridData()
          }
          // this.formFlagCaseComments = false;

        } else {
          if (res.Message.toLowerCase().includes('Could not find'))
            this._snackbar.open(res.Message, 'Failed');
          //this.ngxService.stop();
        }
      });

    }
  }

  openCreateScreen() {
    if ((this.userFlag || this.underorguserlevel) && this.gridwidth == 0 && !this.migrateenable) {
      let dialogRef = this.dialog.open(ConfirmComponent, {
        disableClose: true,
        width: '500px',
        data: { header: "Create User Level Case Comments", message: "You have chosen to create User level templates, any templates created will be specific only to this user. Do you want to proceed? ", alert: "", continue: "Yes", cancel: "No" }
      });
      return dialogRef
        .afterClosed().toPromise().then
        ((result) => {
          if (result) {
            this.createNewCaseComment()
          }
        });

    } else {
      this.createNewCaseComment()
    }
  }

  //#region Creating New Case Comment create Button clicked
  createNewCaseComment() {
    // this.actionHeader = 'Create Case Comment'
    this.copyDataClicked = false;
    this.ngxService.start();
    this.submitted = false;
    this.userDropdownList1 = [];
    this.formFlagCaseComments = true;
    this.createFrmCaseComment = true;
    this.tableGrid = false;
    let Org_id = sessionStorage.getItem('org_id');
    if (this.userFlag) {
      this.caseCommentData.reset();
      this.sitesOfSelectedCaseType = [];
      this.actionHeader = 'Create User Level Case Comment';
      var obj = {
        //organizationid: this.templateData.submenuData[0].OrganizationId
        userid: this.templateData.cardIdentifier
      };
      this.ngxService.start();
      let query = this.VitalHttpServices.GetQuery('getOrganizationIdFromUserid');
      let queryVariable = {
        userid: this.templateData.cardIdentifier.toString()
      };
      let queryResult = this.commonService.GetCardRequest(queryVariable, query);
      this.ngxService.start();
      this.VitalHttpServices.GetData(queryResult).subscribe((Resdata) => {

        if (!Resdata.errors) {
          this.userOrganizationid = Resdata.data.submenuData[0].Organization_Id
        }
      })
      this.ngxService.stop();
      this.caseCommentData.patchValue({
        templateName: '',
        active: true,
        description: '',
        userId: '',
        caseType: '',
        siteID: ''
      })
    }
    else {
      this.caseCommentData.reset();
      if (this.underorguserlevel) {
        this.actionHeader = 'Create User Level Case Comment';
      } else {
        this.actionHeader = 'Create Group Level Case Comment';
      }
      this.fetchCaseCommentUsers(Org_id);

    }
    this.ngxService.stop();
  }
  //#endregion


  fetchCaseCommentUsers(Org_id, onEdit?) {
    let query = this.VitalHttpServices.GetQuery('CaseCommentUserList');
    let role
    if (this.commonService.finalizeAfterTc) {
      role = 'Lab Manager,Lab Director,Technician'
    }
    else if (!this.commonService.finalizeAfterTc && this.commonService.isScreeningEnabled) {
      role = 'Cytotechnologist Supervisor,Cytotechnologist,Pathologist,Pathologist Assistant', 'Screening Technologist'
    }
    else if (!this.commonService.finalizeAfterTc && !this.commonService.isScreeningEnabled) {
      role = 'Pathologist,Pathologist Assistant', 'Screening Technologist'
    }
    let queryVariable = {
      orgid: Org_id,//this.templateData.secondarykeys.OrganizationId.toString(),
      filterParameter: "rolename in \"" + role.toString() + "\":string []"
    };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult).subscribe((Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        this.userDropdownList = Resdata.data.submenuData;
        this.userDropdownList.forEach(element => {
          const temp: object = {
            'UserId': element.UserId, 'User_Name': element.User_Name + '(' + element.Role + ')'
          }
          this.userDropdownList1.push(temp);
        });
        this.userDropdownList2 = this.userDropdownList1;

        // for (var i = 0; i < this.userDropdownList.length; i++) {
        //   this.userDropdownList[i].ischecked = -1; // Add ischecked  to all objects in array
        // }
        // let distictRolename = [...new Map(this.userDropdownList.map((x) => [x['Role'], x.Role])).values()];

        // let Users = []
        // Users.push({ "Pathologist": this.userDropdownList.filter((va) => va.Role == "Pathologist") })
        // let userListByUserRole = [];
        // for (let i = 0; i < distictRolename.length; i++) {

        //   //distictRolename["Pathologist"].push("Pathologist" :Users)

        //   userListByUserRole.push(distictRolename[i]);
        // }
        //this.AddUserGridData()
        if (!onEdit) {
          this.userfetchValue = this.userDropdownList2.filter(x => x.UserId == this.inData);
          setTimeout(() => {
            this.onCaseTypeChange(this.templateData.GroupData.casetype);
            this.caseCommentData.patchValue({
              templateName: '',
              active: true,
              description: '',
              caseType: '',
              siteID: 'All'
            })
          });
          if (!this.selectedOption.toString().match(/Group Case Comments/i) && !this.selectedOption.toString().match(/All Users/i) && this.selectedOption !== 'All Locations  Case Comments') {
            this.caseCommentData.patchValue({
              userId: this.userfetchValue
            })
            this.caseCommentData.controls.userId.disable();
            this.caseCommentUsers.push(this.userfetchValue[0].UserId)
          }
          else {
            this.caseCommentData.patchValue({
              userId: ''
            })
          }
        }
      }
      else {
        this._snackbar.open('Something went wrong!')
      }
    });
  }

  onCaseTypeChange(selectedCaseType) {
    //  selectedCaseType=selectedCaseType.split('/');
    this.sitesOfSelectedCaseType = [];
    if (this.userFlag) {
      // this.templateData.submenuData[0].Organization_Id=sessionStorage.getItem('org_id');
      let Org_id = sessionStorage.getItem('org_id');
      if (this.templateData.submenuData.length > 0 && this.templateData.submenuData[0].Organization_Id) {
        var object = {
          organizationid: this.templateData.submenuData[0].Organization_Id,
          casetype: selectedCaseType
        }
      }
      else {
        var object = {
          organizationid: this.userOrganizationid ? this.userOrganizationid : Org_id,
          casetype: selectedCaseType
        }
      }
    }
    else {
      var object = {
        organizationid: this.templateData.GroupData.OrganizationId,
        casetype: selectedCaseType
      }
    }
    this.ngxService.start();
    this.VitalHttpServices.getCaseTypeSites(object).subscribe((res) => {
      this.ngxService.stop();
      if (res.Success) {
        this.sitesOfSelectedCaseType = JSON.parse(res.Message);

      }
      else {
        this.sitesOfSelectedCaseType = [];
        this._snackbar.open('Something went wrong!')
      }
    }), (error) => {
      this._snackbar.open('An error occurred while processing your request', 'Failed');
    }
  }

  //#region for calling API with case comment Object,
  CreateCaseComments(caseCommentData) {
    this.ngxService.start();
    let Org_id = sessionStorage.getItem('org_id');
    this.submitted = true;
    if (this.userFlag && (caseCommentData.templateName.trim() == '' || caseCommentData.description.trim() == '' || caseCommentData.caseType.trim() == '')) {
      this._snackbar.open('Please enter required data!', 'Close');
      this.ngxService.stop()
      return;
    }
    else if (caseCommentData.templateName.trim() == '' || caseCommentData.description.trim() == '') {
      this._snackbar.open('Please enter required data!', 'Close');
      this.ngxService.stop()
      return;
    }
    else {
      let object
      if (this.userFlag) {
        object = {
          templateName: caseCommentData.templateName,
          description: caseCommentData.description,
          applicationUserId: sessionStorage.getItem('Userid') != '' ? sessionStorage.getItem('Userid') : -100,
          isActive: caseCommentData.active == true ? true : false,
          //id: caseCommentData.id,
          userId: this.inData = 'Organization' ? this.inData : this.templateData.cardIdentifier,
          caseType: caseCommentData.caseType,
          siteID: caseCommentData.siteID == 'All' ? null : caseCommentData.siteID,
          organizationId: this.userOrganizationid ? this.userOrganizationid : Org_id,
          temptext: 'User Level'
        }
      }
      else {
        object = {
          templateName: caseCommentData.templateName,
          description: caseCommentData.description,
          applicationUserId: sessionStorage.getItem('Userid') != '' ? sessionStorage.getItem('Userid') : -100,
          isActive: caseCommentData.active == true ? true : false,
          //id: caseCommentData.id,
          // userId: caseCommentData.userId == '' ? null : caseCommentData.userId,
          userId: this.caseCommentUsers,
          caseType: this.templateData.GroupData.casetype,
          siteID: caseCommentData.siteID == 'All' ? null : caseCommentData.siteID,
          organizationId: Org_id,//this.templateData.GroupData.OrganizationId,
          temptext: 'org level'
        }
      }
      this.VitalHttpServices.createCaseComments(object).subscribe((res) => {
        this.isdataAdded = true;
        if (res[1].toLowerCase() == 'true') {
          this.selectedOption = 'Group Case Comments';
          // this.caseTypeList = JSON.parse(res.Message);
          this.ngxService.stop();
          this.caseCommentUsers = [];
          if (res.length > 2) {
            if (res[0].toLowerCase() != "") {
              this._snackbar.open(res[2], 'Success');
              // this.formFlagCaseComments = false;
              this.commonService.setBooleanToTrue(true);
              this.tableGrid = true;

            }
            else { this._snackbar.open(res[2], 'Failed');
              this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
              this.createFrmCaseComment = true;
              // this.formFlagCaseComments = true;  
              this.tableGrid = false;
              return;
            }
          }
          else {
            this.isdataAdded = true;
            this._snackbar.open('Case comment added successfully', 'Success');
            this.selectedOption = 'Group Case Comments';
            // this.formFlagCaseComments = false;
            this.caseCommentUsers = [];
            this.tableGrid = true;
            this.commonService.setBooleanToTrue(true);
          }
          this.caseCommentData.reset();
          this.createFrmCaseComment = false;
        } else {
          this._snackbar.open(res.Message, 'Failed');
          this.ngxService.stop();
        }
      });
    }
  }

  //#endregion
  AddUserGridData() {
    this.columnDefs = Object.keys(this.userDropdownList[0]);
    this.gridData2 = new CollectionView(this.userDropdownList, {
      groupDescriptions: ['Role']
    });
    this.gridwidth = 170 * (this.columnDefs.length - 1);
    if (this.gridwidth > 1300) this.gridwidth = 1300;
    if (this.gridwidth != 0) {
      this.gridwidth = this.getpercentage(this.gridwidth);
    }
  }

  // Export Excel with file Name and sheet name
  ExportExcel(flex) {
    let rowData = flex._view
    let excel = [];
    // rowData.rows.forEach(e => {
    //   let { ...jsonData } = e._data
    //   delete jsonData.Action;
    //   excel.push(jsonData);
    // });
    if (!this.templateData.secondarykeys) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = 'Case Comments_' + this.organizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(rowData);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Case Comments');
    XLSX.writeFile(wb, filename);
  }

  // exportDetailsToExcel() {
  //   let orgname = sessionStorage.getItem('Org_Name');
  //   let filename;
  //   if (this.viewTemplateName != undefined || this.viewTemplateName != null) {
  //     if (this.viewTemplateName.length > 31) {
  //       var matches = this.viewTemplateName.match(/\b(\w)/g);
  //       filename = matches.join('').toUpperCase() + '.xlsx';
  //     }
  //     else {
  //       filename = '' + this.viewTemplateName + '.xlsx';
  //     }
  //   }
  //   else if (orgname != 'undefined') {
  //     if (orgname != undefined || orgname != null) {
  //       if (orgname.length > 31) {
  //         var data = orgname.match(/\b(\w)/g);
  //         filename = data.join('').toUpperCase() + '.xlsx';
  //       }
  //       else {
  //         filename = '' + orgname + '.xlsx';
  //       }
  //     }
  //     else {
  //       filename = 'CaseComments.xlsx';
  //     }
  //   }
  //   else {
  //     filename = 'CaseComments.xlsx';
  //   }
  //   var sheet = XLSX.utils.book_new();
  //   let array = [];
  //   array.push(this.viewCommentsData);
  //   let sheetData = XLSX.utils.json_to_sheet(array);
  //   XLSX.utils.book_append_sheet(sheet, sheetData, '');
  //   XLSX.writeFile(sheet, filename);
  // }
  // get formcontrol() {
  //   return this.caseCommentData.controls;
  // }
  // public GetQuery(keyword: string) {
  //   // let query: string = '';
  //   let mainQueryIndex: number = this.VitalHttpServices.mainQueryList.findIndex(
  //     (mainQuery) =>
  //       mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase()
  //   );
  //   if (mainQueryIndex > -1)
  //     return this.VitalHttpServices.mainQueryList[mainQueryIndex].Query;

  // }
  // initializeGrid(grid) {
  //   grid.collapseGroupsToLevel(0); //for collapsing the rows to single row
  //   this.selector = new Selector(grid, {
  //     itemChecked: () => {
  //       var va = grid.rows.filter((r) => r.isSelected);
  //       this.selectedUserId.push(va);
  //       this.userIdsArray = [];
  //       for (
  //         let i = 0;
  //         i < this.selectedUserId[this.selectedUserId.length - 1].length;
  //         i++
  //       ) {
  //         this.userIdsArray.push(
  //           this.selectedUserId[this.selectedUserId.length - 1][i]._data.UserId
  //         );
  //       }
  //     },
  //   });
  // }
  public setForm() {
    this.formGroup = new FormGroup({
      name: new FormControl(this.usersListArray, Validators.required),
    });
    // this.loadContent = true;
  }

  // get formdata() {
  //   return this.formGroup.controls;
  // }
  onCheckChange(item: any) {
    this.caseCommentUsers.push(item.UserId);
  }

  onSelectAll(items: any) {
    this.caseCommentUsers = [];
    for (let itr = 0; itr < items.length; itr++) {
      this.caseCommentUsers.push(items[itr].UserId);
    }
  }

  onItemDeSelect(items: any) {
    const index: number = this.caseCommentUsers.indexOf(items.UserId);
    if (index !== -1) {
      this.caseCommentUsers.splice(index, 1);
    }
  }
  public onDeSelectAll() {
    this.caseCommentUsers = [];
  }
  //#region  Copy from other Organization

  selectedUserGridData(item) {
    this.hideUserCopyToOrg = true;
    this.ngxService.start();
    this.headerName = "User Level Case Comments"
    try {
      if (this.UserListData && this.UserListData.length > 0) {
        this.Edituserlevel = false;

        if (this.userFlag) {
          this.selectedUserData = [];
          this.selectedUserData = this.UserListData
            .filter(va => va.UserId.toString() === item.UserId.toString())
            .map(user => {
              // user["User"] = this.tabService.tabs[0].tabData.mainCard.Full_Name + "(" + this.selectedRole + ")";
              user["User"] = user['UserId'];
              return user;
            });
        } else {
          if(this.sourceApp === 'VitalDx'){
            //&& va.CaseType === this.templateData.secondarykeys.casetype.toString()
            if(this.selectedCasetypeForLabadmin){
              this.selectedUserData = this.UserListData
              .filter(va => va.UserId.toString() === item.userid.toString() && va.CaseType.toLowerCase()==this.selectedCasetypeForLabadmin.toString().toLowerCase())
              .map(user => {
                user["User"] = user['UserId'];
                return user;
              });
            }else{
              this.selectedUserData = this.UserListData
              .filter(va => va.UserId.toString() === item.userid.toString() )
              .map(user => {
                user["User"] = user['UserId'];
                return user;
              });
            }
          }else{
            this.selectedUserData = this.UserListData
            .filter(va => va.UserId.toString() === item.userid.toString() &&  va.CaseType === this.templateData.secondarykeys.casetype.toString())
            .map(user => {
              // user["User"] = item.username + "(" + item.rolename + ")";
              user["User"] = user['UserId'];
              return user;
            });
          }
        }

        this.tableGrid = true;
        let gridarray = []
        let primary = {}
        if (this.selectedUserData != null) {
          if (this.selectedUserData.length > 0) {
            for (let i = 0; i < this.selectedUserData.length; i++) {
              primary = {}
              for (let [key, value] of Object.entries(this.selectedUserData[i])) {
                for (let j = 0; j < this.gridHeader.length; j++) {
                  if (key === this.gridHeader[j]) {
                    primary[key] = value
                  }
                  if (key === 'UserId') {
                    //this.userFlag = true;
                  }
                }
              }
              this.tempExportData = [primary];
              gridarray.push(primary)
              this.exportButtonFlag = true;
            }
            // gridarray.sort((a, b) => a.Sequence > b.Sequence ? 1 : -1).sort((a, b) => a.Sitename < b.Sitename ? 1 : -1).sort((a, b) => a.Case_Type > b.Case_Type ? 1 : -1)
            //gridarray = this.groupBy(gridarray,'sitename', 'Case_Type')
            let groupedData = this.groupBy(gridarray, 'SiteName', 'CaseType')
            let finalTemplate: any[] = [];
            if (groupedData !== undefined) {
              for (let [key, value] of Object.entries(groupedData)) {
                if (Array.isArray(value)) {
                  // value= value.sort((a,b)=> a.Case_Type.localeCompare(b.Case_Type));

                  // for (let i = 0; i < value.length; i++) {
                  //   // value[i].sequnece = i + 1;
                  // }
                }
                finalTemplate = finalTemplate.concat(value);
              }
            }
            // finalTemplate.sort((a, b) => a.Case_Type > b.Case_Type ? 1 : -1).sort((a, b) => a.Sitename > b.Sitename ? 1 : -1).sort((a, b) => a.Sequence < b.Sequence ? 1 : -1)
            gridarray = finalTemplate.sort((a, b) => a?.CaseType?.localeCompare(b.CaseType) || a?.SiteName?.localeCompare(b?.SiteName) || a?.Sequence - b?.Sequence);
            this.gridData = new CollectionView(gridarray)
            if (gridarray.length > 10) {
              this.showPaginationMainGrid = true;
            } else {
              this.showPaginationMainGrid = false;
            }
            this.gridwidth = (170 * this.gridHeader.length) + 37;
            if (this.gridwidth > 1300)
              this.gridwidth = 1300;
            if (this.gridwidth != 0) {
              this.gridwidth = this.getpercentage(this.gridwidth)
            }
          }
          else {
            // this.exportButtonFlag = false;
            this.gridwidth = 0;
            this.gridData = new CollectionView([]);
          }
        }
        else {
          this.gridwidth = 0;
          this.gridData = new CollectionView([]);
        }
      }
      else {
        this.gridwidth = 0;
        this.gridData = new CollectionView([]);
      }

    } catch (e) {
      this.gridwidth = 0;
      this.gridData = new CollectionView([]);
      this.ngxService.stop();
    }
    this.ngxService.stop();
  }
  //filter users
  UserGridData(e, item) {
    this.isAllUser = false;
    if (item == 'All User') {
      this.CaseComments = item;
      this.isAllUser = true;
      this.userFlag = false;
      //this.listedgridheader = this.listHeaderbackup.filter(e => e.Name.toLowerCase() !== 'case type')
      this.listedgridheader = this.listHeaderbackup.filter(e => e.Name != 'User')
      return this.AddGridData();
    } else {
      this.isAllUser = false;
      this.listedgridheader = this.listHeaderbackup.filter(e => e.Name != 'User')
    }
    if (e?.source?.selected && item !== 'All User') {
      this.inData = null;
      this.inData = item.username.match(/Organization/i) ? item.username : item.userid
      if (this.inData.toString().toLowerCase() != 'organization') {
        this.CaseComments = 'User'
        this.PreviousUserdata = item;
        this.underorguserlevel = true;
        this.getOrgCasetype();
        this.selectedUserGridData(item);
      }
      if (this.inData.toString().toLowerCase() == 'organization') {
        this.CaseComments = 'Organization';
      }
      this.migrateEnabling(item)
    }
    // if (e.source.selected) {
    //   this.selectedUserGridData(item);
    // }
  }
  //#region Filter users
  filterUsers(value: string) {
    //await this.getListUsers( sessionStorage.getItem('org_id') )
    this.showOrgInDrop = true;
    this.userNamesList = this.userList
    if (value) {
      if (!value.replace(/\s/g, '').length) {
        this.showOrgInDrop = true;
        return
      }
      else {
        this.userNamesList = this.userNamesList.filter(va => va.username.toLowerCase().includes(value.toLowerCase()))
        this.showOrgInDrop = 'organization templates'.includes(value.toLowerCase()) ? true : false;
      }
    }
  }
  //#endregion
  //#region for copy button
  public GetQuery(keyword: string) {
    let query: string = "";
    let mainQueryIndex: number = this.VitalHttpServices.mainQueryList.findIndex(mainQuery => mainQuery.Keyword.toLocaleLowerCase() == keyword.toLocaleLowerCase());
    if (mainQueryIndex > -1)
      query = this.VitalHttpServices.mainQueryList[mainQueryIndex].Query;
    return query;
  }

  copyOrg() {
    this.destDeployment = this.dbname//sessionStorage.getItem('deploymentKey').toUpperCase();
    this.srcDeployment = this.destDeployment
    this.copyDataClicked = true;
    this.tableGrid = false;
    this.uploadClicked = false;
    this.postUpload = false;
    this.backBtn = true;
    this.srcOrgid = ''
    this.copycollectionMethodForm.reset()
    this.copycollectionMethodForm.patchValue({
      frmOrganization: "",
      frmUser: "",
      frmDepKey: this.destDeployment,
      frmOrgType: "Laboratory"
    })
    this.getListOrg()
    this.getOrgCasetype();
    if(!this.copycollectionMethodForm.controls.frmDepKey.value){
      this.copycollectionMethodForm.controls.frmOrganization.disable();
      this.copycollectionMethodForm.controls.frmUser.disable();
    }
    else{
      this.copycollectionMethodForm.controls.frmOrganization.enable();
      this.copycollectionMethodForm.controls.frmUser.disable();
    }
  }

  getListUsers(org_id) {
    let orgid = org_id === null ? sessionStorage.getItem('org_id') : org_id;
    let query = this.subMenuCardModel.GetQuery('getuserscasecomments');
    let role;
    let queryVariable;
    if (!this.userFlag) {
      if (this.commonService.finalizeAfterTc) {
        role = 'Lab Manager,Lab Director,Technician'
      }
      else if (!this.commonService.finalizeAfterTc && this.commonService.isScreeningEnabled) {
        role = 'Cytotechnologist Supervisor,Cytotechnologist,Pathologist,Pathologist Assistant,Screening Technologist'
      }
      else if (!this.commonService.finalizeAfterTc && !this.commonService.isScreeningEnabled) {
        role = 'Pathologist,Pathologist Assistant,Screening Technologist'
      }
      queryVariable = {
        "orgid": orgid.toString(), "filtername": "rolename in \"" + role.toString() + "\":string []"
      };
    }
    else {
      queryVariable = {
        "orgid": orgid.toString()
      };
    }

    //let queryVariable = { "orgid": orgid.toString() };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    let dbName;
    dbName = this.srcDeployment;
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      if (!res.errors) {
        this.userList = res.data.Card;
        this.name = res.data.Card;
        this.userListArray = res.data.Card;
        this.userList.sort((a, b) => a.username < b.username ? -1 : a.username > b.username ? 1 : 0)
        // let obj = { "userid": "", "username": "Organization", "rolename": "Org-Level" }
        // this.userList.unshift(obj);
        this.ngxService.stop();
      }
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  getListOrg() {
    this.orgList = [];
    let dbName;
    let query = this.GetQuery('adduserfilterorg');
    let queryString = null
    dbName = this.srcDeployment;

    let queryVariable = { "filterParam": queryString };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(res => {
      if (res.data.Organizations_list && res.data.Organizations_list.length > 0) {
        this.orgList = res.data.Organizations_list;
      }
      this.ngxService.stop();
    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  //on changes deployment key
  onChangeDeployment(e, DeploymentKey) {
    if (e.source.selected) {
      this.srcDeployment = DeploymentKey;
      this.searchInput = '';
      this.searchUserInput = '';
      this.usertoggle = false;
      this.searchResult = []
      this.searchuserlist = []
      this.srcOrgType = "Laboratory"
      this.removeGrid();
      this.gridWidth = -1
      this.resetCopyForm();
      this.getListOrg();
      this.copycollectionMethodForm.controls.frmOrganization.enable();
      this.copycollectionMethodForm.controls.frmUser.disable();
    }
  }
  //

  disableGetDataBtn() {
    return this.searchInput == '' ? true : (this.srcOrgid && this.srcOrgid) != '' ? false : true
  }

  resetCopyForm() {
    this.copycollectionMethodForm.patchValue({
      frmOrganization: "",
      frmUser: "",
      frmDepKey: this.srcDeployment,
      frmOrgType: this.srcOrgType
    })
  }

  fetchUserSeries(value: string) {
    value = value?.toString().trim();
    // this.removeGrid();
    this.searchuserlist = []
    if (!value.trim()) {
      const userListCopy = this.userListArray.slice();
      return (this.searchuserlist = userListCopy.splice(0, 25));
    }
    this.usertoggle = false;
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 0) {
        this.userListArray.find(r => {
          if (r.username.toString().toLowerCase() === value.toLowerCase() && r.username.toLowerCase() != 'organization') {
            this.srcUserid = r.userid
            return r
          } else {
            this.srcUserid = ''
            this.usertoggle = true
          }
        })
        this.searchuserlist = this.userListArray.filter(function (series) {
          if (series && series.username != null) {
            return series.username.toString().toLowerCase().includes(value.toLowerCase()) && series.username.toLowerCase() != 'organization';
          }
        });
        this.searchuserlist = this.searchuserlist.splice(0, 25);
      }
    }
    else {
      this.searchuserlist = this.userListArray.filter(function (series) {
        if (series && series.userid != null) {
          return series.userid.toString().toLowerCase().includes(value.toLowerCase()) && series.username.toLowerCase() != 'organization';
        }
      });
      this.searchuserlist = this.searchuserlist.splice(0, 25);
    }
  }

  selectedCopyDataOrganization(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.gridWidth = -1;
      this.srcUserid = '';
      this.searchUserInput = '';
      this.searchuserlist = [];
      this.srcOrgid = data.organizationid;
      this.srcOrgName = data.organizationname;
      this.searchInput = data.organizationname;
      this.getListUsers(this.srcOrgid);
      this.copycollectionMethodForm.controls.frmUser.enable();

    }
  }

  selectedCopyDataUser(event, data) {
    if (event.source.selected) {
      this.removeGrid();
      this.gridWidth = -1;
      this.usertoggle = false;
      this.srcUserid = data.userid;
      this.srcUserName = data.username;
      this.searchUserInput = data.username;
      this.copycollectionMethodForm.controls.frmUser.enable();
    }
  }

  //#region
  removeGrid() {
    // this.gridWidth = this.copyDataClicked ? -1 : 0;
    this.gridWidth = 0;
    this.excelDataArray = [];
    this.postUpload = false;
    this.backBtn = true;
    this.postDownload = false;
    this.showInvalidColumns = false;
    this.invalidColumns = '';
    this.showDelete = true;
  }
  //#endregion

  //#region Get data to copy-from-other-entity
  getDataToCopy() {
    this.removeGrid()
    this.gridWidth = -1;
    this.uploadClicked = false;
    this.postUpload = false;
    this.backBtn = true;
    let queryVariable;
    if (this.userFlag) {
      queryVariable = { casetype: null, orgid: this.srcOrgid.toString() };
    }
    else {
      queryVariable = { casetype: this.templateData.secondarykeys.casetype.toString(), orgid: this.srcOrgid.toString() };
    }
    let query = this.subMenuCardModel.GetQuery("OrgCaseComments");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, this.srcDeployment).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        if (this.srcUserid == '' && (this.searchUserInput == null || this.searchUserInput == '')) {
          if (data.data.submenuData.length > 0) {
            this.backBtn = false
            this.manageDataColumn(data.data.submenuData);
          } else {
            this.backBtn = true
            this.excelGridData([]);
          }
        }
        else {
          if (!this.usertoggle) {
            if (data.data.submenuData1.length > 0) {
              this.backBtn = false
              let userSpecificData = [];
              userSpecificData = data.data.submenuData1.filter(u => u.userid == Number(this.srcUserid));
              if (userSpecificData.length > 0) {
                this.manageDataColumn(userSpecificData);
              }
              else {
                this.backBtn = true
                this.excelGridData([]);
              }
            }
            else {
              this.backBtn = true
              this.excelGridData([]);
            }
          } else {
            this._snackbar.open("Please enter valid user", "Close");
            return
          }
        }
      }
    })
  }

  manageDataColumn(data) {
    var i = 1;
    // for (i = 0; i < data.length; i++) {
    //   data[i].displayname = data[i]['Display_Name'];
    //   data[i].isuserleveladequacycodeenabled = data[i]['Is_User_Level_Enabled'];
    //   data[i].isactive = data[i]['Status'];
    //   delete data[i].Status;
    //   delete data[i].Is_User_Level_Enabled;
    //   delete data[i].Display_Name;
    //   delete data[i].Sequence;
    //   delete data[i].orgid;
    //   delete data[i].CreatedBy;
    //   delete data[i].ModifiedBy;
    //   delete data[i].ModifiedDate;
    //   delete data[i].CreatedDate;
    // }
    if (this.copyDataClicked) {
      this.sheetHeader = ["isactive", "sequence", "type", "isdefault", "description", "templatename", "casetype", "notes"]//Object.keys(data[0]);
    }
    this.excelDataArray = [];
    this.excelDataArray = this.convertObjKeysToLower(data);
    if (this.userFlag) {
      this.excelDataArray.find((d): any => {
        Object.assign(d, {
          slno: Number(i), notes: '', tablename: 'OrganizationCommentsTemplates', organizationid: Number(sessionStorage.getItem('org_id')),
          casetype: d.casetype, userid: !this.inData ? null : Number(this.inData)
        });
        i++;
      });
    }
    else {
      this.inData = this.CaseComments.toLowerCase() === 'organization' ? null : this.inData
      this.excelDataArray.find((d): any => {
        Object.assign(d, {
          slno: Number(i), notes: '', tablename: 'OrganizationCommentsTemplates', organizationid: Number(sessionStorage.getItem('org_id')),
          casetype: this.templateData?.cardtype?.toString(), userid: !this.inData ? null : Number(this.inData)
        });
        i++;
      });
    }
    this.validateExcelData(this.excelDataArray);
  }
  //#endregion

  //#region Validate Excel/(Copy from other entity data)
  validateExcelData(excelArr) {
    this.ngxService.start();
    this.dbname;
    if (this.bulkUpload || this.copyDataClicked) {
      this.VitalHttpServices.ValidateCaseComments(excelArr, this.dbname).subscribe(result => {
        this.ngxService.stop();
        if (!result.errors) {
          for (let i = 0; i < excelArr.length; i++) {
              if (this.bulkUpdateFile && (this.missingColumn.some(e=> e.toLowerCase() == 'id') || (!excelArr[0].isactive && !excelArr[0].description))) {
                this._snackbar.open("Please provide valid data", 'Failed');
                this.ngxService.stop();
                return;
            }
          }

          if (result.length > 0) {
            this.ngxService.stop();
            if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
              this.showInvalidColumns = true;
              this.invalidColumns = result[0].InvalidColumns;
            }
            excelArr.filter(e => {
              result.find(r => {
                if (r.SlNo === e.slno) {
                  e.notes = r.NoteMessage;
                }
              });
            });
            this.ngxService.stop();
            this.excelGridData(excelArr);
          }
          else {
            this._snackbar.open("Please provide valid data", 'Failed');
          }
        }
        this.ngxService.stop();
      }, error => {
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", 'Failed');
        console.log(error);
      });
    } else {
      this.VitalHttpServices.ValidateBUlkUpdateCaseComments(excelArr, this.dbname).subscribe(result => {
        this.ngxService.stop();
        if (!result.errors) {
          if (result.length > 0) {
            this.ngxService.stop();
            if (result[0]["InvalidColumns"] && result[0]["InvalidColumns"].length > 0) {
              this.showInvalidColumns = true;
              this.invalidColumns = result[0].InvalidColumns;
            }
            excelArr.filter(e => {
              result.find(r => {
                if (r.SlNo === e.slno) {
                  e.notes = r.NoteMessage;
                }
              });
            });
            this.ngxService.stop();
            this.excelGridData(excelArr);
          }
          else {
            this._snackbar.open("Please provide valid data", 'Failed');
          }
        }
        this.ngxService.stop();
      }, error => {
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", 'Failed');
        console.log(error);
      });
    }

  }
  //#endregion

  //#region Grid Data : uploaded data with Notes column
  excelGridData(data) {
    this.ngxService.stop();
    this.gridDisplay = true;
    this.gridArrays = [];
    this.gridData = new CollectionView([]);
    let primary = {}
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          let mandatoryFieldEmpty = false
          let checkDataType = false
          let dataTypeCol;
          for (let [key, value] of Object.entries(data[i])) {
            if (!key.toString().toLowerCase().match(/empty/i)) {
              let flag = false;

              if (key.toLowerCase() == 'isactive') {
                if (value === 'false' || value === false || value === 0) {
                  value = false;
                } else if (value === 'true' || value === true || value === 1 ||(!this.bulkUpdateFile && value == '') || value === null) {
                  value = true;
                } else if(this.bulkUpdateFile && value == ''){
                  value = '';
                }
                primary[key] = value;
              }

              if (!this.bulkUpdateFile && (key.toString().toLowerCase() != 'templateName' && key.toString().toLowerCase() != 'description')) {
                if (value != null) {
                  if (value.toString().toLowerCase().trim() == 'null') {
                    flag = true;
                  }
                  primary[key] = value;
                }
              }

              if(this.bulkUpdateFile && (key.toString().toLowerCase() == 'description')){
                if(value === null || value === 'null'){
                  value = null;
                }else if(value === ''){
                  value = '';
                }
              }

              if (key.toLowerCase() == 'isdefault') {
                if (value == 'true' || value == true) {
                  value = true
                } else if (value == 'false' || value == false || !value) {
                  value = false
                }
                primary[key] = value;
              }
              // flag ? primary['notes'] = 'Null values exist!' : data[i]['notes'] == '' ? primary['notes'] = 'Valid' : null
              // if (key.toString().toLowerCase() == 'name' || key.toString().toLowerCase() == 'displayname' || key.toString().toLowerCase() == 'description') {
              //   // if (value == null || value.toString().toLowerCase().trim() == 'null' || (!/\S/.test(value.toString()))) {
              //   //   mandatoryFieldEmpty = true;
              //   // }
              //   primary[key] = value;
              // }

              if ( !this.bulkUpdateFile && checkDataType) {
                value = dataTypeCol;
                this.excelDataArray[i]["notes"] = value;
                primary['notes'] = value;
              }

              // if (mandatoryFieldEmpty) {
              //   value = 'Mandatory field is missing';
              //   this.excelDataArray[i]["notes"] = value;
              //   primary['notes'] = value;
              // }
              primary[key] = value;
            }
          }
          if((primary['description'] == null ||  primary['description'] == 'null')&& this.bulkUpdateFile){
            primary['notes'] = 'Mandatory field is missing'
          }

          this.gridArrays.push(primary);
        }
        if (this.copyDataClicked && this.postUpload) {
          this.selector.column.grid.headersVisibility = HeadersVisibility.Column
        }
        this.excelDataArray = this.gridArrays;
        this.gridData = new CollectionView(this.gridArrays)
        this.gridWidth = (120 * this.sheetHeader.length) + 37;
        if (this.gridWidth > 1300) {
          this.gridWidth = 1300;
        }
      }
      else {
        this.gridWidth = 0;
      }
    }
    else {
      this.gridWidth = 0;
    }
  }
  //#endregion

  //#region Convert Obj to lower
  convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        let value = ""
        value = typeof (inputArr[i][key]) == 'string' ? inputArr[i][key].replace(/\s+/g, " ") : inputArr[i][key]
        newobj[key.replace(/\s/g, "").toLowerCase()] = value
      }
      array.push(newobj)
    }
    return array;
  }
  //#endregion
  //#region copy-from-entity: orglist

  fetchOrgSeries(value: string) {
    value = value?.toString().trim();
    this.searchResult = []
    this.searchuserlist = [];
    this.searchUserInput = '';
    this.srcUserid = '';
    if (!value.trim()) {
      this.searchuserlist = [];
      let copyOrglist=this.orgList.slice();
      return (this.searchResult = copyOrglist.splice(0, 25));
    }
    let idValidate = /^[0-9]*$/
    if (!idValidate.test(value)) {
      if (value.length > 2) {
        this.orgList.find(r => {
          if (r.organizationname) {
            if (r.organizationname.toString().toLowerCase() === value.toLowerCase()) {
              this.srcOrgid = r.organizationid
              return r
            }
            else {
              this.srcOrgid = ''
            }
          }
        })
        this.searchResult = this.orgList.filter(function (series) {
          if (series && series.organizationname != null) {
            return series.organizationname.toString().toLowerCase().includes(value.toLowerCase());
          }
        });
        this.searchResult = this.searchResult.splice(0, 25);
      }
    }
    else {
      this.orgList.find(r => {
        if (r.organizationid.toString().toLowerCase() === value.toLowerCase()) {
          this.srcOrgid = r.organizationid
          return r
        }
        else {
          this.srcOrgid = ''
        }
      })
      this.searchResult = this.orgList.filter(function (series) {
        if (series && series.organizationid != null) {
          return series.organizationid.toString().toLowerCase().includes(value.toLowerCase());
        }
      });
      this.searchResult = this.searchResult.splice(0, 25);
    }
  }

  checkValue(event) {
    if (event.target.checked) {
      this.checkStatus = true;
    } else {
      this.checkStatus = false;
    }
  }

  initializeGrid(flexgrid) {
    this.selectedItems = [];
    flexgrid.collapseGroupsToLevel(0);
    setTimeout(function () {
      if (this.flexgrid) this.flexgrid.refresh(true);
    });
    this.selector = new Selector(flexgrid, {
      itemChecked: () => {
        var va = flexgrid.rows.filter((r) => r.isSelected);
        this.selectedItems = va;
      },
    });
    const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  //Upload to database the select one
  uploadCopyData(flexgrid) {
    this.excelDataArray = [];
    this.selector = new Selector(flexgrid, {
      itemChecked: () => {
        this.selectedItems = flexgrid.rows.filter(r => r.isSelected);
      }
    });
    for (let i = 0; i < this.selectedItems.length; i++) {
      this.excelDataArray.push(this.selectedItems[i]._data);
    }
    if (this.CaseComments.toLowerCase() === 'organization') {
      for (let i = 0; i < this.excelDataArray.length; i++) {
        // delete this.excelDataArray[i].isuserleveladequacycodeenabled;
        // delete this.excelDataArray[i].isactive;
      }
    }
    if (this.userFlag) {
      this.validateCasetype()
    } else {
      this.uploadData();
    }
  }

  //#region Upload ExcelData to Insert API
  uploadData() {
    let dataArray = [];
    if (this.excelDataArray && this.excelDataArray.length > 0) {
      let errorcount = 0;
      for (let i = 0; i < this.excelDataArray.length; i++) {
        if (!this.excelDataArray[i]["notes"]) {
          if (this.copyDataClicked) {
            this._snackbar.open('Copying data failed! Please check the data for datatype mismatch.', 'Close');
          }
          else {
            this._snackbar.open('Data upload failed! Please check the data for datatype mismatch.', 'Close');
          }
        }
        else if (!this.excelDataArray[i]["notes"].toString().match(/approval/i) && !this.excelDataArray[i]["notes"].toString().match(/already exist/i) && !this.excelDataArray[i]["notes"].toString().match(/accepts only/i) && !this.excelDataArray[i]["notes"].toString().match(/valid/i) && !this.excelDataArray[i]["notes"].toString().match(/cannot be reverted/i)) {
          errorcount++
        }
      }
      if (errorcount > 0) {
        this._snackbar.open("Some of the data in the rows have issues. Please fix them and try again", "Close");
        return;
      }
      if ((this.invalidColumns != "")) {
        this._snackbar.open("Invalid columns found! Please upload valid data.", "Close");
        return
      }
      else {
        for (let i = 0; i < this.excelDataArray.length; i++) {
          // for (let [key, value] of Object.entries(this.excelDataArray[i])) {
          //   if (value === "null" || value === "" || value === null) {
          //     this.excelDataArray[i][key] = null;
          //   }
          //   else {
          //     this.excelDataArray[i][key] = value.toString();
          //   }
          // }
          for (let i = 0; i < this.excelDataArray.length; i++) {
            delete this.excelDataArray[i]["slno"]
            delete this.excelDataArray[i]["tablename"]
            delete this.excelDataArray[i]["notes"]
            this.excelDataArray[i]["createdby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
            this.excelDataArray[i]["modifiedby"] = sessionStorage.getItem("Userid") == null || sessionStorage.getItem("Userid") == '' ? -100 : sessionStorage.getItem("Userid")
            this.excelDataArray[i]["organizationid"] = sessionStorage.getItem('org_id').toString();
            this.excelDataArray[i]["casetype"] = !this.userFlag ? this.templateData.cardtype.toString() : this.excelDataArray[i]["casetype"];
            if (this.copyDataClicked) {
              this.excelDataArray[i]["isactive"] = (this.copyDataClicked) ? (this.checkStatus ? false : true) : true
            }
          }
        }
        if (!this.userFlag) {
          dataArray = (this.CaseComments.toLowerCase() != 'organization') ? this.excelDataArray.map(v => ({ ...v, userid: this.inData })) : this.excelDataArray;
        }
        else {
          dataArray = this.excelDataArray.map(v => ({ ...v, userid: this.inData }))
        }
      }
      this.ngxService.start();
      if (this.bulkUpload || this.copyDataClicked) {
        this.VitalHttpServices.BulkUploadCaseComments(dataArray, this.dbname).subscribe(result => {
          this.ngxService.stop();
          if (!result.errors) {
            if (result.length > 0) {
              this.checkStatus = false;
              this.postUpload = true;
              this.postDownload = true;
              this.showDelete = false;
              if (this.copyDataClicked && this.postUpload) {
                this.copycollectionMethodForm.controls.frmOrganization.disable();
                this.copycollectionMethodForm.controls.frmUser.disable();
              }
              this.excelDataArray = [];
              this.excelDataArray = this.convertObjKeysToLower(result);

              for (let i = 0; i < result.length; i++) {
                this.excelDataArray[i]["notes"] = result[i]["NoteMessage"];
                if (result[i]["NoteMessage"].match(/already exists/)) {
                  this.excelDataArray[i]["status"] = "Ignored"
                }
                else {
                  this.excelDataArray[i]["status"] = result[i]["STATUS"]
                  this.commonService.auditDetails('', '', [], dataArray, 'Upload', this.templateData, this.auditableColumns);
                }
              }
              this.excelGridData(this.excelDataArray);
              this.ngxService.start();
              // for (let i = 0; i < result.length; i++) {
              //   // this.commonService.createActivityTracker({}, result[i].ID, 'Casecomments', 'Audit', result[i], {});
              // }
              this.ngxService.stop();
            }
            else {
              this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close');
            }
          }
        }, error => {
          this.ngxService.stop();
          this._snackbar.open('Something went wrong.Please try again', 'Close');
          console.error(error)
        })
      } else {
        this.VitalHttpServices.BulkUpdateCaseComments(dataArray, this.dbname).subscribe(result => {
          this.ngxService.stop();
          if (!result.errors) {
            if (result.length > 0) {
              this.checkStatus = false;
              this.postUpload = true;
              this.postDownload = true;
              this.showDelete = false;
              this.excelDataArray = [];
              this.excelDataArray = this.convertObjKeysToLower(result);

              for (let i = 0; i < result.length; i++) {
                this.excelDataArray[i]["notes"] = result[i]["NoteMessage"];
                if (result[i]["NoteMessage"].match(/already exists/)) {
                  this.excelDataArray[i]["status"] = "Ignored"
                }
                else {
                  this.excelDataArray[i]["status"] = result[i]["Status"]
                  this.commonService.auditDetails('', '', [], dataArray, 'Bulk Update', this.templateData, this.auditableColumns);
                }
              }
              this.excelGridData(this.excelDataArray);
              this.ngxService.start();
              // for (let i = 0; i < result.length; i++) {
              //   this.commonService.createActivityTracker({}, result[i].ID, 'Casecomments', 'Audit', result[i], {});
              // }
              this.ngxService.stop();
            }
            else {
              this._snackbar.open('Data upload failed! Please check the data for type mismatch.', 'Close');
            }
          }
        }, error => {
          this.ngxService.stop();
          this._snackbar.open('Something went wrong.Please try again', 'Close');
          console.error(error)
        })
      }

    }
  }
  //#endregion

  //#region User/Pathologist Cardtype: casetype validation
  validateCasetype() {
    let temp1 = []
    for (let i = 0; i < this.caseTypeArray.length; i++) {
      temp1.push(this.caseTypeArray[i].casetype)
    }
    let configuredCasetype = [];
    configuredCasetype = this.excelDataArray.filter(item => temp1.includes(item.casetype))
    let invalidCasetype = [];
    let temp = [];
    invalidCasetype = this.excelDataArray.filter(item => !temp1.includes(item.casetype))
    for (let i = 0; i < invalidCasetype.length; i++) {
      temp.push(invalidCasetype[i].casetype)
    }
    let displayInvalidCasetype = [...new Set(temp)];
    if (displayInvalidCasetype.length > 0) {
      this.repeatedNames = '';
      let str = '';
      for (let i = 0; i < displayInvalidCasetype.length; i++) {
        str += displayInvalidCasetype[i] + ', '
      }
      if (configuredCasetype.length > 0) {
        this.excelDataArray = configuredCasetype;
        this.uploadData();
      }
      this.repeatedNames = str.substring(0, str.length - 2);
      this.invalidCasetypeFlag = true;
    }
    else {
      if (configuredCasetype.length > 0) {
        this.excelDataArray = configuredCasetype;
        this.uploadData();
      }
    }
  }
  //#endregion

  getOrgdata(event) {
    if (event.source.selected) {
      this.underorguserlevel = false;
      this.CaseComments = 'Organization'

      this.listedgridheader = this.listedgridheader.filter(e => e.Name != 'User')
      if(this.sourceApp !=='VitalDx'){
        this.refreshGrid();
      }
    }
  }
  //#region Back to upload
  refreshGrid(selectedCasetype?) {
    //this.editEnabled = false;
    this.isAllUser = false
    this.templateData.submenuData = [];
    this.tableGrid = true;
    this.uploadClicked = false;
    this.copyDataClicked = false;
    this.gridDisplay = false;
    this.caseCommentData.reset();
    this.gridArrays = [];
    this.excelDataArray = [];
    this.gridData = new CollectionView([]);
    this.removeGrid();
    let caseTypes = []
    let queryVariable
    if (this.underorguserlevel) {
      for (let i = 0; i < this.caseTypeList.length; i++) {
        caseTypes.push(this.caseTypeList[i].CaseType)
      }
      queryVariable = { "filterParam": "CaseType in \"" + caseTypes.toString() + "\":string [] ", orgid: sessionStorage.getItem('org_id').toString() };
    }
    else {
      queryVariable = { "casetype": this.templateData?.cardtype?.toString(), orgid: sessionStorage.getItem('org_id').toString() };
    }

    if(selectedCasetype){
      queryVariable = { "casetype": selectedCasetype.toString(), orgid: sessionStorage.getItem('org_id').toString() };
    }

    if(!queryVariable['casetype']) {
      delete queryVariable['casetype'];
    }

    // let queryVariable = { casetype: !this.userFlag ? this.templateData.secondarykeys.casetype.toString() : null, orgid: sessionStorage.getItem('org_id').toString() };
    let query = this.subMenuCardModel.GetQuery("mastercasecomments");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, this.dbname).subscribe(data => {
      this.ngxService.stop();
      if (!data.errors) {
        if (this.CaseComments.toLowerCase() === 'organization') {
          this.templateData.submenuData = [];
          this.templateData.submenuData = data.data.submenuData;
          if (data.data.submenuData.length > 0) {
            this.templateData.submenuData.submenuData1 = [];
            this.templateData.submenuData.submenuData1 = data.data.submenuData1;
            this.UserListData = this.templateData.submenuData.submenuData1;
            this.templateData.submenuData.submenuData2 = [];
            this.templateData.submenuData.submenuData2 = data.data.submenuData2;
            this.UserList = this.templateData.submenuData.submenuData2

          } else {
            this.templateData.submenuData.submenuData1 = [];
            this.templateData.submenuData.submenuData1 = data.data.submenuData1;
            this.UserListData = this.templateData.submenuData.submenuData1;
            this.templateData.submenuData.submenuData2 = [];
            this.templateData.submenuData.submenuData2 = data.data.submenuData2;
            this.UserList = this.templateData.submenuData.submenuData2
          }
          //this.AddGridData();
          this.loadInitialData()
        }
        else {
          if (data.data.submenuData.length > 0) {
            this.templateData.submenuData.submenuData1 = [];
            this.templateData.submenuData.submenuData1 = data.data.submenuData1;
            this.UserListData = this.templateData.submenuData.submenuData1;
            this.templateData.submenuData.submenuData2 = [];
            this.templateData.submenuData.submenuData2 = data.data.submenuData2;
            this.UserList = this.templateData.submenuData.submenuData2
          } else {
            this.templateData.submenuData1 = [];
            this.templateData.submenuData1 = data.data.submenuData1;
          }
          if (!this.underorguserlevel) {
            this.AddGridData();
          }
          else {
            this.refreshUserlevelGrid();
          }
        }
      }
    }, error => {
      console.error(error);
    });
  }
  //#endregion

  //#endregion
  //#region Download All Fields
  downloadAllFields() {
    let sample = true
    if (this.templateData.secondarykeys == undefined || this.templateData.secondarykeys == null) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = 'Case Comments_' + 'All Fields_' + this.organizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.AllFields);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Case Comments');
    sample ? ws = XLSX.utils.json_to_sheet(this.sampleDataAll) : null;
    sample ? XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData') : null;
    XLSX.writeFile(wb, filename);
  }
  //#endregion


  //#region Download Madatory Fields
  downloadMadatoryFields() {
    let sample = true
    if (this.templateData.secondarykeys == undefined || this.templateData.secondarykeys == null) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = 'Case Comments_' + 'Minimal Fields_' + this.organizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.MandatoryFields);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Case Comments');
    sample ? ws = XLSX.utils.json_to_sheet(this.sampleDataMin) : null;
    sample ? XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData') : null;
    XLSX.writeFile(wb, filename);
  }

  bulkUpdateFields(){
    this.BulkUpdateFields[0]['Id'] = null;
    this.BulkUpdateFields[0]['Description'] = null;
    this.BulkUpdateFields[0]['IsActive'] = null;
  }

  //#endregion
  downloadBulkUpdateField(isdownload? : boolean) {
    this.bulkUpdateFields();
    let sample = true
    if (this.templateData.secondarykeys == undefined || this.templateData.secondarykeys == null) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = 'Case Comments_' + 'BulkUpdate Fields_' + this.organizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(this.BulkUpdateFields);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'organizationcasecomments');
    sample ? ws = XLSX.utils.json_to_sheet(this.sampleDataUpdate) : null;
    sample ? XLSX.utils.book_append_sheet(wb, ws, "" + 'SampleData') : null;
    XLSX.writeFile(wb, filename);
  }

  //#region Upload screen
  loadUploadScreen() {
    this.invalidCasetypeFlag = false;
    this.uploadClicked = true;
    this.copyDataClicked = false;
    this.tableGrid = false;
    let queryVariable = { tablename: 'OrganizationCommentsTemplates', identity: "false", nullable: "false" };
    let query = this.subMenuCardModel.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult).subscribe(data => {
      if (!data.errors) {
        if (data) {
          let allFields = {}
          for (let i = 0; i < data.data.allFields.length; i++) {
            allFields[data.data.allFields[i]["Column"]] = null
          }
          this.AllFields[0] = allFields
          delete this.AllFields[0]['OrganizationCommentsTemplateGUID']
          delete this.AllFields[0]['SourceGUID']
          delete this.AllFields[0]['isDefault']
          delete this.AllFields[0]['Type']
          delete this.AllFields[0]['SiteId']
          delete this.AllFields[0]['CreatedBy']
          delete this.AllFields[0]['CreatedDate']
          delete this.AllFields[0]['ModifiedBy']
          delete this.AllFields[0]['organizationID']
        
          delete this.AllFields[0]['ModifiedDate']
          delete this.AllFields[0]['AdminKey'],
            delete this.AllFields[0]['IsActive'],
            delete this.AllFields[0]['testCategory'],
            delete this.AllFields[0]['IsUserlevelTemplates'],
            delete this.AllFields[0]['IsUserLevelCaseCommentsTemplateEnabled'],
            delete this.AllFields[0]['ReferenceTemplateId'],
            delete this.AllFields[0]['DivPlaceHolder'],
            this.MandatoryFields[0]['TemplateName'] = null
          this.MandatoryFields[0]['Description'] = null
  // for user card changes.
          if(this.userFlag){
            delete this.AllFields[0]['UserId']
            delete this.AllFields[0]['TestCategory']
          }
          else{
            delete this.AllFields[0]['caseType']
            delete this.AllFields[0]['TestCategory']
          }
        }
      }
    }, error => {
      console.error(error);
    });
  }
  //#endregion



  onFileDropped($event) {
    this.onFileChange($event);
  }

  //#region Method to hit once a file is dragged to or uploaded
  onFileChange(ev) {
    this.bulkUpdateFile = false
    this.UpdateColumnMisMatch = false
    this.minFieldsFile = false
    this.missingColumn = []
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if(filename.toLowerCase().includes('bulkupdate')){
      this.bulkUpdateFile = true
     }
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please upload an excel file only.', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          this.sheetsToSelect = this.sheetsToSelect.filter(va => va.toLowerCase() != 'sampledata');
          if (this.sheetsToSelect.length > 1) {
            let validSheet = this.sheetsToSelect.filter(va => va.toLowerCase() == 'organizationcasecomments')
            this.sheetsToSelect.length == 1 ? this.convertToJson(validSheet[0]) : this.openModal();
          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }
  //#endregion

  //#region Method to validate sheet data.
  convertToJson(sheetname) {
    var worksheet;
    this.excelDataArray = [];
    let tempExcelArr = [];
    let primary = {}
    let tempArray = []
    this.sheetHeader = [];
    let temp = [];
    let i = 1;
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() == sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });
    if (tempExcelArr.length == 0) {
      this._snackbar.open("The uploaded excel does not contain any data", "Close");
      tempExcelArr = [];
      return;
    }

    if(Object.keys(tempExcelArr[0]).length < 2){
      this._snackbar.open("Please Provide Valid Data", "close")
      return;
    }

    this.bulkUpdateFields()
    this.uploadedHeaders = Object.keys(tempExcelArr[0]);
    this.expectedHeaders = this.bulkUpdateFile? Object.keys(this.BulkUpdateFields[0]) : Object.keys(this.AllFields[0]);
    if(this.bulkUpdateFile){
       // Check if arrays are of equal length
       if (this.expectedHeaders.length !== this.uploadedHeaders.length || !(this.expectedHeaders.sort().join(',') === this.uploadedHeaders.sort().join(','))) {
         this.UpdateColumnMisMatch = true;
      }
      this.missingColumn = this.expectedHeaders.filter(header => !this.uploadedHeaders.includes(header))
    }else if(!this.bulkUpdateFile){
      if (this.expectedHeaders.length !== this.uploadedHeaders.length || !(this.expectedHeaders.sort().join(',') === this.uploadedHeaders.sort().join(','))) {
        this._snackbar.open("Invalid excel", "Close") 
        return;
      }
    }

    if (this.bulkUpdateFile && this.missingColumn.some(e=> e.toLowerCase() == 'id')) {
      this._snackbar.open("Please provide valid data", 'Failed');
      this.ngxService.stop();
      return;
    }
  
    // tempExcelArr.find(r => {
    for (let i = 0; i < tempExcelArr.length; i++) {
      for (let [key, value] of Object.entries(tempExcelArr[i])) {
        if (!key.toString().match(/empty/i)) {
          primary[key] = value;
        }
      }
      tempArray.push(primary)
      primary = {}
      // });
    }
    tempExcelArr = tempArray;
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
    temp[0].reverse()
    temp[0].push("Notes")
    this.sheetHeader = temp[0].filter(va => !va.toString().toLowerCase().match(/empty/i));
    if (this.sheetHeader.filter(element => element.toLowerCase() == 'id').length > 0) {
      this.bulkUpload = false;
    }
    else {
      this.bulkUpload = true;
    }
    for (let i = 0; i < this.sheetHeader.length; i++) {
      if (this.sheetHeader[i]) {
        this.sheetHeader[i] = this.sheetHeader[i].replace(/\s/g, "");
      }
    }
    // Add the key and value to the primary object in tempArray
    if(this.UpdateColumnMisMatch){
      for(let i=0; i<tempExcelArr.length; i++){
       for(let j=0; j<this.missingColumn.length; j++){
         tempExcelArr[i][this.missingColumn[j]] = '';
       }
      }
    }
    this.excelDataArray = this.convertObjKeysToLower(tempExcelArr);
    this.excelDataArray.sort((a, b) => a.displayname < b.displayname ? -1 : a.displayname > b.displayname ? 1 : 0)
    if(this.userFlag){
      this.excelDataArray.find((d): any => {
        Object.assign(d, {
          slno: Number(i), notes: '', tablename: 'OrganizationCommentsTemplates', organizationid: Number(sessionStorage.getItem('org_id')),
          casetype: d.casetype, userid: !d.userid ? this.inData : d.userid
        });
        i++;
       });
    }
    else{
     this.excelDataArray.find((d): any => {
      Object.assign(d, {
        slno: Number(i), notes: '', tablename: 'OrganizationCommentsTemplates', organizationid: Number(sessionStorage.getItem('org_id')),
        casetype: this.templateData.cardtype.toString(), userid: !d.userid ? null : d.userid
      });
      i++;
     });
   }
    // this.sheetHeader = ["isactive", "referencetemplateid", "isuserlevelcasecommentstemplateenabled", "isuserleveltemplates", "userid","sequence", "type", "isdefault","description","templatename","casetype"];
    this.validateExcelData(this.excelDataArray);
  }
  //#endregion

  //#region Open Modal
  async openModal() {
    let result = await this.commonService.openMultisheetModal(this.sheetsToSelect);
    //let result=this.commonService.selectdSheet;
    if (result) {
      this.convertToJson(result)
    }
  }
  //#endregion
  ngOnDestroy() {
    //code to remove the modals in the component appended to the body
    $('#selectSheetModal').remove();
  }

  disableApprovebtn() {
    let existsCount = 0;
    for (let i = 0; i < this.excelDataArray.length; i++) {
      if (this.gridArrays[i]['notes'] && (this.gridArrays[i]['notes'].toString().match(/Mandatory field is missing/i) || this.gridArrays[i]['notes'].toString().match(/Case Comments  already exists for this Entity./i))) {
        existsCount++;
      }
    }
    if (existsCount > 0) {
      return true
    }
    return false;
  }

  //#region Tooltip for Grid
  initLocationGrid(grid) {
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  //#region Export Grid data
  ExportExcelCopyandUpload(flex) {
    let excel = [];
    if (!this.bulkUpload) {
      flex.rows.find(e => {
        delete e._data["notemessage"];
        excel.push(e._data);
      });
    } else {
      flex.rows.find(e => {
        delete e._data["notemessage"];
        delete e._data["modifiedby"];
        delete e._data["id"];
        excel.push(e._data);
      });
    }
    if (this.templateData.secondarykeys == undefined || this.templateData.secondarykeys == null) {
      this.organizationId = this.tabService.tabs[0].tabData.mainCard.Organizationid;
    }
    else {
      this.organizationId = this.templateData.secondarykeys.OrganizationId;
    }
    let filename = 'Case Comments_' + this.organizationId + '.xlsx';
    var ws = XLSX.utils.json_to_sheet(excel.reverse());
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "" + 'Case Comments');
    XLSX.writeFile(wb, filename);
  }
  //#endregion


  // Delete Row
  deleteRow(grid, row) {
    grid.collectionView.remove(row.dataItem);
    this.excelDataArray = this.excelDataArray.filter(va => va.slno != row.dataItem.slno);
    if (grid.rows.length == 0) {
      this.removeGrid();
    }
    this._snackbar.open('Deleted successfully', 'Close');
  }
  //invalid case type
  closeCasetypeModel() {
    this.invalidCasetypeFlag = false;
  }

  //#region User/PAthologist Cardtype: back button
  refreshUserGrid() {
    this.uploadClicked = false;
    this.copyDataClicked = false;
    this.gridDisplay = false;
    this.copyDataClicked = false;
    this.invalidCasetypeFlag = false;
    this.tableGrid = true;
    this.removeGrid();
    this.getOrgCasetype();
    let queryVariable = { accid: this.inData.toString() };
    let query = this.subMenuCardModel.GetQuery("usercasecomments");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult, this.dbname).subscribe(data => {
      if (!data.errors) {
        if (data.data.submenuData.length > 0) {
          this.templateData.submenuData = [];
          this.templateData.submenuData = data.data.submenuData;
        }
        if (!this.underorguserlevel) {
          this.AddGridData();
        }
        else {
          // let item=this.templateData.submenuData.filter(va => va.UserId == this.inData);
          //this.selectedUserGridData(item);
          this.refreshUserlevelGrid();
        }
      }
    }, error => {
      console.error(error);
    });
    if(this.copyDataClicked){
      this.copycollectionMethodForm.controls.frmOrganization.enable();
      this.copycollectionMethodForm.controls.frmUser.enable();
    }
  }
  //#endregion



  //#region User/PAthologist Cardtype: back button
  async refreshUserlevelGrid() {
    this.uploadClicked = false;
    this.copyDataClicked = false;
    this.gridDisplay = false;
    this.copyDataClicked = false;
    this.invalidCasetypeFlag = false;
    this.tableGrid = true;
    //await this.getOrgCasetype();
    let caseTypes = []
    for (let i = 0; i < this.caseTypeList?.length; i++) {
      
      caseTypes.push(this.commonService.removeSpecialChars(this.caseTypeList[i].CaseType));
    }
    let queryVariable
    if (this.userFlag) {
      queryVariable = { "filterParam": "CaseType in \"" + caseTypes.toString() + "\":string [] ", orgid: sessionStorage.getItem('org_id').toString() };
    }
    else {
      queryVariable = { "casetype": this.templateData?.cardtype?.toString(), orgid: sessionStorage?.getItem('org_id').toString() };
    }
    //let queryVariable = { casetype: this.CaseComments == 'Organization' ? this.templateData.secondarykeys.casetype.toString() : this.caseTypeList, orgid: sessionStorage.getItem('org_id').toString() };
    let query = this.subMenuCardModel.GetQuery("mastercasecomments");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    await this.VitalHttpServices.GetData(queryResult, this.dbname).toPromise().then(data => {
      this.ngxService.stop();
      if (!data.errors) {
        this.templateData.submenuData = [];
        this.templateData.submenuData = data.data.submenuData;
        if (data.data.submenuData.length > 0) {
          this.templateData.submenuData.submenuData1 = [];
          this.templateData.submenuData.submenuData1 = data.data.submenuData1;
          this.templateData.submenuData.submenuData2 = []
          this.templateData.submenuData.submenuData2 = data.data.submenuData2;
        }
        else {
          this.templateData.submenuData.submenuData1 = [];
          this.templateData.submenuData.submenuData1 = data.data.submenuData1;
          this.templateData.submenuData.submenuData2 = []
          this.templateData.submenuData.submenuData2 = data.data.submenuData2;
        }
        this.userListArray = this.templateData.submenuData.submenuData1;
        this.UserList = this.templateData.submenuData.submenuData2;
        this.UserListData = this.templateData.submenuData.submenuData1;
        this.selectedUserGridData(this.PreviousUserdata);

      }
    }, error => {
      console.error(error);
    });
  }
  //#endregion

  //#regin to show notes
  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel == flexGird.cells) {
      var value = e.panel.getCellData(e.row, e.col, false);
      if (value) {
        if (value == 'Mandatory field is missing' || value == 'Null'
          || value.toString().includes('Invalid') || value.toString().includes('accepts only') || value.toString().includes("User id does not belong to the Group") || value.toString().includes('Case comment already exists') ||
          value.toString().includes('Case comments id is not available') || value.toString().includes('Id is not valid') || value.toString().includes('Case comment cannot be created for this user')) {
          wjCore.toggleClass(e.cell, 'error-msg ', true);
        }
        else if (value == 'Valid' || value.toString().includes('Case comment creation successful.') || value.toString().includes('cannot be reverted') || value == 'Success' || value.toString().includes('Update Successfully')) {
          wjCore.toggleClass(e.cell, 'high-value', true);
        }
        else if (value.toString().includes('already exists')) {
          wjCore.toggleClass(e.cell, 'warn-value', true);
        }
      }
      //  }
    }
  }
  fnToggleFilter() {
    this.txtInput.nativeElement.focus();
    // this.configname.reset();
    this.frm_testForm.reset();
  }

  mapData() {
    // Extract data for the selected user
    let selectedUserData = [];
    let duplData = this.templateData;
    // if (Array.isArray(duplData.submenuData.submenuData1) && Array.isArray(duplData.submenuData.submenuData2)) {
    //   duplData.submenuData.submenuData1.forEach(element1 => {
    //     duplData.submenuData.submenuData2.forEach(element2 => {
    //       if (element1.UserId === element2.UserId) {
    //         const { UserId, ...user1WithoutUserId } = element1; // Destructure to remove UserId
    //         const user = {
    //           ...user1WithoutUserId, // Use the updated element1 without UserId
    //           // User: element2.Username + "(" + element2.rolename + ")",

    //         };
    //         selectedUserData.push(element1);
    //       }
    //     });
    //   });
    // }
    selectedUserData = duplData.submenuData.submenuData1.map(({ UserId, ...rest }) => ({ // as we dont have userId key adding in User key
      ...rest,
      User: UserId
    }));
    return selectedUserData;
  }
  //#endregion

  showColumns(userFlag) {
    if (userFlag) {
      return 'User'
    }
    return 'CaseType'
  }

  async migrateEnabling(selectedid?) {
    await this.getuserroleslist();
    let rolenamesforcasetype = selectedid.rolename == undefined ? selectedid.RoleName : selectedid.rolename
    // this.getCasetypeList(rolenamesforcasetype,selectedid.userid);

    await this.VitalHttpServices.getCasetypesBasedOnRole(rolenamesforcasetype, selectedid.userid).toPromise().then(
      (res) => {
        this.caseTypeList = res;
        this.caseTypes = res
      });


    let finduser = this.userRolesList1.findIndex(x => selectedid.userid == x.userid)
    if (this.userRolesList1[finduser].Allowuserlvl == 1 || this.userRolesList1[finduser].Allowuserlvl == true) {
      this.migrateenable = true
    } else {
      this.migrateenable = false
    }
  }

  // getCasetypeList(RoleName,userid){
  //   this.VitalHttpServices.getCasetypesBasedOnRole(RoleName, userid).subscribe(
  //     (res) => {
  //       this.caseTypeList = res;
  //       this.caseTypes = res
  //     });
  // }

  async getuserroleslist() {
    let orgid = sessionStorage.getItem('org_id');
    let query = this.subMenuCardModel.GetQuery('getuserscasecomments');
    let role;
    let queryVariable;
    if (!this.userFlag) {
      if (this.commonService.finalizeAfterTc) {
        role = 'Lab Manager,Lab Director,Technician'
      }
      else if (!this.commonService.finalizeAfterTc && this.commonService.isScreeningEnabled) {
        role = 'Cytotechnologist Supervisor,Cytotechnologist,Pathologist,Pathologist Assistant,Screening Technologist'
      }
      else if (!this.commonService.finalizeAfterTc && !this.commonService.isScreeningEnabled) {
        role = 'Pathologist,Pathologist Assistant,Screening Technologist'
      }
      queryVariable = {
        "orgid": orgid.toString(), "filtername": "rolename in \"" + role.toString() + "\":string []"
      };
    }
    else {
      queryVariable = {
        "orgid": orgid.toString()
      };
    }

    //let queryVariable = { "orgid": orgid.toString() };
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    let dbName;
    dbName = this.srcDeployment;
    this.userRolesList1 = [];
    await this.VitalHttpServices.GetData(queryResult, dbName).toPromise().then(res => {
      if (!res.errors) {
        this.userRolesList1 = res.data.Card;
        this.userRolesList1.sort((a, b) => a.username < b.username ? -1 : a.username > b.username ? 1 : 0)
        for (let i = 0; i < this.userRolesList1.length; i++) {
          if (this.userRolesList1[i].Allowedit == 1) {
            this.userRolesList1[i].Allowedit = true
          } else {
            this.userRolesList1[i].Allowedit = false
          }
          if (this.userRolesList1[i].Allowuserlvl == 1) {
            this.userRolesList1[i].Allowuserlvl = true
            this.userRolesList1[i]["disabletoggle"] = true
          } else {
            this.userRolesList1[i].Allowuserlvl = false
            this.userRolesList1[i]["disabletoggle"] = false
          }
        }
      }
      this.ngxService.stop();


    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  async openUserToggleDialog() {
    if (!this.hideManageCaseCommentbtn) {
      await this.getuserroleslist()
      if (this.userRolesList1 && this.userRolesList1.length > 0) {
        const dialogRef = this.dialog.open(UserToggleComponent, {
          disableClose: true,
          width: '80%',
          height: 'calc(100vh - 20vh)',
          data: {
            title: 'Manage Case Comments',
            obj: this.userRolesList1,
            context: "Case Comments",
            keyword:'getuserscasecomments',
            auditableColumns: this.auditableColumns,
            template: this.templateData
          },
        });
      }
      else {
        this._snackbar.open('No Data Found', 'Close');
        this.ngxService.stop();
      }
    }
  }

  MigrateGLdata() {
    if (!this.hideMigratebtn) {
      let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
        disableClose: true,
        autoFocus: false,
        width: '500px',
        data: { header: "", message: "Copy Group Level Case Comments", alert: 'You have chosen to copy all Group level comments to exclusive User. This copy of comments can be only accessed by the user and will not be shared by other users. Do you want to proceed?', continue: "Yes", cancel: "No" }
      });
      return dialogRef
        .afterClosed().toPromise().then
        ((result) => {
          if (result) {
            let caseTypes = []
            // if(!this.caseTypes)
            // {
            for (let i = 0; i < this.caseTypes.length; i++) {
              caseTypes.push(this.caseTypeList[i].CaseType)
            }
            // }
            // else{
            //   caseTypes= this.templateData.cardtype.toString()
            // }
            let object = {
              orgid: sessionStorage.getItem('org_id'),
              userid: this.inData.toString(),
              createdby: sessionStorage.getItem('Userid') ? sessionStorage.getItem('Userid') : -100,
              CaseType: caseTypes.toString(),
              tablename: "CaseComments"
            }
            this.ngxService.start();
            this.VitalHttpServices.migrateTemplates(object).subscribe(result => {
              this.ngxService.stop();
              if (!result.errors) {
                //this.refreshGrid()
              this.commonService.auditDetails('', '', [], [object], 'Migrate', this.templateData, this.auditableColumns);
                this.refreshUserlevelGrid()
              }
            }, error => {
              console.error(error);
              this.ngxService.stop();
            });
          }
        }
        )
    }
  }

  groupBy(objectArray: any, Property1: string, Property2?: string) {
    return objectArray.reduce(function (
      acc: { [key: string]: any[] },
      obj: { [key: string]: any }
    ) {
      // Generate a unique key based on the combination of values from Property1 and Property2

      var compositeKey = obj[Property1] + (Property2 ? ('|' + obj[Property2]) : '');

      if (!acc[compositeKey]) {
        acc[compositeKey] = [];
      }

      acc[compositeKey].push(obj);
      return acc;
    },
      {});
  }

  handleListingScreen(val: any) {
    if (val == 'closeList') {
      this.ViewScreen = true
    } else if (val = 'openList') {
      this.ViewScreen = false
    }
  }

  #editStarting
  newEditMethod(editObj: any) {
    editObj.rowData.Active == "Active" ? editObj.rowData.Active = 'Yes' : editObj.rowData.Active = '';
    this.editCaseComments(editObj.rowData);
  }

  recieveObjFromListing(object: any) {

    if (object.newData.Action === 'Create') {
      this.receivedObject = object.newData;
      delete this.receivedObject.Action
      this.receivedObject['applicationUserId'] = this.receivedObject['CreatedBy'];
      delete this.receivedObject.CreatedBy
      delete this.receivedObject.ModifiedBy
      if(this.userFlag && object?.newData)
        {
          if (this.receivedObject.hasOwnProperty('userId')) {
            object.newData['userId'] = object?.newData?.userId;
          } else {
            object.newData['userId'] = this.inData
          }
        }
        let filteredList = object?.newData?.userId ? this.userRolesList1.filter((item) => !item.Allowuserlvl && object?.newData?.userId.includes(item?.userid)):[];
      if ((this.userFlag || filteredList?.length) && !this.migrateenable) {
        let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
          disableClose: true,
          autoFocus: false,
          width: '500px',
          data: { header: "", message: "Create User Level Case Comments", alert: 'You have chosen to create User level templates, any templates created will be specific only to this user. Do you want to proceed? ', continue: "Yes", cancel: "No" }
        });
        return dialogRef
          .afterClosed().toPromise().then
          ((result) => {
            if (result) {
      this.CreateCaseCommentsTemplate(this.receivedObject);
            } else {
              this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
    }
          });
  
      } else {
        this.CreateCaseCommentsTemplate(this.receivedObject);
      }
    }
    else {
      object.newData['applicationUserId'] = object.newData['CreatedBy'];
      delete object.newData.CreatedBy
      delete object.newData.ModifiedBy
      delete object.newData.Action
      object.newData['id'] = object.oldData['Id'];
      object.newData = { ...object.newData, ...{ 'ActionParam': 'UpdateCaseComments' } }
      this.updateCaseCommentsTemplate(object.newData, object.oldData)
    }
  }

  CreateCaseCommentsTemplate(newObj: any) {
    this.submitted = true;
    if (this.userFlag) {
      newObj['CaseType'] = newObj['caseType']
      delete newObj['caseType']
      newObj = { ...newObj, ...{ 'temptext': 'User Level', 'userId': this.inData } };
    }
    else {
      newObj = { ...newObj, ...{ 'temptext': 'org level' } };
    }
    this.VitalHttpServices.createCaseComments(newObj).subscribe((res) => {
      this.isdataAdded = true;
      this.ngxService.start();
      if (res[1].toLowerCase() == 'true') {
        this.selectedOption = 'Group Case Comments';
        this.ngxService.stop();
        this.caseCommentUsers = [];
        if (res.length > 2) {
          if (res[0].toLowerCase() != "") {
            this._snackbar.open(res[2], 'Success');
            // this.formFlagCaseComments = false;
            this.commonService.setBooleanToTrue(true);
            this.tableGrid = true;
          }
          else {
            this._snackbar.open(res[2], 'Failed');
            this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
            // this.createFrmCaseComment = true;
            // this.formFlagCaseComments = true;
            // this.tableGrid = false;
          return;
          }
        }
        else {
          this.isdataAdded = true;
          this._snackbar.open('Case comment added successfully', 'Success');
          this.selectedOption = 'Group Case Comments';
          // this.formFlagCaseComments = false;
          this.caseCommentUsers = [];
          this.tableGrid = true;
          this.commonService.setBooleanToTrue(true);
        }
        this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
        this.selectedCasetypeForLabadmin = '';
        let successObj = { 'id': res[0] }
        newObj.isActive = newObj.isActive == 1 ? 'Active' : 'Inactive'
        this.commonService.auditDetails('id', 'templateName', [successObj], [newObj], 'Create', this.templateData, this.auditableColumns);
        this.caseCommentData.reset();
        this.selectedOption = 'Group Case Comments';
        // this.refreshGrid();
        this.refeshGridToGroupLevel();
      } else {
        this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
        this._snackbar.open(res.Message, 'Failed');
        this.ngxService.stop();
      }
    });
  }


  updateCaseCommentsTemplate(newObj, oldObj) {
    this.ngxService.start();
    this.submitted = true;
    this.VitalHttpServices.updateCaseComments(newObj).subscribe((res) => {
      this.ngxService.stop();
      if (res.Success) {
        if (res.Message.toLowerCase() == 'template with the same name already exists.cannot update!') {
          this._snackbar.open(res.Message, 'Failed');
          this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';
          this.createFrmCaseComment = false;
          this.formFlagCaseComments = true;
        }
        else {
          this.createFrmCaseComment = false;
          this.formFlagCaseComments = false;
          this.commonService.setBooleanToTrue(true);
          this.tableGrid = true;
          this._snackbar.open(res.Message, 'Success');
          newObj.IsActive = newObj.IsActive == 1 ? 'Active' : 'Inactive'
          this.saveOrEditCompleted = Math.floor(Math.random() * 500) + 1;
          this.selectedCasetypeForLabadmin = '';
          this.commonService.auditDetails('Id', 'TemplateName', [oldObj], [newObj], 'Edit', this.templateData, this.auditableColumns);
          this.selectedOption = 'Group Case Comments';
          // this.refreshGrid();
          this.refeshGridToGroupLevel();
        }
      } else {
        if (res.Message.toLowerCase().includes('Could not find'))
          this._snackbar.open(res.Message, 'Failed');
          this.saveOrEditCompleted = this.saveOrEditCompleted === 'failed' ? 'error' : 'failed';

        //this.ngxService.stop();
      }
    });
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }


  async emitFilters($event: any) {
    this.migrateenable = false;
    this.selectedCasetypeForLabadmin = '';
    const filtersObj = {
      All: 'All',
      Group: 'Group',
      'All Users': 'All User',
      'Group Case Comments': 'Group'
    };

    let modifedObj: any;

    if (this.sourceApp !== 'VitalDx') {
      modifedObj = this.convertKeysToLowerCase($event?.UserId?.object);
    } else {
      modifedObj = $event?.UserId?.object ? this.convertKeysToLowerCase($event?.UserId?.object) : null;
      this.selectedCasetypeForLabadmin = $event?.CaseType?.object?.CaseType !== 'All Case Types' ? $event?.CaseType?.object?.CaseType :null;
    }

    const userIdEvent = $event?.UserId?.event;

    if (modifedObj) {
      if (filtersObj[modifedObj.username] === 'Group') {
        if(this.sourceApp == 'VitalDx') {
          await this.refreshGrid(this.selectedCasetypeForLabadmin);
        }
        await this.getOrgdata(userIdEvent);
      } else {
        if(this.sourceApp == 'VitalDx') {
          await this.refreshGrid(this.selectedCasetypeForLabadmin);
        }
        await this.UserGridData(userIdEvent, filtersObj[modifedObj.username] || modifedObj);
      }
    } else {
      await this.refreshGrid(this.selectedCasetypeForLabadmin);
      if (filtersObj[modifedObj?.username] === 'Group') {
        this.getOrgdata(userIdEvent);
      } else {
        this.UserGridData(userIdEvent, modifedObj);
      }
    }
  }


  convertKeysToLowerCase(obj) {
    return Object.keys(obj).reduce((acc, key) => {
      acc[key.toLowerCase()] = obj[key];
      return acc;
    }, {});
  }

  roleChanged($event: any) {
    this.setUserRole($event?.event, $event?.user)
  }

  refeshGridToGroupLevel() {
    this.migrateenable = false;
    this.underorguserlevel = false;
    this.selectedOption = 'Group Case Comments';
    this.CaseComments ='Organization';
    this.refreshGrid();
    if(this.copyDataClicked){
      this.copycollectionMethodForm.controls.frmOrganization.enable();
      this.copycollectionMethodForm.controls.frmUser.enable();
    }
  }

  trimFieldValue(controlName: any){
    this.commonService.trimFieldValue(this.copycollectionMethodForm, controlName , this.copycollectionMethodForm.value.frmOrganization)
  }
  trimUserFieldValue(controlName: any){
    this.commonService.trimFieldValue(this.copycollectionMethodForm, controlName , this.copycollectionMethodForm.value.frmUser)
  }
}




// class ColDef {
//   constructor(public binding?: string, public header?: string) { }
// }
