import { Component, OnInit, Inject, ViewChild, NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { VACommonModule } from '../../common.module';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmLabadminComponent } from '../confirm-labadmin/confirm-labadmin.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { SubMenuCardModel } from 'src/app/client/DbModel/SubMenuCard/Submenucardmodel';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { ActivityTrackerService } from '../../../core/services/activity-tracker.service';

@Component({
  selector: 'app-user-toggle',
  templateUrl: './user-toggle.component.html',
  styleUrls: ['./user-toggle.component.scss'],
})
export class UserToggleComponent implements OnInit {

  savedata: any = []
  changedData: any = []

  gridHeader: any[] = [
    {
      "Name": "Seq",
      "colWidth": "col-sm-1",
      "key": "index"
    },
    {
      "Name": "User Name",
      "colWidth": "col-sm-3",
      "key": "username"
    },
    {
      "Name": "Role",
      "colWidth": "col-sm-4",
      "key": "rolename"
    },
    {
      "Name": "Accessibility",
      "colWidth": "col-sm-2",
      "key": "Allowuserlvl"
    },
    {
      "Name": "Permission",
      "colWidth": "col-sm-2",
      "key": "Allowedit"
    },
  ];
  gridData: any[] = [];
  isUserLevel: boolean;
  gridDataCopy: any;
  backupGridDataForUser: any;
  subMenuCardModel: SubMenuCardModel;
  userRoleList: any[];
  isAlertOpen: boolean = false;

  ngAfterViewInit() {
  }
  constructor(private ngxService: NgxUiLoaderService, public VitalHttpServices: VitalHttpServices, private _snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<UserToggleComponent>, public dialog: MatDialog, public commonService: CommonService,public activityService: ActivityTrackerService,
    @Inject(MAT_DIALOG_DATA) public data: any,private datashare: DataShareService) {
      this.subMenuCardModel = new SubMenuCardModel(commonService, VitalHttpServices, datashare);
      this.getUserRoleList();
  }

  ngOnInit(): void {
  }

  displayColumns(item: any, header: any) {
    this.isUserLevel = item['disabletoggle'];
    return item[header.key]
  }

  disableToggle(item: any) {
    if (item['Allowuserlvl'] && item['Allowedit']) {
      return true;
    } else {
      return false;
    }
  }

  toggleChanged(value, selectedItem, key) {
    let index = this.savedata.findIndex((va) => va.userid == selectedItem.userid);
    let changedDataIndex = this.changedData.findIndex((va) => va.userid == selectedItem.userid);
    let gridDataindex = this.gridDataCopy.findIndex((va) => va.userid == selectedItem.userid);
    if (key == 'Allowuserlvl' && value) {
      this.isAlertOpen = true;
      let dialogRef = this.dialog.open(ConfirmLabadminComponent, {
        disableClose: true,
        width: '400px',
        data: { header: "", message: "Are you sure you want to make this change?", alert: 'The User level template cannot be reverted back to group level', continue: "Change", cancel: "Cancel" }
      })
      return dialogRef.afterClosed().toPromise().then(result => {
        this.isAlertOpen = false;
        if (result) {
            selectedItem[key] = value;
            selectedItem['Allowedit'] = value;
            this.savedata.push(selectedItem);
            this.gridData[gridDataindex]['Allowedit'] = value;
            this.changedData.push(this.gridDataCopy[gridDataindex]);
            this.backupGridDataForUser = structuredClone(this.gridData);
        } else {
          this.gridData = structuredClone(this.backupGridDataForUser);
          if(index != -1){
            this.savedata.splice(index, 1);
          }
        }
      })
    } else if (key == 'Allowuserlvl' && !value) {
      selectedItem[key] = value;
      this.gridData[gridDataindex]['Allowedit'] = value;
      this.savedata.splice(index, 1);
      this.changedData.splice(changedDataIndex, 1);
      this.backupGridDataForUser = structuredClone(this.gridData);
    } else {
      if (selectedItem[key] != value) {
        selectedItem[key] = value;
        index == -1 ? this.savedata.push(selectedItem) : (this.savedata[index] = selectedItem);
        changedDataIndex == -1 ? this.changedData.push(this.gridDataCopy[gridDataindex]) : (this.changedData[changedDataIndex] = this.gridDataCopy[changedDataIndex]);
      } else {
        this.savedata.slice(index, 1);
        this.changedData.slice(changedDataIndex, 1);
      }
    }
  }

  saveUserConfig() {
    let oldData = JSON.parse(JSON.stringify(this.changedData));
    let newData = JSON.parse(JSON.stringify(this.savedata));
    this.savedata.forEach((element,i) => {
      this.savedata[i]['context'] = this.data.context;
    });
    let conn = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.ngxService.start();
    this.VitalHttpServices.editUserlevelcomments(this.savedata, conn).subscribe(response => {
      this._snackbar.open("Saved successfully", "Close");
      this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.data.template.menuURL, 'context': [{ 'key': 'User', 'value': this.data.template.menuURL }] })
      this.getUserRoleList()
      newData.forEach((element , index)=> {
      let activityInfo = `Modified User Level Configurations of "${element['username']}" for "${element['rolename']}"`;
      element['Allowuserlvl'] = element['Allowuserlvl'] ? 'User Level' : 'Group Level';
      element['Allowedit'] = element['Allowedit'] ? 'Edit' : 'View';
      oldData[index]['Allowuserlvl'] = oldData[index]['Allowuserlvl'] ? 'User Level' : 'Group Level';
      oldData[index]['Allowedit'] = oldData[index]['Allowedit'] ? 'Edit' : 'View';
      this.commonService.createActivityObject(element['userid'],element['username'],this.data.template.menuURL, 'Edit', element,oldData[index],'',this.data.auditableColumns, '', '', activityInfo)
     });
     this.savedata = [];
     this.changedData = [];
     this.commonService.createContext(this.data.template.secondarykeys, '',this.data.template.menuURL);
      this.ngxService.stop();
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }

  filterData(event: any) {
    if (event.target.value != "") {
      this.gridData = this.gridDataCopy.filter((x: any) => x['username'].toLowerCase().includes(event.target.value.toLowerCase()) ||
        x['rolename'].toLowerCase().includes(event.target.value.toLowerCase()));
    } else {
      this.gridData = this.gridDataCopy;
    }
  }

  getUserRoleList(){
    let orgid = sessionStorage.getItem('org_id');
    let query = this.subMenuCardModel.GetQuery(this.data.keyword);
    let role;
    let queryVariable;
    
    if (this.commonService.finalizeAfterTc) {
      role = 'Lab Manager,Lab Director,Technician'
    }
    else if (!this.commonService.finalizeAfterTc && this.commonService.isScreeningEnabled) {
      role = 'Cytotechnologist Supervisor,Cytotechnologist,Pathologist,Pathologist Assistant,Screening Technologist'
    }
    else if (!this.commonService.finalizeAfterTc && !this.commonService.isScreeningEnabled) {
      role = 'Pathologist,Pathologist Assistant,Screening Technologist'
    }

    if(this.data.keyword == 'getUserForManagePathologists'){
      queryVariable = {
        "orgid": sessionStorage.getItem('org_id'),
        "filtername": "RoleName in \"" + role.toString() + "\":string []"
      };
    }else if(this.data.keyword == 'getuserscasecomments'){
      queryVariable = {
        "orgid": orgid.toString(), "filtername": "rolename in \"" + role.toString() + "\":string []"
      };
    }else{
      queryVariable = { "orgid": orgid.toString() };
    }

    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    let dbName;
    dbName = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.userRoleList = [];
     this.VitalHttpServices.GetData(queryResult, dbName).toPromise().then(res => {
      if (!res.errors) {
        this.userRoleList = this.data.keyword == 'getUserForManagePathologists' ? res.data.submenuData : res.data.Card;
        this.userRoleList.sort((a, b) => a.username < b.username ? -1 : a.username > b.username ? 1 : 0)
        for (let i = 0; i < this.userRoleList.length; i++) {
          if (this.userRoleList[i].Allowedit == 1) {
            this.userRoleList[i].Allowedit = true
          } else {
            this.userRoleList[i].Allowedit = false
          }
          if (this.userRoleList[i].Allowuserlvl == 1) {
            this.userRoleList[i].Allowuserlvl = true
            this.userRoleList[i]["disabletoggle"] = true
          } else {
            this.userRoleList[i].Allowuserlvl = false
            this.userRoleList[i]["disabletoggle"] = false
          }
        }
        this.gridData = this.userRoleList;
        this.backupGridDataForUser = structuredClone(this.userRoleList);
        this.gridDataCopy = JSON.parse(JSON.stringify(this.userRoleList));
      }
      this.ngxService.stop();


    }, error => {
      console.error(error);
      this.ngxService.stop();
    });
  }

  closeDialog() {
    this.dialogRef.close("closed");
  }
}
