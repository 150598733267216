import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { CollectionView, Clipboard } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { InjectorService } from '../../../core/services/Injectorservices';
import { BaseGridContainerComponent } from '../../commoncomponents/templateclasses/basegrid';
import * as wjcCore from '@grapecity/wijmo';
import { VitalAdminTabService } from '../../tab.service';
import { format } from 'date-fns';

@Component({
  selector: 'readonlygrid-container',
  templateUrl: './readonlygridcontainer.component.html',
  styleUrls: ['../GridContainer/gridcontainer.component.scss']
})

export class ReadOnlyGridContainerComponent extends BaseGridContainerComponent implements OnChanges {

  @Input()
  public subMenudata: any;
  @Input()
  public cardIdentifier: number = -1;
  @Input()
  public cardtype: string = '';
  @Input()
  labels: any;
  @Input()
  rowData: any;
  @Input()
  component: any = '';
  columnDefs: any = [];
  dateColumns: string[] = [];
  gridwidth: number = 0;
  labelFirstCount: number = 0;
  showPaginationMainGrid: boolean = false;
  // public actionButtonDetails: any[];
  public hideExportBtn: boolean = true;
  private currentSelection = null;
  @Input()
  templateData: any;
  @Input()
  templateChangedTime: string;
  gridData: CollectionView;
  @ViewChild('flex') flex: wjcGrid.FlexGrid;
  @Input()
  mainCard: any;
  @Input()
  valueShare: any;
  constructor(public VitalHttpServices: VitalHttpServices, private injectorServiceReadOnlyGrid: InjectorService, private datashare: DataShareService, public CommonService: CommonService, public tabService: VitalAdminTabService) {
    super(injectorServiceReadOnlyGrid);
  }

  ngOnChanges() {
    this.columnDefs = [];
    this.dateColumns = [];
    let labels = JSON.parse(JSON.stringify(this.templateData.labels))
    for (let i = 0; i < labels.length; i++) {
      this.columnDefs.splice(0, 0, labels[i]);
    }
    this.UpdateData(this.templateData.submenuData);
    this.GetButtondetails();
    this.AddGridData()
    console.log(this.dateColumns);
  }

  UpdateData(data: any) {
    data.forEach(row => {
      Object.keys(row).forEach((key) => {
        if(this.isValidDate(row[key])) {
          if(!this.dateColumns.includes(key))
            this.dateColumns.push(key);
          row[key] = new Date(Date.parse(row[key]));
        }
      })
    })
  }

  isDateColumn(column: string): boolean {
    return this.dateColumns.includes(column);
  }

  isValidDate(stringDate) {    
    let pattern = /^\d{2}[./-]\d{2}[./-]\d{4}/;
    return isNaN(stringDate) && pattern.test(stringDate)  && !isNaN(Date.parse(stringDate));
  }

  //#region Tooltip for Grid
  initGrid(flex) {
    let _this = this;
    flex.onSelectionChanged(null);
    // allow user selection

    // to copy the selected text to clipboard
    flex.cells.hostElement.addEventListener('keydown', function (e) {
      // copy: ctrl+c or ctrl+Insert
      if (e.ctrlKey && e.code == 'KeyC') {

        //CellRange Selected
        if(_this.currentSelection && _this.currentSelection.length > 0 && _this.currentSelection.split('-')[0] != _this.currentSelection.split('-')[1])
          return;
        
        e.preventDefault();
        const text = flex.activeCell.textContent?.toString();
        Clipboard.copy(text);
        return;
      }
    });
    
    const tt = new wjcCore.Tooltip();
    flex.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null) {
        let cellData = s.getCellData(e.row, e.col);
        tt.setTooltip(e.cell, `${(cellData instanceof Date && !isNaN(cellData.valueOf())) ? format(cellData, 'MM/dd/yyyy hh:mm:ss a') : cellData }`);
      }
    });
  }
  //#endregion

  
  onSelectionChanged(flex) {
    this.currentSelection = wjcCore.format(
        '({row},{col})-({row2},{col2})',
        flex.selection);
  }

  GetButtondetails() {
    let casebutton = this.VitalHttpServices.CasemenuAction;
    if (!casebutton || !this.templateData.headerText) {
      return this.hideExportBtn = false;
    }
    else {
      this.GetButtonAccess(this.VitalHttpServices.CasemenuAction);
    }
  }

  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case "Export":
          this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }

  AddGridData() {
    this.gridData = new CollectionView(this.templateData.submenuData, { pageSize: 30 });
    if (this.gridData.itemCount > 10) {
      this.showPaginationMainGrid = true;
    } else {
      this.showPaginationMainGrid = false;
    }
    //this.gridwidth = (170 * this.gridHeader.length) + 37;
    this.gridwidth = 412 * this.templateData.labels.length;
    if (this.gridwidth > 1050)
      this.gridwidth = 1050;
    // this.showPaginationMainGrid = this.templateData.length > 10 ? true : false;
  }

  onGridLoaded(){
    var self = this;
    setTimeout(function() {
         self.flex.autoSizeColumns();
    },300);
}

  // ExportExcel(flex) {
  //   console.log(this.valueShare);

  //   let url: any = this.templateData.menuURL ?? this.component;

  //   let id: any
  //   if (!this.templateData.secondarykeys) {
  //     id = this.mainCard && this.mainCard.Organizationid ? this.mainCard.Organizationid : '';
  //   }
  //   else {
  //     id = this.templateData.secondarykeys.OrganizationId;
  //   }
  //   let filename = this.CommonService.getExcelFileName(url, id)
  //   this.CommonService.ExportToExcel(flex, filename);
  // }
  ExportExcel(flex) {
    let url: any = this.templateData.menuURL ? this.templateData.menuURL : this.component;
    let id: any;
    if(this.component.toString().toLowerCase() == 'execute sql'){
    let tableName:any = this.valueShare.Querytxt;
    tableName = tableName.toLowerCase().split('from')[1].trim().split(' ')[0].trim();
      url = tableName.toString().charAt(0).toUpperCase() + tableName.toString().slice(1).toLowerCase();
  }
  else{
    url = this.templateData.menuURL;
  }
  if(this.component.toString().toLowerCase() == 'execute sql'){
      let deployment: any =  this.valueShare.Deployment;
      id = deployment;
  }
   else{
    if (!this.templateData.secondarykeys) {
      id = this.mainCard && this.mainCard.Organizationid ? this.mainCard.Organizationid : '';
    }
    else {
      id = this.templateData.secondarykeys.OrganizationId;
    }
   }
     let filename = this.CommonService.getExcelFileName(url, id)
     this.CommonService.ExportToExcel(flex, filename);
   }
}
