import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import {  MatDialog } from '@angular/material/dialog';
import {  MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GridContainerWithCheckBoxComponent } from 'src/app/client/Templates/GridContainerWithCheckBox/GridContainerWithCheckBox.component';
import { CommonService } from 'src/app/core/services/commonservices';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { HomeLayoutComponent } from 'src/app/common/layout/home-layout.component';
// import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
//import 'src/assets/css/styles.css';
import 'monaco-themes/themes/Monokai.json';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'app-export-case-details',
  templateUrl: './export-case-details.component.html',
  styleUrls: ['./export-case-details.component.scss']
})

export class ExportCaseDetailsComponent {
  // editorOptions = { theme: 'iPlastic', // Choose the theme you want
  // language: 'sql', };
  editorOptions = {theme: 'vs-dark', language: 'javascript'};
  VersionOfFile: any;
  ExportDetails: any;
  resultData: any;
  onInit(editor) {
      let line = editor.getPosition();
      console.log(line);
    }
  @Input() selecteddefaultdeploymentkey: any;
  @Input() permissions: any;
  @Output()
  selectedLang = "plaintext";
  @Output()
  selectedTheme = "vs";
  value = 'Clear me';

  public DeploymentKeys = [];
  searchType = "";
  searchValue = "";
  showTab = false;
  roledata: any;
  nodata: boolean = false;
  defaultdeploymentkey: string;
  exportBtn: boolean = true;
  DownloadFilename: string;
  task: Task = {
    color: 'primary',
  };
  methodHit: boolean = false;

  constructor(private ngxService: NgxUiLoaderService, public vaservices: VitalHttpServices, private _fb: FormBuilder,
    private commonService: CommonService, private activatedroute: ActivatedRoute, private router: Router,
    public GridwithCB: GridContainerWithCheckBoxComponent, private clipboard: Clipboard,
    public _snackBar: MatSnackBar, public dialog: MatDialog, private datashare: DataShareService, private homeComponent: HomeLayoutComponent,private activeModal: NgbActiveModal) {
    this.DeploymentKeys = [...this.vaservices.DeploymentKeys]
  }

  ExportCasegroup = this._fb.group({
    DeploymentKey: ['', Validators.required],
    QuickSearch: ['', Validators.required],
    ExportCaseSearchInput: ['', Validators.required],
    ConnectionName: [false, Validators.required],
    ExportCaseGenerateDML: ['Hello everyone', Validators.required],
  })

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    //this.defaultdeploymentkey = this.commonService.getDefaultDeployment()
    this.addDeployments();
    // Fetch the version of the file
    this.getVersionOfFile();
    this.ExportCasegroup.patchValue({
      DeploymentKey: this.selecteddefaultdeploymentkey && this.selecteddefaultdeploymentkey != 'ALL' ? this.selecteddefaultdeploymentkey : 'none',
      QuickSearch: "caseid",
      ExportCaseSearchInput: "",
      ExportCaseGenerateDML: "",
      ConnectionName: false
    })
  }

  addDeployments(){
    const Additionaldeployment = this.permissions?.MenuItems?.find((item: any) => item?.Htext === 'Export Case Details')?.DeploymentKeys;
      if (Additionaldeployment?.length > 0) {
        this.DeploymentKeys.push(...Additionaldeployment);
      } else {
        this.DeploymentKeys = this.DeploymentKeys;
      }
  }

  getVersionOfFile(){
    this.ngxService.start();
    const data = this.vaservices.GetData({
      OperationName: null,
      Query: `query {
        ExportCaseDetails: VitalAdminAttributes(Context: "XmlVersions", Keyword: "ExportCaseDetails") {
          Items_JSON
        }
      }`,
      Variables: null,
    }, 'configdb').subscribe(
      data => {
        if (data?.data?.ExportCaseDetails?.Items_JSON) {
             this.resultData = JSON.parse(data?.data?.ExportCaseDetails?.Items_JSON);
          }
        this.ngxService.stop();
      },
      error => {
        console.error(error);
        this.ngxService.stop();
      }
    );
  }

  // to close the modal
  exportCaseClose() {
    this.ExportCasegroup.reset();
    this.activeModal.close();
    this.ExportCasegroup.patchValue(
      {
        DeploymentKey: "none",
        QuickSearch: "caseid",
        ExportCaseSearchInput: "",
        ExportCaseGenerateDML: "",
        ConnectionName: false
      });
    this.roledata = ""
    this.nodata = false
    this.homeComponent.SelectedMenuModel = false;
  }

  selectDeployment(tab, event) {
    if (event.source.selected) {
      tab.ResultContent.Deployment = event.source.value.toString().toUpperCase();
    }
  }

  // copy the script
  copyData(value: string) {
    this.clipboard.copy(value);
    this._snackBar.open('The script has been copied.', 'Success');
  }

  // get call - to gengrate the DML script
  async generateDML(value) {
    this.nodata = false;
    this.roledata = "";
    this.ExportCasegroup.patchValue({
      ExportCaseGenerateDML: this.roledata
    })
    this.methodHit = true;
    let form = this.ExportCasegroup.value;
    if (form.DeploymentKey === this.selecteddefaultdeploymentkey || form.DeploymentKey == 'none') {
      form.DeploymentKey = this.selecteddefaultdeploymentkey == 'ALL' ? form.DeploymentKey : this.selecteddefaultdeploymentkey;
    }
    if (!form.ConnectionName) {
      form.ConnectionName = false;
    }  
 
    this.ngxService.start();  
    try {  
      this.VersionOfFile = this.resultData[form.DeploymentKey.toUpperCase()];
      // Generate the DML script
      const ResData = await this.vaservices.generateDMLScript(form.QuickSearch, form.ExportCaseSearchInput, form.ConnectionName, form.DeploymentKey, this.VersionOfFile).toPromise();
         this.DownloadFilename = form.DeploymentKey + '_' + form.QuickSearch + '_' + form.ExportCaseSearchInput;
          this.ngxService.stop();
          if (ResData == null) {
            this.nodata = true;
          }
      else if (!ResData.errors) {
        this.roledata = await ResData.text()
        //this.roledata = this.roledata.replace(/<br \/>/gi, '\n').replace(/&nbsp;/gi, ' ');
        this.ExportCasegroup.patchValue({
          ExportCaseGenerateDML: this.roledata
        })

        if (/No data found/i.test(this.roledata)) {
          this.nodata = true;
        }
      } else {
        this._snackBar.open("An error occurred while processing your request", 'Failed');
        this.nodata = true;
      }
      
    } catch (error) {
      this.getError(error);
      this.nodata = true;
    }
  } 

  downloadData() {
    const blob = new Blob([this.roledata], { type: "application/sql" });
    const a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = this.DownloadFilename + '.sql';
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }


  // validation
  getError(error) {
    this.roledata = ""
    this.ngxService.stop();
    this._snackBar.open("An error occurred while processing your request", 'Failed');
    console.log(error);
  }
}
