<!-- if there is data -->
<div *ngIf="gridShow1 && !noDataFound" class="siteorderdiv">
  <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
      <strong class="header-View">{{templateData.menuURL}}</strong>
    </h3>
  </div>
  <app-templates-listing2 [gridHeader]="listGridheaders" [gridData]="gridData" [groupBy]="groupBy"
    (createEmitter)="createdSiteOrders($event)" (copyEmitter)="loadCopyScreen()" (editEmitter)="editClicked($event)"
    (manageSiteOrderEventTriggered)="OpenMangeSite()" (toggleChangeEmitter)="UpdateVAEntityAttribute($event)"
    (deleteEmitter)="removeSiteOrder($event)" [context]="'Site Order'" [siteLabelAlphabetFlag]="sitelebelaphabetflag"
    [sortAZ]="sortAZ" [hideEdit]="editBtn" [isEdit]="true" [hideCreate]="createBtn" [hideDelete]="deletBtn"
    [hideUpload]="uploadBtn" [hideExport]="false" [hideCopy]="copyBtn" [dragDrop]="false" [hideDragDrop]="true">
  </app-templates-listing2>
</div>

<!-- Create Sites -->
<div *ngIf="createdpage">
  <div class="row col-sm-12 p-0 m-0">
    <div class="row col-sm-12 pr-0">
      <div class="col-sm-3 p-0 modal-header admin-model-header mb-2 mt-3 listingModalHeader">
        <h3 class="modal-title w-100 admin-model-header-txt main-title p-0">
          <strong class="header-View">{{ action }}</strong>
        </h3>
      </div>

      <div class="col-sm-9 align-center button-wrap p-0">
        <a  *ngIf="sitesequenceleableshow" class="reset-all mr-4" type="button" (click)="resetOption(frm_SiteOrder.value)">
          Reset
        </a>
        <button class="admin-rtn-btn mr-4" (click)="Refershback()">
          Return
        </button>
        <button class="admin-btn" (click)="saveAndCreate(frm_SiteOrder.value,'close')">
          Save
        </button>
      </div>
    </div>

    <form [formGroup]="frm_SiteOrder" autocomplete="off" class="col-sm-12 p-0 text-wrap">
      <div class="p-3 ml-0 card-wrapper">
        <div class="col-sm-12 mt-2 box-align p-0">

          <div class="row col-sm-12">
            <div class="col-sm-3 w-25 p-0">
              <div *ngIf="action=='Edit Site Orders'" style="margin-right: 20px;">
                <app-input-auto-complete  [required]="true" labelValue="No of Jars" class="mb-3"
                  [dropDownValue]="Jarsvalue"
                  [value]="editNumberOfJars"
                  (onKeyUpChanges)="filterNumberofJars($event)"
                  placeholder=""
                  [isReadOnly]="true"
                  [disabled]="true"
                  style="pointer-events: none; position: relative;"> 
                </app-input-auto-complete>
              </div>
              <div *ngIf="action=='Create Site Orders'"  style="margin-right: 20px;">
              <app-input-auto-complete  [required]="true" labelValue="No of Jars" class="mb-3"
                                [dropDownValue]="Jarsvalue" [value]="frm_SiteOrder.value.frmNumberOfJars"
                                (onKeyUpChanges)="filterNumberofJars($event)"
                                placeholder=""></app-input-auto-complete>
            </div>
            </div>
            <div class="col-sm-3 w-25 p-0" *ngIf="siteordersitechemaflag"  style="margin-right: 20px;">
              <div *ngIf="action=='Edit Site Orders'">
                <app-input  class="f-12 mb-3" labelValue="Site Scheme"
                placeholder="" formcontrolname="frmSechmaSite"  isReadOnly="true"   style="pointer-events: none; position: relative;"
               ></app-input>
              </div>
              <div *ngIf="action=='Create Site Orders'">
                <app-input  class="f-12 mb-3" labelValue="Site Scheme"
                placeholder="" formcontrolname="frmSechmaSite"
               ></app-input>
              </div>
            </div>

            <div *ngIf="(action=='Create Site Orders' && sitesequenceleableshow && Jarsvalue.length>0 ) "
              class="col-sm-3 w-25 p-0"  style="margin-right: 20px;">
            <app-input-auto-complete labelValue="Copy from Existing Site Order" class="mb-3"
            [dropDownValue]="copyJarsdataschema" [value]="number"
            (onSelected)="copyNumberJars($event)"
            placeholder=""></app-input-auto-complete>
            </div>
          </div>

          <div class="col-sm-12 mt-2 p-0">
            <div class="col-sm-12 p-0">
              <div *ngIf="sitesequenceleableshow" class="col-sm-12 p-0">
                <div class="col-sm-12">
                  <div class="col-sm-12 align-center p-0 mt-2 mb-2">
                    <label class="col-sm-2 p-0 m-0">Sequence</label>
                    <div class="col-sm-10 border-line" ></div>
                  </div>
                  <div *ngFor="let Number of sitelebelvalueshow;let index = index" class="col-sm-12 p-0 d-flex mb-3">
                    <label class="text-color" style="padding-top: 10px; min-width: 50px; text-align: center;">
                      {{Number}}.
                    </label>
                    <app-input-auto-complete labelValue="Site Name" class="mb-3"
                    [dropDownValue]="siteorderuniqueData"  [(value)]="editvalueforsitename[index]"
                    (onSelected)="SaveBodySitename($event,index)"
                    placeholder=""></app-input-auto-complete>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="col-sm-12 d-flex p-0 mt-2">
     
    </div>

  </div>
</div>


<!-- Upload  -->
<div *ngIf="uploadClicked" class="mt-3 my-manage-form">
  <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
      <strong class="header-View">Upload Site Order</strong>
    </h3>
  </div>
  <div class="button-field-wrap m-1 p-0">
    <mat-form-field class="col-sm-3 example-additional-selection copyfrmOrg-site-dropdown p-0" appearance="outline">
      <mat-label class="d-flex">
        Templates
      </mat-label>
      <!-- <em class="fa fa-chevron-down chevron-align"></em> -->
      <mat-select disableOptionCentering class="ml-2 temp-mat-align">
        <mat-option class="temp-font-size" (click)="downloadTemplate('allFieldsCheck')">
          <mat-icon class="pr-2 get-app">get_app</mat-icon>All Fields
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="button-field-wrap">
    <span class="instr-size mb-2">
      <img aria-hidden="true" alt="help" class="mb-1" src="/assets/icons/help.svg" width="15px" height="auto" />
      You can download the sample template file to upload</span>
  </div>

  <div class="col-sm-12 p-0" *ngIf="gridwidth == 0">
    <div class="image-height" (fileDropped)="onFileChange($event)" appDnd>
      <input type="file" id="fileDropRef" #fileDropRef click="value = null" value="" (change)="onFileChange($event)"
        hidden />
      <span class="align-center img-wrap">
        <img src="/assets/images/upload_excel.svg" alt="upload" width="60px" height="auto">
      </span>
      <span>Drag & Drop excel files here</span>
      <span class="align-center">Or</span>
      <label class="btn-sm btn rounded browse ml-5" for="fileDropRef">Browse File</label>
    </div>
    <button mat-raised-button class="admin-btn-success mt-4" (click)="backSelect()">
      Back
    </button>
  </div>

  <div *ngIf="gridwidth > 0">
    <wj-flex-grid class="grid-properties" [isReadOnly]="true" [itemsSource]="copygridData"
      (initialized)="initGrid(grid)" #grid [headersVisibility]="'Column'" (formatItem)="formateItem(grid, $event)"
      [frozenColumns]="2">
      <!-- Status -->
      <wj-flex-grid-column [header]="'Status'" [binding]="status" *ngIf="postUpload" [allowResizing]="true"
        [width]="170" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
          <span
            [ngStyle]="{color: cell.item.status=='Success' ?'green':cell.item.status=='Ignored' ? '#ff9800' : 'red' }">{{cell.item.status}}
          </span>
        </ng-template>
      </wj-flex-grid-column>


      <div *ngFor="let columnname of sheetHeader">
        <wj-flex-grid-column [binding]="columnname?.toString().toLowerCase()" [header]="columnname"
          [allowResizing]="true" [width]="'*'" [allowDragging]="false" [allowSorting]="false" [format]="'d'">
        </wj-flex-grid-column>
      </div>
      <!-- Action -->
      <wj-flex-grid-column *ngIf="showDelete" [header]="'Action'" align="center" [width]="126" [isReadOnly]="true"
        [format]="'d'">
        <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
          <button class="edit-delete-btn" (click)="deleteRow(grid, row)">
            <em id="deleteButton" [title]="'Delete'" class="p-0 fa fa-trash"></em>
          </button>
        </ng-template>
      </wj-flex-grid-column>
      <wj-flex-grid-filter #filter></wj-flex-grid-filter>
    </wj-flex-grid>

    <div class="row pl-3">
      <div class="col-sm-2 p-0 mt-4">
        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth > 0"
          (click)="gridRemove()">Back</button>
        <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth == 0"
          (click)="backSelect()">Back</button>
      </div>
      <div class="col-sm-10 button-wrap p-0 mt-4">
        <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
          (click)="backSelect()">Cancel</button>
        <button mat-raised-button *ngIf="!postUpload" class="admin-btn-success mr-4"
          (click)="approveSiteOrder()">Approve</button>
        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success mr-4"
          (click)="backSelect()">Finish</button>
        <button mat-raised-button *ngIf="postUpload" class="admin-btn-success" (click)="ExportExcel(grid)">Download
          Result</button>
      </div>
    </div>
  </div>
  <div *ngIf="showInvalidColumns" class="m-1 p-0 error-field-wrap">
    <div class="error-msg">*Invalid Columns: </div>&nbsp;<span>{{invalidColumns}} </span>
  </div>
</div>
<!-- Choose sheet to upload -->
<div class="modal" id="selectSheetModal" aria-hidden="true" data-backdrop="true" data-keyboard="false">
  <div class="modal-dialog large-model">
    <div class="modal-content">
      <div class="admin-model-header">
        <h4 class="modal-title p-1">Choose a sheet to be uploaded</h4>
      </div>
      <div class="modal-body modal-btn-wrap row">
        <div *ngFor="let sheet of sheetsToSelect">
          <button mat-raised-button class="admin-btn-success" (click)="getSheetName(sheet)">
            {{sheet}}
          </button>
        </div>
      </div>
      <div class="modal-footer cursor">
        <button type="button" mat-raised-button class="admin-btn-success" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<!-- Copy From Other Entity -->
<div *ngIf="copyDataClicked">
  <div class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
      <strong class="header-View">Copy Site Order</strong>
    </h3>
  </div>
  <form [formGroup]="frm_SiteOrder" class="copy-collection">
    <div class="p-0 ml-0">
      <div class="col-sm-12 mb-4 text-wrap-icdcode">
        <mat-form-field class="col-sm-4 example-additional-selection copyfrmOrg-site-dropdown mt-2" appearance="outline">
          <mat-label>Deployment<span class="error-msg">*</span></mat-label>
          <mat-select disableOptionCentering type="text" matInput #searchbar formControlName="frmDepKey">
            <mat-option class="temp-font-size" *ngFor="let i of DeploymentKeys" value="{{i}}"
              (onSelectionChange)="onChangeDeployment($event,i)">
              <span>{{ i }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-sm-4 example-additional-selection copyfrmOrg-site-dropdown mt-2" appearance="outline">
          <mat-label>Group<span class="error-msg">*</span></mat-label>
          <em class="fa fa-chevron-down chevron-align" (click)="fetchOrgSeries(frm_SiteOrder.value.frmOrganization)"></em>
          <input [readonly]="postUpload" formControlName="frmOrganization" type="text" matInput #searchbar
          (click)="fetchOrgSeries(frm_SiteOrder.value.frmOrganization)"
            (keyup)="fetchOrgSeries(frm_SiteOrder.value.frmOrganization)" [matAutocomplete]="orgauto"
            placeholder="Search here ..." style="width: 95%;">
          <mat-autocomplete #orgauto="matAutocomplete">
            <div *ngFor="let show of searchResult">
              <mat-option [disabled]="postUpload" class="mat-opt-align"
                title="{{ show.organizationname }} ({{show.organizationid}})"
                (onSelectionChange)="selectedCopyDataOrganization($event, show)" value="{{ show.orgname }}">
                <span>{{ show.organizationname }} ({{show.organizationid}})</span>
              </mat-option>
            </div>
          </mat-autocomplete>
        </mat-form-field>


        <mat-form-field class="col-sm-4 example-additional-selection copyfrmOrg-site-dropdown mt-2" appearance="outline">
          <mat-label>No of Jars</mat-label>
          <em class="fa fa-chevron-down chevron-align" (click)="fetchJarCount(frm_SiteOrder.value.frmNumberOfJars)"></em>
          <input formControlName="frmNumberOfJars" type="number" matInput #searchbar [matAutocomplete]="orgjars"
          (click)="fetchJarCount(frm_SiteOrder.value.frmNumberOfJars)"
          (keyup)="fetchJarCount(frm_SiteOrder.value.frmNumberOfJars)" placeholder="Search here ..."
            style="width: 95%;">
          <mat-autocomplete #orgjars="matAutocomplete">
            <div *ngFor="let k of searchJarResult">
              <mat-option class="mat-opt-align" title="{{ k }}" value="{{ k }}"
                (onSelectionChange)="selectedCopyDatajars($event, k)">
                <span>{{ k }}</span>
              </mat-option>
            </div>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <div *ngIf="gridwidth > 0" class="col-sm-12">
        <wj-flex-grid [isReadOnly]="true" [itemsSource]="copygridData" (initialized)="initGrid(flexgrid,1)" #flexgrid
          (formatItem)="formateItem(flexgrid, $event)" [frozenColumns]="1">
          <!-- Status -->
          <div *ngIf="copysechmaflag">
            <wj-flex-grid-column *ngIf="postUpload" [binding]="'status'" [allowSorting]="true" [header]="'Status'"
              [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'notes'" [allowSorting]="true" [header]="'Notes'" [isRequired]="true"
              [width]="'*'"></wj-flex-grid-column>
            <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'"
              [fixed]="true" [binding]="'jars'" [header]="'Jars'" [allowMerging]=true></wj-flex-grid-column>
            <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'"
              [fixed]="true" [binding]="'sitescheme'" [header]="'Site Scheme'"
              [allowMerging]=true></wj-flex-grid-column>

            <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'"
              [fixed]="true" [binding]="'sequencelabel'" [header]="'Sequence'"></wj-flex-grid-column>
            <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'"
              [fixed]="true" [binding]="'sitename'" [header]="'Site Name'"></wj-flex-grid-column>
          </div>
          <div *ngIf="!copysechmaflag">
            <wj-flex-grid-column *ngIf="postUpload" [binding]="'status'" [allowSorting]="true" [header]="'Status'"
              [isRequired]="true" [width]="'*'"></wj-flex-grid-column>
            <wj-flex-grid-column [binding]="'notes'" [allowSorting]="true" [header]="'Notes'" [isRequired]="true"
              [width]="'*'"></wj-flex-grid-column>
            <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'"
              [fixed]="true" [binding]="'jars'" [header]="'Jars'" [allowMerging]=true></wj-flex-grid-column>
            <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'"
              [fixed]="true" [binding]="'sequencelabel'" [header]="'Sequence'"></wj-flex-grid-column>
            <wj-flex-grid-column [allowResizing]="true" [allowDragging]="false" [format]="'d'" [width]="'*'"
              [fixed]="true" [binding]="'sitename'" [header]="'Site Name'"></wj-flex-grid-column>
          </div>
          <wj-flex-grid-filter #filter></wj-flex-grid-filter>
        </wj-flex-grid>
        <div class="col-sm-12 pl-0 mt-4">
          <div *ngIf="gridwidth == 0">
            <button mat-raised-button class="admin-btn-success mr-4" (click)="backSelect()">Back</button>
          </div>

          <div *ngIf="gridwidth > 0" class="m-1 p-0">
            <button mat-raised-button class="admin-btn-success mr-4" *ngIf="gridwidth > 0"
              (click)="gridRemove()">Back</button>
            <span style="float: right;">
              <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!postUpload"
                (click)="backSelect()">Cancel</button>
              <!-- <div *ngIf="!btnDisable"> -->
              <button mat-raised-button class="admin-btn-success mr-4" *ngIf="postUpload"
                (click)="backSelect()">Finish</button>
              <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!postUpload" [disabled]="approveDisable()"
                (click)="approveSiteOrder('copy')">Approve</button>
              <button mat-raised-button class="admin-btn-success" *ngIf="postUpload"
                (click)="ExportExcel(flexgrid)">Download
                Result</button>
            </span>
          </div>
        </div>
      </div>

      <div class="col-sm-12 pl-0 mt-4">
        <div *ngIf="gridwidth == 0">
          <button mat-raised-button class="admin-btn-success mr-4" (click)="backSelect()">Back</button>
        </div>
      </div>

      <div class="col-sm-12" *ngIf="gridwidth == 0">
        <div class="container create-btn col-sm-12 align-center" *ngIf="noDataFoundCopy">
          <div class="col-sm-10 nodata-wrap no-data-wraps mt-4">
            <div class="nodata-design">No Data Found</div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>