<div *ngIf="gridPage && !sourceApp" class="row">
    <div class="col-md-12 p-0">
        <div class="modal-header admin-section-header mt-2 listingModalHeader">
            <h3 class="modal-title w-100 admin-model-header-txt">
                <strong class="header-View">Quality Attributes</strong>
            </h3>
        </div>
    </div>
</div>

<!-- <mat-spinner class="labadmin-spinner" *ngIf="sourceApp && !(gridPage && (qualityAttributeFilter$|async)?.['data']) && !uploadClicked"
    style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50"></mat-spinner> -->

<div class="px-4" *ngIf="sourceApp && !(gridPage && (qualityAttributeFilter$|async)?.['data']) && !uploadClicked">
    <app-shimmerui [line] = 8></app-shimmerui>
</div>


<section class="ang-mat-section" *ngIf="gridPage && qualityAttributeFilter$ | async as qualityAttribueList">
    <div>
        <div *ngIf="!sequenceEditMode" class="d-flex align-items-center mt-3 mb-2 mx-2 filters gap-4px">
            <span class="not-selectable text-center" tabindex="0" [ngClass]="[filtercondition.value.status=='all' ? 'filterselected' : '',qualityAttribueList.isNoDataPresent || qualityAttributeSelectionModel.selected.length?'disabled':'']"
            (click)="!qualityAttribueList.isNoDataPresent || qualityAttributeSelectionModel.selected.length?Filter$({status:'all'}):null">All ({{qualityAttribueList.statusCount.totalCount}})</span>
            <span id="seperator"></span>
                <div class="filters d-flex not-selectable flex-wrap gap-4px">
                    <span class="not-selectable text-center" tabindex="0"
                        [ngClass]="[filtercondition.value.status=='active'? 'filterselected' : '',qualityAttribueList.isNoDataPresent?'disabled':'']"
                        (click)="!qualityAttribueList.isNoDataPresent?Filter$({status:'active'}):null" [class.filterDisabled]="(qualityAttribueList.statusCount.activeCount <= 0)">
                        Active ({{qualityAttribueList.statusCount.activeCount}})
                    </span>
                    <span class="not-selectable text-center" tabindex="0"
                        [ngClass]="[filtercondition.value.status=='inactive'? 'filterselected' : '',qualityAttribueList.isNoDataPresent?'disabled':'']"
                        (click)="!qualityAttribueList.isNoDataPresent?Filter$({status:'inactive'}):null" [class.filterDisabled]="(qualityAttribueList.statusCount.totalCount-qualityAttribueList.statusCount.activeCount <= 0)">
                        Inactive ({{qualityAttribueList.statusCount.totalCount-qualityAttribueList.statusCount.activeCount}})
                    </span>

                </div>
            <span id="miniseperator"></span>

            <app-auto-complete-select class="ml-auto" placeholder="Select Role" [dropDownValue]="RoleList"
                (onSelection)="RoleStatusSelection($event)"
                [isDisabled]="qualityAttribueList.isNoDataPresent || qualityAttributeSelectionModel.selected.length"></app-auto-complete-select>

            <app-auto-complete-select placeholder="Select Case Status" [dropDownValue]="caseStatus"
                (onSelection)="caseStatusSelection($event)"
                [isDisabled]="qualityAttribueList.isNoDataPresent || qualityAttributeSelectionModel.selected.length"></app-auto-complete-select>

            <app-search-box placeHolder="Search Quality Attribute" (enteredValue)="filterStatus({searchText:$event})"
                class="flex-grow-1 mr-1" [isDisabled]="qualityAttribueList.isNoDataPresent || qualityAttributeSelectionModel.selected.length"></app-search-box>

            <app-button class="mr-1" matTooltip="Copy" *ngIf="copyBtn" [isDisabled]="!copyBtn || qualityAttributeSelectionModel.selected.length > 0" [matTooltipDisabled]="!editBtn || qualityAttributeSelectionModel.selected.length > 0" image="icons/Global_Icon.svg" (click)="copyOrg()" class=""></app-button>

            <app-button class="mr-1" matTooltip="Reorder" *ngIf="editBtn" [matTooltipDisabled]="!editBtn || qualityAttributeSelectionModel.selected.length > 0 || isFilterEnabledChanged()"  image="icons/Reorder_Blue.svg" class="" [isDisabled]="qualityAttribueList.isNoDataPresent || !editBtn || qualityAttributeSelectionModel.selected.length > 0  || isFilterEnabledChanged()"
                (click)="enableSortEdit()"></app-button>

            <app-button buttonHoverText=":Bulk Upload" [isDisabled]="qualityAttributeSelectionModel.selected.length > 0" [hideRightBtn]="!uploadBtn" [hideLeftBtn]="!createBtn" [isRightButtonDisabled]="!uploadBtn || qualityAttributeSelectionModel.selected.length > 0" 
                [multiple]="true" image=":icons/Excel-Bulk Upload_white.svg" buttonText="Create" (leftBtnClick)="openCreateGroup()" 
                 (rightBtnClick)="loadUploadScreen()" class=""></app-button>
        </div>
        <div *ngIf="sequenceEditMode" class="mt-3 mb-2 d-flex">
            <app-button buttonclass="secondary" class="ml-auto edit-page-button"
                (click)="returnToList()">Return</app-button>
            <app-button buttonclass="primary" class="edit-page-button ml-2" (click)="saveSequence()"
                [isDisabled]="!isSortDataChanged">Save</app-button>
        </div>
        <div class="content-slab">
            <span>QA Group Name</span>


            <img *ngIf="sequenceEditMode && isGroupAscending === 'DESC'"
                (click)="sequenceTracking('group','ASC', qualityAttribueList.data)" matTooltip="Sort" matTooltipPosition="right" class="ml-2 cursor" height="17px"
                src="/assets/icons/Reorder_Descending.svg" alt="Descending">
            <img *ngIf="sequenceEditMode && isGroupAscending === 'ASC'"
                (click)="sequenceTracking('group','DESC', qualityAttribueList.data)" matTooltip="Sort" matTooltipPosition="right" class="ml-2 cursor" height="17px"
                src="/assets/icons/Reorder_Asceding.svg" alt="Ascending">
            <img *ngIf="sequenceEditMode && isGroupAscending === 'UNS'"
                (click)="sequenceTracking('group','ASC', qualityAttribueList.data)" matTooltip="Sort" matTooltipPosition="right" class="ml-2 cursor" height="17px"
                src="/assets/icons/Mix - Sort.svg" alt="Un-Ordered">
            <img *ngIf="areAllCollapsed(qualityAttribueList.data)" src="../../../../assets/icons/tableExpandAll.svg" [attr.disabled]="" (click)="expandAll()"  height="17px"
                class="ml-2 cursor" matTooltip="Expand All"  alt="Expand"    />
            <img *ngIf="!areAllCollapsed(qualityAttribueList.data)"  src="../../../../assets/icons/tableCollapse.svg" [attr.disabled]="" (click)="collapseAll(qualityAttribueList.data)" height="17px"
                class="ml-2 cursor"   matTooltip='Collapse All' alt="Collapse"    />


            <span *ngIf="!sequenceEditMode" class="right-elements cursor"  (click)="(!qualityAttribueList.isNoDataPresent && qualityAttribueList.data.length > 0 && qualityAttributeSelectionModel.selected.length === 0 &&filtercondition.value.status!='inactive') || !editBtn ?openPreview():null" [class.disabled] ="(qualityAttribueList.isNoDataPresent || qualityAttribueList.data.length === 0 || qualityAttributeSelectionModel.selected.length || filtercondition.value.status=='inactive')&&editBtn">Preview</span>

            <img *ngIf="!sequenceEditMode&&exportBtn" class="excel" src="/assets/icons/Excel_Download.svg" matTooltip="Export" alt="Download As Excel"  (click)="!qualityAttribueList.isNoDataPresent && qualityAttribueList.data.length > 0 && qualityAttributeSelectionModel.selected.length === 0 ?exportexcel(qualityAttribueList.data):null" [class.disabled]="qualityAttribueList.data.length === 0 || qualityAttribueList.isNoDataPresent || qualityAttributeSelectionModel.selected.length > 0" [matTooltipDisabled]="qualityAttribueList.data.length === 0 || qualityAttribueList.isNoDataPresent || qualityAttributeSelectionModel.selected.length > 0">
</div>                                                                                                                                                                                                                                                    
        <div class="colsplit" [class.nogrid]="sequenceEditMode || qualityAttribueList.data.length == 0 "
             *ngIf="!qualityAttribueList.isNoDataPresent || qualityAttributeSelectionModel.isSelected(-1)">
            <div class="quality-attributes "  [class.main-div]="qualityAttribueList.data.length != 0" >
                <div [ngClass]="{'vitalDx': sourceApp === 'VitalDx'}">
                    <div *ngIf="qualityAttribueList.data.length == 0 && !qualityAttributeSelectionModel.isSelected(-1)"
                        class="col-sm-12 text-center">
                        <div class="col-sm-12 nodata-header-wrap">
                            <span class="col-sm-12 nodata-wrapper"> No Results </span>
                        </div>
                    </div>
                    <div #scrollableDiv cdkDropList [cdkDropListData]="qualityAttribueList.data"
                        *ngIf="qualityAttribueList.data.length > 0" (cdkDropListDropped)="drop($event,'group')"
                        cdkDropListGroup class="search-results main-list mt-2" [alwaysCallback]="true"
                        (scroll)="onScroll($event)">
                        <div *ngFor="let item of qualityAttribueList.data; let i = index"
                            class="quality-attribute-main"
                            cdkDragBoundary=".main-list" cdkDragLockAxis="y" cdkDrag
                            [cdkDragDisabled]="!editBtn || qualityAttributeSelectionModel.selected.length > 0 || !sequenceEditMode">
                            <div class="d-flex"  [class.collapsed]="isCollapsed(item)" (click)="!editBtn ?openEditGroup(item):null" [class.cursor-pointer]="!editBtn">

                                <div *ngIf="sequenceEditMode" class="drag-handle margin-left-10" cdkDragHandle>
                                    <img src="/assets/icons/Drag_handle.svg" alt="drag" width="14px" />
                                </div>
                                <!-- <div class="seperator"></div> -->
                                <span (click)="!editBtn?openEditGroup(item) : null" [class.cursor]="!editBtn" class="ml-1 mr-2" >QA Group Name: <span>{{ item.attrGroupName.category }}</span>


                                    <img class="ml-2" matTooltip="Sort" matTooltipPosition="right" (click)="sequenceTracking(item.attrGroupName.attrid,'ASC', item)"  [class]="sequenceEditMode && item.attrGroupName.currentChildSortOrder === 'DESC' ? 'show-sort' : 'hide-sort'" height="17px" src="/assets/icons/Reorder_Descending.svg" alt="Descending">

                                    <img class="ml-2" height="17px" matTooltip="Sort" matTooltipPosition="right" (click)="sequenceTracking(item.attrGroupName.attrid,'DESC', item)" [class]="sequenceEditMode && item.attrGroupName.currentChildSortOrder === 'ASC' ? 'show-sort' : 'hide-sort'" src="/assets/icons/Reorder_Asceding.svg" alt="Ascending">

                                    <img class="ml-2" height="17px" matTooltip="Sort" matTooltipPosition="right" (click)="sequenceTracking(item.attrGroupName.attrid,'ASC', item)" [class]="sequenceEditMode && item.attrGroupName.currentChildSortOrder === 'UNS' ? 'show-sort' : 'hide-sort'" src="/assets/icons/Mix - Sort.svg" alt="Un-Ordered">
                                </span><span class="f-12"></span>

                                <img  *ngIf="isCollapsed(item)" src="../../../../assets/icons/tableExpandAll.svg" [attr.disabled]="" (click)="toggleCollapse(item)" class="img-width" matTooltip="Expand"  alt="Expand"    />

                                <img  *ngIf="!isCollapsed(item)" src="../../../../assets/icons/tableCollapse.svg" [attr.disabled]="" (click)="toggleCollapse(item) " class="img-width"    matTooltip='Collapse' alt="Collapse"    />
                                <div *ngIf="!sequenceEditMode"
                                    class="ml-auto mr-2 d-flex align-items-center justify-content-center gap-20">
                                    <div>
                                        <button class="card-action-button" [matMenuTriggerFor]="create" *ngIf="createBtn"
                                            [matTooltipDisabled]="!createBtn" [disabled]="!createBtn"
                                            matTooltip="Add Attribute">
                                            <img [class.disabled]="!createBtn" src="../../../../assets/images/add_circle.png" class="img-width"
                                                alt="Add" />
                                        </button>
                                        <mat-menu #create="matMenu" xPosition="before">
                                            <button mat-menu-item class="f-12 sort-menu-option"
                                                (click)="openAddAttribute('Add Quality Attribute',item.attrGroupName)">
                                                <span>Add Quality Attribute</span>
                                            </button>
                                            <button mat-menu-item class="f-12 sort-menu-option"
                                                [disabled]="item.attrGroupName.isOthersPresent"  
                                                (click)="openAddAttribute('Other',item.attrGroupName)">
                                                <span>Add Other</span>
                                            </button>
                                        </mat-menu>
                                    </div>
                                    <img *ngIf="editBtn" src="../../../../assets/images/edit.png" [attr.disabled]="" (click)="editBtn?openEditGroup(item) : null" class="img-width"
                                    [matTooltip]=" 'Edit' " alt="edit"
                                    [matTooltipDisabled]="!editBtn"
                                    [class.disabled]="!editBtn" />
                                </div>
                            </div>

                            <!-- edit group region -->
                            <div cdkDropList *ngIf="!isCollapsed(item)" [cdkDropListData]="item.attrList" class="items-list"
                                (cdkDropListDropped)="drop($event, 'attr', item)"
                                [style]="item.attrList.length > 0? 'min-height: 60px; padding: 10px;':''">

                                <ng-container *ngIf="item.attrList.length > 0">
                                    <div class="items"  *ngFor="let childItem of item.attrList" cdkDragBoundary=".items-list"
                                        cdkDragLockAxis="y" cdkDrag
                                        [cdkDragDisabled]="!editBtn ||qualityAttributeSelectionModel.selected.length>0 || !sequenceEditMode">

                                        <div (click)="!sequenceEditMode ?openEditAttribute(childItem,item):null"
                                         [class.cursor-pointer]="!editBtn"
                                         [ngClass]="childItem.isactive.toLowerCase()"
                                         [class.item-selected] = "qualityAttributeSelectionModel.isSelected(childItem.attrid)">
                                            <div *ngIf="sequenceEditMode" class="drag-handle" cdkDragHandle>
                                                <img src="/assets/icons/Drag_handle.svg" alt="drag" width="14px" />
                                            </div>

                                            <span class="ml-1">{{ childItem.attrname }}</span>
                                            <img [ngClass]="'opacity-'+childItem.isactive.toLowerCase()+' ml-2'"
                                                *ngIf="childItem.iscomment" src="../../../../assets/images/comment.png"
                                                class="img-width" alt="comments" matTooltip="Comments" matTooltipPosition="right"/>
                                            <div *ngIf="!sequenceEditMode&&editBtn" class="ml-auto"
                                                (click)="editBtn?openEditAttribute(childItem,item):null">
                                            </div>
                                        </div>

                                    </div>
                                </ng-container>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div *ngIf="(!sequenceEditMode && qualityAttribueList.data.length != 0) || qualityAttributeSelectionModel.isSelected(-1) " class="mb-2 m-3 mr-4">
                <div class="mb-2" *ngIf="!qualityAttributeSelectionModel.selected.length>0">
                    <span class="nodata-wrapper1"> Select any Quality Attribute to Manage </span>
                </div>
                <div class="mb-2" *ngIf="qualityAttributeSelectionModel.isSelected(-1)">
                    <div class="vdm-heading">
                        <span>Create QA Group </span>
                    </div>
                    <div class="heading p-1 px-3 f-12 d-flex align-items-center">
                        <div class="ml-auto d-flex align-items-center">

                            <app-toggle-switch   [isChecked]="frmComments"
                                labelActivetext='Enable Comments:' labelInactivetext="Enable Comments:"
                                (clicked)="frmComments = $event"
                                type="secondary"></app-toggle-switch>

                            <app-toggle-switch [isChecked]="frmIsActive" labelActivetext='Status:'
                                labelInactivetext="Status:" (clicked)="frmIsActive = $event"
                                type="secondary"></app-toggle-switch>
                        </div>
                    </div>
                    <div class="form-subbody  pr-3 py-2 ">
                        <div class="ml-auto align-items-center">
                            <app-input [maxLength]="50"  [selectedValue]="frmGpName" class="f-12" placeholder="Enter Group Name"
                            labelValue="Group Name " [isFormValid]="QualityAttributeObject.frmGpName.isValid"
                            (onFocusOut)="[frmGpName=$event,validateQualityfrm('frmGpName')]" [required]="true">
                        </app-input>
                        </div>
                    </div>
                    <div class="form-body  pr-3 py-2">
                        <!-- create group region -->
                        <!-- <span class="sub-heading">Add Quality Attributes   </span>   -->
                        <div class="mt-2">
                            <app-input [maxLength]="100" [selectedValue]="frmname" class="f-12 mb-3" placeholder="Enter Quality Attribute" labelValue="Quality Attribute"
                                [isFormValid]="QualityAttributeObject.frmName.isValid"
                                (onFocusOut)="[frmname=$event,validateQualityfrm('frmname')]" [required]="true">
                            </app-input>

                            <app-multi-select-checkbox [listData]="RoleList" class="mb-3" (onFocusOut)="validateQualityfrm('frmRole')"
                                [selectionModel]="RolesSelectionModel"
                                [isFormValid]="QualityAttributeObject.frmRole.isValid" [labelValue]="'Role'"
                                [required]="true"></app-multi-select-checkbox>

                            <app-multi-select-checkbox [listData]="caseStatus" class="mb-3" [selectionModel]="CaseStatusSelectionModel"
                                [labelValue]="'Case Status'"
                                [palceholder]="'Select Case Sequence'"></app-multi-select-checkbox>

                            <app-multi-select-checkbox class="mb-3" [listData]="ReportTypes" [selectionModel]="ReportTypesSelectionModel"
                                labelValue="Report Type" [palceholder]="'Select Report Type'"></app-multi-select-checkbox>

                                <div class="text-container f-10 mb-3 expand" [class.cform-in-valid] = !QualityAttributeObject.frmDescription.isValid>
                                    <textarea maxlength="2000" rows="3" class=" p-2 word-wrap label-font-size"
                                       [value]="frmDescription"  name="frmDescription" #svalue (focus)="isFocused = true"                                           
                                       (input)="[frmDescription = $event.target.value, validateQualityfrm('frmDescription')]"
                                        (focusout)="svalue.value ? isFocused = true : isFocused = false"></textarea>
                                    <label [class.position]="isFocused || svalue.value ">Description</label>
                                </div>
                        </div>


                        <div class="button-container mt-2 d-flex">
                            <app-button buttonclass="secondary" class="ml-auto edit-page-button"
                                (click)="onCreateAttributeReturn()">Return</app-button>
                            <app-button buttonclass="primary" class="edit-page-button ml-2"
                                (click)="createNewAttribute(true)">Save</app-button>
                        </div>
                    </div>

                </div>
                <div class="mb-2" *ngIf="qualityAttributeSelectionModel.isSelected(selectedAttribute.attrid*-1)">
                        <div class="vdm-heading">                            
                            <span>{{editBtn ?'Edit QA Group' :'View QA Group'}}</span>
                        </div>
                        <div class="heading p-1 px-3 f-12">
                            <div class="ml-auto d-flex align-items-center">

                                <span class="f-12" >Group Sequence:&nbsp;</span>

                                <input type="number"  name="frmGpSquence" class="sequence-input"  [disabled]="!editBtn"
                                    [value]="frmGpSquence" (input)="frmGpSquence = $event.target.value"
                                    />

                                   &nbsp;&nbsp;&nbsp;&nbsp;
                                        <app-toggle-switch [isChecked]="selectedItem.attrGroupName.isAllAttrActive" 
                                        [id]="'status'+i"  [isDisable]="!editBtn" 
                                        labelActivetext='Status:' labelInactivetext="Status:"
                                        (clicked)="setAttributeStatus($event,selectedItem, 'status'+i)"
                                        type="secondary">
                                     </app-toggle-switch>
                            </div>
                        </div>
                        <div  class="form-body  pr-3 py-1">
                            <div class="mt-2">
                                <app-input [maxLength]="50" [selectedValue]="frmGpName" [placeholder]="" labelValue="QA Group Name"
                                    [isFormValid]="QualityAttributeObject.frmGpName.isValid"
                                    (onFocusOut)="[frmGpName=$event,validateQualityfrm('frmGpName')]"
                                    [required]="true"  [isDisabled]="!editBtn"  >
                                </app-input>
                            </div>
                            <div class="button-container mt-2 d-flex">
                                <app-button buttonclass="secondary" class="ml-auto edit-page-button"
                                    (click)="OnReturnGroup()">Return</app-button>
                                <app-button buttonclass="primary"  *ngIf="editBtn" class="edit-page-button" [isDisabled]="!(this.savedValue.category != this.frmGpName || this.savedValue.grouporder != this.frmGpSquence )"
                                    (click)="editGroupName(selectedItem.attrGroupName)">Save</app-button>
                            </div>
                        </div>




                </div>
                <div class="mb-2" *ngIf="qualityAttributeSelectionModel.isSelected(selectedAttribute.attrid) && addAttributeBtn">

                    <div class="vdm-heading" *ngIf="!isOthersPress">
                        <span>Add Quality Attribute</span>
                    </div>
                    <div class="vdm-heading" *ngIf="isOthersPress">
                        <span>Add Others</span>
                    </div>
                    <div class="heading p-1 px-3 f-12 d-flex align-items-center">
                        <div class="ml-auto d-flex align-items-center">

                            <app-toggle-switch   [isChecked]="frmComments" 
                                labelActivetext='Enable Comments' labelInactivetext="Enable Comments"
                                (clicked)="frmComments = $event"
                                type="secondary"></app-toggle-switch>

                            <app-toggle-switch [isChecked]="frmIsActive" labelActivetext='Status:'
                                labelInactivetext="Status:" (clicked)="frmIsActive = $event"
                                type="secondary"></app-toggle-switch>
                        </div>
                    </div>
                    <div class="form-subbody  pr-3 py-2 ">
                        <div class="ml-auto align-items-center">
                            <app-input [maxLength]="50" [selectedValue]="frmGpName" class="f-12" placeholder="Enter Group Name"
                            labelValue="Group Name " [isFormValid]="QualityAttributeObject.frmGpName.isValid"
                            (onFocusOut)="[frmGpName=$event,validateQualityfrm('frmGpName')]" [required]="true"
                            [isDisabled]="true">
                        </app-input>
                        </div>
                    </div>
                    <div class="form-body  pr-3 py-2">
                        <!-- <span class="sub-heading">Add Quality Attributes   </span>                     -->
                        <div class="mt-2">
                            <app-input [maxLength]="100" [selectedValue]="frmname" class=" f-12 mb-3" placeholder="Enter Quality Attribute"
                                labelValue="Quality Attribute " [isFormValid]="QualityAttributeObject.frmName.isValid"
                                (onFocusOut)="[frmname=$event,validateQualityfrm('frmname')]"
                                [required]="true" [isDisabled]="isOthersPress">
                            </app-input>

                            <app-multi-select-checkbox [listData]="RoleList" class="mb-3"
                                (onFocusOut)="validateQualityfrm('frmRole')"
                                [selectionModel]="RolesSelectionModel"
                                [isFormValid]="QualityAttributeObject.frmRole.isValid" [labelValue]="'Role'"
                                [required]="true"></app-multi-select-checkbox>

                            <app-multi-select-checkbox [listData]="caseStatus" class="mb-3"
                                [selectionModel]="CaseStatusSelectionModel" [labelValue]="'Case Status'"
                                [palceholder]="'Select Case Status'"></app-multi-select-checkbox>

                            <app-multi-select-checkbox [listData]="ReportTypes" class="mb-3"
                                [selectionModel]="ReportTypesSelectionModel" labelValue="Report Type"
                                [palceholder]="'Select Report Type'"></app-multi-select-checkbox>

                                <div class="text-container f-10 mb-3 expand" [class.cform-in-valid] = !QualityAttributeObject.frmDescription.isValid>
                                    <textarea maxlength="2000" rows="3" class=" p-2 word-wrap label-font-size"
                                       [value]="frmDescription"  name="frmDescription" #svalue (focus)="isFocused = true"                                           
                                       (input)="[frmDescription = $event.target.value, validateQualityfrm('frmDescription')]"
                                        (focusout)="svalue.value ? isFocused = true : isFocused = false"></textarea>
                                    <label [class.position]="isFocused || svalue.value ">Description</label>
                                </div>


                        </div>

                        <div class="button-container mt-2 d-flex">
                            <app-button buttonclass="secondary" class="ml-auto edit-page-button"
                                (click)="onCreateAttributeReturn()">Return</app-button>
                            <app-button buttonclass="primary" class="edit-page-button ml-2"
                                (click)="createNewAttribute()">Save</app-button>
                        </div>
                    </div>
                </div>
                <div class="mb-2" *ngIf="qualityAttributeSelectionModel.isSelected(selectedAttribute.attrid) && !addAttributeBtn " >

                            <div class="vdm-heading">
                                <span>{{editBtn ?'Edit Quality Attribute' :'View Quality Attribute'}}</span>
                            </div>
                            <div class="heading p-1 px-3 f-12 d-flex align-items-center">
                                <div class="ml-auto d-flex align-items-center">

                                    <span class="f-12" >QA Sequence:&nbsp;</span>
                                    <input type="number"  name="frmSequence" class="sequence-input"  [disabled]="!editBtn"
                                        [value]="frmSequence" (input)="frmSequence = $event.target.value"
                                         /> 
                                            &nbsp;
                                    <app-toggle-switch   [isChecked]="frmComments" *ngIf= "!isOthersPress"
                                        labelActivetext='Enable Comments:' labelInactivetext="Enable Comments:"
                                        (clicked)="frmComments = $event"  [isDisable]="!editBtn" 
                                        type="secondary"></app-toggle-switch>

                                    <app-toggle-switch [isChecked]="frmIsActive" 
                                        labelActivetext='Status:' labelInactivetext="Status:" [isDisable]="!editBtn" 
                                        (clicked)="frmIsActive = $event"
                                        type="secondary"></app-toggle-switch>

                                </div>
                            </div>
                            <div class="form-subbody  pr-3 py-2 ">

                                <div class="ml-auto align-items-center">
                                    <app-input  [maxLength]="50"  [selectedValue]="frmGpName" class="f-12" placeholder="Enter Group Name"
                                    labelValue="Group Name " [isFormValid]="QualityAttributeObject.frmGpName.isValid"
                                    (onFocusOut)="[frmGpName=$event,validateQualityfrm('frmGpName')]" [required]="true"
                                    [isDisabled]="true">
                                </app-input>
                                </div>
                            </div>
                            <div class="form-body  pr-3 py-2" >
                                <!-- <span class="sub-heading">Add Quality Attributes  </span>  -->
                                <div class="mt-2">

                                    <app-input [maxLength]="100" [selectedValue]="frmname" class=" f-12 mb-3"
                                        placeholder="Quality Attribute" labelValue="Quality Attribute"
                                        [isFormValid]="QualityAttributeObject.frmName.isValid"
                                        (onFocusOut)="[frmname=$event,validateQualityfrm('frmname')]"
                                        [required]="true" [isDisabled]="isOthersPress" [isDisabled]="!editBtn" >
                                    </app-input>
                                    <app-multi-select-checkbox [listData]="RoleList" class="mb-3"
                                        (onFocusOut)="validateQualityfrm('frmRole')"
                                        [selectionModel]="RolesSelectionModel"
                                        [isFormValid]="QualityAttributeObject.frmRole.isValid"
                                        [labelValue]="'Role'"  [isDisabled]="!editBtn" 
                                        [required]="true"></app-multi-select-checkbox>

                                    <app-multi-select-checkbox [listData]="caseStatus" class="mb-3"
                                        [selectionModel]="CaseStatusSelectionModel"
                                        [labelValue]="'Case Status'" [isDisabled]="!editBtn" 
                                        [palceholder]="'Select Case Status'"></app-multi-select-checkbox>

                                    <app-multi-select-checkbox [listData]="ReportTypes" class="mb-3"
                                        [selectionModel]="ReportTypesSelectionModel"
                                        labelValue="Report Type" [isDisabled]="!editBtn" 
                                        [palceholder]="'Select Report Type'"></app-multi-select-checkbox>


                                    <div class="text-container f-10 mb-3 expand" [class.cform-in-valid] = !QualityAttributeObject.frmDescription.isValid>
                                        <textarea maxlength="2000" rows="3" class=" p-2 word-wrap label-font-size" maxlength="2000"
                                           [value]="frmDescription"  name="frmDescription" #svalue (focus)="isFocused = true"                                           
                                           (input)="[frmDescription = $event.target.value, validateQualityfrm('frmDescription')]"
                                            (focusout)="svalue.value ? isFocused = true : isFocused = false" 
                                            [disabled]="!editBtn"></textarea>
                                        <label [class.position]="isFocused || svalue.value ">Description</label>
                                    </div>
                                </div>
                                <div class="button-container mt-2 d-flex" >

                                    <span class="ml-auto"></span>
                                    <span class="ml-auto reset-btn align-self-end mr-2" *ngIf=" onEditAttributeReturn()"
                                        (click)="onReset()">Reset</span>
                                    <app-button buttonclass="secondary" class=" edit-page-button" (click)="onAttributeReturn()">Return</app-button>
                                    <app-button buttonclass="primary" *ngIf = "editBtn" class="edit-page-button ml-2" (click)="updateAttribute()">Save</app-button>
                                </div>
                            </div>

                </div>
            </div>
        </div>

        <div *ngIf="qualityAttribueList.isNoDataPresent&&!qualityAttributeSelectionModel.isSelected(-1)"
            class="col-sm-12 text-center">
            <!-- <span class="w-50 text-center">
                No Data Available
            </span> -->
            <div class="col-sm-12 nodata-header-wrap">
                <span class="col-sm-12 nodata-wrapper"> No Data Available </span>
            </div>
        </div>
    </div>

</section>
<section *ngIf="!gridPage">
    <app-export-upload-copy (uploadBack)=backClicked($event) [templateData]="templateData"
        [copyDataClicked]="copyDataClicked" [uploadClicked]="uploadClicked">{{templateData}}</app-export-upload-copy>
</section>
