
<search-panel *ngIf="templateData && templateData.template.toLowerCase()=='searchpanel'" [templateData] = "templateData" ></search-panel>
<grid-container *ngIf=" templateData && templateData.template.toLowerCase()=='grid' "  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></grid-container>
<readonlygrid-container *ngIf="templateData &&  templateData.template.toLowerCase()=='readonlygrid'" [templateData] = "templateData" ></readonlygrid-container>
<hgrid-container *ngIf=" templateData && templateData.template.toLowerCase()=='hgrid'" [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></hgrid-container>
<!-- <Casetypetemplate-container *ngIf="templateData && templateData.template.toLowerCase()=='casetypetemplate'" [templateData] = "templateData" ></Casetypetemplate-container> -->
<settingstemplate-container *ngIf="templateData && templateData.template.toLowerCase()=='templatesettings'" [templateData] = "templateData" ></settingstemplate-container>
<materialtemplate-container *ngIf="templateData && templateData.template.toLowerCase()=='templatematerial'" [templateData] = "templateData" ></materialtemplate-container>
<resultnested  *ngIf=" templateData && templateData.template.toLowerCase()=='ngrid'"  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></resultnested>
<app-resultsection *ngIf=" templateData && templateData.template.toLowerCase()=='resultsection'" [templateData] = "templateData" [templateChangedTime] = "templateChangedTime" ></app-resultsection>
<customrules-grid *ngIf=" templateData && templateData.template.toLowerCase()=='customrulesgrid' "  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></customrules-grid>
<app-interface  *ngIf=" templateData && templateData.template.toLowerCase()=='interface' "  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-interface>
<app-desktoptemplate  *ngIf=" templateData && templateData.template.toLowerCase()=='vdtemplate' "  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-desktoptemplate>
<customreport-grid *ngIf=" templateData && templateData.template.toLowerCase()=='customreportgrid' "  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></customreport-grid>
<app-vitalbridge  *ngIf=" templateData && templateData.template.toLowerCase()=='vtbridge' "   [mainCard]="mainCard"  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-vitalbridge>
<app-vdinformation  *ngIf=" templateData && templateData.template.toLowerCase()=='vdinformation' "   [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-vdinformation>
<app-vdgenric  *ngIf=" templateData && templateData.template.toLowerCase()=='vdgenric' "  [mainCard]="mainCard"  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-vdgenric>
<app-personalinfo  *ngIf=" templateData && templateData.template.toLowerCase()=='userpersonalinfo' "   [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-personalinfo>
<app-licensureinfo  *ngIf=" templateData && templateData.template.toLowerCase()=='licensureinfo' "   [templateData] = "templateData" [templateChangedTime] = "templateChangedTime" [mainCard]="mainCard"></app-licensureinfo>
<app-vdtemplateupload  *ngIf=" templateData && templateData.template.toLowerCase()=='vdtemplateupload' "   [templateData] = "templateData" [templateChangedTime] = "templateChangedTime" [mainCard]="mainCard"></app-vdtemplateupload>
<app-pathology-sign-upload  *ngIf="templateData && templateData.template.toLowerCase()=='pathologysignupload'"   [templateData] = "templateData" [templateChangedTime] = "templateChangedTime" [mainCard]="mainCard"></app-pathology-sign-upload>
<app-accountlogoupload  *ngIf=" templateData && templateData.template.toLowerCase()=='accountlogoupload' "   [templateData] = "templateData" [templateChangedTime] = "templateChangedTime" [mainCard]="mainCard"></app-accountlogoupload>
<app-gridguidcontainer *ngIf=" templateData && templateData.template.toLowerCase()=='gridguid' "  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-gridguidcontainer>
<managescheduledreport-grid *ngIf=" templateData && templateData.template.toLowerCase()=='managescheduledreportgrid' "  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></managescheduledreport-grid>
<app-report-builder *ngIf=" templateData && templateData.template.toLowerCase()=='app-report-builder' "  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-report-builder>
<app-allusers  *ngIf=" templateData && templateData.template.toLowerCase()=='allusers' "  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime" ></app-allusers>
<app-cases  *ngIf=" templateData && templateData.template.toLowerCase()=='cases' "  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime" ></app-cases>


<app-accounts-association *ngIf=" templateData && templateData.template.toLowerCase()=='app-accounts-association'"  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-accounts-association>
<!-- <app-new-accounts-association *ngIf=" templateData && templateData.template.toLowerCase()=='app-accounts-association' "  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-new-accounts-association> -->


<app-setup-new-location *ngIf=" templateData && templateData.template.toLowerCase()=='locgrid' "  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-setup-new-location>
<app-case-comments-component *ngIf=" templateData && templateData.template.toLowerCase()=='app-case-comments-component' "  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-case-comments-component>
<manage-gross-templates *ngIf=" templateData && templateData.template.toLowerCase()=='grosstemplates' "  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></manage-gross-templates>
<app-MigrateUsers *ngIf=" templateData && templateData.template.toLowerCase()=='migrateusers' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-MigrateUsers>
<app-disclaimers *ngIf=" templateData && templateData.template.toLowerCase()=='app-disclaimers' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime" [orgLevel] = "mainCard.Organization ? true : false" ></app-disclaimers>
<!-- <app-case-profiles *ngIf=" templateData && templateData.template.toLowerCase()=='app-case-profiles' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-case-profiles> -->
<app-diagnosis-templates *ngIf=" templateData && templateData.template.toLowerCase()=='diagnosistemplates' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-diagnosis-templates>
<app-va-entity-attributes *ngIf=" templateData && templateData.template.toLowerCase()=='app-va-entity-attributes' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-va-entity-attributes>
<!-- <app-bulk-upload-tests *ngIf=" templateData && templateData.template.toLowerCase()=='app-bulk-upload-tests' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-bulk-upload-tests> -->
<app-manage-sites *ngIf=" templateData && templateData.template.toLowerCase()=='app-manage-sites' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-sites>
<app-grossing-attributes *ngIf=" templateData && templateData.template.toLowerCase()=='app-grossing-attributes' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-grossing-attributes>
<app-adequacy-codes *ngIf=" templateData && templateData.template.toLowerCase()=='app-adequacy-codes' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-adequacy-codes>
<app-manage-collection-methods *ngIf=" templateData && templateData.template.toLowerCase()=='app-manage-collection-methods' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-collection-methods>
<app-organization-attributes *ngIf=" templateData && templateData.template.toLowerCase()=='app-organization-attributes' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-organization-attributes>
<app-casetype-flags *ngIf=" templateData && templateData.template.toLowerCase()=='app-casetype-flags' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-casetype-flags>
<!-- <app-quality-attributes *ngIf=" templateData && templateData.template.toLowerCase()=='qagrid' && templateData.menuURL.toString().toLowerCase() == 'quality attributes'" [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-quality-attributes> -->
<!-- <app-quality-attributes *ngIf=" templateData && templateData.template.toLowerCase()=='qagrid' && templateData.menuURL.toString().toLowerCase() == 'flags'" [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-quality-attributes> -->
<app-manage-rule-outs *ngIf="templateData && templateData.template.toLowerCase()=='newmanageruleouts' && vitalHttpServices.deploymentKey.toLowerCase().includes('thx')"  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-rule-outs>
<app-new-manage-rule-outs *ngIf=" templateData && templateData.template.toLowerCase()=='newmanageruleouts' && !vitalHttpServices.deploymentKey.toLowerCase().includes('thx')"  [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-new-manage-rule-outs>
<app-default-stains *ngIf=" templateData && templateData.template.toLowerCase()=='app-default-stains' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-default-stains>
<app-casetype-details *ngIf=" templateData && templateData.template.toLowerCase()=='app-casetype-details' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-casetype-details>
<app-revise-templates *ngIf=" templateData && templateData.template.toLowerCase()=='app-revise-templates' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-revise-templates>
<app-revise-notes-templates *ngIf=" templateData && templateData.template.toLowerCase()=='app-revise-notes-templates' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-revise-notes-templates>
<app-user-defined-fields *ngIf=" templateData && templateData.template.toLowerCase()=='app-user-defined-fields' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-user-defined-fields>
<app-microscopic-codes *ngIf=" templateData && templateData.template.toLowerCase()=='app-microscopic-codes' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-microscopic-codes>
<app-manage-icd9codes *ngIf=" templateData && templateData.template.toLowerCase()=='app-manage-icd9codes' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-icd9codes>
<app-casetype-attributes *ngIf=" templateData && templateData.template.toLowerCase()=='app-casetype-attributes' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-casetype-attributes>
<app-mandatory-fields *ngIf=" templateData && templateData.template.toLowerCase()=='app-mandatory-fields' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-mandatory-fields>
<app-requisition-profiles *ngIf="templateData && templateData.template.toLowerCase()=='app-requisition-profiles' " [templateData] = "templateData"  [templateChangedTime] = "templateChangedTime"></app-requisition-profiles>
<app-manage-screening-types *ngIf=" templateData && templateData.template.toLowerCase()=='app-manage-screening-types' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-screening-types>
<!-- <app-organization-tags *ngIf=" templateData && templateData.template.toLowerCase()=='app-organization-tags'" [templateData] = "templateData"[templateChangedTime] = "templateChangedTime"></app-organization-tags> -->
<app-organization-case-tags *ngIf=" templateData && templateData.template.toLowerCase()=='app-organization-case-tags' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-organization-case-tags>
<app-screening-comments *ngIf=" templateData && templateData.template.toLowerCase()=='app-screening-comments' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-screening-comments>
<app-manage-rejection-comments *ngIf=" templateData && templateData.template.toLowerCase()=='app-manage-rejection-comments' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-rejection-comments>
<app-manage-tab-sequence *ngIf=" templateData && templateData.template.toLowerCase()=='app-manage-tab-sequence' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-tab-sequence>
<app-manage-password-policy *ngIf=" templateData && templateData.template.toLowerCase()=='app-manage-password-policy' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-password-policy>
<app-manage-reflex-rules *ngIf=" templateData && templateData.template.toLowerCase()=='app-manage-reflex-rules' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-reflex-rules>
<!-- <app-manage-reflex-rules></app-manage-reflex-rules> -->
<app-manage-sequence-wheel *ngIf=" templateData && templateData.template.toLowerCase()=='app-manage-sequence-wheel' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-sequence-wheel>
<app-new-diagnosis-templates *ngIf=" templateData && templateData.template.toLowerCase()=='app-new-diagnosis-templates' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-new-diagnosis-templates>
<app-manage-auto-assignment-rules *ngIf=" templateData && templateData.template.toLowerCase()=='app-manage-auto-assignment-rules' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-auto-assignment-rules>
<app-new-quality-attributes *ngIf=" templateData && templateData.template.toLowerCase()=='qagrid' && templateData.menuURL.toString().toLowerCase() == 'quality attributes'" [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-new-quality-attributes>
<app-new-flags *ngIf=" templateData && templateData.template.toLowerCase()=='qagrid' && templateData.menuURL.toString().toLowerCase() == 'flags'" [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-new-flags>
<app-new-bulk-upload-tests *ngIf="templateData && templateData.template.toLowerCase()=='app-bulk-upload-tests' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-new-bulk-upload-tests>
<app-new-case-profiles *ngIf=" templateData && templateData.template.toLowerCase()=='app-case-profiles' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-new-case-profiles>
<app-manage-custom-listing *ngIf=" templateData && templateData.template.toLowerCase()=='app-manage-custom-listing' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-custom-listing>
<app-manage-pathologist-privilege *ngIf=" templateData && templateData.template.toLowerCase()=='app-manage-pathologist-privilege' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-pathologist-privilege>
<app-deletion-templates *ngIf=" templateData && templateData.template.toLowerCase()=='app-deletion-templates' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-deletion-templates>
<app-manage-categories *ngIf=" templateData && templateData.template.toLowerCase()=='app-manage-categories' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-categories>
<app-diagnosis-references *ngIf=" templateData && templateData.template.toLowerCase()=='app-diagnosis-references' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-diagnosis-references>
<app-custom-abbreviations *ngIf=" templateData && templateData.template.toLowerCase()=='app-custom-abbreviations' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-custom-abbreviations>
<app-sequester-templates *ngIf=" templateData && templateData.template.toLowerCase()=='app-sequester-templates' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-sequester-templates>
<app-corelation-templates *ngIf=" templateData && templateData.template.toLowerCase()=='app-corelation-templates' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-corelation-templates>
<app-tubetype-templates *ngIf=" templateData && templateData.template.toLowerCase()=='app-tubetype-templates' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-tubetype-templates>
<app-sendout-reasons *ngIf=" templateData && templateData.template.toLowerCase()=='app-sendout-reasons' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-sendout-reasons>
<app-nomenclature-templates *ngIf=" templateData && templateData.template.toLowerCase()=='app-nomenclature-templates' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-nomenclature-templates>
<app-popup *ngIf=" templateData && templateData.template.toLowerCase()=='app-popup' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-popup>
<app-workgroup *ngIf="templateData && templateData.template.toLowerCase()=='app-workgroup'" [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-workgroup>
<app-newcasetypetemplates *ngIf="templateData && templateData.template.toLowerCase()=='casetypetemplate'" [templateData] = "templateData" ></app-newcasetypetemplates>
<app-manage-report-signatories *ngIf=" templateData && templateData.template.toLowerCase()=='app-manage-report-signatories' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-report-signatories>
<app-manage-payers *ngIf=" templateData && templateData.template.toLowerCase()=='app-manage-payers' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-payers>
<app-manage-payers-lookup *ngIf=" templateData && templateData.template.toLowerCase()=='app-manage-payers' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-payers-lookup>
<app-manage-medications *ngIf=" templateData && templateData.template.toLowerCase()=='app-manage-medications' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-manage-medications>
<app-observation-and-quantitation-template *ngIf=" templateData && templateData.template.toLowerCase()=='app-observation-and-quantitation-template'" [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-observation-and-quantitation-template>
<app-clinical-information *ngIf=" templateData && templateData.template.toLowerCase()=='app-clinical-information' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-clinical-information>
<app-cassette-templates *ngIf=" templateData && templateData.template.toLowerCase()=='app-cassette-templates' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-cassette-templates>
<app-statistic-master-data *ngIf=" templateData && templateData.template.toLowerCase()=='app-statistic-master-data' " [templateData] = "templateData" [templateChangedTime] = "templateChangedTime"></app-statistic-master-data>


