import { Component, EventEmitter, Output } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService } from 'src/app/core/services/commonservices';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';

@Component({
  selector: 'app-acs-serach-optimization',
  templateUrl: './acs-serach-optimization.component.html',
  styleUrls: ['./acs-serach-optimization.component.scss']
})
export class ACSSerachOptimizationComponent {

  @Output() close = new EventEmitter<void>();
  public DeploymentList: any = [];
  selectedDeployment: string;
  copyOrganizations: any;
  isCustomer: any;
  isSetUpPending: any;
  isTestOrganization: any;
  organizationId: any;
  organizationInput: string = '';
  searchResult: any[];
  oldObj: any = {};
  disableOrg: boolean = true;
  showFlags: boolean = false;
  auditableColumns: any;
  activityEntity: any;

  constructor( public VitalHttpServices: VitalHttpServices, public commonService: CommonService,
    private ngxService: NgxUiLoaderService, public activityService: ActivityTrackerService, private _snackbar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.DeploymentList = this.VitalHttpServices.DeploymentKeys;
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': "Group Details", 'context': [{ 'key': 'parentMenu', 'value': "Location" }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
    this.getAuditableDetails("Group Details");
  }

  getAuditableDetails(location: any) {
    this.VitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      if (res.content) {
        this.auditableColumns = JSON.parse(res.content.JsonData);
      }
    }, error => {
      console.error(error);
    })
  }

  SetConfigClose() {
    this.close.emit();
    this.selectedDeployment = ''
    this.disableOrg = true;
    this.organizationInput = ''
    this.showFlags = false;
    this.searchResult = [];
  }

  selectDeployment(e, depKey) {
    if (e.source.selected) {
      this.selectedDeployment = depKey
      this.disableOrg = false;
      this.organizationInput = ''
      this.showFlags = false;
      this.searchResult = [];
      this.getOrganizations(depKey);
    }
  }

  getOrganizations(depkey) {
    let dbName = depkey;
    let queryVariable = {};
    let query = this.VitalHttpServices.GetQuery("getalllaboratories");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.VitalHttpServices.GetData(queryResult, dbName).subscribe(data => {
      if (!data.errors) {
        this.ngxService.stop();
        if (data) {
          if (data.data.submenuData.length > 0) {
            this.copyOrganizations = data.data.submenuData
          }
        }
      }
    }, error => {
      console.error(error);
    });
    this.ngxService.stop();
  }

  filterOrganizations(value: string) {
    this.organizationInput = value?.trim();
    this.searchResult = [];
    if (!value) {
      this.searchResult = this.copyOrganizations?.slice(0, 25);
      return;
    }
    const idValidate = /^[0-9]*$/;
    const lowerCaseValue = value.toLowerCase();

    const filterCondition = idValidate?.test(value)
      ? (series) => series.organizationid && series.organizationid.toString().toLowerCase().includes(lowerCaseValue)
      : (series) => series.organizationname && series.organizationname.toString().toLowerCase().includes(lowerCaseValue);

    this.searchResult = this.copyOrganizations?.filter(filterCondition).slice(0, 25);
  }

  selectedOrganization(event, data) {
    if (event.source.selected) {
      this.oldObj = {
        orgid : data.organizationid,
        organizationname : data.organizationname,
        iscustomer : data.iscustomer,
        isorgsetuppending : data.isorgsetuppending,
        istestorganization : data.istestorganization,
      }
      this.organizationId = data.organizationid
      this.organizationInput = data.organizationname;
      this.isCustomer = data.iscustomer;
      this.isSetUpPending = data.isorgsetuppending;
      this.isTestOrganization = data.istestorganization;
      this.showFlags = true;
    }
  }

  onCheckChanges(value ,flagName) {
    flagName == 'isCustomer' ? this.isCustomer = value : flagName == 'isSetUpPending' ? this.isSetUpPending = value : this.isTestOrganization = value
  }

  SaveUpdatedFlags(){
    let obj = {
      orgid : this.organizationId,
      organizationname : this.organizationInput,
      iscustomer : this.isCustomer,
      isorgsetuppending : this.isSetUpPending,
      istestorganization : this.isTestOrganization,
      modifiedby : sessionStorage.getItem("Userid")
    }
    sessionStorage.setItem('DeploymentKey', this.selectedDeployment)
    this.VitalHttpServices.EditOrgACSSearch([obj], this.selectedDeployment).subscribe(
      (result) => {
        if (!result.errors) {
          if (result.status.toLowerCase() == 'success') {
            if(result.content[0].notes == 'Updated Successfully'){
              this._snackbar.open("Updated Successfully", "Close");
              this.SetConfigClose();       
             }
             this.activityEntity['entityid'] = obj.orgid;
             this.activityService.setActivitySession(this.activityEntity);
             let info = `Modified "${obj.organizationname}" for Search Optimization`
              this.commonService.createActivityObject(obj.orgid, obj.organizationname, 'Group Details', 'Edit', obj, this.oldObj, this.commonService.generateGuid(), this.auditableColumns, '', '', info);
              sessionStorage.setItem('DeploymentKey', '')
            }
          else {
            this._snackbar.open("Something went wrong!", "Close");
          }
        } else {
          this._snackbar.open("Please provide valid data", 'Failed');
        }
        this.ngxService.stop();
      },
      (error) => {
        this._snackbar.open("An error occurred while processing your request", 'Failed');
        console.error(error);
        this.ngxService.stop();
      }
    );
  }
  clearOrganization() {
   this.organizationInput = ''
   this.showFlags = false;
  }

}
