import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DataShareService } from 'src/app/core/services/datashare.service';
import { VitalAdminTabService } from '../../tab.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/services/commonservices';
import { SubMenuCardModel } from '../../DbModel/SubMenuCard/Submenucardmodel';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import * as XLSX from 'xlsx';
import * as wjcCore from '@grapecity/wijmo';
import * as wjCore from '@grapecity/wijmo';
import { CollectionView } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import * as wjGrid from '@grapecity/wijmo.grid';
import { Selector } from '@grapecity/wijmo.grid.selector';
import { ThemePalette } from '@angular/material/core';


interface Submenu {
  DisplayName: string;
  TableName: string;
  ConfigJson: string;
}

export interface Task {
  color: ThemePalette;
}

@Component({
  selector: 'app-generic-bulk-upload',
  templateUrl: './generic-bulk-upload.component.html',
  styleUrls: ['./generic-bulk-upload.component.scss']
})
export class GenericBulkUploadComponent {

  task: Task = {
    color: 'primary',
  };
  @Output() close = new EventEmitter<void>();
  @Input() selecteddefaultdeploymentkey: any;
  SubMenuCardModel: SubMenuCardModel;
  DeploymentKeys: any;
  FormDataEntry: boolean = false;
  frm_GenericBulkUpload = this._fb.group({
    frmDeploymentKey: ['', Validators.required],
    SheetName: ['', Validators.required],
  });
  selectedData: boolean;
  deploymentKey: any;
  disableDeployment: boolean = false;
  disableTablelist:boolean=true;
  uploadClicked:boolean=false;
  filename: any;
  gridWidth: number = 0;
  workBook:any;
  sheetsToSelect: any[];
  updateFlag: boolean;
  @ViewChild('fileDropRef', { static: false }) fileDropRef: ElementRef;
  configuredData: any;
  allFields: any = [{}];
  submenuData: Submenu[] = [];
  excelDataArray: any = [];
  sheetHeader = [];
  excelDownloadArray: any[];
  gridArray = [];
  gridData: wjcCore.CollectionView<any>;
  gridDisplay:boolean =false;
  postUpload:boolean=false;
  selector: Selector;
  addNew: boolean;
  bulkUpdate: boolean;
  allowInsertValue: boolean=false;
  sheetInfo: any;
  auditUserId: string;
  successCount: number;
  failureCount: number;
  operationName: string;
  highlightNotes = true;
  headerPassValue: any[];
  failureMessage: boolean=false;
  message: any;


  constructor(private ngxService: NgxUiLoaderService, private datashare: DataShareService,
    private _fb: FormBuilder, public tabService: VitalAdminTabService, public VitalHttpServices: VitalHttpServices,
    private _snackbar: MatSnackBar, private dialog: MatDialog, public commonService: CommonService
   ) {
    this.SubMenuCardModel = new SubMenuCardModel(
      commonService,
      VitalHttpServices,
      datashare
    );
    this.DeploymentKeys = this.VitalHttpServices.DeploymentKeys;
  }

  ngOnInit() {
    this.selectedData = true
    this.frm_GenericBulkUpload.patchValue({
      frmDeploymentKey: '',
      SheetName: 'Select'
    });
    this.getDisplayName();
    if(this.selecteddefaultdeploymentkey && this.selecteddefaultdeploymentkey.toString().toLowerCase() != 'all'){
      this.onChangeDep(this.selecteddefaultdeploymentkey)
    }

  }

  async onChangeDep(deviceValue) {
    this.selectedData = false;
    this.uploadClicked = false;
    this.disableTablelist=false;
    this.disableDeployment=false;
    this.allowInsertValue=false;
    this.postUpload=false;
    this.excelDataArray=[];
    this.failureMessage=false;
    this.gridWidth = 0;
     this.gridData = new CollectionView([]);
    this.postUpload = false;
    this.gridDisplay=false;
    this.addNew=false;
    this.bulkUpdate=false;
    this.deploymentKey = deviceValue;
    this.frm_GenericBulkUpload= this._fb.group({
      frmDeploymentKey: [ this.deploymentKey],
      SheetName: ['select'],
    });
  }

  async onChangeTable(deviceValue){
    this.filename=deviceValue.DisplayName;
    this.sheetInfo=deviceValue;
    this.disableDeployment=true;
    this.allowInsertValue=false;
    this.postUpload=false;
    this.excelDataArray=[];
    this.failureMessage=false;
    this.gridWidth = 0;
     this.gridData = new CollectionView([]);
    this.postUpload = false;
    this.gridDisplay=false;
    this.getSheetFileds(this.deploymentKey,deviceValue.TableName,deviceValue)

  }

  onFileDropped($event) {
    if(this.addNew || this.bulkUpdate){
      this.onFileChange($event);
    }
  }
  //#region Method to hit once a file is dragged to or uploaded
  onFileChange(ev) {
    this.uploadClicked = true;
    this.workBook = {};
    this.sheetsToSelect = [];
    const reader = new FileReader();
    let file = ev.target ? ev.target.files[0] : ev[0];
    let filename = file.name;
    let splitarry = filename.split('.');
    if (splitarry[1].toUpperCase() != 'XLSX' && splitarry[1].toUpperCase() != 'XLS') {
      this._snackbar.open('Please provide valid data', 'Close');
    }
    else {
      reader.onload = (event) => {
        const data = reader.result;
        this.workBook = XLSX.read(data, { type: 'binary' });
        this.sheetsToSelect = this.workBook.SheetNames;
        if (this.sheetsToSelect) {
          if (this.sheetsToSelect.length > 1) {
             this.convertToJson(this.sheetsToSelect[0])

          }
          else {
            this.convertToJson(this.sheetsToSelect[0])
          }
        }
      };
      reader.readAsBinaryString(file);
    }
    this.fileDropRef.nativeElement.value = "";
  }
  //#endregion

  downloadTemplate() {
    // Assuming this.allFields and this.sheetInfo are defined as per your input
    const identityColumn = this.sheetInfo?.ConfigJson?.identitycolumn;

    // Remove the identity column value from all fields
    if (!this.allowInsertValue) {
      this.allFields.forEach(field => {
        delete field[identityColumn];
      });
    }

    // Create the Excel file data
    if (this.allowInsertValue || this.bulkUpdate) {
      const valuesToAdd = { [identityColumn]: '' };
      this.allFields = [{ ...valuesToAdd, ...this.allFields[0] }, ...this.allFields.slice(1)];
    }

    // Prepare filename and excel data
    const filename = `${this.filename}AllFields_.xlsx`;
    const excel = this.allFields; // This will be either modified or unmodified based on allowInsertValue
    const ws = XLSX.utils.json_to_sheet(excel);
    const wb = XLSX.utils.book_new();
    const sheetName = this.filename;

    // Append the sheet and write the file
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, filename);

  }

   getSheetFileds(key, name, sheetValue) {
    this.allFields=[];
    let queryVariable = { tablename: name, identity: "false", nullable: "false" };
    let query = this.SubMenuCardModel.GetQuery("getTableColumns");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult, key).subscribe(data => {
        if (!data.errors) {
            if (data && data.data && Array.isArray(data.data?.allFields)) {
                // Initialize allFields if not already
                if (!this.allFields || !Array.isArray(this.allFields)) {
                    this.allFields = []; // Initialize as an empty array
                }
                let allFields = {};
                for (let i = 0; i < data.data.allFields.length; i++) {
                    allFields[data.data.allFields[i]['Column']] = '';
                }
                // If allFields should only contain one object, ensure it is set correctly
                const identityColumn = sheetValue.ConfigJson?.identitycolumn;
                if (!allFields.hasOwnProperty('identityColumn')) {
                  const valuesToAdd = { [identityColumn]: '' };
                 // allFields[identityColumn] = '';
                  allFields = { ...valuesToAdd, ...allFields };
                   // Add 'id' if it doesn't exist
                 }
                this.allFields[0] = allFields;
                if(sheetValue.ConfigJson?.guid){
                  const guid = sheetValue.ConfigJson?.guid;
                  delete this.allFields[0][guid];
                }
                delete this.allFields[0][Object.keys(this.allFields[0]).find(key =>['modifiedon','modifieddate','lastmodifieddate','createddate']?.includes( key?.toString().toLowerCase()))];
                let result = this.VitalHttpServices.auditUsers;
                if (result) { // Check if result is not null or undefined
                  for (let [key, value] of Object.entries(result)) {
                    if (key.toLowerCase() === this.deploymentKey.toLowerCase()) {
                       this.auditUserId = value.toString();
                    }
                  }
                }
            }
        }
    }, error => {
        console.error(error);
    });
   }


  closeModal() {
    this.FormDataEntry = false;
    this.disableTablelist=true;
    this.gridWidth = 0;
    this.frm_GenericBulkUpload.reset();
    this.gridData = new CollectionView([]);
    this.gridDisplay=false;
    this.postUpload = false;
    this.disableDeployment=false;
    this.uploadClicked=false;
    this.addNew=false;
    this.bulkUpdate=false;
    this.allowInsertValue=false;
    this.postUpload=false;
    this.excelDataArray=[];
    this.failureMessage=false;
    this.close.emit();
  }

  async getDisplayName(){
    let queryVariable = { };
    let query = this.SubMenuCardModel.GetQuery("getGenericBulkUpload");
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.VitalHttpServices.GetData(queryResult,'configdb').subscribe(data => {
      if (!data.errors) {
        if (data) {
          this.submenuData = data.data.submenuData
          .filter(item => item.Status) // Filter for items with Status true
          .map(item => ({
              DisplayName: item.DisplayName,
              TableName: item.TableName,
              ConfigJson: JSON.parse(item.ConfigJson)
          }));
        }
      }
    }, error => {
      console.error(error);
    });
  }


  //#region Method to validate sheet data.
  convertToJson(sheetname) {
    var worksheet;
    this.excelDataArray = [];
    let tempExcelArr = [];
    let primary = {};
    let tempArray = [];
    this.sheetHeader = [];
    let headerValue=[];
    let temp = [];
    let i = 1;

    // Find the worksheet by name
    this.workBook.SheetNames.find(e => {
      if (e.toLowerCase() === sheetname.toLowerCase()) {
        worksheet = this.workBook.Sheets[e];
      }
    });

    // Convert the worksheet to JSON
    tempExcelArr = XLSX.utils.sheet_to_json(worksheet, { defval: null });

    // Check if the array is empty
    if (tempExcelArr.length === 0) {
      this._snackbar.open("The uploaded sheet does not contain any data", "Close");
      return [];
    }

    // Construct the primary array and filter out empty values
    tempExcelArr.forEach(r => {
      for (let [key, value] of Object.entries(r)) {
          primary[key] = value;
      }
      if (Object.keys(primary).length > 0) { // Only push if there are valid key-value pairs
        tempArray.push(primary);
      }
      primary = {}; // Reset primary for the next row
    });

    // Check if tempArray is empty after filtering
    if (tempArray.length === 0) {
      this._snackbar.open("The uploaded sheet does not contain any data", "Close");
      return [];
    }
    // Process sheet headers
    temp = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
     headerValue=XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    this.headerPassValue=headerValue[0];
    temp[0].push("ResultMessage");
    temp[0].reverse();
    this.sheetHeader = temp[0];

    // Clean header keys
    this.sheetHeader = this.sheetHeader.map(header => header ? header.replace(/\s/g, "") : header);

    this.sheetHeader.reverse();

    // Convert keys to lowercase and assign to excelDataArray
    this.excelDataArray = this.convertObjKeysToLower(tempArray);
    this.excelDownloadArray = [...this.excelDataArray];
    // Add serial numbers and If there is created by or Modified by in the all data
    if (this.bulkUpdate) {
      let modifiedby=this.sheetInfo.ConfigJson?.modifiedby;
      if(modifiedby){
      this.excelDataArray.forEach(d => {
        Object.assign(d, { slno: i, modifiedby: this.auditUserId });
        i++;
      });
     }
     else{
      this.excelDataArray.forEach(d => {
        Object.assign(d, { slno: i });
        i++;
      });
     }
    }
    else{
      let createdby=this.sheetInfo.ConfigJson?.createdby;
      if(createdby){
      this.excelDataArray.forEach(d => {
        Object.assign(d, { slno: i, createdby: this.auditUserId });
        i++;
      });}
      else{
        this.excelDataArray.forEach(d => {
          Object.assign(d, { slno: i });
          i++;
        });
      }
    }



    this.excelGridData(this.excelDataArray);
  }

   //#region Convert Obj to lower
   convertObjKeysToLower(inputArr) {
    let array = [];
    for (let i = 0; i < inputArr.length; i++) {
      var key, keys = Object.keys(inputArr[i]);
      var n = keys.length;
      var newobj = {}
      while (n--) {
        key = keys[n];
        newobj[key.replace(/\s/g, "").toLowerCase()] = inputArr[i][key]
      }
      array.push(newobj)
    }
    return array;
  }
  //#endregion

  //#region Grid Data : uploaded data with Notes column
  excelGridData(data) {
    this.ngxService.stop();
    this.gridDisplay = true;
    this.gridArray = [];
    this.gridData =new CollectionView([]);
    let primary = {};
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          primary = {};
          for (let [key, value] of Object.entries(data[i])) {
            if((['isactive','isdefault'].includes(key.toString().toLowerCase()))){
              if(value === 0){
                value = '0'
              }else if(value === false){
                value = 'false'
              }
            }
            primary[key] = value;

          }
          this.gridArray.push(primary);
        }
      }
      this.gridData =new CollectionView(this.gridArray);
      this.gridWidth = (170 * this.sheetHeader.length) + 37;
      if (this.gridWidth > 1300) {
        this.gridWidth = 1300;
      }
    }
    else {
      this.gridWidth = 0;
    }
  }
  //#endregion

  //message validation color
  formatItem(flexGird: wjGrid.FlexGrid, e: wjGrid.FormatItemEventArgs) {
    if (e.panel === flexGird.cells) {
        const value = e.panel.getCellData(e.row, e.col, false);
        if (value) {
            const valueStr = value.toString();
            const errorKeywords = ['exists', 'Mandatory'];
            const highValueKeywords = ['Valid', 'Successfully', 'success', 'Success', 'approval'];
            const warnValueKeywords = ['Ignored'];

            const hasError = errorKeywords.some(keyword => valueStr.includes(keyword));
            const hasHighValue = highValueKeywords.some(keyword => valueStr.includes(keyword));
            const hasWarnValue = warnValueKeywords.some(keyword => valueStr.includes(keyword));

            wjCore.toggleClass(e.cell, 'error-msg', hasError);
            wjCore.toggleClass(e.cell, 'high-value', hasHighValue);
            wjCore.toggleClass(e.cell, 'warn-value', hasWarnValue);
        }
    }
  }
 // Cancel
  backSelect(){
    this.FormDataEntry = false;
    this.disableTablelist=true;
    this.gridWidth = 0;
    this.gridData = new CollectionView([]);
    this.gridDisplay=false;
    this.postUpload = false;
    this.disableDeployment=false;
    this.uploadClicked=false;
    this.addNew=false;
    this.bulkUpdate=false;
    this.allowInsertValue=false;
    this.postUpload=false;
    this.excelDataArray=[];
    this.failureMessage=false;
    this.frm_GenericBulkUpload= this._fb.group({
      frmDeploymentKey: [ this.deploymentKey],
      SheetName: ['select'],
    });

  }

   //#region Tooltip for Grid
   initGrid(grid, hitCount?) {
    if (hitCount) {
      if (!this.postUpload) {
        this.selector = new Selector(grid, {
          itemChecked: () => {
            this.selectedData = grid.rows.filter(r => r.isSelected);
          }
        });
      }
    }
    const tt = new wjcCore.Tooltip();
    grid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell) {
        return;
      }
      if (s.getCellData(e.row, e.col) != null) {
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
      }
    });
    grid.groupHeaderFormat = '{name}: <b>{value}</b>'
  }
  //#endregion

  async GenericUpload(){
    this.postUpload = false;
    let inputParameters ={
      "Deployment":this.deploymentKey,
      "Identitycolumn":this.sheetInfo.ConfigJson?.identitycolumn,
      "SheetHeader":this.headerPassValue,
      "TableName":this.sheetInfo.TableName,
      "AllowInsertIdentity":this.allowInsertValue,
      "Operation":this.operationName,
      "data":this.excelDataArray,
      "ConfigJSON":this.sheetInfo.ConfigJson
    }
    this.ngxService.start("BulkUpload")
    await this.VitalHttpServices.genericBulkUploadandUpdate(inputParameters,this.deploymentKey).toPromise().then(response=> {
      if(response){
        this.ngxService.stop("BulkUpload");
      if (response.content && response.content.ValidationMessage === null) {
        let result = response.content;
        let excelValue = result.Results; // Adjusted to access results properly
        this.successCount = result.SuccessCount; // Correct property
        this.failureCount = result.Failiurecount; // Ensure this spelling is consistent
        if(this.failureCount==0){
          for (let i = 0; i < excelValue.length; i++) {
            this.excelDataArray[i]["ResultMessage"] = excelValue[i]["Status"];
         }
        }
        else{
          for (let i = 0; i < excelValue.length; i++) {
            if( excelValue[i]["Status"].toLowerCase()=='success'){
            this.excelDataArray[i]["ResultMessage"] = 'Valid'
            }
           else{
            this.excelDataArray[i]["ResultMessage"] = excelValue[i]["Status"];
           }
          }
        }
        this.postUpload = true;
        this.failureMessage=false;

        this._snackbar.open('Data uploaded successfully', 'Close');
        this.excelGridData(this.excelDataArray);
    } else if (response.content.ValidationMessage!==null) {
      this.failureMessage=true
      this.postUpload = true;
       this.message=response.content.ValidationMessage;
       this._snackbar.open('Bulk Upload failed', 'Close');
    }
     }
    else{
      this.ngxService.stop("BulkUpload")
      this._snackbar.open('Bulk Upload failed', 'Close');
    }

    });

  }

  //on select of the button
  onChangeRadioButton(value){
    if(value=='addNew'){
     this.addNew=true;
     this.bulkUpdate=false;
     this.uploadClicked=true;
     this.operationName ='addnew'
    }
    else{
       this.addNew=false;
      this.bulkUpdate=true;
      this.uploadClicked=true;
      this.allowInsertValue=false;
      this.operationName ='bulkupdate'
    }

  }
  onChangeIdentity(value){
    this.allowInsertValue=value
  }

  excelExport(){
    let filename = ""
    let excel = [{}];
    if(!this.addNew){
      filename = this.filename + 'UpdateSheet'+'.xlsx';
    }
    else{
      filename = this.filename + 'UploadSheet'+'.xlsx';
    }

    excel = this.excelDataArray
    var ws = XLSX.utils.json_to_sheet(excel);
    var wb = XLSX.utils.book_new();
    let sheetName = this.filename;
    XLSX.utils.book_append_sheet(wb, ws, "" + sheetName);
    XLSX.writeFile(wb, filename);
  }
}
