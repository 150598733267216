<div *ngIf="sourceApp != 'VitalDx'" class="modal-header admin-model-header mb-2 mt-3 listingModalHeader">
    <h3 class="modal-title w-100 admin-model-header-txt main-title">
        <strong class="header-View">Master Data Export</strong>
    </h3>
</div>
<div class="p-3">
    <form [formGroup]="masterDataFormGroup" autocomplete="off" class="col-sm-12 p-3 form-wrap-view">
        <div class="p-0 row col-sm-12 mt-3 mb-3 pl-3">
            <div class="viewAutoCompleteContainer row col-sm-12">
                <div class="p-0 m-0 col-sm-3" (click)="hideCaseTypeDD()">
                    <mat-form-field class="mr-3 w-100 viewAutoCompleteStatic">
                        <mat-label>Laboratory </mat-label>
                        <em class="fa fa-chevron-down chevron-align" (click)="trigger.openPanel()"></em>
                        <em *ngIf="masterDataFormGroup.value.frmLaboratory" 
                        class="fa fa-times chevron-align mr-2" (click)="clearInput($event)"></em>
                        <input class="input-lab" #labval trim type="text" maxlength="200" matInput formControlName="frmLaboratory"
                            [matAutocomplete]="auto" 
                            [matTooltip]="labval.value" 
                            #trigger="matAutocompleteTrigger"
                            (focus)="clickLaboratory();trigger.openPanel()">
                        <mat-autocomplete #auto="matAutocomplete" class="customCEOverlayPanelClass listingDropdownPanel">
                            <mat-option class="mat-opt-align" *ngFor="let autoCompleteOption of filteredLabList | async"
                                [value]="autoCompleteOption.accountName" [matTooltip]="autoCompleteOption.accountName"
                                (onSelectionChange)="onLabChange($event,autoCompleteOption)">
                                <div class="truncate-text"> {{ autoCompleteOption.accountName | shortentext: 30 }}
                                </div>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div *ngIf="isOFInfo" class="p-0 m-0 col-sm-3">
                    <mat-checkbox color='primary' formControlName='frmIsOF' (click)="hideCaseTypeDD()" [ngClass]="{'padding-top': sourceApp =='VitalDx'}"><b>Ordering Facility
                            Information</b></mat-checkbox>
                </div>
                <div *ngIf="isLab" class="p-0 m-0 col-sm-2">
                    <mat-checkbox color='primary' formControlName='frmIsLocation' (click)="hideCaseTypeDD()" [ngClass]="{'padding-top': sourceApp =='VitalDx'}"><b>Lab
                            Information</b></mat-checkbox>
                </div>
                <div *ngIf="isCaseType" class="p-0 m-0 col-sm-2 custom-dropdown">
                    <!-- Toggle checkbox -->
                    <div class="toggle-checkbox">
                        <mat-checkbox color='primary' (click)="toggleDropdown(true)" class="pr-2" formControlName="frmIsCaseType" [ngClass]="{'padding-top': sourceApp =='VitalDx'}"><b>Case Type
                            </b></mat-checkbox><span *ngIf="caseTypeCount !=''" (click)="toggleDropdown()"
                            class="casetype-count"><b>{{caseTypeCount}}</b></span>
                    </div>
    
                    <!-- Casetype Dropdown panel -->
                    <div class="dropdown-panel" *ngIf="isDropdownVisible">
                        <div class="row col-sm-12 Header-width">
                            <div class="col-sm-6 mb-0 mt-2 p-0 pl-2 pt-1"><b>Case Types</b></div>
                            <div class="col-sm-4 m-0 p-0">
                                <mat-checkbox color='primary' formControlName="frmIsAllCaseType" [ngClass]="{'padding-top': sourceApp =='VitalDx'}"
                                    (change)="selectAllCasetypes($event.checked)"><b style="color: white;">ALL</b></mat-checkbox>
                            </div>
                            <div class="col-sm-1 selectALL align-status p-0 m-0 pt-1">
                                <b><mat-icon class="p-0 pt-1 m-0" (click)="hideCaseTypeDD()" title="Close"
                                        aria-label="Close dialog">close</mat-icon></b>
                            </div>
                        </div>
    
                        <!-- Search input -->
                        <mat-form-field class="search-field">
                            <input matInput #searchbar placeholder="Search Case Types"
                                (keyup)="filterCasetype(searchbar.value)" />
                        </mat-form-field>
    
                        <!-- Case type options with checkboxes -->
                        <div class="panel-width" *ngFor="let caseType of searchResult">
                            <mat-checkbox [checked]="caseType.Selected" color='primary'
                                (change)="onCaseTypeChange($event, caseType)">
                                {{ caseType.DisplayName }}
                            </mat-checkbox>
                        </div>
                    </div>
                </div>
                <div class="pt-1 col-sm-2" (click)="hideCaseTypeDD()">
                    <button class="edit-page-button" [disabled]="!generateReportBtn" buttonclass="primary"
                        (click)="generateReport(masterDataFormGroup.value)">Generate Report</button>
                </div>
            </div>
        </div>
    </form>
</div>
<mat-spinner class="labadmin-spinner" *ngIf="sourceApp =='VitalDx' && VitalDxLoader"
        style="margin: 20vh 0vw 0vh 40vw;" [diameter]="50"> </mat-spinner>
<!-- Master Report data with download button -->
<div class="col-sm-12 mt-2 table-align" (click)="hideCaseTypeDD()" *ngIf="detailedReportGridData[0].SubReports?.length">
    <div class="col-sm-12 header d-flex py-1 mb-1">
        <div class="col-sm-12 header-wrap">
            <span [ngClass]="header.colWidth" *ngFor="let header of reportGridHeader">
                <div class="dataTable">{{header.DisplayName}}</div>
            </span>
        </div>
    </div>
    <div class="container-fluid col-sm-12 header-wrap p-0">
        <div class="col-sm-12 p-0">
            <div class="col-sm-12">
                <cdk-virtual-scroll-viewport itemSize="15" class="tableScroll-report cstcdkscroll">
                    <div class="col-sm-12 card-data mt-1 ml-0 mb-1" *cdkVirtualFor="let item of detailedReportGridData">
                        <div appTooltipEllipsis class="text-muted text-ellipsis dataTable widthClass" #isElipsed
                            *ngFor="let header of reportGridHeader" [ngClass]="header.colWidth">
                            <i *ngIf="header.DisplayName == ' '"
                                [ngClass]="(isMaximized==true) ? 'fa fa-minus': 'fa fa-plus'" aria-hidden="true"
                                (click)="isMaximized=!isMaximized"></i>
                            <span *ngIf="header.DisplayName != ' ' && header.DisplayName != 'Download'">{{
                                displayedColumns(item,header) | stripHtml }}</span>
                            <ng-template #showLoader>
                                <div class="loader"></div>
                            </ng-template>
                            <span *ngIf="header.DisplayName == 'Download'">
                                <ng-container *ngIf="item['Status'] == 'New'; else showIcon">
                                    <ng-container *ngTemplateOutlet="showLoader"></ng-container>
                                </ng-container>
                            </span>
                            <ng-template #showIcon>
                                <button
                                    *ngIf="!FilesNotFound && (item['Status'] == 'Partial' || item['Status'] == 'Success'); else showNoData"
                                    [disabled]="!downloadBtn" buttonclass="primary"
                                    class="edit-page-button edit-delete-btn-wrap p-0"
                                    (click)="downloadZipFile(item.GroupUniqueID)">
                                    <img src="../../../../assets/images/folder_zip.svg" alt="download" id="download"
                                        title="Download" height="auto"
                                        class="icon-size"> Download Report
                                </button>
                            </ng-template>
                            <ng-template #showNoData>
                                <ng-container
                                    *ngIf="FilesNotFound && (item['Status'] == 'Partial' || item['Status'] == 'Success')">
                                    <span><b>No data found</b></span>
                                </ng-container>
                            </ng-template>
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
                <!-- <div class="col-sm-12 nodata-header-wrap" *ngIf="detailedReportGridData?.length === 0">
                    <span class="col-sm-12 nodata-wrapper"> No Results </span>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div *ngIf="isMaximized && detailedReportGridData[0].SubReports?.length" class="col-sm-12 mt-2 table-align"
    (click)="hideCaseTypeDD()">
    <div class="col-sm-12 header d-flex py-1 mb-1">
        <div class="col-sm-12 header-wrap">
            <span [ngClass]="header.colWidth" *ngFor="let header of detailedReportGridHeader">
                <div class="dataTable">{{header.DisplayName}}</div>
            </span>
        </div>
    </div>
    <div class="container-fluid col-sm-12 header-wrap p-0">
        <div class="col-sm-12 p-0">
            <div class="col-sm-12">
                <cdk-virtual-scroll-viewport itemSize="15" class="tableScroll cstcdkscroll">
                    <div class="col-sm-12 card-data mt-1 ml-0  mb-1"
                        *cdkVirtualFor="let item of detailedReportGridData[0].SubReports">
                        <div appTooltipEllipsis class="text-muted text-ellipsis dataTable widthClass" #isElipsed
                            *ngFor="let header of detailedReportGridHeader" [ngClass]="header.colWidth">
                            {{ displayedColumns(item,header) | stripHtml }}
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
                <!-- <div class="col-sm-12 nodata-header-wrap" *ngIf="detailedReportGridData[0].SubReports?.length === 0">
                    <span class="col-sm-12 nodata-wrapper"> No Results </span>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div *ngIf="detailedReportGridData[0].SubReports?.length == 0" class="col-sm-12 mt-2 table-align"
    (click)="hideCaseTypeDD()">
    <div class="container-fluid col-sm-12 header-wrap p-0">
        <div class="col-sm-12 nodata-header-wrap">
            <span class="col-sm-12 nodata-wrapper"> No Results </span>
        </div>
    </div>
</div>