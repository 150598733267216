import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { VitalHttpServices } from 'src/app/core/services/VitalHttpServices';
import { CommonService } from 'src/app/core/services/commonservices';
import { DialogService } from 'src/app/core/services/dialog.service';
import * as XLSX from 'xlsx';
import { filter, map, take, tap } from 'rxjs/operators';
import { qualityAttributeAndFlags } from 'src/app/common/constants/qualityAttributeAndFlags';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { LabadminSessionService } from 'src/app/labadmin/services/labadmin-session.service';
import { Store } from '@ngrx/store';
import { LabadminService } from 'src/app/labadmin/labadmin.service';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { ConfirmLabadminComponent } from 'src/app/base/popup/confirm-labadmin/confirm-labadmin.component';
import { MatDialog } from '@angular/material/dialog';
import { sequence } from '@angular/animations';


@Component({
  selector: 'app-new-flags',
  templateUrl: './new-flags.component.html',
  styleUrls: ['./new-flags.component.scss']
})
export class NewFlagsComponent implements OnInit, AfterViewInit {
  userSessionDetails: any;
  sourceApp: string;
  dbName: string;
  previewopen: boolean;

  constructor(
    private _vitalHttpService: VitalHttpServices,
    private ngxService: NgxUiLoaderService,
    private dialog: DialogService,
    private commonService: CommonService,
    private _fb: FormBuilder,
    private _snackbar: MatSnackBar,
    private labAdminSessionService : LabadminSessionService,
    private store : Store<{breadcrum : []}>,
    private labAdminService : LabadminService,
    public activityService: ActivityTrackerService,
    private matdialog: MatDialog

  ) { }

  @Input() templateData: any;
  flagsList: any
  Orgtype: any
  destDeployment: any;
  listAll: 'Active' | 'All' = 'All';
  creationHeading: 'Create Flag' | 'Edit Flag' = 'Edit Flag'
  flagsSelectionModel = new SelectionModel(false, [])
  editedFlag: any
  sortedOrder: string
  isactive: Boolean = true
  gridPage: Boolean = true
  copyDataClicked: Boolean = true
  uploadClicked: Boolean = false;
  filteredFliagList$: any;
  searchText: string = "";
  flagList$: any;
  filtercondition = new BehaviorSubject({ status: 'all', searchText: '' });
  filtercondition$ = this.filtercondition.asObservable()
  uploadBtn:boolean=false
  createBtn:boolean=false
  editBtn:boolean=false
  exportBtn:boolean=false
  copyBtn:boolean=false
  scrollHistory : number = 0
  oldObject: { AttrOrder: any; AttrName: any; IsActive: any; };
  newObject: any;
  activityEntity: any;
  auditableColumns: any;
  @ViewChildren('scrollableDiv') maindiv
  sequenceEditMode: boolean = false
  previousSortedOrder: string;
  flagSortData : {currentData : any, previousData : any}
  tracking: string[] = [];
  isSortDataChanged: boolean = false;
  storesub:Subscription = new Subscription();
  deployment: any;
  orgId: any;
  showLabAdminLoader:boolean = false;
  showsequenceloader :boolean = false

  formAddFlag = this._fb.group({
    attrorder: 0,   
    attrid: 0,
    attrname: ["",Validators.required],
  })

  get getters() {
    return this.formAddFlag.controls
  }

  async ngOnInit() {
    this.destDeployment = sessionStorage.getItem('DeploymentKey').toUpperCase();
    this.dbName = this.destDeployment;
    let checkLaunchUrl = window.location.href.split('/#/')[1]
    if (checkLaunchUrl.toString().toLocaleLowerCase() != "home") {
      let _this = this;
      await _this.labAdminSessionService.getLabAdminSession.pipe(take(1)).subscribe(async session => {
        if (Object.keys(session["userDetails"]).length > 1) {
          _this.userSessionDetails = session["userDetails"];
          _this.sourceApp = 'VitalDx'
          let menuObj = {}
          await _this.store.select("breadcrum").pipe(take(1)).subscribe(async result => {
            menuObj = result[result.length - 1]
            await _this.commonService.getCommmonTemplateData(menuObj, _this.userSessionDetails);
            _this.templateData = _this.labAdminService.templateData;
            _this.dbName = _this.labAdminService.deploymentKey;
            sessionStorage.setItem('org_id', _this.labAdminService.organizationId);
            sessionStorage.setItem('deploymentKey', _this.dbName);
            _this.destDeployment = _this.dbName;
            _this.getFlagList(_this.templateData.Keyword, _this.templateData.secondarykeys.OrganizationId);
            _this.templateData['cardIdentifier'] = _this.userSessionDetails.userAccountIDs[0].accountId;
            _this.getAccountType();
            _this.orgId = _this.userSessionDetails?.organizationId;
            _this.GetButtonAccess(_this.labAdminService.subMenuPermissions);
            _this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': 'Lab' },{'key':'Lab Management','value':this.templateData.menuURL}] })
            _this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
            _this.getAuditableDetails(this.templateData.menuURL);
          });
        }
      })
    }
    else {
      this.destDeployment = sessionStorage.getItem('deploymentKey').toUpperCase()
      this.getFlagList(this.templateData.Keyword, this.templateData.secondarykeys.OrganizationId)
      this.GetButtondetails();
      this.getAccountType()
      this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL}] });
    this.activityService.getActivitySession.subscribe(res=> this.activityEntity = res);
    this.getAuditableDetails(this.templateData.menuURL);

    }
  }

   /* RBAC */
 GetButtondetails() {
  this.GetButtonAccess(this._vitalHttpService.SubmenuAction);
}

//#region get button access
GetButtonAccess(actionButtonDetails) {
  let seletedMenuPermissions
  if (this.sourceApp == 'VitalDx') {
    seletedMenuPermissions = actionButtonDetails.find(e => e.Htext  == this.labAdminService.templateData.menuURL)["ActionButton"]
  }
  else {
    seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
  }
  for (let i = 0; i < seletedMenuPermissions.length; i++) {
    switch (seletedMenuPermissions[i].Button) {
      case "Upload":
        this.uploadBtn = seletedMenuPermissions[i].IsPermitted === "true" ;
        break;
      case "Create":
        this.createBtn = seletedMenuPermissions[i].IsPermitted === "true" ;
        break;
      case "Edit":
        this.editBtn = seletedMenuPermissions[i].IsPermitted === "true" ;
        break;
      case "Export":
        this.exportBtn = seletedMenuPermissions[i].IsPermitted === "true" ;
        break;
      case "CopyToOrg":
        this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ;
        break;
      case "Copy":
        this.copyBtn = seletedMenuPermissions[i].IsPermitted === "true" ;
        break;
    }
    
    this.editBtn?this.formAddFlag.get('attrorder')?.enable():this.formAddFlag.get('attrorder')?.disable()
  }
}
//#endregion

  getAccountType() {
    let queryResult: any;
    let queryVariable;
    let query = this._vitalHttpService.GetQuery('getassociationtypeforaccid');
    let Qstr = "";
    Qstr += "accountid = \"" + this.templateData.cardIdentifier.toString() + "\"";
    queryVariable = { "accid": Qstr };
    queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this._vitalHttpService.GetData(queryResult, this.destDeployment).subscribe(res => {
      this.ngxService.stop();
      if (!res.errors) {
        if (res.data.vwGetAssociationtypeByOrgID_list.length > 0) {
          this.Orgtype = res.data.vwGetAssociationtypeByOrgID_list;
        }
      }
    }, error => {
      this.ngxService.stop();
      console.error(error);
    })
  }

  private getFlagList(keyword: string, orgId: string) {
    // this.ngxService.start()
  
    this.flagList$ = this._vitalHttpService.GetData({
      OperationName: null,
      Query: this.GetQuery(keyword),
      Variables: { orgid: `${orgId}` }
    },this.destDeployment).pipe(
      map(i => i.data.submenuData)
      //tap((res) => this.sortedOrder = this.checkOrder(res, 'attrname'))
    )
   
    this.filteredFliagList$ = combineLatest([this.flagList$, this.filtercondition$]).pipe(
      map(([data, filtered]: any) => {
        if (data && data.length) {
          let res = []
          res=filtered.searchText != '' ? data.filter(i =>this.stringNormalize( i.attrname).includes(this.stringNormalize(filtered.searchText))) : data
          let totalCount = res.length
          let activeCount = res.filter(i=>i.isactive.toLowerCase() == 'active').length

         if( filtered.status.toLowerCase() != 'all')
            res=filtered.status.toLowerCase() == 'active'?res.filter(i => i.isactive.toLowerCase() == 'active'):res.filter(i => i.isactive.toLowerCase() != 'active')

          data = { data: res, statusCount: { totalCount, activeCount }, isNoDataPresent: false }
          this.ngxService.stop()
          return data
        }
        else
        return { data: [], statusCount: { totalCount:0, activeCount:0 }, isNoDataPresent: true }

      }),
      tap((res : any) => {
        this.ngxService.stop()
        this.sortedOrder = this.checkOrder(res.data, 'attrname')
        this.previousSortedOrder = this.sortedOrder
        this.flagSortData = { currentData : res.data, previousData : res.data.map((j) => ({...j}))}
      })
    )
  }
  stringNormalize(str:any)
  {
    return str!=null?str.toLowerCase().replace(/\s/g, ""):'';
  }

  private GetQuery(keyword: string) {
    let query: string = '';
    let mainQueryIndex: number = this._vitalHttpService.mainQueryList.findIndex(
      mainQuery =>
        mainQuery.Keyword.toLowerCase() == keyword.toLowerCase()
    );
    if (mainQueryIndex > -1)
      query = this._vitalHttpService.mainQueryList[mainQueryIndex].Query;
    return query;
  }

  // saves the drag and droped data
  drop(event: CdkDragDrop<any>) {
    if (event.previousContainer === event.container && event.previousIndex != event.currentIndex) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.tracking.push('drop')
      this.sortedOrder = this.checkOrder(event.container.data, 'attrname')
      this.isSortDataChanged = this.flagSortData.currentData.map((i) => i.attrname.toLowerCase()).join(',') != this.flagSortData.previousData.map((i) => i.attrname.toLowerCase()).join(',')
    }
  }

  openPreview() {
    if(!this.previewopen){
      this.previewopen = true;
    this.ngxService.start()
    const preview$ = this.flagList$.pipe(map((i:any)=>i.filter(j=>j.isactive.toLowerCase() == 'active'))).subscribe((res) => {
      this.dialog.openLargeDialog("Preview Flags", res,"flags").afterClosed().subscribe(() => {
        preview$.unsubscribe()
        this.previewopen = false;
        // this.filtercondition.next({ ...previousFilterConditions })
      })
      this.ngxService.stop()
    })
  }

  }

  openEdit(flag: any) {
    this.editedFlag = flag
    //this.formAddFlag.controls.attrorder.setValidators(Validators.required)
    this.formAddFlag.patchValue({
      attrorder: flag.attrorder,
      attrid: flag.attrid,
      attrname: flag.attrname,
    })
    this.isactive = flag.isactive === 'Active'
    this.oldObject={
      AttrOrder : flag.attrorder,
      AttrName: flag.attrname,
      IsActive: flag.isactive === 'Active'
    }
  
  }

  SaveFlag() {
    if (!this.isValidate()) return
    this.ngxService.start()
    this.showLabAdminLoader = true
    this._vitalHttpService.BulkUploadFlags(this.getFlagObjectArray(), this.destDeployment).subscribe({
      next: (result) => this.handleSuccess(result[0].Data[0].Notes, 'Create'),
      error: (err) => this.handleError(err),
      complete: () =>
        {
          this.ngxService.stop()
          this.showLabAdminLoader = false
        } 
    });
  }

  EditFlag() {
    if (!this.isValidate()) return
    this.ngxService.start()
    this.showLabAdminLoader = true
    this._vitalHttpService.UpdateFlags(this.getFlagObjectArray(), this.destDeployment).subscribe({
      next: (result) => this.handleSuccess(result[0]?.Data[0]?.Notes, 'Edit'),
      error: (err) => this.handleError(err),
      complete: () =>
        {
          this.ngxService.stop()
          this.showLabAdminLoader = false
        } 
    });
  }

  private getFlagObjectArray(): any {
    return [{
      "associationtype": this.Orgtype[0].associationtype,
      "organizationid": this.sourceApp ? this.labAdminService.organizationId.toString() : sessionStorage.getItem('org_id'),
      "category": "Flags",
      "attrdescription": this.formAddFlag.controls.attrname.value,
      "attrorder": this.formAddFlag.controls.attrorder.value,
      "attrname": this.formAddFlag.controls.attrname.value,
      "isactive": this.isactive,
      "entitytype": "Role",
      "attrtype": "checkbox",
      "defaultvalue": "Checked",
      "tablename": "qualityattributes",
      "slno": 1,
      "attrid": this.formAddFlag.controls.attrid.value
    }]
  }

  private isValidate(): boolean {
    const currentValue = this.formAddFlag.controls.attrname.value.trim().toLowerCase();
  //  const currentIsactiveValue = this.isactive ? 'Active' : 'Inactive'

    if (currentValue === "") {
      this._snackbar.open("Please Enter the Name", "close");
      document.getElementById('name').querySelector('input').focus()
      return false;
    }
    return true;
  }

  private handleSuccessSequence(newobj,oldobj)
  {
    let sessionId = this.commonService.generateGuid();  
    for(let i = 0; i<newobj.length; i++)
    {     
     this.commonService.createActivityObject(newobj[i].attrid,newobj[i].attrname, this.templateData.menuURL, 'Edit',newobj[i], oldobj[i], sessionId,this.auditableColumns);
    }    
  }
  
  private handleSuccess(Message: string, Status: string) {
    this.ngxService.stop();
    this.showLabAdminLoader = false
    let sessionId = this.commonService.generateGuid();
    if(Status == 'Edit'){
      this.newObject=this.getFlagObjectArray()[0]      
      this.oldObject.IsActive  = this.oldObject.IsActive ? "Active":"In Active"
      this.newObject.isactive  = this.newObject.isactive ? "Active":"In Active"
    this.commonService.createActivityObject(this.getFlagObjectArray()[0].attrid,this.getFlagObjectArray()[0].attrname, this.templateData.menuURL, 'Edit',this.newObject, this.oldObject, sessionId,this.auditableColumns);
    }
    else {
      this.newObject=this.getFlagObjectArray()[0]
      delete this.newObject.attrorder
      this.newObject.isactive  = this.newObject.isactive ? "Active":"In Active"
      this.commonService.createActivityObject('', this.getFlagObjectArray()[0].attrname, this.templateData.menuURL, 'Create', this.newObject, {}, sessionId, this.auditableColumns);
    }
    this._snackbar.open(Message, 'Close');
    //this.filtercondition.next({ status:'all', searchText: '' })
    if(Message.toLowerCase().includes('exists')) return
    
    this.flagsSelectionModel.clear()
    //this.filtercondition.next({ status:'all', searchText: '' })
    this.getFlagList(this.templateData.Keyword, this.templateData.secondarykeys.OrganizationId)
  }

  private handleError(err) {
    this.ngxService.stop();
    this.showsequenceloader = false
    this.showLabAdminLoader = false
    this._snackbar.open('Something went wrong. Please try again', 'Close');
    console.error(err)
  }

  resetForm() {
    this.editedFlag = undefined
    this.isactive = true
    this.formAddFlag.controls.attrorder.removeValidators;
    this.formAddFlag.reset({
      attrorder: 0,
      attrid: 0,
      attrname: '',
    });
  }

  loadUploadScreen() {
    this.gridPage = false;
    this.uploadClicked = true;
    this.copyDataClicked = false;
  }

  copyOrg() {
    this.gridPage = false;
    this.copyDataClicked = true;
    this.uploadClicked = false;
  }
  backClicked() {
    this.gridPage = true;
    this.getFlagList(this.templateData.Keyword, this.templateData.secondarykeys.OrganizationId)
  }

  // export the list of reflex rule data in Excel format
  exportexcel(flagData) {
    if(this.flagsSelectionModel.selected.length) return 
    const flagsData_Export=flagData.map((j:any)=>{
     let obj= 
      {
        'Attribute Id':j.attrid?.toString(),
        'Order':j.attrorder?.toString(),
        'Name': j.attrname,
        // 'Description': j.attrdescription,
        // 'Role Name': j.rolename,
        'Status' : j.isactive,
    }
    if (this.sourceApp === 'VitalDx') delete obj['Attribute Id']
    return obj
  }) 
    const fileName = this.sourceApp=='VitalDx'?'Flags.xlsx':`Flags_${this.templateData.secondarykeys.OrganizationId.toString()}.xlsx`;
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(flagsData_Export);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const headerCellRange = XLSX.utils.decode_range(ws['!ref'] || '');
    for (let col = headerCellRange.s.c; col <= headerCellRange.e.c; col++) {
      const cell = ws[XLSX.utils.encode_cell({ r: 0, c: col })];
      if (cell) {
        cell.s = {
          font: {
            bold: true,
          },
        };
      }
    }
    
    XLSX.utils.book_append_sheet(wb, ws, 'Flags');

    // Export the workbook
    // XLSXStyle.writeFile(wb, 'data.xlsx');
    XLSX.writeFile(wb, fileName);
  }

  filterStatus(updateValue) {
    if(this.flagsSelectionModel.selected.length) return
    this.filtercondition.next({ ...this.filtercondition.value, ...updateValue })
  }

  ngAfterViewInit(): void {
    this.maindiv.changes.subscribe((comps: QueryList <any>) =>
    {
      if(comps.first)  comps.first.nativeElement.scrollTop = this.scrollHistory
    });
  }

  onScroll(e: any){
    this.scrollHistory = e.target.scrollTop
  }

  private checkOrder(array, key): 'ASC' | 'DESC' | 'UNS' | '' {
    if (array.length <= 1) return ""
    var aa = array.slice(1);
    if (aa.every((a, i) => array[i][key].toLowerCase() <= a[key].toLowerCase())) {
      return "ASC";
    }
    if (aa.every((a, i) => array[i][key].toLowerCase() >= a[key].toLowerCase())) {
      return "DESC";
    }
    return "UNS";
  }

  sequenceTracking(order : string, list : any = [], fromcode : boolean = false){
    if(!fromcode){
    this.sortedOrder = order
    //if(this.previousSortedOrder === order) this.tracking = []
    this.tracking.push(order)
    }

    if(order === 'ASC'){
      list = list.sort((a,b) => a.attrname.toLowerCase() > b.attrname.toLowerCase() ? 1 : -1)
    }
    else{
      list = list.sort((a,b) => a.attrname.toLowerCase() > b.attrname.toLowerCase() ? -1 : 1)
    }

    for (let index = 0; index < list.length; index++) {
      list[index].attrorder = index + 1
    }

    this.isSortDataChanged = this.flagSortData.currentData.map((i) => i.attrname.toLowerCase()).join(',') != this.flagSortData.previousData.map((i) => i.attrname.toLowerCase()).join(',')
  }

  returnToList(){
    if(this.isSortDataChanged){
      this.dialog.openLabadminConfimationdialog('', "All your changes will be lost. Do you want to proceed?", 'OK', 'Cancel')
          .afterClosed().subscribe(result => {
            if (result) {
              this.filtercondition.next({ status:'all', searchText: '' })
                  this.sequenceEditMode = !this.sequenceEditMode
                  this.getFlagList(this.templateData.Keyword, this.templateData.secondarykeys.OrganizationId)
                  this.tracking = []
            }
          })
    }
    else{
        this.filtercondition.next({ status:'all', searchText: '' })
        this.sequenceEditMode = !this.sequenceEditMode
        this.tracking = []
    }
  }

  saveSequence(){
    this.ngxService.start()
    this.showsequenceloader = true
    let attrSort = []
    let groupSort = []
    let groupSortforAudit=[]   
    const ascIndex = this.tracking.lastIndexOf('ASC')
    const descIndex = this.tracking.lastIndexOf('DESC')
    const sortedValue = ascIndex >= descIndex ? ascIndex : descIndex

    if(sortedValue != -1 && this.tracking[sortedValue] != this.previousSortedOrder){
      this.sequenceTracking(this.tracking[sortedValue], this.flagSortData.previousData, true)
      let currentSeq = this.tracking[sortedValue]
      this.tracking = this.tracking.pop() === 'drop' ? ['drop'] : []
      groupSort.push({ organizationId: this.templateData.secondarykeys.OrganizationId, oper: qualityAttributeAndFlags.Flags, orderby: currentSeq, accountid: null, casetype: null, parentId: null })
      groupSortforAudit.push({ id: this.templateData.secondarykeys.OrganizationId,
        name : 'Flags',
         new: currentSeq ,
         old:this.previousSortedOrder,
         groupname: 'Flags',
        groupid:null} )
    }

    if(this.tracking.length > 0){
      for (let index = 0; index < this.flagSortData.currentData.length; index++) {
        const currentElement = this.flagSortData.currentData[index];
        const previousElement = this.flagSortData.previousData[index];

        if (currentElement.attrid != previousElement.attrid) {
          attrSort.push({ id: currentElement.attrid, sequence: previousElement.attrorder, to: qualityAttributeAndFlags.Flags })
          groupSortforAudit.push({id: currentElement.attrid,              
            name: currentElement.attrname,
            new:  previousElement.attrorder,
            old:currentElement.attrorder,
            groupname: currentElement.category,
            groupid: currentElement.attrid} )   
        }
      }
    }
    const result: any = {};

  groupSortforAudit.forEach(item => {        
    const groupName = item.groupname;
    let itemName = item.name;
    const oldValue = item.old;
    const newValue = item.new;  

    if (item.groupid === null) {
      itemName += " Group";
  }
    result[groupName] = result[groupName] || [];
    result[groupName].push({
        [itemName]: [
            {
                Sequence: 
                    {
                        oldValue: oldValue,
                        newValue: newValue
                    }                
            }
        ]
    });
});

    
    this._vitalHttpService.SwapSequence2({ UpdateSequences: attrSort, SortItems: groupSort },this.destDeployment).subscribe({
      next: (res) => {
        this.showsequenceloader = false
        let sessionId = this.commonService.generateGuid(); 
        this.commonService.createActivityObject('',"Sequence", this.templateData.menuURL, 'Edit', '', '', sessionId,this.auditableColumns,'',result);   
        this._snackbar.open('Sequence updated successfully', 'Close')
        this.sequenceEditMode = !this.sequenceEditMode
        this.filtercondition.next({ status:'all', searchText: '' })
        this.getFlagList(this.templateData.Keyword, this.templateData.secondarykeys.OrganizationId)
        this.tracking = []
        this.ngxService.stop()


      },
      error: (err) => this.handleError(err)
    })
  }

  enableSortEdit(){
    this.sequenceEditMode = !this.sequenceEditMode
    this.isSortDataChanged = false
    this.filtercondition.next({ status: 'Active', searchText: '' })
  }

  openReturnPopup() {
      this.dialog.openLabadminConfimationdialog('', "All your changes will be lost. Do you want to proceed?", 'OK', 'Cancel')
        .afterClosed().subscribe(result => {
          if (result) {
            this.flagsSelectionModel.clear()
          }
          else{
            return
          }             
        })
  }

  onEditReturn()
  {
    if(this.getters.attrname.value != this.editedFlag.attrname || this.getters.attrorder.value !=this.editedFlag.attrorder ||
      this.editedFlag.isactive =='Active' != this.isactive)
    {
      this.openReturnPopup()
    }
    else
    this.flagsSelectionModel.clear()
  }

  isFilterEnabledChanged()
  {
    const x = this.filtercondition.value
    return !(x.searchText == '' && (x.status.toLowerCase() == 'all' || x.status.toLowerCase() == 'active'));
  }

  getAuditableDetails(location: any) {
    this._vitalHttpService.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns =  JSON.parse(res.content.JsonData);
    })
  }
  oncreateReturn()
  {
   if(this.getters.attrname.value || !this.isactive) this.openReturnPopup()
    else  this.flagsSelectionModel.clear()
  }
}
