<div class="modal-body set-location-wrap">
    <div class="row">
        <div class="col-sm-12 p-0">
            <!------------ Organization Locations Grid--------------------- -->
            <div *ngIf="ShowLocationGrid">
                <div *ngIf="gridData && columns.length > 0" class="modal-content bordernone">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="modal-header admin-model-header">
                                <h3 class="modal-title w-100 admin-model-header-txt">
                                    <strong>Locations Under The Group</strong>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <br>
                    <wj-flex-grid (initialized)="initLocationGrid(locguid)" [headersVisibility]="'Column'"
                        [isReadOnly]="true" (click)="locationItemOnClick(locguid, $event,'null')" [itemsSource]="gridData"
                        [itemFormatter]="formatItem" #locguid>
                        <wj-flex-grid-column [width]="200" [minWidth]="40" *ngFor="let col of columns" [binding]="col.binding"
                            [header]="col.header" [visible]="locListVisible(col.header)" [allowResizing]="true" [allowDragging]="false" [format]="'d'">
                        </wj-flex-grid-column>
                        <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                    </wj-flex-grid>
                    <div class="alignText">
                        <wj-collection-view-navigator *ngIf="GridLocArray.length > 10" class="mt-2" headerFormat="Page {currentPage:n0} of {pageCount:n0}"
                            [byPage]="true" [cv]="gridData">
                        </wj-collection-view-navigator>
                        <wj-menu class="mt-2 ml-2" [(value)]="gridData.pageSize" [header]="'Page Size'" *ngIf="GridLocArray.length > 10">
                            <wj-menu-item [value]="0">No Paging</wj-menu-item>
                            <wj-menu-item [value]="10">10</wj-menu-item>
                            <wj-menu-item [value]="15">15</wj-menu-item>
                            <wj-menu-item [value]="30">30</wj-menu-item>
                            <wj-menu-item [value]="50">50</wj-menu-item>
                          </wj-menu>
                    </div>
                    <div class="alignText">
                        <br />
                        <div class="sub-button mt-2">
                            <button mat-raised-button class="admin-btn-success mr-4" (click)="ExportExcel(locguid)"
                                [disabled]="export">Export</button>
                            <!-- <button (click)="createBtnClick()" class="admin-btn-success">Add Location</button> -->
                            <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!commonService.isPol" (click)="createBtnClick()"
                                [disabled]="btcreate">Create</button>

                            <button mat-raised-button class="admin-btn-success mr-4 menu-list" *ngIf="commonService.isPol" #POLcreate [matMenuTriggerFor]="createMenu"
                                [disabled]="btcreate">Create</button>
                                <mat-menu  #createMenu="matMenu" class="menu-wrap mt-2 btn-height">
                                    <button mat-menu-item (click)="createBtnClick('OF')" class="btn-line">
                                       <span class="font-size"> Ordering Facility </span>
                                    </button>

                                    <button mat-menu-item (click)="createBtnClick('RL')">
                                        <span class="font-size">   Reading Location </span>
                                    </button>
                                </mat-menu>
                            <!-- <button mat-raised-button class="admin-btn-success" [title]="'Associate'"
                                [disabled]="btnCreate" (click)="addNewAssociation()">
                                Associate
                            </button> -->
                        </div>
                    </div>
                </div>
            </div>

            <!-------------- Setup New Location ---------------------- -->
            <div *ngIf="ShowSetupNewLocation" class="modal-content bordernone">
                <div class="modal-header admin-model-header">
                    <h3 *ngIf="!showHeaderDisplayName" class="modal-title w-100 admin-model-header-txt">
                        <strong>Setup New Location</strong>
                    </h3>
                    <h3 *ngIf="showHeaderDisplayName" class="modal-title w-100 admin-model-header-txt">
                        <strong>{{this.displaynameHeader}} </strong>
                    </h3>
                </div>
                <mat-tab-group class="width-mat-tab" [selectedIndex]="selectedIndex" >
                    <!-- <mat-tab-group (selectedTabChange)="tabClick($event)" [selectedIndex]="selectedIndex"> -->
                    <!-- <mat-tab #tab [disabled]='!tab.isActive'> -->
                    <mat-tab label="Add Location" [disabled]="DisplayLocationTab" *ngFor="let number of [0];let i=index;">
                        <ng-template mat-tab-label class="tabSize">
                            <span>Location</span>
                        </ng-template>
                        <form [formGroup]="AddLocRecipientData" autocomplete="off">
                            <div class="mt-3">
                                <div class="row mt-3">
                                    <div class="col-sm-6">
                                  <!-- NPI Field -->
                                      <div class="row m-0 p-0 mb-1">
                                        <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                            <label class="required">NPI </label>
                                        </div>
                                        <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                            <div class="row">
                                                <div class="col-sm-12" id="input-container">
                                                    <input formControlName="NPI" type="text " maxlength="10" (input)="captureNPI(AddLocRecipientData.value.NPI)"
                                                        class="form-control font-size" (keyup)="triggerNPIPopup()"/>
                                                </div>
                                            </div>
                                            <div *ngIf="NPIWarning" class="error-msg">
                                                Invalid NPI!
                                            </div>
                                            <div *ngIf="AddLocRecipientData.controls['NPI'].invalid && (AddLocRecipientData.controls['NPI'].dirty ||
                                                AddLocRecipientData.controls['NPI'].touched)"
                                                class="error-msg">
                                                Invalid NPI!
                                                <div *ngIf="AddLocRecipientData.controls['NPI'].errors.maxLength || AddLocRecipientData.controls['NPI'].errors.required ">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Location Name Field -->
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size d-flex">
                                                <label class="required">Location Name </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1 d-flex">
                                                <input class="form-control font-size" formControlName="AccountName" id="accName"
                                                    (oninput)="enableBtn(AddLocRecipientData.controls['AccountName'].value)">
                                                <div class="error-msg" *ngIf="AddLocRecipientData.controls['AccountName'].invalid &&
                                                    (AddLocRecipientData.controls['AccountName'].dirty ||
                                                    AddLocRecipientData.controls['AccountName'].touched)">
                                                    Upto 50 alphanumeric and special characters allowed.
                                                    <div *ngIf="AddLocRecipientData.controls['AccountName'].errors.maxLength ||
                                                        AddLocRecipientData.controls['AccountName'].errors.required">
                                                    </div>
                                                    <!-- <div *ngIf="AddLocRecipientData.controls['AccountName'].errors.maxLength ||
                                                    AddLocRecipientData.get('AccountName').errors || AddLocRecipientData.value.AccountName == '' ">
                                                    </div> -->
                                                </div>

                                            </div>
                                        </div>
                                        <!-- Search NPI / Registry Button  -->
                                         <div class="row m-0 p-0 mb-2 pr-3 justify-content-end">
                                          <button mat-raised-button class="admin-btn-success" (click)="openSearchNPIPopup()">
                                            {{ isProviderMdmEnabled ? 'Search NPI' : 'Search NPI Registry' }}
                                          </button>
                                         </div>


                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label class="required">Short Name </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input class="form-control font-size" formControlName="ShortName"
                                                    (oninput)="enableBtn(AddLocRecipientData.controls['ShortName'].value)">
                                                <div class="error-msg" *ngIf="AddLocRecipientData.controls['ShortName'].invalid &&
                                                    (AddLocRecipientData.controls['ShortName'].dirty ||
                                                    AddLocRecipientData.controls['ShortName'].touched)">
                                                    Upto 10 alphanumeric and special characters allowed.
                                                    <div *ngIf="AddLocRecipientData.controls['ShortName'].errors.maxLength ||
                                                        AddLocRecipientData.controls['ShortName'].errors.required">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1  font-size">
                                                <label>Parent Location Name</label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input class="form-control font-size" formControlName="ParentAccountName">
                                                <div *ngIf="AddLocRecipientData.controls['ParentAccountName'].invalid && (AddLocRecipientData.controls['ParentAccountName'].dirty ||
                                                    AddLocRecipientData.controls['ParentAccountName'].touched)"
                                                    class="error-msg">
                                                    Must be 100 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['ParentAccountName'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1  font-size">
                                                <label class="required">Display Name </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_LatestVersion" class="form-control font-size" title="{{AddLocRecipientData.controls['DisplayName'].value}}"
                                                    formControlName="DisplayName" (oninput)="enableBtn(AddLocRecipientData.controls['DisplayName'].value)">
                                                <div *ngIf="AddLocRecipientData.controls['DisplayName'].invalid &&
                                            (AddLocRecipientData.controls['DisplayName'].dirty ||
                                            AddLocRecipientData.controls['DisplayName'].touched)"
                                                    class="error-msg">
                                                    Upto 50 alphanumeric and special characters allowed.
                                                    <div *ngIf="AddLocRecipientData.controls['DisplayName'].errors.maxLength ||
                                                AddLocRecipientData.controls['DisplayName'].errors.required">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label class="required">NPI </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_APIKey" class="form-control font-size"
                                                    formControlName="NPI" autocomplete="off"
                                                    (input)="NPIValidation(AddLocRecipientData.value.NPI)">
                                                 <div *ngIf="NPIWarning" class="error-msg">
                                                    Invalid NPI!
                                                </div>
                                                <div *ngIf="NPIWarning && AddLocRecipientData.controls['NPI'].invalid && (AddLocRecipientData.controls['NPI'].dirty ||
                                                AddLocRecipientData.controls['NPI'].touched)"
                                                    class="error-msg">Invalid NPI!
                                                    <div
                                                        *ngIf="AddLocRecipientData.controls['NPI'].errors.maxLength || AddLocRecipientData.controls['NPI'].errors.required ">
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>EIN </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_APIKey" class="form-control font-size" formControlName="EIN"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['EIN'].invalid && (AddLocRecipientData.controls['EIN'].dirty ||
                                                    AddLocRecipientData.controls['EIN'].touched)"
                                                    class="error-msg">
                                                    Must be 10 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['EIN'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>CLIA </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_APIKey" class="form-control font-size" formControlName="CLIA"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['CLIA'].invalid && (AddLocRecipientData.controls['CLIA'].dirty ||
                                                    AddLocRecipientData.controls['CLIA'].touched)"
                                                    class="error-msg">
                                                    Must be 50 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['CLIA'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>State License </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_APIKey" class="form-control font-size" formControlName="StateLicense"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['StateLicense'].invalid && (AddLocRecipientData.controls['StateLicense'].dirty ||
                                                AddLocRecipientData.controls['StateLicense'].touched)"
                                                    class="error-msg">
                                                    Must be 50 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['StateLicense'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Primary Contact Name </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_APIKey" class="form-control font-size" formControlName="PrimaryContactname"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['PrimaryContactname'].invalid && (AddLocRecipientData.controls['PrimaryContactname'].dirty ||
                                                AddLocRecipientData.controls['PrimaryContactname'].touched)"
                                                    class="error-msg">
                                                    Must be 100 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['PrimaryContactname'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Primary Contact Phone </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_APIKey" class="form-control font-size" (keypress)="AllowOnlyNumber($event)"
                                                    formControlName="PrimaryContactPhone" autocomplete="off" maxlength="10">
                                                <!-- <div *ngIf="AddLocRecipientData.controls['PrimaryContactPhone'].invalid && (AddLocRecipientData.controls['PrimaryContactPhone'].dirty ||
                                                AddLocRecipientData.controls['PrimaryContactPhone'].touched)"
                                                    class="error-msg">
                                                    Invalid Phone number

                                                    <div
                                                        *ngIf="AddLocRecipientData.controls['PrimaryContactPhone'].errors.maxLength">
                                                    </div>
                                                </div> -->
                                            </div>

                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Primary Contact Email </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_APIKey" class="form-control font-size" formControlName="PrimaryContactEmail"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['PrimaryContactEmail'].invalid && (AddLocRecipientData.controls['PrimaryContactPhone'].dirty ||
                                                AddLocRecipientData.controls['PrimaryContactEmail'].touched)"
                                                    class="error-msg">
                                                    Invalid email.
                                                    <div *ngIf="AddLocRecipientData.controls['PrimaryContactEmail'].errors.maxLength ||
                                                    AddLocRecipientData.controls['PrimaryContactEmail'].errors.pattern">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Claim Options </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <select #CLM id="input_APIKey" class="form-control font-size"
                                                    formControlName="ClaimOptions" autocomplete="off">
                                                    <!-- <option value="" selected disabled>Select</option> -->
                                                    <option *ngFor="let a of ClaimOptionsArray">
                                                        {{a.item}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1  font-size">
                                                <label>Enable Fax </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input type="checkbox" id="input_FaxEnable" formControlName="FaxEnable"
                                                    maxlength="25">
                                            </div>
                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Collection Date Format </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <select #CDF class="form-control font-size" formControlName="CollectionDateFormat"
                                                    autocomplete="off">
                                                    <!-- <option value="" selected>Select</option> -->
                                                    <option *ngFor="let d of dateFormat">{{d}}</option>
                                                </select>

                                                <!-- <input id="input_APIKey" class="form-control font-size"
                                                    formControlName="CollectionDateFormat" autocomplete="off"> -->
                                                <!-- <div *ngIf="AddLocRecipientData.controls['CollectionDateFormat'].invalid && (AddLocRecipientData.controls['CollectionDateFormat'].dirty ||
                                                AddLocRecipientData.controls['CollectionDateFormat'].touched)"
                                                    class="error-msg">
                                                    Must be 10 characters or less.
                                                    <div
                                                        *ngIf="AddLocRecipientData.controls['CollectionDateFormat'].errors.maxLength">
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Package Date Format </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <select #PDF class="form-control font-size" formControlName="PackageDateFormat"
                                                    autocomplete="off">
                                                    <option value="" selected>Select</option>
                                                    <option *ngFor="let d of dateFormat">{{d}}</option>
                                                </select>
                                                <!-- <input id="input_APIKey" class="form-control font-size"
                                                    formControlName="PackageDateFormat" autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['PackageDateFormat'].invalid && (AddLocRecipientData.controls['PackageDateFormat'].dirty ||
                                                AddLocRecipientData.controls['PackageDateFormat'].touched)"
                                                    class="error-msg">
                                                    Must be 10 characters or less.
                                                    <div
                                                        *ngIf="AddLocRecipientData.controls['PackageDateFormat'].errors.maxLength">
                                                    </div>
                                                </div> -->
                                            </div>

                                        </div>
                                        <div class="row m-0 p-0 mb-1" *ngIf="!entityType">
                                            <div class="col-md-5 p-0 m-0 pr-1  font-size">
                                                <label>Is Listed As OF </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input type="checkbox" id="input_FaxEnable" formControlName="IsListedAsOF">
                                            </div>
                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Geo Coordinates </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_APIKey" class="form-control font-size" formControlName="GeoCoordinates"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['GeoCoordinates'].invalid && (AddLocRecipientData.controls['GeoCoordinates'].dirty ||
                                          AddLocRecipientData.controls['GeoCoordinates'].touched)"
                                                    class="error-msg">
                                                    Must be 100 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['GeoCoordinates'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Report Data </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_APIKey" class="form-control font-size" formControlName="ReportData"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['ReportData'].invalid && (AddLocRecipientData.controls['ReportData'].dirty ||
                                          AddLocRecipientData.controls['ReportData'].touched)"
                                                    class="error-msg">
                                                    Must be 100 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['ReportData'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="row m-0 p-0 mb-1" *ngIf="!entityType || entityType === 'Lab'">
                                            <div class="col-md-5 p-0 m-0 pr-1  font-size">
                                                <label>Enable SendOut </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input type="checkbox" id="input_EnableSendOutCheckBox" formControlName="EnableSendOutCheckBox">
                                            </div>
                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Address1 </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_APIKey" class="form-control font-size" formControlName="Address1"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['Address1'].invalid && (AddLocRecipientData.controls['Address1'].dirty ||
                                          AddLocRecipientData.controls['Address1'].touched)"
                                                    class="error-msg">
                                                    Must be 100 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['Address1'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Address2 </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_APIKey" class="form-control font-size" formControlName="Address2"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['Address2'].invalid && (AddLocRecipientData.controls['Address2'].dirty ||
                                          AddLocRecipientData.controls['Address2'].touched)"
                                                    class="error-msg">
                                                    Must be 100 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['Address2'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Street </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_Street" class="form-control font-size" formControlName="Street"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['Street'].invalid && (AddLocRecipientData.controls['Street'].dirty ||
                                                    AddLocRecipientData.controls['Street'].touched)"
                                                    class="error-msg">
                                                    Must be 50 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['Street'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>City </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_Street" class="form-control font-size" formControlName="City"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['City'].invalid && (AddLocRecipientData.controls['City'].dirty ||
                                          AddLocRecipientData.controls['City'].touched)"
                                                    class="error-msg">
                                                    Must be 50 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['City'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <!-- <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>State </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <select #STA id="input_State" class="form-control font-size"
                                                    formControlName="State" autocomplete="off">
                                                    <option value="" selected>Select</option>
                                                    <option *ngFor="let a of StatesArray">
                                                        {{a.item}}
                                                    </option>
                                                </select>
                                            </div> -->
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>State </label>
                                            </div>
                                            <mat-form-field appearance="outline" class="frmMatSelect frm-auto w-100 col-md-7 p-0 m-0 pr-3 mb-1" [ngclass]="{'valuedField': AddLocRecipientData.value.State}">
                                                <!-- <mat-label>State</mat-label> -->
                                                <i class="fa fa-chevron-down chevron-align align-icon"></i>
                                                <input type="text" #searchInput  matInput #trigger="matAutocompleteTrigger"
                                                  [matAutocomplete]="auto"  formControlName="State"
                                                  (input)="filterState(AddLocRecipientData.value.State)">
                                                <button *ngIf="AddLocRecipientData.value.State" matSuffix mat-icon-button aria-label="Clear" title="Reset Search"
                                                  (click)="refreshStateSearch()">
                                                  <mat-icon>close</mat-icon>
                                                </button>
                                                <mat-autocomplete (optionSelected)="filterState($event.option.viewValue)" #auto="matAutocomplete">
                                                  <mat-option class="mat-opt-align" title="{{state.item}}"
                                                    *ngFor="let state of getStatesList(AddLocRecipientData.value.State)"
                                                    value = "{{state.item}}">
                                                    <span class="font-size"> {{state.item}}</span>
                                                  </mat-option>
                                                </mat-autocomplete>
                                              </mat-form-field>

                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>ZIP </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_ZIP" class="form-control font-size" formControlName="ZIP"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['ZIP'].invalid && (AddLocRecipientData.controls['ZIP'].dirty ||
                                          AddLocRecipientData.controls['ZIP'].touched)"
                                                    class="error-msg">
                                                    Invalid ZIP code.
                                                    <div *ngIf="AddLocRecipientData.controls['ZIP'].errors.maxLength ||
                                                    AddLocRecipientData.controls['ZIP'].errors.pattern">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Phone </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_Phone" class="form-control font-size" (keypress)="AllowOnlyNumber($event)"
                                                    formControlName="Phone" autocomplete="off" maxlength="20">
                                                <!-- <div *ngIf="AddLocRecipientData.controls['Phone'].invalid && (AddLocRecipientData.controls['Phone'].dirty ||
                                          AddLocRecipientData.controls['Phone'].touched)" class="error-msg">
                                                   Must be 25 characters or less.
                                                    Invalid Phone Number
                                                    <div *ngIf="AddLocRecipientData.controls['Phone'].errors.maxLength">
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Fax </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_Fax" class="form-control font-size" (keypress)="AllowOnlyNumber($event)"
                                                    formControlName="Fax" autocomplete="off" maxlength="25">
                                                <!-- <div *ngIf="AddLocRecipientData.controls['Fax'].invalid && (AddLocRecipientData.controls['Fax'].dirty ||
                                                    AddLocRecipientData.controls['Fax'].touched)"
                                                    class="error-msg">
                                                    Invalid fax!
                                                    <div *ngIf="AddLocRecipientData.controls['Fax'].errors.maxLength ||
                                                    AddLocRecipientData.controls['Fax'].errors.pattern">
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>

                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Name Display Format </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <select #UDF id="input_UsernameDisplayformat" class="form-control font-size"
                                                    formControlName="UsernameDisplayformat" autocomplete="off">
                                                    <!-- <option value="" selected>Select</option> -->
                                                    <option *ngFor="let a of UsernameDisplayFormatArray">
                                                        {{a.item}}
                                                    </option>
                                                </select>
                                            </div>

                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Reports Display Format </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <select #UPF id="input_UsernameReportformat" class="form-control font-size"
                                                    formControlName="UsernameReportformat" autocomplete="off">
                                                    <!-- <option value="" selected>Select</option> -->
                                                    <option *ngFor="let a of UsernameReportFormatArray">
                                                        {{a.item}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-5 p-0 m-0 font-size">
                                                    <mat-checkbox [color]="task.color" class="example-margin" formControlName="Status">Active
                                                    </mat-checkbox>
                                            </div>
                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Reg Location Name </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_Street" class="form-control font-size" formControlName="RegAccountName"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['RegAccountName'].invalid &&
                                        (AddLocRecipientData.controls['RegAccountName'].dirty ||
                                          AddLocRecipientData.controls['RegAccountName'].touched)"
                                                    class="error-msg">
                                                    Must be 300 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['RegAccountName'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Claim Mnemonic </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_Street" class="form-control font-size" formControlName="ClaimMnemonic"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['ClaimMnemonic'].invalid && (AddLocRecipientData.controls['ClaimMnemonic'].dirty ||
                                            AddLocRecipientData.controls['ClaimMnemonic'].touched)"
                                                    class="error-msg">
                                                    Must be 2 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['ClaimMnemonic'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Received Date Format </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <select #RDF class="form-control font-size" formControlName="ReceivedDateFormat"
                                                    autocomplete="off">
                                                    <option value="" selected>Select</option>
                                                    <option *ngFor="let d of dateFormat">{{d}}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <!-- <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Username Report format </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <select id="input_UsernameReportformat" class="form-control font-size"
                                                    formControlName="UsernameReportformat" autocomplete="off">
                                                    <option value="" selected>Select</option>
                                                    <option *ngFor="let a of UsernameReportFormatArray">
                                                        {{a.item}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div> -->

                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>ETA Date Format </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <select #RVT class="form-control font-size" formControlName="ETADateFormat"
                                                    autocomplete="off">
                                                    <option value="" selected>Select</option>
                                                    <option *ngFor="let d of dateFormat">{{d}}</option>
                                                </select>
                                                <!-- <input id="input_Street" class="form-control font-size"
                                                    formControlName="ETADateFormat" autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['ETADateFormat'].invalid && (AddLocRecipientData.controls['ETADateFormat'].dirty ||
                                            AddLocRecipientData.controls['ETADateFormat'].touched)"
                                                    class="error-msg">
                                                    Must be 10 characters or less.
                                                    <div
                                                        *ngIf="AddLocRecipientData.controls['ETADateFormat'].errors.maxLength">
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>CLPOL </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_Street" class="form-control font-size" formControlName="CLPOL"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['CLPOL'].invalid && (AddLocRecipientData.controls['CLPOL'].dirty ||
                                          AddLocRecipientData.controls['CLPOL'].touched)"
                                                    class="error-msg">
                                                    Must be 50 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['CLPOL'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row m-0 p-0 mb-1" *ngIf="entityType == 'OF'">
                                            <div class="col-md-5 p-0 m-0 pr-1  font-size">
                                                <label>Is Shared Surgicenter </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input type="checkbox" id="input_IsSharedSurgicenter" formControlName="IsSharedSurgicenter">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Pay To Address1 </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_Street" class="form-control font-size" formControlName="PayToAddress1"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['PayToAddress1'].invalid && (AddLocRecipientData.controls['PayToAddress1'].dirty ||
                                              AddLocRecipientData.controls['PayToAddress1'].touched)"
                                                    class="error-msg">
                                                    Must be 100 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['PayToAddress1'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Pay To Address2 </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_Street" class="form-control font-size" formControlName="PayToAddress2"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['PayToAddress2'].invalid && (AddLocRecipientData.controls['PayToAddress2'].dirty ||
                                              AddLocRecipientData.controls['PayToAddress2'].touched)"
                                                    class="error-msg">
                                                    Must be 100 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['PayToAddress2'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Pay To State </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_Street" class="form-control font-size" formControlName="PayToState"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['PayToState'].invalid && (AddLocRecipientData.controls['PayToState'].dirty ||
                                              AddLocRecipientData.controls['PayToState'].touched)"
                                                    class="error-msg">
                                                    Must be 100 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['PayToState'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Pay To Zip </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_Street" class="form-control font-size" formControlName="PayToZip"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['PayToZip'].invalid && (AddLocRecipientData.controls['PayToZip'].dirty ||
                                              AddLocRecipientData.controls['PayToZip'].touched)"
                                                    class="error-msg">
                                                    Invalid ZIP code.
                                                    <div *ngIf="AddLocRecipientData.controls['PayToZip'].errors.pattern">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row m-0 p-0 mb-1">
                                            <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                <label>Pay To City </label>
                                            </div>
                                            <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                <input id="input_Street" class="form-control font-size" formControlName="PayToCity"
                                                    autocomplete="off">
                                                <div *ngIf="AddLocRecipientData.controls['PayToCity'].invalid && (AddLocRecipientData.controls['PayToCity'].dirty ||
                                              AddLocRecipientData.controls['PayToCity'].touched)"
                                                    class="error-msg">
                                                    Must be 100 characters or less.
                                                    <div *ngIf="AddLocRecipientData.controls['PayToCity'].errors.maxLength">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- custom attribute -->
                            <div class="mt-3" *ngFor="let attr of customorgattributrlist; let i = index">
                                    <div class="row mt-3" *ngIf="i % 2 === 0 && ContextType!=='Laboratory'">
                                        <div class="col-sm-6">
                                            <div class="row m-0 p-0 mb-1">
                                                <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                    <label>{{ attr.Attribute }}</label>
                                                </div>
                                                <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                    <input class="form-control font-size" placeholder="Enter {{ attr.Attribute }}"
                                                        id="CAID{{ i }}" [(ngModel)]="attr.Value" value="{{ attr.Value }}" maxlength="200"
                                                        (change)="updateCustomorgAttributeArray(attr, $event)"
                                                        [ngModelOptions]="{ standalone: true }">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6" *ngIf="customorgattributrlist[i + 1] && ContextType!=='Laboratory'">
                                            <div class="row m-0 p-0 mb-1">
                                                <div class="col-md-5 p-0 m-0 pr-1 font-size">
                                                    <label>{{ customorgattributrlist[i + 1].Attribute }}</label>
                                                </div>
                                                <div class="col-md-7 p-0 m-0 pr-3 mb-1">
                                                    <input class="form-control font-size"
                                                        placeholder="Enter {{ customorgattributrlist[i + 1].Attribute }}"
                                                        id="CAID{{ i + 1 }}" [(ngModel)]="customorgattributrlist[i + 1].Value"
                                                        value="{{ customorgattributrlist[i + 1].Value }}" maxlength="200"
                                                        (change)="updateCustomorgAttributeArray(customorgattributrlist[i + 1], $event)"
                                                        [ngModelOptions]="{ standalone: true }">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            <!-- AddLocRecipientData.value.AccountName== '' || AddLocRecipientData.value.AccountName == null || AddLocRecipientData.value.AccountName == undefined ||
                            AddLocRecipientData.value.ShortName == '' || AddLocRecipientData.value.ShortName == null || AddLocRecipientData.value.ShortName == undefined ||
                            AddLocRecipientData.value.DisplayName == '' || AddLocRecipientData.value.DisplayName == null || AddLocRecipientData.value.DisplayName == undefined ||
                            AddLocRecipientData.value.NPI == '' || AddLocRecipientData.value.NPI == null || AddLocRecipientData.value.NPI == undefined ||
                            NPIWarning == true || -->
                            <div class="mt-4 mb-2">
                                <button mat-raised-button class="admin-btn-success mr-4" (click)="previousNxtBtn('Location','prev')">Previous</button>
                                <button mat-raised-button class="admin-btn-success mr-4" (click)="previousNxtBtn('Location','nxt')">Next</button>
                                <!-- <button [disabled]="
                                (NPIWarning == true || NPIvalidated == false ) && AddLocRecipientData.invalid" (click)="addNewLocation(AddLocRecipientData.value)"
                                    class="btn admin-btn-success ">Save</button> -->
                                <!-- [disabled]="NPIWarning == true || NPIvalidated == false || AddLocRecipientData.invalid" -->
                                <!-- <button
                                    (click)="addNewLocation(AddLocRecipientData.value,AddLocRecipientData.controls.NPI.status,AddLocRecipientData.controls.AccountName.status,AddLocRecipientData.controls.DisplayName.status,AddLocRecipientData.controls.ShortName.status,false)"
                                    class="btn admin-btn-success  PosSaveBtn btnSavePos">Save</button>  -->
                                <button mat-raised-button class="admin-btn-success float-right" (click)="addNewLocation(AddLocRecipientData.value,AddLocRecipientData.invalid,false)">Save</button>
                                <button mat-raised-button class="admin-btn-success float-right mr-4" (click)="previousNxtBtn('Location','can')">Cancel</button>
                            </div>
                        </form>
                    </mat-tab>
                    <mat-tab label="Associate Location" [disabled]="DisplayAssocLocTab">
                        <ng-template mat-tab-label class="tabSize">
                            <span>Associate Locations</span>
                        </ng-template>
                        <!-------------- Associate Location -------->
                        <form [formGroup]="recipientData" autocomplete="off">
                            <div class="row col-sm-12 mt-3">
                                <div class="col-sm-3">
                                    <strong>Types of Association</strong>
                                    <mat-checkbox [color]="task.color" class="mt-2 mb-2" *ngIf="!entityType" #currloc (change)="fetchCurrentLocationAssociations($event.checked)">
                                        Same as current location
                                    </mat-checkbox>

                                    <mat-form-field appearance="outline" class=" col-sm-12 mt-2">
                                        <mat-label>Select Type</mat-label>
                                        <mat-select disableOptionCentering [disabled]="currentAssociations"
                                            formControlName="AssociationType">
                                            <mat-option *ngFor="let cat of associationTypeList" (click)="selectAssociation(cat.item,recipientData.controls.AssociationType)"
                                                value="{{ cat.item }}">
                                                {{ cat.item }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-sm-5 ngx-drop">
                                    <strong *ngIf="recipientData.controls['AssociationType'].value != ''">Associate
                                        With</strong>
                                    <div class="col-sm-12 p-0" *ngIf="recipientData.controls['AssociationType'].value != ''">
                                        <div class="row p-0 m-0 mt-2" *ngIf="!entityType">
                                            <div class="col-6 p-0">
                                                <mat-radio-button [color]="task.color" value="Organizations" [disabled]="currentAssociations"
                                                    formControlName="Selecttype" value="Location Group" class="mr-1"
                                                    (change)="changingOrganizationAndAccount('Organizations')">Location
                                                    Group
                                                </mat-radio-button>
                                            </div>
                                            <div class="col-6">
                                                <mat-radio-button [color]="task.color" value="'Accounts'" [disabled]="currentAssociations"
                                                    formControlName="Selecttype" class="mr-1" (change)="changingOrganizationAndAccount('Accounts')">Locations
                                                </mat-radio-button>
                                            </div>
                                        </div>

                                        <div *ngIf="recipientData.controls['Selecttype'].value == 'Accounts'" class="mt-2">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Locations</mat-label>
                                                <i class="fa fa-chevron-down chevron-align mt-1"></i>
                                                <input type="text" style="width: 94%;" matInput [matAutocomplete]="auto"
                                                    title="{{recipientData.value.AssociatedAccountid}}" formControlName="AssociatedAccountid"
                                                    placeholder="Please enter minimum three characters to search"
                                                    maxlength="200" placeholder="Search" aria-label="Select" (input)="locationFilter($event)" >
                                                <button *ngIf="recipientData.value.AssociatedAccountid" matSuffix
                                                    mat-icon-button aria-label="Clear" title="Reset Search" (click)="clearResults('AssociatedAccountid',$event)">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                                <mat-autocomplete #auto="matAutocomplete">
                                                    <mat-option class="mat-opt-align" id="drop-down-mat" *ngFor="let acc of locationsList"
                                                        value="{{ acc.accountname }} ({{ acc.accountid }})"
                                                        (onSelectionChange)="getAssociatedAccount($event)">
                                                        <span>{{ acc.accountname }} ({{ acc.accountid }})</span>
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>

                                        <div *ngIf="recipientData.controls['Selecttype'].value ==
                                          'Organizations'"
                                            class="mt-2">
                                            <mat-form-field appearance="outline" class="w-100 p-0 col-12">
                                                <mat-label>Location Group</mat-label>
                                                <i class="fa fa-chevron-down chevron-align" style="margin-top: 6px;"></i>
                                                <input type="text" style="width: 93%" class="asso-input-wrap" matInput
                                                    [matAutocomplete]="auto1" formControlName="associateOrg"
                                                    placeholder="Please enter minimum three characters to search"
                                                    (ngModelChange)="onOrganizationChange($event,recipientData.controls.AssociationType)"
                                                    class="mt-2" (input)="locationFilter($event)" />
                                                <button *ngIf="recipientData.value.associateOrg" matSuffix
                                                    mat-icon-button aria-label="Clear" title="Reset Search" (click)="clearResults('associateOrg',$event)">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                                <mat-autocomplete #auto1="matAutocomplete">
                                                    <mat-option class="mat-opt-align" id="drop-down-mat" *ngFor="let org of organizationsList"
                                                        value="{{
                                                  org.organizationname}} ({{ org.organizationid }})"
                                                        (onSelectionChange)="getOrganizationName($event)">
                                                        <span>{{ org.organizationname }}({{org.organizationid}})</span>
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>

                                        <div *ngIf="(AddMultiselect && recipientData.controls.Selecttype.value == 'Organizations' &&
                                        recipientData.controls['associateOrg'].value != '') && (this.temporgid != null)"class="mt-3">
                                            <ng-multiselect-dropdown [placeholder]="'Locations'" [settings]="dropdownSettings"
                                                [data]="accountsOfSelectedOrg" [(ngModel)]="selectedLevel" (onSelect)="onCheckChange($event)"
                                                (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                                                (onDeSelectAll)="onDeSelectAll($event)" formControlName="AssociatedAccountid">
                                            </ng-multiselect-dropdown>
                                        </div>

                                        <div *ngIf="(UpdateMultiselect && recipientData.controls['Selecttype'].value =='Organizations' &&
                                                recipientData.controls['associateOrg'].value != '') && (this.temporgid != null)">
                                            <ng-multiselect-dropdown [placeholder]="'Locations'" [formControl]="getformDetails.id"
                                                [settings]="dropdownSettings" [data]="accountsOfSelectedOrg"
                                                [(ngModel)]="selectedLevel" (onSelect)="onCheckChange($event)"
                                                (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                                                (onDeSelectAll)="onDeSelectAll($event)" formControlName="AssociatedAccountid">
                                            </ng-multiselect-dropdown>
                                        </div>

                                        <!-- <div *ngIf="recipientData.controls['Selecttype'].value ==
                                        'Organizations'"  class="mt-2">
                                          <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Select</mat-label>
                                            <i class="fa fa-chevron-down chevron-align"></i>
                                            <input type="text" [formControl]="myControl" maxlength="200" matInput placeholder="Select"
                                              aria-label="Select" [matAutocomplete]="auto2" style="width: 95%;">
                                            <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn">
                                              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                                {{ option.name }}
                                              </mat-option>
                                            </mat-autocomplete>
                                          </mat-form-field>
                                        </div>

                                        <div class="mt-2">
                                          <ng-multiselect-dropdown [placeholder]="'Select'"
                                            [settings]="dropdownSettings" [data]="cities" [(ngModel)]="selectedLevel"
                                            (onSelect)="onCheckChange($event)" (onDeSelect)="onItemDeSelect($event)"
                                            (onSelectAll)="onSelectAll($event)" (onDeSelectAll)="onDeSelectAll($event)"
                                            formControlName="AssociatedAccountid">
                                          </ng-multiselect-dropdown>
                                        </div> -->

                                        <!-- <div class="mt-2">
                                          <ng-multiselect-dropdown id="one" name="city" [settings]="dropdownSettings" [data]="cities"
                                            [(ngModel)]="selectedItem" (onSelect)="onItemSelect($event)">
                                          </ng-multiselect-dropdown>
                                        </div> -->

                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div>
                                        <strong>Configurations</strong>

                                        <div class="row mt-2 mb-2">
                                            <div class="col-sm-12 pr-0">
                                                <mat-form-field appearance="outline" class="col-sm-12 p-0">
                                                    <mat-label>Service Type</mat-label>
                                                    <mat-select disableOptionCentering [disabled]="currentAssociations"
                                                        formControlName="Service">
                                                        <mat-option *ngFor="let cat of servicesList" value="{{ cat.item }}">
                                                            {{ cat.item }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <div class="row p-0 temp-font-size">
                                            <div class="col-sm-11">
                                                <label>Diagnosis Reporting done using VitalDx(VitalOffice)
                                                </label>
                                            </div>
                                            <div class="col-sm-1">
                                                <mat-checkbox [color]="task.color" [disabled]="currentAssociations"
                                                    formControlName="isPcEnabled" value="true"></mat-checkbox>
                                            </div>
                                        </div>

                                        <div class="row p-0 temp-font-size">
                                            <div class="col-sm-11">
                                                <label>Allow lab to view reports </label>
                                            </div>
                                            <div class="col-sm-1">
                                                <mat-checkbox [color]="task.color" [disabled]="currentAssociations"
                                                    formControlName="AllowReportViewing" value="true"></mat-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="row mb-2 boder-checkbox">
                            <div class="col-12 d-flex">
                                <div class="col-sm-12 mt-4 mb-4">
                                    <div class="sub-button">
                                        <!-- <button *ngIf="cancelButton || (addeddata && addeddata.length == 0)" [disabled]="currentAssociations"
                                        mat-raised-button class="admin-btn-success mr-4" (click)="backAssociation()" type="button">
                                        Back
                                      </button> -->
                                        <button mat-raised-button class="admin-btn-success mr-4" [disabled]="currentAssociations"
                                            (click)="getpatchdata()" type="button">
                                            Reset
                                        </button>
                                        <!-- *ngIf="!(cancelButton||( addeddata && addeddata.length==0)) && recipientData.controls['AssociationType'].value !=''" -->
                                        <button mat-raised-button class="admin-btn-success" (click)="addAssociationList(recipientData.value)"
                                            type="button" [disabled]="
                                          (recipientData.value.AssociationType == null ||
                                          recipientData.value.AssociationType == '' ||
                                          recipientData.value.AssociatedAccountid == '' ||
                                          recipientData.value.AssociatedAccountid == null)">
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ml-3" *ngIf="associationTable && addeddata.length > 0"><strong>Locations to be
                                Associated : </strong></div>
                        <div *ngIf="associationTable && addeddata.length > 0" class="col-sm-12 mt-2">
                            <wj-flex-grid #assocgrid [itemsSource]="assocGridData"
                                [allowDelete]="false" [headersVisibility]="'Column'" (initialized)="initGrid(grid)">
                                <!-- <wj-flex-grid-column [header]="'Edit'" align="center" [width]="120" [isReadOnly]="true"> -->
                                <!-- <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">

            <button class="wj-btn remove-background p-0" [disabled]=" recipientData.controls['AssociationType'].value !=''">
              <i class="fa fa-pencil-square-o" [title]="'Edit'" (click)="editAssociatingTableRow(row)" aria-hidden="true"></i>
            </button>
            <i class="fa-solid fa-pen-to-square"></i> -->
                                <!-- </ng-template> -->
                                <!-- </wj-flex-grid-column> -->
                                <wj-flex-grid-column [header]="'Services'" [dataMap]="serviceDataMap" [width]="120"
                                    [binding]="'services'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'PC Enabled'" [width]="120" [binding]="'isPcEnabled'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'Allow report viewing'" [width]="120" [binding]="'AllowReportViewing'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'Action'" align="center" [width]="120" [isReadOnly]="true">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                                        <button id="deleteButton" class="wj-btn remove-background p-0">
                                            <i id="deleteButton" [title]="'Delete'" class="fa fa-trash" (click)="deleteRowFromGrid(assocgrid, row)"></i>
                                        </button>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <div *ngFor="let columnname of columnsAssc">
                                    <wj-flex-grid-column [visible]="columnname.header != 'AssociationID'" [isReadOnly]="true"
                                        [binding]="columnname.binding" width="*" [header]="columnname.header">
                                    </wj-flex-grid-column>
                                </div>
                                <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                            </wj-flex-grid>
                            <div class="pagination-align mt-1">
                                <wj-collection-view-navigator *ngIf="showPaginationNewAssocGrid" headerFormat="Page {currentPage:n0} of {pageCount:n0}"
                                    [byPage]="true" [cv]="assocGridData">
                                </wj-collection-view-navigator>
                                <wj-menu class="ml-2" [(value)]="assocGridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationNewAssocGrid">
                                    <wj-menu-item [value]="0">No Paging</wj-menu-item>
                                    <wj-menu-item [value]="10">10</wj-menu-item>
                                    <wj-menu-item [value]="15">15</wj-menu-item>
                                    <wj-menu-item [value]="30">30</wj-menu-item>
                                    <wj-menu-item [value]="50">50</wj-menu-item>
                                  </wj-menu>
                                <!-- <div class="mt-4 mb-2">
                                    <button mat-raised-button class="admin-btn-success mr-4 float-left" (click)="previousNxtBtn('Associate','prev')">Previous</button>
                                    <button mat-raised-button class="admin-btn-success mr-4 float-left" (click)="previousNxtBtn('Associate','nxt')">Next</button>
                                    <button [disabled]="
                (NPIWarning == true || NPIvalidated == false ) && AddLocRecipientData.invalid" (click)="addNewLocation(AddLocRecipientData.value)"
                    class="btn admin-btn-success ">Save</button> -->
                                <!-- [disabled]="NPIWarning == true || NPIvalidated == false || AddLocRecipientData.invalid" -->
                                <!-- <button
                    (click)="addNewLocation(AddLocRecipientData.value,AddLocRecipientData.controls.NPI.status,AddLocRecipientData.controls.AccountName.status,AddLocRecipientData.controls.DisplayName.status,AddLocRecipientData.controls.ShortName.status,false)"
                    class="btn admin-btn-success  PosSaveBtn btnSavePos">Save</button>  -->
                                <!-- <button mat-raised-button class="admin-btn-success float-right" (click)="AddAssociation()">Save</button>
                                    <button mat-raised-button class="admin-btn-success float-right mr-4" (click)="previousNxtBtn('Associate','can')">Cancel</button>
                                </div>  -->
                            </div>
                            <!-- <div class="row">
        <div class="col-12 d-flex">
          <div class="col-md-6 mt-4 p-0 m-0">
            <div class="sub-button d-flex float-left">
              <button mat-raised-button class="admin-btn-success mr-4" (click)="backAssociation()">
                Back
              </button>
            </div>
          </div>
          <div class="col-sm-6 mt-4 p-0 m-0">
            <div class="sub-button float-right">
              <button mat-raised-button class="admin-btn-success mr-4" (click)="AddAssociation(addeddata)" type="button"
                [disabled]="Edit">
                Save & Close
              </button>
              <button mat-raised-button class="admin-btn-success" (click)="AddAssociation(addeddata,true)" type="button"
                [disabled]="Edit">
                Save & Add
              </button>
            </div>
          </div>
        </div>
      </div> -->

                        </div>
                        <div class="ml-3" *ngIf="existingData.length > 0"><strong>Locations Associated :
                            </strong></div>
                        <div *ngIf="existingData.length > 0" class="col-sm-12 mt-2">
                            <wj-flex-grid #existinggrid [itemsSource]="existingGridData"
                                [allowDelete]="false" [headersVisibility]="'Column'" (initialized)="initEditGrid(existinggrid)">
                                <!-- <wj-flex-grid-column [header]="'Edit'" align="center" [width]="120" [isReadOnly]="true"> -->
                                <!-- <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">

                                <button class="wj-btn remove-background p-0" [disabled]=" recipientData.controls['AssociationType'].value !=''">
                                  <i class="fa fa-pencil-square-o" [title]="'Edit'" (click)="editAssociatingTableRow(row)" aria-hidden="true"></i>
                                </button>
                                <i class="fa-solid fa-pen-to-square"></i> -->
                                <!-- </ng-template> -->
                                <!-- </wj-flex-grid-column> -->
                                <wj-flex-grid-column [header]="'Services'" [dataMap]="serviceDataMap" [width]="120"
                                    [binding]="'services'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'PC Enabled'" [width]="120" [binding]="'isPcEnabled'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'Allow report viewing'" [width]="120" [binding]="'AllowReportViewing'">
                                </wj-flex-grid-column>
                                <wj-flex-grid-column [header]="'Action'" align="center" [width]="120" [isReadOnly]="true">
                                    <ng-template wjFlexGridCellTemplate [cellType]="'Cell'" let-cell="cell" let-row="row">
                                        <button id="deleteButton" class="wj-btn remove-background p-0">
                                            <i id="deleteButton" [title]="'Delete'" class="fa fa-trash" (click)="openDelete(row.dataItem)"></i>
                                        </button>
                                    </ng-template>
                                </wj-flex-grid-column>
                                <div *ngFor="let columnname of columnsAssc">
                                    <wj-flex-grid-column [visible]="columnname.header != 'AssociationID'" [isReadOnly]="true"
                                        [binding]="columnname.binding" width="*" [header]="columnname.header">
                                    </wj-flex-grid-column>
                                </div>
                                <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                            </wj-flex-grid>
                            <div class="pagination-align mt-1">
                                <wj-collection-view-navigator *ngIf="showPaginationExistAssocGrid" headerFormat="Page {currentPage:n0} of {pageCount:n0}"
                                    [byPage]="true" [cv]="existingGridData">
                                </wj-collection-view-navigator>
                                <wj-menu class="ml-2" [(value)]="existingGridData.pageSize" [header]="'Page Size'" *ngIf="showPaginationExistAssocGrid">
                                    <wj-menu-item [value]="0">No Paging</wj-menu-item>
                                    <wj-menu-item [value]="10">10</wj-menu-item>
                                    <wj-menu-item [value]="15">15</wj-menu-item>
                                    <wj-menu-item [value]="30">30</wj-menu-item>
                                    <wj-menu-item [value]="50">50</wj-menu-item>
                                  </wj-menu>
                            </div>
                        </div>
                        <div class="mt-4 mb-2">
                            <button mat-raised-button class="admin-btn-success mr-4" (click)="previousNxtBtn('Associate','prev')">Previous</button>
                            <button mat-raised-button class="admin-btn-success mr-4" (click)="previousNxtBtn('Associate','nxt')">Next</button>
                            <!-- <button [disabled]="
            (NPIWarning == true || NPIvalidated == false ) && AddLocRecipientData.invalid" (click)="addNewLocation(AddLocRecipientData.value)"
                class="btn admin-btn-success ">Save</button> -->
                            <!-- [disabled]="NPIWarning == true || NPIvalidated == false || AddLocRecipientData.invalid" -->
                            <!-- <button
                (click)="addNewLocation(AddLocRecipientData.value,AddLocRecipientData.controls.NPI.status,AddLocRecipientData.controls.AccountName.status,AddLocRecipientData.controls.DisplayName.status,AddLocRecipientData.controls.ShortName.status,false)"
                class="btn admin-btn-success  PosSaveBtn btnSavePos">Save</button>  -->
                            <button mat-raised-button class="admin-btn-success float-right" (click)="AddAssociation()">Save</button>
                            <button mat-raised-button class="admin-btn-success float-right mr-4" (click)="previousNxtBtn('Associate','can')">Cancel</button>
                        </div>
                    </mat-tab>
                    <mat-tab label="CaseType" [disabled]="DisplayCaseType">
                        <ng-template mat-tab-label class="tabSize">
                            <span>Case Type</span>
                        </ng-template>
                        <!-- <div class="mt-3" *ngIf="casetypegrid && casetypecolumnnames.length > 0">
                            <wj-flex-grid [selectionMode]="'ListBox'" #flexgrid style="max-height: 334px;"
                                (initialized)="initializeCaseTypesGrid(flexgrid)" [isReadOnly]="true"
                                [showMarquee]="true" [alternatingRowStep]="0" [itemsSource]="casetypegrid"
                                >
                                <div *ngFor="let columnname of casetypecolumns">
                                    <wj-flex-grid-column
                                        [visible]="!(columnname.header =='Category') && !(columnname.header =='Category Text')"
                                        [binding]="columnname.binding" [header]="columnname.header" [width]="175"
                                        [format]="'d'" aria-expanded="false" [allowResizing]="true">
                                    </wj-flex-grid-column>
                                </div>
                                <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                            </wj-flex-grid>
                        </div>
                        <div class="nodata-wrap mt-4" *ngIf="!casetypegrid || casetypecolumnnames.length < 0">
                            <div class="nodata-design">No Data Found </div>
                        </div> -->
                        <app-casetype-configuration [source]="'NewLocation'" [mode]="ifEdit ? 'edit' : 'create'"
                           [isPOLOrg]="commonService.isPol" [entityType]="entityType" [accountid]="_parseAccountID" (newCasetypes) = "newCaseTypes($event)" (clonedCaseTypes) = "clonedCaseTypes($event)"></app-casetype-configuration>
                        <!-- <div class="sub-button mt-3" *ngIf="!casetypegrid || casetypecolumnnames.length < 0">
                            <button (click)="viewLocation()" class="btn  mr-4">Previous</button>
                        </div>*ngIf="casetypegrid && casetypecolumnnames.length > 0" -->

                    </mat-tab>
                    <!-- Hiding User Tab as per a requirement given in May 2024 - TFS ID : 112977 - Setting Up a Location under a Existing POL Entity -->
                    <mat-tab label="Users" [disabled]="DisplayUsersTab" *ngIf="false">
                        <ng-template mat-tab-label class="tabSize">
                            <span>Users</span>
                        </ng-template>
                        <!-- <div class="mt-3 " *ngIf="usersgrid && userscolumnnames.length > 0"> -->
                        <div class="mt-3" *ngIf="usersgrid && userscolumnnames.length > 0">
                            <wj-flex-grid [selectionMode]="'ListBox'" #grid (initialized)="initializeUsersGrid(grid)"
                                [isReadOnly]="true" [showMarquee]="true" [alternatingRowStep]="0" [itemsSource]="usersgrid">
                                <div *ngFor="let columnname of userscolumns">
                                    <wj-flex-grid-column [binding]="columnname.binding" [header]="columnname.header"
                                        [width]="'*'" [format]="'d'" aria-expanded="false" [allowResizing]="true">
                                    </wj-flex-grid-column>
                                </div>
                                <wj-flex-grid-filter #filter></wj-flex-grid-filter>
                            </wj-flex-grid>
                        </div>
                        <!-- <div class=" mt-4" *ngIf="DisplayAddUser || (!usersgrid && userscolumnnames.length < 0)">
                            <button mat-raised-button class="admin-btn-success" [disabled]="btcreate">Create</button>
                        </div> -->
                        <div class="nodata-wrap mt-4" *ngIf="userscolumnnames.length == 0">
                            <div class="nodata-design"> No Data Found </div>
                        </div>
                        <div class="mt-4 mb-2">
                            <button mat-raised-button class="admin-btn-success mr-4" (click)="previousNxtBtn('Users','prev')">Previous</button>
                            <button mat-raised-button class="admin-btn-success mr-4" (click)="previousNxtBtn('Users','nxt')">Next</button>
                            <button mat-raised-button class="admin-btn-success float-right" (click)="associateUsers(false)">Save</button>
                            <button mat-raised-button class="admin-btn-success float-right mr-4" (click)="previousNxtBtn('Users','can')">Cancel</button>
                        </div>
                        <!-- <div class=" mt-4" *ngIf="DisplayAddUser || (!usersgrid && userscolumnnames.length < 0)">
                            <button mat-raised-button class="admin-btn-success" [disabled]="btcreate">Create</button>
                        </div> -->
                    </mat-tab>
                    <mat-tab label="Logo" [disabled]="DisplayLogoTab">
                        <ng-template mat-tab-label class="tabSize">
                            <span>Logo</span>
                        </ng-template>
                        <form class="row mt-2 minHeight" [formGroup]="myForm">
                            <div class="col-sm-12 upload-wrap">
                                <div class="col-sm-6">
                                    <div class="row">
                                        <label class="upload-sign">Existing Logo</label>
                                    </div>
                                    <div class="row image-heights nodata-wrap" *ngIf="noLogo">
                                        <div class="nodata-design">No Logo Found</div>
                                    </div>
                                    <div class="image-heights" style="width: 94%;" *ngIf="!noLogo">
                                        <img alt="image" style="max-width: 94%;max-height: 100%;" [src]="imageSrc">
                                    </div>
                                </div>
                                <div class="col-sm-6 new-sign-wrap">
                                    <div class="row" style="width: 100%;">
                                        <label class="upload-sign">Upload New Logo</label>
                                    </div>
                                    <div class="row">
                                        <div class="image-heights" style="width: 94%;" appDnd (fileDropped)="onFileDropped($event)"
                                            *ngIf="logoPath">
                                            <input type="file" #fileDropRef id="fileDropRef" (change)="onFileChange($event)"
                                                [multiple]="false" hidden />
                                            <input type="text" #fileDropRef id="fileDropRef" (click)="onFileChange($event)"
                                                [multiple]="false" hidden />
                                            <span class="mb-2">Drag and Drop image here</span>&nbsp;<span class="mb-2">or</span>&nbsp;
                                            <label class="btn-sm btn rounded browse" for="fileDropRef">Browse
                                                Image</label>
                                        </div>
                                        <div class="image-heights" *ngIf="!logoPath" style="width: 94%;">
                                            <img alt="image" style="max-width: 94%;max-height: 100%;" [src]="newLogoSrc"
                                                alt="" srcset="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="mt-4 mb-2">
                            <button mat-raised-button class="admin-btn-success mr-4" (click)="previousNxtBtn('Logo','prev')">Previous</button>
                            <button mat-raised-button class="admin-btn-success float-right" (click)="previousNxtBtn('Logo','nxt')">Finish
                                Setup</button>
                            <button mat-raised-button class="admin-btn-success mr-4" *ngIf="!logoPath" [disabled]="logoPath"
                                (click)="removeNewLogo()">
                                Reset</button>
                            <button mat-raised-button class="admin-btn-success float-right mr-4" *ngIf="!logoPath"
                                (click)="ConfirmUploadLogo(false)">Save</button>
                            <button mat-raised-button class="admin-btn-success float-right mr-4" *ngIf="!logoPath"
                                (click)="previousNxtBtn('Logo','can')">Cancel</button>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>

            <!-------------- OnClick View Details Page ------------------>
            <div *ngIf="ShowGridItemDetails" class="modal-content bordernone">
                <form id="form-data">
                    <div class="modal-header admin-model-header" *ngIf="locGridRowArray && locGridRowArray[0]">
                        <h3 class="modal-title w-100 admin-model-header-txt">
                            <strong>{{this.locGridRowArray[0].displayname}}</strong>
                        </h3>
                    </div>
                    <div class="row mt-3 " *ngIf="locGridRowArray && locGridRowArray[0]">
                        <div class="col-sm-6 colBorder">
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">VitalAxis Code </span>
                                </div>
                                <div class="col-sm-1 text-right">
                                    <img alt="copy" class="copyIcon cursor" (click)="copy(this.GroupCode)" *ngIf="this.GroupCode != 'Not Specified'"
                                    src="/assets/images/copy.svg" />
                                </div>
                                <!-- *ngIf="this.GroupCode != 'Not Specified'" -->
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.GroupCode}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Location logo</span>
                                </div>
                                <div class="col-sm-1"></div>
                                <div class="text-muted col-sm-7" *ngIf="noLogo">
                                    <span class="font-size">No Logo Found</span>
                                </div>
                                <div class="col-sm-7" *ngIf="!noLogo">
                                    <img alt="image" class="img-resize" [src]="imageSrc">
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Location ID </span>
                                </div>
                                <div class="col-sm-1 text-right">
                                    <img alt="copy" class="copyIcon cursor" (click)="copy(this.locGridRowArray[0].accountid)"
                                        *ngIf="this.locGridRowArray[0].accountid != 'Not Specified'" src="/assets/images/copy.svg" />
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].accountid}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Location Name </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].accountname}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Location Type </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].accounttype}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                        <span scope="row" class="label-font-size" aria-readonly="true">Status </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].status.toString().toLowerCase()
                                        ==
                                        'active' ? 'Active' : 'Inactive'}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Mnemonic </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].mnemonic}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">NPI </span>
                                </div>
                                <div class="col-sm-1">
                                    <img alt="copy" class="copyIcon cursor" (click)="copy(this.locGridRowArray[0].NPI)"
                                        *ngIf="this.locGridRowArray[0].NPI.toString().toLowerCase() != 'unknown'" src="/assets/images/copy.svg" />
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].NPI}}</span>
                                </div>
                            </div>
                            <!-- <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">EIN </span>
                                </div>
                                <div class="col-sm-1 text-right">
                                    <img alt="copy" class="copyIcon cursor" (click)="copy(this.locGridRowArray[0].ein)"
                                        *ngIf="this.locGridRowArray[0].ein != 'Not Specified'" src="/assets/images/copy.svg" />
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].ein}}</span>
                                </div>
                            </div> -->
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">CLIA </span>
                                </div>
                                <div class="col-sm-1 text-right">
                                    <img alt="copy" class="copyIcon cursor" (click)="copy(this.locGridRowArray[0].clia)"
                                        *ngIf="this.locGridRowArray[0].clia != 'Not Specified'" src="/assets/images/copy.svg" />
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].clia}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">State License </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].statelicensenumber}}</span>
                                </div>
                            </div>
                            <!--
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                              <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Primary Contact Name
                                    </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].primarycontactname}}</span>
                                </div>

                            </div> -->

                            <!-- <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Primary Contact Ph.
                                    </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].primarycontactphone}}</span>
                                </div>
                            </div> -->
                            <!-- <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Primary Contact Email
                                    </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].primarycontactemail}}</span>
                                </div>
                            </div> -->
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Report Data
                                    </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].ReportData}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Claim Options </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].claimoptions}}</span>
                                </div>
                            </div>

                            <div class="row mt-2 col-sm-12 m-0 p-0" *ngFor="let item of customorgattributrlist">
                                <div class="text-sm col-sm-4 p-0" *ngIf="ContextType!=='Laboratory'">
                                    <span scope="row" class="label-font-size" aria-readonly="true">{{item.Attribute}}
                                    </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7" *ngIf="ContextType!=='Laboratory'">
                                    <span class="font-size text-ellipsis text-left">{{ item.Value || 'Not Specified' }}</span>
                                </div>
                            </div>


                        </div>
                        <div class="col-sm-6">
                            <!-- <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Geo Coordinates
                                    </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].GeoCoordinates}}</span>
                                </div>
                            </div> -->

                            <!-- <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Enable SendOut
                                    </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].EnableSendOutCheckBox}}</span>
                                </div>
                            </div> -->
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Time Zone
                                    </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].TimeZone}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Address </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].address1}} ,
                                        {{this.locGridRowArray[0].address2}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Street </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].street}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">City </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].city}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">State </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].STATE}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">ZIP </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].zip}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Phone </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].phone}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Fax </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].fax}}</span>
                                </div>
                            </div>
                            <!-- <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Reg Location Name
                                    </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].RegAccountName}}</span>
                                </div>
                            </div> -->
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Claim Mnemonic
                                    </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].claimmnemonic}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">CLPOL </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].CLPOL}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Is Shared
                                        Surgicenter
                                    </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].IsSharedSurgicenter}}</span>
                                </div>
                            </div>
                            <!-- <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Pay To Address
                                    </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].PayToAddress1}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Pay To State </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].PayToState}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Pay To Zip </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].PayToZip}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Pay To City </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].PayToCity}}</span>
                                </div>
                            </div> -->
                            <!-- <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Associated Locations
                                    </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size text-ellipsis text-left" title="{{this.assocAccounts}}">{{this.assocAccounts}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Associated Case
                                        Types
                                    </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size text-ellipsis text-left" title="{{this.assocCaseTypes}}">{{this.assocCaseTypes}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Associated Users
                                    </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size text-ellipsis text-left" title="{{this.assocUsers}}">{{this.assocUsers}}</span>
                                </div>
                            </div> -->
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Enable Fax </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].FaxEnable}}</span>
                                </div>
                            </div>
                            <div class="row mt-2 col-sm-12 m-0 p-0">
                                <div class="text-sm col-sm-4 p-0">
                                    <span scope="row" class="label-font-size" aria-readonly="true">Is Listed As OF
                                    </span>
                                </div>
                                <div class="col-sm-1">
                                </div>
                                <div class="text-muted col-sm-7">
                                    <span class="font-size">{{this.locGridRowArray[0].IsListedAsOF}}</span>
                                </div>
                            </div>

                        </div>
                        <!--Action buttons-->
                        <span class="col-sm-12 align-center mt-2">
                            <div class="col-sm-4 button-align">
                                <button mat-raised-button class="admin-btn-success" (click)="back()">All Locations</button>
                                <button mat-raised-button class="admin-btn-success" (click)="exportdata()"
                                    [disabled]="export">Export</button>
                                <button mat-raised-button class="admin-btn-success" (click)="editData()"
                                    [disabled]="btnEdit">Edit</button>
                                <!-- <button (click)="goToAssociationTab()" class="admin-btn-success">Associate</button> -->
                            </div>
                        </span>
                    </div>
                </form>
            </div>


            <div *ngIf="NoDataSetupNewPage" class="nodata-wrap">
                <div class="alignText">
                    <br />
                    <div class="sub-button mt-4">
                        <!-- <button (click)="getDropdownValues()" class="admin-btn-success" [disabled]="btcreate">Add Location</button> -->

                        <button mat-raised-button class="admin-btn-success mr-4" (click)="createBtnClick()" [disabled]="btcreate">Create
                            Location</button>
                        <!-- <button mat-raised-button class="admin-btn-success" [title]="'Associate'" [disabled]="btnCreate"
                            (click)="addNewAssociation()">
                            Associate
                        </button> -->
                    </div>
                </div>
                <div class="nodata-design"> No Data Found </div>
            </div>
        </div>
    </div>
</div>

<!-- Pop Up section -->
<!-- for confirm save -->

<!-- <div class="row p-0 m-0 mt-2">
    <div class="col-12 p-0 m-0">
          The Modal -->
<!-- <div class="modal" id="setupNewFacilityModal" data-backdrop="static" data-keyboard="false">
            <div class="modal-dialog" style="max-width: 1005;border-radius: 15px;">
                <div class="modal-content setupnewfacility-model">
                      Modal Header -->
<!-- <div class="admin-model-header">
                        <h4 class="modal-title p-1">{{tabDisplayName}}</h4>
                    </div> -->
<!-- Modal body -->
<!-- <div class="modal-body" style="text-align: center;font-size:16px">
                        <span class="title-card">
                            Save Changes ?
                        </span><br><span class="title-card">
                            If not saved, any modifications made will be lost.
                        </span><br>
                    </div> -->
<!-- Modal footer -->
<!-- <div class="modal-footer mb-3" style="display: block;margin: auto;">
                        <button type="button" class="admin-btn-success p-2" (click)="saveNotSave()">Save and
                            Proceed</button>
                        <button type="button" class="admin-btn-success p-2" (click)="saveNotSave()">Proceed
                            without Saving</button>
                        <button type="button" class="admin-btn-success p-2" (click)="closeModal()">Stay in this
                            Page</button>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
<!-- </div> -->







<div class="model-dialog row p-0 m-0 mt-2 staticdrop" *ngIf="staticBackdrop"  data-backdrop="static" data-keyboard="false">
    <div class="col-12 p-0 m-0">
        <!-- The Modal -->
        <div class="col-sm-12 align-center modal" style="background: #393d406b;">
            <div class="modal-content setupnewLocation-model">
                <!-- Modal Header -->
                <div class="admin-model-header">
                    <h4 class="modal-title p-1">{{tabDisplayName}}</h4>
                </div>
                <!-- Modal body -->
                <div class="modal-body" style="text-align: center;font-size:16px">
                    <span class="title-card">
                      Save Changes ?
                    </span><br><span class="title-card">
                        If not saved, any modifications made will be lost.
                    </span><br>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer mb-4" style="display: flex;">
                    <button mat-raised-button class="admin-btn-success mr-4" type="button" (click)="saveNotSave('save')">Save
                        and
                        Proceed</button>
                    <button mat-raised-button class="admin-btn-success mr-4" type="button" (click)="saveNotSave('notsave')">Proceed
                        without Saving</button>
                    <button mat-raised-button class="admin-btn-success" type="button" (click)="closeModal()">Stay
                        in
                        this
                        Page</button>
                    </div>
            </div>
        </div>
    </div>
</div>


<div class="model-dialog row p-0 m-0 mt-2" *ngIf="deleteAssoc"  data-backdrop="static" data-keyboard="false">
    <div class="col-12 p-0 m-0">
        <!-- The Modal -->
        <div class="col-sm-12 align-center modal" style="background: #393d406b;">
            <div class="modal-content setupnewLocation-model">
                <!-- Modal Header -->
                <div class="admin-model-header">
                    <h4 class="modal-title p-1">Delete Association</h4>
                </div>
                <!-- Modal body -->
                <div class="modal-body" style="text-align: center;font-size:16px">
                    <span class="title-card">
                        Clicking on yes will delete this association.
                    </span><br><span class="title-card">
                        Do you wish to continue?
                    </span><br>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer mb-4" style="display: flex;margin: auto;">
                    <button mat-raised-button class="admin-btn-success mr-4" type="button" (click)="deleteAssociation('save')">Yes</button>
                        <button mat-raised-button class="admin-btn-success mr-4" type="button" (click)="deleteAssociation('notsave')">No</button>
                    </div>
            </div>
        </div>
    </div>
</div>
