<div class="modal-content">
    <div class="modal-header admin-model-header">
        <h3 class="modal-title w-100 admin-model-header-txt">Entity Details</h3>
        <span type="button" data-dismiss="modal" (click)="exportCaseClose()" class="cursor mr-2">
            <img src="../../../../assets/icons/Close_Icon.svg" alt="Close" class="material-icons md-24"
                title="Close">
        </span>
    </div>
    <div class="modal-body">
        <form [formGroup]="ExportCasegroup">
            <div class="export-case-height" >
                <!-- Modal Header -->
            

                <!-- Modal body -->
                <div class="mt-2 mb-2">
                    <div class="container-fluid">
                        <div class="row font-size">
                            <mat-form-field class="col-sm-2 example-additional-selection" appearance="outline">
                                <mat-label><strong>Deployment<span class="error-msg">*</span></strong></mat-label>
                                <mat-select disableOptionCentering formControlName="DeploymentKey">
                                    <mat-option value="none" disabled selected>--Select--</mat-option>
                                    <ng-container *ngFor="let i of DeploymentKeys">
                                        <mat-option class="mat-opt-align" value="{{i.toString().toUpperCase()}}">{{ i }}
                                        </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="col-sm-2 example-additional-selection" appearance="outline">
                                <mat-label> <span class="error-msg">*</span>Criteria</mat-label>
                                <mat-select name="drpQSearch" id="drpQSearch" disableOptionCentering
                                    formControlName="QuickSearch">
                                    <mat-option class="mat-opt-align" value="caseid">Case ID</mat-option>
                                    <mat-option class="mat-opt-align" value="supplyorderid">Supply Order ID</mat-option>
                                    <mat-option class="mat-opt-align" value="organizationid">Group ID</mat-option>
                                    <mat-option class="mat-opt-align" value="accountid">Location ID</mat-option>
                                    <mat-option class="mat-opt-align" value="userid">User ID</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field class="col-md-3 example-full-width" appearance="outline">
                                <input type="text" id="inpQSearch" autocomplete="off" name="inpQSearch"
                                    placeholder="Enter Text" matInput formControlName="ExportCaseSearchInput">
                            </mat-form-field>

                            <div class="col-md-3 mt-2">
                                <mat-checkbox [color]="task.color" formControlName="ConnectionName" value="ReportDB"
                                    class="example-margin">
                                    Use IF EXISTS Construct</mat-checkbox>
                            </div>
                            <div class="row col-sm-2">
                                <div class="dml-btn">
                                    <button mat-raised-button class="admin-btn-success"
                                        [disabled]="ExportCasegroup.value.ExportCaseSearchInput == '' ? true : null"
                                        (click)=generateDML(ExportCasegroup.value)>Generate DML</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="border-style script-wrap" *ngIf="methodHit">
                        <div class="nodata-wrap mt-4" *ngIf="nodata">
                            <div class="nodata-design">No Data Found</div>
                        </div>
                        <div *ngIf="!nodata && ExportCasegroup.value.ExportCaseGenerateDML">
                             <div *ngIf="ExportCasegroup.value.ExportCaseGenerateDML" style="height:55vh; overflow:auto; border: 1px solid #ccc; padding: 10px; background-color: #f4f4f4;">
                                <pre>{{ ExportCasegroup.value.ExportCaseGenerateDML }}</pre>
                            </div>

                            <div class="row example-button-container align-center mt-2">
                                <button *ngIf="roledata" class="admin-btn-success" (click)="copyData(roledata)"
                                    title="Copy to Clipboard" mat-raised-button aria-label="Copy">Copy Script
                                </button>

                                <div class="align-center ml-2" *ngIf="!nodata">
                                    <button (click)="downloadData()" mat-raised-button title="Download SQL"
                                        class="admin-btn-success"> Download</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
