import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import * as wjcCore from '@grapecity/wijmo';
import { CollectionView, PopupPosition, Tooltip } from '@grapecity/wijmo';
import * as wjcGrid from '@grapecity/wijmo.grid';
import { FlexGrid, FormatItemEventArgs } from '@grapecity/wijmo.grid';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, Observer } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonService } from '../../../core/services/commonservices';
import { VitalHttpServices } from '../../../core/services/VitalHttpServices';
import { ActivityTrackerService } from 'src/app/core/services/activity-tracker.service';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ManageJsonEditorComponent } from '../manage-json-editor/manage-json-editor.component';

declare var $;
export interface VaEntityAttributesTab {
  label: string;
  content: string;
}

export interface Task {
  color: ThemePalette;
}
@Component({
  selector: 'app-va-entity-attributes',
  templateUrl: './va-entity-attributes.component.html',
  styleUrls: ['./va-entity-attributes.component.scss']
})

export class VaEntityAttributesComponent implements OnInit {

  task: Task = {
    color: 'primary',
  };

  searchEntityAttribute: boolean;
  @ViewChild('txtInput') txtInput: ElementRef;
  @ViewChild('myTabGroup') tabGroup: MatTabGroup;
  //templateData: [any];
  @Input() templateData: any;
  asyncTabs: Observable<VaEntityAttributesTab[]>;
  selectedTab = new FormControl(0);
  // templateData1: any;
  // templateData2: any;
  tempExport: any[];
  gridheader = [
    'refconfigname',
    'id',
    "scope",
    'OrgVeId',
    'AccountVeID',
    'EntityId',
    'EntityType',
    'category',
    'validvalues',
    'defaultvalue',
    'This_Location_Value_2',
    "All_Locations_Value_2",
    "Deployment_Level_Value_2",
    'Default_Configuration_2',
    'This_Location_Value_1',
    "All_Locations_Value_1",
    'Deployment_Level__Value_1',
    'Default_Configuration_1',
    'Configuration_Level',
    'Description',
    'Name',
  ];
  gridheader1 = ['Description', 'Name'];
  columns = [
    new ColDef('Description', 'Description'),
    new ColDef('Name', 'Name')
  ];
  filteredOptions: Observable<any[]>;
  filterdConfig: Observable<any[]>;
  filterdConfig2: Observable<any[]>;
  gridData: CollectionView<any>;
  showGrid: boolean;
  editAttribute: boolean;
  Attr1LooseDropDown: any;
  Attr1StrictDropDown: boolean;
  Attr1Freetext: boolean;
  Attr2LooseDropDown: any;
  Attr2StrictDropDown: boolean;
  Attr2Freetext: boolean;
  frmEntityAttribute = this._fb.group({
    name: '',
    configValue1: '',
    configValue2: '',
    scope: ''
  })

  configname = new FormControl();

  configurationDropdownvalue: any;
  createEntity: boolean;
  tempObj: any;
  searchedValue: any;
  entityName: any;
  attributeInfo: any;
  attributesName: any = [];
  filterdConfigvalue1: any = [];
  editEntity: boolean;
  showConfigFields: boolean;
  dropdownValues: any;
  dropdownValues2: any[];
  //finalarray = []
  txtvalue: any;
  disable: boolean;
  textview: any = -1;
  checkNullforConfig1: any;
  checkNullforConfig2: any;
  fieldNameCongiguration1: any;
  fieldNameCongiguration2: any;
  disableEditBn: boolean;
  filterdConfigvalue2: any = [];
  hideExportBtn: boolean;
  hideCreateBtn: boolean;
  hideEditBtn: boolean;
  oldObject: { ExtAttrValue1: any; ExtAttrValue2: any; };
  allowMultiple: boolean;
  addButtonEnable: boolean;
  accountLevel: boolean;
  orgLevel: boolean;
  supportBoth: boolean;
  tableName: any;
  attrValidValue: any;
  rowObject: any;
  gridValueShowing: boolean;
  flagCategoryListData: any;
  gridwidth: number;
  gridData2: CollectionView<any>;
  gridwidth1: number;
  gridarray1: any;
  showviewflaggrid: boolean = false;
  tabList: any = [];
  searchData: any;
  deployment: string;
  intstMsg: any;
  intstMsg1: any;
  regex1: any;
  regex: any;
  auditableColumns: any;
  activityEntity: any;
  templateDataCopy: any;
  action: boolean = false;
  orglevelFinalstate: boolean;
  orglevelFinalstate1: boolean;
  orglevelFinalstate2: boolean = false;
  accountlevelFinalstate: boolean = false;
  accountlevelFinalstate2: boolean = false;
  accountlevelFinalstate1: boolean = false;
  hidethislocation: boolean = false;
  finalstateforthislocation: boolean;
  finalstateforthislocation1: boolean = false;
  finalstateforthislocation2: boolean = false;
  finalstateforalllocation1: boolean = false;
  finalstateforalllocation2: boolean = false;
  finalstateforalllocation: boolean;
  deploymentLevel: boolean = false;
  //configInAccountsTable: boolean;


  constructor(private ngxService: NgxUiLoaderService, private _snackbar: MatSnackBar,
    private vitalHttpServices: VitalHttpServices, private commonService: CommonService,
    private _fb: FormBuilder, public activityService: ActivityTrackerService,private dialog: MatDialog) {

  }


  ngOnInit(): void {
    this.deployment = sessionStorage.getItem('deploymentKey').toUpperCase();
    this.GetButtondetails();
    this.searchEntityAttribute = true;
    this.getConfigname();
    this.getAuditableDetails(this.templateData.menuURL);
    this.templateDataCopy = this.templateData;
    this.activityService.setActivitySession({ 'entityId': '', 'entityType': this.templateData.menuURL, 'context': [{ 'key': 'parentMenu', 'value': this.templateData.menuURL }] })
    this.activityService.getActivitySession.subscribe(res => this.activityEntity = res);
  }
  getConfigname() {
    let query = this.vitalHttpServices.GetQuery('getAttributeName');
    let queryVariable = {
      scope: "flags",
      filterParameter: "refconfigtarget = \"organizations\" or refconfigtarget = \"VaEntityExtAttributes\" or refconfigtarget=\"Accounts\""
    }
    let queryResult = this.commonService.GetCardRequest(queryVariable, query);
    this.ngxService.start();
    this.vitalHttpServices.GetData(queryResult, 'configdb').subscribe((Resdata) => {
      this.ngxService.stop();
      if (!Resdata.errors) {
        this.flagCategoryListData = JSON.parse(JSON.stringify(Resdata.data.submenuData));
        this.attributesName = Resdata.data.submenuData;
        // this.getInfoAboutSelectedAttribute(row.dataItem.refconfigname);
        this.filteredOptions = this.configname.valueChanges
          .pipe(
            startWith(''),
            map(user => user && typeof user === 'object' ? user.configname || user.name : user),
            map((name: string) => name ? this.filterName(name) : this.attributesName.slice())
          );
      }
    }, error => {
      console.error(error);
    });
  }


  GetButtondetails() {
    this.GetButtonAccess(this.vitalHttpServices.SubmenuAction);
    // this.DataShare.SubmenuAction.subscribe((data) => {
    //   if (data) {
    //     // this.actionButtonDetails = data;
    //     this.GetButtonAccess(data);
    //   }
    // });
  }
  GetButtonAccess(actionButtonDetails) {
    let seletedMenuPermissions = actionButtonDetails.find(e => e.Htext == this.templateData.headerText)['SubMenu'].find(ele => ele.URL == this.templateData.menuURL)['ActionButton'];
    for (var i = 0; i < seletedMenuPermissions.length; i++) {
      switch (seletedMenuPermissions[i].Button) {
        case 'Export':
          this.hideExportBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Create':
          this.hideCreateBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
        case 'Edit':
          this.hideEditBtn = seletedMenuPermissions[i].IsPermitted == "true" ? false : true;
          break;
      }
    }
  }

  getEntityAttributeList(data) {

    // let config1 =  ;
    let value = data.source ? data.source.value.match(/\(([^)]+)\)/)[1] : data
    if (data.source && data.source._selected == false) {
      return;
    }

    if (value.length > 2) {
      this.searchData = value;
      //this.configInAccountsTable=false;
      let temptableName = this.attributesName.filter(va => va.refconfigname == value)
      this.tableName = temptableName[0].refconfigtarget;// to getting the table name which that configuration belongs to
      this.searchedValue = value;
      let obj = {
        entityid: this.tableName && this.tableName.toLowerCase() != 'organizations' ? sessionStorage.getItem('AccountID') : sessionStorage.getItem('org_id'),
        name: value,
        entytitype: this.tableName && this.tableName.toLowerCase() != 'organizations' ? 3 : 2,
        ConfigName: value,
        scope: 3,
        tableName: this.tableName,
      }
      this.ngxService.start();
      this.activityEntity.entityId = obj.name;
      this.activityService.setActivitySession(this.activityEntity);
      this.vitalHttpServices.getVAEntityAttribute(obj, this.deployment).subscribe((res) => {
        this.ngxService.stop();
        if (res.Success) {
          //this.ngxService.stop();
          //this._snackbar.open(res.Message, 'Close');
          this.templateData = JSON.parse(res.Message);
          if (this.templateData.length > 0) {
            this.addGridData(this.templateData);
          }
          else {
            let obj1 = {
              name: value
            }
            this.vitalHttpServices.getOtherVAEntityAttribute(obj1, 'configdb').subscribe((res) => {
              if (res.Success) {
                this.templateData = JSON.parse(res.Message);
                this.addGridData(this.templateData);
              }
            }, error => {
              // Change position in view level if update fail
              this._snackbar.open("An error occurred while processing your request", "Failed");
            });

          }
        }
      }, error => {
        // Change position in view level if update fail
        this.ngxService.stop();
        this._snackbar.open("An error occurred while processing your request", "Failed");
      });
    }
  }

  addGridData(templateData) {
    this.frmEntityAttribute.controls['configValue2'].enable(); 
    this.frmEntityAttribute.controls['configValue1'].enable(); 
    this.orglevelFinalstate = false;
    this.orglevelFinalstate1 = false;
    this.orglevelFinalstate2 = false;
    this.finalstateforthislocation = false;
    this.finalstateforalllocation = false;
    this.accountlevelFinalstate = false;
    this.accountlevelFinalstate1 = false;
    this.accountlevelFinalstate2 = false;
    this.finalstateforthislocation1 = false;
    this.finalstateforthislocation2 = false;
    this.finalstateforalllocation1 = false;
    this.finalstateforalllocation2 = false;
    this.addButtonEnable = false;
    this.showGrid = true;
    this.deploymentLevel = false;
    this.searchEntityAttribute = true;
    let gridarray = [];
    let primary = {};
    let tempvalid1 = '';
    let tempvalid2 = '';
    if (templateData && templateData.length > 0) {
      this.tempExport = [...templateData];
      if (templateData[0].Configuration_Level == 'Deployment Level') {
        this.deploymentLevel = true;
      }
      for (let i = 0; i < templateData.length; i++) {
        primary = {};
        for (let [key, value] of Object.entries(templateData[i])) {
          for (let j = 0; j < this.gridheader.length; j++) {
            if (key.toLowerCase() === this.gridheader[j].toLowerCase()) {
              // if (value == null || value.toString() == 'null' || value.toString().trim() == '') {
              //   value = 'Not Specified';
              // }
              if (key.toLowerCase() == 'validvalues') {
                this.attrValidValue = JSON.parse(value.toString());
                this.gridValueShowing = this.attrValidValue.AttributeFields[1] && this.attrValidValue.AttributeFields[1].datatype && this.attrValidValue.AttributeFields[1].datatype.toLowerCase() != 'not set' ? true : false;
              }
              //to check orglevel finalstate
              if (key.toLowerCase() == 'validvalues') {
                for (let i = 0; i < this.attrValidValue.AttributeFields.length; i++) {
                  if (this.attrValidValue.AttributeFields[i].scope == 2) {
                    for (let j = 0; j < this.attrValidValue.AttributeFields[i].datasource.length; j++) {
                      for (let [keyvalue, value] of Object.entries(this.attrValidValue.AttributeFields[i].datasource[j])) {
                        if (this.attrValidValue.AttributeFields[i].alllocationfinalstate) {
                          if (templateData[0].All_Locations_Value_1 == value) {
                            tempvalid1 = keyvalue;
                          }
                          let data = this.searchData;
                          let temptableName = this.attributesName.filter(va => va.refconfigname == data)
                          this.tableName = temptableName[0].refconfigtarget;//
                          if (this.tableName.toLowerCase() != 'organizations') {
                            if (templateData[0].All_Locations_Value_2 == value) {
                              tempvalid2 = keyvalue;
                            }
                          }
                          if (tempvalid1 != '' && tempvalid1.toLowerCase() == this.attrValidValue.AttributeFields[i].alllocationfinalstate.toLowerCase()) {
                            this.orglevelFinalstate1 = true;
                              if (this.attrValidValue.AttributeFields[i].datatype.toLowerCase() == 'strict dropdown') {
                                this.frmEntityAttribute.controls['configValue1'].disable();
                            }
                          }
                          else {
                            this.frmEntityAttribute.controls['configValue1'].enable();
                          }

                          if (tempvalid2 != '' && tempvalid2.toLowerCase() == this.attrValidValue.AttributeFields[i].alllocationfinalstate.toLowerCase()) {
                            this.orglevelFinalstate2 = true;
                              if (this.attrValidValue.AttributeFields[i].datatype.toLowerCase() == 'strict dropdown' ) {
                                this.frmEntityAttribute.controls['configValue2'].disable();
                            }

                          }
                          else {
                            this.frmEntityAttribute.controls['configValue2'].enable();
                          }
                          if (this.orglevelFinalstate1 && this.orglevelFinalstate2) {
                            this.orglevelFinalstate = true;
                          }
                        }
                      }
                    }

                  }
                  //to check account level finalsate
                  if (this.attrValidValue.AttributeFields[i].scope == 3) {
                    for (let j = 0; j < this.attrValidValue.AttributeFields[i].datasource.length; j++) {
                      for (let [keyvalue, value] of Object.entries(this.attrValidValue.AttributeFields[i].datasource[j])) {
                        if (this.attrValidValue.AttributeFields[i].thislocationfinalstate) {
                          if (templateData[0].This_Location_Value_1 == value) {
                            tempvalid1 = keyvalue;
                          }
                          if (templateData[0].This_Location_Value_2 == value) {
                            tempvalid2 = keyvalue;
                          }
                          if (tempvalid1 != '' && tempvalid1.toLowerCase() == this.attrValidValue.AttributeFields[i].thislocationfinalstate.toLowerCase()) {
                            this.accountlevelFinalstate1 = true;
                              if (this.attrValidValue.AttributeFields[i].datatype.toLowerCase() == 'strict dropdown') {
                                this.frmEntityAttribute.controls['configValue1'].disable();
                            }
                          }
                          else{
                            this.frmEntityAttribute.controls['configValue1'].enable(); 
                          }
                          if (tempvalid2 != '' && tempvalid2.toLowerCase() == this.attrValidValue.AttributeFields[i].thislocationfinalstate.toLowerCase()) {
                            this.accountlevelFinalstate2 = true;
                              if (this.attrValidValue.AttributeFields[i].datatype.toLowerCase() == 'strict dropdown') {
                                this.frmEntityAttribute.controls['configValue2'].disable();
                            }
                          }
                          else{
                            this.frmEntityAttribute.controls['configValue2'].enable(); 
                          }
                          if (this.accountlevelFinalstate1 && this.accountlevelFinalstate2) {
                            this.accountlevelFinalstate = true;
                          }                          
                        }
                      }
                    }

                  }
                   //to check both org and account level finalsate
                  if (this.attrValidValue.AttributeFields[i].scope === 'both') {
                    for (let j = 0; j < this.attrValidValue.AttributeFields[i].datasource.length; j++) {
                      for (let [keyvalue, value] of Object.entries(this.attrValidValue.AttributeFields[i].datasource[j])) {
                        if (this.attrValidValue.AttributeFields[i].thislocationfinalstate) {
                          if (templateData[0].This_Location_Value_1 == value) {
                            tempvalid1 = keyvalue;
                          }
                          if (templateData[0].This_Location_Value_2 == value) {
                            tempvalid2 = keyvalue;
                          }
                          if (tempvalid1 != '' && tempvalid1.toLowerCase() == this.attrValidValue.AttributeFields[i].thislocationfinalstate.toLowerCase()) {
                            this.finalstateforthislocation1 = true;
                              if (this.attrValidValue.AttributeFields[i].datatype.toLowerCase() == 'strict dropdown') {
                                this.frmEntityAttribute.controls['configValue1'].disable();
                            }
                            else{
                              this.frmEntityAttribute.controls['configValue1'].enable(); 
                            }
                            }
                          if (tempvalid2 != '' && tempvalid2.toLowerCase() == this.attrValidValue.AttributeFields[i].thislocationfinalstate.toLowerCase()) {
                            this.finalstateforthislocation2 = true;
                              if (this.attrValidValue.AttributeFields[i].datatype.toLowerCase() == 'strict dropdown') {
                                this.frmEntityAttribute.controls['configValue2'].disable();
                            }
                            else{
                              this.frmEntityAttribute.controls['configValue2'].enable(); 
                            }
                          }
                          if (this.finalstateforthislocation1 && this.finalstateforthislocation2) {
                            this.finalstateforthislocation = true;
                          }

                          // else {                              
                          //   this.frmEntityAttribute.controls['configValue1'].enable(); 
                          //   this.frmEntityAttribute.controls['configValue2'].enable();
                          // }
                        }

                        if (this.attrValidValue.AttributeFields[i].alllocationfinalstate) {
                          if (templateData[0].All_Locations_Value_1 == value) {
                            tempvalid1 = keyvalue;
                          }
                          if (templateData[0].All_Locations_Value_2 == value) {
                            tempvalid2 = keyvalue;
                          }
                          if (tempvalid1 != '' && tempvalid1.toLowerCase() == this.attrValidValue.AttributeFields[i].alllocationfinalstate.toLowerCase()) {
                            this.finalstateforalllocation1 = true;
                              if (this.attrValidValue.AttributeFields[i].datatype.toLowerCase() == 'strict dropdown') {
                                this.frmEntityAttribute.controls['configValue1'].disable();
                            }
                          }
                          else{
                            this.frmEntityAttribute.controls['configValue1'].enable(); 
                          }
                          if (tempvalid2 != '' && tempvalid2.toLowerCase() == this.attrValidValue.AttributeFields[i].alllocationfinalstate.toLowerCase()) {
                            this.finalstateforalllocation2 = true;
                              if (this.attrValidValue.AttributeFields[i].datatype.toLowerCase() == 'strict dropdown') {
                                this.frmEntityAttribute.controls['configValue2'].disable();
                            }
                          }
                          else{
                            this.frmEntityAttribute.controls['configValue2'].enable(); 
                          }
                          if (this.finalstateforalllocation1 && this.finalstateforalllocation2) {
                            this.finalstateforalllocation = true;
                          }

                        }
                        // else {                              
                        //   this.frmEntityAttribute.controls['configValue1'].enable(); 
                        //   this.frmEntityAttribute.controls['configValue2'].enable();
                        // }

                      }
                    }
                  }
                  //to hide thislocation for orglevel
                  if (this.attrValidValue.AttributeFields[i].scope == 2) {
                    this.hidethislocation = true;
                  }
                  else{
                    this.hidethislocation=false;
                  }
                }
              }
              primary[key] = value;
            }
          }
        }
        gridarray.push(primary);
      }
      // gridarray.sort((a, b) => a.Sequence > b.Sequence ? 1 : -1)
      this.gridData = new CollectionView(gridarray)
    }
  }
  private _hdrTips = new Tooltip({
    position: PopupPosition.RightTop,
    showAtMouse: true,
    showDelay: 600,
    cssClass: 'hdr-tip'
  });

  formatItem(s: FlexGrid, e: FormatItemEventArgs) {
    if (e.panel == s.columnHeaders) {
      this._hdrTips.setTooltip(e.cell, e.getColumn().header);
    }
  }
  //  fixGridRowSize(grid){
  // 	grid.autoSizeRows(0,grid.rows.length,false);
  // }
  initGrid(flexgrid) {
    // this.fixGridRowSize(flexgrid);
    // flexgrid.rows[1].height=200;
    const tt = new wjcCore.Tooltip();
    flexgrid.formatItem.addHandler((s, e) => {
      if (e.panel.cellType !== wjcGrid.CellType.Cell)
        return;
      tt.hideDelay = 9999999;
      if (s.getCellData(e.row, e.col) != null)
        tt.setTooltip(e.cell, `${s.getCellData(e.row, e.col)}`);
    });
  }

  editEntityAttribute(row) {
    this.accountLevel = false;
    this.orgLevel = false
    if (row.dataItem.EntityType == 3) {
      this.accountLevel = true;

    }
    else if (row.dataItem.EntityType == 2) {
      this.orgLevel = true;
    }
    this.searchEntityAttribute = false;
    this.showGrid = false;
    this.editAttribute = true;
    this.createEntity = false;
    this.editEntity = true;
    this.entityName = row.dataItem.Name;
    this.rowObject = row;
    this.tempObj = row.dataItem;// assigning to tempobj for sending to api
    this.attrValidValue = JSON.parse(row.dataItem.validvalues);
    let attrDefValue: any
    let attrDefValue2: any
    let TempattrDefValue: any = this.attrValidValue;
    attrDefValue = TempattrDefValue.AttributeFields[0].datasource;
    attrDefValue2 = TempattrDefValue.AttributeFields.length > 1 && TempattrDefValue.AttributeFields[1].datasource ? TempattrDefValue.AttributeFields[1].datasource : null;
    // let attrDefValue = JSON.parse(row.dataItem.defaultvalue)
    this.dropdownValues = []
    if (this.attrValidValue.AttributeFields[0].datatype) {
      this.checkNullforConfig1 = this.attrValidValue.AttributeFields[0].nullable === 'True' ? true : false;
      this.supportBoth = this.attrValidValue.AttributeFields[0].scope == "both" ? true : false;
      this.intstMsg = this.attrValidValue.AttributeFields[0].configinstruction;
      this.regex = this.attrValidValue.AttributeFields[0].regex;
      this.fieldNameCongiguration1 = this.attrValidValue.AttributeFields[0].fieldname;
      this.fieldNameCongiguration2 = this.attrValidValue.AttributeFields[1] && this.attrValidValue.AttributeFields[1].fieldname ? this.attrValidValue.AttributeFields[1].fieldname : null;
      if (this.attrValidValue.AttributeFields[0].datatype.toLowerCase() == 'dropdown') {
        this.Attr1LooseDropDown = true;
        this.filterdConfigvalue1 = [];
        for (let i = 0; i < attrDefValue.length; i++) {
          for (let [key, value] of Object.entries(attrDefValue[i])) {
            let tempConfig = [];
            tempConfig["key"] = key;
            tempConfig["value"] = value;
            this.filterdConfigvalue1.push(tempConfig);
          }
        }
        // this.filterdConfigvalue1 = attrDefValue

        this.filterdConfig = this.frmEntityAttribute.controls.configValue1.valueChanges
          .pipe(
            startWith(''),
            map((user: any) => user && typeof user === 'object' ? user.configValue1 : user),
            map((name: string) => name ? this.filterConfig(name) : this.filterdConfigvalue1.slice())
          );
      }
      else if (this.attrValidValue.AttributeFields[0].datatype.toLowerCase() == 'strict dropdown') {
        this.Attr1StrictDropDown = true;
        this.dropdownValues = [];
        for (let i = 0; i < attrDefValue.length; i++) {
          for (let [key, value] of Object.entries(attrDefValue[i])) {
            let tempConfig = [];
            tempConfig["key"] = key;
            tempConfig["value"] = value;
            this.dropdownValues.push(tempConfig);
          }
        }
        // this.dropdownValues = attrDefValue;
      }
      else if (this.attrValidValue.AttributeFields[0].datatype.toLowerCase() == 'free text') {
        this.Attr1Freetext = true;
      }
    }
    if (this.attrValidValue.AttributeFields[1] && this.attrValidValue.AttributeFields[1].datatype) {
      this.intstMsg1 = this.attrValidValue.AttributeFields[1].configinstruction;
      this.regex1 = this.attrValidValue.AttributeFields[1].regex;
      this.gridValueShowing = this.attrValidValue.AttributeFields[1].datatype.toLowerCase() == 'not set' ? true : false; // for showing only configuration 1 value
      this.checkNullforConfig2 = this.attrValidValue.AttributeFields[1].nullable === 'True' ? true : false;
      if (this.attrValidValue.AttributeFields[1].datatype.toLowerCase() == 'dropdown') {
        this.filterdConfigvalue2 = [];
        this.Attr2LooseDropDown = true;
        for (let i = 0; i < attrDefValue2.length; i++) {
          for (let [key, value] of Object.entries(attrDefValue2[i])) {
            let tempConfig = [];
            tempConfig["key"] = key;
            tempConfig["value"] = value;
            this.filterdConfigvalue2.push(tempConfig);
          }
        }
        //this.dropdownValues2 = attrDefValue.AttributeFields;1        this.filterdConfigvalue2 = attrDefValue2
        this.filterdConfig2 = this.frmEntityAttribute.controls.configValue2.valueChanges
          .pipe(
            startWith(''),
            map((user: any) => user && typeof user === 'object' ? user.configValue2 : user),
            map((name: string) => name ? this.filterConfig2(name) : this.filterdConfigvalue2.slice())
          );
      }
      else if (this.attrValidValue.AttributeFields[1].datatype.toLowerCase() == 'strict dropdown') {
        this.dropdownValues2 = [];
        this.Attr2StrictDropDown = true;
        for (let i = 0; i < attrDefValue2.length; i++) {
          for (let [key, value] of Object.entries(attrDefValue2[i])) {
            let tempConfig = [];
            tempConfig["key"] = key;
            tempConfig["value"] = value;
            this.dropdownValues2.push(tempConfig);
          }
        }
        // this.dropdownValues2 = attrDefValue2;
      }
      else if (this.attrValidValue.AttributeFields[1].datatype.toLowerCase() == 'free text') {
        this.Attr2Freetext = true;
      }
    }
    let scope;
    scope = row.dataItem.scope ? row.dataItem.scope == 'both' ? 3 : row.dataItem.scope : row.dataItem.EntityType
    if (scope == '3') {
      this.frmEntityAttribute.patchValue({
        configValue1: row.dataItem.This_Location_Value_1 == 'N/A' ? 'Not set' : row.dataItem.This_Location_Value_1,
        configValue2: row.dataItem.This_Location_Value_2 == 'N/A' ? 'Not set' : row.dataItem.This_Location_Value_2,
        scope: scope.toString(),
      })
      this.oldObject = row.dataItem;
    }
    else {
      this.frmEntityAttribute.patchValue({
        configValue1: row.dataItem.All_Locations_Value_1 == 'N/A' ? 'Not set' : row.dataItem.All_Locations_Value_1,
        configValue2: row.dataItem.All_Locations_Value_2 == 'N/A' ? 'Not set' : row.dataItem.All_Locations_Value_2,
        scope: scope.toString(),
      })
      this.oldObject = row.dataItem;
    }

  }

  editEntityExtAttribute(values) {
    this.ngxService.start()
    let value = this.frmEntityAttribute.getRawValue(); // to get the disabled data
    try {
      if (value.scope == '2') {
        this.tempObj.EntityId = this.tempObj.EntityId ? this.tempObj.EntityId : sessionStorage.getItem('org_id')
        //this.tempObj.EntityType?this.tempObj.EntityType:value.scope
      }
      else {
        this.tempObj.EntityId = this.tempObj.EntityId ? this.tempObj.EntityId : sessionStorage.getItem('AccountID')
        //this.tempObj.EntityType?this.tempObj.EntityType:value.scope
      }

      if (this.hideEditBtn) {
        this._snackbar.open('User is not Authorized', 'Close');
        this.ngxService.stop();
        return;
      }

      //this.validateData(value);
      let config1 = this.Attr1Freetext ? value.configValue1 : value.configValue1.match(/\(([^)]+)\)/) && value.configValue1 != 'N/A' ? value.configValue1.match(/\(([^)]+)\)/)[1] : value.configValue1;
      let config2 = this.Attr2Freetext ? value.configValue2 : value.configValue2 && value.configValue2.match(/\(([^)]+)\)/) && value.configValue2 != 'N/A' ? value.configValue2.match(/\(([^)]+)\)/)[1] : value.configValue2;
      if ((!this.checkNullforConfig1 && value.configValue1.trim() == '') || (!this.checkNullforConfig2 && value.configValue2 && value.configValue2.trim() == '')) {
        this._snackbar.open('Please enter required data!', 'Close');
        this.ngxService.stop()
        return;
      }
      if (this.regex) {
        let reg = new RegExp(this.regex)
        if (!reg.test(config1.toUpperCase())) {
          this._snackbar.open('Please enter valid input!', 'Close');
          this.ngxService.stop();
          return;
        }
      }
      if (!value.configValue2 || (value.configValue2 && value.configValue2 == 'N/A')) {
        config2 = 'Not Set'
      }

      if (value.configValue1 && (value.configValue1 == 'N/A' || value.configValue1.toLowerCase() == 'not set')) {
        this._snackbar.open('Please enter valid data!', 'Close');
        this.ngxService.stop()
        return;
      }
      let attrValidValue = this.attrValidValue.AttributeFields[0].datasource
      for (let i = 0; i < attrValidValue.length; i++) {
        for (let [key, value] of Object.entries(attrValidValue[i])) {
          if (value == config1) {
            config1 = key
            break;
          }
        }
      }
      if (this.attrValidValue.AttributeFields.length > 1) {
        let attrvalid2values = this.attrValidValue.AttributeFields[1].datasource
        for (let i = 1; i < attrvalid2values.length; i++) {
          for (let [key, value] of Object.entries(attrvalid2values[i])) {
            if (value == config2) {
              config2 = key
              break;
            }
          }
        }
      }


      let obj;
      let activityObj;
      if (value.scope == '2') {
        obj = {
          ExtAttrValue1: config1,
          ExtAttrValue2: config2,
          configid: this.tempObj.id,
          entityid: sessionStorage.getItem('org_id'),
          entytitype: 2,
          description: this.tempObj.Description,
          AttributeName: this.tempObj.refconfigname,
          id: this.tempObj.OrgVeId ?? -1,
          tableName: this.tableName
        }
        activityObj = {
          All_Locations_Value_1: config1,
          All_Locations_Value_2:  config2 == 'Not set' ? 'N/A' : config2 ,
          configid: this.tempObj.id,
          entityid: sessionStorage.getItem('org_id'),
          entytitype: 2,
          description: this.tempObj.Description,
          AttributeName: this.tempObj.refconfigname,
          id: this.tempObj.OrgVeId ?? -1,
          tableName: this.tableName
        }

      }
      else {
        obj = {
          ExtAttrValue1: config1,
          ExtAttrValue2: config2,
          configid: this.tempObj.id,
          entityid: sessionStorage.getItem('AccountID'),
          entytitype: value.scope,
          description: this.tempObj.Description,
          AttributeName: this.tempObj.refconfigname,
          id: this.tempObj.AccountVeID ?? -1,
          tableName: this.tableName
        }
        activityObj = {          
          This_Location_Value_1: config1,
          This_Location_Value_2: config2 == 'Not set' ? 'N/A' : config2 ,
          configid: this.tempObj.id,
          entityid: sessionStorage.getItem('org_id'),
          entytitype: 2,
          description: this.tempObj.Description,
          AttributeName: this.tempObj.refconfigname,
          id: this.tempObj.OrgVeId ?? -1,
          tableName: this.tableName
        }
      }
      if ((obj.id && obj.id != -1) || this.tableName.toLowerCase() == 'accounts' || this.tableName.toLowerCase() == 'organizations') {
        this.vitalHttpServices.UpdateVAEntityAttribute(obj, this.deployment).subscribe((res) => {
          if (res && res.Success == true) {
            this._snackbar.open(res.Message, 'Close');
            this.editAttribute = false;
            this.getEntityAttributeList(this.searchedValue)
            // this.commonService.createActivityTracker('Edited', obj.id, 'Configurations', 'Audit', obj, this.oldObject); obj['ExtAttrValue1'] = obj['ExtAttrValue1']==0?'False':'True';
            Object.keys(activityObj).forEach(function(key) {
              if(activityObj[key] === "YD") {
                activityObj[key] = "Yesterday Date (YD)";
              }else if(activityObj[key] === "CD"){
                activityObj[key] = "Current Date (CD)";
              }
          })
            this.oldObject['id'] = obj.AttributeName;
            this.oldObject['AttributeName'] = obj.AttributeName;
            this.commonService.auditDetails('id', 'AttributeName', [this.oldObject], [activityObj], 'Edit', this.templateDataCopy, this.auditableColumns);
            this.Attr1LooseDropDown = this.Attr2Freetext = this.Attr1StrictDropDown = this.Attr1Freetext = this.Attr2StrictDropDown = this.Attr2LooseDropDown = false;
            //this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Case Profiles', 'Audit', obj, {isactive:oldValue})
          }
          else if (res.Success != true) {
            this._snackbar.open(res.Message, 'Close');
          }
          this.ngxService.stop();
        }, error => {
          // Change position in view level if update fail
          this.ngxService.stop();
          this._snackbar.open("An error occurred while processing your request", "Failed");
        });
      }
      else {
        this.vitalHttpServices.createVAEntityAttribute(obj, this.deployment).subscribe((res) => {
          if (res && res[2] == 'True') {
            this._snackbar.open(res[1], 'Close');
            let id = parseInt(res[0]);
            this.editAttribute = false;
            this.getEntityAttributeList(this.searchedValue);
            Object.keys(activityObj).forEach(function(key) {
              if(activityObj[key] === "YD") {
                activityObj[key] = "Yesterday Date (YD)";
              }else if(activityObj[key] === "CD"){
                activityObj[key] = "Current Date (CD)";
              }
          })
            this.oldObject['id'] = obj.AttributeName;
            this.oldObject['AttributeName'] = obj.AttributeName;
            this.commonService.auditDetails('id', 'AttributeName', [this.oldObject], [activityObj], 'Edit', this.templateDataCopy, this.auditableColumns);
            // this.commonService.createActivityTracker('Created', id, 'Configurations', 'Audit', obj, []);
            this.Attr1LooseDropDown = this.Attr2Freetext = this.Attr1StrictDropDown = this.Attr1Freetext = this.Attr2StrictDropDown = this.Attr2LooseDropDown = false;

            //this.commonService.createActivityTracker('Edited', s.rows[e.row].dataItem.CaseProfileId, 'Case Profiles', 'Audit', obj, {isactive:oldValue})
          }
          this.ngxService.stop();

        }, error => {
          // Change position in view level if update fail
          this.ngxService.stop();
          this._snackbar.open("An error occurred while processing your request", "Failed");
        });
      }
    }
    catch {
      this.ngxService.stop();
    }
  }

  clickProtect(data) {
    if (data == 'organization') { // patching org details
      //this.tempObj

      this.editEntityAttribute(this.rowObject)
      this.frmEntityAttribute.patchValue({
        configValue1: this.tempObj.All_Locations_Value_1 == 'N/A' ? 'Not set' : this.tempObj.All_Locations_Value_1,
        configValue2: this.tempObj.All_Locations_Value_2 == 'N/A' ? 'Not set' : this.tempObj.All_Location_Value_2,
        scope: "2",
      })
      this.orgLevel = true;
      this.accountLevel = false;
      for (let i= 0; i < this.attrValidValue.AttributeFields[i].datatype.length; i++) {
        if (this.finalstateforalllocation1 == true && this.finalstateforalllocation2 == true) {
          if (this.attrValidValue.AttributeFields[i].datatype.toLowerCase() == 'strict dropdown' && this.orgLevel == true) {
            this.finalstateforalllocation=true;
            this.frmEntityAttribute.controls['configValue1'].disable();
            this.frmEntityAttribute.controls['configValue2'].disable();
          }

        }
        else if (this.finalstateforalllocation2 == true) {
          this.finalstateforalllocation2 =true;
          if (this.attrValidValue.AttributeFields[i].datatype.toLowerCase() == 'strict dropdown' && this.orgLevel == true) {
            this.frmEntityAttribute.controls['configValue2'].disable();
          }

        }
        else if (this.finalstateforalllocation1 == true) {
          this.finalstateforalllocation1 = true
          if (this.attrValidValue.AttributeFields[i].datatype.toLowerCase() == 'strict dropdown' && this.orgLevel == true) {
            this.frmEntityAttribute.controls['configValue1'].disable();
          }

        }
        else {
          this.frmEntityAttribute.controls['configValue1'].enable();
          this.frmEntityAttribute.controls['configValue2'].enable();
        }
    }
    }
    else {

      this.editEntityAttribute(this.rowObject)
      this.frmEntityAttribute.patchValue({
        configValue1: this.tempObj.This_Location_Value_1 == 'N/A' ? 'Not set' : this.tempObj.This_Location_Value_1,
        configValue2: this.tempObj.This_Location_Value_2 == 'N/A' ? 'Not set' : this.tempObj.This_Location_Value_2,
        scope: "3",
      })
      this.accountLevel = true;
      this.orgLevel = false;
      for (let i= 0; i < this.attrValidValue.AttributeFields[i].datatype.length; i++) {
        if (this.finalstateforthislocation1 == true && this.finalstateforthislocation2 == true) {
          if (this.attrValidValue.AttributeFields[i].datatype.toLowerCase() == 'strict dropdown' && this.accountLevel == true) {
            this.finalstateforthislocation = true
            this.frmEntityAttribute.controls['configValue1'].disable();
            this.frmEntityAttribute.controls['configValue2'].disable();
          }

        }
        else if (this.finalstateforthislocation2 == true) {
          this.finalstateforthislocation2 = true
          if (this.attrValidValue.AttributeFields[i].datatype.toLowerCase() == 'strict dropdown' && this.accountLevel == true) {
            this.frmEntityAttribute.controls['configValue2'].disable();
          }

        }
        else if (this.finalstateforthislocation1 == true) {
          this.finalstateforthislocation1 = true
          if (this.attrValidValue.AttributeFields[i].datatype.toLowerCase() == 'strict dropdown' && this.accountLevel == true) {
            this.frmEntityAttribute.controls['configValue1'].disable();
          }

        }
        else {
          this.frmEntityAttribute.controls['configValue1'].enable();
          this.frmEntityAttribute.controls['configValue2'].enable();
        }
      }
    }
  }
  backFromFormPage() {
    if (this.createEntity) {
      this.searchEntityAttribute = true;
      if (this.templateData && this.templateData.length > 0) {
        this.showGrid = true;
      }
    }
    else {
      //this.showGrid = true;
      this.getEntityAttributeList(this.searchData)
      this.searchEntityAttribute = true;
    }
    this.editAttribute = false;
    this.frmEntityAttribute.reset();
    this.createEntity = false;
    this.editEntity = false;
    this.Attr1LooseDropDown = this.Attr2Freetext = this.Attr1StrictDropDown = this.Attr1Freetext = this.Attr2StrictDropDown = this.Attr2LooseDropDown = false;
  }

  filterName(name: string): any[] {
    return this.attributesName.filter(option =>
      option.refconfigname.toLowerCase().includes(name.toLowerCase()) || option.name.toLowerCase().includes(name.toLowerCase()));
  }
  filterConfig(name: string): any[] {
    return this.filterdConfigvalue1.filter(option =>
      option.value.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  //return this.options.filter(option => option.toLowerCase().includes(filterValue)
  filterConfig2(name: string): any[] {
    return this.filterdConfigvalue2.filter(option =>
      option.value.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  fnToggleFilter() {
    this.txtInput.nativeElement.focus();
    this.configname.reset();
    this.showGrid = false;
  }


  viewAllFlags() {
    this.gridData2 = undefined;
    //get data to show in pop up
    // tabList = (this.flagCategoryListData.map(e => e = { label: e.category, content: e.name + ' (' + e.refconfigname + ')' }));
    // const tabData = tabList.reduce((groups, Json) => {
    //   const { label } = Json; // we first extract the label property from the current Json object.
    //   if (!groups[label]) {
    //     groups[label] = { label: Json.label, content: [] };
    //   }
    //   groups[label]['content'].push(Json.content);
    //   return groups;
    // }, {});
    let tabList: any = [];
    if (this.flagCategoryListData && this.flagCategoryListData.length > 0) {
      tabList = Object.values(this.flagCategoryListData.reduce((groups, e) => {
        const { category, name, refconfigname, description } = e;// we first extract the label property from the current Json object.
        const label = category;
        if (!groups[label]) {
          groups[label] = { label, content: [] };
        }
        groups[label].content.push({ 'Name': name + ' (' + refconfigname + ')', 'Description': description });
        // groups[label].content.sort();
        return groups;
      }, {})).sort((a: any, b: any) => {
        const labelA = a.label.toUpperCase();
        const labelB = b.label.toUpperCase();
        if (labelA < labelB) {
          return -1;
        }
        if (labelA > labelB) {
          return 1;
        }
        return 0;
      });
      this.tabList = tabList
      this.asyncTabs = new Observable((observer: Observer<VaEntityAttributesTab[]>) => {
        setTimeout(() => {
          observer.next(tabList);
        }, 1000);
      });

      let mattabEvent: MatTabChangeEvent;
      // abc.index = 0
      //adddatatoGrid based on current tab
      this.addViewGridData(0, mattabEvent);
      // this.tabGroup.selectedIndex = 0;
      //open pop up
      $('#vaEntityAttributesModal').appendTo("body").modal('show');
    }
    else {
      this._snackbar.open("No Flags found", "Close");
    }
  }
  addViewGridData(selectedTab, event: MatTabChangeEvent) {
    let tabListContent;
    if (selectedTab != -100 && selectedTab == 0) {
      tabListContent = this.tabList[0].content
    }
    else {
      if (event.index < 0) {
        event.index = 0;
      }
      tabListContent = this.tabList[event.index].content
    }
    this.gridData2 = undefined;

    this.gridarray1 = [];
    let primary = {};
    if (tabListContent && tabListContent.length > 0) {
      for (let i = 0; i < tabListContent.length; i++) {
        primary = {};
        for (let [key, value] of Object.entries(tabListContent[i])) {
          for (let j = 0; j < this.gridheader1.length; j++) {
            if (key.toLowerCase() === this.gridheader1[j].toLowerCase()) {
              primary[key] = value;
            }
          }
        }
        this.gridarray1.push(primary);
      }
    }
    this.gridData2 = new CollectionView(this.gridarray1, { pageSize: 10 });
    this.gridwidth1 = 170 * this.gridheader1.length + 37;
    if (this.gridwidth1 > 1300) this.gridwidth1 = 1700;
    this.showviewflaggrid = true;
  }

  closePopUp() {
    //code to close pop up
    $('.vaEntityAttributesModal').modal('hide');
    this.showviewflaggrid = false;
  }

  selectFlags(grid, $event) {
    var hti = grid.hitTest(event);
    let selectedrowData = hti.panel.rows[hti.row].dataItem;
    this.configname.setValue(selectedrowData.Name);
    let data: any;
    data = { 'source': { 'value': selectedrowData.Name } };
    this.getEntityAttributeList(data)
    this.closePopUp();
  }
  //#endregion

  //on destroy remove the modals that has been appended on the body
  ngOnDestroy() {
    //code to remove the modals in the component appended to the body
    $('#vaEntityAttributesModal').remove();
  }


  getAuditableDetails(location: any) {
    this.vitalHttpServices.getDisplayColumns({ "TableName": location }).subscribe((res) => {
      this.auditableColumns = JSON.parse(res.content.JsonData);
    })
  }
  editJson(str) {
    let dialogRef = this.dialog.open(ManageJsonEditorComponent, {
      disableClose: true,
      width: '50vw',
      height: 'auto',
      panelClass: 'admin-custom-popup',
      data: { header: "JSON Editor", message: "", jsonText: this.frmEntityAttribute.value[str], continue: "Done", cancel: "Cancel" }
    })
    return dialogRef.afterClosed().toPromise().then(res => {
      if (res && res.result == 'Success') {
        this.frmEntityAttribute.patchValue({ [str]: res.jsonText })
      }
    })
  }
}

class ColDef {
  constructor(public binding?: string, public header?: string) { }
}
